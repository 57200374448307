import React from 'react';
import { ReactComponent as GraniteWireless } from '../../assets/images/granite_wireless.svg';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { OpenOutline } from 'react-ionicons';
import clsx from 'clsx';
const Card = ({
  Vector,
  HoverVector,
  title,
  subtitle,
  onLaunch,
}: {
  Vector: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  HoverVector: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  subtitle: string;
  onLaunch: () => void;
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <div className="flex w-[252.5px] min-w-[252.5px] flex-col  gap-2">
      <div
        className="relative h-[255px] rounded-sm shadow-[0px_4px_8px_6px_#00000026] hover:cursor-pointer"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onLaunch}
      >
        <div className="absolute inset-0 flex overflow-hidden">
          <HoverVector
            width={'auto'}
            height={'215px'}
            className={clsx(
              'absolute left-0 right-0 top-[-10px] w-full overflow-hidden transition-all duration-300 ease-linear',
              isHovered ? '!top-0 opacity-100' : 'opacity-0',
            )}
          />
          <Vector
            width={'auto'}
            className={clsx(
              'absolute inset-0 w-full overflow-hidden transition-opacity duration-300 ease-linear',
              isHovered ? 'opacity-0' : 'opacity-100',
            )}
          />
          <GraniteButton
            size="medium"
            className={clsx(
              'z-50 mx-auto self-center align-middle transition-all duration-200 ease-linear',
              isHovered ? 'opacity-100' : 'opacity-0',
            )}
          >
            Launch <OpenOutline height="21px" width="21px" color="inherit" />
          </GraniteButton>
        </div>

        <GraniteWireless
          className={clsx(
            'm-auto h-full pt-16 transition-all duration-300 ease-linear',
            isHovered
              ? '-translate-y-2 opacity-0'
              : 'translate-y-0 opacity-100',
          )}
        />
      </div>
      <p
        className={clsx(
          'font-bold text-content-base-default',
          isHovered && '!text-content-accent-default',
        )}
      >
        {title}
      </p>
      <p
        className={clsx(
          'text-xs font-semibold text-content-base-subdued',
          isHovered && '!text-content-accent-default',
        )}
      >
        {subtitle}
      </p>
    </div>
  );
};

export default Card;
