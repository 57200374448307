import clsx from 'clsx';
import { CSSProperties } from 'react';

export interface DividerProps {
  variant?: 'default' | 'subdued';
  layout?: 'horizontal' | 'vertical';
  className?: string;
  style?: CSSProperties;
}

const Divider = ({
  variant = 'subdued',
  layout = 'horizontal',
  className,
  style,
}: DividerProps) => {
  const horizontalStyles = 'h-[1px] flex-shrink-0';
  const verticalStyles = 'w-[1px] h-[57.5px] flex-shrink-0';

  const defaultVariant = 'bg-stroke-base-default';
  const subduedVariant = 'bg-stroke-base-subdued';

  return (
    <div
      data-testid="divider"
      style={style}
      className={clsx(
        `
        border-solid border-opacity-[.5]
        ${layout === 'horizontal' ? horizontalStyles : verticalStyles}
        ${variant === 'default' ? defaultVariant : subduedVariant}
      `,
        className,
      )}
    />
  );
};

export default Divider;
