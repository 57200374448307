import React from 'react';

export const BackgroundTypeBox = ({
  isActive,
  children,
  handleClick,
  name,
}: {
  isActive: boolean;
  children: React.ReactNode;
  handleClick: (value: string) => void;
  name: string;
}) => {
  return (
    <div
      onClick={() => handleClick(name)}
      className={`w-full cursor-pointer border-l-2 border-background-base-surface-1 ${
        isActive
          ? '!border-stroke-accent-default bg-background-base-surface-2'
          : ''
      } min-w-[200px] rounded-r px-4 py-2`}
    >
      {children}
    </div>
  );
};
