import { z } from 'zod';
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';

export const TicketDetailForms = z.enum([
  'ExtensionPOTS',
  'ExtensionCircuit',
  'Move',
  'SmartHands',
  'Audit',
  'EquipmentInstall',
  'BroadbandCircuit',
  'POTSGraniteLine',
  'WiringCopper',
  'WiringFiber',
  'CustomerBroadbandCircuit',
  'POTSCustomerLine',
  'CustomerWiringCopper',
  'CustomerWiringFiber',
  'CustomerEquipment',
  'CustomerMaterial',
]);

export type TicketDetailFormType = z.infer<typeof TicketDetailForms>;

export interface TicketDetailForm<T extends FieldValues> {
  register: UseFormRegister<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
  watch?: UseFormWatch<T>;
}
