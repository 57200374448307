import React, { ReactNode } from 'react';

interface BadgeCellProps {
  value: ReactNode;
  status?: BadgeStatus | Priority | string;
  width?: string;
}

export enum BadgeStatus {
  Informative = 'informative',
  Success = 'success',
  Danger = 'danger',
  Caution = 'caution',
  Neutral = 'neutral',
}

export enum Priority {
  priority1 = 'priority1',
  priority2 = 'priority2',
  priority3 = 'priority3',
}

interface BadgeProps {
  status?: string;
  width?: string;
  children: React.ReactNode;
}

const Badge: React.FC<BadgeProps> = ({ status, width, children }) => {
  const getBackgroundColor = () => {
    switch (status) {
      case BadgeStatus.Informative:
        return 'bg-status-info-default';
      case BadgeStatus.Success:
        return 'bg-status-success-default';
      case BadgeStatus.Danger:
        return 'bg-status-error-default';
      case BadgeStatus.Caution:
        return 'bg-status-warning-default';
      case Priority.priority1:
        return 'bg-bar-priority1';
      case Priority.priority2:
        return 'bg-bar-priority2';
      case Priority.priority3:
        return 'bg-bar-priority3';
      default:
        return 'bg-background-base-surface-1 ';
    }
  };

  const getColor = () => {
    switch (status) {
      case BadgeStatus.Informative:
      case BadgeStatus.Success:
      case BadgeStatus.Danger:
      case BadgeStatus.Caution:
        return 'text-content-flip-default';
      default:
        return 'text-content-base-default';
    }
  };

  return (
    <div
      className={`h-6 rounded-lg  px-2 text-center text-xs font-bold ${getBackgroundColor()} ${getColor()} ${
        width ? width : 'inline-block'
      } leading-2 flex items-center justify-center`}
    >
      {children}
    </div>
  );
};

const BadgeCell: React.FC<BadgeCellProps> = ({ value, status, width }) => {
  return (
    <Badge status={status} width={width}>
      {value}
    </Badge>
  );
};

export default BadgeCell;
