import { zodResolver } from '@hookform/resolvers/zod';
import Form from './Form';
import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { RoleFormType } from 'screens/RolesPermissions/roles-permissions.types';
import { useMutation, useQueryClient } from 'react-query';
import { postRole } from 'screens/RolesPermissions/utils';
import showToast from 'components/Toast/Toast';
import { RoleSchema } from './schema';
import ModalFormHeader from 'components/Modal/ModalFormHeader';

const CreateRole = () => {
  const navigate = useNavigate();

  const onClose = () => navigate('..', { replace: true });

  const methods = useForm<RoleFormType>({
    resolver: zodResolver(RoleSchema),
  });

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(postRole, {
    onSuccess: (data) => {
      showToast.confirmation({
        title: 'Role created!',
        message: `“${data?.name}”`,
      });
      queryClient.refetchQueries('role-index-table');
      navigate(`/users/roles/${data?.id}`, { replace: true });
    },
  });

  const onSubmit = (data: RoleFormType) => mutate(data);

  return (
    <>
      <Modal isVisible={true} close={onClose} className="w-full max-w-[624px]">
        <ModalFormHeader
          title="Create new role"
          subtitle="Roles contain collections of permissions and can be assigned to
            users."
        />
        <FormProvider {...methods}>
          <form className="p-8" onSubmit={methods.handleSubmit(onSubmit)}>
            <Form />
            <div className="mt-12 flex gap-5">
              <GraniteButton variant="secondary" size="large" onClick={onClose}>
                Cancel
              </GraniteButton>
              <GraniteButton size="large" type="submit" disabled={isLoading}>
                Create role
              </GraniteButton>
            </div>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default CreateRole;
