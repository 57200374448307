import { ContentLayout } from '../../layouts/ContentLayout/ContentLayout';
import Tabs from '../../components/Table/Tabs';
import {
  Eye,
  EyeOff,
  FileTray,
  InformationCircle,
  Notifications as NotificationIcon,
  Podium,
  Ticket,
} from 'react-ionicons';
import { DashboardIframe } from './DashboardIframe';
import { useDomoDashboard } from '../../shared/hooks/useDomoDashboard';
import { NOCExpressFilters } from './NOCExpressFilters';
import InformationalMessage from './InformationalMessage';
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Searchbar from 'components/Table/SearchBar';
import Filters from 'components/Filters';
import { GraniteSelect } from 'components/Select/Select';
import { PageTitleGranite } from 'components';
import {
  StackedCell,
  StackedCellMainContent,
  StackedCellSubtitle,
} from 'components/Table/Table.styles';
import {
  StatusCell,
  TicketIdBadge,
} from 'screens/TechExpress/TechExpress.styles';
import { ColumnDef, RowData } from '@tanstack/react-table';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import {
  NetOpsFilterSearchParams,
  NetOpsItem,
  NetOpsTicketStatuses,
} from 'api/nocexpress/schema';
import { useSortedTable } from 'hooks/useSortedTable';
import { useFilterForTable } from 'hooks/useFilterForTable';
import { followTicket, getNetOpsTicketsIndex } from 'api/nocexpress/api';
import { formatDate } from 'screens/TechExpress/TechExpress';
import { Chip } from 'components/Chip/Chip';
import { getCookie, setCookie, toSentenceCase } from 'shared/util/util';
import { EmptySearchResults } from 'screens/QuoteIndex/QuoteIndexPage';
import StatusIndicatorNoc from './StatusIndicatorNoc';
import { returnMainStatusOfTicket } from './TicketDetails/util';
import {
  NetOpsTicketIssue,
  NetOpsTicketService,
} from './TicketDetails/form-schema';
import { useDownloadAsExcel } from 'hooks/useDownloadAsExcel';
import { useProductTourContext } from 'context/ProductTourContext';
import { getNOCTicketsMockData } from 'mocks/tour';
import { usePopper } from 'react-popper';
import { TokenUserPermissions } from 'api/users/schemas/Users';
import Alerting from './Alerting/Alerting';
import { useFeatureFlags } from 'feature-flags';
import Customize from 'components/Table/Customize/Customize';
import { useCustomizeTHead } from 'hooks/useCustomizeTHead';
import { DisplayForPermission } from 'components/Permission/DisplayForPermission';

declare module '@tanstack/react-table' {
  // TData is not "used" but required for module augmentation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    align?: 'left' | 'center' | 'right';
    name?: string;
  }
}

type TabsType = 'tickets' | 'dashboard' | 'alerting';

enum TabsTypeEnum {
  tickets,
  alerting,
  dashboard,
}

const EmptyNetOps = () => {
  return (
    <div className="col-span-full flex flex-col items-center gap-6 bg-background-base-surface-2 p-8">
      <FileTray
        color="rgb(var(--content-base-subdued))"
        width="48px"
        height="48px"
      />
      <p className="rounded font-bold leading-[22px] text-content-base-default">
        You haven&apos;t created any repair tickets yet.
      </p>
    </div>
  );
};

const netOpsFilterStatus = NetOpsTicketStatuses.options.map((status) => ({
  label: status === 'Pending NI' ? 'Pending NI' : toSentenceCase(status),
  value: status,
}));

const netOpsFilterIssue = NetOpsTicketIssue.options.map((issue) => ({
  label: toSentenceCase(issue),
  value: issue,
}));

const netOpsFilterService = NetOpsTicketService.options.map((service) => ({
  label: service,
  value: service,
}));

export const NOCExpress = () => {
  const navigate = useNavigate();
  const { flags } = useFeatureFlags();
  const { dashboardHeight, filterStore } = useDomoDashboard();
  const { running } = useProductTourContext();
  const [pageSize, setPageSize] = useState<number>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialTab = (queryParams.get('tab') as TabsType) || 'tickets';

  const [activeTab, setActiveTab] = useState<TabsType>(initialTab);

  const [tableData, setTableData] = useState<NetOpsItem[]>([]);

  const [followStates, setFollowStates] = useState<Record<string, boolean>>({});
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>(
    {},
  );

  const tableHeaders: ColumnDef<NetOpsItem>[] = [
    {
      id: 'parent_macnum',
      header: 'Parent #',
      accessorKey: 'parent_macnum',
      enableSorting: false,
    },
    {
      id: 'macnum',
      header: 'Account #',
      accessorKey: 'macnum',
      enableSorting: false,
    },
    {
      id: 'full_site_name',
      header: 'Account name',
      accessorKey: 'full_site_name',
      enableSorting: false,
    },
    {
      id: 'submitted_by',
      header: 'Submitted by',
      accessorKey: 'submitted_by',
      enableSorting: false,
    },
    {
      id: 'id',
      header: 'Ticket #',
      accessorKey: 'id',
      enableSorting: false,
      cell: (row) => (
        <TicketIdBadge>{row.getValue() as ReactNode}</TicketIdBadge>
      ),
    },
    {
      id: 'address',
      header: 'Address',
      accessorKey: 'site',
      enableSorting: false,
      cell: (cellCtx) => {
        const sitesCount = cellCtx.row.original.impacted_site_count;
        const isMultiSite = Number(sitesCount) > 1;

        const address = [
          cellCtx.row.original.city,
          cellCtx.row.original.state,
          cellCtx.row.original.zip,
        ].filter(Boolean);
        const singleSiteAddress =
          address.length > 0 ? address.join(', ') : '-- -- --';

        return (
          <StackedCell className="w-full">
            <StackedCellMainContent>
              {isMultiSite ? 'Multi-site' : cellCtx.row.original.address_1}
            </StackedCellMainContent>
            <StackedCellSubtitle>
              {isMultiSite ? `${sitesCount} locations` : singleSiteAddress}
            </StackedCellSubtitle>
          </StackedCell>
        );
      },
    },
    {
      id: 'service',
      header: 'Service',
      enableSorting: false,
      cell: (row) => {
        const isMultiSite = Number(row.row.original.impacted_site_count) > 1;
        return (
          <StackedCell>
            <StackedCellMainContent>
              {row.row.original.service_name ?? '-- -- --'}
            </StackedCellMainContent>
            <StackedCellSubtitle>
              {row.row.original.service_id && !isMultiSite
                ? `Service ID: ${row.row.original.service_id}`
                : '-- -- --'}
            </StackedCellSubtitle>
          </StackedCell>
        );
      },
    },
    {
      id: 'issue',
      header: 'Issue',
      accessorKey: 'issue',
      enableSorting: false,
    },
    {
      id: 'status_name',
      header: 'Status',
      accessorKey: 'status',
      enableSorting: false,
      cell: (row) => {
        const isNestedStatus = NetOpsTicketStatuses.options.some(
          (item) => item === row.row.original.status_name,
        );
        return (
          <StatusCell className="relative">
            <StatusIndicatorNoc status={row.row.original.status_name} />
            <StackedCell className="w-full pl-5">
              <StackedCellMainContent>
                {returnMainStatusOfTicket(row.row.original.status_name)}
              </StackedCellMainContent>
              {!isNestedStatus && (
                <StackedCellSubtitle>
                  {row.row.original.status_name}
                </StackedCellSubtitle>
              )}
            </StackedCell>
          </StatusCell>
        );
      },
    },
    {
      id: 'date_entered',
      accessorKey: 'date_entered',
      header: 'Opened',
      accessorFn: (r) => formatDate(r.date_entered),
    },
    {
      id: 'last_updated',
      header: 'Last updated',
      accessorKey: 'last_updated',
      accessorFn: (r) => formatDate(r.last_updated.toString()),
    },
    {
      id: 'is_followed',
      header: () => (
        <div className="flex items-center gap-2">
          <span>Watching</span>
          <WatchTooltip />
        </div>
      ),
      accessorKey: 'is_followed',
      cell: (r) => {
        const ticketId = r.row.original.id;
        const isFollowed = followStates[ticketId] ?? r.row.original.is_followed;
        const isLoading = loadingStates[ticketId] ?? false;

        const handleClick = async (e: React.MouseEvent) => {
          e.stopPropagation();
          if (isLoading) return;

          setLoadingStates((prev) => ({
            ...prev,
            [ticketId]: true,
          }));

          try {
            await followTicket(ticketId, { follow: !isFollowed });
            setFollowStates((prev) => ({
              ...prev,
              [ticketId]: !isFollowed,
            }));
            updateTableData(ticketId, !isFollowed);
          } finally {
            setLoadingStates((prev) => ({
              ...prev,
              [ticketId]: false,
            }));
          }
        };
        return (
          <div onClick={handleClick}>
            {isFollowed ? (
              <Eye color="#F8FAFC" width="20px" height="20px" />
            ) : (
              <EyeOff color="#94A3B8" width="20px" height="20px" />
            )}
          </div>
        );
      },
      enableSorting: false,
      meta: {
        align: 'center' as 'left' | 'center' | 'right',
        name: 'Watching',
      },
    },
  ];

  const { sortingQueryParams, sortingState, onSortingChange } = useSortedTable({
    initialSorting: [
      { id: 'date', desc: true },
      { id: 'last_updated', desc: true },
    ],
  });
  const {
    queryParamFilter: statusFilter,
    clearFilter: clearStatusFilter,
    ...statusFilterProps
  } = useFilterForTable({ queryParamKey: 'status' });
  const {
    queryParamFilter: issueFilter,
    clearFilter: clearIssueFilter,
    ...issueFilterProps
  } = useFilterForTable({ queryParamKey: 'issue' });
  const {
    queryParamFilter: serviceFilter,
    clearFilter: clearServiceFilter,
    ...serviceFilterProps
  } = useFilterForTable({ queryParamKey: 'services' });

  const [search, setSearch] = useState<string>();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabFromURL = queryParams.get('tab') as TabsType;
    if (tabFromURL && tabFromURL !== activeTab) {
      setActiveTab(tabFromURL);
    }
  }, [location.search, activeTab]);

  const getNetOpsIndexFn = (args?: NetOpsFilterSearchParams) => {
    if (running) {
      return Promise.resolve(getNOCTicketsMockData());
    }
    return getNetOpsTicketsIndex({
      ...(search ? { search } : {}),
      ...sortingQueryParams,
      ...statusFilter,
      ...issueFilter,
      ...serviceFilter,
      ...args,
    });
  };

  const { data: tablePageData, ...paginatedTableProps } = usePaginatedTable(
    // @ts-expect-error schema validation
    getNetOpsIndexFn,
    {
      search,
      statusFilter,
      issueFilter,
      serviceFilter,
      sortingQueryParams,
    },
    [
      'quote-index-table',
      sortingQueryParams,
      search,
      statusFilter,
      issueFilter,
      serviceFilter,
    ],
    {
      onSuccess: ({ data }: { data: NetOpsItem[] }) => {
        setTableData(sortTableData(data));
      },
      refetchOnMount: true,
    },
  );

  const clearAllFilters = useCallback(() => {
    clearIssueFilter();
    clearStatusFilter();
    clearServiceFilter();
    setSearch('');
  }, [clearIssueFilter, clearStatusFilter, clearServiceFilter]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRowClick = (row: any) => {
    navigate(`/noc-express/${row?.original?.id}`);
  };

  const updateTableData = (ticketId: number, follow: boolean) => {
    setTableData((prevTableData) => {
      const updatedData = prevTableData.map((ticket) => {
        if (ticket.id === ticketId) {
          return { ...ticket, is_followed: follow };
        }
        return ticket;
      });
      return sortTableData(updatedData);
    });
  };

  const sortTableData = (data: NetOpsItem[]) => {
    const watchedTickets = data.filter((ticket) => ticket.is_followed);
    const unwatchedTickets = data.filter((ticket) => !ticket.is_followed);

    watchedTickets.sort(
      (a, b) =>
        new Date(b.date_entered).getTime() - new Date(a.date_entered).getTime(),
    );
    unwatchedTickets.sort(
      (a, b) =>
        new Date(b.date_entered).getTime() - new Date(a.date_entered).getTime(),
    );

    return [...watchedTickets, ...unwatchedTickets];
  };
  useEffect(() => {
    const savedSize = getCookie('paginationSizeNocExpress');
    if (savedSize) {
      setPageSize(parseInt(savedSize, 10));
    }
  }, []);

  const paginationChanged = (page: number) => {
    setPageSize(page);
    setCookie('paginationSizeNocExpress', page.toString(), 365);
  };

  const {
    columns,
    isPreferenceLoading,
    customizeProps,
    exportWithPreferences,
  } = useCustomizeTHead({
    columns: tableHeaders,
    tableName: 'nocexpress-tickets',
  });

  const createExcelFile = useDownloadAsExcel(async () => {
    const tickets = await getNetOpsIndexFn();
    const transformedData = tickets.data.map((item) => ({
      parent_macnum: item.parent_macnum,
      macnum: item.macnum,
      full_site_name: item.full_site_name,
      id: item.id,
      address: `${item.address_1} ${item.city}, ${item.state}, ${item.zip}`,
      service: item.service_id,
      issue: item.issue,
      status_name: returnMainStatusOfTicket(item.status_name),
      date_entered: `${item.date_entered}`,
      last_updated: `${item.last_updated}`,
      submitted_by: item.submitted_by,
      is_followed: item.is_followed ? 'Yes' : '',
    }));

    return exportWithPreferences(transformedData);
  }, 'NOCExpress_tickets');

  return (
    <ContentLayout className="flex flex-col !pb-4">
      <div className="mb-2">
        <PageTitleGranite title="NOCExpress">
          <div className="flex gap-x-4">
            <DisplayForPermission
              permission={TokenUserPermissions.NOC_EXPRESS_WRITE}
              upgradeTooltip
            >
              <Link
                to="/noc-express/open-ticket"
                className="button large primary product-tour-noc-open-button"
              >
                Open repair ticket
              </Link>
            </DisplayForPermission>
          </div>
        </PageTitleGranite>
      </div>
      <Tabs
        className="mt-2 pb-12"
        withUnderline={false}
        variant="medium"
        defaultActiveTab={TabsTypeEnum[activeTab]}
        tabs={[
          {
            icon: <Ticket width="16px" height="16px" />,
            title: 'Repair tickets',
            onClick: () => {
              setActiveTab('tickets');
              navigate('/noc-express?tab=tickets', { replace: true });
            },
            className: 'product-tour-noc-index',
          },
          ...(flags['ALERTING']
            ? [
                {
                  icon: <NotificationIcon width="16px" height="16px" />,
                  title: 'Alert preferences',
                  onClick: () => {
                    setActiveTab('alerting');
                    navigate('/noc-express?tab=alerting', { replace: true });
                  },
                  // className: 'product-tour-noc-index',
                },
              ]
            : []),
          {
            icon: <Podium width="16px" height="16px" />,
            title: 'Dashboard',
            onClick: () => {
              setActiveTab('dashboard');
              navigate('/noc-express?tab=dashboard', { replace: true });
            },
            className: 'product-tour-noc-dashboard',
          },
        ]}
      />
      {activeTab === 'alerting' &&
        (flags['ALERTING'] ? (
          <Alerting />
        ) : (
          <Navigate to="/noc-express?tab=tickets" />
        ))}
      {activeTab === 'dashboard' && (
        <>
          <InformationalMessage />
          <NOCExpressFilters filterStore={filterStore} />
          <DashboardIframe height={dashboardHeight} />
        </>
      )}
      {activeTab === 'tickets' && (
        <>
          <div className="flex w-full flex-col items-start justify-start">
            <div className="mb-4 flex w-full flex-wrap items-start justify-between gap-4">
              <div className="w-full sm:flex-1">
                <Searchbar
                  placeholder={'Search by ticket #, address, or location name'}
                  onSearch={setSearch}
                  onQueryClear={() => {
                    setSearch('');
                  }}
                  clearAllValues={search === ''}
                />
              </div>
              <Customize {...customizeProps} />
              <Filters
                clearFilters={clearAllFilters}
                clearFilterClassName="col-span-full md:col-span-1"
              >
                <GraniteSelect
                  isMulti
                  className="col-span-2 md:col-span-2 xl:col-span-1"
                  placeholder="Service"
                  options={netOpsFilterService}
                  controlShouldRenderValue={false}
                  isSearchable={true}
                  {...serviceFilterProps}
                />
                <GraniteSelect
                  isMulti
                  className="col-span-2 md:col-span-2 xl:col-span-1"
                  placeholder="Issue"
                  options={netOpsFilterIssue}
                  controlShouldRenderValue={false}
                  isSearchable={true}
                  {...issueFilterProps}
                />
                <GraniteSelect
                  isMulti
                  className="col-span-2 md:col-span-2 xl:col-span-1"
                  placeholder="Ticket status"
                  options={netOpsFilterStatus}
                  controlShouldRenderValue={false}
                  isSearchable={true}
                  {...statusFilterProps}
                />
              </Filters>
            </div>
            <div className="mb-12 flex flex-wrap gap-4">
              {statusFilterProps.value.map((sf) => (
                <Chip
                  key={sf.value}
                  label={sf.label}
                  onDelete={() =>
                    statusFilterProps.onChange(
                      statusFilterProps.value.filter(
                        (option) => option.value !== sf.value,
                      ),
                    )
                  }
                />
              ))}
              {issueFilterProps.value.map((sf) => (
                <Chip
                  key={sf.value}
                  label={sf.label}
                  onDelete={() =>
                    issueFilterProps.onChange(
                      issueFilterProps.value.filter(
                        (option) => option.value !== sf.value,
                      ),
                    )
                  }
                />
              ))}
              {serviceFilterProps.value.map((sf) => (
                <Chip
                  key={sf.value}
                  label={sf.label}
                  onDelete={() =>
                    serviceFilterProps.onChange(
                      serviceFilterProps.value.filter(
                        (option) => option.value !== sf.value,
                      ),
                    )
                  }
                />
              ))}
            </div>
            <div className="w-full">
              <ServerPaginatedTable
                data={tableData as NetOpsItem[]}
                columns={columns}
                handleRowClick={handleRowClick}
                title={'Noc express page'}
                sortingState={sortingState}
                onSortingChange={onSortingChange}
                downloadTableFn={createExcelFile}
                paginationChanged={paginationChanged}
                paginationSizeStored={pageSize}
                emptyDataElement={
                  search ||
                  statusFilterProps.value.length > 0 ||
                  issueFilterProps.value.length > 0 ||
                  serviceFilterProps.value.length > 0 ? (
                    <EmptySearchResults />
                  ) : (
                    <EmptyNetOps />
                  )
                }
                tourClassName={'product-tour-noc-view'}
                {...paginatedTableProps}
                isFetchingData={
                  paginatedTableProps.isFetchingData || isPreferenceLoading
                }
              />
            </div>
          </div>
        </>
      )}
    </ContentLayout>
  );
};

export const WatchTooltip = () => {
  const [isVisible, setIsVisible] = useState(false);
  const triggerEltRef = useRef<HTMLDivElement>(null);
  const popperEltRef = useRef<HTMLDivElement>(null);

  const { styles, attributes } = usePopper(
    triggerEltRef?.current,
    popperEltRef?.current,
    {
      placement: 'top-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-10, 10],
          },
        },
      ],
    },
  );

  return (
    <Fragment>
      <div
        ref={triggerEltRef}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        <InformationCircle width="20px" height="20px" color="#94A3B8" />
      </div>
      <div ref={popperEltRef} style={styles.popper} {...attributes.popper}>
        {isVisible && (
          <div className="max-w-[328px] rounded-lg bg-background-base-surface-0 p-5 text-content-base-subdued shadow-elevation3">
            <p className="text-base font-bold text-content-base-default">
              Watching tickets
            </p>
            <p className="mt-1 text-sm font-medium">
              Prioritize important repair tickets by keeping them at the top of
              your list for quick access and management.
            </p>
          </div>
        )}
      </div>
    </Fragment>
  );
};
