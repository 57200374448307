import { TypeOf, ZodTypeAny, z } from 'zod';
import { TokenUserRoles } from './users/schemas/Users';

export const assertSchema = <T extends z.ZodTypeAny>(
  schema: T,
  data: unknown,
): data is TypeOf<T> => {
  schema.parse(data);
  return true;
};

/**
 * Strip constraints and make all schema fields nullable and optional
 */
export const makeSchemaOptional = (schema: ZodTypeAny): ZodTypeAny => {
  if (schema instanceof z.ZodString) {
    return z.string().nullable().optional();
  } else if (schema instanceof z.ZodNumber) {
    return z.number().nullable().optional();
  } else if (schema instanceof z.ZodBoolean) {
    return z.boolean().nullable().optional();
  } else if (schema instanceof z.ZodDate) {
    return z.coerce.date().nullable().optional();
  } else if (schema instanceof z.ZodObject) {
    const shape = schema.shape;
    const newShape: Record<string, ZodTypeAny> = {};
    for (const key in shape) {
      newShape[key] = makeSchemaOptional(shape[key]);
    }
    return z.object(newShape).nullable().optional();
  } else if (schema instanceof z.ZodArray) {
    return z.array(makeSchemaOptional(schema.element)).nullable().optional();
  } else if (schema instanceof z.ZodTuple) {
    return z.tuple(schema.items.map(makeSchemaOptional)).nullable().optional();
  } else if (schema instanceof z.ZodIntersection) {
    return z
      .intersection(
        makeSchemaOptional(schema._def.left),
        makeSchemaOptional(schema._def.right),
      )
      .nullable()
      .optional();
  } else if (schema instanceof z.ZodUnion) {
    return z
      .union(schema.options.map(makeSchemaOptional))
      .nullable()
      .optional();
  } else if (schema instanceof z.ZodDiscriminatedUnion) {
    const optionsArray = Array.from(schema.optionsMap.values());
    const newOptionsArray = optionsArray.map((option) =>
      makeSchemaOptional(option),
    );
    const optionsMap = new Map();
    schema.optionsMap.forEach((option, key) => {
      optionsMap.set(key, makeSchemaOptional(option));
    });
    return new z.ZodDiscriminatedUnion({
      discriminator: schema.discriminator,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      options: newOptionsArray as any,
      optionsMap: optionsMap,
      typeName: schema._def.typeName,
    })
      .nullable()
      .optional();
  } else if (schema instanceof z.ZodEffects) {
    const innerSchema = makeSchemaOptional(schema.innerType());
    // override effect to strip refinements
    const effect = { ...schema._def.effect };
    if (effect.type === 'refinement') effect.refinement = () => true;
    return new z.ZodEffects({
      ...schema._def,
      schema: innerSchema,
      effect,
    })
      .nullable()
      .optional();
  } else if (schema instanceof z.ZodOptional) {
    return makeSchemaOptional(schema.unwrap()).optional().nullable();
  } else {
    return schema.nullable().optional();
  }
};

// modifies a Zod schema to make specified fields nullable and optional.
export const makeFieldsOptional = (
  schema: ZodTypeAny,
  fields: string[],
): ZodTypeAny => {
  if (schema instanceof z.ZodObject) {
    const shape = schema.shape;
    const newShape: Record<string, ZodTypeAny> = {};

    for (const key in shape) {
      if (fields.includes(key)) {
        newShape[key] = shape[key].nullable().optional();
      } else if (fields.some((field) => field.startsWith(`${key}.`))) {
        const nestedFields = fields
          .filter((field) => field.startsWith(`${key}.`))
          .map((field) => field.replace(`${key}.`, ''));
        newShape[key] = makeFieldsOptional(shape[key], nestedFields);
      } else {
        newShape[key] = shape[key];
      }
    }

    return z.object(newShape);
  } else if (schema instanceof z.ZodIntersection) {
    const leftSchema = makeFieldsOptional(schema._def.left, fields);
    const rightSchema = makeFieldsOptional(schema._def.right, fields);
    return z.intersection(leftSchema, rightSchema);
  } else if (schema instanceof z.ZodUnion) {
    const modifiedOptions = schema._def.options.map((option: ZodTypeAny) =>
      makeFieldsOptional(option, fields),
    );
    return z.union(modifiedOptions);
  } else if (schema instanceof z.ZodDiscriminatedUnion) {
    const optionsArray = Array.from(schema.optionsMap.values());
    const newOptionsArray = optionsArray.map((option) =>
      makeFieldsOptional(option, fields),
    );
    const optionsMap = new Map();
    schema.optionsMap.forEach((option, key) => {
      optionsMap.set(key, makeFieldsOptional(option, fields));
    });
    return new z.ZodDiscriminatedUnion({
      discriminator: schema.discriminator,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      options: newOptionsArray as any,
      optionsMap: optionsMap,
      typeName: schema._def.typeName,
    });
  } else if (schema instanceof z.ZodEffects) {
    const innerSchema = makeFieldsOptional(schema._def.schema, fields);
    const effect = { ...schema._def.effect };
    if (effect.type === 'refinement') effect.refinement = () => true;
    return new z.ZodEffects({
      ...schema._def,
      schema: innerSchema,
      effect,
    });
  } else {
    return schema;
  }
};

export const mapBackendRoleToFrontendRole = (role: string): string => {
  switch (role) {
    case TokenUserRoles.COMPANY_ADMIN:
      return 'Company Admin';
    case TokenUserRoles.VIEWER:
      return 'Viewer';
    case TokenUserRoles.USER:
      return 'User';
    case TokenUserRoles.SUPER_ADMIN:
      return 'Super Admin';
    case TokenUserRoles.WELCOME_USER:
      return 'Welcome User';
    case TokenUserRoles.QR_ADMIN:
      return 'QR Admin';
    case TokenUserRoles.EARLY_ADOPTER:
      return 'Early adopter';
    case TokenUserRoles.PROSPECT:
      return 'Prospect';
    case TokenUserRoles.GRANITE_BETA_TESTER:
      return 'Granite beta tester';
    default:
      return role;
  }
};

export const mapFrontendRoleToBackendRole = (role: string): string => {
  switch (role) {
    case 'Company Admin':
      return TokenUserRoles.COMPANY_ADMIN;
    case 'Viewer':
      return TokenUserRoles.VIEWER;
    case 'User':
      return TokenUserRoles.USER;
    case 'Super Admin':
      return TokenUserRoles.SUPER_ADMIN;
    case 'Welcome User':
      return TokenUserRoles.WELCOME_USER;
    case 'QR Admin':
      return TokenUserRoles.QR_ADMIN;
    case 'Early adopter':
      return TokenUserRoles.EARLY_ADOPTER;
    case 'Prospect':
      return TokenUserRoles.PROSPECT;
    case 'Granite beta tester':
      return TokenUserRoles.GRANITE_BETA_TESTER;
    default:
      return role;
  }
};
