import { Outlet, RouteObject, useNavigate } from 'react-router-dom';
import { useReducer } from 'react';
import { ContentLayout } from '../../../layouts/ContentLayout/ContentLayout';
import PageTitle from '../../../components/PageTitle';
import {
  OpenNetOpsTicketWizardContext,
  getWizardInitialState,
  wizardReducer,
} from './WizardReducer';
import { WizardProgress } from '../WizardProgress/WizardProgress';
import { FindLocationOrServiceWrapper } from '../FindLocationOrService/FindLocationOrService';
import { TicketDetailsFormWrapper } from '../TicketDetails/TicketDetails';
import { ResubmitTicket } from '../ResubmitTicket/ResubmitTicket';
import { ResubmitTicketPrior } from '../ResubmitTicket/ResubmitTicketPrior';
import ResubmitTicketWithAddress from '../ResubmitTicket/ResubmitTicketWithAddress';

export const OpenNetOpsTicketWizard = () => {
  const [state, dispatch] = useReducer(wizardReducer, getWizardInitialState());
  const navigate = useNavigate();

  const breadcrumbs = [
    {
      icon: 'home',
      label: 'NOCExpress',
      onClick: () => navigate('/noc-express'),
    },
  ];

  return (
    <ContentLayout>
      <div className="flex flex-col gap-6">
        <PageTitle title="Open repair ticket" breadcrumbs={breadcrumbs} />
        <WizardProgress step={state.step} />
      </div>
      <Outlet
        context={{ dispatch, state } satisfies OpenNetOpsTicketWizardContext}
      />
    </ContentLayout>
  );
};

export const OpenNetOpsTicketRoutes: RouteObject[] = [
  {
    path: 'open-ticket',
    element: <OpenNetOpsTicketWizard />,
    children: [
      { index: true, element: <FindLocationOrServiceWrapper /> },
      { path: 'details', element: <TicketDetailsFormWrapper /> },
      {
        path: 'resubmit/:priorTicket/',
        element: <ResubmitTicketPrior />,
      },
      {
        path: 'resubmit/:priorTicket/:initialDescription/',
        element: <ResubmitTicketPrior />,
      },
      {
        path: 'resubmit/:priorTicket/:initialDescription/:addressName',
        element: <ResubmitTicket />,
      },
      {
        path: 'resubmit-address/:priorTicket/:addressName',
        element: <ResubmitTicketWithAddress />,
      },
    ],
  },
];
