import { Fragment } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import clsx from 'clsx';
import Checkbox from 'components/Checkbox';
import RadioButton from 'components/RadioButton';
import {
  NotificationPreferenceForm,
  NotificationSetting,
} from 'api/notifications/schema';

type Field = NotificationPreferenceForm['preferences'][number];
type EventTypeFilterGroupProps = {
  field: Field;
  control: Control<NotificationPreferenceForm>;
  setValue: UseFormSetValue<NotificationPreferenceForm>;
  eventGroup: NotificationSetting['event_group'];
  pillarNotificationSettings: NotificationSetting[];
};
export const EventTypeFilterGroup = ({
  field,
  control,
  setValue,
  eventGroup,
  pillarNotificationSettings,
}: EventTypeFilterGroupProps) => (
  <Fragment>
    <div className="space-y-2 pl-4">
      <Controller
        name={`preferences.${field.index}.filter_event_type`}
        control={control}
        render={({ field: { value, onChange, ref }, fieldState }) => (
          <RadioButton
            options={[
              {
                value: 'false',
                label:
                  eventGroup.config.event_type_filter_settings?.false ??
                  'All events',
              },
              {
                value: 'true',
                label:
                  eventGroup.config.event_type_filter_settings?.true ??
                  'Select events',
              },
            ]}
            selectedValue={`${value}`}
            onChange={(newValue) => {
              const value = newValue === 'true';
              onChange(value);
              if (!value) {
                Object.keys(field.event_type_ids).forEach((event_type_id) => {
                  setValue(
                    `preferences.${field.index}.event_type_ids.${event_type_id}.value`,
                    true as never,
                  );
                });
              }
            }}
            className="[&_span]:!text-content-base-subdued"
            error={fieldState.error?.message}
            ref={ref}
          />
        )}
      />
    </div>
    <div className="space-y-2 pl-12">
      {pillarNotificationSettings
        .filter((setting) => setting.event_group_id === eventGroup.id)
        .map(({ id, display_name }) => (
          <Controller
            key={id}
            name={`preferences.${field.index}.event_type_ids.${id}.value`}
            control={control}
            render={({ field: { onChange, value, ..._field } }) => (
              <Checkbox
                {..._field}
                label={display_name}
                className={clsx(
                  field.filter_event_type &&
                    '[&_span]:!text-content-base-subdued',
                )}
                checked={value}
                onChange={onChange}
                disabled={!field.filter_event_type}
              />
            )}
          />
        ))}
    </div>
  </Fragment>
);
