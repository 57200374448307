import { z } from 'zod';
import { apiClient } from '../apiClient';
import { assertSchema } from '../schema-utils';
import {
  CreateTicketResponse,
  DraftAPIResponseOptionalSchema,
  ExtendedTicket,
  ExtendedTicketSchema,
  GetDraftFilterSearchParams,
  GetDraftFilterSearchParamsSchema,
  GetTemplatesFilterSearchParams,
  GetTemplatesFilterSearchParamsSchema,
  GetTicketFilterSearchParams,
  GetTicketFilterSearchParamsSchema,
  RecentlyCompletedTicketResponse,
  RecentlyCompletedTicketResponseSchema,
  Template,
  TemplateAPIResponse,
  TemplateAPIResponseOptionalSchema,
  TemplateMetrics,
  TemplateMetricsSchema,
  TicketAPIResponse,
  TicketAttachmentMetadataResponseSchema,
  TicketAttachmentsMetadataResponse,
  TicketEditResponse,
  TicketEditResponseSchema,
  TicketUpdatesResponse,
} from './schema';
import { EditTicketRequest } from 'screens/TicketDetail/TicketEdit/form-schema';
import {
  EstimatedCostRequest,
  EstimatedCostResponse,
  EstimatedCostResponseSchema,
} from './schemas/EstimatedCostSchemas';
import {
  TicketNoteResponse,
  TicketNoteResponseSchema,
} from './schemas/TicketNoteSchemas';
import {
  PriorityMetricResponse,
  PriorityMetricResponseSchema,
  StatusMetricResponse,
  StatusMetricResponseSchema,
  TicketTypeMetricResponse,
  TicketTypeMetricResponseSchema,
} from './schemas/MetricsSchemas';
import { FeedbackForm } from 'layouts/DefaultLayout/FeedbackDialog/schema';
import { WizardReducerState } from 'screens/LetUsHelp/WizardProvider/WizardReducer';
import { GetStartedQuestionnaireBaseForm } from 'screens/LetUsHelp/GetStarted/schemas';
import { TicketDetailFormUnion } from 'screens/LetUsHelp/TicketDetails/schemas';
import { ReviewTemplateForm } from 'screens/TechExpress/Templates/schemas';
import { ChildAccountRequest } from 'screens/LetUsHelp/GetStarted/CreateChildAccount/schemas';
import { ParentAccountsResponse } from './schemas/ParentAccountsResponse';
import { SiteAddress } from 'api/addresssearch/schema';
import {
  BaseFeedbackResponseSchema,
  FeedbackSchemaRequestType,
} from 'screens/TicketDetail/TicketBanner/form-schema';

export const getTickets = async (
  params: GetTicketFilterSearchParams = {},
): Promise<TicketAPIResponse> => {
  const parameters = GetTicketFilterSearchParamsSchema.parse(params);

  const queryParams = {
    ...parameters,
  };

  const response = await apiClient.get(`/api/v1/tickets`, {
    params: queryParams,
  });

  return response.data;
};

export const createTicket = async (
  request: FormData,
): Promise<CreateTicketResponse[]> => {
  const response = await apiClient.post(`/api/v1/tickets`, request, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });

  return response.data;
};

export const getEstimatedCost = async (
  request: EstimatedCostRequest,
): Promise<EstimatedCostResponse> => {
  const response = await apiClient.get(`/api/v1/tickets/estimatedCosts`, {
    params: request,
  });

  return EstimatedCostResponseSchema.parse(response.data);
};

export const getNetOpsTickets = async (
  board_netops: string,
  site_id?: string | number,
) => {
  const response = await apiClient.get(`/api/v1/tickets`, {
    params: {
      board_name: board_netops,
      site: site_id,
    },
  });

  return response.data;
};

export const getTicket = async (ticketId?: string): Promise<ExtendedTicket> => {
  const response = await apiClient.get<ExtendedTicket>(
    `/api/v1/tickets/${ticketId}`,
  );

  return ExtendedTicketSchema.parse(response.data);
};

export const getTicketNotes = async (
  ticketId?: string,
): Promise<TicketNoteResponse[]> => {
  const response = await apiClient.get<TicketNoteResponse>(
    `/api/v1/tickets/notes/${ticketId}`,
  );

  return z.array(TicketNoteResponseSchema).parse(response.data);
};

export const getTicketUpdates = async (
  ticketId?: string,
): Promise<TicketUpdatesResponse[]> => {
  const response = await apiClient.get<TicketUpdatesResponse[]>(
    `/api/v1/tickets/statusUpdates/${ticketId}`,
  );

  return response.data;
};

export const createTicketNote = async (
  request: { text: string },
  ticketId?: string,
): Promise<TicketNoteResponse> => {
  const response = await apiClient.post(
    `/api/v1/tickets/notes/${ticketId}`,
    request,
    {
      headers: {
        'Content-type': 'application/json',
      },
    },
  );
  assertSchema(TicketNoteResponseSchema, response);

  return response.data;
};

export const editTicket = async (
  ticketId: number,
  request: EditTicketRequest,
): Promise<TicketEditResponse> => {
  if (!ticketId) {
    throw new Error('companyId and ticketId are required');
  }
  const response = await apiClient.patch(
    `/api/v1/tickets/${ticketId}`,
    request,
    {
      headers: {
        'Content-type': 'application/json',
      },
    },
  );
  assertSchema(TicketEditResponseSchema, response);

  return response.data;
};

export const cancelTicket = async (ticketId: number) => {
  if (!ticketId) {
    throw new Error('companyId and ticketId are required');
  }
  const response = await apiClient.delete(`/api/v1/tickets/${ticketId}`);

  return response.data;
};

export const getTicketStatusMetrics =
  async (): Promise<StatusMetricResponse> => {
    const response = await apiClient.get(`/api/v1/tickets/metrics/status`);
    return StatusMetricResponseSchema.parse(response.data.metrics);
  };

export const getTicketPriorityMetrics =
  async (): Promise<PriorityMetricResponse> => {
    const response = await apiClient.get(`/api/v1/tickets/metrics/priority`);
    return PriorityMetricResponseSchema.parse(response.data);
  };

export const getTicketTypeMetrics =
  async (): Promise<TicketTypeMetricResponse> => {
    const response = await apiClient.get(`/api/v1/tickets/metrics/types`);
    return TicketTypeMetricResponseSchema.parse(response.data.metrics);
  };

export const getTicketAttachmentsMetadata = async (
  ticketId?: string,
): Promise<TicketAttachmentsMetadataResponse[]> => {
  const response = await apiClient.get(
    `/api/v1/tickets/attachments/${ticketId}`,
  );
  return z.array(TicketAttachmentMetadataResponseSchema).parse(response.data);
};

export const downloadTicketAttachment = async (
  attachmentId?: number,
  ticketId?: string,
): Promise<string> => {
  const response = await apiClient.get(
    `/api/v1/tickets/attachments/${ticketId}/download/${attachmentId}`,
    {
      responseType: 'arraybuffer',
    },
  );

  return response.data;
};

export const getTicketFeedback = async (
  ticketId?: string,
): Promise<BaseFeedbackResponseSchema> => {
  const response = await apiClient.get(`/api/v1/tickets/feedback/${ticketId}`);
  return response.data;
};

export const postTicketFeedback = async (
  ticketId: string,
  request: FeedbackSchemaRequestType,
) => {
  const response = await apiClient.post(
    `/api/v1/tickets/feedback/${ticketId}`,
    request,
  );
  return response.data;
};

export const postTicketAttachments = async (
  ticketId: number,
  request: FormData,
) => {
  await apiClient.post(`/api/v1/tickets/attachments/${ticketId}`, request, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
};

export const getRecentlyCompletedTickets = async (): Promise<
  RecentlyCompletedTicketResponse[]
> => {
  const response = await apiClient.get(`/api/v1/tickets/recentlyCompleted`);
  return z.array(RecentlyCompletedTicketResponseSchema).parse(response.data);
};

export const postFeedback = async (request: FeedbackForm) => {
  const payload = {
    ...request,
    feedback_type: request.feedback_type.value,
    issue_type: request.issue_type?.value,
  };
  await apiClient.post('/api/v1/feedback', payload);
};

export const getAddressBasedOnMacnum = async (search: string) => {
  const response = await apiClient.get(
    `/api/v1/companies/address/macnum?search=${search ?? '03885899'}`,
  );

  return response.data;
};

export const getDrafts = async (params: GetDraftFilterSearchParams = {}) => {
  const parameters = GetDraftFilterSearchParamsSchema.parse(params);

  const queryParams = {
    ...parameters,
  };
  const response = await apiClient.get('/api/v1/tickets/drafts', {
    params: queryParams,
  });

  return DraftAPIResponseOptionalSchema.parse(response.data);
};

export const createDraft = async (data: WizardReducerState) => {
  const response = await apiClient.post('/api/v1/tickets/drafts', { data });
  return response.data;
};

export const updateDraft = async (id: string, data: WizardReducerState) => {
  const response = await apiClient.put(`/api/v1/tickets/drafts/${id}`, {
    data,
  });
  return response.data;
};

export const deleteDraft = async (id: string) => {
  const response = await apiClient.delete(`/api/v1/tickets/drafts/${id}`);
  return response.data;
};

export const getTemplate = async (templateId?: string) => {
  const response = await apiClient.get<Template>(
    `/api/v1/tickets/templates/${templateId}`,
  );
  return response.data;
};

export const getTemplates = async (
  params: GetTemplatesFilterSearchParams = {},
): Promise<TemplateAPIResponse> => {
  const parameters = GetTemplatesFilterSearchParamsSchema.parse(params);

  const queryParams = {
    ...parameters,
  };
  const response = await apiClient.get('/api/v1/tickets/templates', {
    params: queryParams,
  });

  return TemplateAPIResponseOptionalSchema.parse(response.data);
};

export const getTemplateMetrics = async (): Promise<TemplateMetrics> => {
  const response = await apiClient.get('/api/v1/tickets/templates/metrics');
  return TemplateMetricsSchema.parse(response.data);
};

export const createTemplate = async (data: {
  name: string;
  description: string;
  data: {
    getStartedQuestionnaire: GetStartedQuestionnaireBaseForm;
    ticketDetailForm: TicketDetailFormUnion;
    reviewForm: ReviewTemplateForm;
  };
}) => {
  const response = await apiClient.post<Template>(
    '/api/v1/tickets/templates',
    data,
  );
  return response.data;
};

export const updateTemplate = async (
  id: string,
  data: {
    name: string;
    description: string;
    data: {
      getStartedQuestionnaire: GetStartedQuestionnaireBaseForm;
      ticketDetailForm: TicketDetailFormUnion;
      reviewForm: ReviewTemplateForm;
    };
  },
) => {
  const response = await apiClient.patch<Template>(
    `/api/v1/tickets/templates/${id}`,
    data,
  );
  return response.data;
};

export const deleteTemplate = async (id: string) => {
  const response = await apiClient.delete(`/api/v1/tickets/templates/${id}`);
  return response.data;
};

export const templateUse = async (id: string) => {
  const response = await apiClient.get(`/api/v1/tickets/templates/use/${id}`);
  return response.data;
};

export const createChildAccount = async (
  data: ChildAccountRequest,
): Promise<SiteAddress> => {
  const response = await apiClient.post('/api/v1/companies/accounts', data);
  return response.data;
};

export const getParentAccounts = async (): Promise<
  ParentAccountsResponse[]
> => {
  const response = await apiClient.get('/api/v1/companies/sites/parents');
  return response.data;
};
