import clsx from 'clsx';
import React from 'react';
import { AlertConfigurationItemType } from './AlertConfigurationTabel';

const Status: React.FC<Pick<AlertConfigurationItemType, 'status'>> = ({
  status,
}) => (
  <p>
    <span
      className={clsx(
        'mr-2 inline-flex h-2.5 w-2.5 rounded-full',
        status === 'Enabled' && 'bg-green-400',
        status === 'Not eligible' && 'bg-neutral-500',
        status === 'Disabled' && 'bg-red-500',
      )}
    />
    {status}
  </p>
);

export default Status;
