import { z } from 'zod';

export const FeedbackSchemaForm = z.object({
  overallRating: z.number(),
  contact_me_flag: z.enum(['yes', 'no']),
  footer_response: z.string().optional(),
  additional_notes: z.string().optional(),
  qualityRating: z.number().optional(),
  professionalismRating: z.number().optional(),
  communationRating: z.number().optional(),
  techArrivedInTimeRating: z.enum(['yes', 'no']).optional(),
  deliverablesRating: z.enum(['yes', 'no']).optional(),
});

export const FeedbackSchemaRequest = z.object({
  footer_response: z.string().nullable(),
  contact_me_flag: z.boolean(),
  results: z.array(z.object({ question_id: z.number(), answer: z.number() })),
});

export const FeedbackSchemaResponseError = z.object({
  message: z.string(),
});

export const FeedbackSchemaResponse = z.object({
  contact_me_flag: z.boolean(),
  email_address: z.string().email(),
  footer_response: z.string().optional(),
  id: z.number(),
  results: z.array(z.object({ question_id: z.number(), answer: z.number() })),
  ticket_id: z.number(),
});

const BaseFeedbackResponseSchema = z.union([
  FeedbackSchemaResponse,
  FeedbackSchemaResponseError,
]);

export type BaseFeedbackResponseSchema = z.infer<
  typeof BaseFeedbackResponseSchema
>;

export type FeedbackSchemaRequestType = z.infer<typeof FeedbackSchemaRequest>;

export type FeedbackSchemaType = z.infer<typeof FeedbackSchemaForm>;
