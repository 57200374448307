import React from 'react';

import { EstimatedCostResponse } from '../../../api/techexpress/schemas/EstimatedCostSchemas';

export interface PriorityProps {
  duration: number;
  rate: number;
  dispatchFee: number;
}

export const PriorityCosts: React.FC<PriorityProps> = ({
  duration,
  rate,
  dispatchFee,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="text-base font-bold leading-6 text-content-base-default">
        {duration} hours SLA
      </div>
      <div className="text-base font-bold leading-6 text-content-base-default">
        ${rate.toFixed(2)}/hour billed in half hour increments
      </div>
      <div className="text-base font-bold leading-6 text-content-base-default">
        ${dispatchFee.toFixed(2)} dispatch fee
      </div>
    </div>
  );
};

export interface PriorityInfoProps {
  estimatedCost?: EstimatedCostResponse;
}

export const PriorityInfo = ({ estimatedCost }: PriorityInfoProps) => {
  const hours = estimatedCost?.hours ?? 0;
  const rate = estimatedCost?.rate_per_hour ?? 0;
  const dispatch = estimatedCost?.dispatch_fee_dollars ?? 0;
  return <PriorityCosts duration={hours} rate={rate} dispatchFee={dispatch} />;
};
