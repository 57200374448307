import { z } from 'zod';
import {
  ContactSubFormSchema,
  NonStandardToolsFieldSchema,
} from '../../utils/schemas';
import { TicketDetailForms } from '../DefinedFormTypes';

export const CustomerWiringFiberFormSchema = z.object({
  formType: z.literal(TicketDetailForms.enum.CustomerWiringFiber),
  serviceDescription: z.string().trim().nonempty('Required'),
  mpoe: z.string(),
  whatIsUsedFor: z.string(),

  techToTestWith: z.discriminatedUnion('techToTestWith', [
    z.object({ techToTestWith: z.literal('Yes') }),
    z.object({ techToTestWith: z.literal('No') }),
  ]),
  itTestingContacts: z.array(ContactSubFormSchema),
  nonStandardTools: NonStandardToolsFieldSchema,
});

export type CustomerWiringFiberForm = z.infer<
  typeof CustomerWiringFiberFormSchema
>;
