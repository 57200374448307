import { useEffect, useState } from 'react';

export const useScrollThreshold = (threshold: number) => {
  const [isAboveThreshold, setIsAboveThreshold] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > threshold && !isAboveThreshold) {
        setIsAboveThreshold(true);
      }
      if (window.scrollY <= threshold && isAboveThreshold) {
        setIsAboveThreshold(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [isAboveThreshold, threshold]);

  return isAboveThreshold;
};
