import { ComponentProps, ForwardedRef, forwardRef } from 'react';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import clsx from 'clsx';
import {
  RadioButtonInput,
  StyledRadioButton,
} from 'components/RadioButton/RadioButton.styles';

interface MultiOptionButtonProps<T>
  extends Omit<ComponentProps<typeof GraniteButton>, 'onClick' | 'value'> {
  label: string;
  subText?: string;
  value: T;
  option: T;
  onClick: (v: T) => void;
}

const MultiOptionButton = <T,>(
  {
    label,
    subText,
    onClick,
    option,
    value,
    className,
    children,
    ...props
  }: MultiOptionButtonProps<T>,
  ref: ForwardedRef<HTMLButtonElement>,
) => {
  const isSelected = value === option;
  return (
    <button
      className={clsx(
        'tile-select flex flex-col !items-stretch gap-1 !text-left !font-semibold',
        isSelected && 'selected',
        className,
      )}
      type="button"
      onClick={() => onClick && onClick(option)}
      ref={ref}
      aria-label={label}
      {...props}
    >
      <div className="flex justify-between gap-2">
        <span>{label}</span>
        <div>
          <RadioButtonInput checked={isSelected} readOnly className="hidden" />
          <StyledRadioButton checked={isSelected} className="!mr-0" />
        </div>
      </div>
      {subText && (
        <span
          className={clsx(
            'text-xs',
            !isSelected && 'text-content-base-subdued',
          )}
        >
          {subText}
        </span>
      )}
      {children}
    </button>
  );
};

MultiOptionButton.displayName = 'MultiOptionButton';

export default forwardRef(MultiOptionButton) as <T>(
  props: MultiOptionButtonProps<T> & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  },
) => ReturnType<typeof MultiOptionButton>;
