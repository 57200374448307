import { Control, Controller } from 'react-hook-form';
import RadioButton from 'components/RadioButton';
import {
  NotificationPreferenceForm,
  NotificationSetting,
} from 'api/notifications/schema';

type Field = NotificationPreferenceForm['preferences'][number];
type UserCreatedOnlyFilterGroupProps = {
  field: Field;
  control: Control<NotificationPreferenceForm>;
  eventGroup: NotificationSetting['event_group'];
};

export const UserCreatedOnlyFilterGroup = ({
  field,
  control,
  eventGroup,
}: UserCreatedOnlyFilterGroupProps) => (
  <div className="space-y-2 pl-4">
    <Controller
      name={`preferences.${field.index}.user_created_only`}
      control={control}
      render={({ field: { value, onChange, ref }, fieldState }) => (
        <RadioButton
          options={[
            {
              value: 'false',
              label:
                eventGroup.config.user_created_only_settings?.false ??
                'Created by everyone',
            },
            {
              value: 'true',
              label:
                eventGroup.config.user_created_only_settings?.true ??
                'Created by me only',
            },
          ]}
          selectedValue={`${value}`}
          onChange={(newValue) => onChange(newValue === 'true')}
          className="[&_span]:!text-content-base-subdued"
          error={fieldState.error?.message}
          ref={ref}
        />
      )}
    />
  </div>
);
