import React from 'react';
import IconMapper from 'components/IconMapper/IconMapper';
import { Button, IconButtonWrapper } from './IconButton.styles';
import { ButtonProps } from 'components/Button/Button';
import theme from '../../shared/theme/custom-theme';

export interface IconButtonProps extends Omit<ButtonProps, 'label'> {
  icon: string;
  buttonType?: 'primary' | 'secondary' | 'destructive' | 'ghost'; // Add the type prop
  width?: string;
  padding?: string;
  size?: 'small' | 'medium' | 'large';
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  buttonType = 'primary',
  size = 'small',
  width,
  padding = undefined,
  ...props
}: IconButtonProps) => {
  return (
    <IconButtonWrapper size={size}>
      <Button
        {...props}
        className={`storybook-button--${buttonType} flex items-center justify-center`}
        style={{ width, padding }}
      >
        <IconMapper name={icon} color={theme.colors.text.white} />
      </Button>
    </IconButtonWrapper>
  );
};

export default IconButton;
