import { GroupBase, OptionProps } from 'react-select';
import checkMark from 'assets/images/checkMark.svg';
import Checkbox from 'components/Checkbox/Checkbox';
import { OptionType } from './Select';
import clsx from 'clsx';

export const CustomOption = <
  Option extends OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  children,
  isSelected,
  isMulti,
  isFocused,
  innerProps,
  data,
}: OptionProps<Option, IsMulti, Group>) => {
  return (
    <div
      className={
        'flex flex-col items-start text-left duration-200 hover:scale-[1.01] hover:bg-background-base-surface-2 hover:transition-transform'
      }
      {...innerProps}
    >
      <div
        className={clsx(
          'flex w-full items-center p-2.5',
          data.subvalue && 'px-[15px] py-1.5',
        )}
      >
        {isMulti && <Checkbox label="" checked={isSelected} className="mr-2" />}
        <div className="flex-grow">
          <div className="text-[16px] font-[300] text-input-content-filled">
            {children}
          </div>
          {data.subvalue && (
            <div className="text-sm text-content-base-subdued">
              {data.subvalue}
            </div>
          )}
        </div>
        {!isMulti && isSelected && (
          <img
            src={checkMark}
            alt="Checkmark"
            className={`ml-2 ${isFocused ? 'text-input-stroke-focus' : ''}`}
          />
        )}
      </div>
    </div>
  );
};
