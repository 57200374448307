import { useState } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import Switch from 'components/Switch';
import { ReorderTwo } from 'react-ionicons';
import clsx from 'clsx';
import { PreferencesType } from './customize.types';

interface SortablCommon {
  onToggle: (field: PreferencesType['field']) => void;
  value: PreferencesType;
}

interface SortableListProps extends Pick<SortablCommon, 'onToggle'> {
  preferences: PreferencesType[];
}

interface SortableItemProps extends SortablCommon {
  isOnlyEnabled: boolean;
}

interface DragHandleProps extends Pick<SortablCommon, 'value'> {
  setDragging: (v: boolean) => void;
  isDragging: boolean;
  on: boolean;
}

const DragHandle = SortableHandle<DragHandleProps>(
  ({ value, setDragging, isDragging, on }: DragHandleProps) => {
    return (
      <div
        className="flex flex-1 cursor-row-resize gap-6"
        onMouseDownCapture={() => setDragging(true)}
        onMouseUpCapture={() => setDragging(false)}
        onMouseLeave={() => setDragging(false)}
      >
        <ReorderTwo width="24px" height="24px" color="currentColor" />
        <p
          className={clsx('flex-1 text-current', {
            'text-white': !isDragging && on,
            'text-neutral-500': !isDragging && !on,
          })}
        >
          {value.header}
        </p>
      </div>
    );
  },
);

const SortableItem = SortableElement<SortableItemProps>(
  ({ value, onToggle, isOnlyEnabled }: SortableItemProps) => {
    const [isDragging, setDragging] = useState(false);

    return (
      <li
        className={clsx('flex justify-between gap-6 py-3', {
          'text-teal-400 opacity-25 shadow-dragged': isDragging,
          'text-white': !isDragging,
        })}
      >
        <DragHandle
          setDragging={setDragging}
          on={value.enabled}
          value={value}
          isDragging={isDragging}
        />
        <Switch
          onChange={() => {
            onToggle(value.field);
          }}
          isOn={isOnlyEnabled || value.enabled}
          disabled={isOnlyEnabled}
        />
      </li>
    );
  },
);

const SortableList = SortableContainer<SortableListProps>(
  ({ preferences, onToggle }: SortableListProps) => {
    const enabledItems = preferences.filter((item) => item.enabled);

    const isSingleEnabled = enabledItems.length === 1;
    const onlyEnabledItem = isSingleEnabled ? enabledItems[0] : null;

    return (
      <ul className="-mt-3 select-none overflow-auto px-2 scrollbar-thin scrollbar-track-background-base-surface-1 scrollbar-thumb-stroke-base-subdued">
        {preferences.map((item, index) => (
          <SortableItem
            key={`item-${item.field}`}
            index={index}
            value={item}
            onToggle={onToggle}
            isOnlyEnabled={isSingleEnabled && item === onlyEnabledItem}
          />
        ))}
      </ul>
    );
  },
);

export default SortableList;
