import { PaginableRequest } from 'api/common-schemas';
import { LETTERS_NUMBERS_SPACES_HYPHENS_APOSTROPHES_COMMAS_PERIODS_REGEXP_LABEL } from 'shared/constants/error-labels';
import { LETTERS_NUMBERS_SPACES_HYPHENS_APOSTROPHES_COMMAS_PERIODS_REGEXP } from 'shared/constants/validation-regex-constants';
import { z } from 'zod';

export const DeviceSchema = z.object({
  devices: z
    .array(
      z.object({
        id: z.number(),
      }),
    )
    .optional(),
});

export const ContactSchema = z.object({
  name: z
    .string()
    .min(2, { message: 'Group name must be at least 2 characters long.' })
    .max(100, {
      message: 'Group name must be no more than 100 characters long.',
    })
    .regex(LETTERS_NUMBERS_SPACES_HYPHENS_APOSTROPHES_COMMAS_PERIODS_REGEXP, {
      message:
        LETTERS_NUMBERS_SPACES_HYPHENS_APOSTROPHES_COMMAS_PERIODS_REGEXP_LABEL,
    }),
  recipients: z
    .array(z.object({ email: z.string().email().nonempty() }))
    .nonempty({ message: 'At least one email address is required.' }),
});

export const ContactGroupSchema = ContactSchema.merge(DeviceSchema);

export type ContactGroupsSearchParams = { search?: string } & PaginableRequest;

export type DevicesContactGroupsSearchParams = {
  search?: string;
} & PaginableRequest;

export type ContactGroupListItemType = Omit<ContactGroupType, 'devices'>;

export type ContactGroupType = {
  id: number;
  first_name: string;
  last_name: string;
  emails: string[];
  status: 'active' | 'inactive';
  devices: DeviceType[];
};

export type DeviceType = {
  id: number;
  name: string;
  serial_number: string;
  macnum: string;
};

export type ContactGroupFormType = z.infer<typeof ContactGroupSchema>;

export type DeviceFormType = z.infer<typeof DeviceSchema>;
export type ContactFormType = z.infer<typeof ContactSchema>;

export type ContactGroupPostType = {
  devices: DeviceType['id'][];
} & Omit<ContactGroupType, 'id' | 'status' | 'devices'>;

export type ContactGroupPatchType = Omit<
  ContactGroupType,
  'devices' | 'status'
>;
export type DeviceContactGroupPatchType = {
  devices: DeviceType['id'][];
} & Pick<ContactGroupType, 'id'>;
