import { FC } from 'react';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb';
import { TitleActionButtonContainer } from 'components/PageTitle/PageTitle.styles';
import { useQuery } from 'react-query';
import { deleteTemplate, getTemplate } from 'api/techexpress/api';
import { WithLoader } from 'components/WithLoader/WithLoader';
import format from 'date-fns/format';
import { labelForTicketType } from 'screens/LetUsHelp/BaseComponents/schemas';
import Divider from 'components/Divider';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useModal } from 'hooks/useModal';
import { getQueryClient } from 'query-client';
import showToast from 'components/Toast/Toast';
import { DeleteTemplateConfirmationDialog } from './DeleteTemplateConfirmationDialog';

const queryClient = getQueryClient();

export const TemplatePage: FC = () => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const { open, ...modalProps } = useModal();

  const breadcrumbs = [
    {
      icon: 'home',
      label: 'TechExpress',
      onClick: () => navigate('/tech-express'),
    },
    {
      label: 'Templates',
      onClick: () => navigate('/tech-express/templates'),
    },
  ];

  const { data, isLoading } = useQuery(
    ['tech-express', 'template', templateId],
    () => getTemplate(templateId),
    {
      enabled: !!templateId,
    },
  );

  const onDeleteTemplateConfirmed = async () => {
    if (data) {
      await deleteTemplate(data.id);
      queryClient.invalidateQueries({
        queryKey: ['tech-express', 'templates'],
      });
      showToast.confirmation({
        message: 'Template has been successfully deleted',
      });
      modalProps.close();
      navigate('/tech-express/templates');
    }
  };

  if (!data) return null;

  return (
    <WithLoader isLoading={isLoading}>
      <ContentLayout className="w-full 2xl:max-w-[1440px]">
        <div className="mb-12">
          <Breadcrumb breadcrumbs={breadcrumbs} />
          <div className="flex justify-between">
            <h1 className="text-4xl font-bold text-content-base-default">
              {data.name}
            </h1>
            <TitleActionButtonContainer className="gap-x-4">
              <Link to="details" className="button primary large">
                Edit template
              </Link>
            </TitleActionButtonContainer>
          </div>
        </div>
        <div className="grid min-h-screen grid-cols-3 rounded">
          <div className="col-span-1 space-y-12 bg-background-base-surface-3 p-8">
            <h2 className="m-0 mb-4 text-2xl font-bold text-white">
              Template Details
            </h2>
            <div className="space-y-6">
              <TemplateDetailField label="Name" value={data.name} />
              <TemplateDetailField label="Created by" value={data.created_by} />
              <TemplateDetailField
                label="Date created"
                value={format(new Date(data.created_at + 'Z'), 'MM/dd/yyyy')}
              />
              <TemplateDetailField
                label="Date updated"
                value={format(new Date(data.updated_at + 'Z'), 'MM/dd/yyyy')}
              />
              <TemplateDetailField
                label="# of times used"
                value={`${data.used_count}`}
              />
            </div>
          </div>
          <div className="col-span-2 flex flex-col gap-y-12 bg-background-base-surface-2 p-8">
            <h2 className="m-0 text-2xl font-bold text-content-base-default">
              Ticket Template
            </h2>
            <div className="space-y-6">
              <TemplateDetailField
                label="Type of dispatch"
                value={labelForTicketType(data.data.reviewForm.ticketType)}
              />
              <TemplateDetailField
                label="Description"
                value={data.description}
              />
            </div>
            <Divider />
            <div className="space-y-4">
              <h3 className="text-2xl font-bold text-content-base-default">
                Scope of work & special instructions
              </h3>
              <div className="space-y-6">
                <TemplateDetailField
                  label="Scope of work"
                  value={data.data.reviewForm.scopeOfWork}
                />
                <TemplateDetailField
                  label="Special instructions"
                  value={data.data.reviewForm.specialInstructions}
                />
                <TemplateDetailField
                  label="Non-standard tools required"
                  value={
                    data.data.reviewForm.nonStandardTools.isNeeded
                      ? data.data.reviewForm.nonStandardTools.nonStandardTools
                      : undefined
                  }
                />
              </div>
            </div>
            <Divider />
            <div className="flex flex-1 items-end">
              <div className="flex gap-4">
                <GraniteButton
                  variant="destructive"
                  size="large"
                  onClick={open}
                >
                  Delete template
                </GraniteButton>
                <Link to="details" className="button primary large">
                  Edit template
                </Link>
              </div>
            </div>
          </div>
        </div>
      </ContentLayout>
      <DeleteTemplateConfirmationDialog
        {...modalProps}
        onConfirmation={onDeleteTemplateConfirmed}
      />
    </WithLoader>
  );
};

const TemplateDetailField = ({
  label,
  value,
}: {
  label?: string;
  value?: string;
}) =>
  !value ? null : (
    <div>
      <h4 className="font-bold text-content-base-subdued">{label}</h4>
      <span className="font-bold text-content-base-default">{value}</span>
    </div>
  );
