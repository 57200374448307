import IconButton from 'components/IconButton/IconButton';
import React from 'react';

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
}

export const ShortPagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}: PaginationProps) => {
  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="flex gap-4">
      <IconButton
        size="medium"
        buttonType="secondary"
        icon="chevronBack"
        onClick={handlePrevPage}
        disabled={currentPage === 1}
      />
      <div className="rounded-[50px] bg-button-background-primary-default px-4 py-1 font-bold text-content-flip-default">
        {`${currentPage} of ${totalPages}`}
      </div>
      <IconButton
        size="medium"
        buttonType="secondary"
        icon="chevronForward"
        onClick={handleNextPage}
        disabled={totalPages === 1 || currentPage === totalPages}
      />
    </div>
  );
};
