import { useOpenQuoteContext } from '../Wizard/OpenQuoteWizardReducer';
import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { PageTitle } from '../BaseComponents/PageTitle';
import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowData,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { QuoteProduct } from '../schemas/Product';
import {
  StackedCell,
  StackedCellMainContent,
  StackedCellSubtitle,
  TableCell,
} from '../../../components/Table/Table.styles';
import clsx from 'clsx';
import { ChevronDown, ChevronUp } from 'react-ionicons';
import Checkbox from '../../../components/Checkbox';
import { Link } from 'react-router-dom';
import Pagination from 'components/Table/Pagination';
import Searchbar from 'components/Table/SearchBar';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { OpenQuoteStore, ProductsByLocation } from '../Wizard/OpenQuoteStore';
import { observer, Observer } from 'mobx-react-lite';
import { useToggleablePopper } from '../../../shared/hooks/useToggleablePopper';
import { ProductsToLocationsTable } from './ProductsToLocationsTable.styles';
import Divider from '../../../components/Divider';
import { computed } from 'mobx';

const getRowIdx = (index: number) => (index + 1) * 2;

const ColumnFilterDropdown = ({
  columns,
}: {
  columns: Column<ProductsByLocation>[];
}) => {
  const {
    isMenuVisible,
    setIsMenuVisible,
    anchorRef,
    popperElementRef,
    styles,
    attributes,
  } = useToggleablePopper<HTMLButtonElement, HTMLDivElement>({
    placement: 'bottom-end',
  });

  return (
    <Fragment>
      <GraniteButton
        ref={anchorRef}
        onClick={() => setIsMenuVisible((prev) => !prev)}
        size="large"
        variant="secondary"
        className="grow !rounded-l-none !border-l-0"
      >
        Display products
        <ChevronDown width="16px" height="16px" color="inherit" />
      </GraniteButton>
      <div
        ref={popperElementRef}
        style={{
          ...styles.popper,
        }}
        {...attributes.popper}
      >
        {isMenuVisible && (
          <div className="flex flex-col gap-1 rounded-b border-x border-b border-input-stroke-filled bg-input-background-unfilled px-4 py-2">
            {columns.map((c) => (
              <Checkbox
                key={c.id}
                label={c.columnDef.header as string}
                checked={c.getIsVisible()}
                onChange={c.getToggleVisibilityHandler()}
              />
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

declare module '@tanstack/react-table' {
  // TData is not "used" but required for module augmentation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    addProductToLocation: (
      productsByLocation: ProductsByLocation,
      product: QuoteProduct,
    ) => void;
    removeProductFromLocation: (
      productsByLocation: ProductsByLocation,
      product: QuoteProduct,
    ) => void;
  }

  // TData is not "used" but required for module augmentation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    isProduct?: boolean;
    isAddress?: boolean;
    product?: QuoteProduct;
  }
}

const useSkipper = () => {
  const shouldSkipRef = useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
};

const ProductCheckbox = observer(
  ({
    product,
    openQuoteStore,
  }: {
    product: QuoteProduct;
    openQuoteStore: OpenQuoteStore;
  }) => {
    const checkboxState = computed(() => {
      return openQuoteStore.productToLocationState(product);
    }).get();
    return (
      <Checkbox
        label=""
        onChange={() => {
          switch (checkboxState) {
            case 'ALL': {
              openQuoteStore.removeFromAllLocations(product);
              return;
            }
            case 'SOME': {
              openQuoteStore.addToAllLocations(product);
              return;
            }
            case 'NONE': {
              openQuoteStore.addToAllLocations(product);
              return;
            }
          }
        }}
        checked={checkboxState === 'ALL'}
        isPartiallChecked={checkboxState === 'SOME'}
      />
    );
  },
);

export const AssociateProductsToLocations = () => {
  const { openQuoteStore, wizardStore } = useOpenQuoteContext();
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );

  const [columnVisibility, setColumnVisibility] = React.useState({});

  useEffect(() => {
    wizardStore.setStep(2);
  }, [wizardStore]);

  const productAssociationsColumns: ColumnDef<ProductsByLocation>[] = [
    {
      id: 'address',
      header: 'Address',
      accessorFn: (row: ProductsByLocation) =>
        [row.streetAddress, row.fullAddress].join(', '),
      enableHiding: false,
      meta: {
        isAddress: true,
      },
      cell: (cellCtx) => {
        return (
          <Observer>
            {() => (
              <StackedCell className="w-full">
                <StackedCellMainContent>
                  {cellCtx.row.original.streetAddress}
                </StackedCellMainContent>
                <StackedCellSubtitle>
                  {cellCtx.row.original.fullAddress}
                </StackedCellSubtitle>
              </StackedCell>
            )}
          </Observer>
        );
      },
    },
    {
      id: 'count',
      header: 'Products',
      accessorKey: 'productCount',
      enableHiding: false,
      cell: (cellCtx) => {
        return (
          <Observer>{() => <>{cellCtx.row.original.productCount}</>}</Observer>
        );
      },
    },

    ...openQuoteStore.products.map(
      (product) =>
        ({
          id: product.id,
          header: product.name,
          accessorFn: (row: ProductsByLocation) => row.products.has(product),
          meta: {
            isProduct: true,
            product: product,
          },
          cell: ({ row }) => {
            return (
              <Observer>
                {() => (
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        table.options.meta?.addProductToLocation(
                          row.original,
                          product,
                        );
                      } else {
                        table.options.meta?.removeProductFromLocation(
                          row.original,
                          product,
                        );
                      }
                    }}
                    checked={row.original.products.has(product)}
                    label=""
                  />
                )}
              </Observer>
            );
          },
        }) as ColumnDef<ProductsByLocation>,
    ),
  ];

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const table = useReactTable({
    data: openQuoteStore.productsByLocations,
    columns: productAssociationsColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      columnFilters,
      columnVisibility,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex,
    enableSortingRemoval: false,
    enableMultiSort: false,
    sortDescFirst: true,
    meta: {
      addProductToLocation: (productsByLocation, product) => {
        skipAutoResetPageIndex();
        productsByLocation.addProduct(product);
      },
      removeProductFromLocation: (productsByLocation, product) => {
        skipAutoResetPageIndex();
        productsByLocation.removeProduct(product);
      },
    },
  });

  const addressColumn = table.getColumn('address');
  const tableColumns = table.getAllLeafColumns().filter((c) => c.getCanHide());

  return (
    <>
      <div className="grid grid-cols-1 gap-4 bg-background-base-surface-2 p-6">
        <PageTitle
          title="Assign products to your locations"
          subtitle="Select products in the table to assign them to locations"
        />

        <div className="grid grid-cols-1 gap-6">
          <div className="flex flex-col gap-2">
            <h3 className="font-bold">Your products</h3>
            <div className="flex gap-4">
              {/* {openQuoteStore.products.map((p) => (
                <ProductTooltip product={p} key={p.id} />
              ))} */}
            </div>
          </div>

          <div className="flex items-center justify-start gap-4">
            <div className="w-[70%]">
              <Searchbar
                placeholder="Search by address"
                onQueryClear={() => addressColumn?.setFilterValue(undefined)}
                onSearch={(query: string) =>
                  addressColumn?.setFilterValue(query)
                }
              />
            </div>
            <div className="flex grow flex-row">
              <GraniteButton
                variant="secondary"
                size="large"
                className="!rounded-r-none"
                onClick={() => table.resetColumnVisibility()}
              >
                Display all
              </GraniteButton>
              <ColumnFilterDropdown columns={tableColumns} />
            </div>
          </div>

          <div>
            <ProductsToLocationsTable>
              {table.getHeaderGroups().map((headerGroup, index) => (
                <Fragment key={headerGroup.id}>
                  <div className="row-[1] mb-6 flex items-center font-bold text-content-base-subdued">
                    #
                  </div>
                  {headerGroup.headers.map((header) => (
                    <div
                      key={header.id}
                      style={{ gridColumnStart: index }}
                      className={clsx(
                        'group row-[1] mb-6 flex items-center font-bold text-content-base-default',
                        header.column.getCanSort() &&
                          'cursor-pointer gap-2 fill-input-content-focus',
                        !header.column.columnDef.meta?.isAddress &&
                          'justify-end',
                      )}
                      role="columnheader"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                      {header.column.getCanSort() ? (
                        <ChevronUp
                          cssClasses={clsx(
                            'group-hover:text-content-base-default group-active:text-content-accent-default group-focus:text-content-accent-default',
                            header.column.getIsSorted() === 'asc'
                              ? 'rotate-180 !text-content-accent-default'
                              : '',
                          )}
                          width="16px"
                          height="16px"
                          color="rgb(var(--content-base-subdued))"
                          onClick={header.column.getToggleSortingHandler()}
                        />
                      ) : null}
                      {header.column.columnDef.meta?.isProduct &&
                      header.column.columnDef.meta.product ? (
                        <ProductCheckbox
                          product={header.column.columnDef.meta.product}
                          openQuoteStore={openQuoteStore}
                        />
                      ) : null}
                    </div>
                  ))}
                </Fragment>
              ))}
              {table.getRowModel().rows.map((row, index, rows) => {
                return (
                  <Fragment key={row.id}>
                    <TableCell
                      style={{ gridRow: getRowIdx(index) }}
                      className="text-content-base-subdued"
                      $minWidth={0}
                    >
                      {table.getState().pagination.pageSize *
                        table.getState().pagination.pageIndex +
                        index +
                        1}
                    </TableCell>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        style={{ gridRow: getRowIdx(index) }}
                        $minWidth={0}
                        className={clsx(
                          !cell.column.columnDef.meta?.isAddress &&
                            'justify-end',
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                    {index + 1 < rows.length && (
                      <Divider
                        style={{
                          gridRow: getRowIdx(index) + 1,
                          gridColumnStart: 1,
                          gridColumnEnd: `span ${
                            row.getVisibleCells().length + 1
                          }`,
                        }}
                      />
                    )}
                  </Fragment>
                );
              })}
            </ProductsToLocationsTable>
            <div className="mt-10 flex">
              <Pagination
                variant="short"
                pageCount={table.getPageCount()}
                totalRows={openQuoteStore.locations.length || 0}
                currentPage={table.getState().pagination.pageIndex + 1 || 1}
                onPageChange={(page: number) => table.setPageIndex(page - 1)}
                currentRowsShown={table.getRowModel().rows.length}
                pageSizeChanged={(page: number) =>
                  table.setPageSize(Number(page))
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-4 rounded-b bg-background-base-surface-3 p-6">
        <Link to="../add-products" className="button large secondary w-[222px]">
          Back
        </Link>
        <Link to="../review" className="button large primary w-[222px]">
          Next
        </Link>
      </div>
    </>
  );
};
