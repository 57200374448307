/* eslint-disable react/prop-types */
import { useState } from 'react';
import { GraniteSelect, OptionType } from 'components/Select/Select';
import { SingleValue } from 'react-select';
import downArrow from 'assets/images/downArrow.svg';
import { DropdownIndicatorProps, GroupBase } from 'react-select';

interface CustomSelectControlProps {
  options: OptionType[];
  onChange?: (selectedOption: OptionType | null) => void;
}

const CustomSelectControl: React.FC<CustomSelectControlProps> = ({
  options,
  onChange,
}) => {
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(
    options[0],
  );

  const handleChange = (option: SingleValue<OptionType>) => {
    setSelectedOption(option);
    if (onChange) {
      onChange(option);
    }
  };

  return (
    <GraniteSelect
      value={selectedOption}
      //@ts-expect-error err
      onChange={handleChange}
      options={options}
      isSearchable={false}
      components={{
        DropdownIndicator: (
          props: DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>,
        ) => {
          const { isDisabled, menuIsOpen } = props.selectProps;
          return (
            <img
              src={downArrow}
              style={{
                filter:
                  isDisabled || !menuIsOpen
                    ? 'grayscale(100%) brightness(0.5)'
                    : 'none',
                transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          );
        },
      }}
      overrideStyles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: 'transparent',
          border: 'none',
          width: 'fit-content',
          fontSize: 14,
          color: '##94A3B8',
        }),
        menu: (provided) => ({
          ...provided,
          background: 'rgb(var(--input-background-unfilled))',
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          width: '200px',
          zIndex: 9999,
        }),
        menuList: (provided) => ({
          ...provided,
          maxHeight: 200,
          overflowY: 'auto',
          overflowX: 'hidden',
          borderColor: 'transparent',
          padding: 8,
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused
            ? 'rgb(var(--content-base-hover))'
            : 'rgb(var(--input-background-unfilled))',
          padding: '10px',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: '##94A3B8',
          '&:hover': { color: 'rgb(var(--content-base-default))' },
          '&:active': { color: 'rgb(var(--content-base-default))' },
          '&:focus': { color: 'rgb(var(--content-base-default))' },
        }),
      }}
    />
  );
};

export default CustomSelectControl;
