import { useQueryClient } from 'react-query';
import { useCallback } from 'react';

export const INVITE_TABLE_QK = ['invite-management-table'];

export const useInviteTableRefetch = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.refetchQueries({ queryKey: INVITE_TABLE_QK });
  }, [queryClient]);
};
