import { z } from 'zod';

export const RequestQuoteSchema = z.object({
  name: z.string().nonempty('Required'),
});

export type RequestQoute = z.infer<typeof RequestQuoteSchema>;

const QEOfferingBucketSchema = z.object({
  average_mrc: z.number(),
  bandwidth_max: z.number(),
  bandwidth_min: z.number(),
  bucket_category: z.string(),
  bucket_type: z.string(),
  id: z.string().uuid(),
  product_id: z.string().uuid(),
  selected: z.boolean(),
});

const ProductSchema = z.object({
  access_type: z.string(),
  exclude_carriers: z.string().nullable(),
  id: z.string().uuid(),
  include_carriers: z.string().nullable(),
  ip_block: z.string(),
  ip_type: z.string(),
  location_id: z.string().uuid(),
  max_download: z.string(),
  min_download: z.string(),
  preferred_carriers: z.string().nullable(),
  product_type: z.string(),
  more_options_required: z.boolean().nullable(),
  qe_offering_buckets: z.array(QEOfferingBucketSchema),
  term: z.string(),
});

const LocationSchema = z.object({
  address1: z.string(),
  address2: z.string().nullable(),
  city: z.string(),
  id: z.string().uuid(),
  products: z.array(ProductSchema),
  quote_id: z.string().uuid(),
  state: z.string(),
  zip_code: z.string(),
});

const ResponseSchema = z.object({
  business_unit: z.string(),
  company_id: z.number(),
  customer_name: z.string(),
  email: z.string().email(),
  error_message: z.string().nullable(),
  id: z.string().uuid(),
  locations: z.array(LocationSchema),
  qe_quote_created_at: z.string(),
  qe_quote_request_id: z.number(),
  quote_name: z.string().nullable(),
  quote_status: z.string(),
  requester: z.string(),
});

export { ResponseSchema };

export type QEOfferingBucketType = z.infer<typeof QEOfferingBucketSchema>;
export type ProductType = z.infer<typeof ProductSchema>;
export type LocationType = z.infer<typeof LocationSchema>;
export type ResponseType = z.infer<typeof ResponseSchema>;
