import { OptionType } from 'components/Select/Select';
import {
  AccountInformationForm,
  ChildAccountRequest,
  FullServiceDetailsFormType,
  InvoiceStyleEnumDefault,
  InvoiceStyleEnumForParentAccount,
  InvoiceStyleEnumForThisAccount,
} from './schemas';

export const states = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
  { name: 'American Samoa', abbreviation: 'AS' },
  { name: 'Guam', abbreviation: 'GU' },
  { name: 'Northern Mariana Islands', abbreviation: 'MP' },
  { name: 'Puerto Rico', abbreviation: 'PR' },
  { name: 'U.S. Virgin Islands', abbreviation: 'VI' },
];

export const childAccountFormToRequest = (
  data: FullServiceDetailsFormType & AccountInformationForm,
): ChildAccountRequest => {
  const sameAsAbove = data.billing_address;
  const payload = {
    local_contact_email: data.local_contact_email,
    parent_account: data.parent_account.value,
    parent_name: data.parent_account.subvalue,
    ship_country: 'USA',
    billing_type: data.billing_type.value,
    invoice_delivery_method: data.invoice_delivery_method.value,
    invoice_style: data.invoice_style.value,
    state: data.state.value,
    country: data.country.value,
    business_name: data.business_name,
    address_line_1: data.address_line_1,
    address_line_2: data.address_line_2,
    city: data.city,
    zip_code: data.zip_code,
    local_contact_name: data.local_contact_name,
    local_contact_phone_number: data.local_contact_phone_number,
    general_ledger: data.general_ledger,
    store_id: data.store_id,
    billing_business_name: sameAsAbove
      ? data.business_name
      : data.billing_business_name,
    billing_contact_name: sameAsAbove
      ? data.local_contact_name
      : data.billing_contact_name,
    billing_contact_phone_number: sameAsAbove
      ? data.local_contact_phone_number
      : data.billing_contact_phone_number,
    billing_address_line_1: sameAsAbove
      ? data.address_line_1
      : data.billing_address_line_1,
    billing_address_line_2: sameAsAbove
      ? data.address_line_2
      : data.billing_address_line_2,
    billing_city: sameAsAbove ? data.city : data.billing_city,
    billing_state: sameAsAbove ? data.state.value : data.billing_state?.value,
    billing_zip_code: sameAsAbove ? data.zip_code : data.billing_zip_code,
    billing_country: sameAsAbove
      ? data.country.value
      : data.billing_country?.value,
  };

  return payload;
};

export const getInvoiceStyleOptions = (selectedBillingType: OptionType) => {
  if (selectedBillingType?.value === '4') {
    return InvoiceStyleEnumForParentAccount.options.map((style) => ({
      label: style,
      value: style,
    }));
  } else if (selectedBillingType?.value === '5') {
    return InvoiceStyleEnumForThisAccount.options.map((style) => ({
      label: style,
      value: style,
    }));
  } else {
    return InvoiceStyleEnumDefault.options.map((style) => ({
      label: style,
      value: style,
    }));
  }
};

export const getTooltipContent = (selectedBillingType: OptionType) => {
  const ListItem = ({
    label,
    description,
  }: {
    label: string;
    description: string;
  }) => (
    <li className="pl-0">
      <span className="font-bold">{label}: </span> {description}
    </li>
  );

  const tooltipData = {
    summaryBill: {
      label: 'Summary Bill',
      description:
        'displays a detailed breakdown of charges for each location.',
    },
    networkIntegration: {
      label: 'Network Integration only',
      description:
        'for NI only accounts. Non-NI details will not be displayed but amounts will be included.',
    },
    noSiteInvoice: {
      label: 'No Site invoice',
      description: 'no invoice will be sent via mail or email.',
    },
    iwOnly: {
      label: 'IW Only',
      description:
        'is for IW only accounts. Non-IW details will not be displayed but amounts will be included.',
    },
    childNoBill: {
      label: 'Child-No Bill',
      description: 'no invoice will be sent via mail or email.',
    },
  };

  const billingType = selectedBillingType?.value;

  const commonItems = [ListItem({ ...tooltipData.summaryBill })];

  if (billingType === '4') {
    commonItems.push(
      ListItem(tooltipData.networkIntegration),
      ListItem(tooltipData.noSiteInvoice),
    );
  }

  if (billingType === '5') {
    commonItems.push(ListItem(tooltipData.networkIntegration));
  }

  if (!billingType) {
    commonItems.push(
      ListItem(tooltipData.iwOnly),
      ListItem(tooltipData.childNoBill),
    );
  }

  if (billingType !== '4' && billingType !== '5' && billingType) {
    commonItems.push(
      ListItem(tooltipData.iwOnly),
      ListItem(tooltipData.childNoBill),
    );
  }

  return (
    <ul className="ml-[20px] list-outside list-disc text-left text-base text-content-base-subdued">
      {commonItems}
    </ul>
  );
};
