import { ComponentProps, forwardRef } from 'react';
import { clsx } from 'clsx';

export interface GraniteButtonProps extends ComponentProps<'button'> {
  variant?:
    | 'primary'
    | 'secondary'
    | 'ghost'
    | 'destructive'
    | 'destructive-secondary'
    | 'tertiary'
    | 'unstyled';

  size?: 'small' | 'medium' | 'large';
}

export const GraniteButton = forwardRef<HTMLButtonElement, GraniteButtonProps>(
  function GraniteButton(
    {
      variant = 'primary',
      size = 'medium',
      className,
      type = 'button',
      ...props
    },
    ref,
  ) {
    return (
      <button
        className={clsx(
          'button box-border',
          variant !== 'unstyled' && variant,
          size,
          className,
        )}
        ref={ref}
        type={type}
        {...props}
      >
        {props.children}
      </button>
    );
  },
);
