import { zodResolver } from '@hookform/resolvers/zod';
import { Modal } from 'components/Modal/Modal';
import { GraniteSelect } from 'components/Select/Select';
import TextAreaGranite from 'components/TextArea/TextArea';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { FileLike, FileUpload } from 'components/V2/FileUpload/FileUpload';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { ModalParams } from 'hooks/useModal';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Checkbox from 'components/Checkbox';
import { removeDuplicatesFromArray } from 'shared/util/util';
import {
  Resource,
  ResourceForm,
  ResourceFormSchema,
  TagAPIResponse,
  acceptedFileTypes,
} from 'api/resources/schema';
import Loader from 'components/Loader';

export interface EditResourceProps {
  tags?: TagAPIResponse;
  dynamicProps?: Resource;
  onSubmit: (data: ResourceForm) => void;
  isLoading: boolean;
}

export interface CombinedProps extends EditResourceProps, ModalParams {}

export const EditResourceDialog = ({
  tags,
  isOpen,
  close,
  dynamicProps: resource,
  onSubmit: _onSubmit,
  isLoading,
}: CombinedProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitting },
    reset,
    setError,
  } = useForm<ResourceForm>({
    resolver: zodResolver(ResourceFormSchema),
  });

  const [selectedFiles, setSelectedFiles] = useState<FileLike[]>(
    resource ? [{ name: resource.filename }] : [],
  );

  useEffect(() => {
    reset({
      id: resource?.id,
      title: resource?.title,
      is_suggested: resource?.is_suggested,
      description: resource?.description ? resource.description : undefined,
      tags: resource?.tags?.map(({ name }) => ({ label: name, value: name })),
    });
    setSelectedFiles(resource ? [{ name: resource.filename }] : []);
  }, [reset, resource, isOpen]);

  const onError = (err: unknown) => console.log(err);

  const onSubmit = (data: ResourceForm) => {
    if (!data.attachments || data.attachments?.length === 0) {
      return setError('attachments', {
        type: 'required',
        message: 'File required',
      });
    }
    _onSubmit(data);
  };

  return (
    <Modal
      enableScrolling={true}
      isVisible={isOpen}
      close={
        isLoading
          ? () => {
              return;
            }
          : close
      }
      className="w-full max-w-[836px]"
    >
      <div className="flex flex-col gap-2 rounded-lg rounded-t-lg bg-background-base-surface-2">
        <div className="flex w-full flex-col  items-start  justify-start gap-1 bg-background-base-surface-3 px-8 py-6">
          <h1 className="rounded-t-lg text-[28px] font-bold leading-9 text-content-base-default">
            {resource ? 'Edit resource' : 'Upload new resource'}
          </h1>
          <p className="text-base font-semibold text-content-base-subdued">
            All Granite360 members will have access to this resource.
          </p>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          className="flex w-full flex-col items-start justify-start gap-4 p-8"
        >
          <Controller
            name="is_suggested"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <Checkbox
                label={'Categorize file as “Suggested” '}
                checked={value}
                onChange={onChange}
                {...field}
              />
            )}
          />
          <GraniteInput
            {...register('title')}
            className="w-full"
            placeholder="Title of file"
            label="Resource title"
            error={errors.title?.message}
          />
          <FileUpload
            label="Select file"
            accept={acceptedFileTypes.join(',')}
            value={selectedFiles}
            error={errors.attachments?.message}
            onChange={(files) => {
              setSelectedFiles(files);
              setValue('attachments', [...files]);
            }}
          />
          <TextAreaGranite
            {...register('description')}
            className="w-full"
            label="Description"
            placeholder="Add description of file and its use case"
            error={errors.description?.message}
            subtitle="Max 100 characters"
          />
          <Controller
            name="tags"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <GraniteSelect
                {...field}
                label="Tags"
                isMulti
                error={errors.tags?.message}
                options={removeDuplicatesFromArray([
                  ...(tags?.map(({ name }) => ({
                    label: name,
                    value: name,
                  })) ?? []),
                ])}
                value={value}
                onChange={(selectedOptions) => {
                  if (selectedOptions.length > 3) {
                    return;
                  }
                  onChange(selectedOptions);
                }}
              />
            )}
          />

          <div className="mt-8 flex items-center justify-start gap-5">
            <GraniteButton size="large" variant="secondary" onClick={close}>
              Cancel
            </GraniteButton>
            <GraniteButton
              disabled={isSubmitting || isLoading}
              type="submit"
              size="large"
              variant="primary"
            >
              Upload
              {isLoading && <Loader animationClassname="!w-3 !h-3" />}
            </GraniteButton>
          </div>
        </form>
      </div>
    </Modal>
  );
};
