import React, { ComponentProps } from 'react';
import { TextLegend, Circle } from './Charts.styles';
import { clsx } from 'clsx';

export interface LegendData extends ComponentProps<'div'> {
  x: string;
  backgroundColor: string;
  active?: boolean;
}

const Legend: React.FC<LegendData> = ({
  backgroundColor,
  x,
  active,
  ...props
}) => {
  return (
    <div
      className="flex w-full items-center justify-between gap-2 border-b py-1 last:border-none hover:text-content-base-default sm:w-40 sm:border-none"
      {...props}
    >
      <div className="flex items-center justify-start gap-2">
        <Circle fill={backgroundColor} />
        <TextLegend
          className={clsx(
            'hover:text-content-base-default',
            active ? 'text-content-base-default' : 'text-content-base-subdued',
          )}
        >
          {x}
        </TextLegend>
      </div>
    </div>
  );
};

export default Legend;
