import Loader from 'components/Loader';
import { IframeHTMLAttributes, useEffect, useState } from 'react';

export const Pdf = ({
  src,
  className,
}: IframeHTMLAttributes<HTMLIFrameElement>) => {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchAndRenderPdf = async () => {
      fetch(src ?? '')
        .then(async (response) => {
          const arrayBuffer = await response.arrayBuffer();
          const pdfBlob = new Blob([arrayBuffer], { type: 'application/pdf' });
          const url = URL.createObjectURL(pdfBlob);
          setPdfUrl(`${url}#toolbar=0&navpanes=0`);
        })
        .catch((error) => {
          console.error('Error fetching and rendering PDF:', error);
        });
    };
    if (!pdfUrl) fetchAndRenderPdf();
    return () => {
      if (pdfUrl) URL.revokeObjectURL(pdfUrl);
    };
  }, [src, pdfUrl]);

  return pdfUrl ? (
    <iframe className={className} src={pdfUrl} />
  ) : (
    <Loader className={className} />
  );
};
