import { useEffect } from 'react';
import { getEstimatedCost } from 'api/techexpress/api';
import {
  AnyTicketType,
  ProductType,
  labelForTicketType,
  netNameForProductType,
} from '../BaseComponents/schemas';
import { EstimatedCostProps, PriorityItem } from './Review';

interface Props {
  priorities: PriorityItem[];
  ticketType: AnyTicketType;
  product: ProductType;
  setEstimatedCosts: (value: EstimatedCostProps) => void;
}

export const useFetchEstimatedCosts = ({
  priorities,
  ticketType,
  product,
  setEstimatedCosts,
}: Props) => {
  useEffect(() => {
    const fetchAndSetCosts = async () => {
      const validPriorities = priorities.filter(
        (p) => p.priority !== undefined,
      );

      const costPromises = validPriorities.map(({ priority, index }) =>
        getEstimatedCost({
          board_name: netNameForProductType(product),
          sub_type_name: priority as 'P1' | 'P2' | 'P3',
          type_name: labelForTicketType(ticketType),
        }).then((cost) => ({ cost, index, priority })),
      );

      try {
        const costs = await Promise.all(costPromises);

        const costsIndexedByLocation = costs.reduce(
          (acc: EstimatedCostProps, { cost, priority }) => {
            acc[priority] = acc[priority] || [];
            acc[priority].push({ ...cost });

            return acc;
          },
          {} as EstimatedCostProps,
        );

        setEstimatedCosts(costsIndexedByLocation);
      } catch (error) {
        console.error('Failed to fetch estimated costs:', error);
      }
    };

    if (priorities.length > 0) {
      fetchAndSetCosts();
    }
  }, [priorities, ticketType, product, setEstimatedCosts]);
};
