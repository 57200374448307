import { z } from 'zod';
import {
  ContactSubFormSchema,
  NonStandardToolsFieldSchema,
  OptionLabelValueSchema,
} from '../../utils/schemas';
import { TicketDetailForms } from '../DefinedFormTypes';
import {
  VALID_NUMBERS_ERROR_LABEL,
  VALID_PHONE_ERROR_LABEL,
} from '../../../../shared/constants/error-labels';
import {
  NUMBERS_ONLY_REGEXP,
  VALID_PHONE_NUMBER,
} from '../../../../shared/constants/validation-regex-constants';

export const ExtensionType = z.enum([
  'NetworkRack',
  'PatchPanelPort',
  'JackIn',
]);

export const BaseExtensionFormSchema = z.object({
  mpoeLocation: z.string(),
  extensionLength: z
    .string()
    .nonempty(VALID_NUMBERS_ERROR_LABEL)
    .regex(NUMBERS_ONLY_REGEXP, VALID_NUMBERS_ERROR_LABEL),
  techToTestWith: z.discriminatedUnion('techToTestWith', [
    z.object({ techToTestWith: z.literal('CustomerIT') }),
    z.object({ techToTestWith: z.literal('Person') }),
    z.object({ techToTestWith: z.literal('No') }),
  ]),
  itTestingContacts: z.array(ContactSubFormSchema),
  nonStandardTools: NonStandardToolsFieldSchema,
});

export const ExtensionPOTSFormSchema = BaseExtensionFormSchema.merge(
  z.object({
    formType: z.literal(TicketDetailForms.enum.ExtensionPOTS),
    telephoneNumber: z
      .string()
      .nonempty(VALID_PHONE_ERROR_LABEL)
      .length(10, VALID_PHONE_ERROR_LABEL)
      .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
    extension: z.discriminatedUnion('value', [
      z.object({
        value: z.literal(ExtensionType.enum.NetworkRack),
        label: z.string(),
      }),
      z.object({
        value: z.literal(ExtensionType.enum.PatchPanelPort),
        label: z.string(),
      }),
      z.object({
        value: z.literal(ExtensionType.enum.JackIn),
        label: z.string(),
        location: z.string().nonempty('Required'),
      }),
    ]),
  }),
);

export type ExtensionPOTSForm = z.infer<typeof ExtensionPOTSFormSchema>;

export const ExtensionCircuitFormSchema = BaseExtensionFormSchema.merge(
  z.object({
    formType: z.literal(TicketDetailForms.enum.ExtensionCircuit),
    serviceDescription: z.string(),
    extension: OptionLabelValueSchema,
    downloadSpeed: z
      .string()
      .trim()
      .regex(NUMBERS_ONLY_REGEXP, VALID_NUMBERS_ERROR_LABEL)
      .or(z.literal(''))
      .or(z.undefined()),
    handoffType: OptionLabelValueSchema.or(z.undefined()),
  }),
);

export type ExtensionCircuitForm = z.infer<typeof ExtensionCircuitFormSchema>;
