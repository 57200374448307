import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { useFormContext } from 'react-hook-form';
import { RoleFormType } from 'screens/RolesPermissions/roles-permissions.types';

const Form = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<RoleFormType>();

  return (
    <div className="space-y-6">
      <GraniteInput
        {...register('name')}
        label="Role"
        subtitle="Role names cannot include any spaces or special characters besides hyphen and underscore."
        error={errors.name?.message}
        placeholder="E.g., viewer, early-adopter, etc."
      />
      <GraniteInput
        {...register('description')}
        label="Description"
        subtitle="Describe the purpose of this new role."
        error={errors.description?.message}
        placeholder="E.g., has view-only permissions to all pillars"
      />
    </div>
  );
};

export default Form;
