import {
  FixMyServiceGraniteServices,
  FixMyServiceSubForm,
  GetStartedQuestionnaireBaseForm,
  GetStartedQuestionnaireForm,
  GraniteNotOwnedServiceSubForm,
  GraniteOwnedServiceSubForm,
  MyServiceIssues,
  WhyTechnicianEnum,
} from '../GetStarted/schemas';
import {
  MACTicketTypes,
  ProductTypes,
  RepairTicketTypes,
  ticketTypeBoardOf,
  ValidTicketTypeProductPair,
} from '../BaseComponents/schemas';

const getTicketTypeProductForFixMyServiceGraniteOwned = (
  questionnaire: GraniteOwnedServiceSubForm,
) => {
  switch (questionnaire.service.which) {
    case FixMyServiceGraniteServices.enum.BroadbandCircuit:
      return ticketTypeBoardOf({
        product: ProductTypes.enum.FieldServicesRepair,
        ticketType: RepairTicketTypes.enum.BroadbandCircuit,
      });
    case FixMyServiceGraniteServices.enum.POTSLine:
      return ticketTypeBoardOf({
        product: ProductTypes.enum.FieldServicesRepair,
        ticketType: RepairTicketTypes.enum.POTS,
      });
    case FixMyServiceGraniteServices.enum.Wiring:
      switch (questionnaire.service.copperOrFiber) {
        case 'Copper': {
          return ticketTypeBoardOf({
            product: ProductTypes.enum.FieldServicesRepair,
            ticketType: RepairTicketTypes.enum.CircuitExtension,
          });
        }
        case 'Fiber': {
          return ticketTypeBoardOf({
            product: ProductTypes.enum.FieldServicesRepair,
            ticketType: RepairTicketTypes.enum.FiberExtension,
          });
        }
      }
  }
};

const getTicketTypeProductForFixMyServiceNotOwned = (
  questionnaire: GraniteNotOwnedServiceSubForm,
) => {
  switch (questionnaire.service.which) {
    case MyServiceIssues.enum.POTSLine:
      return ticketTypeBoardOf({
        product: ProductTypes.enum.FieldServicesRepair,
        ticketType: RepairTicketTypes.enum.POTS,
      });
    case MyServiceIssues.enum.Circuit:
      return ticketTypeBoardOf({
        product: ProductTypes.enum.FieldServicesRepair,
        ticketType: RepairTicketTypes.enum.BroadbandCircuit,
      });
    case MyServiceIssues.enum.CustomerProvidedEquipment:
      return ticketTypeBoardOf({
        product: ProductTypes.enum.FieldServicesRepair,
        ticketType: RepairTicketTypes.enum.CustomerEquipment,
      });
    case MyServiceIssues.enum.MaterialReplacement:
      return ticketTypeBoardOf({
        product: ProductTypes.enum.FieldServicesRepair,
        ticketType: RepairTicketTypes.enum.MaterialReplacement,
      });
    case MyServiceIssues.enum.Wiring:
      switch (questionnaire.service.extensionType) {
        case 'Copper': {
          return ticketTypeBoardOf({
            product: ProductTypes.enum.FieldServicesRepair,
            ticketType: RepairTicketTypes.enum.CircuitExtension,
          });
        }
        case 'Fiber': {
          return ticketTypeBoardOf({
            product: ProductTypes.enum.FieldServicesRepair,
            ticketType: RepairTicketTypes.enum.FiberExtension,
          });
        }
      }
  }
};

const getTicketTypeProductForFixMyService = (
  questionnaire: FixMyServiceSubForm,
) => {
  if (questionnaire.graniteOwned.isGraniteOwned) {
    return getTicketTypeProductForFixMyServiceGraniteOwned(
      questionnaire.graniteOwned,
    );
  } else {
    return getTicketTypeProductForFixMyServiceNotOwned(
      questionnaire.graniteOwned,
    );
  }
};

export const getInternalTicketTypeAndProductNamesFromQuestionnaire = (
  questionnaire: GetStartedQuestionnaireBaseForm | GetStartedQuestionnaireForm,
): ValidTicketTypeProductPair => {
  switch (questionnaire.whyTechnician) {
    case WhyTechnicianEnum.enum.FixMyService: {
      return getTicketTypeProductForFixMyService(questionnaire);
    }
    case WhyTechnicianEnum.enum.MoveEquipmentOrWiring: {
      return ticketTypeBoardOf({
        product: ProductTypes.enum.FieldServicesMAC,
        ticketType: MACTicketTypes.enum.Move,
      });
    }
    case WhyTechnicianEnum.enum.OnsiteTechSupport: {
      return ticketTypeBoardOf({
        product: ProductTypes.enum.FieldServicesMAC,
        ticketType: MACTicketTypes.enum.SmartHands,
      });
    }
    case WhyTechnicianEnum.enum.SiteAudit: {
      return ticketTypeBoardOf({
        product: ProductTypes.enum.FieldServicesMAC,
        ticketType: MACTicketTypes.enum.SiteAudit,
      });
    }
    case WhyTechnicianEnum.enum.EquipmentInstall: {
      return ticketTypeBoardOf({
        product: ProductTypes.enum.FieldServicesMAC,
        ticketType: MACTicketTypes.enum.EquipmentInstall,
      });
    }
    case WhyTechnicianEnum.enum.RunExtension: {
      if (questionnaire.potsOrCircuit === 'POTS') {
        return ticketTypeBoardOf({
          product: ProductTypes.enum.FieldServicesMAC,
          ticketType: MACTicketTypes.enum.POTSExtension,
        });
      } else {
        return ticketTypeBoardOf({
          product: ProductTypes.enum.FieldServicesMAC,
          ticketType: MACTicketTypes.enum.CircuitExtension,
        });
      }
    }
  }
};
