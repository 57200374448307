import {
  addMinutes,
  getHours,
  getMinutes,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';
import { ReviewTicketForm } from './schemas';
import { CreateTicketRequest } from '../../../api/techexpress/schema';
import { zonedTimeOfZipCodeToUtc } from '../../../shared/util/zonedTimeOfZipCodeToUtc';
import { dateToUTCWithoutMS } from '../../../shared/util/dateToUtcStrWithoutMS';
import { mergeDateTimeParts } from 'shared/util/mergeDateTimeParts';
import { DispatchSchedule } from '../../../api/techexpress/schemas/SiteDispatchDateScheduleSchemas';
import getSummaryFieldValue from '../utils/getSummaryFieldValue';
import calculateDispatchDate from 'shared/util/calculateDispatchDate';

export type LocationItemRequest = {
  sub_type_name: string;
  local_contact_number: string;
  location_name: string;
  site: number;
  summary: string;
  local_contact_name: string;
};

export const getEndTimeDate = (
  accessTime: DispatchSchedule,
  dispatchDate: Date,
) => {
  const endTime =
    accessTime.scheduling_type === 'Hard Start'
      ? addMinutes(accessTime.start_date, 1)
      : accessTime.end_date;

  return mergeDateTimeParts(dispatchDate, endTime);
};

export const reviewTicketFormToRequest = (
  formData: ReviewTicketForm,
  ticketType: string,
  productType: string,
  priority: 'P1' | 'P2' | 'P3',
  creatorEmailAddress: string,
  creatorName?: string,
) => {
  const parsed_prior_ticket = formData.prior_ticket
    ? parseInt(formData.prior_ticket)
    : undefined;
  const requestData: CreateTicketRequest = {
    locations:
      formData.type === 'single'
        ? [
            {
              site: formData.site.id,
              sub_type_name: priority,
              location_name:
                formData.content.type === 'single'
                  ? formData.content.locationName
                  : '',
              ...(formData.content.type === 'single' &&
                formData.content.locationName && {
                  location_number: formData.content.locationNumber,
                }),
              ...(formData.type === 'single' && {
                ...(formData.content.type === 'single' && {
                  local_contact_name: formData.content.localContact[0].name,
                  local_contact_number:
                    formData.content.localContact[0].phoneNumber,
                  ...(formData.content.localContact[0].extension && {
                    location_contact_ext:
                      formData.content.localContact[0].extension,
                  }),
                  location_contact_email:
                    formData.content.localContact[0].email,
                }),
              }),
              ...(formData.type === 'single' && {
                ...(formData.content.type === 'single' && {
                  ...(formData.content.localContact.length > 1
                    ? {
                        secondary_lcon_name:
                          formData.content.localContact[1].name,
                        secondary_lcon_email:
                          formData.content.localContact[1].email,
                        secondary_lcon_number:
                          formData.content.localContact[1].phoneNumber,
                        secondary_lcon_ext:
                          formData.content.localContact[1].extension,
                      }
                    : {}),
                }),
              }),
              summary: getSummaryFieldValue({
                ticketType: ticketType,
                priority: formData.priority,
                dispatchDate: formData.dispatchDate,
                accessTime: {
                  start_date: formData.accessTime.start_date,
                  scheduling_type: formData.accessTime.scheduling_type,
                  end_date:
                    formData.accessTime.scheduling_type === 'Hard Start'
                      ? undefined
                      : formData.accessTime.end_date,
                },
                zip: formData.site.zip,
              }),
              scheduling_type: formData.accessTime.scheduling_type,
              start_date: dateToUTCWithoutMS(
                zonedTimeOfZipCodeToUtc(
                  setHours(
                    setMinutes(
                      formData.dispatchDate,
                      getMinutes(formData.accessTime.start_date),
                    ),
                    getHours(formData.accessTime.start_date),
                  ),
                  formData.site.zip,
                ),
              ),

              end_date: dateToUTCWithoutMS(
                zonedTimeOfZipCodeToUtc(
                  getEndTimeDate(formData.accessTime, formData.dispatchDate),
                  formData.site.zip,
                ),
              ),
            },
          ]
        : formData.content.type === 'multi'
          ? formData?.content?.locations?.map((item) => {
              const dispatchDate = new Date(
                calculateDispatchDate({
                  priority: item.priority.value as 'P1' | 'P2' | 'P3',
                  zipcode: formData.site.zip,
                }),
              );
              const startDate = setSeconds(
                setMinutes(setHours(dispatchDate, 9), 0),
                0,
              );
              const endDate = setSeconds(
                setMinutes(setHours(dispatchDate, 17), 0),
                0,
              );

              return {
                sub_type_name: item.priority.value,
                location_name: item.locationName,
                location_number: item.locationNumber,
                local_contact_name: item.local_contact_name,
                local_contact_number: item.local_contact_number,
                site: item.site.id,
                summary: `${ticketType} | ${item.priority.label}`,
                scheduling_type: 'Requested Window',
                start_date: dateToUTCWithoutMS(
                  zonedTimeOfZipCodeToUtc(startDate, item.site.zip),
                ),
                end_date: dateToUTCWithoutMS(
                  zonedTimeOfZipCodeToUtc(endDate, item.site.zip),
                ),
              };
            })
          : [],
    board_name: productType,
    contact_name: creatorName ?? 'N/A',
    contact_email_address: creatorEmailAddress,
    type_name: ticketType,
    initial_description:
      formData.scopeOfWork + '\n\n' + formData.scopeOfWorkInstructions,

    automatic_email_contact: !!formData.isSendNotificationsViaEmailEnabled,
    automatic_email_cc: formData.recipients.length > 0,
    automatic_email_cc_address: formData.recipients
      .map((r) => r.email)
      .join(';'),
    customer_ticket_number: formData.customer_ticket_number,
    customer_preferred_tech: formData.customer_preferred_tech,
    prior_ticket: parsed_prior_ticket,
    special_instructions: formData.specialInstructions,

    po_number: formData.po_number,
    non_standard_tools: formData.nonStandardTools?.isNeeded
      ? formData.nonStandardTools.nonStandardTools
      : undefined,
  };
  const openTicketFormData = new FormData();
  Object.entries(requestData).forEach(([key, value]) => {
    if (value === undefined || value === '') return;
    if (typeof value === 'object' && value !== null) {
      openTicketFormData.append(key, JSON.stringify(value));
    } else {
      openTicketFormData.append(key, `${value}`);
    }
  });
  formData.attachments?.forEach((file) => {
    openTicketFormData.append('file', file);
  });

  return openTicketFormData;
};
