import { useEffect, useState } from 'react';
import { Switch as HeadlessSwitch } from '@headlessui/react';
import clsx from 'clsx';

const Switch = ({
  disabled = false,
  label,
  onChange,
  isOn = false,
  labelClassName,
  size = 'default',
  classNames,
  indeterminate,
}: {
  disabled?: boolean;
  label?: string;
  onChange: (value: boolean) => void;
  isOn?: boolean;
  size?: 'default' | 'small';
  labelClassName?: string;
  classNames?: string;
  indeterminate?: boolean;
}) => {
  const [on, setOn] = useState(isOn);
  const handleOnChange = (value: boolean) => {
    setOn(value);
    onChange(value);
  };
  useEffect(() => {
    setOn(isOn);
  }, [isOn]);

  return (
    <HeadlessSwitch.Group>
      <div className={clsx(`flex items-center justify-between`, classNames)}>
        {label && (
          <HeadlessSwitch.Label
            className={clsx('mr-4 font-normal', labelClassName)}
            passive
          >
            {label}
          </HeadlessSwitch.Label>
        )}
        <HeadlessSwitch
          checked={on}
          disabled={disabled}
          onChange={handleOnChange}
          className={clsx(
            `${
              (on || indeterminate) && !disabled
                ? 'bg-input-background-selected'
                : disabled
                  ? 'bg-input-stroke-disabled'
                  : 'bg-input-stroke-unfilled'
            } inline-flex h-6 w-11 items-center rounded-full border-opacity-[.5] py-[6px] hover:border-input-background-selected`,
            size === 'small' && '!h-[18px] !w-8',
          )}
        >
          <span
            className={clsx(
              'inline-block transform rounded-full transition',
              disabled
                ? 'bg-button-background-primary-disabled'
                : 'bg-background-base-surface-0',
              size === 'default' ? 'h-4 w-4' : 'h-[14px] w-[14px]',
              indeterminate && size === 'default' && 'translate-x-3.5',
              indeterminate && size === 'small' && 'translate-x-[9px]',
              on && size === 'default' && 'translate-x-6',
              on && size === 'small' && 'translate-x-4',
              // size === 'small' &&
              //   `${on ? '!translate-x-4' : '!translate-x-0.5'} `,
              !on && !indeterminate && size === 'default' && 'translate-x-1',
              !on && !indeterminate && size === 'small' && 'translate-x-0.5',
            )}
          >
            {indeterminate && (
              <span className="flex h-full w-full items-center justify-center px-1">
                <span className="h-0.5 w-full rounded-full bg-input-background-selected" />
              </span>
            )}
          </span>
        </HeadlessSwitch>
      </div>
    </HeadlessSwitch.Group>
  );
};

export default Switch;
