import { CloseCircle } from 'react-ionicons';
import { clsx } from 'clsx';

export interface ChipProps {
  className?: string;
  label: string;
  onDelete?: () => void;
}

export const Chip = ({ className, label, onDelete }: ChipProps) => {
  return (
    <div
      className={clsx(
        'flex items-center rounded-[32px] border border-stroke-base-subdued bg-background-base-surface-3 py-1 pl-3 pr-2',
        className,
      )}
    >
      <span className="mr-2 text-base text-input-content-filled">{label}</span>
      {onDelete && (
        <button onClick={onDelete}>
          <CloseCircle
            width="16px"
            height="16px"
            color="rgb(var(--content-base-subdued))"
          />
        </button>
      )}
    </div>
  );
};
