import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ModalParams } from 'hooks/useModal';

export const POTSTicketSubmissionDialog = ({ isOpen, close }: ModalParams) => {
  return (
    <Modal isVisible={isOpen} close={close}>
      <div className="max-w-[624px] p-8">
        <h1 className="m-0 mb-2 text-2xl font-bold text-content-base-default">
          POTS repair ticket submitted!
        </h1>
        <p className="mb-8 text-base font-bold text-content-base-subdued">
          Your ticket details have been forwarded to Granite’s POTS repair team.
          You can access this ticket through Granite’s Rock Reports portal until
          POTS repairs are integrated into Granite360.
        </p>
        <div className="flex gap-4">
          <GraniteButton variant="primary" size="large" onClick={close}>
            Close
          </GraniteButton>
        </div>
      </div>
    </Modal>
  );
};
