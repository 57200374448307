import { Resource } from 'api/resources/schema';
import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';

export const DeleteResourceDialog = ({
  isOpen,
  close,
  handleConfirm,
  dynamicProps,
}: {
  isOpen: boolean;
  handleConfirm: (data: Resource) => void;
  close: () => void;
  dynamicProps?: Resource;
}) => {
  return (
    <Modal isVisible={isOpen} close={close} className="max-w-[624px]">
      <div className="flex flex-col gap-8 rounded-t-lg bg-background-base-surface-2 p-8">
        <div className="flex flex-col items-start justify-start gap-2">
          <p className="text-2xl font-bold  text-content-base-default">
            Are you sure you want to delete this file?
          </p>
          <p className="medium text-base font-bold text-content-base-subdued">
            This file will no longer be available to all Granite360 members.
          </p>
        </div>
        <div className="flex gap-4">
          <GraniteButton size="large" variant="secondary" onClick={close}>
            Cancel
          </GraniteButton>
          <GraniteButton
            size="large"
            variant="destructive"
            onClick={() => handleConfirm(dynamicProps!)}
          >
            Delete
          </GraniteButton>
        </div>
      </div>
    </Modal>
  );
};
