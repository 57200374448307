import { NotificationType, Pillar } from 'components/Notifications/utils';
import { apiClient } from '../apiClient';
import {
  Notification,
  NotificationSetting,
  NotificationRequest,
  NotificationPreference,
  PatchNotificationPreferences,
} from './schema';

export const getNotifications = async (
  unread_only: boolean = false,
  notification_type: NotificationType = 'all',
  pillar: Pillar = 'all',
  page: number = 1,
): Promise<NotificationRequest> => {
  const params: {
    pillar?: typeof pillar;
    notification_type?: typeof notification_type;
    read?: boolean;
    page: number;
  } = { page };
  if (pillar !== 'all') params.pillar = pillar;
  if (notification_type !== 'all') params.notification_type = notification_type;
  if (unread_only === true) params.read = false;
  const response = await apiClient.get('/api/v1/notifications/', { params });
  return response.data;
};

export const getNotificationEvents = async (
  pillar: Pillar = 'all',
  notification_type: NotificationType = 'all',
  page: number = 1,
): Promise<NotificationRequest> => {
  const params: {
    pillar?: typeof pillar;
    notification_type?: typeof notification_type;
    page: number;
  } = { page };
  if (pillar !== 'all') params.pillar = pillar;
  if (notification_type !== 'all') params.notification_type = notification_type;
  const response = await apiClient.get('/api/v1/notifications/events', {
    params,
  });
  return response.data;
};

export const markNotification = async (
  id: string,
  read: boolean,
): Promise<Notification> => {
  const response = await apiClient.patch(`/api/v1/notifications/mark/${id}`, {
    read,
  });
  return response.data;
};

export const markAllNotifications = async (
  read: boolean,
  pillar: Pillar = 'all',
): Promise<Notification> => {
  const data: { read: boolean; pillar?: typeof pillar } = { read };
  if (pillar !== 'all') data.pillar = pillar;
  const response = await apiClient.patch(
    `/api/v1/notifications/mark-all`,
    data,
  );
  return response.data;
};

export const getNotificationPreferences = async (
  pillar: Pillar,
): Promise<NotificationPreference[]> => {
  const response = await apiClient.get(`/api/v1/notifications/preferences`, {
    params: {
      pillar,
    },
  });
  return response.data;
};

export const getNotificationSettings = async (): Promise<
  NotificationSetting[]
> => {
  const response = await apiClient.get(`/api/v1/notifications/settings`);
  return response.data;
};

export const patchNotificationPreferences = async (
  data: PatchNotificationPreferences,
): Promise<NotificationPreference[]> => {
  const response = await apiClient.patch(
    `/api/v1/notifications/preferences`,
    data,
  );
  return response.data;
};

export const resetNotificationPreferences = (pillar: Pillar) => {
  return apiClient.delete(`/api/v1/notifications/preferences/reset/${pillar}`);
};
