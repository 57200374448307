import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import Logo from 'components/Logo';
import { useNavigate } from 'react-router-dom';

export const SessionExpired = () => {
  const navigate = useNavigate();
  return (
    <div className="m-auto h-screen w-screen bg-background-base-surface-1">
      <Modal
        isVisible={true}
        close={close}
        className="w-[624px]"
        hideCloseButton
      >
        <div className="flex flex-col items-start gap-12 rounded bg-background-base-surface-2 px-8 py-6">
          <Logo />
          <div>
            <h1 className="mb-1 rounded-t-lg text-[36px] font-bold leading-9 text-content-base-default">
              Your session has expired.
            </h1>
            <p className="text-xl font-semibold">
              Your session has expired. Please log in again to continue using
              Granite360.
            </p>
          </div>
          <div className="mt-4 flex gap-5">
            <GraniteButton
              size="large"
              variant="primary"
              onClick={() => navigate('/')}
            >
              Back to login
            </GraniteButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};
