import { makeFieldsOptional } from 'api/schema-utils';
import { ZodTypeAny } from 'zod';
import {
  FieldValues,
  Resolver,
  ResolverOptions,
  ResolverResult,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

const createGetStartedReviewResolver = <T extends FieldValues>(
  schema: ZodTypeAny,
): Resolver<T> => {
  return async (
    data: T,
    context: unknown = {},
    //@ts-expect-error err
    options: ResolverOptions<T> = {},
  ): Promise<ResolverResult<T>> => {
    let modifiedSchema = schema;
    if (data.type === 'multi') {
      modifiedSchema = makeFieldsOptional(modifiedSchema, [
        'dispatchDate',
        'accessTime.start_date',
      ]);
    }

    return zodResolver(modifiedSchema)(data, context, options);
  };
};

export default createGetStartedReviewResolver;
