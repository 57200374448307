import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  SortingState,
  Table,
  useReactTable,
} from '@tanstack/react-table';
import {
  DefaultTableContainer,
  TableCell,
  TableHeaderCell,
  TableRow,
} from './Table.styles';
import Pagination from './Pagination';
import { utils, writeFile } from 'xlsx';
import { ChevronDown, Download, FileTray } from 'react-ionicons';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { formatDate } from 'screens/TechExpress/TechExpress';
import clsx from 'clsx';
import { isAfterDate } from './filters';
import { DataLoading } from './ServerPaginatedTable';
import { NoResultSvg } from './NoResultSvg';

interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  title: string;
  handleRowClick?: (row: T) => void;
  showExport?: boolean;
  className?: string;
  columnGap?: string;
  withRowCount?: boolean;
  specificColumnCount?: number;
  columnFiltersState?: ColumnFiltersState;
  onColumnFiltersChange?: OnChangeFn<ColumnFiltersState>;
  onTableLoaded?: (table: Table<T>) => void;
  globalFilterState?: string;
  customNoDataMessage?: string;
  isLoading?: boolean;
  hasSearched?: boolean;
  onGlobalFilterChange?: OnChangeFn<string | undefined>;
}

interface HeadersObject {
  [key: string]: number | string;
}

const ReusableTable = <T extends object>({
  data,
  columns,
  title,
  handleRowClick,
  className,
  columnGap,
  withRowCount = false,
  showExport = true,
  specificColumnCount,
  columnFiltersState,
  onColumnFiltersChange,
  onTableLoaded,
  globalFilterState,
  customNoDataMessage,
  isLoading,
  hasSearched,
  onGlobalFilterChange,
}: ReactTableProps<T>) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const defaultPageSize = 20;

  const isFirstRender = useRef<boolean>(true);

  const table = useReactTable({
    columns: columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(), // Set default page size
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnFilters: columnFiltersState,
      globalFilter: globalFilterState,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange,
    onGlobalFilterChange,
    enableSortingRemoval: false,
    filterFns: {
      isAfterDate,
    },
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      table.setPageSize(defaultPageSize);
    }
  }, [table]);

  const columnCount = useMemo(
    () => (withRowCount ? columns.length + 1 : columns.length),
    [columns.length, withRowCount],
  );

  const filterObjectsByHeaders = (
    data: Record<string, number | string>[],
    headers: HeadersObject[],
  ) => {
    return data.map((object: Record<string, number | string>) => {
      const filteredObject: HeadersObject = {};
      headers.forEach((header: HeadersObject) => {
        if (header.id && typeof header.header === 'function') {
          filteredObject[header.id] = object[header.accessorKey];
        } else if (
          header.accessorKey === 'last_updated' &&
          object[header.accessorKey]
        ) {
          filteredObject[header.header] = formatDate(
            object[header.accessorKey] as string,
          );
        } else if (header.accessorKey && object[header.accessorKey]) {
          filteredObject[header.header] = object[header.accessorKey];
        } else if (!header.accessorKey && object[header.header]) {
          filteredObject[header.header] = object[header.header];
        }
      });
      return filteredObject;
    });
  };

  const exportToPDF = (fileName: string) => {
    //@ts-expect-error remove this error for now
    const filtered = filterObjectsByHeaders(data, columns);
    const datas = filtered?.length ? filtered : [];
    const worksheet = utils.json_to_sheet(datas);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    writeFile(
      workbook,
      fileName ? `${fileName} ${new Date()}.xlsx` : 'data.xlsx',
    );
  };

  useEffect(() => {
    if (table && onTableLoaded) {
      onTableLoaded(table);
    }
  }, [table, onTableLoaded]);

  const render = () => {
    if (isLoading) return <DataLoading />;
    return (
      <>
        <div className="w-full overflow-x-auto">
          <DefaultTableContainer
            $columnCount={
              specificColumnCount ? specificColumnCount : columnCount
            }
            className={clsx(className)}
            $columnGap={columnGap}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <Fragment key={headerGroup.id}>
                {withRowCount && (
                  <TableHeaderCell className="!min-w-0 max-w-[4ch] justify-center text-content-base-subdued">
                    #
                  </TableHeaderCell>
                )}
                {headerGroup.headers.map((header) => (
                  <div
                    key={header.id}
                    className={clsx(
                      'group font-bold text-content-base-default',
                      header.column.getCanSort()
                        ? 'flex cursor-pointer items-start gap-2 fill-input-content-focus'
                        : '',
                      header.colSpan ? `col-span-${header.colSpan}` : '',
                    )}
                    onClick={header.column.getToggleSortingHandler()}
                    role="columnheader"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    {header.column.getCanSort() ? (
                      <ChevronDown
                        cssClasses={clsx(
                          'mt-1',
                          header.column.getIsSorted() === 'asc'
                            ? 'rotate-180'
                            : '',
                        )}
                        width="16px"
                        height="16px"
                        color={`rgb(var(--${
                          header.column.getIsSorted() === 'asc'
                            ? 'content-base-subdued'
                            : 'content-accent-default'
                        }))`}
                      />
                    ) : null}
                  </div>
                ))}
              </Fragment>
            ))}
            {!table.getRowModel().rows.length && (
              <div className="col-span-full flex h-[160px] w-full flex-col items-center justify-center bg-background-base-surface-2 ">
                <span className="fill-content-base-subdued">
                  {hasSearched ? (
                    <NoResultSvg />
                  ) : (
                    <FileTray
                      color={'inherit'}
                      width={'42px'}
                      height={'36px'}
                    />
                  )}
                </span>
                <span className="mt-6 text-base font-bold text-content-base-default">
                  {hasSearched ?? 'No results'}
                  {!hasSearched ? customNoDataMessage : 'No data'}
                </span>
              </div>
            )}
            {!!table.getRowModel().rows.length &&
              table.getRowModel().rows.map((row, index) => (
                <TableRow
                  key={row.id}
                  onClick={
                    handleRowClick
                      ? () => handleRowClick(row.original)
                      : undefined
                  }
                >
                  {withRowCount && (
                    <TableCell className="!min-w-0 max-w-[4ch] justify-center text-content-base-subdued">
                      {index + 1}
                    </TableCell>
                  )}
                  {row.getVisibleCells().map((cell) => {
                    if (cell.column.id === 'Actions') {
                      return (
                        <TableCell
                          key={cell.id}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </DefaultTableContainer>
        </div>
        <div className="mt-10 flex w-full flex-col items-start justify-start">
          <Pagination
            pageCount={table.getPageCount()}
            totalRows={data.length || 0}
            currentPage={table.getState().pagination.pageIndex + 1 || 1}
            onPageChange={(page: number) => table.setPageIndex(page - 1)}
            currentRowsShown={table.getRowModel().rows.length}
            pageSizeChanged={(page: number) => table.setPageSize(Number(page))}
          />
        </div>
        {showExport && (
          <div className="mt-6 flex w-full items-center justify-end">
            <GraniteButton
              variant="secondary"
              onClick={() => exportToPDF(title)}
              className="border-stroke-secondary-default flex cursor-pointer items-center justify-center rounded border text-content-base-default "
            >
              Export table
              <Download color={'inherit'} />
            </GraniteButton>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="flex w-full flex-col items-start justify-start">
      {render()}
    </div>
  );
};

export default ReusableTable;
