import { FC } from 'react';

interface ModalFormHeaderProps {
  title: string;
  subtitle?: string;
}

const ModalFormHeader: FC<ModalFormHeaderProps> = ({ title, subtitle }) => {
  return (
    <div className="rounded-t-lg bg-background-base-surface-3 px-8 py-6 font-bold leading-9">
      <h1 className="text-2.5xl font-bold leading-9 text-content-base-default">
        {title}
      </h1>
      {subtitle ? (
        <p className="mt-2 text-base font-semibold leading-[22px]">
          {subtitle}
        </p>
      ) : null}
    </div>
  );
};

export default ModalFormHeader;
