import {
  GraniteButton,
  GraniteButtonProps,
} from 'components/V2/Button/GraniteButton';
import { FC } from 'react';
import { activateContactGroupById } from '../utils';
import { useMutation } from 'react-query';
import showToast from 'components/Toast/Toast';

interface ReactiveGroupProps extends Pick<GraniteButtonProps, 'size'> {
  label: string;
  contactGroupId: number;
  refetchOnSuccess: () => void;
}

const ReactiveGroup: FC<ReactiveGroupProps> = ({
  label,
  contactGroupId,
  refetchOnSuccess,
  ...props
}) => {
  const { mutate: activateContactGroupByIdMutate } = useMutation(
    () => activateContactGroupById(contactGroupId),
    {
      onSuccess: () => {
        refetchOnSuccess();
        showToast.confirmation({ message: 'Reactivated' });
      },
      onError: () => {
        showToast.error({ message: 'Failed to reactivate' });
      },
    },
  );
  return (
    <GraniteButton
      variant="secondary"
      {...props}
      onClick={() => activateContactGroupByIdMutate()}
    >
      {label}
    </GraniteButton>
  );
};

export default ReactiveGroup;
