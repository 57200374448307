import React from 'react';

interface ChartTitleProp {
  title: string;
}

const ChartTitle: React.FC<ChartTitleProp> = ({ title }) => {
  return (
    <h2 className="m-0 mb-4 text-base font-bold leading-snug text-content-base-default">
      {title}
    </h2>
  );
};

export default ChartTitle;
