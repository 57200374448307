import { Modal } from 'components/Modal/Modal';
import { WizardStateBlock } from 'components/StepperWizard/WizardStateBlock';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useEffect, useState } from 'react';
import MultiOptionButton from 'components/MultiOptionButton';
import { ProductTypes, ProductTypesEnum } from '../AddProducts/schemas';
import BroadbandCircuitForm from '../AddProducts/BroadbandCircuitForm';
import { QuoteProduct } from '../schemas/Product';

const AddProductDialog = ({
  isOpen,
  close,
  onAddProduct,
  onEditProduct,
  dynamicProps,
}: {
  isOpen: boolean;
  close: () => void;
  onAddProduct: (product: QuoteProduct) => void;
  onEditProduct: (product: QuoteProduct) => void;
  dynamicProps?: QuoteProduct;
}) => {
  const [step, setStep] = useState(1);
  const [productType, setProductType] = useState<
    'BroadbandCircuit' | 'DIA' | null
  >(null);

  const onModalClose = () => {
    setStep(1);
    setProductType(null);
    close();
  };

  useEffect(() => {
    if (dynamicProps) {
      setStep(2);
      setProductType(
        dynamicProps.type === 'broadband' ? 'BroadbandCircuit' : 'DIA',
      );
    }
  }, [dynamicProps]);

  return (
    <Modal
      isVisible={isOpen}
      close={onModalClose}
      className="max-w-[836px]"
      enableScrolling={step === 2 && productType === 'BroadbandCircuit'}
    >
      <div className="flex flex-col gap-2 rounded-lg rounded-t-lg bg-background-base-surface-2">
        <h1 className="rounded-t-lg bg-background-base-surface-3 px-8  py-6 text-[28px] font-bold leading-9 text-content-base-default">
          {dynamicProps
            ? 'Edit product'
            : step === 1
              ? 'What type of product would you like to add?'
              : productType === 'BroadbandCircuit'
                ? 'Broadband circuit options'
                : ''}
          {!dynamicProps && (
            <div className="grid grid-cols-2 items-end gap-1 pt-4">
              <WizardStateBlock isActive={step >= 1} />
              <WizardStateBlock isActive={step >= 2} />
            </div>
          )}
        </h1>
        {!dynamicProps && step === 1 && (
          <div className="px-8 py-6">
            <div className="">
              <>
                <div className="flex gap-4">
                  <MultiOptionButton<ProductTypes>
                    label="Broadband circuit"
                    subText="Reliable high-speed internet connection for seamless browsing, streaming, and connectivity, delivered straight to your business"
                    option={ProductTypesEnum.enum.BroadbandCircuit}
                    value={productType as 'BroadbandCircuit' | 'DIA'}
                    onClick={() => setProductType('BroadbandCircuit')}
                    disabled={!!dynamicProps}
                  />
                  <MultiOptionButton<ProductTypes>
                    label="Direct internet access (DIA)"
                    subText="Dedicated, high-performance internet connection for businesses, ensuring fast and reliable connectivity without shared bandwidth"
                    option={ProductTypesEnum.enum.DIA}
                    value={productType as 'BroadbandCircuit' | 'DIA'}
                    onClick={() => setProductType('DIA')}
                    disabled={true}
                  />
                </div>
                <div className="mt-12 flex gap-5">
                  <GraniteButton
                    size="large"
                    variant="secondary"
                    onClick={onModalClose}
                  >
                    Cancel
                  </GraniteButton>
                  <GraniteButton
                    size="large"
                    onClick={() => setStep(2)}
                    disabled={!productType}
                  >
                    Next
                  </GraniteButton>
                </div>
              </>
            </div>
          </div>
        )}
        {(dynamicProps || step === 2) && productType === 'BroadbandCircuit' && (
          <BroadbandCircuitForm
            setStep={setStep}
            productValues={dynamicProps}
            onModalClose={onModalClose}
            onAddProduct={(product: QuoteProduct) => {
              if (dynamicProps) {
                onEditProduct(product);
                onModalClose();
                return;
              }
              onAddProduct(product);
              onModalClose();
            }}
          />
        )}
      </div>
    </Modal>
  );
};

export default AddProductDialog;
