import { GetStartedQuestionnaireForm } from '../../GetStarted/schemas';
import { ReviewTicketForm } from '../../Review/schemas';
import { ExtensionCircuitForm } from './schemas';
import { questionnaireFormToReviewTicketDefaultValues } from '../../BaseComponents/questionnaireFormToReviewTicketDefaultValues';
import {
  NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX,
  NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX,
} from '../../utils/nonStandardToolsSOWUtils';
import { formatContactTesting } from 'shared/util/util';

export const extensionCircuitFormToReview = (
  questionnaire: GetStartedQuestionnaireForm,
  form: ExtensionCircuitForm,
): Partial<ReviewTicketForm> => {
  return {
    ...questionnaireFormToReviewTicketDefaultValues(questionnaire),
    scopeOfWork: formToSOW(form),
    nonStandardTools: form.nonStandardTools,
  };
};

const formToSOW = (form: ExtensionCircuitForm) => {
  return [
    form.nonStandardTools.isNeeded
      ? `${NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX} ${form.nonStandardTools.nonStandardTools} ${NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX}\n`
      : '',
    'Service technician needed on site to run an extension for an existing circuit.',
    form.mpoeLocation
      ? `\nThe MPOE (Minimum Point of Entry) for this circuit is located at ${form.mpoeLocation}.`
      : '',
    form.serviceDescription
      ? `\nThe circuit ID associated with this circuit is ${form.serviceDescription}.`
      : '',
    form.downloadSpeed && !form.handoffType?.label
      ? `\nThe circuit is ${form.downloadSpeed}MB.`
      : '',
    !form.downloadSpeed && form.handoffType?.label
      ? `\nThe circuit has a ${form.handoffType.label.toLowerCase()} hand off type.`
      : '',
    form.downloadSpeed && form.handoffType?.label
      ? `\nThe circuit is ${
          form.downloadSpeed
        }MB with a ${form.handoffType.label.toLowerCase()} hand off type.`
      : '',
    `\nThe extension will be going to ${form.extension.label} and is approximately ${form.extensionLength} feet in length.`,
    form.techToTestWith.techToTestWith !== 'No'
      ? `\nOnce the extension is successful, the technician should test with: ${formatContactTesting(
          form.itTestingContacts,
        )}`
      : '',
  ]
    .filter((l) => l)
    .join('\n');
};
