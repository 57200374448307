import { z } from 'zod';
import { nullToUndefined } from '../../../shared/util/schema-utils';

export const TicketNoteRequestSchema = z.object({
  text: z.string().trim().nonempty('Required'),
});

export type TicketNoteRequest = z.infer<typeof TicketNoteRequestSchema>;

export const TicketNoteResponseSchema = z.object({
  id: z.number().nullish().transform(nullToUndefined),
  text: z.string().nullish().transform(nullToUndefined),
  ticket_id: z.number().nullish().transform(nullToUndefined),
  last_updated: z.string().nullish().transform(nullToUndefined),
  date_entered: z.string().nullish().transform(nullToUndefined),
  created_by: z.string().nullish().transform(nullToUndefined),
});

export type TicketNoteResponse = z.infer<typeof TicketNoteResponseSchema>;

export type TicketNotesResponseSchema = z.infer<
  typeof TicketNoteResponseSchema
>[];
