import { cancelTicket } from 'api/techexpress/api';
import { ExtendedTicket } from 'api/techexpress/schema';
import { Modal } from 'components/Modal/Modal';
import showToast from 'components/Toast/Toast';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useMutation, useQueryClient } from 'react-query';

const CancelConfirmationDialog = ({
  isOpen,
  close,
  ticket,
  onSuccessSubmission,
}: {
  isOpen: boolean;
  close: () => void;
  ticket: ExtendedTicket;
  onSuccessSubmission: () => void;
}) => {
  const queryClient = useQueryClient();

  const updateTicketMutation = useMutation(
    ({ ticketId }: { ticketId: number }) => cancelTicket(ticketId),
    {
      onSuccess: () => {
        onSuccessSubmission();
        queryClient.refetchQueries([ticket.id.toString(), 'ticket-detail']);
        queryClient.refetchQueries([ticket.id.toString(), 'ticket-notes']);
        showToast.confirmation({
          message: 'Ticket has been successfully canceled!',
        });
      },
    },
  );

  const onCancelTicket = () => {
    updateTicketMutation.mutate({
      ticketId: ticket.id,
    });
  };
  return (
    <Modal isVisible={isOpen} close={close} className="max-w-[624px]">
      <div className="flex flex-col gap-2 rounded-t-lg bg-background-base-surface-2">
        <h1 className="bg-background-base-surface-3 px-8 py-6  text-[28px] leading-9 text-content-base-default">
          Confirm Cancellation
        </h1>
        <div className="px-8 py-6">
          <p className="mb-[45px] text-[24px] font-bold leading-[22px] text-content-base-default">
            Are you sure you want to cancel this ticket?
          </p>
          <div className="">
            <p className="medium text-sm text-content-base-subdued">
              By clicking cancel ticket you agree to any additional fees that
              may be incurred.
            </p>
            <div className="mt-4 flex gap-5">
              <GraniteButton size="large" variant="secondary" onClick={close}>
                Cancel
              </GraniteButton>
              <GraniteButton
                size="large"
                variant="destructive"
                onClick={() => {
                  onCancelTicket();
                }}
              >
                Cancel ticket
              </GraniteButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CancelConfirmationDialog;
