import {
  forwardRef,
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CloseCircle, Search } from 'react-ionicons';
import { clsx } from 'clsx';
import '../../../components/SearchAddressBar/SearchBar.css';
import { usePopper } from 'react-popper';
import { useDebouncedState } from '../../../shared/hooks/useDebouncedState';
import escapeRegExp from 'lodash.escaperegexp';
import { ErrorSubtext } from '../../../components/ErrorSubtext/ErrorSubtext';
import { QuoteLocation, QuoteLocationSchema } from '../schemas/QuoteLocation';
import { lookup } from 'zipcodes';

export interface SearchAddressBarProps {
  className?: string;
  error?: string;
  onAddressSelected: (address?: QuoteLocation) => void;
  onAddressDelete: (location: QuoteLocation) => void;
  value?: QuoteLocation[];
  isDisabled?: boolean;
  name?: string;
  isSelectable?: boolean;
}

export const SearchAddressBar = forwardRef(
  (
    {
      className,
      isDisabled,
      error,
      name,
      value,
      onAddressSelected,
      onAddressDelete,
      isSelectable = false,
    }: SearchAddressBarProps,
    ref,
  ) => {
    const anchorEltRef = useRef<HTMLDivElement>(null);
    const popperElementRef = useRef<HTMLDivElement>(null);
    const inputEltRef = useRef<HTMLInputElement>(null);
    const [searchInput, setSearchInput] = useState('');
    const [isMenuVisible, setIsMenuVisible] = useState(true);
    const [selectedValue, setSelectedValue] = useState<QuoteLocation | null>(
      null,
    );
    const { value: debouncedIsMenuVisible } = useDebouncedState(isMenuVisible);
    const { value: debouncedSearch } = useDebouncedState(searchInput, 100);

    const [searchResults, setSearchResults] = useState<QuoteLocation[]>([]);

    const [zipCode, setZipCode] = useState('');

    useEffect(() => {
      const match = debouncedSearch.match(/(\b\d{5}\b)/);
      if (match) {
        setZipCode(match[0]);
      }
    }, [debouncedSearch]);

    useEffect(() => {
      if (zipCode) {
        const address = lookup(zipCode);
        if (address) {
          const brandedLocation = QuoteLocationSchema.parse({
            address: `${address?.state} ${address?.city}`,
            state: address.state,
            city: address.city,
            zipcode: zipCode,
          });
          if (value && value.length < 10) {
            // for now we will have only one result
            setSearchResults([brandedLocation]);
          }
        } else setSearchResults([]);
      }
    }, [value, zipCode]);

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          inputEltRef.current?.focus();
        },
      }),
      [inputEltRef],
    );

    const onAddressChangeInternal = useCallback(
      (address: QuoteLocation) => {
        if (isSelectable) {
          setSelectedValue(address);
        }
        onAddressSelected(address);
        setSearchInput('');
        setSearchResults([]);
        setIsMenuVisible(false);
      },
      [onAddressSelected, isSelectable],
    );

    const onSearchResultRowEscape = useCallback(() => {
      if (inputEltRef.current) {
        inputEltRef.current.focus();
      }
    }, []);

    const { styles, attributes } = usePopper(
      anchorEltRef.current,
      popperElementRef.current,
      { placement: 'bottom' },
    );

    return (
      <Fragment>
        <div className={clsx('group relative', className)}>
          <div
            className={clsx(
              'search-bar-wrapper',
              isDisabled && '!bg-input-background-disabled',
              !searchResults.length && 'rounded-b',
              searchResults.length > 0 &&
                'focus-within:border-b-input-stroke-unfilled',
              error && 'error',
            )}
            ref={anchorEltRef}
            role="listbox"
            aria-labelledby="search address bar"
          >
            <Search color="inherit" width="16px" height="16px" />
            {selectedValue && isSelectable ? (
              <div className="flex items-center gap-1 rounded-[32px] border border-stroke-base-subdued bg-background-base-surface-3 fill-content-base-subdued px-3 font-light">
                {selectedValue.address}, {selectedValue.zipcode}
                <button
                  className="cursor-pointer fill-content-base-subdued"
                  title="remove address"
                  type="button"
                  onClick={() => {
                    setSelectedValue(null);
                    setSearchInput('');
                  }}
                  disabled={isDisabled}
                >
                  <CloseCircle color="inherit" width="16px" height="16px" />
                </button>
              </div>
            ) : (
              <input
                placeholder="Search address..."
                name={name}
                aria-label={name}
                value={searchInput}
                ref={inputEltRef}
                onChange={(e) => setSearchInput(e.target.value)}
                onFocus={() => setIsMenuVisible(true)}
                onBlur={() => setIsMenuVisible(false)}
                disabled={isDisabled}
                className="disabled:cursor-not-allowed disabled:bg-input-background-disabled"
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              />
            )}
          </div>
          <div
            className={clsx(
              searchResults.length > 0 && 'search-result-container',
            )}
            onFocus={() => setIsMenuVisible(true)}
            onBlur={() => setIsMenuVisible(false)}
            ref={popperElementRef}
            style={{
              width: (anchorEltRef.current?.clientWidth ?? -2) + 2,
              ...styles.popper,
            }}
            {...attributes.popper}
          >
            {debouncedIsMenuVisible && searchResults.length > 0 && (
              <Fragment>
                {searchResults.map((address) => (
                  <SearchResultRow
                    key={address.id || address.address}
                    searchTerm={searchInput}
                    address={address}
                    onClick={onAddressChangeInternal}
                    onEscapeRequest={onSearchResultRowEscape}
                  />
                ))}
                {searchResults.length === 0 && (
                  <div className="rounded-b bg-input-background-unfilled px-4 py-3 font-light text-content-base-subdued">
                    No results
                  </div>
                )}
              </Fragment>
            )}
          </div>
          {!isSelectable && value && value.length > 0 && (
            <div className="mt-4 flex flex-wrap gap-2">
              {value?.map((location, i) => (
                <div
                  key={i}
                  className="flex h-8 items-center rounded-[32px] border border-stroke-base-subdued bg-background-base-surface-3 px-2"
                >
                  <span className="fill-content-base-subdued pr-1 text-content-base-default">
                    {location.address}, {location.zipcode}
                  </span>
                  <button className="fill-content-base-subdued" type="button">
                    <CloseCircle
                      width="16px"
                      height="16px"
                      color="inherit"
                      onClick={() => onAddressDelete(location)}
                    />
                  </button>
                </div>
              ))}
            </div>
          )}
          <ErrorSubtext error={error} />
        </div>
      </Fragment>
    );
  },
);

SearchAddressBar.displayName = 'SearchAddressBar';

interface SearchResultRowProps {
  searchTerm: string;
  address: QuoteLocation;
  onClick: (address: QuoteLocation) => void;
  onEscapeRequest?: () => void;
}

export const highlightMatch = (
  address: string,
  searchTerm: string,
): ReactElement => {
  const parts = address
    .split(new RegExp(`(${escapeRegExp(searchTerm)})`, 'ig'))
    .map((part, i) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <mark role="mark" key={i}>
          {part}
        </mark>
      ) : (
        part
      ),
    );

  return <Fragment>{parts}</Fragment>;
};

export const SearchResultRow = ({
  address,
  searchTerm,
  onClick,
  onEscapeRequest,
}: SearchResultRowProps) => {
  const fmtAddress = `${address.address}, ${address.zipcode}`;
  const highlightedResults = useMemo(
    () => highlightMatch(fmtAddress, searchTerm),
    [fmtAddress, searchTerm],
  );

  return (
    <div
      className="search-result-row"
      onClick={() => onClick(address)}
      role="option"
      tabIndex={0}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          onClick(address);
          return;
        }
        if (e.key === 'Escape') {
          onEscapeRequest && onEscapeRequest();
        }
      }}
    >
      <div className=" [&>mark]:bg-transparent [&>mark]:text-content-accent-default">
        {highlightedResults}
      </div>
      <div className="text-sm font-light text-input-content-unfilled">{''}</div>
    </div>
  );
};
