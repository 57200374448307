import { z } from 'zod';
import { v4 as uuid } from 'uuid';

/**
 * This schema is `branded`, that means that for a function taking an object
 * of this type, the object **needs** to be created with
 * `QuoteLocationSchema.parse` to be  accepted. It's not enough the object to
 * have the same _shape_.
 *
 * This is done so that we ensure that every object is valid and has a valid id
 * for tracking relationships.
 *
 * Essentially it uses this type as nominal instead of structural.
 */
export const QuoteLocationSchema = z
  .object({
    id: z.string().default(uuid),
    address: z.string(),
    address2: z.string().optional(),
    city: z.string(),
    state: z.string(),
    zipcode: z.string(),
  })
  .brand<'QuoteLocationSchema'>();

export type QuoteLocation = z.infer<typeof QuoteLocationSchema>;
