import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

type StyleProps = {
  background: string;
  zIndex?: number;
  borderColor?: string;
  color?: string;
  'data-dragged': boolean;
};

const SliderContainer = styled.div`
  position: relative;
  border-radius: 10px;
  padding-top: 56px;
`;

const Slider = styled.input.attrs({
  type: 'range',
  className:
    'slider h-1 bg-transparent rounded-lg appearance-none cursor-pointer absolute w-full',
})<StyleProps>`
  pointer-events: none;
  &::-webkit-slider-thumb {
    appearance: none;
    height: 16px;
    width: 16px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    cursor: pointer;
    margin-top: 0;
    pointer-events: all;
    z-index: 2;
    border: 2px solid ${(props) => props.borderColor};
    box-sizing: content-box;
  }

  &::-moz-range-thumb {
    appearance: none;
    height: 16px;
    width: 16px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    cursor: pointer;
    margin-top: 0;
    pointer-events: all;
    z-index: 2;
    border: 2px solid ${(props) => props.borderColor};
    box-sizing: content-box;
  }

  width: calc(100%);
  height: 20px;
  z-index: ${(props) => props.zIndex};
  border-radius: 10px;
`;

const SliderBackgroundWrapper = styled.div.attrs({
  className:
    'slider h-1 bg-transparent rounded-xl appearance-none cursor-pointer absolute',
})`
  pointer-events: none;
  left: 0;
  right: 0;
  height: 20px;
  background: #191925;
`;

const SliderBackground = styled.div.attrs({
  className:
    'slider h-1 bg-transparent rounded-lg appearance-none cursor-pointer absolute',
})<{ background: string }>`
  pointer-events: none;

  height: 20px;
  background: ${(props) => props.background};
  border-radius: 10px;
`;

const Label = styled.div.attrs({
  className: 'absolute',
})`
  top: -5px;
  pointer-events: none;
`;

interface DualRangeSliderProps {
  color: string;
  background: string;
  allowedValues: number[];
  value: [number, number];
  onChange: (values: [number, number]) => void;
}

const DualRangeSlider = ({
  color,
  background,
  allowedValues,
  value: [globalMinValue, globalMaxValue],
  onChange,
}: DualRangeSliderProps) => {
  const minimum = 0;
  const maximum = allowedValues.length - 1;
  const valuesCount = allowedValues.length;

  const [minValue, setMinValue] = useState(
    allowedValues.findIndex((v) => v === globalMinValue),
  );
  const [maxValue, setMaxValue] = useState(
    allowedValues.findIndex((v) => v === globalMaxValue),
  );

  const handleOnMouseUp = useCallback(() => {
    setIsDragged(false);
    const [min, max] = [Math.round(minValue), Math.round(maxValue)];
    onChange([allowedValues[min], allowedValues[max]]);
  }, [allowedValues, maxValue, minValue, onChange]);

  useEffect(() => {
    animateValue(
      minValue,
      allowedValues.findIndex((v) => v === globalMinValue),
      200,
      setMinValue,
    );
    animateValue(
      maxValue,
      allowedValues.findIndex((v) => v === globalMaxValue),
      200,
      setMaxValue,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalMinValue, globalMaxValue, allowedValues]);

  const [positions, setPositions] = useState<[number, number]>([0, 1]);
  const [activeHandle, setActiveHandle] = useState<'min' | 'max'>('min');

  const fillSlider = useCallback(() => {
    const range = valuesCount;
    const values = [minValue, maxValue].sort((a, b) => a - b);
    const fromPosition = values[0] / (range - 1);
    const toPosition = values[1] / (range - 1);
    setPositions([fromPosition, toPosition]);
  }, [valuesCount, minValue, maxValue]);

  useEffect(() => {
    fillSlider();
  }, [minValue, maxValue, fillSlider]);

  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    setActiveHandle('min');
    setMinValue(newValue);
  };

  const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    setActiveHandle('max');
    setMaxValue(newValue);
  };

  const [isDragged, setIsDragged] = useState(false);

  const handleOnMouseDown = useCallback(() => {
    setIsDragged(true);
  }, []);

  return (
    <SliderContainer>
      <SliderBackgroundWrapper>
        <SliderBackground
          background={background}
          style={{
            left: `${positions[0] * 100 * 0.98}%`,
            right: `${(1 - positions[1]) * 100 * 0.98}%`,
            display:
              globalMinValue === globalMaxValue ||
              Math.abs(minValue - maxValue) < 0.25
                ? 'none'
                : 'block',
          }}
        />
      </SliderBackgroundWrapper>
      <Slider
        data-dragged={isDragged}
        value={minValue}
        min={0}
        max={valuesCount - 1}
        step={0.01}
        onChange={handleMinChange}
        background={'#f00'}
        zIndex={activeHandle === 'min' ? 2 : 1}
        borderColor={background}
        color={color}
        onMouseUp={handleOnMouseUp}
        onTouchEnd={handleOnMouseUp}
        onMouseDown={handleOnMouseDown}
        onTouchStart={handleOnMouseDown}
      />
      <div
        style={{
          width: 'calc(100% - 20px)',
          position: 'relative',
          left: 10,
          right: 10,
        }}
      >
        <Label
          style={{
            left: `calc(${(minValue / (valuesCount - 1)) * 100}%)`,
          }}
        >
          <Tooltip position={minValue} values={allowedValues} />
        </Label>
      </div>
      <Slider
        data-dragged={isDragged}
        value={maxValue}
        min={0}
        max={valuesCount - 1}
        step={0.01}
        onChange={handleMaxChange}
        onChangeCapture={handleMaxChange}
        background={'#f00'}
        zIndex={activeHandle === 'max' ? 2 : 1}
        borderColor={background}
        color={color}
        onMouseUp={handleOnMouseUp}
        onTouchEnd={handleOnMouseUp}
        onMouseDown={handleOnMouseDown}
        onTouchStart={handleOnMouseDown}
      />
      <div
        style={{
          width: 'calc(100% - 20px)',
          position: 'relative',
          left: 10,
          right: 10,
        }}
      >
        <Label
          style={{
            left: `calc(${(maxValue / (valuesCount - 1)) * 100}%)`,
          }}
        >
          <Tooltip position={maxValue} values={allowedValues} />
        </Label>
      </div>
      <div className="mt-7 flex w-full items-center justify-between px-2 text-xs">
        <span>{formatBytes(allowedValues[minimum], ' ', 'b/s')}</span>
        <span>{formatBytes(allowedValues[maximum], ' ', 'b/s')}</span>
      </div>
    </SliderContainer>
  );
};

interface TooltipProps {
  position: number;
  values: number[];
}

const Tooltip: React.FC<TooltipProps> = ({ position, values }) => {
  return (
    <div className="relative flex items-center">
      <div className="absolute bottom-full left-1/2 mb-3 min-w-[40px] -translate-x-1/2 rounded-md bg-background-base-surface-1 px-1 py-2.5 text-center text-xs leading-5 text-content-base-subdued shadow-lg">
        <div className="relative h-5 w-full overflow-hidden text-center">
          <div
            className="relative z-10 transition-all duration-200 ease-out"
            style={{ transform: `translateY(calc(-${position * 20}px))` }}
          >
            {values.map((v) => (
              <div key={v}>{formatBytes(v)}</div>
            ))}
          </div>
        </div>
        <div className="absolute left-1/2 top-full -translate-x-1/2 scale-x-50 transform">
          <div className="mt -mt-2 h-4 w-4 rotate-45 rounded-br-md bg-background-base-surface-1"></div>
        </div>
      </div>
    </div>
  );
};

export default DualRangeSlider;

function formatBytes(bytes: number, prefix = '', suffix = ''): string {
  if (bytes < 1000) {
    return bytes + prefix + 'M' + suffix;
  } else if (bytes < 10000) {
    return (bytes / 1000).toFixed(1) + prefix + 'G' + suffix;
  } else {
    return (bytes / 1000).toFixed(0) + prefix + 'G' + suffix;
  }
}

type EasingFunction = (t: number, b: number, c: number, d: number) => number;

const easeOutBack: EasingFunction = (t, b, c, d) => {
  const s = 1.70158;
  return c * ((t = t / d - 1) * t * ((s + 1) * t + s) + 1) + b;
};

function animateValue(
  a: number,
  b: number,
  duration: number,
  callback: (currentValue: number) => void,
): void {
  const start = performance.now();
  const change = b - a;

  const step = (currentTime: number) => {
    const elapsed = currentTime - start;
    if (elapsed < duration) {
      const currentValue = easeOutBack(elapsed, a, change, duration);
      callback(currentValue);
      requestAnimationFrame(step);
    } else {
      callback(b);
    }
  };

  requestAnimationFrame(step);
}
