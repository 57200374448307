import React, { MouseEventHandler, ReactNode } from 'react';
import { TabButton } from './Tabs.styles';

export interface TabComponentProps {
  icon?: ReactNode;
  title: ReactNode;
  onClick: MouseEventHandler;
  active: boolean;
  withUnderline: boolean;
  variant: 'compact' | 'medium' | 'large';
  disabled?: boolean;
  className?: string;
}

const TabComponent: React.FC<TabComponentProps> = ({
  icon,
  title,
  onClick,
  active,
  variant,
  withUnderline,
  disabled,
  className,
}) => (
  <TabButton
    $variant={variant}
    $withUnderline={withUnderline}
    type="button"
    onClick={onClick}
    $active={active}
    disabled={disabled}
    className={className}
  >
    {icon && icon}
    {title}
  </TabButton>
);

export default TabComponent;
