import { TicketDetailForm } from '../DefinedFormTypes';
import React, { Fragment } from 'react';
import Divider from '../../../../components/Divider';
import FormSection from '../../../../components/FormSection';
import { StandardTools } from '../../../../components/StandardTools/StandardTools';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import { GraniteLabel } from '../../../../components/V2/Label/GraniteLabel';
import RadioButton from '../../../../components/RadioButton';
import { GraniteInput } from '../../../../components/V2/Input/GraniteInput';
import { MoveForm } from './schemas';
import { optionsToLabelValuePairs } from '../../utils/optionsToLabelValuePairs';
import {
  FormWithContact,
  TicketDetailContactForm,
} from 'screens/LetUsHelp/BaseComponents/TicketDetailContactForm';
import { GraniteSelect } from '../../../../components/Select/Select';
import { NonStandardToolsFieldTextField } from 'screens/LetUsHelp/BaseComponents/NonStandardToolsField';

export type MoveProps = TicketDetailForm<MoveForm>;

export const Move = ({ register, control, errors }: MoveProps) => {
  return (
    <Fragment>
      <FormSection title="Ticket details" gridClassName="grid-cols-2">
        <Controller
          name="equipmentOrServicesBeingMoved"
          control={control}
          render={({ field: { ref: _ref, ...field } }) => (
            <GraniteSelect
              className="col-span-full"
              {...field}
              label="What equipment or services will the technician be moving?"
              error={errors.equipmentOrServicesBeingMoved?.message}
              options={optionsToLabelValuePairs(
                'Cabling',
                'Jack',
                'Equipment',
                'Circuit/TN',
              )}
            />
          )}
        />
        <Controller
          name="whereIsTheService"
          control={control}
          render={({ field }) => (
            <GraniteInput
              {...field}
              className="col-span-full"
              label="Where is the service/equipment located currently?"
              error={errors.whereIsTheService?.message}
            />
          )}
        />
        <Controller
          name="whereDoesItNeedToBeMoved"
          control={control}
          render={({ field }) => (
            <GraniteInput
              {...field}
              className="col-span-full"
              label="Where does the service/equipment need to be moved to?"
              error={errors.whereDoesItNeedToBeMoved?.message}
            />
          )}
        />
      </FormSection>
      <Divider />
      <FormSection gridClassName="grid-cols-2">
        <Controller
          control={control}
          name="techToTestWith"
          render={({ field: { onChange, value, ref } }) => (
            <Fragment>
              <GraniteLabel
                label="Does the technician need to test with anyone specific?"
                element="div"
                className="col-span-full"
              >
                <RadioButton
                  options={[
                    {
                      label: "Yes, I'll provide their contact information",
                      value: 'Yes',
                    },
                    { label: 'No', value: 'No' },
                  ]}
                  selectedValue={value?.techToTestWith}
                  error={errors.techToTestWith?.message}
                  onChange={(techToTestWith) =>
                    onChange({ ...value, techToTestWith })
                  }
                  ref={ref}
                />
              </GraniteLabel>
              {value?.techToTestWith && value.techToTestWith === 'Yes' && (
                <TicketDetailContactForm
                  errors={errors}
                  register={
                    register as unknown as UseFormRegister<FormWithContact>
                  }
                  control={control as unknown as Control<FormWithContact>}
                />
              )}
            </Fragment>
          )}
        />
      </FormSection>
      <Divider />
      <FormSection>
        <StandardTools />
        <NonStandardToolsFieldTextField control={control} />
      </FormSection>
    </Fragment>
  );
};
