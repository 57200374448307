import { useMemo } from 'react';
import {
  NotificationType,
  Pillar,
  categorizeNotifications,
} from 'components/Notifications/utils';
import {
  getNotifications,
  markAllNotifications,
  markNotification,
} from 'api/notifications/api';
import { Notification } from 'api/notifications/schema';
import { useInfiniteQuery, useQueryClient } from 'react-query';

type UseNotificationsProps = {
  onlyUnread?: boolean;
  notificationType?: NotificationType;
  pillar?: Pillar;
};
export const useNotifications = (
  {
    onlyUnread = false,
    notificationType = 'all',
    pillar = 'all',
  }: UseNotificationsProps = {},
  options = {},
) => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => ['notifications', { onlyUnread, notificationType, pillar }],
    [notificationType, onlyUnread, pillar],
  );
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isLoading } =
    useInfiniteQuery({
      queryKey,
      queryFn: ({ pageParam }) => {
        return getNotifications(
          onlyUnread,
          notificationType,
          pillar,
          pageParam,
        );
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.metadata.total_pages <= lastPage.metadata.page) return;
        return lastPage.metadata.page + 1;
      },
      ...options,
    });

  const notifications = useMemo(
    () => data?.pages.map(({ data }) => data).flat(),
    [data],
  );

  const invalidateQuery = () =>
    queryClient.invalidateQueries({ queryKey: ['notifications'] });

  const categories = useMemo(() => {
    if (notifications) {
      const categorized = categorizeNotifications(notifications);
      return [
        { name: 'Today', items: categorized.today },
        { name: 'Yesterday', items: categorized.yesterday },
        { name: 'Older', items: categorized.older },
      ].filter((category) => category.items.length > 0);
    }
    return [];
  }, [notifications]);

  const markAllAsRead = async (
    notificationsRead: boolean,
    pillar: Pillar = 'all',
  ) => {
    // update local state without waiting for server response
    if (data) {
      queryClient.setQueryData(queryKey, {
        pageParams: data.pageParams,
        pages: data.pages.map(({ metadata, data }) => ({
          metadata,
          data: data.map((item) => ({ ...item, read: !notificationsRead })),
        })),
      });
      if (!notificationsRead)
        queryClient.setQueryData(
          ['notifications', { notificationType, pillar, onlyUnread: true }],
          {
            pageParams: data.pageParams,
            pages: data.pages.map(({ metadata }) => ({
              metadata,
              data: [],
            })),
          },
        );
    }
    await markAllNotifications(!notificationsRead, pillar);
    invalidateQuery();
  };

  const markNotificationAsRead = async (
    notification: Notification,
    read: boolean,
  ) => {
    // update local state without waiting for server response
    if (data) {
      queryClient.setQueryData(queryKey, {
        pageParams: data.pageParams,
        pages: data.pages.map(({ metadata, data }) => ({
          metadata,
          data: data.map((item) =>
            item.id !== notification.id ? item : { ...item, read },
          ),
        })),
      });
      queryClient.setQueryData(
        ['notifications', { notificationType, pillar, onlyUnread: true }],
        {
          pageParams: data.pageParams,
          pages: data.pages.map(({ metadata, data }) => ({
            metadata,
            data: data.filter((item) => item.id !== notification.id),
          })),
        },
      );
    }
    await markNotification(notification.id, read);
    invalidateQuery();
  };
  const notificationsRead = useMemo(() => {
    const checkNotificationsRead = (items: Notification[]) => {
      return items.every((item) => item.read);
    };

    return notifications ? checkNotificationsRead(notifications) : true;
  }, [notifications]);

  return {
    notifications,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    categories,
    markAllAsRead,
    markNotificationAsRead,
    notificationsRead,
    invalidateQuery,
  };
};
