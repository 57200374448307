/**
 * Transforms null to undefined and narrows definition.
 *
 * Useful with .transform()
 *
 * @param value Value to cast
 */
export const nullToUndefined = <T>(
  value: T | null | undefined,
): T | undefined => {
  return value ?? undefined;
};
