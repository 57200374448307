import clsx from 'clsx';
import Checkbox from 'components/Checkbox';
import Loader from 'components/Loader';
import { Modal } from 'components/Modal/Modal';
import ModalFormHeader from 'components/Modal/ModalFormHeader';
import Searchbar from 'components/Table/SearchBar';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useModal } from 'hooks/useModal';
import { FC, useEffect, useMemo, useState } from 'react';
import { Add } from 'react-ionicons';
import { useQuery, useQueryClient } from 'react-query';
import CreateFormPermission from 'screens/RolesPermissions/Permissions/Form/CreateFormPermission';
import { PermissionType } from 'screens/RolesPermissions/roles-permissions.types';
import { getPermissions } from 'screens/RolesPermissions/utils';

export interface AddPermissionsToRoleProps {
  onSave: (data: PermissionType[]) => void;
  defaultValue: string[];
  close: () => void;
}

const AddPermissionsToRoleModal: FC<AddPermissionsToRoleProps> = ({
  onSave,
  defaultValue,
  close,
}) => {
  const { open: openNew, isOpen: isOpenNew, close: closeNew } = useModal();

  const [checkedPermissions, setCheckedPermissions] = useState<string[]>([]);
  const [permissions, setPermissions] = useState<PermissionType[]>([]);
  // const [search, setSearch] = useState('');
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ['permission-edit-role'],
    () => getPermissions({}),
    {
      refetchOnMount: true,
    },
  );

  useEffect(() => {
    if (data?.data) setPermissions(data.data);
  }, [data]);

  const onNewPermissionSuccess = (data: PermissionType | undefined) => {
    if (data) {
      setPermissions((prev) => [...prev, data]);
      setCheckedPermissions((prev) => [...prev, data.id]);
    }
    queryClient.refetchQueries('permission-edit-role');
    queryClient.refetchQueries('permission-index-table');
    closeNew();
  };

  const onAdd = () => {
    if (data?.data) {
      onSave(data.data.filter((p) => checkedPermissions.includes(p.id)));
      close();
    }
  };

  const onChange = (id: string) => {
    setCheckedPermissions((prev) => {
      if (prev.includes(id)) return prev.filter((pId) => pId !== id);
      return [...prev, id];
    });
  };

  const onSearch = (query: string) => {
    if (data?.data)
      setPermissions(
        data?.data.filter((permission) =>
          permission.name.toLowerCase().includes(query.toLowerCase()),
        ),
      );
  };

  const onQueryClear = () => data?.data && setPermissions(data.data);

  const filteredPermissions = useMemo(() => {
    const updatedPermissions = permissions.filter(
      (permission) => !defaultValue.includes(permission.id),
    );
    return updatedPermissions;
  }, [permissions, defaultValue]);

  return (
    <>
      <ModalFormHeader title="Add permissions" />
      <div className="p-8">
        <div className="flex flex-col gap-6">
          <Searchbar
            placeholder="Search for permissions"
            onQueryClear={onQueryClear}
            onSearch={onSearch}
          />
          <div className="max-h-[336px] overflow-y-auto rounded bg-blue-900 p-6 scrollbar-thin scrollbar-track-background-base-surface-1 scrollbar-thumb-stroke-base-subdued">
            {isLoading ? (
              <Loader className="h-[288px] w-full" />
            ) : (
              <>
                <div className="flex flex-wrap content-start items-start gap-4">
                  {filteredPermissions.map((permission) => (
                    <div
                      className={clsx(
                        'flex cursor-pointer gap-4 rounded bg-blue-800 p-3 last:mb-4',
                        {
                          'border border-teal-400 text-teal-400':
                            checkedPermissions.includes(permission.id),
                        },
                      )}
                      key={permission.id}
                      onClick={() => onChange(permission.id)}
                    >
                      <Checkbox
                        label={permission.name}
                        checked={checkedPermissions.includes(permission.id)}
                        className={clsx('pointer-events-none', {
                          '!text-teal-400': checkedPermissions.includes(
                            permission.id,
                          ),
                        })}
                      />
                    </div>
                  ))}
                </div>
                <div className=" flex w-full flex-1 items-end">
                  <GraniteButton variant="tertiary" onClick={openNew}>
                    Create new <Add />
                  </GraniteButton>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mt-12 flex gap-5">
          <GraniteButton variant="secondary" size="large" onClick={close}>
            Cancel
          </GraniteButton>
          <GraniteButton size="large" onClick={onAdd}>
            Add permissions
          </GraniteButton>
        </div>
      </div>
      <Modal
        isVisible={isOpenNew}
        close={closeNew}
        className="w-full max-w-[624px]"
      >
        <CreateFormPermission
          onSuccess={onNewPermissionSuccess}
          onClose={closeNew}
        />
      </Modal>
    </>
  );
};

export default AddPermissionsToRoleModal;
