import { OptionLabelValueSchema } from 'screens/LetUsHelp/utils/schemas';
import {
  LETTERS_SPACES_HYPENS_ERROR_LABEL,
  VALID_PHONE_ERROR_LABEL,
} from 'shared/constants/error-labels';
import {
  LETTERS_SPACES_HYPENS_REGEXP,
  NUMBERS_ONLY_REGEXP,
  VALID_PHONE_NUMBER,
} from 'shared/constants/validation-regex-constants';
import { z } from 'zod';

const MAX_TOTAL_SIZE = 25 * 1024 * 1024; // 25 MB in bytes

export const TicketDetailsBaseFormSchema = z.object({
  whatNeedsToBeDone: z.object({
    label: z.string(),
    value: z.string(),
  }),
  initialDescription: z.string().trim().nonempty('Required'),
  tns_impacted: z.string().trim().max(1000).optional(),
  customer_name: z.string().optional(),
  prior_ticket: z
    .string()
    .nonempty({ message: 'Required' })
    .regex(NUMBERS_ONLY_REGEXP, {
      message: 'Only numbers allowed',
    })
    .or(z.literal(''))
    .optional(),
  additional_contacts: z
    .array(
      z.object({
        type: OptionLabelValueSchema.optional().refine(
          (data) => data?.label && data?.value,
          {
            message: 'Required',
          },
        ),
        name: z
          .string()
          .trim()
          .nonempty('Required')
          .max(50, 'Only 50 characters allowed')
          .regex(LETTERS_SPACES_HYPENS_REGEXP, {
            message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
          }),
        number: z
          .string()
          .nonempty(VALID_PHONE_ERROR_LABEL)
          .length(10, VALID_PHONE_ERROR_LABEL)
          .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
      }),
    )
    .max(2),
  access_hours: z.string().trim().max(1000).nonempty('Required'),
  technician_dispatch_approved: z.boolean().optional(),
  local_contact_name: z
    .string()
    .trim()
    .nonempty('Required')
    .max(50, 'Only 50 characters allowed')
    .regex(LETTERS_SPACES_HYPENS_REGEXP, {
      message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
    }),
  local_contact_number: z
    .string()
    .nonempty(VALID_PHONE_ERROR_LABEL)
    .length(10, VALID_PHONE_ERROR_LABEL)
    .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
  attachments: z
    .array(z.any())
    .optional()
    .refine(
      (attachments) => {
        if (!attachments) return true;
        const totalSize = attachments.reduce((acc, file) => acc + file.size, 0);
        return totalSize <= MAX_TOTAL_SIZE;
      },
      {
        message: 'Total attachments size must not exceed 25MB!',
      },
    ),
  isSendNotificationsViaEmailEnabled: z.boolean().optional(),
  recipients: z.array(z.object({ email: z.string().email().nonempty() })),
});

const TicketDetailsWithMaintenanceRequired = TicketDetailsBaseFormSchema.extend(
  {
    canTestingBeDoneAnytime: z.literal(false),
    maintenance_window: z.string().nonempty('Required'),
  },
);

const TicketDetailsWithMaintenanceOptional = TicketDetailsBaseFormSchema.extend(
  {
    canTestingBeDoneAnytime: z.literal(true),
    maintenance_window: z.string().optional(),
  },
);

const TicketDetailsWithMaintenanceUndefined =
  TicketDetailsBaseFormSchema.extend({
    canTestingBeDoneAnytime: z.undefined(),
    maintenance_window: z.string().nonempty('Required'),
  });

export const TicketDetailsFormSchema = z.discriminatedUnion(
  'canTestingBeDoneAnytime',
  [
    TicketDetailsWithMaintenanceRequired,
    TicketDetailsWithMaintenanceOptional,
    TicketDetailsWithMaintenanceUndefined,
  ],
);

export type TicketDetails = z.infer<typeof TicketDetailsFormSchema>;
