import { z } from 'zod';
import { VALID_US_DATE_FORMAT } from '../../../shared/constants/error-labels';
import { isAfterCutoffTime } from '../../../shared/util/getTodaysCutoffTime';
import {
  differenceInHours,
  endOfDay,
  isAfter,
  isBefore,
  startOfDay,
} from 'date-fns';
import { SiteAddressSchema } from '../../addresssearch/schema';
import { mergeDateTimeParts } from '../../../shared/util/mergeDateTimeParts';
import { utcToZonedTimeOfZipCode } from '../../../shared/util/utcToZonedTimeOfZipCode';

const RequestedWindowScheduleSchema = z.object({
  scheduling_type: z.literal('Requested Window'),
  start_date: z.coerce.date(),
  end_date: z.coerce.date(),
});

const HardStartScheduleSchema = z.object({
  scheduling_type: z.literal('Hard Start'),
  start_date: z.coerce.date(),
});

const DispatchScheduleSchema = z
  .discriminatedUnion('scheduling_type', [
    RequestedWindowScheduleSchema,
    HardStartScheduleSchema,
  ])
  .refine((val) => {
    return (
      val.scheduling_type === 'Hard Start' ||
      isBefore(val.start_date, val.end_date)
    );
  }, 'Start must be before end');

export type DispatchSchedule = z.infer<typeof DispatchScheduleSchema>;

const UpdatableHardSTartScheduleSchema = HardStartScheduleSchema.merge(
  z.object({ id: z.number() }),
);
const UpdatableRequestedWindowScheduleSchema =
  RequestedWindowScheduleSchema.merge(z.object({ id: z.number() }));

export const ResponseScheduleSchema = z.discriminatedUnion('scheduling_type', [
  UpdatableRequestedWindowScheduleSchema,
  UpdatableHardSTartScheduleSchema,
]);

export type ResponseSchedule = z.infer<typeof ResponseScheduleSchema>;

export const SiteDispatchDateScheduleSchemas = z.object({
  dispatchDate: z
    .date({ required_error: VALID_US_DATE_FORMAT })
    .min(
      isAfterCutoffTime() ? endOfDay(new Date()) : startOfDay(new Date()),
      'Dispatch date should be today or in the future',
    ),
  accessTime: DispatchScheduleSchema.refine((val) => {
    return (
      val.scheduling_type === 'Hard Start' ||
      isBefore(val.start_date, val.end_date)
    );
  }, 'Start must be before end'),
  site: SiteAddressSchema,
});

export type SiteDispatchDateSchedule = z.infer<
  typeof SiteDispatchDateScheduleSchemas
>;

export const extendSiteDispatchDateScheduleWithValidations = <
  T extends SiteDispatchDateSchedule,
>(
  schema: z.ZodType<T>,
) => {
  return schema
    .refine(
      (val) => {
        const dispatchDate = mergeDateTimeParts(
          val.dispatchDate,
          val.accessTime.start_date,
        );
        return isAfter(
          dispatchDate,
          utcToZonedTimeOfZipCode(new Date(), val.site.zip),
        );
      },
      {
        message: 'Dispatch date and time cannot be in the past.',
        path: ['accessTime', 'start_date'],
      },
    )
    .refine(
      (val) =>
        Math.abs(
          differenceInHours(
            mergeDateTimeParts(val.dispatchDate, val.accessTime.start_date),
            utcToZonedTimeOfZipCode(new Date(), val.site.zip),
          ),
        ) >= 4,
      {
        message: 'Schedule must be done four hours in advance',
        path: ['accessTime', 'start_date'],
      },
    );
};

export const SiteDispatchDateScheduleWithValidationsSchema =
  extendSiteDispatchDateScheduleWithValidations(
    SiteDispatchDateScheduleSchemas,
  );
