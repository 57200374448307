import { GraniteButton } from 'components/V2/Button/GraniteButton';
import Logo from 'components/Logo';
import { useParams } from 'react-router-dom';

export const AcceptInvitation = () => {
  const { id } = useParams();
  return (
    <div className="flex h-screen w-screen items-center justify-center bg-background-base-surface-1">
      <div className="flex w-[624px] flex-col items-start gap-12 rounded bg-background-base-surface-2 p-8">
        <Logo />
        <div className="space-y-4">
          <h1 className="rounded-t-lg text-[28px] font-bold leading-9 text-content-base-default">
            Welcome to Granite360!
          </h1>
          <p className="text-xl font-semibold text-content-base-subdued">
            Accept your invitation by clicking the button below.
          </p>
        </div>
        <div className="mt-4 flex gap-5">
          <GraniteButton
            type="button"
            size="large"
            variant="primary"
            onClick={() =>
              (location.href = `${process.env.REACT_APP_G360_API_URL}/api/v1/invitations/process/${id}`)
            }
          >
            Accept invitation
          </GraniteButton>
        </div>
      </div>
    </div>
  );
};
