import { TokenUserRoles } from 'api/users/schemas/Users';

export const isStepAccessible = (
  roles: TokenUserRoles[] | undefined,
  stepSection?: string,
) => {
  if (!roles) {
    return false;
  }

  const excludedRoles = [TokenUserRoles.QR_ADMIN];

  if (roles.some((role) => role === TokenUserRoles.QR_ADMIN)) {
    return stepSection === 'dashboard';
  }

  if (stepSection === 'user_management') {
    return roles.some(
      (role) =>
        role === TokenUserRoles.COMPANY_ADMIN ||
        role === TokenUserRoles.SUPER_ADMIN,
    );
  }

  // Viewer should not have access to certain steps
  if (stepSection === 'open_ticket' || stepSection === 'noc_ticket') {
    return !roles.some((role) => role === TokenUserRoles.VIEWER);
  }

  return !roles.some((role) => excludedRoles.includes(role));
};

export const catalogAccessConfig: Record<
  TokenUserRoles,
  { accessibleItems: string[] }
> = {
  [TokenUserRoles.QR_ADMIN]: {
    accessibleItems: ['dashboard'],
  },
  [TokenUserRoles.VIEWER]: {
    accessibleItems: [
      'dashboard',
      'techexpress',
      'noc_express',
      'noc_express-view-tickets',
      'mobility',
    ],
  },
  [TokenUserRoles.USER]: {
    accessibleItems: [
      'dashboard',
      'techexpress',
      'techexpress-open-ticket',
      'techexpress-view-tickets',
      'noc_express',
      'noc_express-open-ticket',
      'noc_express-view-tickets',
      'mobility',
    ],
  },
  [TokenUserRoles.COMPANY_ADMIN]: {
    accessibleItems: [
      'dashboard',
      'techexpress',
      'techexpress-open-ticket',
      'techexpress-view-tickets',
      'noc_express',
      'noc_express-open-ticket',
      'noc_express-view-tickets',
      'mobility',
      'user_management',
    ],
  },
  [TokenUserRoles.SUPER_ADMIN]: {
    accessibleItems: [
      'dashboard',
      'techexpress',
      'techexpress-open-ticket',
      'techexpress-view-tickets',
      'noc_express',
      'noc_express-open-ticket',
      'noc_express-view-tickets',
      'mobility',
      'user_management',
    ],
  },
  [TokenUserRoles.GRANITE_BETA_TESTER]: {
    accessibleItems: [],
  },
  [TokenUserRoles.EARLY_ADOPTER]: {
    accessibleItems: [],
  },
  [TokenUserRoles.WELCOME_USER]: {
    accessibleItems: [],
  },
  [TokenUserRoles.PROSPECT]: {
    accessibleItems: [],
  },
};

export const hasAccessToCatalogItem = (
  roles: TokenUserRoles[],
  key: string,
): boolean => {
  for (const role of roles) {
    if (catalogAccessConfig[role]?.accessibleItems.includes(key)) {
      return true;
    }
  }
  return false;
};
