import { ComponentProps } from 'react';
import { clsx } from 'clsx';

export type LayoutProps = ComponentProps<'div'>;

export const Layout = ({ className, children, ...props }: LayoutProps) => {
  return (
    <div
      className={clsx(
        'grid grid-cols-1 items-start gap-8 lg:grid-cols-[2fr_minmax(0,_508px)]',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
