import { TokenUserPermissions } from '../api/users/schemas/Users';
import { useMemo } from 'react';
import { useAuthUser } from './useAuthUser';
import { G360Token } from 'api/auth/schema';

export interface PermissionsProps {
  permission?: TokenUserPermissions | TokenUserPermissions[];
  onlyForSuperAdmin?: boolean;
}

export const hasPermission = (args: PermissionsProps | undefined) => {
  const g360Token: G360Token = JSON.parse(
    localStorage.getItem('g360-token-storage') ?? '{}',
  );

  const { permissions: userPermissions, super_admin } = g360Token;

  if (args?.onlyForSuperAdmin) {
    if (super_admin) return true;
    else return false;
  } else {
    if (super_admin) return true;

    let _permission: TokenUserPermissions[] = [];

    if (typeof args?.permission === 'string') _permission.push(args.permission);
    else if (Array.isArray(_permission))
      _permission = [...(args?.permission?.length ? args.permission : [])];

    return _permission.some((perm) => userPermissions?.includes(perm));
  }
};

export const usePermissions = (
  props: PermissionsProps | undefined = undefined,
) => {
  const { permissions: userPermissions, super_admin } = useAuthUser();

  const _hasPermission = useMemo(() => hasPermission(props), [props]);

  return {
    userPermissions,
    isSuperAdmin: super_admin,
    hasPermission: _hasPermission,
  };
};
