import Skeleton from 'components/Skeleton/Skeleton';
import { Fragment } from 'react';

export const PreferenceSkeleton = ({ loaders = 1 }: { loaders?: number }) => (
  <div className="w-2/3 space-y-4">
    <Skeleton className="h-6" />
    {[...new Array(loaders)].map((_, index) => (
      <Fragment key={index}>
        <div className="flex justify-between gap-x-32">
          <Skeleton className="h-4 grow" />
          <Skeleton className="h-4 w-8" />
        </div>
        <div className="space-y-2 pl-4">
          <div className="flex gap-x-2">
            <Skeleton className="h-4 w-4 shrink-0 !rounded-full" />
            <Skeleton className="h-4 w-1/3" />
          </div>
          <div className="flex gap-x-2">
            <Skeleton className="h-4 w-4 shrink-0 !rounded-full" />
            <Skeleton className="h-4 w-2/3" />
          </div>
        </div>
      </Fragment>
    ))}
  </div>
);
