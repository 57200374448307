import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { createPortal } from 'react-dom';

const ConfettiOverlay: React.FC = () => {
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });

  const [showConfetti, setShowConfetti] = useState(false);
  const [recycleConfetti, setRecycleConfetti] = useState(true);

  const handleWindowResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      handleWindowResize();
      window.addEventListener('resize', handleWindowResize);

      const timers = [
        setTimeout(() => setShowConfetti(true), 200),
        setTimeout(() => {
          setRecycleConfetti(false);
          setTimeout(() => setShowConfetti(false), 3000);
        }, 3000),
      ];

      return () => {
        window.removeEventListener('resize', handleWindowResize);
        timers.forEach((timer) => clearTimeout(timer));
      };
    }
  }, []);

  return createPortal(
    <div className="pointer-events-none fixed left-0 top-0 z-[10000000000000] h-full w-full">
      {showConfetti && (
        <Confetti
          colors={['#82f0ff', '#455e8e', '#fbb979']}
          width={windowSize.width}
          height={windowSize.height}
          numberOfPieces={400}
          recycle={recycleConfetti}
          gravity={0.1}
        />
      )}
    </div>,
    document.body as HTMLElement,
  );
};

export default ConfettiOverlay;
