import { Modal } from 'components/Modal/Modal';
import { WizardStateBlock } from 'components/StepperWizard/WizardStateBlock';
import { useState } from 'react';
import AccountInformationForm from './AccountInformationForm';
import {
  AccountInformationForm as AccountInformationType,
  FullServiceDetailsFormType,
  InvoiceStyleEnumForParentAccount,
} from './schemas';
import ServiceDetailsForm from './ServiceDetailsForm';
import { useMutation } from 'react-query';
import { createChildAccount } from 'api/techexpress/api';
import showToast from 'components/Toast/Toast';
import { SiteAddress } from 'api/addresssearch/schema';
import { addressSearch } from 'api/addresssearch/api';
import { DuplicateAccountWarningDialog } from './DuplicateAccountModal';
import { useModal } from 'hooks/useModal';
import { childAccountFormToRequest } from './utils';

const CreateChildAccountModal = ({
  isOpen,
  close,
  onAccountSubmit,
}: {
  isOpen: boolean;
  close: () => void;
  onAccountSubmit: (data: SiteAddress) => void;
}) => {
  const [step, setStep] = useState(1);
  const [accountInformation, setAccountInformation] =
    useState<AccountInformationType | null>(null);
  const [serviceDetails, setServiceDetails] =
    useState<FullServiceDetailsFormType | null>(null);
  const {
    openWithProps: openDuplicateAccountsWithProps,
    ...duplicateAccountsModalProps
  } = useModal<SiteAddress[]>();

  const createChildAccountMutation = useMutation(
    (data: FullServiceDetailsFormType & AccountInformationType) =>
      createChildAccount(childAccountFormToRequest(data)),
    {
      onSuccess: (response) => {
        showToast.confirmation({
          title: 'Success!',
          message: `Child account #${response.name
            .split('-')[0]
            .trim()} created.`,
        });
        onAccountSubmit(response);
        setStep(1);
        close();
      },
    },
  );

  const handleSubmit = async (
    serviceDetailsForm: FullServiceDetailsFormType,
  ) => {
    try {
      setServiceDetails(serviceDetailsForm);

      const duplicates = await addressSearch(serviceDetailsForm.address_line_1);
      const hasDuplicateZipCode = duplicates.some(
        (duplicate) => duplicate.zip === serviceDetailsForm.zip_code,
      );

      if (duplicates.length > 0 && hasDuplicateZipCode) {
        openDuplicateAccountsWithProps(duplicates);
      } else {
        //@ts-expect-error error
        await createChildAccountMutation.mutateAsync({
          ...serviceDetailsForm,
          ...accountInformation,
        });
        duplicateAccountsModalProps.close();
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
    }
  };

  const handleDuplicateModalSubmit = () => {
    //@ts-expect-error error
    createChildAccountMutation.mutate({
      ...serviceDetails,
      ...accountInformation,
    });
    close();
    duplicateAccountsModalProps.close();
    setAccountInformation(null);
    setServiceDetails(null);
  };

  return (
    <>
      <Modal
        isVisible={isOpen}
        close={close}
        className="min-w-full max-w-[840px] md:min-w-[836px]"
        enableScrolling={true}
      >
        <div className="flex flex-col gap-2 rounded-lg rounded-t-lg bg-background-base-surface-2">
          <h1 className="rounded-t-lg bg-background-base-surface-3 px-8  py-6 text-[28px] font-bold leading-9 text-content-base-default">
            New child account
            <p className="text-base font-semibold text-content-base-subdued">
              Create a new billable child account.
            </p>
            <div className="grid grid-cols-2 items-end gap-1 pt-4">
              <WizardStateBlock
                isActive={step >= 1}
                label="Account information"
                classNames="text-xs"
              />
              <WizardStateBlock
                isActive={step >= 2}
                label="Service & billing details"
                classNames="text-xs"
              />
            </div>
          </h1>
          {step === 1 && (
            <AccountInformationForm
              setStep={setStep}
              onFormSubmit={setAccountInformation}
              onClose={close}
              initialData={accountInformation}
            />
          )}
          {step === 2 && (
            // 4 - value of Bill to parent account
            // 5 - value of Bill to this account
            <ServiceDetailsForm
              setStep={setStep}
              onFormSubmit={handleSubmit}
              initialData={serviceDetails}
              onFormChange={setServiceDetails}
              disableBillingAddress={
                accountInformation?.billing_type.value === '4' &&
                accountInformation.invoice_style.value ===
                  InvoiceStyleEnumForParentAccount.Enum['No site invoice']
              }
            />
          )}
        </div>
      </Modal>
      <DuplicateAccountWarningDialog
        duplicates={duplicateAccountsModalProps.dynamicProps}
        {...duplicateAccountsModalProps}
        onConfirm={handleDuplicateModalSubmit}
      />
    </>
  );
};

export default CreateChildAccountModal;
