import Divider from 'components/Divider';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import React from 'react';
import { ArrowBack, CopyOutline } from 'react-ionicons';
import { useQuery } from 'react-query';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { copyText, getKeyById } from './utils';
import Loader from 'components/Loader';

const ApiKey = () => {
  const { key } = useParams<{ key: string }>();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    ['user-settings-api-key', key],
    () => getKeyById(key as string),
    {
      refetchOnMount: true,
    },
  );

  const onBack = () => navigate('..', { replace: true });

  const onDelete = () => navigate(`delete/${data?.name}`, { replace: true });
  const onRegenerate = () =>
    navigate(`regenerate/${data?.name}`, { replace: true });

  if (isLoading) return <Loader className="h-full w-full" />;
  return (
    <>
      <Outlet />
      <GraniteButton variant="ghost" onClick={onBack} className="-ml-6">
        <ArrowBack width="20px" height="20px" color="currentColor" /> Back to
        keys
      </GraniteButton>
      <p className="mb-6 mt-8 text-2xl font-bold text-white">Key details</p>
      <div>
        <p className="font-bold">Key name</p>
        <p className="font-bold text-white">{data?.name}</p>
      </div>
      <div className="mt-4">
        <p className="font-bold">Client key</p>
        <p className="flex gap-2">
          <span className="font-bold text-white">{data?.client_key}</span>
          <span
            className="cursor-pointer hover:text-teal-400"
            onClick={(e) => {
              e.stopPropagation();
              copyText(data!.client_key, data!.name);
            }}
          >
            <CopyOutline width="24px" height="24px" color="currentColor" />
          </span>
        </p>
      </div>
      <Divider className="my-16" />
      <div className="flex gap-4">
        <GraniteButton variant="secondary" size="large" onClick={onRegenerate}>
          Regenerate key
        </GraniteButton>
        <GraniteButton variant="destructive" size="large" onClick={onDelete}>
          Delete key
        </GraniteButton>
      </div>
    </>
  );
};

export default ApiKey;
