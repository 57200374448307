import { GetStartedQuestionnaireForm } from '../../GetStarted/schemas';
import { AuditForm } from './schemas';
import { ReviewTicketForm } from '../../Review/schemas';
import { questionnaireFormToReviewTicketDefaultValues } from '../../BaseComponents/questionnaireFormToReviewTicketDefaultValues';
import {
  NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX,
  NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX,
} from '../../utils/nonStandardToolsSOWUtils';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';

export const auditFormToReview = (
  questionnaire: GetStartedQuestionnaireForm,
  form: AuditForm,
): Partial<ReviewTicketForm> => {
  return {
    ...questionnaireFormToReviewTicketDefaultValues(questionnaire),
    attachments: form.attachments,
    scopeOfWork: formToSOW(form),
    nonStandardTools: form.nonStandardTools,
  };
};

const formToSOW = (form: AuditForm) => {
  return [
    form.nonStandardTools.isNeeded
      ? `${NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX} ${form.nonStandardTools.nonStandardTools} ${NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX}\n`
      : '',
    'Service technician needed on site to complete a site audit.',
    `\nThe technician should contact ${
      form.contactName
    } at phone number ${formatPhoneNumber(
      form.contactNumber,
    )} upon arriving on site for access to the IT rooms and demarcation point.`,
    form.attachments?.length
      ? '\nAn audit sheet has been attached to this ticket.'
      : '',
  ]
    .filter((l) => l)
    .join('\n');
};
