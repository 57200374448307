import { useOutletContext } from 'react-router-dom';
import { OpenQuoteStore } from './OpenQuoteStore';
import { OpenQuoteWizardStore } from './OpenQuoteWizardStore';
import { ProductSelectionStore } from './ProductSelectionStore';

export type OpenQuoteWizardContext = {
  openQuoteStore: OpenQuoteStore;
  wizardStore: OpenQuoteWizardStore;
};

export const useOpenQuoteContext = () => {
  return useOutletContext<OpenQuoteWizardContext>();
};

export type SelectProductsWizardContext = {
  productSelectionStore: ProductSelectionStore;
  wizardStore: OpenQuoteWizardStore;
};

export const useSelectProductsContext = () => {
  return useOutletContext<SelectProductsWizardContext>();
};
