import styled from 'styled-components';

export const CardContainer = styled.div`
  background-color: #fff;
  color: #fff;
  width: 352px;
  height: 240px;
  padding: 20px 16px 16px 16px;
  background: ${({ theme }) => theme.colors.background.row};
  border-radius: 8px;
`;

const title = styled.p`
  font-size: ${({ theme }) => theme.text.size.lg};
  margin: 0;
`;

export const BlueTitle = styled(title)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: 600;
`;

export const WhiteTitle = styled(title)`
  color: ${({ theme }) => theme.colors.text.white};
  font-weight: 600;
`;

export const Subtitle = styled.p`
  font-size: ${({ theme }) => theme.text.size.sm};
  color: white;
  margin: 0;
`;

export const StyledDivider = styled.div`
  height: 1px;
  width: 100%;
  margin: 20px 0 20px 0;
  background-color: ${({ theme }) => theme.colors.border.hr};
`;

export const ListContainer = styled.ul`
  list-style: none;
  padding: 0;
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  line-height: 2;
  font-size: ${({ theme }) => theme.text.size.xs};
`;

export const Description = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const Value = styled.span`
  color: ${({ theme }) => theme.colors.text.white};
  font-size: ${({ theme }) => theme.text.size.sm};
  font-weight: 900;
`;

export const Footer = styled.footer`
  background-color: #333;
  padding: 12px 8px 12px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background.medium};
`;

export const FooterTitle = styled.h3`
  color: white;
  margin: 0;
`;

export const FooterDescription = styled.p`
  color: ${({ theme }) => theme.colors.text.primary};
  margin: 0;
`;

export const BigValue = styled.span`
  color: red;
  font-size: ${({ theme }) => theme.text.size['4xl']};
  font-weight: 900;
  color: ${({ theme }) => theme.colors.segment.light};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
