import { LockClosed } from 'react-ionicons';
import { FC } from 'react';
import { SubGroupType } from './accounts.types';
import SubGroup from './SubGroup';

interface UserAccountDetailsProps {
  subGroups: SubGroupType[];
  allSelected: boolean;
}

const UserAccountDetails: FC<UserAccountDetailsProps> = ({
  subGroups,
  allSelected,
}) => {
  return (
    <>
      <div>
        <p className="mb-2 font-bold text-neutral-500">Accounts</p>
        <div className="flex gap-4 text-neutral-500">
          <LockClosed width={'24px'} height={'24px'} color="currentColor" />
          <p className="font-bold text-white">
            {allSelected
              ? 'This user has access to all accounts'
              : 'This user has access to select accounts'}
          </p>
        </div>
      </div>
      {!allSelected && (
        <div className="mt-2 grid w-full grid-cols-1 gap-4 xl:grid-cols-2">
          {subGroups.map((subGroup) => (
            <SubGroup key={subGroup.id} {...subGroup} />
          ))}
        </div>
      )}
    </>
  );
};

export default UserAccountDetails;
