import { useOpenQuoteContext } from '../Wizard/OpenQuoteWizardReducer';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getQuote } from 'api/accessexpress/api';
import { QuoteApiLocation } from 'api/accessexpress/schema';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export const ResubmitOpenQuote = () => {
  const { openQuoteStore } = useOpenQuoteContext();
  const isFirstRender = useRef<boolean>(true);

  const navigate = useNavigate();

  const { quoteId } = useParams();
  const { data, isLoading, isError } = useQuery(
    ['access-express-quote', quoteId],
    () => getQuote(quoteId ?? ''),
    {
      enabled: !!quoteId,
      refetchOnMount: false,
    },
  );
  useEffect(() => {
    if (!isLoading && !isError && isFirstRender.current) {
      const locations = data?.quote_request.locations;
      openQuoteStore.fromRequest(locations as QuoteApiLocation[]);
      isFirstRender.current = false;
      navigate('/access-express/open-quote');
    }
  }, [
    data?.quote_request.locations,
    isError,
    isLoading,
    navigate,
    openQuoteStore,
  ]);

  return <></>;
};
