import React from 'react';

export const StandardTools = () => {
  return (
    <div className="col-span-full">
      <div className="mb-2 text-base font-bold text-content-base-subdued">
        Technicians are expected to dispatch with the following standard cabling
        tools:
      </div>
      <div className="grid sm:grid-cols-2 sm:gap-4">
        <ul className="list-inside list-disc text-base text-content-base-default">
          <li>CAT5e/CAT6 cable</li>
          <li>Cabling and punch down tools</li>
          <li>Cable continuity tester</li>
          <li>Jacks & faceplates</li>
          <li>Patch cables</li>
          <li>Cell phone</li>
        </ul>
        <ul className="list-inside list-disc text-base text-content-base-default">
          <li>Laptop & charger</li>
          <li>Hotspot</li>
          <li>USB console cable</li>
          <li>6-8’ ladder</li>
          <li>Toner & wand</li>
          <li>Butt set</li>
        </ul>
      </div>
    </div>
  );
};
