export interface PageTitleProps {
  title: string;
  subtitle?: string;
}

export const PageTitle = ({ title, subtitle }: PageTitleProps) => {
  return (
    <div>
      <h1 className="m-0 text-2xl font-bold text-content-base-default">
        {title}
      </h1>
      {subtitle && (
        <h2 className="m-0 text-base font-bold text-content-base-subdued">
          {subtitle}
        </h2>
      )}
    </div>
  );
};
