import { GraniteButton } from 'components/V2/Button/GraniteButton';
import FormSection from 'components/FormSection';
import Divider from 'components/Divider';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import {
  Controller,
  DeepPartial,
  FieldErrors,
  useForm,
  Control,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import RadioButton from 'components/RadioButton';
import { Dispatch, Fragment, useEffect, useMemo } from 'react';
import {
  FixMyServiceGraniteServices,
  GetStartedQuestionnaireBaseFormSchema,
  GetStartedQuestionnaireBaseForm,
  GraniteNotOwnedServiceSubForm,
  GraniteOwnedServiceSubForm,
  MyServiceIssues,
  WhyTechnicianEnum,
} from '../../../LetUsHelp/GetStarted/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorSubtext } from 'components/ErrorSubtext/ErrorSubtext';
import { Layout } from '../BaseComponents/Layout';
import { Form } from '../BaseComponents/Form';
import {
  useNewTemplateContext,
  WizardAction,
} from '../WizardProvider/WizardReducer';
import { preventSubmitOnEnter } from 'shared/util/preventSubmitOnEnter';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'hooks/useModal';
import { DuplicateTicketWarningDialog } from 'components/DuplicateTicketWarningDialog/DuplicateTicketWarningDialog';
import { Ticket } from 'api/techexpress/schema';
import MultiOptionButton from 'components/MultiOptionButton';
import clsx from 'clsx';
import {
  TicketDetailFormSchemas,
  TicketDetailFormUnion,
  getFormTypeFromGetStartedQuestionnaire,
} from '../../../LetUsHelp/TicketDetails/schemas';
import { ExtensionPOTS } from '../../../LetUsHelp/TicketDetails/RunExtension/ExtensionPOTS';
import {
  ExtensionCircuitForm,
  ExtensionPOTSForm,
} from '../../../LetUsHelp/TicketDetails/RunExtension/schemas';
import {
  TicketDetailForms,
  TicketDetailFormType,
} from '../../../LetUsHelp/TicketDetails/DefinedFormTypes';
import { ExtensionCircuit } from '../../../LetUsHelp/TicketDetails/RunExtension/ExtensionCircuit';

import { MoveForm } from '../../../LetUsHelp/TicketDetails/Move/schemas';
import { SmartHands } from '../../../LetUsHelp/TicketDetails/SmartHands/SmartHands';
import { SmartHandsForm } from '../../../LetUsHelp/TicketDetails/SmartHands/schemas';
import { Move } from '../../../LetUsHelp/TicketDetails/Move/Move';
import { Audit } from '../../../LetUsHelp/TicketDetails/Audit/Audit';
import { AuditForm } from '../../../LetUsHelp/TicketDetails/Audit/schemas';

import { EquipmentInstall } from '../../../LetUsHelp/TicketDetails/EquipmentInstall/EquipmentInstall';
import { EquipmentInstallForm } from '../../../LetUsHelp/TicketDetails/EquipmentInstall/schemas';
import { BroadbandCircuit } from '../../../LetUsHelp/TicketDetails/BroadbandCircuit/BroadbandCircuit';
import { BroadbandCircuitForm } from '../../../LetUsHelp/TicketDetails/BroadbandCircuit/schemas';
import { POTSCustomerLine } from '../../../LetUsHelp/TicketDetails/POTSCustomerLine/POTSCustomerLine';
import { POTSCustomerLineForm } from '../../../LetUsHelp/TicketDetails/POTSCustomerLine/schemas';
import { POTSGraniteLine } from '../../../LetUsHelp/TicketDetails/POTSGraniteLine/POTSGraniteLine';
import { POTSGraniteLineForm } from '../../../LetUsHelp/TicketDetails/POTSGraniteLine/schemas';
import { WiringCopper } from '../../../LetUsHelp/TicketDetails/WiringCopper/WiringCopper';
import { WiringCopperForm } from '../../../LetUsHelp/TicketDetails/WiringCopper/schemas';
import { WiringFiber } from '../../../LetUsHelp/TicketDetails/WiringFiber/WiringFiber';
import { WiringFiberForm } from '../../../LetUsHelp/TicketDetails/WiringFiber/schemas';
import { CustomerBroadbandCircuitForm } from '../../../LetUsHelp/TicketDetails/CustomerBroadbandCircuit/schemas';
import { CustomerBroadbandCircuit } from '../../../LetUsHelp/TicketDetails/CustomerBroadbandCircuit/CustomerBroadbandCircuit';

import { CustomerWiringCopper } from '../../../LetUsHelp/TicketDetails/CustomerWiringCopper/CustomerWiringCopper';
import { CustomerWiringCopperForm } from '../../../LetUsHelp/TicketDetails/CustomerWiringCopper/schemas';
import { CustomerWiringFiber } from '../../../LetUsHelp/TicketDetails/CustomerWiringFiber/CustomerWiringFiber';
import { CustomerWiringFiberForm } from '../../../LetUsHelp/TicketDetails/CustomerWiringFiber/schemas';
import { CustomerEquipmentForm } from '../../../LetUsHelp/TicketDetails/CustomerEquipment/schemas';
import { CustomerEquipment } from '../../../LetUsHelp/TicketDetails/CustomerEquipment/CustomerEquipment';
import { CustomerMaterial } from '../../../LetUsHelp/TicketDetails/CustomerMaterial/CustomerMaterial';
import { CustomerMaterialForm } from '../../../LetUsHelp/TicketDetails/CustomerMaterial/schemas';
import { dispatchReviewFormFromTicketDetailUnionForm } from 'screens/LetUsHelp/utils/dispatchReviewFormFromTicketDetailUnionForm';

const detailFormForTypeDispatcher = (
  detailFormType: TicketDetailFormType,
  register: UseFormRegister<TicketDetailFormUnion>,
  control: Control<TicketDetailFormUnion>,
  errors: FieldErrors<TicketDetailFormUnion>,
  watch: UseFormWatch<TicketDetailFormUnion>,
) => {
  switch (detailFormType) {
    case TicketDetailForms.enum.ExtensionPOTS:
      return (
        <ExtensionPOTS
          key={TicketDetailForms.enum.ExtensionPOTS}
          register={register as UseFormRegister<ExtensionPOTSForm>}
          control={control as Control<ExtensionPOTSForm>}
          errors={errors as FieldErrors<ExtensionPOTSForm>}
        />
      );
    case TicketDetailForms.enum.ExtensionCircuit: {
      return (
        <ExtensionCircuit
          key={TicketDetailForms.enum.ExtensionCircuit}
          register={register as UseFormRegister<ExtensionCircuitForm>}
          control={control as Control<ExtensionCircuitForm>}
          errors={errors as FieldErrors<ExtensionCircuitForm>}
        />
      );
    }
    case TicketDetailForms.enum.Move: {
      return (
        <Move
          key={TicketDetailForms.enum.Move}
          register={register as UseFormRegister<MoveForm>}
          control={control as Control<MoveForm>}
          errors={errors as FieldErrors<MoveForm>}
        />
      );
    }
    case TicketDetailForms.enum.SmartHands: {
      return (
        <SmartHands
          key={TicketDetailForms.enum.SmartHands}
          register={register as UseFormRegister<SmartHandsForm>}
          control={control as Control<SmartHandsForm>}
          errors={errors as FieldErrors<SmartHandsForm>}
        />
      );
    }
    case TicketDetailForms.enum.Audit: {
      return (
        <Audit
          key={TicketDetailForms.enum.Audit}
          register={register as UseFormRegister<AuditForm>}
          control={control as Control<AuditForm>}
          errors={errors as FieldErrors<AuditForm>}
        />
      );
    }
    case TicketDetailForms.enum.EquipmentInstall: {
      return (
        <EquipmentInstall
          key={TicketDetailForms.enum.EquipmentInstall}
          register={register as UseFormRegister<EquipmentInstallForm>}
          control={control as Control<EquipmentInstallForm>}
          errors={errors as FieldErrors<EquipmentInstallForm>}
        />
      );
    }
    case TicketDetailForms.enum.BroadbandCircuit: {
      return (
        <BroadbandCircuit
          key={TicketDetailForms.enum.BroadbandCircuit}
          register={register as UseFormRegister<BroadbandCircuitForm>}
          control={control as Control<BroadbandCircuitForm>}
          errors={errors as FieldErrors<BroadbandCircuitForm>}
        />
      );
    }
    case TicketDetailForms.enum.POTSGraniteLine: {
      return (
        <POTSGraniteLine
          key={TicketDetailForms.enum.POTSGraniteLine}
          register={register as UseFormRegister<POTSGraniteLineForm>}
          control={control as Control<POTSGraniteLineForm>}
          errors={errors as FieldErrors<POTSGraniteLineForm>}
        />
      );
    }
    case TicketDetailForms.enum.WiringCopper: {
      return (
        <WiringCopper
          key={TicketDetailForms.enum.WiringCopper}
          register={register as UseFormRegister<WiringCopperForm>}
          control={control as Control<WiringCopperForm>}
          errors={errors as FieldErrors<WiringCopperForm>}
        />
      );
    }
    case TicketDetailForms.enum.WiringFiber: {
      return (
        <WiringFiber
          key={TicketDetailForms.enum.WiringFiber}
          register={register as UseFormRegister<WiringFiberForm>}
          control={control as Control<WiringFiberForm>}
          errors={errors as FieldErrors<WiringFiberForm>}
        />
      );
    }
    case TicketDetailForms.enum.CustomerBroadbandCircuit: {
      return (
        <CustomerBroadbandCircuit
          key={TicketDetailForms.enum.CustomerBroadbandCircuit}
          register={register as UseFormRegister<CustomerBroadbandCircuitForm>}
          control={control as Control<CustomerBroadbandCircuitForm>}
          errors={errors as FieldErrors<CustomerBroadbandCircuitForm>}
        />
      );
    }
    case TicketDetailForms.enum.POTSCustomerLine: {
      return (
        <POTSCustomerLine
          key={TicketDetailForms.enum.POTSCustomerLine}
          register={register as UseFormRegister<POTSCustomerLineForm>}
          control={control as Control<POTSCustomerLineForm>}
          errors={errors as FieldErrors<POTSCustomerLineForm>}
        />
      );
    }
    case TicketDetailForms.enum.CustomerWiringCopper: {
      return (
        <CustomerWiringCopper
          key={TicketDetailForms.enum.CustomerWiringCopper}
          register={register as UseFormRegister<CustomerWiringCopperForm>}
          control={control as Control<CustomerWiringCopperForm>}
          errors={errors as FieldErrors<CustomerWiringCopperForm>}
        />
      );
    }
    case TicketDetailForms.enum.CustomerWiringFiber: {
      return (
        <CustomerWiringFiber
          key={TicketDetailForms.enum.CustomerWiringFiber}
          register={register as UseFormRegister<CustomerWiringFiberForm>}
          control={control as Control<CustomerWiringFiberForm>}
          errors={errors as FieldErrors<CustomerWiringFiberForm>}
        />
      );
    }
    case TicketDetailForms.enum.CustomerEquipment: {
      return (
        <CustomerEquipment
          key={TicketDetailForms.enum.CustomerEquipment}
          register={register as UseFormRegister<CustomerEquipmentForm>}
          control={control as Control<CustomerEquipmentForm>}
          errors={errors as FieldErrors<CustomerEquipmentForm>}
          watch={watch as UseFormWatch<CustomerEquipmentForm>}
        />
      );
    }
    case TicketDetailForms.enum.CustomerMaterial: {
      return (
        <CustomerMaterial
          key={TicketDetailForms.enum.CustomerMaterial}
          register={register as UseFormRegister<CustomerMaterialForm>}
          control={control as Control<CustomerMaterialForm>}
          errors={errors as FieldErrors<CustomerMaterialForm>}
        />
      );
    }
  }
};

interface GetStartedProps {
  dispatch: Dispatch<WizardAction>;
  questionnaire?: DeepPartial<GetStartedQuestionnaireBaseForm>;
  ticketDetail?: DeepPartial<TicketDetailFormUnion>;
  onCancel: () => void;
}

const Details = ({
  dispatch,
  questionnaire,
  ticketDetail,
  onCancel,
}: GetStartedProps) => {
  const navigate = useNavigate();
  const {
    watch: getStartedQuestionnaireFormWatch,
    ...getStartedQuestionnaireForm
  } = useForm<GetStartedQuestionnaireBaseForm>({
    resolver: zodResolver(GetStartedQuestionnaireBaseFormSchema),
    defaultValues: questionnaire,
  });

  useEffect(() => {
    const { unsubscribe } = getStartedQuestionnaireFormWatch((formData) => {
      dispatch({
        type: 'UpdateDetails',
        getStartedQuestionnaire: formData,
      });
    });
    return () => unsubscribe();
  }, [dispatch, getStartedQuestionnaireFormWatch]);

  useEffect(() => {
    const handleReloading = (event: BeforeUnloadEvent) => {
      if (getStartedQuestionnaireForm.formState.isDirty) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleReloading, { capture: true });

    return () => {
      window.removeEventListener('beforeunload', handleReloading, {
        capture: true,
      });
    };
  }, [getStartedQuestionnaireForm.formState.isDirty]);

  const { openWithProps, ...duplicateModalProps } = useModal<Ticket[]>();

  const onSubmit = async () => {
    const getStartedQuestionnaireFormIsValid =
      await getStartedQuestionnaireForm.trigger();
    const ticketDetailFormIsValid = await ticketDetailForm.trigger();

    if (getStartedQuestionnaireFormIsValid && ticketDetailFormIsValid) {
      dispatchReviewFormFromTicketDetailUnionForm(
        ticketDetailForm.getValues(),
        // @ts-expect-error dispatchReviewFormFromTicketDetailUnionForm uses the same GoToReview type
        dispatch,
        getStartedQuestionnaireForm.getValues(),
      );
      navigate('../review');
    } else {
      const errorElements = document.getElementsByClassName('error');
      if (errorElements.length > 0) {
        errorElements[0].scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const whyTechnician = getStartedQuestionnaireFormWatch('whyTechnician');

  useEffect(() => {
    if (whyTechnician && whyTechnician !== questionnaire?.whyTechnician) {
      dispatch({ type: 'ResetForms', keepDefaults: true });
    }
  }, [dispatch, questionnaire?.whyTechnician, whyTechnician]);

  const detailFormType = useMemo(() => {
    if (!questionnaire) return;
    if (
      questionnaire.whyTechnician === WhyTechnicianEnum.enum.FixMyService &&
      (questionnaire?.graniteOwned?.isGraniteOwned === undefined ||
        questionnaire?.graniteOwned?.service === undefined ||
        (questionnaire.graniteOwned.service.which ===
          FixMyServiceGraniteServices.Enum.Wiring &&
          //@ts-expect-error this exists
          questionnaire.graniteOwned.service?.copperOrFiber === undefined &&
          //@ts-expect-error this exists
          questionnaire.graniteOwned.service?.extensionType === undefined))
    )
      return;
    return getFormTypeFromGetStartedQuestionnaire(
      questionnaire as GetStartedQuestionnaireBaseForm,
    );
  }, [questionnaire]);

  const formType = detailFormType ?? ticketDetail?.formType;

  const {
    watch: ticketDetailFormWatch,
    setValue,
    ...ticketDetailForm
  } = useForm<TicketDetailFormUnion>({
    resolver: zodResolver(TicketDetailFormSchemas),
    defaultValues: {
      nonStandardTools: { isNeeded: false },
      ...ticketDetail,
    },
  });

  useEffect(() => {
    if (formType) setValue('formType', formType);
  }, [formType, setValue]);

  useEffect(() => {
    const { unsubscribe } = ticketDetailFormWatch((formData) => {
      dispatch({
        type: 'UpdateDetails',
        ticketDetailForm: formData,
      });
    });
    return () => unsubscribe();
  }, [dispatch, ticketDetailFormWatch]);

  useEffect(() => {
    const { unsubscribe } = ticketDetailFormWatch((formData) => {
      const isTechToTestWith = 'techToTestWith' in formData;
      if (
        !isTechToTestWith &&
        formData.formType === 'CustomerEquipment' &&
        formData.itTestingContacts?.length === 0
      ) {
        setValue('itTestingContacts', [{ contactName: '', contactNumber: '' }]);
      }
      if (isTechToTestWith) {
        if (formData.techToTestWith?.techToTestWith === 'No') {
          if (formData.itTestingContacts?.length !== 0) {
            setValue('itTestingContacts', []);
          }
        } else {
          if (formData.itTestingContacts?.length === 0) {
            setValue('itTestingContacts', [
              { contactName: '', contactNumber: '' },
            ]);
          }
        }
      }
    });
    return () => unsubscribe();
  }, [setValue, ticketDetailFormWatch]);

  return (
    <Layout>
      <Form
        className="gap-16"
        id="template-details-form"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
        onKeyDown={preventSubmitOnEnter}
      >
        <FormSection title="Ticket type">
          <Controller
            name="whyTechnician"
            control={getStartedQuestionnaireForm.control}
            render={({ field: { onChange, value, ref } }) => (
              <div className="gap grid gap-4 sm:grid-cols-2">
                <MultiOptionButton
                  label="Fix my service"
                  option={WhyTechnicianEnum.enum.FixMyService}
                  value={value}
                  onClick={onChange}
                  ref={ref}
                  subText="Technician will be dispatched to site to investigate, troubleshoot, or repair the service."
                  className="!justify-start"
                />
                <MultiOptionButton
                  label="Move equipment or wiring"
                  option={WhyTechnicianEnum.enum.MoveEquipmentOrWiring}
                  value={value}
                  onClick={onChange}
                  subText="Technician will be dispatched to site to move specific equipment or wiring from one location to another."
                  className="!justify-start"
                />
                <MultiOptionButton
                  label="Onsite tech support"
                  option={WhyTechnicianEnum.enum.OnsiteTechSupport}
                  value={value}
                  onClick={onChange}
                  subText="Technician will be dispatched to site to provide remote smart hands support."
                  className="!justify-start"
                />
                <MultiOptionButton
                  label="Site audit"
                  option={WhyTechnicianEnum.enum.SiteAudit}
                  value={value}
                  onClick={onChange}
                  subText="Technician will be dispatched to site to survey and record location & network specifications for future installs."
                  className="!justify-start"
                />
                <MultiOptionButton
                  label="Equipment install"
                  option={WhyTechnicianEnum.enum.EquipmentInstall}
                  value={value}
                  onClick={onChange}
                  subText="Technician will be dispatched to site to install or decommission equipment."
                  className="!justify-start"
                />
                <MultiOptionButton
                  label="Run extension"
                  option={WhyTechnicianEnum.enum.RunExtension}
                  value={value}
                  onClick={onChange}
                  subText="Technician will be dispatched to site to run an extension for an existing POTS line or circuit."
                  className="!justify-start"
                />
                {getStartedQuestionnaireForm.formState.errors.whyTechnician && (
                  <ErrorSubtext
                    className="-mt-2"
                    error="Selecting a reason is required"
                    withTopMargin={false}
                  />
                )}
              </div>
            )}
          />
          <div
            className={clsx(
              'col-span-full mt-6 hidden',
              whyTechnician === WhyTechnicianEnum.enum.FixMyService &&
                '!flex flex-col gap-6',
            )}
          >
            <Controller
              name="graniteOwned"
              shouldUnregister={false}
              control={getStartedQuestionnaireForm.control}
              render={({ field: { value, onChange, ref }, fieldState }) => (
                <Fragment>
                  <GraniteLabel
                    label="Is this a Granite-provided service?"
                    subtitle="Services that are provided, managed, or rebilled through Granite are Granite-provided"
                    element="div"
                  >
                    <RadioButton
                      options={[
                        { value: 'true', label: 'Yes' },
                        { value: 'false', label: 'No' },
                      ]}
                      selectedValue={`${value?.isGraniteOwned}`}
                      onChange={(newValue) =>
                        onChange({
                          isGraniteOwned: newValue === 'true',
                        })
                      }
                      className="text-base"
                      error={fieldState.error?.message}
                      ref={ref}
                    />
                  </GraniteLabel>
                  {value?.isGraniteOwned ? (
                    <Fragment>
                      <GraniteLabel
                        label="Which Granite service is experiencing an issue?"
                        element="div"
                      >
                        <RadioButton
                          options={[
                            {
                              value:
                                FixMyServiceGraniteServices.enum
                                  .BroadbandCircuit,
                              label: 'Broadband circuit',
                            },
                            {
                              value: FixMyServiceGraniteServices.enum.POTSLine,
                              label: 'POTS line',
                            },
                            {
                              value: FixMyServiceGraniteServices.enum.Wiring,
                              label: 'Wiring',
                            },
                          ]}
                          selectedValue={value.service?.which}
                          onChange={(newValue) =>
                            onChange({
                              ...value,
                              service: {
                                which: newValue,
                              },
                            })
                          }
                          ref={ref}
                          className="text-base"
                          error={
                            (
                              fieldState.error as FieldErrors<GraniteOwnedServiceSubForm>
                            )?.service?.message
                          }
                        />
                      </GraniteLabel>
                      {value.service?.which ===
                        FixMyServiceGraniteServices.enum.Wiring && (
                        <GraniteLabel
                          label="Is this a fiber or wire extension?"
                          element="div"
                        >
                          <RadioButton
                            options={[
                              {
                                value: 'Copper',
                                label: 'Copper',
                              },
                              { value: 'Fiber', label: 'Fiber' },
                            ]}
                            error={
                              (
                                fieldState.error as FieldErrors<GraniteOwnedServiceSubForm>
                              )?.service && 'Required'
                            }
                            selectedValue={value.service.copperOrFiber}
                            onChange={(newValue) =>
                              onChange({
                                ...value,
                                service: {
                                  ...value.service,
                                  copperOrFiber: newValue,
                                },
                              })
                            }
                            ref={ref}
                          />
                        </GraniteLabel>
                      )}
                    </Fragment>
                  ) : value?.isGraniteOwned === false ? (
                    <Fragment>
                      {/*  Add a key to GraniteLabel to avoid react reconciliation algo reusing the RadioButton with a previous state*/}
                      <GraniteLabel
                        label="Please select the best category for the issue you're experiencing"
                        element="div"
                        key={`granite-owned-${value?.isGraniteOwned}`}
                      >
                        <RadioButton
                          options={[
                            {
                              value: MyServiceIssues.enum.Circuit,
                              label: 'Circuit',
                            },
                            {
                              value: MyServiceIssues.enum.POTSLine,
                              label: 'POTS line',
                            },
                            {
                              value: MyServiceIssues.enum.Wiring,
                              label: 'Wiring',
                            },
                            {
                              value:
                                MyServiceIssues.enum.CustomerProvidedEquipment,
                              label: 'Customer provided equipment',
                            },
                            {
                              value: MyServiceIssues.enum.MaterialReplacement,
                              label:
                                'Material replacement (broken jack, faceplate, patch cord, etc.)',
                            },
                          ]}
                          selectedValue={value.service?.which}
                          onChange={(newValue) =>
                            onChange({
                              ...value,
                              service: {
                                which: newValue,
                              },
                            })
                          }
                          ref={ref}
                          className="text-base"
                          error={
                            (
                              fieldState.error as FieldErrors<GraniteNotOwnedServiceSubForm>
                            )?.service?.message
                          }
                        />
                      </GraniteLabel>
                      {value.service?.which === MyServiceIssues.enum.Wiring && (
                        <GraniteLabel
                          label="Is this a fiber or copper extension?"
                          element="div"
                          key={`${value.isGraniteOwned}-${value.service?.extensionType}`}
                        >
                          <RadioButton
                            options={[
                              {
                                value: 'Copper',
                                label: 'Copper',
                              },
                              { value: 'Fiber', label: 'Fiber' },
                            ]}
                            error={
                              (
                                fieldState.error as FieldErrors<GraniteNotOwnedServiceSubForm>
                              )?.service && 'Required'
                            }
                            selectedValue={value.service.extensionType}
                            onChange={(newValue) =>
                              onChange({
                                ...value,
                                service: {
                                  ...value.service,
                                  extensionType: newValue,
                                },
                              })
                            }
                            ref={ref}
                          />
                        </GraniteLabel>
                      )}
                    </Fragment>
                  ) : null}
                </Fragment>
              )}
            />
          </div>
          <div
            className={clsx(
              // We use the 'hidden' class instead
              // of conditionally rendering these components because we want to ensure
              // that they are present in the DOM. This is important for the auto-scrolling
              // functionality of react-hook-form to work correctly.
              'col-span-full mt-6 hidden',
              whyTechnician === WhyTechnicianEnum.enum.RunExtension &&
                '!flex flex-col gap-6',
            )}
          >
            <Controller
              name="potsOrCircuit"
              control={getStartedQuestionnaireForm.control}
              render={({ field: { value, onChange, ref }, fieldState }) => (
                <GraniteLabel
                  label="Is this for POTS or a circuit?"
                  element="div"
                >
                  <RadioButton
                    options={[
                      {
                        value: 'POTS',
                        label: 'POTS (plain old telephone service)',
                      },
                      { value: 'Circuit', label: 'Circuit' },
                    ]}
                    error={fieldState.error?.message}
                    selectedValue={value}
                    onChange={onChange}
                    ref={ref}
                  />
                </GraniteLabel>
              )}
            />
          </div>
        </FormSection>
        {detailFormType && (
          <Fragment>
            <Divider />
            {detailFormForTypeDispatcher(
              detailFormType,
              ticketDetailForm.register,
              ticketDetailForm.control,
              ticketDetailForm.formState.errors,
              ticketDetailFormWatch,
            )}
          </Fragment>
        )}
      </Form>
      <div className="sticky top-8 flex gap-4 rounded bg-background-base-surface-2 p-6 shadow">
        <GraniteButton
          variant="secondary"
          size="large"
          className="w-full"
          onClick={onCancel}
        >
          Cancel
        </GraniteButton>
        <GraniteButton
          className="w-full"
          size="large"
          type="submit"
          form="template-details-form"
          disabled={
            getStartedQuestionnaireForm.formState.isSubmitting ||
            ticketDetailForm.formState.isSubmitting
          }
        >
          Next
        </GraniteButton>
      </div>
      <DuplicateTicketWarningDialog
        duplicates={duplicateModalProps.dynamicProps}
        {...duplicateModalProps}
      />
    </Layout>
  );
};

export const DetailsFormWrapper = () => {
  const { dispatch, state, onCancel } = useNewTemplateContext();

  const questionnaireDefaults = {
    ...state.getStartedQuestionnaire,
  };

  if (state.state !== 'Details') {
    const questionnaire = GetStartedQuestionnaireBaseFormSchema.safeParse(
      questionnaireDefaults,
    );

    if (questionnaire.success) {
      dispatch({
        type: 'GoToDetails',
        getStartedQuestionnaire: questionnaire.data,
      });
    } else {
      dispatch({ type: 'ResetForms' });
    }

    // Return Fragment for first-render, once dispatch triggers re-render we should get the proper form
    return <Fragment />;
  }

  return (
    <Details
      dispatch={dispatch}
      questionnaire={state.getStartedQuestionnaire}
      ticketDetail={state.ticketDetailForm}
      onCancel={onCancel}
    />
  );
};
