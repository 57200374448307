import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import Logo from 'components/Logo';
import { useNavigate, useSearchParams } from 'react-router-dom';

const errorMessageMap: Record<string, { title: string; content: string }> = {
  'Invitation does not exist': {
    title: 'Oops! Invite expired',
    content:
      'Your invitation has expired. Please reach out to g360@granitenet.com for a new invitation.',
  },
  default: {
    title: 'Oops! Something went wrong.',
    content:
      'There was an unexpected error. Return to login to restart your session.',
  },
};

const InvitationErrorModal = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const message = searchParams.get('message') || 'default';
  const { title, content } =
    errorMessageMap[message] || errorMessageMap.default;

  return (
    <div className="m-auto h-screen w-screen">
      <Modal
        isVisible={true}
        close={close}
        className="w-[624px]"
        overlayClassName="bg-background-base-surface-1"
        hideCloseButton
      >
        <div className="flex flex-col items-start gap-8 rounded bg-background-base-surface-2 px-8 py-6">
          <Logo />
          <div>
            <h1 className="mb-1 rounded-t-lg text-[28px] font-bold leading-9 text-content-base-default">
              {title}
            </h1>
            <p className="text-xl font-semibold text-content-base-subdued">
              {content}
            </p>
          </div>
          <div className="mt-4 flex gap-5">
            <GraniteButton
              size="large"
              variant="primary"
              onClick={() => navigate('/')}
            >
              Return to login
            </GraniteButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InvitationErrorModal;
