import { PatternFormat } from 'react-number-format';
import { GraniteInput, GraniteInputProps } from './GraniteInput';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { ComponentProps } from 'react';

interface PhoneNumberInputProps<T extends FieldValues>
  extends Omit<
    ComponentProps<typeof PatternFormat>,
    'format' | 'mask' | 'type'
  > {
  name: Path<T>;
  control: Control<T>;
  label: string;
  error?: string;
  className?: string;
  format?: string;
  placeholder?: string;
  onUpdate?: (value: string) => void;
}

const PhoneNumberInput = <T extends FieldValues>({
  name,
  control,
  label,
  error,
  className,
  onUpdate,
  format = '(###) ###-####',
  placeholder = '(---) --- ----',
  ...props
}: PhoneNumberInputProps<T> & GraniteInputProps) => {
  const { field } = useController({ name, control });
  const { onChange, ref, ...rest } = field;

  return (
    <PatternFormat
      {...props}
      {...rest}
      getInputRef={ref}
      type="tel"
      label={label}
      error={error}
      mask="_"
      format={format}
      placeholder={placeholder}
      onValueChange={({ value }) => {
        onChange(value);
        if (onUpdate) {
          onUpdate(value);
        }
      }}
      customInput={GraniteInput}
      className={className}
    />
  );
};

export default PhoneNumberInput;
