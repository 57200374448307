import { GetStartedQuestionnaireForm } from '../../GetStarted/schemas';
import { CustomerWiringFiberForm } from './schemas';
import { ReviewTicketForm } from '../../Review/schemas';
import { questionnaireFormToReviewTicketDefaultValues } from '../../BaseComponents/questionnaireFormToReviewTicketDefaultValues';
import {
  NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX,
  NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX,
} from '../../utils/nonStandardToolsSOWUtils';
import { formatContactTesting } from 'shared/util/util';

export const customerWiringFiberFormToReview = (
  questionnaire: GetStartedQuestionnaireForm,
  form: CustomerWiringFiberForm,
): Partial<ReviewTicketForm> => {
  return {
    ...questionnaireFormToReviewTicketDefaultValues(questionnaire),
    scopeOfWork: formToSOW(form),
    nonStandardTools: form.nonStandardTools,
  };
};

const formToSOW = (form: CustomerWiringFiberForm) => {
  return [
    form.nonStandardTools.isNeeded
      ? `${NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX} ${form.nonStandardTools.nonStandardTools} ${NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX}\n`
      : '',
    `Service technician needed on site to repair an existing Fiber extension.`,
    `\nThe extension is currently connected to the following circuit: ${form.serviceDescription}.`,
    form.mpoe
      ? `\nThe MPOE (Minimum Point of Entry) for the circuit is ${form.mpoe}.`
      : '',
    form.whatIsUsedFor
      ? `\nThe circuit is currently being used for ${form.whatIsUsedFor}.`
      : '',
    form.techToTestWith.techToTestWith !== 'No'
      ? `\nThe technician should test with: ${formatContactTesting(
          form.itTestingContacts,
        )}`
      : '',
  ]
    .filter((l) => l)
    .join('\n');
};
