import { useProductTourContext } from 'context/ProductTourContext';
import React, {
  FC,
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from 'react';
import { StepDetail, TourSteps, getStepDetails } from './steps';
import { sections as sectionsData } from './CustomTooltip';
import { useAuthUser } from 'hooks/useAuthUser';
import { TokenUserRoles } from 'api/users/schemas/Users';
import Confetti from 'react-confetti';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

interface ProgressTrackerProps {}

const Checkbox = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="2" width="20" height="20" rx="10" fill="#82F0FF" />
    <path
      d="M8 12L11 15L16 9"
      stroke="#191925"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

interface ConfettiInDivProps {
  targetRef: React.RefObject<HTMLDivElement>;
  showConfetti: boolean;
}

const ConfettiInDiv: FC<ConfettiInDivProps> = ({ targetRef, showConfetti }) => {
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
    x: number;
    y: number;
  }>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (targetRef.current) {
      const { width, height, left, top } =
        targetRef.current.getBoundingClientRect();
      setDimensions({
        width,
        height,
        x: left,
        y: top,
      });
    }
  }, [targetRef, showConfetti]);

  if (!showConfetti) return null;

  return createPortal(
    <div
      className="pointer-events-none fixed"
      style={{
        width: dimensions.width,
        height: dimensions.height,
        top: dimensions.y,
        left: dimensions.x,
        zIndex: 1000,
      }}
    >
      <Confetti
        width={dimensions.width}
        height={dimensions.height}
        numberOfPieces={200}
        gravity={0.09}
        colors={['#82f0ff', '#455e8e', '#fbb979']}
        recycle={false}
      />
    </div>,
    document.body,
  );
};

const ProgressTracker: FC<ProgressTrackerProps> = () => {
  const { stepIndex, completedSections, markSectionAsCompleted, progressData } =
    useProductTourContext();
  const { roles } = useAuthUser();

  const [showConfetti, setShowConfetti] = useState(false);
  const confettiRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (stepIndex === TourSteps.LAST_STEP) {
      setShowConfetti(true);
    } else setShowConfetti(false);
  }, [stepIndex]);

  const hasAccessOnUserManagement = useMemo(() => {
    const acceptableAppRoles = [
      TokenUserRoles.COMPANY_ADMIN,
      TokenUserRoles.SUPER_ADMIN,
    ];
    return roles
      ? roles.some((role) => acceptableAppRoles.includes(role))
      : false;
  }, [roles]);

  type SectionKeys =
    | 'dashboard'
    | 'techexpress'
    | 'nocexpress'
    | 'mobility'
    | 'user_management';

  const items = useMemo(() => {
    const initialItems = [
      { name: 'Welcome dashboard', section: 'dashboard', label: 'Dashboard' },
      {
        name: 'Explore TechExpress',
        section: 'techexpress',
        label: 'TechExpress',
      },
      {
        name: 'Explore NOCExpress',
        section: 'nocexpress',
        label: 'NOCExpress',
      },
      { name: 'Explore Mobility', section: 'mobility', label: 'Mobility' },
    ];

    const isQRAdmin = roles.some((role) => role === TokenUserRoles.QR_ADMIN);

    if (isQRAdmin) {
      return initialItems.filter((item) => item.section === 'dashboard');
    }

    if (hasAccessOnUserManagement) {
      initialItems.push({
        name: 'User management',
        section: 'user_management',
        label: 'User Management',
      });
    }
    return initialItems;
  }, [hasAccessOnUserManagement, roles]);

  // Define the type for sections
  const sections: Record<SectionKeys, number[]> = sectionsData;

  // Determine the current section based on the current step
  const currentSectionKey = Object.keys(sections).find((section) =>
    sections[section as SectionKeys].includes(stepIndex),
  ) as SectionKeys;

  const stepDetails = getStepDetails();
  const filteredSections = filterSectionsByUserRole(
    roles,
    sections,
    stepDetails,
  );

  const currentSectionSteps = filteredSections[currentSectionKey];

  // Mark sections as completed when the current step is in them
  const markCompletedSections = useCallback(() => {
    Object.keys(sections).forEach((section) => {
      if (
        sections[section as SectionKeys].every((item) => item < stepIndex) &&
        !completedSections.has(section)
      ) {
        markSectionAsCompleted(section);
      }
    });
  }, [sections, stepIndex, completedSections, markSectionAsCompleted]);

  useEffect(() => {
    markCompletedSections();
  }, [markCompletedSections]);

  return (
    <div className="">
      <div className="rounded-t bg-background-base-surface-3 px-8 py-6">
        <div
          className={clsx(
            'text-[20px] font-bold leading-[24px] text-content-base-default',
            currentSectionSteps?.length && 'mb-6',
          )}
        >
          Your progress
        </div>
        {currentSectionSteps?.length ? (
          <div className="flex h-[6px] items-end space-x-1">
            {currentSectionSteps.map((step) => (
              <div
                key={step}
                className={`h-1.5 flex-1 rounded ${
                  step <= stepIndex ? 'bg-status-info-default' : 'bg-[#475569]'
                }`}
              ></div>
            ))}
          </div>
        ) : null}
      </div>
      <div ref={confettiRef} className="relative space-y-4 p-8">
        <ConfettiInDiv targetRef={confettiRef} showConfetti={showConfetti} />
        {items?.map((item) => (
          <div key={item.name} className="flex items-center space-x-2">
            <div>
              {progressData.some((data) => data.section === item.label) ? (
                <Checkbox />
              ) : (
                <div className="mx-[2px] h-[20px] w-[20px] rounded-full border border-input-stroke-unfilled bg-input-background-unfilled"></div>
              )}
            </div>
            <div
              className={
                progressData.some((data) => data.section === item.label)
                  ? 'text-base font-medium text-content-accent-default'
                  : 'text-base font-medium text-content-base-default'
              }
            >
              {item.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressTracker;

const filterSectionsByUserRole = (
  roles: TokenUserRoles[],
  sections: Record<string, number[]>,
  stepDetails: StepDetail[],
) => {
  const filteredSections: Record<string, number[]> = {};

  Object.keys(sections).forEach((section) => {
    filteredSections[section] = sections[section].filter((stepNumber) => {
      const stepDetail = stepDetails.find(
        (detail) => detail.step === stepNumber,
      );
      if (stepDetail?.hasAccess) {
        return stepDetail.hasAccess(roles);
      }
      return true; // If no hasAccess attribute, assume the step is accessible
    });
  });

  return filteredSections;
};
