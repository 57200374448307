import { z } from 'zod';
import {
  LETTERS_SPACES_HYPENS_ERROR_LABEL,
  VALID_PHONE_ERROR_LABEL,
} from 'shared/constants/error-labels';
import {
  LETTERS_SPACES_HYPENS_REGEXP,
  VALID_PHONE_NUMBER,
} from 'shared/constants/validation-regex-constants';
import { OptionLabelValueSchema } from 'screens/LetUsHelp/utils/schemas';

export const ESCALATION_REQUESTED = 'Escalation Requested';
export const ESCALATION_REVIEWED = 'Escalation Reviewed';
export const ESCALATION_IN_PROCESS = 'Escalation In Process';
export const ESCALATION_REJECTED = 'Escalation Rejected';

export const NetOpsTicketIssue = z.enum([
  'Add Service',
  'Alert Testing',
  'Allow/Block Traffic',
  'Assumed Dial/Prepend',
  'Audio Drop-Outs',
  'Battery Failover',
  'Battery Problem',
  "Can't Be Called",
  "Can't Call Long Distance",
  "Can't Call Out",
  'Cellular',
  'Change Service',
  'Comm-Failure',
  'Content Filtering',
  'Degraded Service',
  'Device Offline',
  'Disconnect Service',
  'Dropped Calls',
  'Equipment Issue',
  'Equipment Update',
  'Ethernet',
  'Extension Dialing',
  'Fax Issue',
  'Fax Trouble',
  'Feature Issue',
  'Firewall Rule Change',
  'Fraud Alert',
  'Firmware Update',
  'Hard Down',
  'High Latency',
  'High Memory Usage',
  'High Utilization',
  'Informational',
  'Intermittent Service',
  'LAN Hard Down',
  'Packet Loss',
  'Project',
  'Service Down',
  'Slow Speeds',
  'WAN Hard Down',
]);

export const NetOpsTicketService = z.enum([
  'GRID',
  'Broadband',
  'DIA',
  'Private IP',
  'Granite Switched Ethernet',
  'MPLS',
  'Rebill - DIA',
  'Rebill - Broadband',
  'edgeboot',
  'Managed SD-WAN',
  'Managed Switch',
  'Managed Wi-Fi',
  'Proactive Ticketing',
  'Advanced Monitoring',
  'EPIK',
  'Mobility Access / M2M',
  'Wireless Voice',
  'SIP',
  'Hosted PBX',
  'ePOTS',
  'Toll-Free Number',
  'RCF',
]);

const MAX_TOTAL_SIZE = 25 * 1024 * 1024; // 25 MB in bytes

export const EditNocTicket = z.object({
  issue: z.object({ value: z.string(), label: z.string() }),
  tns_impacted: z.string().trim().max(1000).optional(),
  customer_ticket_number: z.string().optional(),
  prior_ticket: z.string().or(z.literal('')).optional(),
  local_contact_name: z
    .string()
    .trim()
    .nonempty('Required')
    .max(50, 'Only 50 characters allowed')
    .regex(LETTERS_SPACES_HYPENS_REGEXP, {
      message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
    }),
  local_contact_number: z
    .string()
    .nonempty(VALID_PHONE_ERROR_LABEL)
    .length(10, VALID_PHONE_ERROR_LABEL)
    .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
  additional_contacts: z
    .array(
      z.object({
        type: OptionLabelValueSchema.optional().refine(
          (data) => data?.label && data?.value,
          {
            message: 'Required',
          },
        ),
        name: z
          .string()
          .trim()
          .nonempty('Required')
          .max(50, 'Only 50 characters allowed')
          .regex(LETTERS_SPACES_HYPENS_REGEXP, {
            message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
          }),
        number: z
          .string()
          .nonempty(VALID_PHONE_ERROR_LABEL)
          .length(10, VALID_PHONE_ERROR_LABEL)
          .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
      }),
    )
    .max(2),
  access_hours: z.string().trim().max(1000).nonempty('Required'),
  technician_dispatch_approved: z.boolean().optional(),
  recipients: z.array(z.object({ email: z.string().email().nonempty() })),
  automatic_email_cc_address: z.string().optional(),
  attachments: z
    .array(z.any())
    .optional()
    .refine(
      (attachments) => {
        if (!attachments) return true;
        const totalSize = attachments.reduce((acc, file) => acc + file.size, 0);
        return totalSize <= MAX_TOTAL_SIZE;
      },
      {
        message: 'Total attachments size must not exceed 25MB!',
      },
    ),
});

const TicketDetailsWithMaintenanceRequired = EditNocTicket.extend({
  canTestingBeDoneAnytime: z.literal(false),
  maintenance_window: z.string().nonempty('Required'),
});

const TicketDetailsWithMaintenanceOptional = EditNocTicket.extend({
  canTestingBeDoneAnytime: z.literal(true),
  maintenance_window: z.string().optional(),
});

const TicketDetailsWithMaintenanceUndefined = EditNocTicket.extend({
  canTestingBeDoneAnytime: z.undefined(),
  maintenance_window: z.string().optional(),
});

export const EditTicketDetailsFormSchema = z.discriminatedUnion(
  'canTestingBeDoneAnytime',
  [
    TicketDetailsWithMaintenanceRequired,
    TicketDetailsWithMaintenanceOptional,
    TicketDetailsWithMaintenanceUndefined,
  ],
);

export const NetOpsResponse = z.object({
  id: z.number(),
  summary: z.string(),
  city: z.string(),
  issue: z.string(),
  state_identifier: z.string(),
  zip: z.string(),
  item: z.string().nullable(),
  status_name: z.string(),
  last_updated: z.string(),
  date_entered: z.string(),
  company_name: z.string(),
  site_name: z.string().nullable(),
  address_1: z.string(),
  address_2: z.string(),
  location_name: z.string().nullable(),
  customer_ticket_number: z.string().nullable(),
  prior_ticket: z.number().nullable(),
  tns_impacted: z.string().nullable(),
  initial_description: z.string().nullable(),
  type_name: z.string(),
  sub_type: z.string(),
  technician_name: z.string().nullable(),
  special_instructions: z.string().nullable(),
  attachments: z.array(z.any()).optional(),
  automatic_email_contact: z.boolean().nullable(),
  contact_email_address: z.string().nullable(),
  automatic_email_cc: z.boolean().nullable(),
  automatic_email_cc_address: z.string().nullable(),
  maintenance_window: z.string().nullable(),
  service_id_label: z.string().nullable(),
  chronic_flag: z.boolean(),
  sites: z
    .array(
      z.object({
        id: z.string(),
        address_line_1: z.string(),
        city: z.string(),
        state: z.string(),
        zip: z.string(),
        account_name: z.string(),
        maintenance_window: z.string(),
        is_chronic: z.boolean().default(false),
        parent_account: z.string(),
      }),
    )
    .optional(),
  g360_escalated: z.boolean().optional(),
  escalation_status: z.string().optional(),
  g360_status: z.string(),
  configurations: z.array(z.any()),
  priority: z.string().optional(),
  company_identifier: z.string(),
  is_followed: z.boolean(),
  rca_requested: z.boolean(),
  local_contact_name: z.string().nullable(),
  local_contact_number: z.string().nullable(),
  additional_contacts: z
    .array(
      z.object({
        type: z.string(),
        name: z.string(),
        number: z.number(),
      }),
    )
    .nullable(),
  access_hours: z.string(),
  technician_dispatch_approved: z.boolean(),
});
export type NetOpsResponseForm = z.infer<typeof NetOpsResponse>;

export type EditNocTicketForm = z.infer<typeof EditTicketDetailsFormSchema>;

export const EscalateTicketForm = z.object({
  reason: z.object({ value: z.string(), label: z.string() }),
  additional_notes: z.string().optional().or(z.literal('')),
});

export const EscalateTicketRequest = z.object({
  reason: z.string(),
  additional_notes: z.string().optional().or(z.literal('')),
});

export type EscalateTicketRequestType = z.infer<typeof EscalateTicketRequest>;

export type EscalateTicketFormType = z.infer<typeof EscalateTicketForm>;

export const CloseAndResolveTicketForm = z.object({
  reason: z.object({ value: z.string(), label: z.string() }),
  additional_notes: z.string().optional().or(z.literal('')),
});

export const CloseAndResolveTicketRequest = z.object({
  resolution_reason: z.string(),
  additional_details: z.string().optional().or(z.literal('')),
});

export type CloseAndResolveTicketRequestType = z.infer<
  typeof CloseAndResolveTicketRequest
>;

export type CloseAndResolveTicketFormType = z.infer<
  typeof CloseAndResolveTicketForm
>;

export const FollowTicketRequest = z.object({
  follow: z.boolean(),
});

export type FollowTicketRequestType = z.infer<typeof FollowTicketRequest>;
