import React, { ReactNode } from 'react';
import {
  HeaderContainer,
  Title,
  TitleActionButtonContainer,
} from './PageTitle.styles';
import { Breadcrumb, BreadcrumbItem } from '../Breadcrumb/Breadcrumb';
import { GraniteButton } from 'components/V2/Button/GraniteButton';

export interface PageTitleProps {
  title: string | React.ReactNode;
  breadcrumbs?: BreadcrumbItem[];
  buttonAction?: {
    action: () => void;
    label: string;
    className?: string;
  };
  children?: ReactNode;
  className?: string;
  heightSpace?: boolean;
}

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  breadcrumbs,
  buttonAction,
  children,
  className,
  heightSpace,
}) => {
  return (
    <HeaderContainer className={className}>
      <div>
        {breadcrumbs && <Breadcrumb breadcrumbs={breadcrumbs} />}
        {typeof title === 'string' ? (
          <Title heightSpace={heightSpace}>{title}</Title>
        ) : (
          title
        )}
      </div>
      {children}
      {buttonAction && (
        <TitleActionButtonContainer>
          <GraniteButton
            size="large"
            onClick={buttonAction.action}
            className={buttonAction.className}
          >
            {buttonAction.label}
          </GraniteButton>
        </TitleActionButtonContainer>
      )}
    </HeaderContainer>
  );
};

export default PageTitle;
