import Divider from 'components/Divider';
import { Modal } from 'components/Modal/Modal';
import { DarkSparkles } from 'components/Sparkles/DarkSparkles';
import { LightSparkles } from 'components/Sparkles/LightSparkles';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ModalParams } from 'hooks/useModal';
import { Trophy } from 'react-ionicons';
import { toSentenceCase } from 'shared/util/util';

interface PackageDetailsProps extends ModalParams {
  type: 'performance' | 'budget' | 'recommended';
}

export const PackageDetailsDialog = ({
  type,
  isOpen,
  close,
}: PackageDetailsProps) => {
  const getIcon = () => {
    switch (type) {
      case 'recommended':
        return <Trophy color={'inherit'} width={'21px'} height={'21px'} />;
      case 'performance':
        return <DarkSparkles />;
      case 'budget':
        return <LightSparkles />;
      default:
        return <Trophy />;
    }
  };

  return (
    <Modal isVisible={isOpen} close={close}>
      <div className="flex h-auto w-[624px] flex-col items-start justify-start gap-12 rounded bg-background-base-surface-2 p-8">
        <div className="flex items-center justify-center gap-2 ">
          <span className="fill-yellow-400 text-yellow-400">{getIcon()}</span>
          <p className="text-2xl font-bold text-content-base-default">
            {toSentenceCase(type)}
          </p>
        </div>
        <div className="flex w-full flex-col items-start justify-start rounded bg-background-base-surface-1 ">
          <div className="flex w-full flex-col items-start justify-start gap-2 p-4">
            <div className="grid w-full grid-cols-2">
              <p className="text-base font-bold text-content-base-subdued">
                Carriers
              </p>
              <p className="text-base font-bold text-content-base-default">
                Comcast, Verizon
              </p>
            </div>
            <Divider className="w-full" />
            <div className="grid w-full grid-cols-2">
              <p className="text-base font-bold text-content-base-subdued">
                Speeds
              </p>
              <p className="text-base font-bold text-content-base-default">
                100-200mbps
              </p>
            </div>
            <Divider className="w-full" />
            <div className="grid w-full grid-cols-2">
              <p className="text-base font-bold text-content-base-subdued">
                Term length
              </p>
              <p className="text-base font-bold text-content-base-default">
                3 years
              </p>
            </div>
            <Divider className="w-full" />
            <div className="grid w-full grid-cols-2">
              <p className="text-base font-bold text-content-base-subdued">
                Type of connection
              </p>
              <p className="text-base font-bold text-content-base-default">
                Fast ethernet
              </p>
            </div>
            <Divider className="w-full" />

            <div className="grid w-full grid-cols-2">
              <p className="text-base font-bold text-content-base-subdued">
                Recurring monthly cost
              </p>
              <p className="text-base font-bold text-content-base-default">
                $70.00 - $100.00
              </p>
            </div>
            <Divider className="w-full" />
            <div className="grid w-full grid-cols-2">
              <p className="text-base font-bold text-content-base-subdued">
                Non-recurring costs
              </p>
              <p className="text-base font-bold text-content-base-default">
                $50.00-60.00
              </p>
            </div>
            <Divider className="w-full" />
            <div className="grid w-full grid-cols-2">
              <p className="text-base font-bold text-content-base-subdued">
                Term length
              </p>
              <p className="text-base font-bold text-content-base-default">
                3 years
              </p>
            </div>
          </div>
        </div>
        <GraniteButton variant="secondary" size="large" onClick={close}>
          Close
        </GraniteButton>
      </div>
    </Modal>
  );
};
