import Tabs from 'components/Table/Tabs';
import { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { GraniteButton } from 'components/V2/Button/GraniteButton';

const RolesPermissions = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const breadcrumbs = [
    {
      icon: 'home',
      label: 'User management',
      onClick: () => navigate('/users'),
    },
    {
      label: 'Role management',
    },
  ];

  const tabs = useMemo(() => {
    return [
      {
        title: 'Roles',
        onClick: () => navigate(''),
      },
      {
        title: 'Permissions',
        onClick: () => navigate('permissions'),
      },
    ];
  }, [navigate]);

  return (
    <ContentLayout>
      <div className="mb-12 flex items-center justify-between gap-2">
        <PageTitle title="Role management" breadcrumbs={breadcrumbs} />
        <div className="flex gap-4">
          <GraniteButton
            size="large"
            variant="secondary"
            onClick={() => navigate('permissions/create', { replace: true })}
          >
            Create permission
          </GraniteButton>
          <GraniteButton
            size="large"
            variant="primary"
            onClick={() => navigate('create', { replace: true })}
          >
            Create role
          </GraniteButton>
        </div>
      </div>
      <Tabs
        className="mb-8"
        variant="medium"
        defaultActiveTab={pathname.includes('/permissions') ? 1 : 0}
        tabs={tabs}
      />
      <Outlet />
    </ContentLayout>
  );
};

export default RolesPermissions;
