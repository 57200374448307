import { Cog } from 'react-ionicons';
import AlertCard from './AlertCard';
import { useNavigate } from 'react-router-dom';
import ContactGroups from './ContactGroups/ContactGroups';
import { DisplayForPermission } from 'components/Permission/DisplayForPermission';
import { TokenUserPermissions } from 'api/users/schemas/Users';

export const alertData = [
  {
    name: 'Cellular signal',
    active_device_number: 10,
  },
  {
    name: 'Connection down',
    active_device_number: 0,
  },
  {
    name: 'Device down',
    active_device_number: 20,
  },
  {
    name: 'Device failover',
    active_device_number: 0,
  },
  {
    name: 'Latency',
    active_device_number: 1,
  },
  {
    name: 'Second row',
    active_device_number: 13,
  },
  {
    name: 'Second row',
    active_device_number: 13,
  },
];

const Alerting = () => {
  const navigate = useNavigate();

  const onClick = (event: React.MouseEvent<HTMLDivElement>) =>
    navigate(`alert-configuration/${event.currentTarget.id}`);

  return (
    <div className="flex flex-col gap-16">
      <div>
        <h2 className="flex items-center gap-4 text-2.5xl font-bold text-white">
          <Cog width="32px" height="32px" color={'#fff'} /> Alert configuration
        </h2>
        <div className="mt-6 grid grid-cols-3 gap-6 lg:grid-cols-5">
          {alertData.map((d, i) => (
            <AlertCard
              key={i}
              onClick={onClick}
              size="large"
              state={!!d.active_device_number}
              {...d}
            />
          ))}
        </div>
      </div>
      <DisplayForPermission
        permission={TokenUserPermissions.CONTACT_GROUPS_READ}
      >
        <ContactGroups />
      </DisplayForPermission>
    </div>
  );
};

export default Alerting;
