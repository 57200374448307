import { ColumnDef } from '@tanstack/react-table';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import { TableTitle } from 'components/Table/Table.styles';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import { useSortedTable } from 'hooks/useSortedTable';
import { useState } from 'react';
import { People } from 'react-ionicons';
import { EmptySearchResults } from 'screens/QuoteIndex/QuoteIndexPage';
import { getContactGroups } from '../utils';
import { PaginableRequest } from 'api/common-schemas';
import clsx from 'clsx';
import Tag from 'components/Tag';
import { TokenUserPermissions } from 'api/users/schemas/Users';
import { Modal } from 'components/Modal/Modal';
import { useModal } from 'hooks/useModal';
import ContactGroupEditForm from './Form/ContactGroupEditForm';
import AddContactGroup from './AddContactGroup';
import DeactivateGroup from './DeactivateGroup';
import ReactiveGroup from './ReactiveGroup';
import RemoveMeFromGroup from './RemoveMeFromGroup';
import { ContactGroupListItemType } from './Form/schema';
import { DisplayForPermission } from 'components/Permission/DisplayForPermission';
import { usePermissions } from 'hooks/usePermissions';

const ContactGroups = () => {
  // temporary comment
  // const [search, setSearch] = useState<string>('');
  const [editGroup, setEditGroup] = useState<ContactGroupListItemType | null>(
    null,
  );

  const { hasPermission } = usePermissions({
    permission: [TokenUserPermissions.CONTACT_GROUPS_WRITE],
  });

  const { sortingQueryParams, sortingState, onSortingChange } = useSortedTable(
    {},
  );

  const _getContactGroups = (args: PaginableRequest) =>
    getContactGroups({ ...args, ...sortingQueryParams });

  const {
    data: tablePageData,
    refetch,
    ...paginatedTableProps
  } = usePaginatedTable(
    _getContactGroups,
    {
      // temporary comment
      // search,
      sortingQueryParams,
    },
    [
      'alert-contact-groups-table',
      // temporary comment
      // search,
      sortingQueryParams,
    ],
    {
      refetchOnMount: true,
    },
  );

  const { open, isOpen, close } = useModal();

  const onEdit = (group: ContactGroupListItemType) => {
    setEditGroup(group);
    open();
  };

  const tableHeaders: ColumnDef<ContactGroupListItemType>[] = [
    {
      header: 'Group name',
      accessorKey: 'last_name',
      cell: (row) => row.row.original.last_name,
    },
    {
      header: 'Email recipients',
      accessorKey: 'emails',
      cell: (row) => (
        <div className="flex gap-2">
          {row.row.original.emails.map((email, i) => (
            <Tag
              key={i}
              content={{ type: 'label', label: email }}
              status="neutral"
              style="default"
            />
          ))}
        </div>
      ),
    },
    {
      header: 'Devices',
      // accessorKey: 'device_number',
      // cell: (row) => (
      //   <NumericCell>{row.row.original.device_number}</NumericCell>
      // ),
      meta: {
        align: 'right',
      },
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: (row) => (
        <p>
          <span
            className={clsx(
              'mr-2 inline-flex h-2.5 w-2.5 rounded-full',
              row.row.original.status === 'active' && 'bg-green-400',
              row.row.original.status === 'inactive' && 'bg-neutral-500',
            )}
          />
          {row.row.original.status[0].toUpperCase()}
          {row.row.original.status.slice(1)}
        </p>
      ),
    },
    {
      header: 'Actions',
      cell: (row) => (
        <div className="mr-1 flex gap-3">
          <GraniteButton
            variant="secondary"
            size="small"
            onClick={() => onEdit(row.row.original)}
          >
            {!hasPermission ? 'View' : 'Edit'}
          </GraniteButton>
          <DisplayForPermission
            permission={TokenUserPermissions.CONTACT_GROUPS_WRITE}
          >
            {row.row.original.status === 'active' && (
              <DeactivateGroup
                label="Deactivate"
                size="small"
                contactGroupId={row.row.original.id}
                refetchOnSuccess={refetch}
              />
            )}
            {row.row.original.status === 'inactive' && (
              <ReactiveGroup
                label=" Reactivate"
                size="small"
                contactGroupId={row.row.original.id}
                refetchOnSuccess={refetch}
              />
            )}
          </DisplayForPermission>
          {!hasPermission && (
            <RemoveMeFromGroup
              label="Remove"
              size="small"
              contactGroupId={row.row.original.id}
              refetchOnSuccess={refetch}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="mb-6 flex items-center justify-between">
        <TableTitle className="!mb-0 flex gap-4">
          <People height="32px" width="32px" color="rgba(248, 250, 252, 1)" />{' '}
          Contact groups
        </TableTitle>
        <DisplayForPermission
          permission={TokenUserPermissions.CONTACT_GROUPS_WRITE}
        >
          <AddContactGroup refetchOnSuccess={refetch} />
        </DisplayForPermission>
      </div>
      {/* temporary comment */}
      {/* <Searchbar
        placeholder="Search by device, account, location"
        onSearch={setSearch}
        onQueryClear={() => {
          setSearch('');
        }}
        clearAllValues={search === ''}
      /> */}
      <div className="mb-16 mt-10 w-full">
        <ServerPaginatedTable
          data={tablePageData}
          columns={tableHeaders}
          title="nocexpress alert contact groups"
          sortingState={sortingState}
          onSortingChange={onSortingChange}
          emptyDataElement={<EmptySearchResults />}
          {...paginatedTableProps}
          isFetchingData={paginatedTableProps.isLoading}
        />
      </div>
      <Modal
        isVisible={isOpen}
        close={close}
        className="w-full max-w-[836px]"
        enableScrolling
      >
        {editGroup && (
          <ContactGroupEditForm
            close={close}
            defaultValue={editGroup}
            refetchOnSuccess={refetch}
          />
        )}
      </Modal>
    </div>
  );
};

export default ContactGroups;
