import clsx from 'clsx';
import { GraniteButton } from 'components/V2/Button/GraniteButton';

const Card = ({
  subText,
  label,
  buttonLabel,
  onButtonClick,
  icon,
}: {
  subText: string;
  label: string;
  buttonLabel: string;
  onButtonClick: () => void;
  icon: React.ReactNode;
}) => {
  return (
    <div
      className={clsx(
        'card-gradient flex w-[459.69px] flex-col gap-3 rounded-lg border border-[#222231] bg-[#222231] px-8 py-6 text-left font-semibold shadow-md',
      )}
    >
      <div className="flex justify-between">
        <span className="text-2xl font-bold text-content-base-default">
          {label}
        </span>
        <div>{icon}</div>
      </div>
      <span className="text-base">{subText}</span>
      <GraniteButton
        size="large"
        className="mt-7 w-max"
        onClick={onButtonClick}
      >
        {buttonLabel}
      </GraniteButton>
    </div>
  );
};

export default Card;
