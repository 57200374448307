import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

interface MenuLinkProps extends NavLinkProps {
  activeClassName?: string;
  setActive?: () => void;
  classNames?: string;
  children:
    | React.ReactNode
    | ((props: { isActive: boolean; isPending: boolean }) => React.ReactNode);
}

const MenuLink = forwardRef<HTMLAnchorElement, MenuLinkProps>(
  ({ children, activeClassName, setActive, classNames, ...rest }, ref) => {
    return (
      <NavLink
        {...rest}
        ref={ref}
        onClick={setActive}
        className={({ isActive }) =>
          clsx(
            'z-10 w-full',
            classNames,
            isActive
              ? // [&>svg] is used to select and apply styles for svg inside NavLink
                `${activeClassName}`
              : 'w-full px-4 py-3 [&>svg]:fill-white [&>svg]:hover:fill-button-background-secondary-hover',
          )
        }
      >
        {children}
      </NavLink>
    );
  },
);

MenuLink.displayName = 'MenuLink';

export default MenuLink;
