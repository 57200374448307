import { OktaGroupProfile as OriginalOktaGroupProfile } from 'api/auth/schema';
import { z } from 'zod';

export const ModifiedOktaGroupProfile = OriginalOktaGroupProfile.superRefine(
  (data, ctx) => {
    if (!data.name || data.name.trim() === '') {
      ctx.addIssue({
        path: ['name'],
        message: 'Required',
        code: 'custom',
      });
    }

    if (!data.display_name || data.display_name.trim() === '') {
      ctx.addIssue({
        path: ['display_name'],
        message: 'Required',
        code: 'custom',
      });
    }
  },
);

export type OktaGroupProfile = z.infer<typeof ModifiedOktaGroupProfile>;
