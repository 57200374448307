import { ColumnDef } from '@tanstack/react-table';
import { PageTitleGranite } from 'components';
import { GraniteSelect } from 'components/Select/Select';
import Searchbar from 'components/Table/SearchBar';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import {
  StackedCell,
  StackedCellMainContent,
  StackedCellSubtitle,
  TableTitle,
} from 'components/Table/Table.styles';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { Link, useNavigate } from 'react-router-dom';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import {
  Fragment,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useModal, useModalReturn } from 'hooks/useModal';
import { getQueryClient } from 'query-client';
import { DeleteTemplateConfirmationDialog } from './DeleteTemplateConfirmationDialog';
import showToast from 'components/Toast/Toast';
import {
  GetTemplatesFilterSearchParams,
  Template,
} from 'api/techexpress/schema';
import { parseISO, format } from 'date-fns';
import {
  deleteTemplate,
  getTemplateMetrics,
  getTemplates,
} from 'api/techexpress/api';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import { useSortedTable } from 'hooks/useSortedTable';
import { useFilterForTable } from 'hooks/useFilterForTable';
import {
  AnyTicketType,
  labelForTicketType,
  labelForTicketTypeBoard,
} from 'screens/LetUsHelp/BaseComponents/schemas';
import { getInternalTicketTypeAndProductNamesFromQuestionnaire } from 'screens/LetUsHelp/utils/GetTypeOfTicketFromQuestionnaire';
import {
  ArrowForward,
  FileTray,
  InformationCircle,
  Podium,
} from 'react-ionicons';
import { Chip } from 'components/Chip/Chip';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import { useDownloadAsExcel } from 'hooks/useDownloadAsExcel';

const TEMPLATE_LIMIT = 10;

export const formatDate = (date?: string) => {
  if (date) {
    const parsedDate = parseISO(date);
    return format(parsedDate, 'MM-dd-yyyy');
  } else {
    return '';
  }
};

export const ticketTypes = [
  'POTSExtension',
  'CircuitExtension',
  'EquipmentInstall',
  'SiteAudit',
  'SmartHands',
  'Move',
  'BroadbandCircuit',
  'POTS',
  'FiberExtension',
  'CustomerEquipment',
  'MaterialReplacement',
];

const queryClient = getQueryClient();

const ActionsContext = createContext<useModalReturn<Template>>(
  {} as useModalReturn<Template>,
);

const templatesTableHeaders: ColumnDef<Template>[] = [
  {
    header: 'Template name',
    id: 'name',
    enableSorting: false,
    cell: (row) => (
      <StackedCell>
        <StackedCellMainContent>{row.row.original.name}</StackedCellMainContent>
        <StackedCellSubtitle>
          {labelForTicketTypeBoard(
            getInternalTicketTypeAndProductNamesFromQuestionnaire(
              row.row.original.data.getStartedQuestionnaire,
            ),
          )}
        </StackedCellSubtitle>
      </StackedCell>
    ),
  },
  {
    header: 'Created by',
    accessorFn: (r) => r,
    accessorKey: 'city',
    enableSorting: false,
    cell: (row) => (
      <StackedCell>
        <StackedCellMainContent>
          {row.row.original.created_by}
        </StackedCellMainContent>
        <StackedCellSubtitle>
          {formatDate(row.row.original.created_at)}
        </StackedCellSubtitle>
      </StackedCell>
    ),
  },
  {
    header: '# of times used',
    accessorKey: 'used_count',
    enableSorting: false,
  },
  {
    header: 'Last updated',
    accessorKey: 'date',
    enableSorting: false,
    accessorFn: (r) => formatDate(r.updated_at),
  },
  {
    id: 'actions',
    header: 'Actions',
    enableSorting: false,
    cell: (props) => <Actions data={props.row.original} />,
  },
];

const Actions = ({ data }: { data: Template }) => {
  const { openWithProps } = useContext(ActionsContext);

  return (
    <div className="flex gap-x-3">
      <Link
        to={`/tech-express/templates/${data.id}`}
        className="button secondary small"
      >
        View
      </Link>
      <GraniteButton
        type="button"
        variant="unstyled"
        size="small"
        onClick={() => openWithProps(data)}
        className="border border-input-stroke-error text-input-stroke-error hover:bg-button-background-destructive-hover hover:text-button-content-destructive-hover"
      >
        Delete
      </GraniteButton>
    </div>
  );
};

const EmptyElement = () => {
  return (
    <div className="col-span-full flex flex-col items-center gap-6 bg-background-base-surface-2 p-8">
      <FileTray
        color="rgb(var(--content-base-subdued))"
        width="48px"
        height="48px"
      />
      <p className="rounded font-bold leading-[22px] text-content-base-default">
        You haven&apos;t created any templates yet.
      </p>
    </div>
  );
};

export const Templates: React.FC = () => {
  const navigate = useNavigate();
  const modalProps = useModal<Template>();
  const [search, setSearch] = useState('');
  const { sortingQueryParams, sortingState, onSortingChange } = useSortedTable({
    initialSorting: [{ id: 'date', desc: true }],
  });

  const {
    queryParamFilter: typeFilter,
    clearFilter: clearTypeFilters,
    ...ticketTypeFilterProps
  } = useFilterForTable({
    queryParamKey: 'type',
  });

  const onDeleteTemplateConfirmed = async (template?: Template) => {
    if (template) {
      await deleteTemplate(template.id);
      queryClient.invalidateQueries({
        queryKey: ['tech-express', 'templates'],
      });
      showToast.confirmation({
        message: 'Template has been successfully deleted',
      });
      modalProps.close();
    }
  };

  const getTemplatesQueryFn = useCallback(
    (args?: GetTemplatesFilterSearchParams) =>
      getTemplates({
        ...typeFilter,
        ...(search ? { search } : {}),
        ...sortingQueryParams,
        ...args,
      }),
    [search, sortingQueryParams, typeFilter],
  );

  const { itemCount, data: allTemplatesPageData } = usePaginatedTable<Template>(
    getTemplatesQueryFn,
    {
      search,
      sortingQueryParams,
    },
    [
      'tech-express',
      'templates',
      {
        search: '',
        sortingQueryParams,
        typeFilter: undefined,
      },
    ],
    { refetchOnMount: true },
  );

  const { data: templateMetrics } = useQuery(
    ['tech-express', 'templates', 'metrics'],
    getTemplateMetrics,
  );

  const { data: tablePageData, ...paginatedTableProps } =
    usePaginatedTable<Template>(
      getTemplatesQueryFn,
      {
        search,
        sortingQueryParams,
        typeFilter,
      },
      [
        'tech-express',
        'templates',
        {
          search,
          sortingQueryParams,
          typeFilter,
        },
      ],
      { refetchOnMount: true },
    );

  const ticketTypeOptions = useMemo(
    () =>
      ticketTypes
        .filter((type) =>
          allTemplatesPageData.find(
            (template) => template.data.reviewForm.ticketType === type,
          ),
        )
        .map((value) => ({
          value,
          label: labelForTicketType(value as AnyTicketType),
        })),
    [allTemplatesPageData],
  );

  const limitReached = itemCount >= TEMPLATE_LIMIT;
  const mostUsedTemplate = allTemplatesPageData?.reduce<Template | undefined>(
    (max, template) =>
      max && max.used_count > template.used_count ? max : template,
    undefined,
  );

  const createExcelFile = useDownloadAsExcel(async () => {
    const transformedData = tablePageData.map((item) => ({
      'Template name': item.name,
      'Ticket type': labelForTicketTypeBoard(
        getInternalTicketTypeAndProductNamesFromQuestionnaire(
          item.data.getStartedQuestionnaire,
        ),
      ),
      'Created by': item.created_by,
      'Date created': item.created_at,
      'Last updated': item.updated_at,
      '# of times used': item.used_count,
    }));

    return transformedData;
  }, 'Templates');

  return (
    <ActionsContext.Provider value={modalProps}>
      <ContentLayout className="w-full 2xl:max-w-[1440px]">
        <div className="mb-12">
          <PageTitleGranite title="Templates" className="items-center">
            <div className="flex items-center gap-4">
              {limitReached && (
                <div className="flex items-center gap-x-1">
                  <InformationCircle
                    color="#9796F3"
                    width="20px"
                    height="20px"
                  />
                  <span className="text-sm text-content-base-default">
                    Your company has used all {TEMPLATE_LIMIT} of it&apos;s
                    available templates.
                  </span>
                </div>
              )}
              <GraniteButton
                size="large"
                variant="primary"
                onClick={() => navigate('/tech-express/templates/details')}
                disabled={paginatedTableProps.isFetchingData || limitReached}
              >
                Create new template
              </GraniteButton>
            </div>
          </PageTitleGranite>
        </div>
        <div className="mb-16 grid min-h-[194px] grid-cols-3 gap-4">
          <div className="flex flex-col justify-between rounded bg-background-base-surface-2 px-6 py-4">
            <h3 className="font-bold text-content-base-default">
              Total templates
            </h3>
            <div className="text-8xl font-bold text-content-base-default">
              {itemCount}
            </div>
          </div>
          <div
            className={clsx(
              'flex flex-col rounded bg-background-base-surface-2 px-6 py-4',
              mostUsedTemplate ? 'justify-between' : 'gap-12',
            )}
          >
            <h3 className="font-bold text-content-base-default">
              Most used template
            </h3>
            {mostUsedTemplate ? (
              <Fragment>
                <div className="text-xl font-bold text-content-base-default">
                  {mostUsedTemplate.name}
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex rounded-[100px] bg-green-300 px-2 py-1 font-semibold text-content-flip-default">
                    <Podium width="24px" height="24px" />
                    <div className="ml-2 whitespace-nowrap">
                      {mostUsedTemplate.used_count} times
                    </div>
                  </div>
                  <Link
                    to={`/tech-express/templates/${mostUsedTemplate.id}`}
                    className="flex gap-2 text-xs font-bold text-content-base-subdued"
                  >
                    <span>View template</span>
                    <ArrowForward
                      width="12px"
                      height="12px"
                      color="currentColor"
                    />
                  </Link>
                </div>
              </Fragment>
            ) : (
              <div className="font-bold text-content-base-subdued">
                No templates created
              </div>
            )}
          </div>
          <div className="flex flex-col justify-between rounded bg-background-base-surface-2 px-6 py-4">
            <h3 className="font-bold text-content-base-default">
              % of tickets opened using templates
            </h3>
            <div className="text-8xl font-bold text-content-base-default">
              {Math.round(templateMetrics?.template_usage_percentage ?? 0)}%
            </div>
          </div>
        </div>

        <div className="flex w-full flex-col items-start justify-start">
          <TableTitle>Templates</TableTitle>
          <div className="mb-4 flex w-full items-start justify-between gap-4">
            <div className="w-full sm:flex-1">
              <Searchbar
                placeholder="Search by name or request #"
                clearAllValues={search === ''}
                onQueryClear={() => setSearch('')}
                onSearch={(query: string) => {
                  setSearch(query);
                }}
              />
            </div>
            <GraniteSelect
              options={ticketTypeOptions}
              isMulti
              className="col-span-2 !w-auto md:col-span-2 xl:col-span-1"
              placeholder="Filter ticket type"
              controlShouldRenderValue={false}
              isSearchable={false}
              {...ticketTypeFilterProps}
            />
          </div>
          <div className="mb-12 flex flex-wrap gap-4">
            {ticketTypeFilterProps.value.map((sf) => (
              <Chip
                key={sf.value}
                label={sf.label}
                onDelete={() =>
                  ticketTypeFilterProps.onChange(
                    ticketTypeFilterProps.value.filter(
                      (option) => option.value !== sf.value,
                    ),
                  )
                }
              />
            ))}
          </div>
          <ServerPaginatedTable
            data={tablePageData}
            columns={templatesTableHeaders}
            title="Service tickets"
            sortingState={sortingState}
            onSortingChange={onSortingChange}
            downloadTableFn={createExcelFile}
            emptyDataElement={itemCount === 0 ? <EmptyElement /> : undefined}
            showPagination={false}
            {...paginatedTableProps}
          />
        </div>
      </ContentLayout>
      <DeleteTemplateConfirmationDialog
        {...modalProps}
        onConfirmation={onDeleteTemplateConfirmed}
      />
    </ActionsContext.Provider>
  );
};
