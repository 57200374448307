import { BasePagination, PaginableRequest } from 'api/common-schemas';
import { SubGroupType } from './accounts.types';

export const subGroups: SubGroupType[] = [
  {
    id: '08203945',
    name: 'Ulta - Headquarters this is a really long account name',
  },
  {
    id: '02948573',
    name: 'Ulta - Headquarters',
  },
  {
    id: '02948576',
    name: 'Ulta - Ulta - malls only',
  },
  {
    id: '02293853',
    name: 'Ulta - IW only',
  },
  {
    id: '02948563',
    name: 'Ulta - Ulta - East coast stores',
  },
  {
    id: '02948579',
    name: 'Ulta - Headquarters',
  },
  {
    id: '02948571',
    name: 'Ulta - Ulta - Headquarters',
  },
  {
    id: '02948574',
    name: 'Ulta - malls only',
  },
  {
    id: '02293854',
    name: 'Ulta - IW only',
  },
  {
    id: '02948565',
    name: 'Ulta - East coast stores',
  },
];

export const getSubGroup = async (
  args: PaginableRequest,
): Promise<BasePagination<SubGroupType>> => {
  return new Promise((resolve) => {
    // simulation loading
    setTimeout(() => {
      resolve({
        data: subGroups,
        metadata: {
          page: args.page || 1,
          page_size: args.page_size || 20,
          total_items: subGroups.length,
          total_pages: 1,
        },
      });
    }, 1000);
  });
};
