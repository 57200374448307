import { formatInTimeZone } from 'date-fns-tz';
import { getPriorityLabel } from 'screens/TicketDetail/ticketdetail-utils';
import { mergeDateTimeParts } from 'shared/util/mergeDateTimeParts';
import { lookup } from 'zipcode-to-timezone';

const fallbackTimezone = 'America/New_York';

const getSummaryFieldValue = ({
  ticketType,
  priority,
  dispatchDate,
  accessTime,
  zip,
}: {
  ticketType: string;
  priority: 'P1' | 'P2' | 'P3';
  dispatchDate: Date;
  accessTime: {
    start_date: Date;
    end_date?: Date;
    scheduling_type: 'Requested Window' | 'Hard Start';
  };
  zip: string;
}) => {
  const timeZone = lookup(zip) ?? fallbackTimezone;
  return `${ticketType} | ${getPriorityLabel(priority)} | ${
    accessTime.scheduling_type === 'Hard Start'
      ? formatInTimeZone(
          mergeDateTimeParts(dispatchDate, accessTime.start_date),
          timeZone,
          'MM/dd/yyyy',
        )
      : `${formatInTimeZone(
          mergeDateTimeParts(dispatchDate, accessTime.start_date),
          timeZone,
          'MM/dd/yyyy HH:mm:ss',
        )} - ${formatInTimeZone(
          accessTime.end_date as Date,
          timeZone,
          'HH:mm:ss zzz',
        )}`
  } | ${accessTime.scheduling_type}`;
};

export default getSummaryFieldValue;
