import { zodResolver } from '@hookform/resolvers/zod';
import { Modal } from 'components/Modal/Modal';
import ModalFormHeader from 'components/Modal/ModalFormHeader';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { KeySchema } from './schema';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useMutation, useQueryClient } from 'react-query';
import { postKey } from './utils';
import showToast from 'components/Toast/Toast';
import { KeyFormType } from './keys.types';
import Loader from 'components/Loader';

const CreateKey = () => {
  const navigate = useNavigate();

  const onClose = () => navigate('..', { replace: true });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<KeyFormType>({
    resolver: zodResolver(KeySchema),
  });

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(postKey, {
    onSuccess: (data) => {
      showToast.confirmation({
        title: 'API key created!',
        message: `${data?.name}`,
      });
      queryClient.refetchQueries('user-settings-api-keys');
      navigate(`../created/${data?.client_key}`, { replace: true });
    },
  });

  const onSubmit = (data: KeyFormType) => mutate(data);

  return (
    <Modal isVisible={true} close={onClose} className="w-full max-w-[624px]">
      <ModalFormHeader
        title="Create new key"
        subtitle="API keys allow your applications to authenticate and interact with our platform securely."
      />
      <form className="p-8" onSubmit={handleSubmit(onSubmit)}>
        <GraniteInput
          {...register('name')}
          label="Key name"
          subtitle="Enter a unique alphanumeric name for your API key, using only letters, numbers, and underscores."
          placeholder="Name your API key"
          error={errors.name?.message}
        />
        <div className="mt-12 flex gap-5">
          <GraniteButton variant="secondary" size="large" onClick={onClose}>
            Back
          </GraniteButton>
          <GraniteButton size="large" type="submit" disabled={isLoading}>
            Create key {isLoading ? <Loader height="h-4 w-4" /> : null}
          </GraniteButton>
        </div>
      </form>
    </Modal>
  );
};

export default CreateKey;
