import { z } from 'zod';
import {
  GetStartedQuestionnaireBaseForm,
  GetStartedQuestionnaireForm,
  WhyTechnicianEnum,
} from '../GetStarted/schemas';
import {
  ExtensionCircuitFormSchema,
  ExtensionPOTSFormSchema,
} from './RunExtension/schemas';
import { TicketDetailForms, TicketDetailFormType } from './DefinedFormTypes';
import {
  MACTicketTypes,
  ProductTypes,
  RepairTicketTypes,
} from '../BaseComponents/schemas';
import { getInternalTicketTypeAndProductNamesFromQuestionnaire } from '../utils/GetTypeOfTicketFromQuestionnaire';
import { MoveFormSchema } from './Move/schemas';
import { SmartHandsFormSchema } from './SmartHands/schemas';
import { AuditFormSchema } from './Audit/schemas';
import { EquipmentInstallFormSchema } from './EquipmentInstall/schemas';
import { BroadbandCircuitFormSchema } from './BroadbandCircuit/schemas';
import { POTSCustomerLineFormSchema } from './POTSCustomerLine/schemas';
import { POTSGraniteLineFormSchema } from './POTSGraniteLine/schemas';
import { WiringCopperFormSchema } from './WiringCopper/schemas';
import { WiringFiberFormSchema } from './WiringFiber/schemas';
import { CustomerBroadbandCircuitFormSchema } from './CustomerBroadbandCircuit/schemas';
import { CustomerWiringCopperFormSchema } from './CustomerWiringCopper/schemas';
import { CustomerWiringFiberFormSchema } from './CustomerWiringFiber/schemas';
import { CustomerEquipmentFormSchema } from './CustomerEquipment/schemas';
import { CustomerMaterialFormSchema } from './CustomerMaterial/schemas';

export const TicketDetailFormSchemas = z.discriminatedUnion('formType', [
  // MAC
  ExtensionPOTSFormSchema,
  ExtensionCircuitFormSchema,
  MoveFormSchema,
  SmartHandsFormSchema,
  AuditFormSchema,
  EquipmentInstallFormSchema,
  // Repair - Granite Owned
  BroadbandCircuitFormSchema,
  POTSGraniteLineFormSchema,
  WiringCopperFormSchema,
  WiringFiberFormSchema,

  // Repair - Customer Owned
  CustomerBroadbandCircuitFormSchema,
  POTSCustomerLineFormSchema,
  CustomerWiringCopperFormSchema,
  CustomerWiringFiberFormSchema,
  CustomerEquipmentFormSchema,
  CustomerMaterialFormSchema,
]);

export type TicketDetailFormUnion = z.infer<typeof TicketDetailFormSchemas>;

export const getFormTypeFromGetStartedQuestionnaire = (
  questionnaire: GetStartedQuestionnaireBaseForm | GetStartedQuestionnaireForm,
): TicketDetailFormType => {
  const { ticketType, product } =
    getInternalTicketTypeAndProductNamesFromQuestionnaire(questionnaire);
  if (product === ProductTypes.enum.FieldServicesMAC) {
    switch (ticketType) {
      case MACTicketTypes.enum.POTSExtension:
        return TicketDetailForms.enum.ExtensionPOTS;
      case MACTicketTypes.enum.CircuitExtension:
        return TicketDetailForms.enum.ExtensionCircuit;
      case MACTicketTypes.enum.Move:
        return TicketDetailForms.enum.Move;
      case MACTicketTypes.enum.SmartHands:
        return TicketDetailForms.enum.SmartHands;
      case MACTicketTypes.enum.SiteAudit:
        return TicketDetailForms.enum.Audit;
      case MACTicketTypes.enum.EquipmentInstall:
        return TicketDetailForms.enum.EquipmentInstall;
    }
  }

  // Narrow the type of questionnaire to FixMyService and granite owned
  if (
    questionnaire.whyTechnician === WhyTechnicianEnum.enum.FixMyService &&
    questionnaire.graniteOwned.isGraniteOwned &&
    product === ProductTypes.enum.FieldServicesRepair
  ) {
    switch (ticketType) {
      case RepairTicketTypes.enum.BroadbandCircuit:
        return TicketDetailForms.enum.BroadbandCircuit;
      case RepairTicketTypes.enum.POTS: {
        return TicketDetailForms.enum.POTSGraniteLine;
      }
      case RepairTicketTypes.enum.CircuitExtension: {
        return TicketDetailForms.enum.WiringCopper;
      }
      case RepairTicketTypes.enum.FiberExtension: {
        return TicketDetailForms.enum.WiringFiber;
      }
      case 'MaterialReplacement': {
        throw new Error(
          'Not implemented yet: "MaterialReplacement" case for Granite Owned',
        );
      }
      case 'CustomerEquipment': {
        throw new Error(
          'Not implemented yet: "CustomerEquipment" case for Granite Owned',
        );
      }
    }
  }

  if (
    questionnaire.whyTechnician === WhyTechnicianEnum.enum.FixMyService &&
    questionnaire.graniteOwned.isGraniteOwned === false &&
    product === ProductTypes.enum.FieldServicesRepair
  ) {
    switch (ticketType) {
      case RepairTicketTypes.enum.BroadbandCircuit: {
        return TicketDetailForms.enum.CustomerBroadbandCircuit;
      }
      case RepairTicketTypes.enum.MaterialReplacement: {
        return TicketDetailForms.enum.CustomerMaterial;
      }
      case RepairTicketTypes.enum.POTS: {
        return TicketDetailForms.enum.POTSCustomerLine;
      }
      case RepairTicketTypes.enum.CircuitExtension: {
        return TicketDetailForms.enum.CustomerWiringCopper;
      }
      case RepairTicketTypes.enum.FiberExtension: {
        return TicketDetailForms.enum.CustomerWiringFiber;
      }
      case RepairTicketTypes.enum.CustomerEquipment: {
        return TicketDetailForms.enum.CustomerEquipment;
      }
    }
  }

  // This is unreachable, it helps remove the undefined from the function return type
  throw Error('Unhandled Get Started Questionnaire case');
};
