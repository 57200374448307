import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  ContactGroupFormType,
  DevicesContactGroupsSearchParams,
  DeviceType,
} from './schema';
import Searchbar from 'components/Table/SearchBar';
import { GraniteSelect, OptionType } from 'components/Select/Select';
import { SingleValue } from 'react-select';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import { useSortedTable } from 'hooks/useSortedTable';
import { EmptySearchResults } from 'screens/QuoteIndex/QuoteIndexPage';
import { ColumnDef } from '@tanstack/react-table';
import Checkbox from 'components/Checkbox';
import { getDevices } from '../../utils';
import { usePermissions } from 'hooks/usePermissions';
import { TokenUserPermissions } from 'api/users/schemas/Users';

const DevicesForm = () => {
  const [search, setSearch] = useState<string>('');
  const [statusFilter, setStatusFilter] =
    useState<SingleValue<OptionType>>(null);

  const { hasPermission } = usePermissions({
    permission: [TokenUserPermissions.CONTACT_GROUPS_WRITE],
  });
  const { control, setValue, watch } = useFormContext<ContactGroupFormType>();

  const onChangeStatus = (status: SingleValue<OptionType>) => {
    setStatusFilter((prev) => (prev?.value !== status?.value ? status : null));
  };

  const { sortingQueryParams, sortingState, onSortingChange } = useSortedTable({
    initialSorting: [{ id: 'name', desc: true }],
  });

  const _getDevices = (args: DevicesContactGroupsSearchParams) =>
    getDevices({ ...args, ...sortingQueryParams });

  const { data: tablePageData, ...paginatedTableProps } = usePaginatedTable(
    _getDevices,
    {
      search,
      sortingQueryParams,
      statusFilter,
    },
    ['alert-contact-groups-device-table', search, sortingQueryParams],
    {
      refetchOnMount: true,
    },
    { pageIndex: 0, pageSize: 10 },
  );

  const onChangeAll = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(
      'devices',
      e.target.checked ? tablePageData.map((e) => ({ id: e.id })) : [],
    );

  const tableHeaders: ColumnDef<DeviceType>[] = [
    {
      id: 'checkbox',
      header: () => {
        const devices = watch('devices');
        const isAllSelected =
          !!devices?.length &&
          tablePageData.every(
            (row) => !!devices?.find((field) => field.id === Number(row.id)),
          );
        const isSomeSelected =
          !!devices?.length &&
          tablePageData.some(
            (row) =>
              !!devices?.find((field) => field.id === Number(row.id)) &&
              !isAllSelected,
          );
        return (
          <Checkbox
            checked={isAllSelected}
            isPartiallChecked={isSomeSelected}
            onChange={onChangeAll}
            className="mx-1"
            disabled={!hasPermission}
          />
        );
      },
      cell: (row) => {
        return (
          <Controller
            name="devices"
            control={control}
            render={({ field }) => {
              const isChecked = !!field.value?.find(
                (d) => d.id === Number(row.row.original.id),
              );
              const valueOnChange = isChecked
                ? field.value?.filter(
                    (s) => s.id !== Number(row.row.original.id),
                  )
                : [...(field.value || []), { id: Number(row.row.original.id) }];

              return (
                <Checkbox
                  disabled={!hasPermission}
                  checked={isChecked}
                  onChange={() => field.onChange(valueOnChange)}
                  className="mx-1"
                />
              );
            }}
          />
        );
      },
    },
    {
      header: 'Device',
      accessorKey: 'name',
      meta: { align: 'left' },
    },
    {
      header: 'Serial number',
      accessorKey: 'serial_number',
      meta: { align: 'left' },
    },
    {
      header: 'Account',
      accessorKey: 'macnum',
      meta: { align: 'left' },
    },
  ];

  return (
    <div>
      <div className="flex gap-4">
        <Searchbar
          placeholder="Search"
          onSearch={setSearch}
          onQueryClear={() => {
            setSearch('');
          }}
          clearAllValues={search === ''}
        />
        <GraniteSelect
          className="max-w-[222px]"
          placeholder="Filter by status"
          isClearable={false}
          isSearchable={false}
          options={[
            { value: 'Selected', label: 'Selected' },
            { value: 'Unselected', label: 'Unselected' },
          ]}
          onChange={onChangeStatus}
          value={statusFilter}
        />
      </div>
      {/* temporary solution */}
      <div className="mt-6 w-full">
        <ServerPaginatedTable
          data={tablePageData}
          columns={tableHeaders}
          title="nocexpress edit alert contact groups devices"
          sortingState={sortingState}
          onSortingChange={onSortingChange}
          emptyDataElement={<EmptySearchResults />}
          paginationSizeStored={10}
          paginationVariant="short"
          pageSizeMenuPlacement="top"
          variant="small"
          tableContainerClassName="max-h-[440px]"
          {...paginatedTableProps}
        />
      </div>
    </div>
  );
};

export default DevicesForm;
