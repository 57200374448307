import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useModal } from 'hooks/useModal';
import { FC } from 'react';
import { Add } from 'react-ionicons';

import AddPermissionsToRoleModal, {
  AddPermissionsToRoleProps,
} from './AddPermissionToRoleModal';

const AddPermissionsToRole: FC<
  Pick<AddPermissionsToRoleProps, 'defaultValue' | 'onSave'>
> = ({ onSave, defaultValue }) => {
  const { open, isOpen, close } = useModal();

  return (
    <>
      <GraniteButton variant="secondary" className="mt-6" onClick={open}>
        Add permissions <Add />
      </GraniteButton>
      <Modal isVisible={isOpen} close={close} className="w-full max-w-[700px]">
        <AddPermissionsToRoleModal
          close={close}
          defaultValue={defaultValue}
          onSave={onSave}
        />
      </Modal>
    </>
  );
};

export default AddPermissionsToRole;
