import clsx from 'clsx';
import Accordion from 'components/Accordion';
import Divider from 'components/Divider';
import { SelectableProduct } from '../../Wizard/ProductSelectionStore';
import { computed } from 'mobx';
import { formatCurrency } from '../../../../shared/util/formatCurrency';
import { observer } from 'mobx-react-lite';

export interface PriceDetailsProps {
  product: SelectableProduct;
}

export const PriceDetails = observer(({ product }: PriceDetailsProps) => {
  const priceDetails = computed(() => [
    {
      title: 'Non-recurring changes',
      items: [
        {
          label: 'Service activation fee',
          value: formatCurrency(product.selectedProduct?.installation?.rate),
          isSum: true,
        },
      ],
    },
    {
      title: 'Monthly recurring charges',
      items: [
        {
          label: 'Modem',
          value: formatCurrency(product.selectedProduct?.modem?.rate),
        },
        {
          label: 'IP blocks',
          value: formatCurrency(product.selectedProduct?.ipBlock?.rate),
        },
        {
          label: 'Monthly price',
          value: formatCurrency(product.selectedService?.rate),
          isSum: true,
        },
      ],
    },
  ]).get();

  return (
    <div className="">
      <Accordion
        items={[
          {
            title: (
              <p className="flex flex-col justify-center font-bold">
                <span className="flex gap-1">
                  <span className="text-4xl text-content-accent-default">
                    {formatCurrency(product.mrc)}
                  </span>
                  <span className="self-end text-xl text-content-base-subdued">
                    /month
                  </span>
                </span>
                <span>
                  +{formatCurrency(product.nrc)} service activation fee
                </span>
              </p>
            ),
            content: (
              <div className="-mt-2 flex flex-col gap-3">
                <Divider variant="default" className="mb-2" />
                <div className="flex flex-col gap-[18px]">
                  {priceDetails.map((priceDetail, index) => (
                    <div className="flex flex-col gap-1" key={index}>
                      <span className="text-xs font-bold text-content-base-default">
                        {priceDetail.title}
                      </span>
                      <div className="flex flex-col gap-2">
                        {priceDetail.items.map((item, i) => (
                          <div
                            className="flex justify-between text-base font-bold"
                            key={i}
                          >
                            <span className="text-content-base-subdued">
                              {item.label}
                            </span>
                            <span
                              className={clsx(
                                'text-content-base-default',
                                item.isSum &&
                                  'text-xl !text-content-accent-default',
                              )}
                            >
                              {item.value}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
});
