import { useEffect } from 'react';
import { useSelectProductsContext } from '../Wizard/OpenQuoteWizardReducer';
import { OpenQuoteLayout } from '../BaseComponents/OpenQuoteLayout';
import { ProductPackage } from './ProductPackage';
import { Link } from 'react-router-dom';
import Divider from 'components/Divider';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { observer } from 'mobx-react-lite';
import { QuoteExpirationBanner } from '../BaseComponents/QuoteExpirationBanner';

export const MassApply = observer(() => {
  const { wizardStore, productSelectionStore } = useSelectProductsContext();

  useEffect(() => {
    wizardStore.setStep(3);
  }, [wizardStore]);

  return (
    <>
      <QuoteExpirationBanner productSelectionStore={productSelectionStore} />
      <OpenQuoteLayout className="mt-6">
        <div className="flex w-full flex-col items-start justify-start gap-8 rounded bg-background-base-surface-2 p-6">
          <div className="flex flex-col items-start justify-start">
            <h1 className="text-2xl text-content-base-default">
              Choose packages to mass apply to your locations
            </h1>
            <p className="text-base font-bold text-content-base-subdued">
              This is just to get started. Next, you’ll be able to fine-tune and
              customize per location as needed. If you prefer, you can skip this
              step and manually add products for every location.
            </p>
          </div>
          {productSelectionStore.locationsByRequirement.map(
            ([requirement, locations]) => (
              <ProductPackage
                key={requirement.id}
                locations={locations}
                requirement={requirement}
              />
            ),
          )}
        </div>
        <div className="flex w-full flex-col items-start justify-start gap-6">
          <div className="flex flex-col items-start justify-start gap-4 rounded bg-background-base-surface-3 p-6">
            <div className="flex w-full items-center justify-between">
              <div className="flex w-full flex-col gap-2 rounded bg-background-base-surface-1 p-4">
                <div className="grid grid-cols-2">
                  <p className="text-base font-bold text-content-base-subdued">
                    Total estimated MRC
                  </p>
                  <p className="text-right text-xl font-bold text-content-accent-default">
                    $900.00
                  </p>
                </div>
                <Divider className="w-full" />
                <div className="grid grid-cols-2">
                  <p className="text-base font-bold text-content-base-subdued">
                    Total estimated NRC
                  </p>
                  <p className="text-right text-xl font-bold text-content-accent-default">
                    $400.00
                  </p>
                </div>
              </div>
            </div>
            <GraniteButton variant="primary" size="large" className="w-[413px]">
              Apply selected packages
            </GraniteButton>
          </div>
          <div className="flex w-full items-center justify-evenly gap-6 px-6">
            <Divider className="w-[195px]" />
            <p className="text-sm font-bold">OR</p>
            <Divider className="w-[195px]" />
          </div>
          <div className="flex w-full items-center justify-center px-6">
            <Link
              to={'../review-products'}
              className="button secondary large w-[413px]"
            >
              Skip this step
            </Link>
          </div>
        </div>
      </OpenQuoteLayout>
    </>
  );
});
