import { IObservableArray, makeAutoObservable, remove, toJS } from 'mobx';
import { QuoteLocation, QuoteLocationSchema } from '../schemas/QuoteLocation';
import { QuoteProduct, QuoteProductSchema } from '../schemas/Product';
import { getProductTypeLabel } from '../utils';
import {
  CreateQuoteRequest,
  QuoteAPIProduct,
  QuoteApiLocation,
} from '../../../api/accessexpress/schema';
import { v4 as uuid } from 'uuid';

export interface LocationsByProduct {
  locations: QuoteLocation[];
  product: QuoteProduct;
}

export class ProductsByLocation {
  private readonly _location: QuoteLocation;
  private readonly _products: Set<QuoteProduct>;

  constructor(location: QuoteLocation, products?: QuoteProduct[]) {
    this._location = location;
    this._products = new Set(products ?? []);

    makeAutoObservable(this);
  }

  get productCount() {
    return this._products.size;
  }

  get streetAddress() {
    return this._location.address;
  }

  get fullAddress() {
    return [
      this._location.city,
      this._location.state,
      this._location.zipcode,
    ].join(', ');
  }

  get location() {
    return this._location;
  }

  get products() {
    return this._products;
  }

  isProductInLocation(product: QuoteProduct) {
    return this._products.has(product);
  }

  addProduct(product: QuoteProduct) {
    this._products.add(product);
  }

  removeProduct(product: QuoteProduct) {
    this._products.delete(product);
  }
}

export class OpenQuoteStore {
  private readonly _locations: QuoteLocation[] = [];
  private readonly _products: QuoteProduct[] = [];
  private readonly _productsByLocations: ProductsByLocation[] = [];
  private _anonymousProducts: Map<QuoteProduct, number> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  addLocation(...locations: QuoteLocation[]) {
    this._locations.push(...locations);
    locations.forEach((l) =>
      this._productsByLocations.push(new ProductsByLocation(l)),
    );
  }

  addProduct(product: QuoteProduct) {
    const observedProduct = makeAutoObservable(product);
    if (!observedProduct.name) {
      this.setDefaultProductName(observedProduct);
    }

    this._products.push(observedProduct);
  }

  editProduct(product: QuoteProduct) {
    const productInstance = this._products.find((p) => p.id === product.id);
    if (productInstance) {
      Object.assign(productInstance, product);

      if (!productInstance.name) {
        this.setDefaultProductName(productInstance);
      }
    }
  }

  deleteLocation(location: QuoteLocation) {
    const itemIndex = this._locations.indexOf(location);
    remove(this._locations as IObservableArray, itemIndex);
    const pblIndex = this._productsByLocations.findIndex(
      (pbl) => pbl.location === location,
    );
    remove(this._productsByLocations as IObservableArray, pblIndex);
  }

  deleteProduct(product: QuoteProduct) {
    const itemIndex = this._products.indexOf(product);
    this._anonymousProducts.delete(product);
    remove(this._products as IObservableArray, itemIndex);
    this._productsByLocations.forEach((pbl) => pbl.removeProduct(product));
  }

  private setDefaultProductName(product: QuoteProduct) {
    const anonymousProductIndex = this.getAnonymousProductIndex();
    product.name = `${getProductTypeLabel(product)} ${anonymousProductIndex}`;
    this._anonymousProducts.set(product, anonymousProductIndex);
  }

  private getAnonymousProductIndex(): number {
    if (this._anonymousProducts.size === 0) {
      return 1;
    } else {
      return (
        Array.from(this._anonymousProducts.values()).reduce((acc, val) => {
          return val > acc ? val : acc;
        }, 0) + 1
      );
    }
  }

  get locations() {
    return this._locations;
  }

  get products() {
    return this._products;
  }

  get locationsByProduct(): LocationsByProduct[] {
    const products = new Map<QuoteProduct, Set<QuoteLocation>>();

    this._productsByLocations.forEach((pbl) => {
      pbl.products.forEach((p) => {
        const locationSet = products.get(p) ?? new Set<QuoteLocation>();
        locationSet.add(pbl.location);
        products.set(p, locationSet);
      });
    });

    this._products.forEach((p) => {
      if (!products.has(p)) {
        products.set(p, new Set());
      }
    });

    return Array.from(products, ([product, locations]) => ({
      product,
      locations: Array.from(locations),
    }));
  }

  get productsByLocations() {
    return this._productsByLocations;
  }

  get eachProductHasLocation() {
    return this.locationsByProduct.every((lbp) => lbp.locations.length > 0);
  }

  productToLocationState(product: QuoteProduct): 'ALL' | 'SOME' | 'NONE' {
    return (
      this._productsByLocations.reduce(
        (acc, val) => {
          const isProductInLocation = val.isProductInLocation(product);

          if (acc === undefined && isProductInLocation) {
            return 'ALL';
          }
          if (acc === undefined && !isProductInLocation) {
            return 'NONE';
          }
          if (acc === 'ALL' && isProductInLocation) {
            return 'ALL';
          }
          if (acc === 'ALL' && !isProductInLocation) {
            return 'SOME';
          }
          if (acc === 'SOME' && isProductInLocation) {
            return 'SOME';
          }
          if (acc === 'SOME' && !isProductInLocation) {
            return 'SOME';
          }
          if (acc === 'NONE' && isProductInLocation) {
            return 'SOME';
          }
          if (acc === 'NONE' && !isProductInLocation) {
            return 'NONE';
          }
          return 'NONE';
        },
        undefined as undefined | 'ALL' | 'SOME' | 'NONE',
      ) ?? 'NONE'
    );
  }

  addToAllLocations(product: QuoteProduct) {
    this._productsByLocations.forEach((pbl) => {
      pbl.addProduct(product);
    });
  }

  removeFromAllLocations(product: QuoteProduct) {
    this._productsByLocations.forEach((pbl) => {
      pbl.removeProduct(product);
    });
  }

  linkProductToLocation(productId: string, locationId: string) {
    const product = this._products.find((p) => p.id === productId);
    const productByLocation = this._productsByLocations.find(
      (pbl) => pbl.location.id === locationId,
    );

    if (product && productByLocation) {
      productByLocation.addProduct(product);
    }
  }

  toRequest(
    name: string,
    customerName: string,
    email: string,
  ): CreateQuoteRequest {
    return toJS({
      business_unit: '',
      quote_name: name,
      customer_name: customerName,
      contact_name: customerName,
      email,
      locations: this._productsByLocations.map((pbl) => ({
        id: pbl.location.id,
        address1: pbl.location.address,
        address2: pbl.location.address2 ?? '',
        city: pbl.location.city,
        state: pbl.location.state,
        zip_code: pbl.location.zipcode,
        products: Array.from(pbl.products).map(
          (p): QuoteAPIProduct =>
            p.type === 'broadband'
              ? {
                  id: p.id,
                  product_type: 'Broadband',
                  ip_type: p.ipType.toUpperCase() as 'STATIC' | 'DYNAMIC',
                  ...(p.ipType === 'static'
                    ? { ip_block: p.ipBlocks }
                    : undefined),
                  name: p.name,
                  access_type: p.accessType,
                  min_download: p.minimumDownloadSpeed.toString(),
                  min_upload: p.minimumUploadSpeed?.toString(),
                  term: p.termLength,
                }
              : {
                  id: p.id,
                  product_type: 'DIA',
                  access_type: p.accessType,
                  minimum_connection_speed: p.minimumConnectionSpeed.toString(),
                  ip_type: p.ipType.toUpperCase() as 'STATIC',
                  ip_block: p.ipBlocks,
                  term: p.termLength,
                  name: p.name,
                },
        ),
      })),
    });
  }

  fromRequest(quoteLocations: QuoteApiLocation[]) {
    const productIDMap = new Map();

    const generateNewProductID = (oldID: string) => {
      if (!productIDMap.has(oldID)) {
        productIDMap.set(oldID, uuid());
      }
      return productIDMap.get(oldID);
    };

    quoteLocations.forEach((item: QuoteApiLocation) => {
      item.id = uuid();
      item.products.forEach((product) => {
        product.id = generateNewProductID(product.id);
      });
    });

    const locations = quoteLocations.map((loc: QuoteApiLocation) => {
      return QuoteLocationSchema.parse({
        id: loc.id,
        address: loc.address1 || loc.address2 || '',
        city: loc.city,
        state: loc.state,
        zipcode: loc.zip_code,
      });
    });

    const products = quoteLocations.flatMap((loc: QuoteApiLocation) => {
      return loc.products.map((p: QuoteAPIProduct) => {
        return QuoteProductSchema.parse(
          p.product_type === 'Broadband'
            ? {
                id: p.id,
                type: 'broadband',
                termLength: p.term,
                accessType: p.access_type,
                ipType: p.ip_type.toLocaleLowerCase() as 'static' | 'dynamic',
                minimumDownloadSpeed: Number(p?.min_download),
                minimumUploadSpeed: Number(p?.min_upload),
                ...(p?.ip_block && {
                  ipBlocks: p.ip_block,
                }),
                name: p.name,
              }
            : {
                id: p.id,
                type: 'dia',
                accessType: p.access_type,
                minimumConnectionSpeed: Number(p.minimum_connection_speed),
                ipType: p.ip_type.toUpperCase() as 'static',
                ipBlocks: p.ip_block,
                termLength: p.term,
                name: p.name,
              },
        );
      });
    });
    locations.forEach((loc) => {
      this.addLocation(loc);
    });
    products.forEach((p) => {
      this.addProduct(p);
    });
    quoteLocations.forEach((loc) => {
      loc.products.forEach((p) => {
        this.linkProductToLocation(p.id, loc.id);
      });
    });
  }
}
