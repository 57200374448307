import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';

const Missing: FC = () => {
  return (
    <Fragment>
      This page does not exist.
      <Link to="/">Go back.</Link>
    </Fragment>
  );
};

export default Missing;
