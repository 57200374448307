import { Outlet, RouteObject, useNavigate, useParams } from 'react-router-dom';
import { useReducer } from 'react';
import {
  EditTemplateWizardContext,
  getWizardInitialState,
  wizardReducer,
} from './WizardReducer';
import { WizardProgress } from './WizardProgress';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import PageTitle from 'components/PageTitle';
import { DetailsFormWrapper } from '../Details';
import { ReviewFormWrapper } from '../Review';
import { CancelConfirmationDialogModal } from '../CancelConfirmationDialog';
import { useModal } from 'hooks/useModal';
import {
  createTemplate,
  getTemplate,
  updateTemplate,
} from 'api/techexpress/api';
import { GetStartedQuestionnaireForm } from 'screens/LetUsHelp/GetStarted/schemas';
import { TicketDetailFormUnion } from 'screens/LetUsHelp/TicketDetails/schemas';
import { ReviewTemplateForm } from '../schemas';
import { useQuery } from 'react-query';
import { WithLoader } from 'components/WithLoader/WithLoader';
import { Template } from 'api/techexpress/schema';
import showToast from 'components/Toast/Toast';

export const EditTemplateWizardInner = ({
  template,
}: {
  template?: Template;
}) => {
  const [state, dispatch] = useReducer(
    wizardReducer,
    getWizardInitialState(template),
  );
  const navigate = useNavigate();

  const { open, ...modalProps } = useModal();

  const breadcrumbs = [
    {
      icon: 'home',
      label: 'TechExpress',
      onClick: () => navigate('/tech-express'),
    },
    {
      label: 'Templates',
      onClick: () => navigate('/tech-express/templates'),
    },
  ];

  const onCancel = () => {
    if (state.getStartedQuestionnaire) open();
    else navigate('/tech-express/templates');
  };

  const onSave = async () => {
    const data = {
      name: state.name!,
      description: state.description!,
      data: {
        getStartedQuestionnaire:
          state.getStartedQuestionnaire as GetStartedQuestionnaireForm,
        ticketDetailForm: state.ticketDetailForm as TicketDetailFormUnion,
        reviewForm: state.reviewForm as ReviewTemplateForm,
      },
    };
    template
      ? await updateTemplate(template.id, data)
      : await createTemplate(data);

    showToast.confirmation({
      title: `Template ${template ? 'updated' : 'created'}!`,
      message: data.name,
    });

    navigate('/tech-express/templates');
  };

  return (
    <ContentLayout>
      <div className="flex flex-col gap-6">
        <PageTitle
          title={`${template ? 'Edit' : 'New'} template`}
          breadcrumbs={breadcrumbs}
        />
        <WizardProgress step={state.step} />
      </div>
      <Outlet
        context={
          {
            dispatch,
            state,
            onCancel,
            onSave,
          } satisfies EditTemplateWizardContext
        }
      />
      <CancelConfirmationDialogModal
        {...modalProps}
        onConfirm={() => {
          let url = '/tech-express/templates';
          if (template) url += `/${template.id}`;
          navigate(url);
        }}
      />
    </ContentLayout>
  );
};

export const EditTemplateWizard = () => {
  const { templateId } = useParams();
  const { data, isLoading } = useQuery(
    ['tech-express', 'template', templateId],
    () => getTemplate(templateId),
    {
      enabled: !!templateId,
    },
  );

  return (
    <WithLoader isLoading={isLoading}>
      <EditTemplateWizardInner template={data} />
    </WithLoader>
  );
};

export const EditTemplateRoutes: RouteObject[] = [
  {
    path: '',
    element: <EditTemplateWizard />,
    children: [
      { path: 'details', element: <DetailsFormWrapper /> },
      { path: 'review', element: <ReviewFormWrapper /> },
    ],
  },
];
