import styled, { css } from 'styled-components';

export const RadioContainer = styled.div<{ horizontal?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ horizontal }) =>
    horizontal &&
    css`
      flex-direction: row;
    `}
`;

export const RadioLabel = styled.label<{ horizontal?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.white};

  &[data-disabled='true'] {
    cursor: not-allowed;
    pointer-events: none;
    color: rgb(var(--content-base-subdued) / var(--tw-text-opacity));
  }
`;

export const RadioButtonInput = styled.input.attrs({ type: 'radio' })`
  opacity: 0;
  width: 0;
`;

export const StyledRadioButton = styled.div<{
  checked: boolean;
  disabled?: boolean;
}>`
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.background.dark};
  border: 2px solid ${({ theme }) => theme.colors.text.primary};
  margin-right: 8px;
  flex: none;

  ${({ disabled }) =>
    disabled &&
    css`
      border-color: #475569;
      background-color: #1e293b;
    `}

  /* Add an active state style */
  ${({ checked, theme }) =>
    checked &&
    css`
      border-color: ${theme.colors.button.primary};
      background-color: ${theme.colors.button.primary};
    `}

  ${RadioButtonInput}:focus + & {
    border-color: ${({ theme }) => theme.colors.segment.light};
  }
  /* Show the indicator when the radio button is checked */
  ${RadioButtonInput}:checked + & {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border-color: ${({ theme }) => theme.colors.button.primary} !important;
      background-color: ${({ theme }) => theme.colors.button.primary};
      display: block;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.background.dark};
      display: block;
    }
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.segment.light};
    /* Increase the fill of the checked option on hover */
    ${RadioButtonInput}:checked + & {
      &::after {
        width: 8px;
        height: 8px;
      }
    }
  }

  /* Additional styles for focused */
  &:focus {
    /* Add an extra circle with the primary color around the outer part */
    &::before {
      content: '';
      position: absolute;
      top: -5px;
      left: -5px;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      border: 2px solid ${({ theme }) => theme.colors.button.primary};
    }
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 4px;
  font-size: ${({ theme }) => theme.text.size.lg};
  font-weight: 800;
`;
