import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HomeLayoutFragment = styled.div`
  max-width: 1376px;
  margin: 0 auto;
`;

export const HomeWelcomeContainer = styled.div`
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1376px;
  border-radius: 4px;
  box-shadow: 0px 4px 32px 0px #0000006e;
`;

export const HomeWelcomeDate = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
  font-style: normal;
  font-weight: 600;
`;

export const HomeWelcomeTitle = styled.h1`
  color: ${({ theme }) => theme.colors.text.white};
  font-style: normal;
  font-weight: 600;
`;

export const HomeWelcomeQuickLinkBar = styled.div`
  display: flex;
  gap: 16px;
`;

export const HomeWelcomeQuickLink = styled(Link)`
  all: unset;
  padding: 12px 16px;
  height: 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  color: ${({ theme }) => theme.colors.text.white};

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.stroke.secondary};

  & span {
    line-height: 0;
  }

  & span svg,
  & svg {
    fill: ${({ theme }) => theme.colors.text.white};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.text.dark};
    background: #bbbafa;
    svg {
      fill: ${({ theme }) => theme.colors.text.dark};
    }
  }

  &:focus {
    background: #9796f3;
  }
`;

export const HomeSeparator = styled.div`
  height: 8px;
  width: 100%;
  background: ${({ theme }) => theme.colors.background.baseSurface2};
`;

export const HomeConciergeWrapper = styled.div`
  background: ${({ theme }) => theme.colors.background.baseSurface2};
`;

export const HomeConciergeContainer = styled.div`
  display: flex;
  padding: 24px 32px;
  gap: 16px;
  max-width: 1376px;
  margin: 0 auto;
`;

export const HomeConciergeAvatar = styled.img`
  max-width: 48px;
  max-height: 48px;
`;

export const HomeConciergeDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HomeConciergeTitle = styled.h3`
  color: ${({ theme }) => theme.colors.text.primary};

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  margin: 0;
`;

export const HomeConciergeDetail = styled.div`
  color: ${({ theme }) => theme.colors.text.white};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  display: flex;
  align-items: center;

  & span {
    line-height: 0;
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  & span svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;

    fill: ${({ theme }) => theme.colors.text.primary} !important;
    color: ${({ theme }) => theme.colors.text.primary} !important;
  }
`;

export const HomeConciergeDetails = styled.div`
  display: flex;
  align-content: center;

  & ${HomeConciergeDetail}:not(:last-child) {
    padding-right: 24px;
    border-right: solid 1px ${({ theme }) => theme.colors.border.hr};
  }

  & ${HomeConciergeDetail}:not(:first-child) {
    padding-left: 24px;
  }
`;

export const HomeServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(320px, 1fr));
  gap: 16px;

  @media (max-width: 640px) {
    grid-template-columns: minmax(auto, 1fr);
  }
`;

export const UICard = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  background: rgba(34, 34, 49, 0.7);
  border-radius: 4px;
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 4px 0px #00000040;

  & h2 {
    margin: 0;
  }
`;

export const UICardHeader = styled.h2`
  color: ${({ theme }) => theme.colors.text.white};

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  margin: 0;
`;

export const CardLogo = styled.img`
  height: 24px;
  width: auto;
  margin-left: 0;
  margin-right: auto;
`;

export const UICardFooter = styled.div`
  margin-bottom: 0;
  margin-top: 16px;
`;

export const HomeServicesHeader = styled.h2`
  color: ${({ theme }) => theme.colors.text.white};
  font-style: normal;
  font-weight: 600;
  margin-bottom: 16px;
`;

export const ButtonLabel = styled.span`
  color: ${({ theme }) => theme.colors.text.dark};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  display: flex;
  align-items: center;
`;

export const GraniteSupportButton = styled.button`
  all: unset;
  border-radius: 4px;

  border: 1px solid ${({ theme }) => theme.colors.stroke.secondary};
  cursor: pointer;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0 32px;

  color: ${({ theme }) => theme.colors.text.white};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */

  & span {
    line-height: 0;
  }

  & svg {
    fill: ${({ theme }) => theme.colors.text.white};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.text.dark};
    background: ${({ theme }) => theme.colors.button.primaryHover};

    svg {
      fill: ${({ theme }) => theme.colors.text.dark};
    }
  }
`;

export const HomeLink = styled(Link)`
  text-decoration: none;
`;

export const HomeHref = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text.white};
`;
