import { InformationCircle } from 'react-ionicons';
import React, { Fragment, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { usePopper } from 'react-popper';
import { getLabelForIpBlock } from '../utils';
import clsx from 'clsx';
import { FormalProductOfferings } from '../QuoteDetails/schema';

export interface ProductTooltipProps {
  product: FormalProductOfferings;
  inReviewProducts?: boolean;
}

const BroadbandProductDescription = ({
  product,
}: {
  product: FormalProductOfferings;
}) => (
  <Fragment>
    <li>Service: Broadband</li>
    <li>
      Bandwidth:{' '}
      {product.formal_broadband_product?.bandwidth_download.replace(/\D/g, '')}/
      {product.formal_broadband_product?.bandwidth_upload.replace(/\D/g, '')}{' '}
      Mbps
    </li>
    {/* TODO: get from BE response */}
    {/* <li>Connection type: Cable/Fiber</li> */}
    <li>IP Type: {product.formal_broadband_product?.ip_type}</li>
    {product.ip_block && (
      <li>
        IP Block: {product.ip_block} - {getLabelForIpBlock(product.ip_block)}{' '}
        usable
      </li>
    )}
    <li>Term length: {product.term}</li>
    <li>
      Total MRC: $
      {product.formal_broadband_product?.formal_broadband_pricing.total_mrc}
    </li>
  </Fragment>
);

const DIAProductDescription = ({
  product,
}: {
  product: FormalProductOfferings;
}) => (
  <Fragment>
    <li>Service: DIA</li>
    <li>
      Bandwidth: {product.formal_dia_product?.bandwidth.replace(/\D/g, '')} Mbps
    </li>
    <li>Access type: {product.formal_dia_product?.access_type}</li>
    <li>
      Building status:{' '}
      {product.formal_dia_product?.on_net ? 'On-net' : 'Off-net'}
    </li>
    <li>IP Type: Static</li>
    {product.ip_block && (
      <li>
        IP Block: {product.ip_block} - {getLabelForIpBlock(product.ip_block)}{' '}
        usable
      </li>
    )}
    <li>Term length: {product.term}</li>
    <li>
      Total MRC: ${product.formal_dia_product?.formal_dia_pricing.total_mrc}
    </li>
  </Fragment>
);

export const ProductTooltip = observer(({ product }: ProductTooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const triggerEltRef = useRef<HTMLDivElement>(null);
  const popperEltRef = useRef<HTMLDivElement>(null);

  const { styles, attributes } = usePopper(
    triggerEltRef?.current,
    popperEltRef?.current,
    {
      placement: 'top-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-16, 14],
          },
        },
      ],
    },
  );

  const isBroadband = product.product_type === 'Broadband';
  const isDIA = product.product_type === 'DIA';

  return (
    <Fragment>
      <div
        ref={triggerEltRef}
        className={clsx(
          'flex items-center justify-center gap-1 rounded-lg bg-orange-300 px-3 py-2 text-xs font-bold text-content-flip-default hover:bg-orange-200',
          !isBroadband &&
            'border border-status-info-default bg-transparent !text-content-base-default hover:bg-[#9796F3] hover:!text-content-flip-default [&>span>svg]:fill-[#9796F3] [&>span>svg]:hover:fill-[#191925]',
          isBroadband &&
            'border !border-orange-300 bg-transparent !text-content-base-default hover:!bg-orange-300 hover:!text-content-flip-default [&>span>svg]:!fill-[#FBB979] [&>span>svg]:hover:!fill-[#191925]',
        )}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        <span>{`${product.provider} ${
          product.formal_broadband_product?.bandwidth_download?.replace(
            /\D/g,
            '',
          ) ?? product.formal_dia_product?.bandwidth?.replace(/\D/g, '')
        }/${
          product.formal_broadband_product?.bandwidth_upload?.replace(
            /\D/g,
            '',
          ) ?? ''
        } Mbps`}</span>

        <InformationCircle width="16px" height="16px" color="inherit" />
      </div>
      <div ref={popperEltRef} style={styles.popper} {...attributes.popper}>
        {isVisible && (
          <div className="rounded-lg bg-background-base-surface-0 p-5 text-content-base-subdued shadow-elevation3">
            <ul className="list-inside list-disc text-sm text-content-base-subdued">
              {isBroadband && product.formal_broadband_product ? (
                <BroadbandProductDescription product={product} />
              ) : isDIA && product.formal_dia_product ? (
                <DIAProductDescription product={product} />
              ) : (
                <li>No product data available</li>
              )}
            </ul>
          </div>
        )}
      </div>
    </Fragment>
  );
});
