import { QuoteLocation } from 'api/accessexpress/schema';
import { Modal } from 'components/Modal/Modal';
import RadioButton from 'components/RadioButton';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ModalParams } from 'hooks/useModal';

export interface VerifyAddressProps extends ModalParams {
  address?: QuoteLocation;
  verifiedAddress?: QuoteLocation & { street?: string | null | undefined };
  onAddressSelected: (address?: QuoteLocation) => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  step: number;
}

export const VerifyAddressDialog = ({
  isOpen,
  close,
  address,
  verifiedAddress,
  onAddressSelected,
  setStep,
  step,
}: VerifyAddressProps) => {
  const onContinueClick = () => {
    onAddressSelected(verifiedAddress!);
    close();
    setStep(1);
  };

  const onCloseClick = () => {
    close();
    setStep(1);
  };

  return (
    <Modal isVisible={isOpen} close={close}>
      <div className="flex h-auto w-[624px] flex-col items-start justify-start gap-8 rounded bg-background-base-surface-2 p-8">
        <div className="flex flex-col justify-center gap-2">
          <p className="text-2xl font-bold text-content-base-default">
            {step === 1 ? 'Verify address' : 'Unable to verify address'}
          </p>
          <p className="font-bold text-content-base-subdued">
            {step === 1
              ? "The address you entered doesn't match the USPS verified address. Click continue if you approve using the USPS verified address for accurate delivery, or click cancel to enter an alternate address."
              : 'The address you entered could not be verified or was incomplete. Please check the address for any errors and try again. Be sure to include street address, any unit/apartment/suite #, city, state and zip code.'}
          </p>
        </div>
        {step === 1 && (
          <div className="h-[152px] w-full rounded bg-background-base-surface-1 p-4">
            <div className="flex w-full gap-6">
              <div className="flex w-1/2 flex-col justify-start gap-2">
                <p className="font-bold text-content-base-subdued">
                  Original address
                </p>
                <div className="flex gap-1">
                  <RadioButton
                    disabled={true}
                    options={[
                      {
                        value: 'Copper',
                        label: '',
                      },
                    ]}
                  />
                  <p className="text-content-base-default">
                    {address
                      ? `${address.address_line_1}, ${address.city}, ${address.state}, ${address.zip}`
                      : ''}
                  </p>
                </div>
              </div>
              <div className="flex w-1/2 flex-col justify-start gap-2">
                <p className="font-bold text-content-base-subdued">
                  USPS verified address
                </p>
                <div className="flex gap-1">
                  <RadioButton
                    disabled={true}
                    options={[
                      {
                        value: 'Copper',
                        label: '',
                      },
                    ]}
                    selectedValue="Copper"
                  />
                  <p className="text-content-base-default">
                    {address
                      ? `${
                          verifiedAddress?.address_line_1 ??
                          verifiedAddress?.street
                        }, ${verifiedAddress?.city}, ${verifiedAddress?.state}, ${verifiedAddress?.zip}`
                      : ''}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="flex gap-4">
          <GraniteButton
            variant="secondary"
            size="large"
            onClick={() => {
              close();
              setStep(1);
            }}
            className="mt-3"
          >
            {step === 1 ? 'Cancel' : 'Close'}
          </GraniteButton>
          <GraniteButton
            variant="primary"
            size="large"
            onClick={step === 1 ? onContinueClick : onCloseClick}
            className="mt-3"
          >
            {step === 1 ? 'Continue' : 'Back to search'}
          </GraniteButton>
        </div>
      </div>
    </Modal>
  );
};
