import { zodResolver } from '@hookform/resolvers/zod';
import Form from './Form';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PermissionSchema } from './schema';
import {
  PermissionFormType,
  PermissionType,
} from 'screens/RolesPermissions/roles-permissions.types';
import { useMutation, useQueryClient } from 'react-query';
import { postPermission } from 'screens/RolesPermissions/utils';
import showToast from 'components/Toast/Toast';
import { FC } from 'react';
import ModalFormHeader from 'components/Modal/ModalFormHeader';

export interface CreateFormPermissionProps {
  onSuccess?: (data: PermissionType | undefined) => void;
  onClose?: () => void;
}

const CreateFormPermission: FC<CreateFormPermissionProps> = ({
  onSuccess,
  onClose: _onClose,
}) => {
  const navigate = useNavigate();

  const onClose = () =>
    _onClose ? _onClose() : navigate('..', { replace: true });

  const methods = useForm<PermissionFormType>({
    resolver: zodResolver(PermissionSchema),
  });

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(postPermission, {
    onSuccess: onSuccess
      ? onSuccess
      : (data) => {
          showToast.confirmation({
            title: 'Permission created',
            message: `${data?.name}`,
          });
          queryClient.refetchQueries('permissions-index-table');
          onClose();
        },
  });

  const onSubmit = (data: PermissionFormType) => mutate(data);

  return (
    <>
      <ModalFormHeader
        title="Create new permission"
        subtitle="Permissions can be assigned to roles."
      />
      <FormProvider {...methods}>
        <form className="p-8">
          <Form />
          <div className="mt-12 flex gap-5">
            <GraniteButton variant="secondary" size="large" onClick={onClose}>
              Cancel
            </GraniteButton>
            <GraniteButton
              size="large"
              disabled={isLoading}
              onClick={methods.handleSubmit(onSubmit)}
            >
              Create permission
            </GraniteButton>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default CreateFormPermission;
