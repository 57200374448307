import { Modal } from 'components/Modal/Modal';
import { WizardStateBlock } from 'components/StepperWizard/WizardStateBlock';
import { Controller } from 'react-hook-form';
import { BulkLocations } from './schemas';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import Checkbox from 'components/Checkbox/Checkbox';
import { MaintenanceWindowTooltip } from './MaintenanceWindowTooltip';
import { useNOCBulkAddLocations } from 'hooks/useNOCBulkAddLocations';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import useScrollbarPresence from 'hooks/useScrollbarPresence';
import { DownloadSharp } from 'react-ionicons';
import StepSubtitle from 'components/StepSubtitle/StepSubtitle';
import { ChildAccountInput } from './ChildAccountInput';
import {
  Configurations,
  NOCSiteAddress,
} from 'api/nocexpress/schemas/ConfigurationsSchema';
import { SiteAddress } from 'api/addresssearch/schema';
import useDownloadConfigurations from 'shared/util/useDownloadConfigurations';
import Loader from 'components/Loader';
import { getConfigurations } from 'api/nocexpress/api';

export const DEFAULT_LOCATION = {
  id: 1,
  childAccount: '',
  maintenance_window: '',
  canTestingBeDoneAnytime: false,
  is_chronic: false,
  parent_account: '',
};

interface BulkAddLocationsDialogProps {
  isOpen: boolean;
  close: () => void;
  locations?: BulkLocations['locations'];
  getLocations: (locations: BulkLocations['locations']) => void;
  isEditTicket?: boolean;
}

const BulkAddLocationsDialog = forwardRef(
  (
    {
      isOpen,
      close,
      getLocations,
      locations = [],
      isEditTicket = false,
    }: BulkAddLocationsDialogProps,
    ref,
  ) => {
    const {
      step,
      setStep,
      showButtonsIndex,
      setShowButtonsIndex,
      checkAll,
      setCheckAll,
      formMethods: {
        register,
        control,
        handleSubmit,
        reset,
        trigger,
        setValue,
        watch,
        getValues,
        formState: { errors },
      },
      locationsFields,
      append,
      handleCheckAllChange,
      handleNextClick,
      handleKeyDown,
      handleMaintenanceWindowBlur,
      applyToAll,
      handlePaste,
      areAllCheckboxesChecked,
      onSubmit,
      onError,
      maintenanceWindowRefs,
    } = useNOCBulkAddLocations({ getLocations, close, locations });
    const scrollableDiv = useRef<HTMLDivElement>(null);
    const hasScrollbar = useScrollbarPresence(scrollableDiv, [locationsFields]);
    const newLocationsAdded = watch('locations');
    const [savedState, setSavedState] = useState<BulkLocations['locations']>(
      locations || [],
    );

    const [loadingStates, setLoadingStates] = useState({});

    const handleLoadingChange = (loading: boolean, index: number) => {
      setLoadingStates((prev) => ({
        ...prev,
        [index]: loading,
      }));
    };

    const isAnyAddressLoading = Object.values(loadingStates).some(
      (state) => state,
    );

    const { isLoading: isDownloading, onDownloadHandler } =
      useDownloadConfigurations({ fetchFn: getConfigurations });

    const isDuplicate = useCallback(
      (value: string, index: number) => {
        if (
          !value ||
          !newLocationsAdded ||
          (newLocationsAdded && newLocationsAdded.length === 0)
        )
          return false;
        if (value.trim() === '') {
          return false;
        }

        const duplicateIndexes = newLocationsAdded
          .map((loc, locIndex) => (loc.childAccount === value ? locIndex : -1))
          .filter((locIndex) => locIndex !== -1);

        const isFirstOccurrence = duplicateIndexes[0] === index;
        const isDuplicateOccurrence =
          duplicateIndexes.includes(index) && !isFirstOccurrence;

        return isDuplicateOccurrence;
      },
      [newLocationsAdded],
    );

    const updateLocationData = useCallback(
      async (
        index: number,
        data: Configurations[] | SiteAddress[] | undefined,
        removeFromSavedState?: boolean,
      ) => {
        if (index !== undefined) {
          if (removeFromSavedState && index < savedState.length) {
            setSavedState((prevState) =>
              prevState.filter((_, i) => i !== index),
            );
          }
          if (data) {
            const addressValue = data[0] as NOCSiteAddress;
            setValue(`locations.${index}.id`, data[0].id);
            setValue(`locations.${index}.site`, addressValue);
            setValue(`locations.${index}.is_chronic`, addressValue.is_chronic);
          } else {
            setValue(`locations.${index}.site`, undefined);
          }
        }
      },
      [savedState.length, setValue],
    );

    useImperativeHandle(ref, () => ({
      resetForm: () => {
        reset({});
      },
    }));

    return (
      <Modal
        isVisible={isOpen}
        close={close}
        className="w-full max-w-[1236px]"
        enableScrolling
      >
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          className="flex flex-col gap-2 rounded-lg rounded-t-lg bg-background-base-surface-2"
        >
          <div className="rounded-t-lg bg-background-base-surface-3 px-8  py-6 font-bold leading-9">
            <h1 className="text-[28px] text-content-base-default">
              Bulk add locations
            </h1>
            {step === 1 && (
              <div className="flex items-center justify-between">
                <StepSubtitle
                  text="Enter or paste a list of the child accounts associated with the
                affected locations."
                />
                <GraniteButton
                  size="small"
                  variant="secondary"
                  onClick={
                    !isDownloading
                      ? onDownloadHandler
                      : (e) => e.preventDefault()
                  }
                  className={clsx(
                    isDownloading &&
                      '!cursor-default !border-button-stroke-secondary-pressed !bg-button-background-secondary-pressed !text-button-content-secondary-pressed',
                  )}
                >
                  Download site list{' '}
                  {isDownloading ? (
                    <Loader animationClassname="!w-3 !h-3" />
                  ) : (
                    <DownloadSharp width="18px" height="18px" color="inherit" />
                  )}
                </GraniteButton>
              </div>
            )}
            {step === 2 && (
              <StepSubtitle text="Specify the approved intrusive testing window for each location." />
            )}
          </div>
          <div className="grid grid-cols-2 items-end gap-1 p-8">
            <WizardStateBlock
              label="Find your addresses"
              isActive={step >= 1}
            />
            <WizardStateBlock
              label="Add testing requirements"
              isActive={step >= 2}
            />
          </div>
          <div className="px-8 pb-8">
            <div className="mb-6 flex w-full items-center justify-start gap-4">
              <div className="w-[30px] font-bold text-content-base-subdued">
                #
              </div>
              <div className="grid w-full grid-cols-11 gap-4">
                {step === 1 && (
                  <div className="col-span-2 font-bold text-content-base-default">
                    Child account
                  </div>
                )}
                <div className="col-span-4 font-bold text-content-base-default">
                  Address
                </div>
                {step === 2 && (
                  <>
                    <div className="col-span-3 flex items-center gap-2 font-bold text-content-base-default">
                      Maintenance window
                      <MaintenanceWindowTooltip />
                    </div>
                    <div
                      className={clsx(
                        `col-span-2 [&>label>span]:!text-base [&>label>span]:!text-content-base-subdued`,
                        hasScrollbar && '-ml-2',
                      )}
                    >
                      <Checkbox
                        checked={areAllCheckboxesChecked}
                        isPartiallChecked={
                          !areAllCheckboxesChecked &&
                          watch('locations').some(
                            (location) => location.canTestingBeDoneAnytime,
                          )
                        }
                        label={'Check all'}
                        onChange={handleCheckAllChange}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              ref={scrollableDiv}
              className="max-h-[350px] !overflow-auto scrollbar-thin scrollbar-track-background-base-surface-1 scrollbar-thumb-stroke-base-subdued"
            >
              {locationsFields.map((field, index) => (
                <div
                  className="mb-6 flex w-full items-center justify-start gap-4"
                  key={field.id}
                >
                  <div className="w-[30px]">{index + 1}</div>
                  <div className="grid w-full grid-cols-11 gap-4" key={index}>
                    {step === 1 && (
                      <ChildAccountInput
                        disabled={!isEditTicket}
                        name={`locations.${index}.childAccount`}
                        control={control}
                        index={index}
                        error={
                          isDuplicate(
                            getValues(`locations.${index}.childAccount`),
                            index,
                          )
                            ? 'Account already added.'
                            : index > 2
                              ? ''
                              : errors.locations?.[index]?.childAccount?.message
                        }
                        updateLocationData={updateLocationData}
                        onPaste={(e) => handlePaste(e, index)}
                        currentLocations={newLocationsAdded}
                        accountValue={getValues(
                          `locations.${index}.childAccount`,
                        )}
                        handleLoadingChange={(loading) =>
                          handleLoadingChange(loading, index)
                        }
                        storedData={savedState}
                      />
                    )}
                    <GraniteInput
                      innerInputClassName="w-full"
                      className="col-span-4"
                      disabled
                      value={addressToString(watch(`locations.${index}.site`))}
                      error={errors.locations?.[index]?.site?.message}
                      {...register(`locations.${index}.site`)}
                    />
                    {step === 2 && (
                      <>
                        {showButtonsIndex !== index && (
                          <GraniteInput
                            innerInputClassName="w-full"
                            className="col-span-3 row-span-2"
                            placeholder="Mon-Fri, 8:00AM-5:00PM"
                            disabled={watch(
                              `locations.${index}.canTestingBeDoneAnytime`,
                            )}
                            error={
                              errors.locations?.[index]?.maintenance_window
                                ?.message
                            }
                            {...register(
                              `locations.${index}.maintenance_window`,
                            )}
                            onChange={(e) => {
                              setValue(
                                `locations.${index}.maintenance_window`,
                                e.target.value,
                              );
                              if (e.target.value.trim())
                                trigger(
                                  `locations.${index}.maintenance_window`,
                                );
                            }}
                            value={watch(
                              `locations.${index}.maintenance_window`,
                            )}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onBlur={(e) => {
                              e.stopPropagation();
                              handleMaintenanceWindowBlur(e, index);
                            }}
                            ref={(el) =>
                              (maintenanceWindowRefs.current[index] = el)
                            }
                          />
                        )}
                        {showButtonsIndex === index && (
                          <div className="col-span-3 flex items-center gap-2">
                            <GraniteButton
                              size="medium"
                              // Using onMouseDown instead of onClick. This is crucial for ensuring
                              // the 'applyToAll' function gets triggered immediately when the mouse button is pressed.
                              // In this way we won't have the issue of values not being applied in prolonged mouse presses.
                              onMouseDown={(e) => {
                                e.stopPropagation();
                                applyToAll(index);
                              }}
                              className="!h-8 w-full"
                            >
                              Apply to all
                            </GraniteButton>
                            <GraniteButton
                              size="medium"
                              variant="secondary"
                              className="!h-8 w-full"
                              onClick={() => setShowButtonsIndex(-1)}
                            >
                              Ignore
                            </GraniteButton>
                          </div>
                        )}
                        <div className="col-span-3 flex items-center justify-center">
                          <Controller
                            name={`locations.${index}.canTestingBeDoneAnytime`}
                            control={control}
                            render={({
                              field: { onChange, value, ...field },
                            }) => (
                              <Checkbox
                                {...field}
                                label="Yes, testing can be done anytime"
                                checked={value}
                                onChange={(e) => {
                                  onChange(e);
                                  if (e.target.checked) {
                                    setValue(
                                      `locations.${index}.maintenance_window`,
                                      '',
                                      {},
                                    );
                                    trigger(
                                      `locations.${index}.maintenance_window`,
                                    );
                                  }

                                  if (!e.target.checked && checkAll) {
                                    setCheckAll(false);
                                  }
                                }}
                              />
                            )}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
              {step === 1 && !isEditTicket && (
                <GraniteButton
                  size="medium"
                  variant="secondary"
                  onClick={() => append(DEFAULT_LOCATION)}
                >
                  Add another
                </GraniteButton>
              )}
            </div>
            <div className="mt-12 flex gap-5">
              <GraniteButton
                size="large"
                variant="secondary"
                onClick={() => {
                  if (step === 1) {
                    close();
                    reset({});
                  } else {
                    setStep(1);
                  }
                }}
              >
                {step === 1 ? 'Cancel' : 'Back'}
              </GraniteButton>
              <GraniteButton
                size="large"
                onClick={handleNextClick}
                disabled={isAnyAddressLoading}
              >
                {step === 1
                  ? 'Next'
                  : `Add locations (${locationsFields.length})`}
              </GraniteButton>
            </div>
          </div>
        </form>
      </Modal>
    );
  },
);

BulkAddLocationsDialog.displayName = 'BulkAddLocationsDialog';

export default BulkAddLocationsDialog;

function addressToString(address?: NOCSiteAddress) {
  if (!address) return '';
  return `${address.address_line_1 || ''}, ${address.city || ''}, ${
    address.state || ''
  }, ${address.zip || ''}`;
}
