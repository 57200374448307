import { useEffect } from 'react';
import { useOpenQuoteContext } from '../Wizard/OpenQuoteWizardReducer';
import { OpenQuoteLayout } from '../BaseComponents/OpenQuoteLayout';
import Table from 'components/Table';
import { ColumnDef } from '@tanstack/react-table';
import { QuoteLocation } from '../schemas/QuoteLocation';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { observer, Observer } from 'mobx-react-lite';

const tableHeaders: ColumnDef<QuoteLocation>[] = [
  {
    id: 'address',
    header: () => 'Address',
    accessorKey: 'address',
  },
  {
    id: 'city',
    header: () => 'City',
    accessorKey: 'city',
  },
  {
    id: 'state',
    header: () => 'State',
    accessorKey: 'state',
  },
  {
    id: 'zip',
    header: () => 'Zip',
    accessorKey: 'zipcode',
  },
];

export const ReviewLocations = observer(() => {
  const { openQuoteStore, wizardStore } = useOpenQuoteContext();

  useEffect(() => {
    wizardStore.setStep(1);
  }, [wizardStore]);

  return (
    <OpenQuoteLayout>
      <div className="flex flex-col gap-16 rounded bg-background-base-surface-2 p-6 shadow">
        <div className="flex w-full flex-col items-start justify-start">
          <h2 className="text-2xl font-bold text-content-base-default">
            Take a moment to review your locations
          </h2>
          <div className="mb-8 text-base text-content-base-subdued">
            Click “Next” to start selecting products for these locations or
            click “Back” to modify the locations in this list.
          </div>
          <Observer>
            {() => (
              <Table
                data={openQuoteStore.locations}
                columns={tableHeaders}
                title="Users"
                showExport={false}
                withRowCount
              />
            )}
          </Observer>
        </div>
      </div>
      <div className="sticky top-8 flex gap-4 rounded bg-background-base-surface-2 p-6 shadow">
        <Link to="../add-locations" className="button secondary large w-full">
          Back
        </Link>
        <Link
          to="../add-products"
          className={clsx(
            `button primary large w-full`,
            openQuoteStore.locations.length === 0 && 'disabled-link',
          )}
        >
          Next
        </Link>
      </div>
    </OpenQuoteLayout>
  );
});
