import { ReviewTicketForm } from '../Review/schemas';
import { getInternalTicketTypeAndProductNamesFromQuestionnaire } from '../utils/GetTypeOfTicketFromQuestionnaire';
import { GetStartedQuestionnaireForm } from '../GetStarted/schemas';

export const questionnaireFormToReviewTicketDefaultValues = (
  formData: GetStartedQuestionnaireForm,
): Partial<ReviewTicketForm> => {
  const { ticketType, product } =
    getInternalTicketTypeAndProductNamesFromQuestionnaire(formData);
  return {
    ticketType,
    product,
    accessTime: formData.accessTime,
    dispatchDate: formData.dispatchDate,
    site: formData.site,
    ...(formData.type === 'single' && {
      //@ts-expect-error this exists
      locationName: formData.content.locationName,
      //@ts-expect-error this exists
      locationNumber: formData.content.locationNumber,
    }),
  };
};
