import { NetOpsTicketStatuses } from 'api/nocexpress/schemas';
import { EditNocTicketForm } from './form-schema';
import { BulkLocations } from 'screens/OpenNetOpsTicket/FindLocationOrService/BulkAddLocations/schemas';
import {
  addDays,
  addHours,
  isBefore,
  isWeekend,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const formatTicketNumber = (array: string) => {
  if (array.length === 0) return;
  const numbers = array.split(',');

  const formattedNumbers = numbers.map((number) => `#${number.trim()}`);

  return formattedNumbers.join(', ');
};

export const editTicketToRequestNoc = (
  payload: EditNocTicketForm,
  siteId?: string | null,
  bulkLocations?: BulkLocations['locations'],
) => {
  return {
    ...payload,
    ...(payload.canTestingBeDoneAnytime &&
      bulkLocations?.length === 1 && {
        sites: [
          {
            id: siteId,
            maintenance_window: 'Mon-Fri, 8:00AM-5:00PM',
          },
        ],
      }),
    ...(!payload.canTestingBeDoneAnytime &&
      bulkLocations?.length === 1 && {
        sites: [{ id: siteId, maintenance_window: payload.maintenance_window }],
      }),
    ...(payload.canTestingBeDoneAnytime === undefined &&
      bulkLocations &&
      bulkLocations?.length > 1 && {
        sites: bulkLocations?.map((item) => {
          return {
            id: item.id,
            ...(item.canTestingBeDoneAnytime
              ? {
                  maintenance_window: 'Mon-Fri, 8:00AM-5:00PM',
                }
              : {
                  maintenance_window: item.maintenance_window,
                }),
          };
        }),
      }),
    issue: payload?.issue?.value,
    prior_ticket: payload.prior_ticket ? Number(payload.prior_ticket) : null,
    automatic_email_cc_address: payload.recipients
      .map((recipient) => recipient.email)
      .join(';'),
    additional_contacts: payload.additional_contacts.map((contact) => ({
      type: contact.type!.value,
      name: contact.name,
      number: Number(contact.number),
    })),
  };
};

export const returnLayerStatusOfTicket = (status: string): number => {
  const openStatuses = ['Open', 'Open (Alert)', 'Open (Alert Sent)'];

  const inProgressStatuses = [
    'Assigned',
    'In Process',
    'Actively Troubleshooting',
    'Layer 1 Completed',
    'Pending Case Mgr Review',
    'Updated by Customer',
    'Recurrence',
    'Re-Opened',
    'Ticket Escalated',
    'Ready to Process',
    'Pending Vendor',
    'Pending Premier',
    'Pending Service Order',
    'Pending Carrier',
    'Pending RMA',
    'Force Majeure',
    'Pending RCA',
    'Rejected',
    'Pending Customer',
    'Monitoring/Cleared',
    'Escalation Cleared - Pending Approval',
    'Pending NI',
  ];

  const resolvedStatuses = [
    'Resolved',
    'Cancelled',
    'Customer Cleared',
    'Closed',
  ];

  if (openStatuses.includes(status)) {
    return 1;
  } else if (inProgressStatuses.includes(status)) {
    return 2;
  } else if (resolvedStatuses.includes(status)) {
    return 3;
  } else {
    return 1;
  }
};

export const returnMainStatusOfTicket = (status: string) => {
  switch (status) {
    case 'Open':
    case 'Open (Alert)':
    case ' Open (Alert Sent)':
      return 'Open';
    case 'Assigned':
    case 'In Process':
    case 'Actively Troubleshooting':
    case 'Layer 1 Completed':
    case 'Pending Case Mgr Review':
    case 'Updated by Customer':
    case 'Recurrence':
    case 'Re-Opened':
    case 'Ticket Escalated':
      return 'In Progress';
    case 'Pending Vendor':
    case 'Pending Premier':
    case 'Pending Service Order':
    case 'Pending Carrier':
    case 'Pending RMA':
    case 'Force Majeure':
    case 'Pending RCA':
      return 'Pending Action';
    case 'Pending Customer':
    case 'Monitoring/Cleared':
    case 'Escalation Cleared - Pending Approval':
      return 'Pending Customer';
    case 'Pending NI':
      return 'Pending NI';
    case 'Resolved':
    case 'Cancelled':
    case 'Customer Cleared':
    case 'Closed':
      return 'Resolved';
    default:
      return '';
  }
};

export const canCancelNocTicket = (status: string) => {
  const statusName = returnMainStatusOfTicket(status);
  return (
    statusName === NetOpsTicketStatuses.Enum.Open ||
    NetOpsTicketStatuses.enum['In Progress'] ||
    NetOpsTicketStatuses.enum['Pending Customer']
  );
};

export const canReopenTicket = (
  resolvedTimeInput: string | Date,
): { result: boolean; data: string } => {
  if (!resolvedTimeInput) {
    return { result: false, data: '' };
  }
  const resolvedTime =
    typeof resolvedTimeInput === 'string'
      ? parseISO(resolvedTimeInput)
      : resolvedTimeInput;
  let deadline = addHours(resolvedTime, 24);

  // Adjust for weekends
  if (isWeekend(deadline)) {
    if (resolvedTime.getDay() === 5) {
      // Friday
      deadline = addDays(deadline, 2);
    } else if (resolvedTime.getDay() === 6) {
      // Saturday
      deadline = addDays(deadline, 1);
    }
  }

  // Set time to the exact last updated time of the original day
  deadline = setHours(deadline, resolvedTime.getHours());
  deadline = setMinutes(deadline, resolvedTime.getMinutes());
  deadline = setSeconds(deadline, resolvedTime.getSeconds());
  const formattedDeadline = formatInTimeZone(
    deadline,
    'America/New_York',
    "MMM d 'at' h:mm a zzz",
  );
  const now = new Date();
  const result =
    isBefore(now, deadline) || now.getTime() === deadline.getTime();

  return {
    result,
    data: formattedDeadline,
  };
};
