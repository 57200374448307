import { Disclosure } from '@headlessui/react';
import chevronDown from '../../assets/icon/chevron-down.svg';
import PropTypes from 'prop-types';

interface AccordionItem {
  title: string | React.ReactNode;
  content: string | React.ReactNode;
  footer?: string | React.ReactNode;
}

export interface AccordionProps {
  items: AccordionItem[];
  isDark?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({ items, isDark = false }) => {
  return (
    <div
      className={`align-center w-full rounded ${
        isDark ? 'bg-background-base-surface-1' : ''
      }`}
    >
      {items.map(({ title, content, footer }, index) => (
        <Disclosure key={index}>
          {({ open }) => (
            <div
              className={`mb-3 rounded ${
                isDark
                  ? ''
                  : 'border border-solid border-stroke-base-default border-opacity-[.5]'
              } py-2 text-content-base-default hover:border-stroke-accent-default`}
            >
              <Disclosure.Button className="flex !w-full justify-between rounded-lg px-4 py-2 text-left text-base">
                <span>{title}</span>
                <img
                  alt="chevron-down"
                  src={chevronDown}
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 self-center `}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm">
                {content}
              </Disclosure.Panel>
              {footer}
            </div>
          )}
        </Disclosure>
      ))}
    </div>
  );
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
    }).isRequired,
  ).isRequired,
  isDark: PropTypes.bool,
};

export default Accordion;
