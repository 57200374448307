import React from 'react';
import { Modal } from 'components/Modal/Modal';
import {
  CloseAndResolveTicketRequestType,
  NetOpsResponseForm,
} from './form-schema';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useMutation, useQueryClient } from 'react-query';
import { closeNocTicket } from 'api/nocexpress/api';
import { ExtendedTicket } from 'api/techexpress/schema';
import { useNavigate } from 'react-router-dom';

const NewRepairTicket = ({
  isOpen,
  close,
  ticket,
  request,
}: {
  isOpen: boolean;
  close: () => void;
  ticket: NetOpsResponseForm | ExtendedTicket;
  request: CloseAndResolveTicketRequestType;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const resolveMutation = useMutation(
    ({
      ticketId,
      data,
    }: {
      ticketId: number;
      data: CloseAndResolveTicketRequestType;
    }) => closeNocTicket(ticketId, data),
    {
      onSuccess: async () => {
        queryClient.refetchQueries([
          ticket?.id?.toString(),
          'ticket-details-noc',
        ]);
        queryClient.refetchQueries([
          ticket?.id?.toString(),
          'ticket-noc-notes',
        ]);

        navigate(`/noc-express/open-ticket/resubmit/${ticket.address_1}`);
        close();
      },
    },
  );

  const onSubmit = () => {
    resolveMutation.mutate({
      ticketId: Number(ticket?.id),
      data: request,
    });
  };

  return (
    <Modal isVisible={isOpen} close={close} className="w-full max-w-[624px]">
      <div className="flex w-full flex-col items-start justify-start gap-6 bg-background-base-surface-2 p-8">
        <div className="flex w-full flex-col items-start justify-start gap-2">
          <h1 className="text-2xl font-bold text-content-base-default">
            New repair ticket needed
          </h1>
          <p className="text-base font-bold text-content-base-subdued">
            A new repair ticket needs to be opened to investigate this issue. Do
            you want to open a new ticket now?
          </p>
        </div>
        <div className="flex w-full items-center justify-start gap-4">
          <GraniteButton size="large" variant="secondary" onClick={close}>
            No, stay in current ticket
          </GraniteButton>
          <GraniteButton size="large" variant="primary" onClick={onSubmit}>
            Yes, open new ticket
          </GraniteButton>
        </div>
      </div>
    </Modal>
  );
};

export default NewRepairTicket;
