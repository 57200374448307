import clsx from 'clsx';
import React from 'react';
import { ArrowForward } from 'react-ionicons';

export interface AlertCardProps {
  size: 'large' | 'medium';
  state: boolean;
  active_device_number: number;
  name: string;
  selected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const AlertCard: React.FC<AlertCardProps> = ({
  active_device_number,
  name,
  size,
  state,
  selected,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      id={name}
      className={clsx(
        ' group flex cursor-pointer items-center rounded-md hover:bg-blue-700',
        size === 'large' && 'flex-col justify-center gap-2 px-10 py-9 ',
        size === 'medium' && ' gap-1 px-4 py-[18px] ',
        selected
          ? 'bg-blue-700 ring-2 ring-teal-400'
          : 'ring-hr bg-blue-800 ring-1',
      )}
    >
      {size === 'medium' && <AlertDot state={state} size={size} />}
      <p
        className={clsx(
          'whitespace-nowrap font-bold text-white',
          size === 'large' && 'text-xl',
          size === 'medium' && 'text-sm ',
        )}
      >
        {name}
      </p>
      <div
        className={clsx(
          'flex items-center whitespace-nowrap text-sm font-bold',
          state ? 'text-white' : 'text-neutral-500',
        )}
      >
        {size === 'large' ? (
          <div className="flex w-full flex-col items-center justify-center">
            <p className="flex items-center gap-2 group-hover:hidden">
              <AlertDot state={state} size={size} />
              {active_device_number ? (
                <span> Enabled for {active_device_number} devices</span>
              ) : (
                <span>Not enabled</span>
              )}
            </p>
            <p className="hidden items-center gap-2 text-white group-hover:inline-flex ">
              Go to settings{' '}
              <ArrowForward width="12px" height="12px" color="#fff" />{' '}
            </p>
          </div>
        ) : (
          <span>({active_device_number})</span>
        )}
      </div>
    </div>
  );
};

const AlertDot: React.FC<Pick<AlertCardProps, 'state' | 'size'>> = ({
  state,
  size,
}) => (
  <span
    className={clsx(
      'h-3 w-3 flex-none rounded-full',
      state ? 'bg-green-400' : 'bg-neutral-500',
      size === 'medium' && 'mr-1',
    )}
  />
);

export default AlertCard;
