import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Modal } from 'components/Modal/Modal';
import { Controller, useForm } from 'react-hook-form';
import {
  CloseAndResolveTicketForm,
  CloseAndResolveTicketFormType,
  CloseAndResolveTicketRequestType,
  EscalateTicketFormType,
  NetOpsResponseForm,
} from './form-schema';
import { GraniteSelect, OptionType } from 'components/Select/Select';
import TextAreaGranite from 'components/TextArea/TextArea';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useMutation, useQueryClient } from 'react-query';
import { closeNocTicket } from 'api/nocexpress/api';
import { useNavigate } from 'react-router-dom';

const options = [
  'The issue is still ongoing.',
  'The same trouble report has occurred again.',
  'RCA (Root Cause Analysis) requested.',
  'The reported issue is resolved, but a different issue has occurred for this location.',
];

const ResolveTicket = ({
  isOpen,
  close,
  ticket,
  openNewRepairModal,
}: {
  isOpen: boolean;
  close: () => void;
  openNewRepairModal: (data: CloseAndResolveTicketRequestType) => void;
  ticket: NetOpsResponseForm;
  ticket_rejection_reasons: string[] | undefined;
}) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<CloseAndResolveTicketFormType>({
    resolver: zodResolver(CloseAndResolveTicketForm),
  });
  const navigate = useNavigate();
  const reason = watch('reason');
  const queryClient = useQueryClient();
  const resolveMutation = useMutation(
    ({
      ticketId,
      data,
    }: {
      ticketId: number;
      data: CloseAndResolveTicketRequestType;
    }) => closeNocTicket(ticketId, data),
    {
      onSuccess: async () => {
        queryClient.refetchQueries([
          ticket?.id?.toString(),
          'ticket-details-noc',
        ]);
        queryClient.refetchQueries([
          ticket?.id?.toString(),
          'ticket-noc-notes',
        ]);
        if (
          reason.value ===
          'The reported issue is resolved, but a different issue has occurred for this location.'
        ) {
          navigate(`/noc-express/open-ticket/resubmit/${ticket.address_1}`);
        }
        close();
      },
    },
  );

  const onSubmit = (payload: EscalateTicketFormType) => {
    const request = {
      resolution_reason: payload.reason.value,
      additional_details: payload.additional_notes,
    };
    if (
      payload.reason.value ===
      'The reported issue is resolved, but a different issue has occurred for this location.'
    ) {
      close();
      openNewRepairModal(request);
      return;
    }

    resolveMutation.mutate({ ticketId: Number(ticket?.id), data: request });
  };

  const onError = (err: unknown) => console.log(err);

  const optionsTypes = options
    .map((item) => {
      if (
        !ticket.rca_requested &&
        (ticket.priority?.includes('Priority 0') ||
          ticket.priority?.includes('Priority 1') ||
          ticket?.chronic_flag ||
          ticket.sites?.some((site) => site.is_chronic))
      ) {
        return { value: item, label: item };
      } else if (item !== 'RCA (Root Cause Analysis) requested.') {
        return { value: item, label: item };
      } else {
        return null;
      }
    })
    .filter(Boolean) as OptionType[];

  return (
    <Modal isVisible={isOpen} close={close} className="w-full max-w-[624px]">
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        className="flex flex-col gap-2 rounded-lg rounded-t-lg bg-background-base-surface-2"
      >
        <div className="flex flex-col items-start justify-start rounded-t-lg bg-background-base-surface-3 px-8 py-6 font-bold leading-9">
          <h1 className="text-[28px] text-content-base-default">
            Issue not resolved
          </h1>
          <p className="text-base font-semibold text-content-base-subdued">
            Select the option that best describes the issue pending resolution.
          </p>
        </div>
        <div className="flex w-full flex-col items-start justify-start gap-6 bg-background-base-surface-2 p-8">
          <Controller
            name="reason"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <GraniteSelect
                {...field}
                className={`col-span-full`}
                onChange={onChange}
                label="Ongoing Issue"
                value={value}
                options={optionsTypes}
                classNames={{
                  control: () => '!rounded-r-none',
                }}
              />
            )}
          />
          <div className="flex w-full flex-col items-start justify-start gap-2">
            <TextAreaGranite
              {...register('additional_notes')}
              className="w-full"
              error={errors.additional_notes?.message}
              placeholder="Add specific details regarding the ongoing issue"
              label="Additional details (optional)"
            />
            <GraniteLabel className="text-sm">
              This response will be added directly into the notes section of
              this ticket.
            </GraniteLabel>
          </div>
        </div>
        <div className="flex items-center justify-start gap-5 px-8 pb-8">
          <GraniteButton size="large" variant="secondary" onClick={close}>
            Cancel
          </GraniteButton>
          <GraniteButton size="large" variant="primary" type="submit">
            Submit
          </GraniteButton>
        </div>
      </form>
    </Modal>
  );
};

export default ResolveTicket;
