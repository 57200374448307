import { GetStartedQuestionnaireForm } from '../GetStarted/schemas';
import { ReviewTicketForm } from '../Review/schemas';
import { TicketDetailFormUnion } from '../TicketDetails/schemas';
import { Dispatch } from 'react';
import { useOutletContext } from 'react-router-dom';
import { DeepPartial } from 'react-hook-form';
import { Template } from 'api/techexpress/schema';

export type WizardReducerState =
  | {
      state: 'GetStarted';
      step: 1;
      template?: Template;
      getStartedQuestionnaire?: DeepPartial<GetStartedQuestionnaireForm>;
      ticketDetailForm?: DeepPartial<TicketDetailFormUnion>;
      reviewForm?: DeepPartial<ReviewTicketForm>;
    }
  | {
      state: 'TicketDetails';
      step: 2;
      template?: Template;
      getStartedQuestionnaire: GetStartedQuestionnaireForm;
      ticketDetailForm?: DeepPartial<TicketDetailFormUnion>;
      reviewForm?: DeepPartial<ReviewTicketForm>;
    }
  | {
      state: 'Review';
      step: 3;
      template?: Template;
      getStartedQuestionnaire: GetStartedQuestionnaireForm;
      ticketDetailForm: TicketDetailFormUnion;
      reviewForm?: DeepPartial<ReviewTicketForm>;
    };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getWizardInitialState = (initialData?: any) =>
  ({
    state: 'GetStarted',
    step: 1,
    getStartedQuestionnaire: initialData?.getStartedQuestionnaire ?? undefined,
    ticketDetailForm: initialData?.ticketDetailForm,
    reviewForm: initialData?.reviewForm,
  }) as const;

export type WizardAction =
  | {
      type: 'GoToGetStarted';
      getStartedQuestionnaire?: DeepPartial<GetStartedQuestionnaireForm>;
    }
  | {
      type: 'GoToTicketDetails';
      getStartedQuestionnaire: GetStartedQuestionnaireForm;
      ticketDetailForm?: DeepPartial<TicketDetailFormUnion>;
    }
  | {
      type: 'GoToReview';
      getStartedQuestionnaire: GetStartedQuestionnaireForm;
      ticketDetail: TicketDetailFormUnion;
      reviewForm: Partial<ReviewTicketForm>;
    }
  | {
      type: 'UpdateGetStartedQuestionnaire';
      getStartedQuestionnaire: DeepPartial<GetStartedQuestionnaireForm>;
    }
  | {
      type: 'UpdateTicketDetails';
      ticketDetailForm: DeepPartial<TicketDetailFormUnion>;
    }
  | {
      type: 'UpdateReview';
      reviewForm: DeepPartial<ReviewTicketForm>;
    }
  | {
      type: 'ApplyTemplate';
      template: Template;
    }
  | {
      type: 'ResetForms';
      keepDefaults?: boolean;
    };

export const wizardReducer = (
  state: WizardReducerState,
  action: WizardAction,
): WizardReducerState => {
  switch (action.type) {
    case 'GoToGetStarted':
      return {
        ...getWizardInitialState(),
        getStartedQuestionnaire: action.getStartedQuestionnaire,
        ticketDetailForm: state.ticketDetailForm,
        reviewForm: state.reviewForm,
      };
    case 'GoToTicketDetails':
      return {
        ...state,
        state: 'TicketDetails',
        getStartedQuestionnaire: action.getStartedQuestionnaire,
        // we retrieve ticketDetailPartialForm from state if we go forward to ticketDetails using Next button
        ticketDetailForm: action.ticketDetailForm || state.ticketDetailForm,
        step: 2,
      };
    case 'GoToReview': {
      let allAttachments = action.reviewForm.attachments
        ? action.reviewForm.attachments
        : state.reviewForm?.attachments;
      if (action.reviewForm.guideAttachments) {
        // Remove items from allAttachments that are no longer in guideAttachments
        allAttachments =
          allAttachments?.filter(
            (attachment) =>
              !state.reviewForm?.guideAttachments?.includes(attachment),
          ) ?? [];

        // Add new items from guideAttachments to allAttachments
        const newGuideAttachments = action.reviewForm.guideAttachments.filter(
          (guideAttachment) => !allAttachments?.includes(guideAttachment),
        );
        allAttachments = [...allAttachments, ...newGuideAttachments];
      }
      // Merge action.reviewForm and state.partialReviewForm if it exists.
      const reviewForm = {
        ...state.reviewForm,
        ...action.reviewForm,
        attachments: allAttachments,
      };

      return {
        state: 'Review',
        getStartedQuestionnaire: action.getStartedQuestionnaire,
        ticketDetailForm: action.ticketDetail,
        reviewForm,
        step: 3,
        template: state.template,
      };
    }
    case 'UpdateGetStartedQuestionnaire':
      if (state.state !== 'GetStarted')
        throw new Error(
          'UpdateGetStartedQuestionnaire can only be called from the GetStarted page',
        );
      return {
        ...state,
        getStartedQuestionnaire: action.getStartedQuestionnaire,
      };
    case 'UpdateTicketDetails':
      if (state.state !== 'TicketDetails')
        throw new Error(
          'UpdateTicketDetails can only be called from the TicketDetails page',
        );
      return {
        ...state,
        ticketDetailForm: action.ticketDetailForm,
      };
    case 'UpdateReview':
      if (state.state !== 'Review')
        throw new Error('UpdateReview can only be called from the Review page');
      return {
        ...state,
        reviewForm: action.reviewForm,
      };
    case 'ApplyTemplate':
      return {
        ...getWizardInitialState(action.template.data),
        template: action.template,
      };
    case 'ResetForms':
      return {
        ...getWizardInitialState(),
        reviewForm: action.keepDefaults ? state.reviewForm : undefined,
      };
  }
};

export type LetUsHelpWizardContext = {
  dispatch: Dispatch<WizardAction>;
  state: WizardReducerState;
  onCancel: () => void;
  draftId?: string;
};

export const useLetUsHelpContext = () => {
  return useOutletContext<LetUsHelpWizardContext>();
};
