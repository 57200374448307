import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { Link } from 'react-router-dom';
import { QuoteProduct } from '../schemas/Product';

const AddProductConfirmationDialog = ({
  isOpen,
  close,
  product,
}: {
  isOpen: boolean;
  close: () => void;
  product?: QuoteProduct;
}) => {
  return (
    <Modal isVisible={isOpen} close={close} className="w-[624px]">
      <div className="flex flex-col gap-12 rounded-lg rounded-t-lg bg-background-base-surface-2 p-8">
        <div className="flex flex-col items-start justify-start gap-2">
          <p className="text-2xl text-content-base-default">
            {product?.name} has been added!
          </p>
          <p>Would you like to assign it to any locations now?</p>
        </div>
        <div className="flex items-start justify-start gap-4">
          <GraniteButton variant="secondary" onClick={close} size="large">
            Not right now
          </GraniteButton>
          <Link to="../products-to-locations" className="button primary large">
            Let&apos;s go
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default AddProductConfirmationDialog;
