import { lookup } from 'zipcode-to-timezone';
import { utcToZonedTime } from 'date-fns-tz';

/**
 * Converts a date which represents the local time of a timezone given by a zipcode
 *
 * @param date
 * @param zipcode
 */
export const utcToZonedTimeOfZipCode = (date: Date, zipcode: string): Date => {
  const targetTimezone = lookup(zipcode);
  if (!targetTimezone) return date;
  return utcToZonedTime(date, targetTimezone);
};
