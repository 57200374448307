export const inventoryChartFilterOptions = [
  {
    label: 'Last 30 days',
    value: 'Past Month',
  },
  {
    label: 'Last 3 months',
    value: 'Past 3 Months',
  },
  {
    label: 'Last year',
    value: 'Past Year',
  },
];
