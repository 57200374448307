import React, { useState, useEffect, useRef } from 'react';
import IconMapper from 'components/IconMapper/IconMapper';
import DropdownMenuOptions from './DropdownMenuOptions';
import clsx from 'clsx';
import { useProductTourContext } from 'context/ProductTourContext';

export interface IDropdownOption {
  label: string;
  icon?: React.ReactNode;
  subText?: string;
  onClick: () => void;
  link?: string;
  className?: string;
}

interface DropdownGroup {
  options: IDropdownOption[];
}

interface DropdownMenuProps {
  label: string | React.ReactNode;
  groups: DropdownGroup[];
}
const DropdownMenu: React.FC<DropdownMenuProps> = ({ label, groups }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const { running, stepDetails } = useProductTourContext();

  const toggleDropdown = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (groupIndex: number, optionIndex: number) => {
    groups[groupIndex].options[optionIndex].onClick();
    closeDropdown();
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className={`group border-0 ${
          isOpen ? 'button-background-primary-default' : 'border-transparent'
        } font-body cursor-pointer ${
          isOpen ? 'text-button-background-primary-default' : 'text-white'
        } bg-background-medium rounded-[5px] p-2.5 font-bold hover:text-button-background-primary-default ${
          isOpen ? 'bg-dark' : 'background-base-surface-2'
        }`}
      >
        <div className="flex items-center justify-center gap-2">
          {typeof label === 'string'
            ? label
            : React.cloneElement(label as React.ReactElement, {
                isSelected: isOpen,
              })}
          <IconMapper
            name={isOpen ? 'chevronUp' : 'chevronDown'}
            onClick={toggleDropdown}
          />
        </div>
      </button>

      {running && stepDetails?.userDropDownOpen && (
        <DropdownMenuOptions
          options={groups}
          handleOptionClick={handleOptionClick}
          className={`absolute right-0 top-full z-[10] flex w-[280px] list-none bg-background-base-surface-3 p-3`}
        />
      )}

      {isOpen && !running && (
        <DropdownMenuOptions
          options={groups}
          handleOptionClick={handleOptionClick}
          className={`absolute right-0 top-full z-[1000] flex w-[280px] list-none bg-background-base-surface-3 p-3`}
        />
      )}
    </div>
  );
};

export const DropdownOption = ({
  groupIndex,
  optionIndex,
  handleOptionClick,
  option,
  isActiveLink,
}: {
  groupIndex: number;
  optionIndex: number;
  handleOptionClick: (groupIndex: number, optionIndex: number) => void;
  option: IDropdownOption;
  isActiveLink?: boolean;
}) => {
  return (
    <li
      key={optionIndex}
      onClick={() => handleOptionClick(groupIndex, optionIndex)}
      className={clsx(
        `min-h-12 flex w-full items-center gap-3 self-stretch rounded-[6px] p-3 hover:cursor-pointer hover:bg-background-base-surface-2`,
        option.className && option.className,
        isActiveLink &&
          'bg-background-base-surface-2 sm:bg-background-base-surface-3',
      )}
    >
      {option.icon}
      <div className="flex flex-col items-start justify-center gap-[-2]">
        <button className="m-0 inline-flex w-full max-w-[200px] text-left text-base font-bold active:border-0">
          <label className="truncate text-content-base-default">
            {option.label}
          </label>
        </button>
        {option.subText && (
          <p className="text-xs font-semibold text-content-base-subdued">
            {option.subText}
          </p>
        )}
      </div>
    </li>
  );
};

export default DropdownMenu;
