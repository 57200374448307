import FormSection from 'components/FormSection';
import { OpenQuoteLayout } from '../BaseComponents/OpenQuoteLayout';
import { useOpenQuoteContext } from '../Wizard/OpenQuoteWizardReducer';
import { Fragment, useEffect, useState } from 'react';
import Divider from 'components/Divider';
import { SearchAddressBar } from './SearchAddressBar';
import { AlertCircle, CheckmarkCircle } from 'react-ionicons';
import { QuoteLocation, QuoteLocationSchema } from '../schemas/QuoteLocation';
import { Link } from 'react-router-dom';
import MultiOptionButton from 'components/MultiOptionButton';
import clsx from 'clsx';
import { FileLike, FileUpload } from 'components/V2/FileUpload/FileUpload';
import { useMutation } from 'react-query';
import { papaParseFileAsync } from 'shared/util/PapaParseFileAsync';
import { z } from 'zod';
import { lookup } from 'zipcodes';
import Table from 'components/Table';
import { ColumnDef } from '@tanstack/react-table';
import { ImportLocationMode, ImportLocationModeEnum } from './schemas';
import { observer } from 'mobx-react-lite';
import { NUMBERS_LETTERS_HYPHENS_SPACES_REGEXP } from 'shared/constants/validation-regex-constants';

const CSVLocationsSchema = z.object({
  address: z.string().nonempty().regex(NUMBERS_LETTERS_HYPHENS_SPACES_REGEXP),
  zipcode: z
    .string()
    .trim()
    .regex(/^\d{5}$/, 'Zipcode must be exactly 5 digits'),
  address2: z
    .union([
      z.string().regex(NUMBERS_LETTERS_HYPHENS_SPACES_REGEXP),
      z.literal(''),
    ])
    .optional(),
});

type CSVLocations = z.infer<typeof CSVLocationsSchema>;

interface ILocationTable {
  row: number;
  address: string;
  zipcode: string;
  address2?: string;
}

const columns: ColumnDef<ILocationTable>[] = [
  {
    id: 'row',
    accessorKey: 'row',
    header: () => 'Row',
  },
  {
    id: 'address',
    accessorKey: 'address',
    header: () => 'Street 1 + street 2',
    cell: ({ row }) => `${row.original.address} ${row.original.address2}`,
    enableSorting: true,
  },
  {
    id: 'zipcode',
    accessorKey: 'zipcode',
    header: () => 'Zip',
    enableSorting: true,
  },
];

export const AddLocations = observer(() => {
  const { openQuoteStore, wizardStore } = useOpenQuoteContext();

  const [selectedFiles, setSelectedFiles] = useState<FileLike[]>([]);
  const [fileFieldError, setFileFieldError] = useState<string | undefined>();
  const [validLocationList, setValidLocationList] = useState<QuoteLocation[]>(
    [],
  );
  const [invalidLocations, setInvalidLocations] = useState<ILocationTable[]>(
    [],
  );

  useEffect(() => {
    wizardStore.setStep(1);
  }, [wizardStore]);

  const onAddressSelected = (address?: QuoteLocation) => {
    if (address) {
      openQuoteStore.addLocation(address);
    }
  };

  const onAddressDelete = (location: QuoteLocation) => {
    openQuoteStore.deleteLocation(location);
  };

  const onHowLocationDetailsChange = (
    importLocationMode: ImportLocationMode,
  ) => {
    wizardStore.setImportMode(importLocationMode);
  };

  const parseMutation = useMutation(
    async (csvFile: FileLike) => {
      if (csvFile instanceof File) {
        const parsedCSV = await papaParseFileAsync<CSVLocations>(
          csvFile as File,
          {
            header: true,
          },
        );

        const csvData = parsedCSV.data;
        if (csvData.length === 0) {
          throw Error('Empty file');
        }
        const validLocations: { address: string; zipcode: string }[] = [];
        const invalidRows: ILocationTable[] = [];

        csvData.forEach((row, index) => {
          const result = CSVLocationsSchema.safeParse(row);
          if (result.success) {
            validLocations.push(row);
          } else {
            invalidRows.push({
              row: index + 2,
              address: row.address,
              zipcode: row.zipcode,
              address2: row.address2,
            });
          }
        });

        return { validLocations, invalidRows };
      } else {
        throw Error('Not a valid File');
      }
    },
    {
      onSuccess: ({ validLocations, invalidRows }) => {
        if (validLocations.length > 100) {
          setFileFieldError('A maximum of 100 locations per file can be added');
        }

        const locations: QuoteLocation[] = [];
        validLocations.forEach((row) => {
          const lookupAddress = lookup(row.zipcode.trim());
          if (lookupAddress) {
            const brandedLocation = QuoteLocationSchema.parse({
              address: row.address,
              state: lookupAddress.state,
              city: lookupAddress.city,
              zipcode: row.zipcode,
            });
            locations.push(brandedLocation);
          }
        });

        setValidLocationList(locations);
        setInvalidLocations(invalidRows);

        if (invalidRows.length > 0) {
          throw Error('Formatting error. See the upload summary below.');
        }
      },
      onMutate: () => {
        setFileFieldError(undefined);
        setValidLocationList([]);
      },
      onError: (error: { message: string }) => {
        setFileFieldError(error.message);
      },
    },
  );

  const onNext = () => {
    validLocationList.forEach((location) => {
      onAddressSelected(location);
    });
  };

  return (
    <OpenQuoteLayout>
      <div className="flex min-h-[662px] flex-col gap-16 rounded bg-background-base-surface-2 p-6 shadow">
        <FormSection title="How would you like to add your location details?">
          <div className="gap grid grid-cols-2 gap-4">
            <MultiOptionButton
              label="Enter location details manually"
              subText="Add up to 10 locations"
              option={ImportLocationModeEnum.enum.Manually}
              value={wizardStore.importLocationMode}
              onClick={() =>
                onHowLocationDetailsChange(ImportLocationModeEnum.enum.Manually)
              }
            />
            <MultiOptionButton
              label="Upload location details in bulk"
              subText="Add up to 100 locations"
              option={ImportLocationModeEnum.enum.InBulk}
              value={wizardStore.importLocationMode}
              onClick={() =>
                onHowLocationDetailsChange(ImportLocationModeEnum.enum.InBulk)
              }
            />
          </div>
        </FormSection>
        {wizardStore.importLocationMode ===
          ImportLocationModeEnum.enum.Manually && (
          <>
            <FormSection title="Enter location details manually">
              <SearchAddressBar
                onAddressSelected={onAddressSelected}
                name="search address"
                value={openQuoteStore.locations}
                onAddressDelete={onAddressDelete}
              />
            </FormSection>
          </>
        )}
        {wizardStore.importLocationMode ===
          ImportLocationModeEnum.enum.InBulk && (
          <>
            <FormSection
              title="Upload location details in bulk"
              subtitle="Please ensure the accuracy of the uploaded locations, as this bulk upload tool will only confirm basic field requirements are met."
            >
              <FileUpload
                label="Upload locations"
                multiple={false}
                accept=".csv"
                error={fileFieldError}
                subtitle={
                  <Fragment>
                    Please use{' '}
                    <a
                      href="/locations_template.csv"
                      target="_blank"
                      rel="noreferrer"
                      download
                      className="cursor-pointer text-content-accent-default underline visited:text-content-accent-default"
                    >
                      this template file
                    </a>{' '}
                    to ensure locations are added successfully. Full street
                    address and ZIP code are required for every location.
                  </Fragment>
                }
                onChange={(files) => {
                  if (files.length > 0) {
                    parseMutation.mutate(files[0]);
                    setSelectedFiles([files[0]]);
                  } else {
                    setValidLocationList([]);
                    setSelectedFiles([]);
                    setFileFieldError(undefined);
                    setInvalidLocations([]);
                  }
                }}
                value={selectedFiles}
              />
            </FormSection>
            {(validLocationList.length > 0 || invalidLocations.length > 0) && (
              <>
                <Divider />
                <FormSection title="Upload summary">
                  <div className="flex flex-col gap-2">
                    {validLocationList.length > 0 && (
                      <div className="flex gap-2">
                        <CheckmarkCircle
                          width={'24px'}
                          height={'24px'}
                          color={'#82FF91'}
                        />
                        <span className="text-base font-bold text-status-success-default">
                          {validLocationList.length} locations passed validation
                        </span>
                      </div>
                    )}
                    {invalidLocations.length > 0 && (
                      <>
                        <div className="flex gap-2">
                          <AlertCircle
                            width={'24px'}
                            height={'24px'}
                            color={'#FF315E'}
                          />
                          <span className="text-base font-bold text-status-error-default">
                            {invalidLocations.length} locations failed
                            validation
                          </span>
                        </div>
                        <span>
                          Please update these rows with all required fields and
                          upload your file again
                        </span>
                        <Table<ILocationTable>
                          data={invalidLocations}
                          columns={columns}
                          showExport={false}
                          title={''}
                          handleRowClick={() => {}}
                        />
                      </>
                    )}
                  </div>
                </FormSection>
              </>
            )}
          </>
        )}
      </div>
      <div className="sticky top-8 flex flex-col gap-6 rounded bg-background-base-surface-2 p-6 shadow">
        {openQuoteStore.locations.length === 0 && (
          <div className="flex items-center gap-4 bg-background-base-surface-1 p-4">
            <AlertCircle width="22px" height="22px" color="#9796F3" />
            <span className="text-semibold text-base text-content-base-default">
              You must add at least one location before you can continue
            </span>
          </div>
        )}
        {openQuoteStore.locations.length > 0 && (
          <div className="flex flex-col gap-2 bg-background-base-surface-1 p-4">
            <span className="text-base text-content-base-subdued">
              Your Locations
            </span>
            <span className="text-base text-content-base-default">
              {openQuoteStore.locations.length}{' '}
              {openQuoteStore.locations.length === 1 ? 'location' : 'locations'}{' '}
              added
            </span>
          </div>
        )}
        <div className="flex gap-4">
          <Link to="/access-express" className="button secondary large w-full">
            Cancel
          </Link>
          <Link
            to="../review-locations"
            className={clsx(
              `button primary large w-full`,
              openQuoteStore.locations.length === 0 && 'disabled-link',
            )}
            onClick={onNext}
          >
            Next
          </Link>
        </div>
      </div>
    </OpenQuoteLayout>
  );
});
