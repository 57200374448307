import { useCallback, useState } from 'react';

export type ModalParams = {
  isOpen: boolean;
  close: () => void;
};

export interface useModalReturn<T = never> {
  isOpen: boolean;
  open: () => void;
  openWithProps: (dynamicProps?: T) => void;
  close: () => void;
  dynamicProps?: T;
}

export const useModal = <T = never>(): useModalReturn<T> => {
  const [isOpen, setIsOpen] = useState(false);
  const [props, setProps] = useState<T>();

  const openWithProps = useCallback(
    (dynProps?: T) => {
      if (dynProps) {
        setProps(dynProps);
      }
      setIsOpen(true);
    },
    [setIsOpen, setProps],
  );

  const open = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const close = useCallback(() => {
    setIsOpen(false);
    setProps(undefined);
  }, [setIsOpen, setProps]);

  return {
    isOpen,
    open,
    openWithProps,
    close,
    dynamicProps: props,
  };
};
