import {
  LETTERS_SPACES_HYPENS_ERROR_LABEL,
  UP_TO_6_DIGITS_ERROR_LABEL,
  VALID_PHONE_ERROR_LABEL,
  VALID_NUMBERS_ERROR_LABEL,
  LETTERS_SPACES_SPECIAL_CHARACTERS_ERROR_LABEL,
  ALPHANUMERIC_REGEX_ERROR,
} from 'shared/constants/error-labels';
import {
  LETTERS_SPACES_HYPENS_REGEXP,
  ALPHANUMERIC_WITH_COMMAS_AND_SPACES_VALIDATOR,
  UP_TO_6_DIGITS_REGEXP,
  VALID_PHONE_NUMBER,
  NUMBERS_ONLY_REGEXP,
  ALPHANUMERIC_REGEX,
  LETTERS_SPACES_DOTS_SPECIAL_CHARACTERS,
} from 'shared/constants/validation-regex-constants';
import { z } from 'zod';
import {
  NonStandardToolsFieldSchema,
  OptionLabelValueSchema,
} from '../../LetUsHelp/utils/schemas';
import {
  extendSiteDispatchDateScheduleWithValidations,
  SiteDispatchDateScheduleSchemas,
} from '../../../api/techexpress/schemas/SiteDispatchDateScheduleSchemas';

export const BaseEditTicketFormSchema = z
  .object({
    automatic_email_cc: z.boolean().optional(),
    automatic_email_cc_address: z.string().email().optional(),
    automatic_email_contact: z.boolean().optional(),
    customer_ticket_number: z.string(),
    customer_preferred_tech: z.boolean().optional(),
    prior_ticket: z
      .string()
      .regex(NUMBERS_ONLY_REGEXP, VALID_NUMBERS_ERROR_LABEL)
      .optional()
      .or(z.literal('')),
    po_number: z
      .string()
      .max(25, 'Only 25 characters allowed')
      .regex(
        ALPHANUMERIC_WITH_COMMAS_AND_SPACES_VALIDATOR,
        `Write it in the correct format. (DXX1234, DXX1234)`,
      )
      .or(z.literal('')),
    local_contact: z.array(
      z.object({
        name: z
          .string()
          .nonempty('Required')
          .max(100, 'Only 100 characters allowed')
          .regex(LETTERS_SPACES_HYPENS_REGEXP, {
            message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
          }),
        email: z.string().email().optional().or(z.literal('')),
        phoneNumber: z
          .string()
          .trim()
          .nonempty('Required')
          .nonempty(VALID_PHONE_ERROR_LABEL)
          .length(10, VALID_PHONE_ERROR_LABEL)
          .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
        extension: z
          .string()
          .max(6, 'Only 6 characters allowed')
          .regex(UP_TO_6_DIGITS_REGEXP, UP_TO_6_DIGITS_ERROR_LABEL)
          .optional()
          .or(z.literal('')),
      }),
    ),
    location_name: z
      .string()
      .nonempty('Required')
      .max(100, 'Only 100 characters allowed')
      .regex(LETTERS_SPACES_DOTS_SPECIAL_CHARACTERS, {
        message: LETTERS_SPACES_SPECIAL_CHARACTERS_ERROR_LABEL,
      }),
    location_number: z
      .string()
      .regex(ALPHANUMERIC_REGEX, ALPHANUMERIC_REGEX_ERROR)
      .or(z.literal(''))
      .optional(),
    nonStandardTools: NonStandardToolsFieldSchema,
    priority: z.string(),
    product: OptionLabelValueSchema,
    recipients: z.array(z.object({ email: z.string().email().nonempty() })),
    scope_of_work: z.string().nonempty('Required'),
    special_instructions: z.string().max(1000, 'Only 1000 characters allowed'),
    ticketType: OptionLabelValueSchema,
  })
  .merge(SiteDispatchDateScheduleSchemas);

export const EditTicketFormSchema =
  extendSiteDispatchDateScheduleWithValidations(BaseEditTicketFormSchema);

export type EditTicketForm = z.infer<typeof EditTicketFormSchema>;

export const EditTicketRequestSchema = z
  .object({
    automatic_email_cc: z.boolean(),
    automatic_email_cc_address: z.string(),
    automatic_email_contact: z.boolean(),
    contact_name: z.string(),
    customer_ticket_number: z.string(),
    customer_preferred_tech: z.boolean().nullish(),
    prior_ticket: z.number().nullish(),
    end_date: z.string().datetime(),
    initial_description: z.string(),
    po_number: z.string().optional(),
    local_contact_name: z.string(),
    local_contact_number: z.string(),
    location_contact_email: z.string(),
    location_contact_ext: z.string(),
    location_name: z.string(),
    location_number: z.string().or(z.literal('')),
    non_standard_tools: z.string(),
    scheduling_type: z.enum(['Hard Start', 'Requested Window']),
    secondary_lcon_email: z.string(),
    secondary_lcon_ext: z.string(),
    secondary_lcon_name: z.string(),
    secondary_lcon_number: z.string(),
    special_instructions: z.string(),
    start_date: z.string().datetime(),
    summary: z.string(),
    sub_type: z.string(),
  })
  .partial();

export type EditTicketRequest = z.infer<typeof EditTicketRequestSchema>;
