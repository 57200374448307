import styled from 'styled-components';

export const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 8px;
`;

export const BreadcrumbItemWrapper = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.text.size.sm};
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: pointer;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${({ theme }) => theme.colors.text.white};
  }
  & > div.b-icon {
    display: flex;
    align-items: center;
  }
  & div.b-icon > div > svg {
    margin-right: 8px;
  }
  & > div.b-icon > p {
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
  }

  & > span {
    margin-right: 8px;
    display: flex !important;
    & > div {
      display: flex;
    }
  }

  & > div {
    display: flex;
  }

  &:last-child {
    margin-right: 0;
  }
`;
