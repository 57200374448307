import { SiteAddress } from 'api/addresssearch/schema';
import { fetchBasedOnIdentifier } from 'api/nocexpress/api';
import { Configurations } from 'api/nocexpress/schemas/ConfigurationsSchema';
import clsx from 'clsx';
import { ErrorSubtext } from 'components/ErrorSubtext/ErrorSubtext';
import escapeRegExp from 'lodash.escaperegexp';
import React, {
  Fragment,
  useState,
  useCallback,
  useRef,
  forwardRef,
  ReactElement,
  useMemo,
} from 'react';
import { CloseCircle, Search } from 'react-ionicons';
import { usePopper } from 'react-popper';
import { useQuery } from 'react-query';
import { useDebouncedState } from 'shared/hooks/useDebouncedState';
import isSiteAddress from '../utils/isSiteAddress';

export interface IdentifierSearchBarProps {
  identifier: string;
  className?: string;
  error?: string;
  onResultSelected: (result?: SiteAddress | Configurations) => void;
  value?: SiteAddress | Configurations;
  isDisabled?: boolean;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const IdentifierSearchBar = forwardRef<
  HTMLInputElement,
  IdentifierSearchBarProps
>(
  (
    {
      identifier,
      className,
      error,
      isDisabled,
      onResultSelected,
      name,
      value,
      onChange,
    }: IdentifierSearchBarProps,
    ref,
  ) => {
    const anchorEltRef = useRef<HTMLDivElement>(null);
    const popperElementRef = useRef<HTMLDivElement>(null);
    const inputEltRef = useRef<HTMLInputElement>(null);
    const [searchInput, setSearchInput] = useState('');
    const [isMenuVisible, setIsMenuVisible] = useState(true);
    const { value: debouncedIsMenuVisible } = useDebouncedState(isMenuVisible);
    const { value: debouncedSearch, flush } = useDebouncedState(searchInput);
    const [invalidInputError, setInvalidInputError] = useState('');

    const fetchFunction = useCallback(() => {
      return fetchBasedOnIdentifier(identifier, debouncedSearch);
    }, [identifier, debouncedSearch]);

    const { data: searchResults } = useQuery(
      ['noc-search-bar', identifier, debouncedSearch],
      fetchFunction,
      {
        enabled: debouncedSearch.trim().length > 0,
        retry: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    );

    const { styles, attributes } = usePopper(
      anchorEltRef.current,
      popperElementRef.current,
      { placement: 'bottom' },
    );

    const onResultClear = useCallback(() => {
      onResultSelected(undefined);
    }, [onResultSelected]);

    const onResultChangeInternal = useCallback(
      (result?: SiteAddress | Configurations) => {
        // Order of execution matters as setSearchInput will trigger a re-render which can override the value
        // coming from the parent.
        onResultSelected(result);
        setSearchInput('');
        // Flush changes immediately after selected result is set so
        // react-query discards data and closes menu.
        flush();
      },
      [setSearchInput, flush, onResultSelected],
    );

    const onSearchResultRowEscape = useCallback(() => {
      if (inputEltRef.current) {
        inputEltRef.current.focus();
      }
    }, []);

    return (
      <Fragment>
        <div className={clsx('group relative', className)}>
          <div
            className={clsx(
              'search-box-wrapper max-h-12 overflow-auto scrollbar-thin scrollbar-track-background-base-surface-1 scrollbar-thumb-stroke-base-subdued',
              isDisabled && '!bg-input-background-disabled',
              !searchResults && 'rounded-b',
              searchResults !== undefined &&
                'focus-within:border-b-input-stroke-unfilled',
              (error || invalidInputError) && 'error',
            )}
            ref={anchorEltRef}
            role="listbox"
            aria-labelledby="search address bar"
          >
            <Search color="inherit" width="16px" height="16px" />
            {value && (
              <div
                className={`'border flex items-center gap-1
                rounded-[32px] border-stroke-base-subdued bg-background-base-surface-3 fill-content-base-subdued  px-3 font-light`}
              >
                {Object.keys(value).length > 0
                  ? isSiteAddress(value)
                    ? `${value.address_line_1}, ${value.city}, ${value.state}, ${value.zip}`
                    : [value.name, value.service, value.serial_number]
                        .filter(Boolean)
                        .join(', ')
                  : ''}

                <button
                  className="cursor-pointer fill-content-base-subdued"
                  title="remove address"
                  type="button"
                  onClick={onResultClear}
                  disabled={isDisabled}
                >
                  <CloseCircle color="inherit" width="16px" height="16px" />
                </button>
              </div>
            )}
            {!value && (
              <input
                type="text"
                placeholder={`Search ${identifier?.toLowerCase()}`}
                name={name}
                aria-label={name}
                value={searchInput}
                ref={ref}
                onChange={(e) => {
                  setInvalidInputError('');
                  const { value } = e.target;

                  setSearchInput(value);
                  onChange?.(e);
                }}
                onFocus={() => setIsMenuVisible(true)}
                onBlur={() => setIsMenuVisible(false)}
                className="disabled:cursor-not-allowed disabled:bg-input-background-disabled"
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                disabled={isDisabled}
              />
            )}
          </div>
          <div
            className={clsx(
              searchResults !== undefined && 'search-result-container',
            )}
            onFocus={() => setIsMenuVisible(true)}
            onBlur={() => setIsMenuVisible(false)}
            ref={popperElementRef}
            style={{
              width: (anchorEltRef.current?.clientWidth ?? -2) + 2,
              ...styles.popper,
            }}
            {...attributes.popper}
          >
            {searchResults !== undefined && debouncedIsMenuVisible && (
              <Fragment>
                {searchResults.map((result) => (
                  <SearchResultRow
                    key={result.id || result.name}
                    searchTerm={searchInput}
                    result={result}
                    onClick={onResultChangeInternal}
                    onEscapeRequest={onSearchResultRowEscape}
                  />
                ))}
                {searchResults.length === 0 && (
                  <div className="rounded-b bg-input-background-unfilled px-4 py-3 font-light text-content-base-subdued">
                    No results
                  </div>
                )}
              </Fragment>
            )}
          </div>
          <ErrorSubtext error={invalidInputError || error} />
        </div>
      </Fragment>
    );
  },
);

IdentifierSearchBar.displayName = 'IdentifierSearchBar';

export const highlightMatch = (
  result: string,
  searchTerm: string,
): ReactElement => {
  const parts = result
    .split(new RegExp(`(${escapeRegExp(searchTerm)})`, 'ig'))
    .map((part, i) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <mark role="mark" key={i}>
          {part}
        </mark>
      ) : (
        part
      ),
    );

  return <Fragment>{parts}</Fragment>;
};

interface SearchResultRowProps {
  searchTerm: string;
  result: SiteAddress | Configurations;
  onClick: (result: SiteAddress | Configurations) => void;
  onEscapeRequest?: () => void;
}

export const SearchResultRow = ({
  result,
  searchTerm,
  onClick,
  onEscapeRequest,
}: SearchResultRowProps) => {
  const resultDisplay = isSiteAddress(result)
    ? `${result.address_line_1}, ${result.city}, ${result.state}, ${result.zip}`
    : [
        result.company.identifier,
        result.name,
        result.site_name,
        result.service,
        result.serial_number,
      ]
        .filter(Boolean)
        .join(', ');

  const highlightedResults = useMemo(
    () => highlightMatch(resultDisplay, searchTerm),
    [resultDisplay, searchTerm],
  );

  return (
    <div
      className="search-result-row"
      onClick={() => onClick(result)}
      role="option"
      tabIndex={0}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          onClick(result);
          return;
        }
        if (e.key === 'Escape') {
          onEscapeRequest && onEscapeRequest();
        }
      }}
    >
      <div className=" [&>mark]:bg-transparent [&>mark]:text-content-accent-default">
        {highlightedResults}
      </div>
      <div className="text-sm font-light text-input-content-unfilled">
        {isSiteAddress(result)
          ? [result.parent_macnum, result.parent_name, result.name]
              .filter(Boolean)
              .join(', ')
          : [
              result.company.identifier,
              result.name,
              result.site_name,
              result.service,
              result.serial_number,
            ]
              .filter(Boolean)
              .join(', ')}
      </div>
    </div>
  );
};
