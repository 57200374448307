import React, { useCallback, useEffect, useRef, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Modal } from 'components/Modal/Modal';
import { WizardStateBlock } from 'components/StepperWizard/WizardStateBlock';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import {
  LocationsArraySchema,
  LocationsForm,
  LocationsSchema,
} from './schemas';
import { MacnumInput } from 'components/V2/Input/MacnumInput';
import { SearchAddressBar } from 'components/SearchAddressBar/SearchAddressBar';
import PhoneNumberInput from 'components/V2/Input/PhoneNumber';
import { DownloadSharp } from 'react-ionicons';
import { getSites } from 'api/companies/api';
import StepSubtitle from 'components/StepSubtitle/StepSubtitle';
import { CustomSelect } from './CustomSelect';
import showToast from 'components/Toast/Toast';
import useDownloadConfigurations from 'shared/util/useDownloadConfigurations';
import clsx from 'clsx';
import Loader from 'components/Loader/Loader';

export interface LocationData {
  address_line_1: string;
  city: string;
  id: number;
  local_contact_name: string;
  local_contact_phone: string | null;
  location_contact_email: string;
  macnum: string;
  name: string;
  parent_macnum: string;
  parent_name: string;
  state: string;
  zip: string;
}

const priorities = [
  { value: 'P1', label: 'Priority 1' },
  { value: 'P2', label: 'Priority 2' },
  { value: 'P3', label: 'Priority 3' },
];

const DEFAULT_STATE = {
  macnum: '',
  site: {},
  locationName: '',
  locationNumber: '',
  accessTime: {
    scheduling_type: 'Hard Start',
  },
  priority: priorities[2],
};

export const BulkModalLocations = ({
  isOpen,
  close,
  uploadLocations,
  locations,
  isReview = false,
}: {
  isOpen: boolean;
  close: () => void;
  uploadLocations?: (data: LocationsSchema) => void;
  locations?: LocationsArraySchema;
  isReview?: boolean;
}) => {
  const isFirstRender = useRef<boolean>(true);
  const [step, setStep] = useState(1);
  const defaultValues = {
    locations:
      locations && locations?.length > 0
        ? locations
        : Array(10).fill(DEFAULT_STATE),
  };
  const [savedState, setSavedState] = useState<LocationsArraySchema>(
    locations || [],
  );
  const {
    register,
    control,
    handleSubmit,
    reset,
    trigger,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<LocationsSchema>({
    resolver: zodResolver(LocationsForm),
    defaultValues,
  });

  const { fields: locationsFields, append } = useFieldArray({
    control: control,
    name: 'locations',
  });
  const newLocationsAdded = watch('locations');

  useEffect(() => {
    if (isFirstRender.current && locations && locations?.length > 0) {
      const nonEmptyLocations = locations.filter(
        (location) => location.macnum.trim() !== '',
      );
      setValue(
        'locations',
        [
          ...nonEmptyLocations,
          ...Array(10 - nonEmptyLocations.length).fill(DEFAULT_STATE),
        ],
        { shouldValidate: true },
      );
      isFirstRender.current = false;
    }
  }, [isFirstRender, locations, setValue, isReview]);

  const updateLocationData = useCallback(
    async (
      index: number,
      data: LocationData | undefined,
      removeFromSavedState?: boolean,
    ) => {
      if (index !== undefined) {
        if (removeFromSavedState && index < savedState.length) {
          setSavedState((prevState) => prevState.filter((_, i) => i !== index));
        }
        if (data) {
          const {
            local_contact_name = '',
            local_contact_phone = '',
            ...siteData
          } = data;
          setValue(
            `locations.${index}.local_contact_name`,
            local_contact_name ?? '',
          );
          setValue(
            `locations.${index}.local_contact_number`,
            local_contact_phone ?? '',
          );
          setValue(`locations.${index}.site`, siteData);
        } else {
          setValue(`locations.${index}.local_contact_name`, '');
          setValue(`locations.${index}.local_contact_number`, '');
          //@ts-expect-error let this one
          setValue(`locations.${index}.site`, data);
        }
      }
    },
    [savedState.length, setValue],
  );

  const handlePaste = async (
    event: React.ClipboardEvent<HTMLInputElement>,
    pasteIndex: number,
  ) => {
    event.preventDefault();
    const nonEmptyLocations = newLocationsAdded.filter((location) => {
      if (location?.macnum) {
        return location?.macnum?.trim() !== '';
      }
      return false;
    });

    if (nonEmptyLocations.length === 10) {
      showToast.error({
        title: 'Cannot add more than 10 locations',
        message: 'Only the first 10 values have been pasted',
      });
      return;
    }
    const pastedData = event.clipboardData.getData('text');
    const splitData = pastedData.split(/\r\n|\r|\n/).filter((item) => item);
    if (splitData.length > 10) {
      showToast.error({
        title: 'Cannot add more than 10 locations',
        message: 'Only the first 10 values have been pasted',
      });
    }
    const slicedData = splitData.slice(0, 10 - nonEmptyLocations.length);
    let currentIndex = pasteIndex;
    for (const data of slicedData) {
      if (currentIndex < locationsFields.length) {
        setValue(
          `locations.${currentIndex}.macnum`,
          data.length === 7 ? `0${data}` : data,
        );
        trigger(`locations.${currentIndex}.macnum`);
      } else {
        append({ ...locationsFields, macnum: data });
      }
      trigger(`locations.${pasteIndex}.macnum`);
      currentIndex++;
    }
  };

  const handleNextClick = async () => {
    if (step === 1) {
      let allValid = true;
      const macnums = [];

      for (let index = 0; index < locationsFields.length; index++) {
        const macnumValue = getValues(`locations.${index}.macnum`);
        macnums.push(macnumValue);

        // Validate all filled macnum fields
        if (macnumValue.trim() !== '' || index < 2) {
          const macnumResult = await trigger(`locations.${index}.macnum`);
          if (!macnumResult) {
            allValid = false;
          }
          const locationContactResult = await trigger(
            `locations.${index}.local_contact_name`,
          );
          if (!locationContactResult) {
            allValid = false;
          }
          const locationContactNumberResult = await trigger(
            `locations.${index}.local_contact_number`,
          );
          if (!locationContactNumberResult) {
            allValid = false;
          }
        }
      }

      const filterEmptyMacnums = macnums.filter((macnum) => macnum);
      const uniqueMacnums = new Set(filterEmptyMacnums);
      if (uniqueMacnums.size < filterEmptyMacnums.length) {
        allValid = false;
      }

      if (allValid) {
        let isValid = true;
        const nonEmptyLocations = [];

        for (const [index, location] of newLocationsAdded.entries()) {
          if (
            location?.macnum &&
            location.site &&
            (location.local_contact_name.length === 0 ||
              location.local_contact_number.length === 0)
          ) {
            isValid = false;
            await trigger(`locations.${index}.local_contact_name`);
            await trigger(`locations.${index}.local_contact_number`);
          } else if (
            location?.macnum &&
            location.site &&
            location.local_contact_name.length > 0 &&
            location?.macnum?.trim() !== ''
          ) {
            nonEmptyLocations.push(location);
          }
        }
        if (isValid) {
          setValue('locations', nonEmptyLocations, { shouldValidate: true });
          setSavedState(nonEmptyLocations);
          setStep(2);
        }
      }
    } else if (step === 2) {
      const validationResults = await Promise.all(
        locationsFields.map((_, index) =>
          trigger(`locations.${index}.locationName`),
        ),
      );

      const validationNumberResults = await Promise.all(
        locationsFields.map((_, index) =>
          trigger(`locations.${index}.locationNumber`),
        ),
      );
      const allValid = validationResults.every((result) => result);
      const allNumbersValid = validationNumberResults.every((result) => result);
      if (allValid && allNumbersValid) {
        setStep(3);
      }
    } else {
      const validationResults = await Promise.all(
        locationsFields.map((_, index) =>
          trigger(`locations.${index}.priority`),
        ),
      );
      const allValid = validationResults.every((result) => result);
      if (allValid) {
        uploadLocations?.(getValues());
        close();
        setStep(1);
        isFirstRender.current = true;
        return;
      }
    }
  };

  const onSubmit = (data: LocationsSchema) => {
    console.log(data);
  };
  const onError = (err: unknown) => console.log(err);

  const handleClose = async () => {
    if (step === 1) {
      close();
      if (locations?.length === 0) {
        reset();
      }
      setStep(1);
      isFirstRender.current = true;
      return;
    }
    if (step === 2) {
      setValue('locations', [
        ...newLocationsAdded,
        ...Array(10 - newLocationsAdded.length).fill(DEFAULT_STATE),
      ]);
    }
    setStep((prevState) => prevState - 1);
  };

  const { isLoading: isDownloading, onDownloadHandler } =
    useDownloadConfigurations({ fetchFn: getSites });

  const isDuplicate = useCallback(
    (value: string, index: number) => {
      if (value.trim() === '') {
        return false;
      }

      const duplicateIndexes = newLocationsAdded
        .map((loc, locIndex) => (loc.macnum === value ? locIndex : -1))
        .filter((locIndex) => locIndex !== -1);

      const isFirstOccurrence = duplicateIndexes[0] === index;
      const isDuplicateOccurrence =
        duplicateIndexes.includes(index) && !isFirstOccurrence;

      return isDuplicateOccurrence;
    },
    [newLocationsAdded],
  );

  const gridCols = step === 2 ? 11 : step === 3 ? 7 : 12;

  return (
    <Modal
      isVisible={isOpen}
      closeOnlyOnIconClick={true}
      close={() => {
        close();
        if (locations?.length === 0) {
          reset();
        }
        setStep(1);
        isFirstRender.current = true;
      }}
      className="bulk-modal-locations w-full !max-w-[1254px]"
      enableScrolling
    >
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        className="flex flex-col rounded-lg rounded-t-lg bg-background-base-surface-2"
        id="bulk-locations"
      >
        <div className="rounded-t-lg bg-background-base-surface-3 px-8  py-6 font-bold leading-9">
          <h1 className="text-[28px] font-bold leading-9 text-content-base-default">
            Bulk add locations
          </h1>
          {step === 1 && (
            <div className="flex flex-col items-center justify-between gap-4 sm:flex-row">
              <StepSubtitle text="Add locations to create up to 10 individual dispatch requests at once." />
              <GraniteButton
                size="medium"
                variant="secondary"
                onClick={
                  !isDownloading ? onDownloadHandler : (e) => e.preventDefault()
                }
                className={clsx(
                  isDownloading &&
                    '!cursor-default !border-button-stroke-secondary-pressed !bg-button-background-secondary-pressed !text-button-content-secondary-pressed',
                  'sm:!small w-full whitespace-nowrap sm:w-auto',
                )}
              >
                Download site list{' '}
                {isDownloading ? (
                  <Loader animationClassname="!w-3 !h-3" />
                ) : (
                  <DownloadSharp width="18px" height="18px" color="inherit" />
                )}
              </GraniteButton>
            </div>
          )}
          {step === 2 && (
            <StepSubtitle
              text="This info will help the technician locate your business upon
                          arrival."
            />
          )}
          {step === 3 && (
            <StepSubtitle text="Select the desired dispatch date & time for each location below." />
          )}
        </div>
        <div className="relative grid grid-cols-3 items-end gap-1 px-8 pb-4 pt-8">
          <WizardStateBlock
            label="Find your addresses"
            classNames="text-xs"
            isActive={step >= 1}
          />
          <WizardStateBlock
            label="Add location details"
            classNames="text-xs"
            isActive={step >= 2}
          />
          <WizardStateBlock
            label="Select dispatch date & time"
            classNames="text-xs"
            isActive={step >= 3}
          />
        </div>

        <div className="px-8 pb-8">
          <div className="max-h-[260px] !overflow-auto scrollbar-thin scrollbar-track-background-base-surface-1 scrollbar-thumb-stroke-base-subdued md:max-h-[408px]">
            <div style={{ minWidth: `${80 * gridCols}px` }}>
              <div className="sticky top-0 z-10 flex w-full items-start justify-start gap-4 bg-background-base-surface-2 pb-6">
                <div className="w-[30px] font-bold text-content-base-subdued">
                  #
                </div>
                <div
                  className={'grid w-full gap-4'}
                  style={{ gridTemplateColumns: `repeat(${gridCols}, 1fr)` }}
                >
                  {step === 1 && (
                    <div className="col-span-2 font-bold text-content-base-default">
                      Child account
                    </div>
                  )}
                  <div className="col-span-5 font-bold text-content-base-default">
                    Address
                  </div>
                  {step === 1 && (
                    <>
                      <div className="col-span-3 col-start-8 font-bold text-content-base-default">
                        Local contact
                      </div>
                      <div className="col-span-2 col-start-11 font-bold text-content-base-default">
                        Phone number
                      </div>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <div className="col-span-3  font-bold text-content-base-default">
                        Location name
                      </div>
                      <div className="col-span-3  font-bold text-content-base-default">
                        Location number (optional)
                      </div>
                    </>
                  )}
                  {step === 3 && (
                    <div className="col-span-2 font-bold text-content-base-default">
                      Dispatch date
                    </div>
                  )}
                </div>
              </div>
              <div className="">
                {locationsFields.map((field, index) => (
                  <div
                    key={field.id}
                    className="mb-6 flex w-full items-start justify-start gap-4"
                  >
                    <div className="w-[30px] pt-3">{index + 1}</div>
                    <div
                      className={'grid h-full w-full gap-x-[16px] gap-y-[24px]'}
                      style={{
                        gridTemplateColumns: `repeat(${gridCols}, 1fr)`,
                      }}
                    >
                      {step === 1 && (
                        <MacnumInput
                          name={`locations.${index}.macnum`}
                          control={control}
                          index={index}
                          error={
                            isDuplicate(
                              getValues(`locations.${index}.macnum`),
                              index,
                            )
                              ? 'Macnum already added.'
                              : index > 2
                                ? ''
                                : errors.locations?.[index]?.macnum?.message
                          }
                          updateLocationData={updateLocationData}
                          onPaste={(e) => handlePaste(e, index)}
                          currentLocations={newLocationsAdded}
                          macnumValue={getValues(`locations.${index}.macnum`)}
                          storedData={savedState}
                        />
                      )}
                      <div className="relative col-span-5">
                        <Controller
                          name={`locations.${index}.site`}
                          control={control}
                          render={({ field }) => {
                            return (
                              <SearchAddressBar
                                {...field}
                                bulkModalValue={newLocationsAdded[index].site}
                                onAddressSelected={(address) => {
                                  // RHF bug workaround
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  setValue(`locations.${index}.site`, address);
                                }}
                                name={'search address'}
                                error={
                                  errors.locations?.[index]?.site?.message &&
                                  'An address is required'
                                }
                                isDisabled
                                isBulkUploadModal={true}
                                className="w-full"
                              />
                            );
                          }}
                        />
                      </div>
                      {step === 1 && (
                        <>
                          <GraniteInput
                            className="col-span-3 col-start-8 w-full"
                            placeholder="Name"
                            {...register(
                              `locations.${index}.local_contact_name`,
                            )}
                            innerInputClassName="w-full"
                            error={
                              errors.locations?.[index]?.local_contact_name
                                ?.message
                            }
                            onChange={(e) => {
                              setValue(
                                `locations.${index}.local_contact_name`,
                                e.target.value,
                              );
                              if (e.target.value.trim())
                                trigger(
                                  `locations.${index}.local_contact_name`,
                                );
                            }}
                          />
                          <PhoneNumberInput
                            className="col-span-2 col-start-11 w-full !max-w-[197px]"
                            name={`locations.${index}.local_contact_number`}
                            control={control}
                            innerInputClassName={'w-full'}
                            error={
                              errors.locations?.[index]?.local_contact_number
                                ?.message
                            }
                            label={''}
                            onUpdate={(e: string) => {
                              setValue(
                                `locations.${index}.local_contact_number`,
                                e,
                              );
                              if (e.trim())
                                trigger(
                                  `locations.${index}.local_contact_number`,
                                );
                            }}
                          />
                        </>
                      )}
                      {step === 2 && (
                        <>
                          <GraniteInput
                            className="col-span-3 w-full"
                            placeholder="Business name"
                            innerInputClassName="w-full"
                            error={
                              errors.locations?.[index]?.locationName?.message
                            }
                            {...register(`locations.${index}.locationName`)}
                            onChange={(e) => {
                              setValue(
                                `locations.${index}.locationName`,
                                e.target.value,
                              );
                              if (e.target.value.trim())
                                trigger(`locations.${index}.locationName`);
                            }}
                          />
                          <GraniteInput
                            className="col-span-3 w-full"
                            placeholder="Store #"
                            innerInputClassName="w-full"
                            error={
                              errors.locations?.[index]?.locationNumber?.message
                            }
                            {...register(`locations.${index}.locationNumber`)}
                            onChange={(e) => {
                              setValue(
                                `locations.${index}.locationNumber`,
                                e.target.value,
                              );
                              if (e.target.value.trim())
                                trigger(`locations.${index}.locationNumber`);
                            }}
                          />
                        </>
                      )}
                      {step === 3 && (
                        <Controller
                          name={`locations.${index}.priority`}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <div className="col-span-2">
                              <CustomSelect
                                options={priorities}
                                onChange={onChange}
                                selectedValue={value}
                                error={
                                  errors.locations?.[index]?.priority?.message
                                }
                              />
                            </div>
                          )}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {step === 3 && (
            <p className="mt-2 text-base font-semibold text-content-base-subdued">
              Same day and next day dispatches will incur an additional fee.
              Time will be automatically converted to the local time zone of the
              location. Dispatches that fall outside of 8:00 AM - 5:00 PM on
              Mon-Fri, as well as holidays and weekends, are considered same-day
              dispatches.
            </p>
          )}
          {/* Form navigation */}
          <div className="relative mt-12 flex gap-5">
            <GraniteButton
              size="large"
              variant="secondary"
              onClick={handleClose}
              className="w-1/2 md:w-auto"
            >
              {step === 1 ? 'Cancel' : 'Back'}
            </GraniteButton>
            <GraniteButton
              size="large"
              onClick={handleNextClick}
              className="w-1/2 whitespace-nowrap md:w-auto"
            >
              {step === 3
                ? `Add locations (${locationsFields.length})`
                : 'Next'}
            </GraniteButton>
          </div>
        </div>
      </form>
    </Modal>
  );
};
