import { AxiosError } from 'axios';
import showToast from 'components/Toast/Toast';

interface Errors {
  json:
    | {
        _schema?: string[];
        text?: string[];
        text1?: string[];
      }
    | string[];
}

interface Reason {
  text?: string[];
  text1?: string[];
}

interface ResponseData {
  errors: Errors;
  reason?: Reason | string;
  message?: string;
}

// Handle 403 error
function handle403Error(status: number): boolean {
  if (status === 403) {
    showToast.error({
      message: "You don't have permissions for that operation!",
    });
    return true;
  }
  return false;
}

// Handle 5xx errors
function handle5xxErrors(status: number): boolean {
  if (status >= 500 && status < 600) {
    showToast.error({ message: 'Service unavailable!' });
    return true;
  }
  return false;
}

// Handle JSON schema errors
function handleJSONSchemaErrors(errors: Errors): boolean {
  if (!errors) return false;

  if (
    typeof errors.json === 'object' &&
    errors.json !== null &&
    !Array.isArray(errors.json)
  ) {
    if ('_schema' in errors.json && errors.json._schema) {
      showToast.error({ message: errors.json._schema.join(' ') });
      return true;
    }
    if ('text' in errors.json && errors.json.text) {
      showToast.error({ message: errors.json.text.join(' ') });
      return true;
    }
    if ('text1' in errors.json && errors.json.text1) {
      showToast.error({ message: errors.json.text1.join(' ') });
      return true;
    }
  } else if (Array.isArray(errors.json)) {
    showToast.error({ message: errors.json.join(' ') });
    return true;
  }

  return false;
}

// Handle reason errors
function handleReasonErrors(reason: Reason | string | undefined): boolean {
  if (!reason) return false;

  if (typeof reason === 'string') {
    showToast.error({ message: reason as string });
    return true;
  }

  const missingData = reason.text;
  const unknownField = reason.text1;
  if (missingData || unknownField) {
    if (missingData) {
      showToast.error({ message: missingData.join(' ') });
    }
    if (unknownField) {
      showToast.error({ message: unknownField.join(' ') });
    }
    return true;
  }
  return false;
}

function handle404Error(status: number): boolean {
  if (status === 404) {
    showToast.error({
      message: 'The requested resource was not found!',
    });
    return true;
  }
  return false;
}

export const getErrorResponse = (error: AxiosError<ResponseData>) => {
  const response = error.response;

  if (!response) {
    showToast.error({ message: 'Something went wrong!' });
    return Promise.reject(error);
  }

  const message = response?.data?.message;

  if (message?.startsWith('File validation')) {
    return Promise.reject(message);
  }

  const data = response.data;
  const status = response.status;

  if (
    !handle5xxErrors(status) &&
    !handle403Error(status) &&
    !handleReasonErrors(data.reason) &&
    !handleJSONSchemaErrors(data.errors) &&
    !handle404Error(status)
  ) {
    showToast.error({ message: 'Something went wrong!' });
  }

  return Promise.reject(error);
};
