import clsx from 'clsx';
import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useProductTourContext } from 'context/ProductTourContext';
import { useAuthUser } from 'hooks/useAuthUser';
import { ModalParams } from 'hooks/useModal';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { ArrowForward } from 'react-ionicons';
import { hasAccessToCatalogItem } from './utils';

type ListItem = {
  text: string;
  key: string;
  onClick: () => void;
  nested?: boolean;
};

export interface CatalogModal extends ModalParams {}

export const CatalogModal = ({ isOpen, close }: CatalogModal) => {
  const { setRunning, setStepHistory } = useProductTourContext();
  const { roles } = useAuthUser();

  const handleOnClick = useCallback(
    (step: number) => {
      close();
      setTimeout(() => {
        const newHistory = Array.from({ length: step + 1 }, (_, i) => i);
        setStepHistory(newHistory);
        setRunning(true);
      }, 100);
    },
    [close, setRunning, setStepHistory],
  );

  const listItems: ListItem[] = useMemo(() => {
    const items: ListItem[] = [
      {
        text: 'Dashboard',
        key: 'dashboard',
        onClick: () => handleOnClick(1),
      },
      {
        text: 'TechExpress',
        key: 'techexpress',
        onClick: () => handleOnClick(3),
      },
      {
        text: 'Open a ticket',
        key: 'techexpress-open-ticket',
        nested: true,
        onClick: () => handleOnClick(4),
      },
      {
        text: 'View & manage tickets',
        key: 'techexpress-view-tickets',
        nested: true,
        onClick: () => handleOnClick(9),
      },
      {
        text: 'NOCExpress',
        key: 'noc_express',
        onClick: () => handleOnClick(11),
      },
      {
        text: 'Open a ticket',
        key: 'noc_express-open-ticket',
        nested: true,
        onClick: () => handleOnClick(13),
      },
      {
        text: 'View & manage tickets',
        key: 'noc_express-view-tickets',
        nested: true,
        onClick: () => handleOnClick(16),
      },
      {
        text: 'Mobility',
        key: 'mobility',
        onClick: () => handleOnClick(20),
      },
      {
        text: 'User management',
        key: 'user_management',
        onClick: () => handleOnClick(21),
      },
    ];

    return items.filter((item) => hasAccessToCatalogItem(roles, item.key));
  }, [handleOnClick, roles]);

  return (
    <Modal isVisible={isOpen} close={close}>
      <h1 className="flex flex-col justify-center gap-2 rounded-t-lg bg-background-base-surface-3 px-8 py-6 text-[28px] font-bold leading-9 text-content-base-default">
        <p className="font-bold text-content-base-default">Product tour</p>
        <p className="text-base font-semibold text-content-base-subdued">
          Complete our interactive tutorial to learn about how to use Granite360
          and its features.{' '}
        </p>
      </h1>
      <div className="flex h-auto w-[836px] flex-col items-start justify-start gap-4 rounded bg-background-base-surface-2 p-8">
        <p className="text-2xl font-bold text-content-base-default">
          Where do you want to start?
        </p>
        <div className="flex w-full flex-col gap-12 bg-background-base-surface-2">
          <div className="flex w-full flex-col items-start justify-start rounded">
            <div className="w-full rounded bg-background-base-surface-1 p-4">
              <ul className="flex list-inside list-disc flex-col items-start justify-start gap-3 p-2">
                {listItems.map((item, index) => (
                  <ListItem
                    key={index}
                    text={item.text}
                    nested={item.nested}
                    onClick={item.onClick}
                  />
                ))}
              </ul>
            </div>
          </div>
          <div className="flex gap-[20px]">
            <GraniteButton
              variant="secondary"
              size="large"
              onClick={close}
              className="mt-3"
            >
              Cancel
            </GraniteButton>
            <GraniteButton
              variant="primary"
              size="large"
              onClick={() => handleOnClick(1)}
              className="mt-3"
            >
              Start tour
            </GraniteButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ListItem = ({
  text,
  nested,
  className,
  onClick,
}: {
  text: string;
  children?: ReactNode;
  nested?: boolean;
  className?: string;
  onClick: () => void;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <li
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      className={clsx(
        'flex gap-[10px] font-bold text-content-base-default hover:cursor-pointer hover:!text-content-accent-default',
        nested && 'ml-6 !text-sm !text-content-base-subdued',
        className,
      )}
    >
      {text}{' '}
      {isHovered && <ArrowForward color="#82F0FF" width="20px" height="20px" />}
    </li>
  );
};
