import { lookup } from 'zipcode-to-timezone';
import {
  CANCELLED,
  COMPLETE,
  PENDING_CUSTOMER,
  ExtendedTicket,
  PENDING_TECH_ASSIGNMENT,
  TECH_ASSIGNED,
  VALID_TYPES_BOARD_MAPPING,
  ON_HOLD,
} from '../../api/techexpress/schema';
import { formatInTimeZone } from 'date-fns-tz';

export const getPriorityLabel = (
  priority: 'P1' | 'P2' | 'P3' | string | undefined,
): string => {
  switch (priority) {
    case 'P1':
      return 'Priority 1';
    case 'P2':
      return 'Priority 2';
    case 'P3':
      return 'Priority 3';
    case 'PD':
      return 'Premium';
    default:
      return '';
  }
};

export const isTicketReadonlyFn = ({
  type_name,
  board_name,
}: Pick<ExtendedTicket, 'type_name' | 'board_name'>) => {
  const boards =
    VALID_TYPES_BOARD_MAPPING[
      type_name as keyof typeof VALID_TYPES_BOARD_MAPPING
    ];
  if (!boards) return false;
  return boards.find(({ board }) => board === board_name)?.readonly ?? false;
};

export const isSameDayAsDispatch = (
  ticket: Pick<ExtendedTicket, 'schedule' | 'zip'>,
): boolean => {
  if (!ticket?.schedule?.start_date || !ticket.zip) return false;

  const timezone = lookup(ticket.zip);
  if (!timezone) return false;

  const dispatchDateStart = formatInTimeZone(
    ticket.schedule.start_date,
    timezone,
    'yyyy-MM-dd',
  );
  const todayDate = formatInTimeZone(new Date(), timezone, 'yyyy-MM-dd');

  return dispatchDateStart === todayDate;
};

export const isTicketEditableFn = (
  ticket: Pick<
    ExtendedTicket,
    'status_name' | 'type_name' | 'board_name' | 'schedule' | 'zip'
  >,
) => {
  if (isTicketReadonlyFn(ticket)) return false;
  return (
    !isSameDayAsDispatch(ticket) &&
    (ticket.status_name === PENDING_TECH_ASSIGNMENT ||
      ticket.status_name === TECH_ASSIGNED ||
      ticket.status_name === PENDING_CUSTOMER ||
      ticket.status_name === ON_HOLD)
  );
};

export const isTicketCancellableFn = (
  ticket: Pick<
    ExtendedTicket,
    'status_name' | 'type_name' | 'board_name' | 'schedule' | 'zip'
  >,
) => {
  if (isTicketReadonlyFn(ticket)) return false;
  return (
    !isSameDayAsDispatch(ticket) &&
    (ticket.status_name === PENDING_TECH_ASSIGNMENT ||
      ticket.status_name === TECH_ASSIGNED ||
      ticket.status_name === PENDING_CUSTOMER)
  );
};

export const isTicketCompletedOrCancelled = (ticket?: ExtendedTicket) =>
  ticket?.status_name === COMPLETE || ticket?.status_name === CANCELLED;

export const isTicketCompleted = (ticket?: ExtendedTicket) =>
  ticket?.status_name === COMPLETE;
