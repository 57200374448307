import {
  VerifyAddressRequest,
  VerifyAddressResponse,
} from 'api/accessexpress/schema';
import { apiClient } from '../apiClient';
import { SearchAddressResponseSchema } from './schema';

export const addressSearch = async (search: string) => {
  const response = await apiClient.get(`/api/v1/companies/addresses`, {
    params: {
      search,
    },
  });
  return SearchAddressResponseSchema.parse(response.data);
};

// MELISSA_DATA_VERIFY = 'verify:melissadata',
export const verifyAddress = async (
  address: VerifyAddressRequest,
): Promise<VerifyAddressResponse> => {
  const response = await apiClient.post('/api/v1/md/address/verify', {
    records: [address],
  });
  return response.data[0];
};
