import Checkbox from 'components/Checkbox';
import React, { FC } from 'react';
import { SubGroupType } from './accounts.types';
import clsx from 'clsx';

export interface SubGroupProps extends SubGroupType {
  onClick?: () => void;
  checked?: boolean;
}

const SubGroup: FC<SubGroupProps> = ({
  id,
  name,
  onClick = undefined,
  checked,
}) => {
  return (
    <div
      className={clsx(
        'flex w-full items-center gap-6 rounded bg-blue-700 px-4 py-2.5',
        {
          'cursor-pointer': onClick,
        },
      )}
      onClick={onClick}
    >
      {onClick && <Checkbox checked={checked} />}
      <p>{id}</p>
      <p>{name}</p>
    </div>
  );
};

export default SubGroup;
