import clsx from 'clsx';
import { Business } from 'react-ionicons';

const CompanyName = ({
  name,
  wrapperClasses,
  textClasses,
}: {
  name: string;
  wrapperClasses?: string;
  textClasses?: string;
}) => {
  return (
    <div className={clsx('flex items-center gap-3 rounded-md', wrapperClasses)}>
      <Business width="20px" height="20px" color={'#F8FAFC'} />
      <label
        className={clsx('font-bold text-content-base-default', textClasses)}
      >
        {name}
      </label>
    </div>
  );
};

export default CompanyName;
