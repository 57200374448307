import { z } from 'zod';
import { AnyTicketTypes } from 'screens/LetUsHelp/BaseComponents/schemas';
import { NonStandardToolsFieldSchema } from 'screens/LetUsHelp/utils/schemas';

export const TemplateDetailsFormSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  description: z.string().min(1, 'Description is required'),
});

export type TemplateDetailsForm = z.infer<typeof TemplateDetailsFormSchema>;

export const ReviewTemplateFormSchema = z.object({
  ticketType: AnyTicketTypes,
  specialInstructions: z
    .string()
    .max(1000, 'Only 1000 characters allowed')
    .optional(),
  scopeOfWork: z.string().nonempty('Required'),
  scopeOfWorkInstructions: z.string().optional(),
  nonStandardTools: NonStandardToolsFieldSchema,
});

export type ReviewTemplateForm = z.infer<typeof ReviewTemplateFormSchema>;
