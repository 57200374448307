import { Manager } from 'socket.io-client';

const URL = process.env.REACT_APP_G360_API_URL!;

const manager = new Manager(URL, {
  transports: ['websocket', 'polling'],
  autoConnect: false,
});

export const createSocketInstance = (namespace: string) =>
  manager.socket(namespace);
