import { NotificationsSharp } from 'react-ionicons';

const EmptyState = () => {
  return (
    <div className="flex h-full w-full items-center justify-center rounded bg-background-base-surface-1">
      <div className="m-auto flex flex-col items-center gap-2 text-content-base-subdued">
        <NotificationsSharp width="36px" height="42px" color="#94A3B8" />
        <div className="flex flex-col items-center gap-1">
          <p className="text-sm font-bold">You&apos;re all caught up</p>
          <p className="text-xs font-semibold">
            We&apos;ll let you know when updates arrive!
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
