import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';

export const SaveDraftConfirmationDialog = ({
  isOpen,
  close,
  onConfirmation,
  dynamicProps,
}: {
  isOpen: boolean;
  close: (cancel?: boolean) => void;
  onConfirmation: () => void;
  dynamicProps?: boolean;
}) => (
  <Modal isVisible={isOpen} close={close} className="max-w-[624px]">
    <div className="space-y-12 rounded-t-lg bg-background-base-surface-2 p-8">
      <div className="space-y-2">
        <h1 className="text-content-base-default">Save as draft?</h1>
        <p className="font-bold text-content-base-subdued">
          Do you want to save this ticket as a draft for future use? Note that
          any attached files will not be saved to this draft and will need to be
          re-attached when ready for submission.
        </p>
      </div>
      <div className="flex gap-5">
        <GraniteButton
          size="large"
          variant="secondary"
          onClick={() => close(dynamicProps)}
        >
          No, thanks
        </GraniteButton>
        <GraniteButton
          size="large"
          variant="primary"
          onClick={() => {
            onConfirmation();
          }}
        >
          Save as draft
        </GraniteButton>
      </div>
    </div>
  </Modal>
);
