import React from 'react';
import { Link } from 'react-router-dom';
import { useSimplePagination } from '../../hooks/useSimplePagination';
import IconButton from 'components/IconButton/IconButton';
import { EmptyState } from 'components/EmptyState/EmptyState';
import Skeleton from 'components/Skeleton/Skeleton';
import BadgeCell from 'components/Badge/Badge';
import {
  EndDatedBadge,
  Priority1Badge,
} from 'screens/TechExpress/TechExpress.styles';
import { IRecentActivity } from './InventoryRecentActivity';

interface RecentActivityProps {
  tickets: IRecentActivity[];
  pageSize?: number;
  isLoading?: boolean;
}

const RecentActivity: React.FC<RecentActivityProps> = ({
  tickets,
  pageSize = 5,
  isLoading,
}) => {
  const {
    maxPages,
    pageNumber,
    previousPage,
    nextPage,
    page: pagedActivity,
  } = useSimplePagination({ items: tickets, pageSize });

  return (
    <>
      <div className="absolute right-0 top-0 hidden items-center justify-center gap-2 sm:flex">
        <IconButton
          size="small"
          buttonType="secondary"
          icon="chevronBack"
          onClick={previousPage}
          disabled={pageNumber === 1}
        />
        <IconButton
          size="small"
          buttonType="secondary"
          icon="chevronForward"
          onClick={nextPage}
          disabled={pageNumber === maxPages}
        />
      </div>
      <div>
        {isLoading ? (
          <div className="flex h-full w-full flex-col gap-3">
            {Array.from({ length: 5 }).map((_, index) => (
              <Skeleton key={index} className="h-16" />
            ))}
          </div>
        ) : pagedActivity.length > 0 ? (
          <div className="max-h-400 flex flex-col gap-3 overflow-y-auto">
            {pagedActivity.map((ticket) => (
              <Link
                key={ticket.id}
                className="flex w-full flex-col gap-2 rounded bg-background-base-surface-3 p-2 hover:border-content-accent-default focus:border-content-accent-default active:bg-background-base-surface-2"
                to={`/inventory`}
              >
                <div className="flex w-full justify-between">
                  <div className="flex w-full flex-row">
                    <p className="w-full text-sm font-bold text-content-base-default">
                      {ticket.address1}
                    </p>
                  </div>
                  <div className="flex w-full justify-end gap-2">
                    <BadgeCell value={ticket.tag} width="!w-max" />
                    <BadgeForTag value={ticket.service_id} tag={ticket.tag} />
                  </div>
                </div>
                <div className="flex w-full justify-between">
                  <div className="flex flex-row">
                    <p className="text-text-content-base-subdued text-xs font-semibold">
                      {ticket.description}
                    </p>
                  </div>
                  <div className="flex flex-row">
                    <p className="text-xs font-semibold text-content-base-subdued">
                      {ticket.type}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <EmptyState className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
        )}
      </div>
    </>
  );
};

export const BadgeForTag = ({
  tag,
  value,
}: {
  tag: 'Added' | 'End dated' | undefined;
  value: string;
}) => {
  switch (tag) {
    case 'Added':
      return <Priority1Badge>{value}</Priority1Badge>;
    case 'End dated':
      return <EndDatedBadge>{value}</EndDatedBadge>;
    default:
      return null;
  }
};

export default RecentActivity;
