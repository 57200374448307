import format from 'date-fns/format';
import {
  DatePartState,
  DatePickerFooter,
  DatePickerFooterButton,
  DatePickerIcon,
  DatePickerMenu,
  DatePickerMenuDayCell,
  DatePickerMenuGrid,
  DatePickerMenuHeaderCell,
  DatePickerMenuToolbar,
  DatePickerMenuToolBarIconWrapper,
  DatePickerMenuWrapper,
} from './DatePicker.styles';
import { usePopper } from 'react-popper';
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import {
  addMonths,
  addYears,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
  eachYearOfInterval,
  endOfMonth,
  endOfWeek,
  endOfYear,
  isBefore,
  isSameDay,
  isSameMonth,
  isSameYear,
  isValid,
  parse,
  setMonth,
  setYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subMonths,
} from 'date-fns';
import IconButton from '../IconButton';
import ListDropdown from '../ListDropdown';
import { ErrorSubtext } from '../ErrorSubtext/ErrorSubtext';
import { PatternFormat } from 'react-number-format';
import { GraniteButton } from '../V2/Button/GraniteButton';
import { clsx } from 'clsx';
import './DatePicker.css';
import { VALID_US_DATE_FORMAT } from '../../shared/constants/error-labels';

export interface DatePickerGraniteProps {
  error?: string;
  disabled?: boolean;
  onChange: (value?: Date) => void;
  value?: Date;
  className?: string;
  min?: Date;
}

const getDatePartState = (
  date?: Date,
  error?: string,
  disabled?: boolean,
): DatePartState => {
  if (disabled) return 'disabled';
  if (error) return 'error';
  if (date) return 'filled';
  return 'empty';
};

const DatePickerGranite = forwardRef<HTMLDivElement, DatePickerGraniteProps>(
  ({ error, onChange, value, disabled, className, min }, ref) => {
    const datePickerAnchorRef = useRef(null);
    const datePickerButtonRef = useRef<HTMLButtonElement>(null);
    const popperElementRef = useRef<HTMLDivElement>(null);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [internalError, setInternalError] = useState<string | undefined>();

    const { styles, attributes } = usePopper(
      datePickerAnchorRef.current,
      popperElementRef.current,
      {
        placement: 'bottom-start',
      },
    );

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        const isClickOutsideDatePickerContent =
          popperElementRef.current &&
          event.target instanceof Node &&
          !popperElementRef.current.contains(event.target);
        const isClickOnDatePickerButton =
          datePickerButtonRef.current &&
          event.target instanceof Node &&
          (event.target.isSameNode(datePickerButtonRef.current) ||
            datePickerButtonRef.current.contains(event.target));

        if (isClickOutsideDatePickerContent && !isClickOnDatePickerButton) {
          setIsMenuVisible(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isMenuVisible]);

    const datePartState = useMemo(
      () => getDatePartState(value, error, disabled),
      [value, error, disabled],
    );

    const formatDateForInput = useCallback(
      (date?: Date) => (date ? format(date, 'MMddyyyy') : ''),
      [],
    );

    const [textState, setTextState] = useState(formatDateForInput(value));

    const parseDateTextInput = useCallback((dateAsStr: string) => {
      if (dateAsStr.length !== 8) return undefined;
      const parsed = parse(dateAsStr, 'MMddyyyy', startOfDay(new Date()));
      if (isValid(parsed)) {
        setInternalError(undefined);
        return parsed;
      } else {
        setInternalError(VALID_US_DATE_FORMAT);
        return undefined;
      }
    }, []);

    useEffect(() => {
      // If the value coming through is valid and the same as the textState, don't call onChange
      if (isValid(value)) {
        const formattedDate = formatDateForInput(value);
        if (textState === formattedDate) {
          return;
        }
      }
      onChange(parseDateTextInput(textState));
    }, [formatDateForInput, onChange, parseDateTextInput, textState, value]);

    const changeAndHide = useCallback(
      (value?: Date) => {
        setTextState(formatDateForInput(value));
        setIsMenuVisible(false);
      },
      [formatDateForInput],
    );

    return (
      <div className={clsx(className, 'flex flex-col gap-2')}>
        <div
          className={clsx('DatePicker', error && 'error')}
          ref={datePickerAnchorRef}
        >
          <PatternFormat
            format="##/##/####"
            mask={['m', 'm', 'd', 'd', 'y', 'y', 'y', 'y']}
            aria-label="date"
            getInputRef={ref}
            placeholder="mm/dd/yyyy"
            value={textState}
            onValueChange={({ value }) => {
              setTextState(value);
            }}
            disabled={disabled}
          />
          <GraniteButton
            variant="unstyled"
            disabled={disabled}
            title="open date picker"
            aria-label="open date picker"
            onClick={() =>
              !disabled && setIsMenuVisible((isVisible) => !isVisible)
            }
            ref={datePickerButtonRef}
          >
            <DatePickerIcon
              $datePartState={datePartState}
              name="calendar"
              size={16}
            />
          </GraniteButton>
        </div>
        {createPortal(
          <DatePickerMenu
            ref={popperElementRef}
            style={{ ...styles.popper, zIndex: 9999 }}
            {...attributes.popper}
          >
            {isMenuVisible && (
              <DatePickerMenuContent
                value={value}
                onChange={changeAndHide}
                minDate={min}
              />
            )}
          </DatePickerMenu>,
          document.body,
        )}
        <ErrorSubtext error={internalError ?? error} withTopMargin={false} />
      </div>
    );
  },
);
DatePickerGranite.displayName = 'DatePickerGranite';

interface DatePickerMenuContentProps {
  value?: Date;
  onChange: (newDate?: Date) => void;
  minDate?: Date;
}

const DatePickerMenuContent = ({
  value,
  onChange,
  minDate,
}: DatePickerMenuContentProps) => {
  const [trackingDate, setTrackingDate] = useState(value ?? new Date());
  const daysOfMonth = useMemo(() => {
    return eachWeekOfInterval({
      start: startOfMonth(trackingDate),
      end: endOfMonth(trackingDate),
    }).flatMap((week) =>
      eachDayOfInterval({ start: startOfWeek(week), end: endOfWeek(week) }),
    );
  }, [trackingDate]);

  const nextMonth = useCallback(() => {
    setTrackingDate((prevDate) => addMonths(prevDate, 1));
  }, [setTrackingDate]);
  const prevMonth = useCallback(() => {
    setTrackingDate((prevDate) => subMonths(prevDate, 1));
  }, [setTrackingDate]);

  const years = useMemo(
    () =>
      eachYearOfInterval({
        start: new Date(),
        end: addYears(new Date(), 5),
      }).map((year) => ({
        label: `${year.getFullYear()}`,
        isSelected: isSameYear(year, trackingDate),
        onClick: () => {
          setTrackingDate((prevTrackingDate) =>
            setYear(prevTrackingDate, year.getFullYear()),
          );
        },
      })),
    [trackingDate],
  );

  const months = useMemo(
    () =>
      eachMonthOfInterval({
        start: startOfYear(trackingDate),
        end: endOfYear(trackingDate),
      }).map((month) => ({
        label: format(month, 'MMMM'),
        isSelected: isSameMonth(month, trackingDate),
        onClick: () => {
          setTrackingDate((prevTrackingDate) =>
            setMonth(prevTrackingDate, month.getMonth()),
          );
        },
      })),
    [trackingDate],
  );

  return (
    <DatePickerMenuWrapper data-testid="datepicker-menu-content">
      <DatePickerMenuToolbar>
        <ListDropdown
          label={format(trackingDate, 'MMMM yyyy')}
          withCaret
          options={[years, months]}
        />
        <DatePickerMenuToolBarIconWrapper>
          <IconButton
            onClick={prevMonth}
            buttonType="ghost"
            icon="arrowBack"
            aria-label="Go to previous month"
            type="button"
            className="border-stroke-base-subdued"
          />
          <IconButton
            onClick={nextMonth}
            buttonType="ghost"
            icon="arrowForward"
            aria-label="Go to next month"
            type="button"
          />
        </DatePickerMenuToolBarIconWrapper>
      </DatePickerMenuToolbar>
      <DatePickerMenuGrid>
        {'SMTWTFS'.split('').map((d, i) => (
          <DatePickerMenuHeaderCell key={i}>{d}</DatePickerMenuHeaderCell>
        ))}
        {daysOfMonth.map((day) => (
          <DatePickerMenuDayCell
            key={day.toISOString()}
            onClick={(e) => {
              e.stopPropagation();
              onChange(day);
            }}
            aria-label={format(day, 'MM/dd/yyyy')}
            $selected={value && isSameDay(day, value)}
            disabled={minDate && isBefore(day, minDate)}
          >
            {format(day, 'd')}
          </DatePickerMenuDayCell>
        ))}
      </DatePickerMenuGrid>
      <DatePickerFooter>
        <DatePickerFooterButton
          mode="ghost"
          size="small"
          onClick={() => onChange(undefined)}
          label="Clear"
          type="button"
        />
        <GraniteButton
          variant="ghost"
          size="small"
          onClick={() => {
            onChange(new Date());
            setTrackingDate(new Date());
          }}
          disabled={minDate && isBefore(startOfDay(new Date()), minDate)}
          type="button"
        >
          Today
        </GraniteButton>
      </DatePickerFooter>
    </DatePickerMenuWrapper>
  );
};

export default DatePickerGranite;
