import { z } from 'zod';
import {
  ContactSubFormSchema,
  NonStandardToolsFieldSchema,
  OptionLabelValueSchema,
} from '../../utils/schemas';
import { TicketDetailForms } from '../DefinedFormTypes';

export const BroadbandCircuitFormSchema = z.object({
  formType: z.literal(TicketDetailForms.enum.BroadbandCircuit),

  whenDidIssueBegin: OptionLabelValueSchema,
  issueDescription: z.string().trim().nonempty('Required'),
  techToTestWith: z.discriminatedUnion('techToTestWith', [
    z.object({ techToTestWith: z.literal('Yes') }),
    z.object({ techToTestWith: z.literal('No') }),
  ]),
  itTestingContacts: z.array(ContactSubFormSchema),
  nonStandardTools: NonStandardToolsFieldSchema,
});

export type BroadbandCircuitForm = z.infer<typeof BroadbandCircuitFormSchema>;
