import { OptionType } from '../components/Select/Select';
import { useCallback, useMemo, useState } from 'react';
import { MultiValue } from 'react-select';

export interface UseFilterForTableProps<T extends string> {
  queryParamKey: T;
}

export interface UseFilterForTableReturn<T extends string> {
  clearFilter: () => void;
  onChange: (values: MultiValue<OptionType>) => void;
  value: MultiValue<OptionType>;
  queryParamFilter?: { [K in T]: string };
}

export const useFilterForTable = <QPKey extends string>({
  queryParamKey,
}: UseFilterForTableProps<QPKey>): UseFilterForTableReturn<QPKey> => {
  const [filter, setFilter] = useState<MultiValue<OptionType>>(
    [] as MultiValue<OptionType>,
  );
  const queryParamFilter = useMemo(() => {
    return filter.length > 0
      ? ({
          [queryParamKey]: filter
            .map((v) => v.value)
            .filter((v) => v)
            .join(','),
        } as { [K in QPKey]: string })
      : undefined;
  }, [filter, queryParamKey]);

  const clearFilter = useCallback(() => setFilter([]), []);

  return {
    clearFilter,
    onChange: setFilter,
    value: filter,
    queryParamFilter,
  };
};
