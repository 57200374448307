import { getSignedUrl } from 'api/resources/api';
import { Resource } from 'api/resources/schema';
import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ModalParams } from 'hooks/useModal';
import { useQuery } from 'react-query';
import { getFileType } from '../utils';
import { Image } from './Image';
import { Pdf } from './Pdf';
import { Excel } from './Excel';
import { Word } from './Word';
import { Unknown } from './Unknown';
import { Video } from './Video';
import { CVS } from './CVS';
import ModalFormHeader from 'components/Modal/ModalFormHeader';
import Loader from 'components/Loader';

export interface ResourcePreviewProps {
  dynamicProps?: Resource;
  downloadFile: (resource: Resource) => void;
}

export interface CombinedProps extends ResourcePreviewProps, ModalParams {}

const getResourceElement = (type?: ReturnType<typeof getFileType>) => {
  switch (type) {
    case 'word':
      return Word;
    case 'pdf':
      return Pdf;
    case 'excel':
      return Excel;
    case 'image':
      return Image;
    case 'video':
      return Video;
    case 'csv':
      return CVS;
    default:
      return Unknown;
  }
};

export const ResourcePreviewDialog = ({
  isOpen,
  close,
  dynamicProps: resource,
  downloadFile,
}: CombinedProps) => {
  const { data, isLoading } = useQuery(
    ['resources', resource?.id],
    () => getSignedUrl(resource!.id),
    { enabled: !!resource?.id },
  );

  const Element = getResourceElement(
    resource?.file_type ? getFileType(resource.file_type) : undefined,
  );
  return (
    <Modal isVisible={isOpen} close={close} className="w-[836px]">
      <ModalFormHeader
        title={resource?.title ? resource?.title : ''}
        subtitle={resource?.description ?? undefined}
      />
      <div className="flex min-h-0 flex-1 flex-col justify-between gap-12 rounded-b-lg p-8">
        <div className="flex min-h-[440px] w-full flex-1 justify-center self-center">
          {!isLoading && data?.url ? (
            <Element className="h-[440px] w-full rounded-lg" src={data.url} />
          ) : (
            <Loader />
          )}
        </div>
        <div className="flex items-center justify-start gap-5">
          <GraniteButton size="large" variant="secondary" onClick={close}>
            Cancel
          </GraniteButton>
          <GraniteButton
            size="large"
            variant="primary"
            onClick={() => resource && downloadFile(resource)}
          >
            Download
          </GraniteButton>
        </div>
      </div>
    </Modal>
  );
};
