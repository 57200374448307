import { useState, useRef } from 'react';
import {
  useForm,
  useFieldArray,
  UseFormReturn,
  FieldErrors,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  BulkLocationsSchema,
  BulkLocations,
} from '../screens/OpenNetOpsTicket/FindLocationOrService/BulkAddLocations/schemas';
import { DEFAULT_LOCATION } from '../screens/OpenNetOpsTicket/FindLocationOrService/BulkAddLocations/BulkAddLocationsDialog';
import showToast from 'components/Toast/Toast';

type UseNOCBulkAddLocationsProps = {
  getLocations: (locations: BulkLocations['locations']) => void;
  close: () => void;
  locations?: BulkLocations['locations'];
};

type UseNOCBulkAddLocationsReturn = {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  showButtonsIndex: number;
  setShowButtonsIndex: React.Dispatch<React.SetStateAction<number>>;
  checkAll: boolean;
  setCheckAll: React.Dispatch<React.SetStateAction<boolean>>;
  formMethods: UseFormReturn<BulkLocations>;
  locationsFields: BulkLocations['locations'];
  append: (location: typeof DEFAULT_LOCATION) => void;
  handleCheckAllChange: () => void;
  handleNextClick: () => Promise<void>;
  handleKeyDown: (event: React.KeyboardEvent, index: number) => void;
  handleMaintenanceWindowBlur: (
    event: React.FocusEvent<HTMLInputElement>,
    currentIndex: number,
  ) => void;
  applyToAll: (index: number) => void;
  handlePaste: (
    event: React.ClipboardEvent<HTMLInputElement>,
    pasteIndex: number,
  ) => Promise<void>;
  areAllCheckboxesChecked: boolean;
  allMaintenanceFieldsHaveSameValue: () => boolean;
  onSubmit: (data: BulkLocations) => void;
  onError: (
    error: FieldErrors<{ locations: BulkLocations['locations'] }>,
  ) => void;
  maintenanceWindowRefs: React.MutableRefObject<(HTMLInputElement | null)[]>;
};

export const useNOCBulkAddLocations = ({
  getLocations,
  close,
  locations = [],
}: UseNOCBulkAddLocationsProps): UseNOCBulkAddLocationsReturn => {
  const [step, setStep] = useState(1);
  const [showButtonsIndex, setShowButtonsIndex] = useState<number>(-1);
  const [checkAll, setCheckAll] = useState(false);
  const maintenanceWindowRefs = useRef<Array<HTMLInputElement | null>>([]);

  const formMethods = useForm<BulkLocations>({
    resolver: zodResolver(BulkLocationsSchema),
    defaultValues: {
      locations:
        locations.length > 0
          ? locations
          : Array.from({ length: 10 }, () => DEFAULT_LOCATION),
    },
  });

  const { control, handleSubmit, trigger, setValue, watch } = formMethods;

  const { fields: locationsFields, append } = useFieldArray({
    control,
    name: 'locations',
  });

  const handleCheckAllChange = () => {
    const newCheckAllValue = !checkAll;
    setCheckAll(newCheckAllValue);
    locationsFields.forEach((_, index) => {
      setValue(`locations.${index}.canTestingBeDoneAnytime`, newCheckAllValue);
      if (newCheckAllValue) {
        setValue(`locations.${index}.maintenance_window`, '');
        trigger(`locations.${index}.maintenance_window`);
      }
    });
  };

  const handleNextClick = async () => {
    if (step === 1) {
      let allValid = true;

      for (let index = 0; index < locationsFields.length; index++) {
        if (index < 2) {
          // For the first two fields, ensure their child account is not empty
          const result = await trigger(`locations.${index}.childAccount`);
          if (!result) {
            allValid = false;
          }
        }
      }

      if (allValid) {
        // remove empty locations
        const formData = watch('locations') ?? [];
        const nonEmptyLocations = formData.filter(
          (location) => location.childAccount?.trim() !== '' && location.site,
        );
        setValue('locations', nonEmptyLocations, { shouldValidate: true });

        // move to step 2
        setStep(2);
      }
    } else if (step === 2) {
      const validationResults = await Promise.all(
        locationsFields.map((_, index) =>
          trigger(`locations.${index}.maintenance_window`),
        ),
      );

      const allValid = validationResults.every((result) => result);
      if (allValid) {
        handleSubmit(onSubmit, onError)();
        getLocations(watch('locations') ?? []);
        close();
        setStep(1);
      }
    }
  };

  const allMaintenanceFieldsHaveSameValue = () => {
    const allValues = (watch('locations') ?? []).map(
      (location) => location.maintenance_window,
    );
    return allValues.every((val) => val === allValues[0]);
  };

  const handleKeyDown = (event: React.KeyboardEvent, index: number) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      if (!allMaintenanceFieldsHaveSameValue()) {
        setShowButtonsIndex(index);
      } else {
        setShowButtonsIndex(-1);
      }
    }
  };

  const handleMaintenanceWindowBlur = (
    event: React.FocusEvent<HTMLInputElement>,
    currentIndex: number,
  ) => {
    setTimeout(() => {
      const blurredFieldValue = event.target.value;
      const nextActiveElement = document.activeElement as HTMLInputElement;

      const isMovingToNextField = maintenanceWindowRefs.current.some(
        (ref, index) => ref === nextActiveElement && index !== currentIndex,
      );

      if (
        isMovingToNextField &&
        blurredFieldValue.trim() !== '' &&
        !allMaintenanceFieldsHaveSameValue()
      ) {
        setShowButtonsIndex(currentIndex);
      } else {
        setShowButtonsIndex(-1);
      }
    }, 100);
  };

  const applyToAll = (index: number) => {
    const fieldValue = watch(`locations.${index}.maintenance_window`);
    locationsFields.forEach((_, index) => {
      setValue(`locations.${index}.maintenance_window`, fieldValue);
      setValue(`locations.${index}.canTestingBeDoneAnytime`, false);
    });
    setShowButtonsIndex(-1);
  };

  const handlePaste = async (
    event: React.ClipboardEvent<HTMLInputElement>,
    pasteIndex: number,
  ) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text');
    const splitData = pastedData.split(/\r\n|\r|\n/);

    if (splitData.length > 100) {
      showToast.error({
        title: 'Cannot add more than 100 locations',
        message: 'Only the first 100 values have been pasted',
      });
      splitData.length = 100;
    }

    let currentIndex = pasteIndex;
    for (const data of splitData) {
      if (currentIndex > locationsFields.length) {
        append({ ...DEFAULT_LOCATION, childAccount: data });
      }
      if (data.length === 7) {
        setValue(`locations.${currentIndex}.childAccount`, `0${data}`);
      } else {
        setValue(`locations.${currentIndex}.childAccount`, data);
      }
      trigger(`locations.${pasteIndex}.childAccount`);
      currentIndex++;
    }
  };

  const areAllCheckboxesChecked =
    checkAll ||
    (watch('locations') ?? []).every(
      (location) => location.canTestingBeDoneAnytime,
    );

  const onSubmit = (data: BulkLocations) => {
    console.log({ data });
  };

  const onError = console.log;
  return {
    step,
    setStep,
    showButtonsIndex,
    setShowButtonsIndex,
    checkAll,
    setCheckAll,
    formMethods,
    locationsFields,
    append,
    handleCheckAllChange,
    handleNextClick,
    handleKeyDown,
    handleMaintenanceWindowBlur,
    applyToAll,
    handlePaste,
    areAllCheckboxesChecked,
    allMaintenanceFieldsHaveSameValue,
    onSubmit,
    onError,
    maintenanceWindowRefs,
  };
};
