import {
  ComponentProps,
  Fragment,
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import './TimePicker.css';
import { ArrowForward, Time } from 'react-ionicons';
import { clsx } from 'clsx';
import { isValid, parse } from 'date-fns';
import format from 'date-fns/format';
import { createPortal } from 'react-dom';
import {
  BaseListDropdown,
  ListDropdownOptionProps,
} from '../ListDropdown/ListDropdown';
import { ErrorSubtext } from '../ErrorSubtext/ErrorSubtext';

export interface TimePickerProps
  extends Omit<ComponentProps<'input'>, 'value' | 'onChange' | 'ref'> {
  onChange?: (date?: Date) => void;
  value?: Date;
  className?: string;
  error?: string;
  timeZoneAbbreviation?: string;
}

export const TimePicker = forwardRef<HTMLInputElement, TimePickerProps>(
  (
    {
      onChange,
      value,
      className,
      error,
      timeZoneAbbreviation,
      ...props
    }: TimePickerProps,
    ref,
  ) => {
    return (
      <div className={clsx('flex flex-col', className)}>
        <div
          className={clsx(
            'TimePicker',
            props.disabled && 'disabled',
            props.readOnly && 'readonly',
            error && 'error',
          )}
        >
          <TimePickerContent
            onChange={onChange}
            value={value}
            ref={ref}
            {...props}
          />
          {timeZoneAbbreviation && (
            <span className="text-content-base-subdued">
              {timeZoneAbbreviation}
            </span>
          )}
        </div>
        <ErrorSubtext error={error} />
      </div>
    );
  },
);

TimePicker.displayName = 'TimePicker';

export interface RangeTimePickerProps
  extends Omit<ComponentProps<'input'>, 'value' | 'onChange' | 'ref'> {
  onStartChange?: (date?: Date) => void;
  startValue?: Date;
  startInputName?: string;
  onEndChange?: (date?: Date) => void;
  endValue?: Date;
  endInputName?: string;
  className?: string;
  error?: string;
  timeZoneAbbreviation?: string;
}

export const RangeTimePicker = forwardRef<
  HTMLInputElement,
  RangeTimePickerProps
>(
  (
    {
      onStartChange,
      startValue,
      startInputName,
      onEndChange,
      endValue,
      endInputName,
      className,
      timeZoneAbbreviation,
      error,
      ...props
    }: RangeTimePickerProps,
    ref,
  ) => {
    return (
      <div className={clsx(className, 'flex flex-col')}>
        <div
          className={clsx(
            'TimePicker',
            'min-w-[280px]',
            props.disabled && 'disabled',
            props.readOnly && 'readonly',
            error && 'error',
          )}
        >
          <TimePickerContent
            onChange={onStartChange}
            value={startValue}
            disabled={props.disabled}
            readOnly={props.readOnly}
            name={startInputName}
            ref={ref}
          />
          <div className="text-content-base-subdued">
            <ArrowForward color="inherit" width="16px" height="16px" />
          </div>
          <TimePickerContent
            onChange={onEndChange}
            value={endValue}
            disabled={props.disabled}
            readOnly={props.readOnly}
            name={endInputName}
            ref={ref}
          />
          {timeZoneAbbreviation && (
            <span className="text-content-base-subdued">
              {timeZoneAbbreviation}
            </span>
          )}
        </div>
        <ErrorSubtext error={error} />
      </div>
    );
  },
);

RangeTimePicker.displayName = 'RangeTimePicker';

interface TimePickerContentProps extends TimePickerProps {}

interface TimePickerContentState {
  hour: string;
  minute: string;
  period: 'AM' | 'PM' | '';
}

type TimePickerPartAction =
  | { type: 'setHour'; hour: string }
  | { type: 'setMinute'; minute: string }
  | { type: 'setPeriod'; period: 'AM' | 'PM' }
  | { type: 'padHour' }
  | { type: 'padMinute' };

const timePickerReducer = (
  state: TimePickerContentState,
  action: TimePickerPartAction,
): TimePickerContentState => {
  switch (action.type) {
    case 'setHour': {
      return {
        ...state,
        hour: action.hour,
        minute: !state.minute ? '00' : state.minute,
        period: !state.period ? 'AM' : state.period,
      };
    }
    case 'setMinute': {
      return {
        ...state,
        minute: action.minute,
        period: !state.period ? 'AM' : state.period,
      };
    }
    case 'padHour': {
      return {
        ...state,
        hour: state.hour.padStart(2, '0'),
      };
    }
    case 'padMinute': {
      return {
        ...state,
        minute: state.minute.padStart(2, '0'),
      };
    }
    case 'setPeriod':
      return {
        ...state,
        period: action.period,
      };
  }
};

const TimePickerContent = forwardRef<HTMLInputElement, TimePickerProps>(
  (
    { onChange, value, name: _name, id: _id, ...props }: TimePickerContentProps,
    ref: Ref<Partial<HTMLInputElement>>,
  ) => {
    const hourPart = useRef<HTMLInputElement>(null);
    const minutePart = useRef<HTMLInputElement>(null);
    const periodPart = useRef<HTMLInputElement>(null);

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          hourPart.current?.focus();
        },
      }),
      [hourPart],
    );

    useEffect(() => {
      const selectOnClick = function (this: HTMLInputElement) {
        this.select();
      };
      const hourPartRef = hourPart.current;
      hourPartRef?.addEventListener('click', selectOnClick);
      const minutePartRef = minutePart.current;
      minutePartRef?.addEventListener('click', selectOnClick);
      const periodPartRef = periodPart.current;
      periodPartRef?.addEventListener('click', selectOnClick);
      return () => {
        hourPartRef?.removeEventListener('click', selectOnClick);
        minutePartRef?.removeEventListener('click', selectOnClick);
        periodPartRef?.removeEventListener('click', selectOnClick);
      };
    }, []);

    const [state, dispatch] = useReducer(timePickerReducer, {
      hour: value && isValid(value) ? format(value, 'hh') : '',
      minute: value && isValid(value) ? format(value, 'mm') : '',
      period:
        value && isValid(value) ? (format(value, 'a') as 'AM' | 'PM') : '',
    });

    useEffect(() => {
      if (!state.hour || !/\d{1,2}/.test(state.hour)) return;
      if (!state.minute || !/\d{1,2}/.test(state.minute)) return;
      if (!state.period || !/am|pm/i.test(state.period)) {
        periodPart.current?.setCustomValidity('Should be AM or PM');
        return;
      } else {
        periodPart.current?.setCustomValidity('');
      }
      const hour = parseInt(state.hour, 10);
      const minute = parseInt(state.minute, 10);

      if (hour < 1 || hour > 12) {
        hourPart.current?.setCustomValidity('Outside valid range 1-12');
        return;
      } else {
        hourPart.current?.setCustomValidity('');
      }

      if (minute < 0 || minute > 59) {
        minutePart.current?.setCustomValidity('Outside valid range 0-59');
        return;
      } else {
        minutePart.current?.setCustomValidity('');
      }

      onChange &&
        onChange(
          parse(`${hour}:${minute} ${state.period}`, 'hh:mm a', new Date()),
        );
      // onChange is not stable across renders which causes an infinite loop
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.hour, state.minute, state.period]);

    const timePickerRef = useRef(null);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const hourOptions: ListDropdownOptionProps[] = useMemo(
      () =>
        ['12', ...Array.from({ length: 11 }, (_, i) => `${i + 1}`)].map(
          (hour) => ({
            label: hour.padStart(2, '0'),
            isSelected:
              state.hour === hour || state.hour === hour.padStart(2, '0'),
            onClick: () =>
              dispatch({ type: 'setHour', hour: hour.padStart(2, '0') }),
          }),
        ),
      [state.hour],
    );

    const minuteOptions = useMemo(
      () =>
        [...Array(60).keys()]
          .map((minute) => minute.toString())
          .map((minute) => ({
            label: minute.padStart(2, '0'),
            isSelected:
              state.minute === minute ||
              state.minute === minute.padStart(2, '0'),
            onClick: () =>
              dispatch({ type: 'setMinute', minute: minute.padStart(2, '0') }),
          })),
      [state.minute],
    );

    const periodOptions = useMemo(
      () => [
        {
          label: 'AM',
          isSelected: state.period?.toUpperCase() === 'AM',
          onClick: () => dispatch({ type: 'setPeriod', period: 'AM' }),
        },
        {
          label: 'PM',
          isSelected: state.period?.toUpperCase() === 'PM',
          onClick: () => dispatch({ type: 'setPeriod', period: 'PM' }),
        },
      ],
      [state.period],
    );
    const listDropdownOptions = useMemo(
      () => [hourOptions, minuteOptions, periodOptions],
      [hourOptions, minuteOptions, periodOptions],
    );

    return (
      <Fragment>
        <div className={clsx('TimePartContainer')} ref={timePickerRef}>
          <input
            type="text"
            maxLength={2}
            pattern="\d{1,2}"
            placeholder="--"
            className="TimePartInput text-right"
            aria-label="timepicker hour"
            ref={hourPart}
            value={state.hour}
            onChange={(e) =>
              dispatch({ type: 'setHour', hour: e.target.value })
            }
            onBlur={() => dispatch({ type: 'padHour' })}
            {...props}
          />
          <span className="TimeSeparator self-center leading-[20px]">:</span>
          <input
            type="text"
            placeholder="--"
            className="TimePartInput"
            maxLength={2}
            pattern="\d{1,2}"
            ref={minutePart}
            aria-label="timepicker minute"
            value={state.minute}
            onChange={(e) =>
              dispatch({ type: 'setMinute', minute: e.target.value })
            }
            onBlur={() => dispatch({ type: 'padMinute' })}
            {...props}
          />
          <input
            type="text"
            placeholder="--"
            pattern="AM|PM|am|pm|Am|aM|Pm|pM"
            maxLength={2}
            className="TimePartInput pl-2"
            aria-label="timepicker period am pm"
            ref={periodPart}
            value={state.period}
            onChange={(e) =>
              dispatch({
                type: 'setPeriod',
                period: e.target.value as 'AM' | 'PM',
              })
            }
            {...props}
          />
          <button
            type="button"
            disabled={props.disabled}
            onClick={() => setIsMenuVisible((v) => !v)}
            className="p-3 px-[2px]"
            title="toggle time picker"
            aria-label="toggle time picker"
          >
            <Time color="inherit" width="16px" height="16px" />
          </button>
        </div>
        {createPortal(
          <BaseListDropdown
            triggerRef={timePickerRef}
            isOpen={isMenuVisible}
            toggle={() => setIsMenuVisible(false)}
            options={listDropdownOptions}
            closeOnClickOutside
            popperOptions={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [-16, 14],
                  },
                },
              ],
            }}
          />,
          document.body,
        )}
      </Fragment>
    );
  },
);

TimePickerContent.displayName = 'TimePickerContent';
