import Logo from 'components/Logo';
import { useNavigate } from 'react-router';
import { ReactComponent as PortalMarketPlace } from '../../assets/images/portal_marketplace.svg';
import { ReactComponent as PortalDashboard } from '../../assets/images/portal_dashboard.svg';
import { ReactComponent as HoverPortalMarketPlace } from '../../assets/images/hover_portal_marketplace.svg';
import { ReactComponent as HoverPortalDashboard } from '../../assets/images/hover_portal_dashboard.svg';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ArrowBack } from 'react-ionicons';
import Card from './Card';

const Mobility = () => {
  const navigate = useNavigate();

  return (
    <div
      className={
        'home-bg flex min-h-screen flex-col items-center justify-center gap-4 bg-background-base-surface-1 bg-cover !bg-top'
      }
    >
      <div className="flex min-h-full w-full flex-col justify-center gap-8 rounded-md bg-background-base-surface-2 px-6 py-12 shadow-[0px_8px_12px_6px_#00000026] md:min-h-0 md:w-[625px] md:px-12">
        <div className="flex flex-col items-center gap-6">
          <Logo className="!h-auto !w-[126px]" />
          <h1 className="font-semibold text-content-base-default">
            Select a Portal
          </h1>
        </div>
        <div className="flex justify-between gap-6">
          <Card
            Vector={PortalMarketPlace}
            HoverVector={HoverPortalMarketPlace}
            title="Mobility Marketplace"
            subtitle="Order new wireless devices and accessories"
            onLaunch={() => {
              window.open(
                'https://marketplace.webmobility.granitenet.net',
                '_blank',
              );
            }}
          />
          <Card
            Vector={PortalDashboard}
            HoverVector={HoverPortalDashboard}
            title="Mobility Dashboard"
            subtitle="Manage your existing Granite-provided wireless inventory"
            onLaunch={() => {
              window.open('https://gemm.granitenet.com/dashboard', '_blank');
            }}
          />
        </div>
      </div>
      <GraniteButton
        size="medium"
        variant="ghost"
        className="mr-[520px]"
        onClick={() => navigate('/')}
      >
        <ArrowBack width="20px" height="20px" color="inherit" />
        Back
      </GraniteButton>
    </div>
  );
};

export default Mobility;
