import {
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getSeconds,
  getYear,
} from 'date-fns';

export const mergeDateTimeParts = (datePart: Date, timePart: Date) => {
  return new Date(
    getYear(datePart),
    getMonth(datePart),
    getDate(datePart),
    getHours(timePart),
    getMinutes(timePart),
    getSeconds(timePart),
  );
};
