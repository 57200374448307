import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import IconMapper from 'components/IconMapper/IconMapper';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  unit?: string;
  icon?: string;
  info?: string;
}

const InputGranite = forwardRef<HTMLInputElement, InputProps>(
  ({ label, error, unit, placeholder, icon, info, ...props }, ref) => {
    return (
      <div className="flex w-full flex-col">
        {label && (
          <label className="mb-2 text-base font-bold text-content-base-subdued">
            {label}
          </label>
        )}
        <span className="relative flex">
          {icon && (
            <div className="absolute left-[15px] top-4 text-content-base-subdued">
              <IconMapper
                name={icon}
                size={16}
                color="var(--content-base-subdued)"
              />
            </div>
          )}
          <input
            {...props}
            ref={ref}
            placeholder={placeholder}
            className={`box-border h-12 w-full border border-solid border-content-base-subdued bg-background-base-surface-1 text-base text-white ${
              error ? 'border-text-input-content-error' : ''
            } rounded p-0 px-[15px] pl-${
              icon ? '11.5' : '2.5'
            } hover:border-button-background-primary-default focus:border-2 focus:border-button-background-primary-default focus:outline-none disabled:border-content-base-subdued`}
          />
          {unit && (
            <span className="absolute right-2.5 top-1/2 -translate-y-1/2 transform">
              {unit}
            </span>
          )}
        </span>
        {info && (
          <p className="mt-2 text-xs text-content-base-subdued">{info}</p>
        )}
        <div className="mt-2 text-xs text-input-content-error">{error}</div>
      </div>
    );
  },
);

InputGranite.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  unit: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  info: PropTypes.string,
};

InputGranite.displayName = 'InputGranite';

export default InputGranite;
