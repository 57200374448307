import React from 'react';

interface UserAvatarProps {
  name: string;
  isSelected?: boolean;
  width?: string;
  height?: string;
  fontSize?: string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  name,
  isSelected,
  width = 'w-10',
  height = 'h-10',
  fontSize = 'text-base',
}) => {
  // Extract initials from the name (assuming name consists of first and last name)
  const initials = name
    .split(' ')
    .map((part) => part.charAt(0).toUpperCase())
    .slice(0, 2)
    .join('');

  return (
    <div
      className={`${width} ${height} ${fontSize} flex items-center justify-center rounded-full border border-button-content-secondary-default bg-transparent font-bold text-button-content-secondary-default ${
        isSelected
          ? 'border-none !bg-background-base-surface-1 !text-content-accent-default'
          : ''
      } active:button-content-secondary-pressed cursor-pointer group-hover:border-button-stroke-secondary-hover group-hover:bg-button-background-secondary-hover group-hover:text-button-content-secondary-hover hover:border-button-stroke-secondary-hover hover:bg-button-background-secondary-hover hover:text-button-content-secondary-hover active:border-button-stroke-secondary-pressed active:bg-button-background-secondary-pressed`}
    >
      {initials}
    </div>
  );
};

export default UserAvatar;
