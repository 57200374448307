import { OptionLabelValueSchema } from 'screens/LetUsHelp/utils/schemas';
import { z } from 'zod';
import { v4 as uuid } from 'uuid';

const productTypes = ['BroadbandCircuit', 'DIA'] as const;

export const ProductTypesEnum = z.enum(productTypes);
export type ProductTypes = z.infer<typeof ProductTypesEnum>;

const BaseBroadbandCircuitFormSchema = z.object({
  id: z.string().default(uuid),
  type: z.literal('broadband'),
  accessType: z.enum(['Cable', 'Fiber']),
  minimumDownloadSpeed: z
    .string()
    .nonempty('Minimum download speed is required')
    .regex(/^[0-9]+$/, 'Enter valid numbers only')
    .min(1)
    .refine((value) => Number(value) >= 1, {
      message: 'Minimum download speed must be greater than 0',
    }),
  minimumUploadSpeed: z
    .string()
    .regex(/^[0-9]+$/, 'Enter valid numbers only')
    .min(1)
    .refine((value) => Number(value) >= 1, {
      message: 'Minimum upload speed must be greater than 0',
    })
    .or(z.literal(''))
    .optional(),
  ipType: z.enum(['static', 'dynamic']),
  termLength: OptionLabelValueSchema,
  name: z.string().optional(),
});

export const BroadbandCircuitFormUnionSchema = z.discriminatedUnion('ipType', [
  z.object({
    ipType: z.literal('dynamic'),
    ipBlocks: z.undefined(),
  }),
  z.object({
    ipType: z.literal('static'),
    ipBlocks: OptionLabelValueSchema,
  }),
]);

export const BroadbandCircuitFormSchema = z.intersection(
  BaseBroadbandCircuitFormSchema,
  BroadbandCircuitFormUnionSchema,
);

export type BroadbandCircuitForm = z.infer<typeof BroadbandCircuitFormSchema>;

export const CheckAvailabilitySchema = z.object({
  quoteName: z.string().nonempty('Required'),
});
export type CheckAvailabilityForm = z.infer<typeof CheckAvailabilitySchema>;
