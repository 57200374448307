import { makeAutoObservable } from 'mobx';
import { QEBundle, QELocation, QEProduct, QEQuote } from '../schemas/QE';
import { QuoteAPIProduct } from '../../../api/accessexpress/schema';
import { v4 as uuid } from 'uuid';
import { optionsToLabelValuePairs } from '../../LetUsHelp/utils/optionsToLabelValuePairs';
import { uniqueItems } from '../../../shared/util/uniqueItems';

export class ProductBundle {
  public readonly id = uuid();
  private readonly _services: QEProduct[] = [];

  constructor(private readonly _bundle: QEBundle) {
    this._services.push(...this._bundle.bundle);

    makeAutoObservable(this);
  }

  get modem() {
    return this._services.find((s) => s.product_type === 'DEVICE');
  }

  get installation() {
    return this._services.find((s) => s.product_type === 'INSTALLATION');
  }

  get ipBlock() {
    return this._services.find((s) => s.product_type === 'IP BLOCK');
  }

  get productVariants() {
    return this._services.filter((s) => s.product_type === 'MONTHLY SERVICE');
  }

  get bundleType() {
    if (this._bundle.circuit_type === 'FIBER DRY LOOP') {
      return 'Fiber';
    } else {
      return 'Cable';
    }
  }

  get ipBlockDetail() {
    return this._services.find((s) => s.product_type === 'IP BLOCK');
  }

  get ipType() {
    if (this.ipBlockDetail) {
      return 'static';
    } else {
      return 'dynamic';
    }
  }

  get vendor() {
    return this._bundle.vendor;
  }
}

export class SelectableProduct {
  private _isEnabled = true;

  constructor(
    private readonly _location: LocationProducts,
    private readonly _availableBundles: ProductBundle[],
  ) {
    makeAutoObservable(this);
  }

  get nrc() {
    return this.selectedProduct?.installation?.rate ?? 0;
  }

  get mrc() {
    return (
      (this.selectedProduct?.modem?.rate ?? 0) +
      (this.selectedProduct?.ipBlock?.rate ?? 0) +
      (this.selectedService?.rate ?? 0)
    );
  }

  get isEnabled() {
    return this._isEnabled;
  }

  toggleStatus() {
    this._isEnabled = !this._isEnabled;
  }

  setStatus(status: boolean) {
    this._isEnabled = status;
  }

  private selectedTypeOfConnection: 'Fiber' | 'Cable' | undefined;

  setTypeOfConection(circuitType: 'Fiber' | 'Cable' | undefined) {
    this.selectedTypeOfConnection = circuitType;

    // TODO: Try to look for a product with the same characteristics before clearing
    this.setCarrier();
    this.setTermLength();
    this.setDownloadSpeed();
    this.setUploadSpeed();
  }

  get typeOfConnection() {
    return this.selectedTypeOfConnection;
  }

  private selectedCarrier: string | undefined;

  get carrierOptions() {
    if (!this.selectedTypeOfConnection) {
      return [];
    }
    return optionsToLabelValuePairs(
      ...uniqueItems(
        this._availableBundles
          .filter((ab) => ab.bundleType === this.selectedTypeOfConnection)
          .map((ab) => ab.vendor),
      ),
    );
  }

  setCarrier(carrier?: string) {
    if (!carrier && this.carrierOptions.length === 1) {
      this.selectedCarrier = this.carrierOptions[0].value;
    } else {
      this.selectedCarrier = carrier;
    }

    this.setTermLength();
    this.setDownloadSpeed();
    this.setUploadSpeed();
  }

  get carrierValue() {
    return this.carrierOptions.find((co) => co.value === this.selectedCarrier);
  }

  get isCarrierDisabled() {
    return this.carrierOptions.length <= 1;
  }

  private selectedTermLength: string | undefined;

  get termLengthOptions() {
    if (!this.selectedTypeOfConnection || !this.selectedCarrier) {
      return [];
    }

    return optionsToLabelValuePairs(
      ...uniqueItems(
        this._availableBundles
          .filter((ab) => ab.bundleType === this.selectedTypeOfConnection)
          .filter((ab) => ab.vendor === this.selectedCarrier)
          .flatMap((ab) => ab.productVariants.map((pv) => pv.term)),
      ),
    );
  }

  setTermLength(term?: string) {
    if (!term && this.termLengthOptions.length === 1) {
      this.selectedTermLength = this.termLengthOptions[0].value;
    } else {
      this.selectedTermLength = term;
    }
    this.setDownloadSpeed();
    this.setUploadSpeed();
  }

  get termLengthValue() {
    return this.termLengthOptions.find(
      (tlo) => tlo.value === this.selectedTermLength,
    );
  }

  get isTermLengthDisabled() {
    return this.termLengthOptions.length <= 1;
  }

  private selectedDownloadSpeed: number | undefined;

  get downloadSpeedOptions() {
    if (
      !this.selectedTypeOfConnection ||
      !this.selectedCarrier ||
      !this.selectedTermLength
    ) {
      return [];
    }

    return uniqueItems(
      this._availableBundles
        .filter((ab) => ab.vendor === this.selectedCarrier)
        .filter((ab) => ab.bundleType === this.selectedTypeOfConnection)
        .flatMap((ab) => ab.productVariants.map((pv) => pv.download_speed))
        .map((n) => n),
    ).map((s) => ({ label: `${s} Mbps`, value: s }));
  }

  setDownloadSpeed(downloadSpeed?: number) {
    if (!downloadSpeed && this.downloadSpeedOptions.length === 1) {
      this.selectedDownloadSpeed = this.downloadSpeedOptions[0].value;
    } else {
      this.selectedDownloadSpeed = downloadSpeed;
    }
    this.setUploadSpeed();
  }

  get isDownloadSpeedDisabled() {
    return this.downloadSpeedOptions.length <= 1;
  }

  get selectedDownloadSpeedValue() {
    return this.downloadSpeedOptions.find(
      (dso) => dso.value === this.selectedDownloadSpeed,
    );
  }

  private selectedUploadSpeed: number | undefined;

  get uploadSpeedOptions() {
    if (
      !this.selectedTypeOfConnection ||
      !this.selectedCarrier ||
      !this.selectedTermLength ||
      !this.selectedDownloadSpeed
    ) {
      return [];
    }

    return uniqueItems(
      this._availableBundles
        .filter((ab) => ab.vendor === this.selectedCarrier)
        .filter((ab) => ab.bundleType === this.selectedTypeOfConnection)
        .flatMap((ab) =>
          ab.productVariants
            .filter((pv) => pv.download_speed === this.selectedDownloadSpeed)
            .map((pv) => pv.upload_speed),
        )
        .map((n) => n),
    ).map((s) => ({ label: `${s} Mpbs`, value: s }));
  }

  get selectedUploadSpeedValue() {
    return this.uploadSpeedOptions.find(
      (dso) => dso.value === this.selectedUploadSpeed,
    );
  }

  setUploadSpeed(uploadSpeed?: number) {
    if (!uploadSpeed && this.uploadSpeedOptions.length === 1) {
      this.selectedUploadSpeed = this.uploadSpeedOptions[0].value;
    } else {
      this.selectedUploadSpeed = uploadSpeed;
    }
  }

  get isUploadSpeedDisabled() {
    return this.uploadSpeedOptions.length <= 1;
  }

  get selectedService() {
    return this.selectedProduct?.productVariants.find(
      (s) =>
        s.term === this.selectedTermLength &&
        s.download_speed === this.selectedDownloadSpeed &&
        s.upload_speed === this.selectedUploadSpeed,
    );
  }

  get selectedProduct() {
    // TODO: This search could possibly return multiple results, it might be necessary to discriminate further
    return this._availableBundles.find(
      (ab) =>
        ab.bundleType === this.selectedTypeOfConnection &&
        ab.vendor === this.selectedCarrier,
    );
  }

  get isDisplayable() {
    return this.isEnabled && this.selectedService;
  }
}

export class LocationProducts {
  public readonly id = uuid();
  public readonly primaryProduct;
  public readonly secondaryProduct;

  private readonly _bundles: ProductBundle[];
  private readonly _requirements: QuoteAPIProduct[];

  constructor(private readonly _location: QELocation) {
    this._requirements = Object.values(_location.requirements);
    this._bundles = _location.products.map(
      (bundle) => new ProductBundle(bundle),
    );
    this.primaryProduct = new SelectableProduct(this, this._bundles);
    this.secondaryProduct = new SelectableProduct(this, this._bundles);

    makeAutoObservable(this);
  }

  get totalNRC() {
    return this.primaryProduct.nrc + this.secondaryProduct.nrc;
  }

  get totalMRC() {
    return this.primaryProduct.mrc + this.secondaryProduct.mrc;
  }

  get selectedProducts(): SelectableProduct[] {
    return [
      this.primaryProduct.isDisplayable && this.primaryProduct,
      this.secondaryProduct.isDisplayable && this.secondaryProduct,
    ].filter(Boolean) as SelectableProduct[];
  }

  get requirements() {
    return this._requirements;
  }

  get fullAddress() {
    return [
      this._location.address,
      this._location.address_2,
      this._location.city,
      this._location.state,
      this._location.zipcode,
    ]
      .map((s) => s.trim())
      .filter(Boolean)
      .join(', ');
  }

  get isPartialProductSelection() {
    return (
      (this.primaryProduct.isEnabled && !this.secondaryProduct.isEnabled) ||
      (!this.primaryProduct.isEnabled && this.secondaryProduct.isEnabled)
    );
  }

  get isAllProductsEnabled() {
    return this.primaryProduct.isEnabled && this.secondaryProduct.isEnabled;
  }

  get isAllProductsDisabled() {
    return !this.primaryProduct.isEnabled && !this.secondaryProduct.isEnabled;
  }

  get availableProducts() {
    return Array.from(new Set(this._bundles.map((b) => b.bundleType)));
  }

  get isError() {
    // If both selected products have the same product_type, that's an error
    if (
      this.primaryProduct.selectedService &&
      this.primaryProduct.isEnabled &&
      this.secondaryProduct.selectedService &&
      this.secondaryProduct.isEnabled
    ) {
      return (
        this.primaryProduct.selectedProduct?.bundleType ===
        this.secondaryProduct.selectedProduct?.bundleType
      );
    }
  }

  toggleAllProductsStatus() {
    if (this.isPartialProductSelection) {
      this.primaryProduct.setStatus(true);
      this.secondaryProduct.setStatus(true);
    } else {
      this.primaryProduct.toggleStatus();
      this.secondaryProduct.toggleStatus();
    }
  }

  get enabledProductCount() {
    return (
      (this.primaryProduct.isEnabled ? 1 : 0) +
      (this.secondaryProduct.isEnabled ? 1 : 0)
    );
  }
}

export class ProductSelectionStore {
  private readonly _locations;
  private readonly _requirements;

  constructor(private readonly _quote: QEQuote) {
    this._locations = _quote.locations.map((l) => new LocationProducts(l));
    this._requirements = this._locations.flatMap((loc) => loc.requirements);
    makeAutoObservable(this);
  }

  get quoteExpiration() {
    return this._quote.quote_expiration;
  }

  // Mass Apply grouping happens here
  // Group locations by product requirements
  // Given requirements A, B
  // locations: 1, 2, 3
  // relations (1, A), (2, A), (3, A, B)
  // Generate the groupings:
  // (A 1, 2, 3), (B, 3)
  get locationsByRequirement(): [QuoteAPIProduct, LocationProducts[]][] {
    return Array.from(
      this._locations
        .flatMap((location) =>
          location.requirements.map(
            (req): [QuoteAPIProduct, LocationProducts] => [req, location],
          ),
        )
        .reduce((acc, [requirement, location]) => {
          const requirementGroup = acc.get(requirement) ?? [];
          requirementGroup.push(location);
          acc.set(requirement, requirementGroup);
          return acc;
        }, new Map<QuoteAPIProduct, LocationProducts[]>()),
    );
  }

  get locations() {
    return this._locations;
  }

  get totalMRC() {
    return (
      this._locations.reduce((acc, val) => {
        return acc + val.totalMRC;
      }, 0) ?? 0
    );
  }

  get totalNRC() {
    return (
      this._locations.reduce((acc, val) => {
        return acc + val.totalNRC;
      }, 0) ?? 0
    );
  }
}
