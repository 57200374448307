import format from 'date-fns/format';
import { ProductSelectionStore } from '../Wizard/ProductSelectionStore';
import { observer } from 'mobx-react-lite';
import { AlertCircle } from 'react-ionicons';

export interface QuoteExpirationBannerProps {
  productSelectionStore: ProductSelectionStore;
}

export const QuoteExpirationBanner = observer(
  ({ productSelectionStore }: QuoteExpirationBannerProps) => {
    return (
      <div className="flex w-full items-center gap-4 rounded border border-status-info-default bg-background-base-surface-1 p-4">
        <AlertCircle width="22px" height="22px" color="#9796F3" />
        <span className="text-base text-content-base-default">
          You have until{' '}
          {format(productSelectionStore.quoteExpiration, "p 'on' MMM d, yyyy")}{' '}
          to request a quote with these specifications.
        </span>
      </div>
    );
  },
);
