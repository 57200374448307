import { FC } from 'react';
import { GraniteButton } from 'components/V2/Button/GraniteButton';

interface EditButtonProps {
  totalSelected: number;
  onClick: () => void;
}

const EditButton: FC<EditButtonProps> = ({ totalSelected, onClick }) => {
  return (
    <div className="mt-6">
      <GraniteButton onClick={onClick} type="button" disabled={!totalSelected}>
        Edit alerts for selected devices ({totalSelected})
      </GraniteButton>
    </div>
  );
};

export default EditButton;
