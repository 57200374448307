import {
  useState,
  useCallback,
  useRef,
  Fragment,
  forwardRef,
  ReactElement,
  useMemo,
  useEffect,
  useImperativeHandle,
} from 'react';
import { useMutation, useQuery } from 'react-query';
import { usePopper } from 'react-popper';
import { useModal } from 'hooks/useModal';
import { getQuoteLocations } from 'api/accessexpress/api';
import { useDebouncedState } from 'shared/hooks/useDebouncedState';
import { ErrorSubtext } from 'components/ErrorSubtext/ErrorSubtext';
import { VerifyAddressDialog } from './VerifyAddressModal';
import { Search, CloseCircle } from 'react-ionicons';
import { clsx } from 'clsx';
import escapeRegExp from 'lodash.escaperegexp';
import { NUMBERS_LETTERS_HYPHENS_SPACES_COMMAS_PERIODS_REGEXP } from 'shared/constants/validation-regex-constants';
import { verifyAddress } from 'api/addresssearch/api';
import { QuoteLocation, VerifyAddressResponse } from 'api/accessexpress/schema';

export interface SearchAddressBarProps {
  className?: string;
  error?: string;
  onAddressSelected: (address?: QuoteLocation) => void;
  value?: QuoteLocation & { street?: string | null | undefined };
  isDisabled?: boolean;
  name?: string;
  onFocus?: () => void;
  onBlur?: () => void;
}

export interface SearchAddressBarRef {
  focusInput: () => void;
}

export const SearchAddressBar = forwardRef<
  SearchAddressBarRef,
  SearchAddressBarProps
>(
  (
    {
      className,
      onAddressSelected,
      error,
      value,
      isDisabled,
      name,
      onFocus,
      onBlur,
    },
    ref,
  ) => {
    const anchorEltRef = useRef<HTMLDivElement>(null);
    const popperElementRef = useRef<HTMLDivElement>(null);
    const inputEltRef = useRef<HTMLInputElement>(null);
    const [searchInput, setSearchInput] = useState('');
    const [isMenuVisible, setIsMenuVisible] = useState(true);
    const { value: debouncedIsMenuVisible } = useDebouncedState(isMenuVisible);
    const { value: debouncedSearch, flush } = useDebouncedState(searchInput);
    const [invalidInputError, setInvalidInputError] = useState('');
    const [step, setStep] = useState(1);

    const [selectedAddress, setSelectedAddress] = useState<
      (QuoteLocation & { street?: string | null | undefined }) | undefined
    >(undefined);

    const [verifiedAddress, setVerifiedAddress] = useState<
      (QuoteLocation & { street?: string | null | undefined }) | undefined
    >(undefined);
    const isFirstRender = useRef<boolean>(true);

    useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        inputRef?.current?.focus();
      }
    }, [isFirstRender]);

    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      focusInput: () => {
        inputRef.current?.focus();
      },
    }));

    const { openWithProps, ...modalProps } = useModal<QuoteLocation>();

    const onSearchResultRowEscape = useCallback(() => {
      if (inputEltRef.current) {
        inputEltRef.current.focus();
      }
    }, []);

    const onAddressClear = useCallback(() => {
      onAddressSelected(undefined);
      setIsMenuVisible(false);
    }, [onAddressSelected]);

    const { data: { data: searchResults, data_source } = {} } = useQuery(
      ['access-search-address-bar', debouncedSearch],
      () => getQuoteLocations(debouncedSearch),
      {
        enabled:
          debouncedSearch.trim().length > 0 &&
          NUMBERS_LETTERS_HYPHENS_SPACES_COMMAS_PERIODS_REGEXP.test(
            debouncedSearch.trim(),
          ),
        retry: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    );

    const verifyAddressMutation = useMutation(
      (address: QuoteLocation & { street?: string | null | undefined }) =>
        verifyAddress({
          street: address.address_line_1!,
          city: address.city,
          state: address.state,
          zip: address.zip,
        }),
      {
        onSuccess: (
          data: VerifyAddressResponse,
          variables: QuoteLocation & { street?: string | null | undefined },
        ) => {
          const status = data.md_status;

          if (status === 'success') {
            setTimeout(() => {
              onAddressSelected(data);
            }, 500);
          } else if (status === 'error') {
            const responseAddress = `${data?.street}, ${data?.city}, ${data?.state}, ${data?.zip}`;
            const selectedAddressItem = `${variables?.address_line_1}, ${variables?.city}, ${variables?.state}, ${variables?.zip}`;

            const isDifferentAddress = responseAddress !== selectedAddressItem;
            // first 5 digits of the zip code are a 1:1 match
            const isSameZip =
              data.zip.slice(0, 4) === variables?.zip.slice(0, 4);

            if (isDifferentAddress && isSameZip) {
              setStep(1);
              setVerifiedAddress(data);
              openWithProps(data);
            } else {
              openWithProps(data);
              setStep(2);
            }
          } else if (status === 'corrected') {
            setStep(1);
            setVerifiedAddress(data);
            openWithProps(data);
          }
        },
      },
    );

    const onAddressChangeInternal = useCallback(
      (address?: QuoteLocation & { street?: string | null | undefined }) => {
        if (data_source === 'CW') {
          setSelectedAddress(address);
          verifyAddressMutation.mutate(address!);
        } else {
          setTimeout(() => {
            onAddressSelected(address);
            setSearchInput('');
            flush();
          }, 500);
        }
        setIsMenuVisible(false);
        onBlur?.();
      },
      [data_source, onBlur, verifyAddressMutation, onAddressSelected, flush],
    );

    const { styles, attributes } = usePopper(
      anchorEltRef.current,
      popperElementRef.current,
      { placement: 'bottom' },
    );

    return (
      <Fragment>
        <div className={clsx('group', className)}>
          <div
            className={clsx(
              'search-bar-wrapper',
              isDisabled && '!bg-input-background-disabled',
              !searchResults && 'rounded-b',
              searchResults !== undefined &&
                'focus-within:border-b-input-stroke-unfilled',
              (error || invalidInputError) && 'error',
            )}
            ref={anchorEltRef}
            role="listbox"
            aria-labelledby="search address bar"
          >
            <Search color="inherit" width="16px" height="16px" />
            {value && (
              <div
                className={`flex items-center gap-1 rounded-[32px]
                  border border-stroke-base-subdued bg-background-base-surface-3
                 fill-content-base-subdued px-3 font-light`}
              >
                {Object.keys(value).length > 0
                  ? `${value.address_line_1 ?? value.street}, ${value.city}, ${
                      value.state
                    }, ${value.zip}`
                  : ''}
                <button
                  className="cursor-pointer fill-content-base-subdued"
                  title="remove address"
                  type="button"
                  onClick={onAddressClear}
                  disabled={isDisabled}
                >
                  <CloseCircle color="inherit" width="16px" height="16px" />
                </button>
              </div>
            )}
            {!value && (
              <input
                type="text"
                placeholder="123 Main Street, Bridgewater, MA 02324 "
                name={name}
                aria-label={name}
                value={searchInput}
                ref={inputRef}
                disabled={isDisabled}
                onChange={(e) => {
                  setInvalidInputError('');
                  const { value } = e.target;
                  const isValid =
                    NUMBERS_LETTERS_HYPHENS_SPACES_COMMAS_PERIODS_REGEXP.test(
                      value,
                    );

                  setSearchInput(value);
                  if (value && !isValid) {
                    setInvalidInputError(
                      'Address can only include letters, numbers, hyphens, commas, periods and spaces.',
                    );
                  }
                  onFocus?.();
                }}
                onFocus={() => {
                  setIsMenuVisible(true);
                  onFocus?.();
                }}
                onBlur={() => {
                  onBlur?.();
                  setIsMenuVisible(false);
                }}
                className="disabled:cursor-not-allowed disabled:bg-input-background-disabled placeholder:disabled:bg-input-background-disabled"
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              />
            )}
          </div>
          <div
            className={clsx(
              debouncedIsMenuVisible &&
                searchResults !== undefined &&
                'search-result-container',
            )}
            onFocus={() => {
              setIsMenuVisible(true);
              onFocus?.();
            }}
            onBlur={() => {
              onBlur?.();
              setIsMenuVisible(false);
            }}
            ref={popperElementRef}
            style={{
              width: (anchorEltRef.current?.clientWidth ?? -2) + 2,
              ...styles.popper,
            }}
            {...attributes.popper}
          >
            {searchResults !== undefined && debouncedIsMenuVisible && (
              <Fragment>
                {searchResults?.map(
                  //@ts-expect-error test
                  (address: {
                    name: string;
                    address_line_1: string;
                    city: string;
                    state: string;
                    zip: string;
                    id: number;
                    address_line_2?: string | null | undefined;
                    parent_macnum?: string | null | undefined;
                    parent_name?: string | null | undefined;
                    street?: string | null | undefined;
                  }) => (
                    <SearchResultRow
                      key={address.id || address.name}
                      searchTerm={searchInput}
                      address={{
                        ...address,
                        address_line_1:
                          address.address_line_1 ?? address.street,
                      }}
                      onClick={onAddressChangeInternal}
                      onEscapeRequest={onSearchResultRowEscape}
                    />
                  ),
                )}
                {searchResults?.length === 0 && (
                  <div className="flex flex-col gap-2 rounded-b bg-input-background-unfilled px-4 py-3">
                    <p className="font-light text-content-base-subdued">
                      No results
                    </p>
                  </div>
                )}
              </Fragment>
            )}
          </div>
          <ErrorSubtext error={invalidInputError || error} />
        </div>
        <VerifyAddressDialog
          {...modalProps}
          address={selectedAddress}
          onAddressSelected={(address) => {
            onAddressSelected(address);
            setSelectedAddress(undefined);
          }}
          verifiedAddress={verifiedAddress}
          setStep={setStep}
          step={step}
        />
      </Fragment>
    );
  },
);

SearchAddressBar.displayName = 'SearchAddressBar';

interface SearchResultRowProps {
  searchTerm: string;
  address: QuoteLocation & { street?: string | null | undefined };
  onClick: (address: QuoteLocation) => void;
  onEscapeRequest?: () => void;
}

export const highlightMatch = (
  address: string,
  searchTerm: string,
): ReactElement => {
  const parts = address
    .split(new RegExp(`(${escapeRegExp(searchTerm)})`, 'ig'))
    .map((part, i) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <mark role="mark" key={i}>
          {part}
        </mark>
      ) : (
        part
      ),
    );

  return <Fragment>{parts}</Fragment>;
};

export const SearchResultRow = ({
  address,
  searchTerm,
  onClick,
  onEscapeRequest,
}: SearchResultRowProps) => {
  const fmtAddress = `${address.address_line_1}, ${address.city}, ${address.state}, ${address.zip}`;
  const highlightedResults = useMemo(
    () => highlightMatch(fmtAddress, searchTerm),
    [fmtAddress, searchTerm],
  );

  return (
    <div
      className="search-result-row"
      onClick={() => onClick(address)}
      role="option"
      tabIndex={0}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          onClick(address);
          return;
        }
        if (e.key === 'Escape') {
          onEscapeRequest && onEscapeRequest();
        }
      }}
    >
      <div className=" [&>mark]:bg-transparent [&>mark]:text-content-accent-default">
        {highlightedResults}
      </div>
      <div className="text-sm font-light text-input-content-unfilled">
        {[address.parent_macnum, address.parent_name, address.name]
          .filter(Boolean)
          .join(', ')}
      </div>
    </div>
  );
};
