import { z } from 'zod';

export const FEEDBACK_TYPES = z.enum([
  'Report a bug',
  'Granite360 technical support',
]);

export const ISSUE_TYPES = z.enum([
  'My account',
  'User management',
  'TechExpress ticketing',
  'NOCExpress ticketing',
]);

export const FeedbackFormSchema = z
  .object({
    feedback_type: z.object({
      label: FEEDBACK_TYPES,
      value: FEEDBACK_TYPES,
    }),
    issue_type: z
      .object({
        label: ISSUE_TYPES,
        value: ISSUE_TYPES,
      })
      .optional(),
    description: z.string().nonempty('Required'),
  })
  .refine(
    (data) => {
      if (
        data.feedback_type.value === 'Granite360 technical support' &&
        !data.issue_type
      ) {
        return false;
      }
      return true;
    },
    {
      message: 'Required',
      path: ['issue_type'],
    },
  );

export type FeedbackForm = z.infer<typeof FeedbackFormSchema>;
