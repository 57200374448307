import Logo from 'components/Logo';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { FileUpload } from 'components/V2/FileUpload/FileUpload';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import React, { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { UploadQRCodeSchema, UploadQRCodeType } from '../schemas/UploadQRCode';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { uploadFormalQuote } from 'api/accessexpress/api';
import showToast from 'components/Toast/Toast';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import DatePicker from 'components/DatePicker';
import { addDays, format, startOfDay } from 'date-fns';
import { CloseCircle } from 'react-ionicons';
import clsx from 'clsx';
import Loader from 'components/Loader';

const UploadQRCode = () => {
  const location = useLocation();
  const { qe_quote_request_id, formal_quote_filename, expiration_date } =
    location.state || {};
  const [fileName, setFileName] = useState(formal_quote_filename ?? null);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm<UploadQRCodeType>({
    resolver: zodResolver(UploadQRCodeSchema),
    defaultValues: {
      qe_quote_request_id: qe_quote_request_id || '',
      expiration_date: expiration_date,
      formal_quote_filename: fileName,
    },
  });
  const quoteId = watch(['qe_quote_request_id']);
  const navigate = useNavigate();
  const onError = (err: unknown) => {
    console.log(err);
  };

  const uploadQuoteMutation = useMutation(
    (data: FormData) => uploadFormalQuote(data),
    {
      onSuccess: async () => {
        showToast.confirmation({
          title: 'Quote uploaded!',
          message: `QR-${quoteId}`,
        });
        navigate('/access-express/index');
      },
    },
  );

  const onSubmit = (data: UploadQRCodeType) => {
    if (uploadQuoteMutation.isLoading) return;
    const formData = new FormData();
    formData.append('qe_quote_request_id', data.qe_quote_request_id.trim());
    const expirationDateString = format(data.expiration_date, 'yyyy-MM-dd');
    formData.append('expiration_date', `${expirationDateString}T23:59:59Z`);
    if (data.formal_quote_filename) {
      formData.append('formal_quote_filename', data.formal_quote_filename);
    }
    if (data.file && data.file.length > 0) {
      formData.append('file', data.file[0]);
    }
    uploadQuoteMutation.mutate(formData);
  };

  return (
    <div className="home-bg flex min-h-screen w-full flex-col items-center justify-center gap-4 bg-background-base-surface-1 bg-cover !bg-top">
      <div className="flex min-h-full w-full flex-col justify-center gap-8 rounded-md bg-background-base-surface-2 px-6 py-12 shadow-[0px_8px_12px_6px_#00000026] md:min-h-0 md:w-[625px] md:px-12">
        <div className="flex flex-col items-center gap-6">
          <Logo className="!h-auto !w-[126px]" />
          <h1 className="font-semibold text-content-base-default">
            Select a Portal
          </h1>
        </div>
        <form
          className="flex flex-col gap-6"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <div className="flex flex-col gap-2">
            <div>
              <p className="text-base font-bold text-content-base-subdued">
                Enter Granite360 Quote ID
              </p>
              <p className="text-sm font-medium text-content-base-subdued">
                Please utilize the QE identifier.
              </p>
            </div>
            <GraniteInput
              {...register('qe_quote_request_id')}
              error={errors.qe_quote_request_id?.message}
              placeholder="E.g., 212436"
              disabled={!!qe_quote_request_id}
            />
            <GraniteLabel
              className="col-span-1 mt-[20px]"
              label="Expiration date"
            >
              <Controller
                name={`expiration_date`}
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <div className="col-span-2">
                    <DatePicker
                      {...field}
                      onChange={onChange}
                      value={value}
                      min={addDays(startOfDay(new Date()), 0)}
                      error={errors.expiration_date?.message}
                    />
                  </div>
                )}
              />
            </GraniteLabel>
          </div>
          <div className="flex max-w-full flex-col justify-start gap-2 ">
            <p className="text-base font-bold text-content-base-subdued">
              Upload quote
            </p>
            <Controller
              name="file"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FileUpload
                  className="col-span-2"
                  accept=".xlsx"
                  error={errors.file?.message}
                  multiple
                  value={value}
                  onChange={(e) => {
                    setValue('formal_quote_filename', null);
                    setFileName(null);
                    onChange(e);
                  }}
                />
              )}
            />
            {fileName && !watch('file') && (
              <div className="flex flex-wrap gap-2">
                <div className="flex h-8 items-center rounded-[32px] border border-stroke-base-subdued bg-background-base-surface-3 px-2">
                  <span className="fill-content-base-subdued pr-1 text-content-base-default">
                    {fileName}
                  </span>
                  <button className="fill-content-base-subdued" type="button">
                    <CloseCircle
                      width="16px"
                      height="16px"
                      color="inherit"
                      onClick={() => {
                        setValue('formal_quote_filename', null);
                        setFileName(null);
                      }}
                    />
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center justify-start gap-6">
            <GraniteButton
              variant="secondary"
              size="large"
              onClick={() => navigate(-1)}
            >
              Cancel
            </GraniteButton>
            <GraniteButton
              variant="primary"
              size="large"
              type="submit"
              className={clsx(
                uploadQuoteMutation.isLoading && 'loading-button',
              )}
            >
              Submit
              {uploadQuoteMutation.isLoading && (
                <Loader animationClassname="!w-3 !h-3" />
              )}
            </GraniteButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadQRCode;
