import { TokenUserRoles } from '../api/users/schemas/Users';
import { useMemo } from 'react';
import { useAuth } from 'authContext';
import { G360Token } from 'api/auth/schema';

interface CWContact {
  user_id: number;
  company_id: number;
  macnum: string;
}

export interface GroupDetail {
  role: TokenUserRoles;
  group_id: string;
  group_name: string;
  cw_contacts: CWContact[];
}

export const useAuthUser = () => {
  const { user } = useAuth();

  const {
    email,
    email_verified,
    name,
    sub,
    given_name: first_name,
    family_name: last_name,
  } = user || {};

  const g360Token: G360Token = JSON.parse(
    localStorage.getItem('g360-token-storage') ?? '{}',
  );
  const { permissions, group_id, group_metadata, super_admin } = g360Token;
  // TODO: handle more than one cw_link
  const companyId = group_metadata?.info?.cw_links?.[0]?.id;
  const macnum = group_metadata?.info?.cw_links?.[0]?.macnum;
  const companyName = group_metadata?.display_name;

  const roles = useMemo(() => {
    if (g360Token.roles) {
      const _roles = [...g360Token.roles];
      if (g360Token.super_admin) _roles.push(TokenUserRoles.SUPER_ADMIN);
      return _roles;
    }
    return [];
  }, [g360Token]);

  return {
    email,
    email_verified,
    name,
    first_name,
    last_name,
    group_id,
    sub,
    companyId,
    companyName,
    roles,
    permissions,
    macnum,
    super_admin,
  };
};
