import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';

export const RestorePreferencesConfirmationDialog = ({
  isOpen,
  close,
  onConfirmation,
}: {
  isOpen: boolean;
  close: () => void;
  onConfirmation: () => void;
}) => (
  <Modal isVisible={isOpen} close={close} className="max-w-[624px]">
    <div className="flex flex-col gap-2 rounded-t-lg bg-background-base-surface-2">
      <h1 className="bg-background-base-surface-3 px-8 py-6  text-[28px] leading-9 text-content-base-default">
        Restore default settings
      </h1>
      <div className="space-y-12 p-8">
        <div>
          <p className="text-xl font-bold text-content-base-default">
            Are you sure you want to make these changes?
          </p>
          <p className="medium text-sm text-content-base-subdued">
            Once default notification settings are restored, the current
            notification preferences will be deleted. Only the notification
            settings in the current page will be restored to default. All other
            pages and workspaces settings will be maintained.
          </p>
        </div>
        <div className="flex gap-5">
          <GraniteButton size="large" variant="secondary" onClick={close}>
            Cancel
          </GraniteButton>
          <GraniteButton
            size="large"
            variant="destructive"
            onClick={() => {
              onConfirmation();
            }}
          >
            Restore default
          </GraniteButton>
        </div>
      </div>
    </div>
  </Modal>
);
