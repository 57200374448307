import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { deleteRole } from '../utils';
import { useMutation, useQueryClient } from 'react-query';
import showToast from 'components/Toast/Toast';
import { useNavigate, useParams } from 'react-router-dom';
import { FC } from 'react';

interface DeleteRoleProps {
  navigateTo?: string;
}

const DeleteRole: FC<DeleteRoleProps> = ({ navigateTo }) => {
  const { id, name } = useParams<{ id: string; name: string }>();

  const navigate = useNavigate();

  const onClose = () => navigate(navigateTo ?? '..', { replace: true });

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(deleteRole, {
    onSuccess: () => {
      showToast.confirmation({
        title: 'Role deleted',
        message: `${name}`,
      });
      queryClient.refetchQueries('roles-index-table');
      onClose();
    },
  });

  return (
    <Modal isVisible={true} close={onClose} className="w-full max-w-[624px]">
      <div className="p-8">
        <div>
          <h1 className="text-2xl font-bold text-white">
            Are you sure you want to delete this role?
          </h1>
          <p className="mt-2 text-base font-bold text-neutral-500">
            Are you sure you want to delete this role? This action cannot be
            undone.
          </p>
        </div>
        <div className="mt-8 flex gap-5">
          <GraniteButton variant="secondary" size="large" onClick={onClose}>
            Cancel
          </GraniteButton>
          <GraniteButton
            size="large"
            variant="destructive"
            disabled={isLoading}
            onClick={() => mutate(id as string)}
          >
            Yes, delete role
          </GraniteButton>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteRole;
