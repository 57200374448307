import { TicketDetailForm } from '../DefinedFormTypes';
import React, { Fragment } from 'react';
import Divider from '../../../../components/Divider';
import FormSection from '../../../../components/FormSection';
import { StandardTools } from '../../../../components/StandardTools/StandardTools';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import { GraniteLabel } from '../../../../components/V2/Label/GraniteLabel';
import GraniteTextArea from '../../../../components/TextArea/TextArea';
import RadioButton from '../../../../components/RadioButton';
import { GraniteInput } from '../../../../components/V2/Input/GraniteInput';
import { SmartHandsForm } from './schemas';
import { optionsToLabelValuePairs } from '../../utils/optionsToLabelValuePairs';
import {
  FormWithContact,
  TicketDetailContactForm,
} from '../../BaseComponents/TicketDetailContactForm';
import { NonStandardToolsFieldTextField } from '../../BaseComponents/NonStandardToolsField';

export type SmartHandsProps = TicketDetailForm<SmartHandsForm>;

export const SmartHands = ({ register, control, errors }: SmartHandsProps) => {
  return (
    <Fragment>
      <FormSection title="Ticket details" gridClassName="grid-cols-2">
        <Controller
          name="installOrTroubleShootingLV"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <GraniteLabel
              label="Is this an install of equipment/services or a troubleshooting dispatch?"
              element="div"
              className="col-span-2"
            >
              <RadioButton
                options={optionsToLabelValuePairs(
                  'Install equipment',
                  'Troubleshoot equipment',
                )}
                selectedValue={value?.value}
                onChangeWithLV={(value, label) => onChange({ value, label })}
                error={errors.installOrTroubleShootingLV?.message}
                ref={ref}
              />
            </GraniteLabel>
          )}
        />
        <Controller
          name="installOrTroubleShootingGuideLV"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <GraniteLabel
              label="Is there an install or troubleshooting guide available for this equipment/service?"
              element="div"
              className="col-span-2"
            >
              <RadioButton
                options={optionsToLabelValuePairs(
                  'Yes, there is a guide',
                  'No',
                )}
                selectedValue={value?.value}
                onChangeWithLV={(value, label) => onChange({ value, label })}
                error={errors.installOrTroubleShootingGuideLV?.message}
                ref={ref}
              />
            </GraniteLabel>
          )}
        />
        <Controller
          name="whichEquipment"
          control={control}
          render={({ field }) => (
            <GraniteInput
              {...field}
              label="Which equipment/services will the technician be assisting with?"
              className="col-span-full"
              error={errors.whichEquipment?.message}
            />
          )}
        />
        <GraniteTextArea
          className="col-span-full"
          label="Provide a port mapping for LAN or specific gear (optional)"
          subtitle="Dispatch success will be based on the accuracy of the information provided. If port mapping is missing information, this could result in a re-dispatch."
          error={errors.portMapping?.message}
          {...register('portMapping')}
        />
      </FormSection>
      <Divider />
      <FormSection gridClassName="grid-cols-2">
        <Controller
          control={control}
          name="techToTestWith"
          render={({ field: { onChange, value, ref } }) => (
            <Fragment>
              <GraniteLabel
                label="Does the technician need to test with anyone specific?"
                element="div"
                className="col-span-full"
              >
                <RadioButton
                  options={[
                    {
                      label: 'Yes, a member of our IT team',
                      value: 'CustomerIT',
                    },
                    {
                      label: 'Yes, an on site member of our team',
                      value: 'Person',
                    },
                    { label: 'No', value: 'No' },
                  ]}
                  selectedValue={value?.techToTestWith}
                  error={errors.techToTestWith?.message}
                  onChange={(techToTestWith) =>
                    onChange({ ...value, techToTestWith })
                  }
                  ref={ref}
                />
              </GraniteLabel>
              {value?.techToTestWith && value.techToTestWith !== 'No' && (
                <TicketDetailContactForm
                  errors={errors}
                  register={
                    register as unknown as UseFormRegister<FormWithContact>
                  }
                  control={control as unknown as Control<FormWithContact>}
                />
              )}
            </Fragment>
          )}
        />
      </FormSection>
      <Divider />
      <FormSection>
        <StandardTools />
        <NonStandardToolsFieldTextField control={control} />
      </FormSection>
    </Fragment>
  );
};
