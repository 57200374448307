import { FC, PropsWithChildren, useEffect } from 'react';
import { apiClient } from '../../api/apiClient';
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { getErrorResponse } from './util';
import { AccessToken } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { G360Token } from 'api/auth/schema';
import { getG360Token } from 'api/auth/auth';

let refreshG360TokenPromise: null | ReturnType<typeof getG360Token> = null;

export const AxiosInterceptor: FC<PropsWithChildren> = ({ children }) => {
  const { oktaAuth } = useOktaAuth();
  useEffect(() => {
    const interceptorRequest = apiClient.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        const controller = new AbortController();
        try {
          const token = (await oktaAuth.tokenManager.get(
            'accessToken',
          )) as AccessToken;
          config.headers['Authorization'] = `Bearer ${token.accessToken}`;
          const g360Token: G360Token = JSON.parse(
            localStorage.getItem('g360-token-storage') ?? '{}',
          );
          if (g360Token.token) {
            config.headers['G360-Authorization'] = `Bearer ${g360Token.token}`;
          }
        } catch (error) {
          controller.abort();
          await oktaAuth.signOut();
        }
        return { ...config, signal: controller.signal };
      },
    );

    const interceptorResponse = apiClient.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error?.response?.status === 401) {
          if (
            [
              'token is expired',
              'Unable to find G360-Authorization token',
              'G360-Authorization incorrect claims',
              'Unable to parse G360-Authorization token',
            ].includes(error?.response?.data?.reason)
          ) {
            localStorage.removeItem('g360-token-storage');
            return oktaAuth.signOut();
          }
          /*
          Refresh token if expired.
          Once token is refreshed retry the failed request
           */
          if (
            error?.response?.data?.reason ===
            'G360-Authorization Token is expired'
          ) {
            if (!originalRequest._retries) originalRequest._retries = 0;
            if (originalRequest._retries > 1) {
              localStorage.removeItem('g360-token-storage');
              return oktaAuth.signOut();
            }
            originalRequest._retries += 1;
            try {
              const g360Token: G360Token = JSON.parse(
                localStorage.getItem('g360-token-storage') ?? '{}',
              );
              if (!refreshG360TokenPromise) {
                refreshG360TokenPromise = getG360Token(g360Token.group_id);
              }
              const data = await refreshG360TokenPromise;
              refreshG360TokenPromise = null;
              localStorage.setItem('g360-token-storage', JSON.stringify(data));
              return apiClient(originalRequest);
            } catch (error) {
              localStorage.removeItem('g360-token-storage');
              return oktaAuth.signOut();
            }
          }
        }
        return getErrorResponse(error);
      },
    );

    return () => {
      apiClient.interceptors.request.eject(interceptorRequest);
      apiClient.interceptors.response.eject(interceptorResponse);
    };
  }, [oktaAuth]);

  return <>{children}</>;
};
