import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';

export const ClearTemplateConfirmationModal = ({
  isOpen,
  close,
  onConfirmation,
}: {
  isOpen: boolean;
  close: () => void;
  onConfirmation: () => void;
}) => {
  return (
    <Modal isVisible={isOpen} close={close} className="max-w-[624px]">
      <div className="space-y-12 rounded-t-lg bg-background-base-surface-2 p-8">
        <div className="space-y-2">
          <h1 className="text-content-base-default">Clear ticket template?</h1>
          <p className="font-bold text-content-base-subdued">
            If the ticket template is cleared, all scope of work info will be
            removed and the information in this ticket will need to be filled
            out manually.
          </p>
        </div>
        <div className="flex gap-5">
          <GraniteButton size="large" variant="secondary" onClick={close}>
            Cancel
          </GraniteButton>
          <GraniteButton
            size="large"
            variant="primary"
            onClick={() => {
              onConfirmation();
            }}
          >
            Clear template
          </GraniteButton>
        </div>
      </div>
    </Modal>
  );
};
