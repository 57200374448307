import Divider from 'components/Divider';
import { Fragment } from 'react';
import ChronicFlag from './ChronicFlag';

const SiteInformation = ({
  showTitle = true,
  information,
  chronic = false,
}: {
  showTitle?: boolean;
  information: { label: string; value: string | undefined }[];
  chronic: boolean;
}) => {
  return (
    <div className="flex flex-col gap-2">
      {showTitle && (
        <span className="text-base font-bold text-content-base-subdued">
          We’ve found your location and associated services. Please select the
          services that you are experiencing issues with.
        </span>
      )}
      {information.length > 0 && (
        <div className="flex flex-col gap-2 bg-background-base-surface-1 p-4">
          {chronic && <ChronicFlag />}
          {information.map((item, index, array) => {
            const isLastItem = index === array.length - 1;
            return (
              <Fragment key={index}>
                <div className="flex w-full items-center justify-between">
                  <span className="w-1/2 text-base font-bold text-content-base-subdued">
                    {item.label}
                  </span>
                  <span className="w-1/2 text-left text-base font-bold text-content-base-default">
                    {item.value}
                  </span>
                </div>
                {!isLastItem && (
                  <div className="w-full">
                    <Divider />
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SiteInformation;
