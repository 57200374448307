import { GraniteButton } from 'components/V2/Button/GraniteButton';
import React, { forwardRef } from 'react';
import { QuoteProduct } from '../schemas/Product';
import Accordion from 'components/Accordion';
import { getIpTypeLabel, getLabelForIpBlock } from '../utils';
import { QuoteLocation } from '../schemas/QuoteLocation';

export interface ProductItemProps {
  product: QuoteProduct;
  locations?: QuoteLocation[];
  onProductDelete: (product: QuoteProduct, name?: string) => void;
  onProductEdit: (product: QuoteProduct) => void;
}

const ProductItem = forwardRef<HTMLDivElement, ProductItemProps>(
  ({ product, locations, onProductDelete, onProductEdit }, ref) => {
    return (
      <div
        ref={ref}
        className="flex w-full flex-col items-start justify-start gap-6 rounded bg-background-base-surface-1 p-4"
      >
        <div className="flex w-full flex-col items-start justify-start gap-2 ">
          <h1 className="text-xl font-bold text-content-base-default">
            {product.name}
          </h1>
          <div className="flex h-[24px] w-[130px] items-center justify-center rounded-lg bg-orange-300 text-xs font-bold text-content-flip-default">
            Broadband circuit
          </div>
        </div>
        <div className="grid w-full grid-cols-3 gap-y-4">
          <div className="flex w-full flex-col items-start justify-start">
            <p className="text-base font-bold text-content-base-subdued">
              Access type
            </p>
            <p className="text-base font-bold text-content-base-default">
              {product.accessType}
            </p>
          </div>
          {product.type === 'broadband' && (
            <div className="flex w-full flex-col items-start justify-start">
              <p className="text-base font-bold text-content-base-subdued">
                Minimum speed
              </p>
              <p className="text-base font-bold text-content-base-default">{`${
                product.minimumDownloadSpeed
              } Mbps ${
                (product.minimumUploadSpeed ?? 0) > 0
                  ? ` / ${product.minimumUploadSpeed} Mbps`
                  : ''
              }`}</p>
            </div>
          )}
          <div className="flex w-full flex-col items-start justify-start">
            <p className="text-base font-bold text-content-base-subdued">
              IP type
            </p>
            <p className="text-base font-bold text-content-base-default">
              {getIpTypeLabel(product.ipType)}
            </p>
          </div>
          {product.type === 'broadband' && product.ipBlocks && (
            <div className="flex w-full flex-col items-start justify-start">
              <p className="text-base font-bold text-content-base-subdued">
                IP block
              </p>
              <p className="text-base font-bold text-content-base-default">
                {getLabelForIpBlock(product.ipBlocks)}
              </p>
            </div>
          )}
          <div className="flex w-full flex-col items-start justify-start">
            <p className="text-base font-bold text-content-base-subdued">
              Term length
            </p>
            <p className="text-base font-bold text-content-base-default">
              {product.termLength} years
            </p>
          </div>
          {locations && (
            <div className="col-span-full mt-2">
              <Accordion
                items={[
                  {
                    title: (
                      <p className="font-bold">
                        Locations{' '}
                        <span className="text-content-base-subdued">
                          ({locations.length})
                        </span>
                      </p>
                    ),
                    content: (
                      <div className="flex flex-col gap-3">
                        {locations.map((location, index) => (
                          <div key={location.id}>
                            <p className="font-semibold">
                              <span className="inline-block h-[20px] w-[21px] text-content-base-subdued">
                                {index + 1}.&nbsp;
                              </span>{' '}
                              <span className="text-sm font-semibold text-content-base-default">
                                {location.address}, {location.city},{' '}
                                {location.state} {location.zipcode}
                              </span>{' '}
                            </p>
                          </div>
                        ))}
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          )}
        </div>
        <div className="flex w-full items-center justify-start gap-4">
          <GraniteButton
            size="medium"
            variant="destructive"
            onClick={() => onProductDelete(product, product.name)}
          >
            Delete
          </GraniteButton>
          <GraniteButton
            size="medium"
            variant="secondary"
            onClick={() => onProductEdit(product)}
          >
            Edit product
          </GraniteButton>
        </div>
      </div>
    );
  },
);

ProductItem.displayName = 'ProductItem';

export default ProductItem;
