import { Fragment, useEffect } from 'react';
import { useSelectProductsContext } from '../Wizard/OpenQuoteWizardReducer';
import { OpenQuoteLayout } from '../BaseComponents/OpenQuoteLayout';
import { useModal } from 'hooks/useModal';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { ArrowBack, InformationCircle } from 'react-ionicons';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { WhatHappensAfterModal } from './WhatHappensAfterModal';
import Divider from 'components/Divider';
import { Price } from 'components/Price';

const getBannerText = (date: Date) =>
  `You have until ${date.toLocaleTimeString('en-US', {
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
  })} on ${date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })} to request a quote with these specifications.`;

export const OpenQuoteReview = observer(() => {
  const { wizardStore, productSelectionStore } = useSelectProductsContext();

  useEffect(() => {
    wizardStore.setStep(4);
  }, [wizardStore]);

  const { open: openWhatHappensAfter, ...modalProps } = useModal();

  return (
    <Fragment>
      <div className="mb-6 flex items-center gap-x-1 rounded border border-status-info-default p-4">
        <InformationCircle color="#9796F3" width="20px" height="20px" />
        <span className="text-sm text-content-base-default">
          {getBannerText(productSelectionStore.quoteExpiration)}
        </span>
      </div>
      <OpenQuoteLayout>
        <div className="flex flex-col gap-10 rounded bg-background-base-surface-2 p-6 shadow">
          <div className="flex w-full flex-col items-start justify-start">
            <div className="flex flex-col gap-4">
              <div>
                <h2 className="text-2xl font-bold text-content-base-default">
                  You&apos;re ready to submit your quote request!
                </h2>
                <p className="text-base font-bold">
                  Your estimated total charges are below. If you need to make
                  any changes, you can go back a step to make adjustments.
                </p>
              </div>
              <Link
                to="../review-products"
                className="button secondary medium w-fit"
              >
                <ArrowBack color="inherit" width="20px" height="20px" />
                <span>Go back to make adjustments</span>
              </Link>
            </div>
          </div>
          <div className="flex w-full flex-col gap-3 rounded bg-background-base-surface-0 px-6 py-4">
            <div className="flex justify-between">
              <span className="text-xl font-bold text-content-base-subdued">
                Products
              </span>
              <span className="text-2xl font-bold text-content-base-default">
                Broadband
              </span>
            </div>
            <Divider />
            <div className="flex justify-between">
              <span className="text-xl font-bold text-content-base-subdued">
                Locations
              </span>
              <span className="text-2xl font-bold text-content-base-default">
                {productSelectionStore.locations.length}
              </span>
            </div>
            <Divider />
            <div className="flex justify-between">
              <span className="text-xl font-bold text-content-base-subdued">
                Total non-recurring charges
              </span>
              <Price
                total={productSelectionStore.totalNRC}
                className="text text-2xl font-bold"
              />
            </div>
            <Divider />
            <div className="flex justify-between">
              <span className="text-xl font-bold text-content-base-subdued">
                Total monthly recurring charges
              </span>
              <Price
                total={productSelectionStore.totalMRC}
                className="text text-2xl font-bold"
              />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <button
              onClick={openWhatHappensAfter}
              className="w-fit p-0 text-sm font-semibold text-content-base-default underline"
            >
              What happens when I submit a quote request?
            </button>
            <div>
              <GraniteButton size="large" onClick={() => {}}>
                Submit quote request
              </GraniteButton>
            </div>
          </div>
        </div>
        <WhatHappensAfterModal {...modalProps} />
      </OpenQuoteLayout>
    </Fragment>
  );
});
