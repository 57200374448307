import React, { cloneElement, isValidElement, ReactNode } from 'react';
import clsx from 'clsx';
import { useUpgradeAccessTooltip } from 'hooks/useUpgradeAccessTooltip';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { Link } from 'react-router-dom';

interface RenderChildrenWithUpgradeTooltipProps {
  children: ReactNode;
}

export const RenderChildrenWithUpgradeTooltip: React.FC<
  RenderChildrenWithUpgradeTooltipProps
> = ({ children }) => {
  const {
    showTooltip,
    hideTooltip,
    renderTooltip,
    keepTooltipVisible,
    referenceElement,
  } = useUpgradeAccessTooltip();

  return (
    <>
      {React.Children.map(children, (child) => {
        if (!isValidElement(child)) return child;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const childType = (child.type as any).displayName || child.type;

        const isLink = ['a', 'Link'].includes(childType) || child.type === Link;
        const isButton =
          ['button', 'GraniteButton'].includes(childType) ||
          (childType.render && childType.render?.name) === 'GraniteButton' ||
          child.type === GraniteButton;

        if (isButton || isLink) {
          return (
            <div
              onMouseEnter={(e) => {
                showTooltip(e);
                keepTooltipVisible();
              }}
              onMouseLeave={hideTooltip}
              className="relative inline-block"
            >
              {cloneElement(child as React.ReactElement, {
                className: isLink
                  ? clsx(child.props.className, 'disabled-link')
                  : undefined,
                disabled: !isLink,
                onClick: isLink
                  ? (e: React.MouseEvent) => e.preventDefault()
                  : undefined,
              })}
            </div>
          );
        }

        return child;
      })}
      {referenceElement && renderTooltip()}
    </>
  );
};
