import { Link } from 'react-router-dom';
import { SettingsOutline } from 'react-ionicons';

const Footer = () => {
  return (
    <div className="sticky -bottom-[34px] mt-auto flex h-[52px] w-full bg-background-base-surface-2">
      <Link
        to="/notifications/preferences"
        className="button ghost medium h-[50px]"
      >
        <SettingsOutline width="17px" height="18px" color="#F8FAFC" />{' '}
        Notification preferences
      </Link>
    </div>
  );
};

export default Footer;
