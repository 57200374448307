import { NetOpsItem } from './schema';
import { getNetOpsTicketsIndex } from './api';

export interface GetDuplicateTicketsProps {
  configuration_ids: string;
  status: string;
}

export const getDuplicateTickets = async ({
  configuration_ids,
  status,
}: GetDuplicateTicketsProps): Promise<NetOpsItem[]> => {
  const ticketResponse = await getNetOpsTicketsIndex({
    configuration_ids,
    status,
  });
  return ticketResponse.data;
};
