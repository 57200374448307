import { addDays, setHours, setMinutes, isBefore, isWeekend } from 'date-fns';
import { isFederalHoliday } from 'date-fns-holiday-us';
import { getTimeZoneAbbreviationFromZipCode } from './util';

interface CalculateDispatchDateParams {
  priority: 'P1' | 'P2' | 'P3';
  zipcode: string;
}

const calculateDispatchDate = ({
  priority,
  zipcode,
}: CalculateDispatchDateParams) => {
  const timeZoneAbbreviation =
    getTimeZoneAbbreviationFromZipCode(zipcode) || 'UTC'; // Fallback to 'UTC'

  const now = new Date();
  const cutoffTime = setMinutes(setHours(now, 15), 0); // 3PM cutoff

  let dispatchDate = now;

  switch (priority) {
    case 'P1':
      dispatchDate = isBefore(now, cutoffTime) ? now : addDays(now, 1);
      break;
    case 'P2':
      dispatchDate = isBefore(now, cutoffTime)
        ? addDays(now, 1)
        : addDays(now, 2);
      break;
    case 'P3':
      dispatchDate = isBefore(now, cutoffTime)
        ? addDays(now, 2)
        : addDays(now, 3);
      break;
  }

  // Adjust for weekends and holidays
  while (isWeekend(dispatchDate) || isFederalHoliday(dispatchDate)) {
    dispatchDate = addDays(dispatchDate, 1);
  }

  // Assuming dispatch window is 9AM-5PM, set time to 9AM
  dispatchDate = setHours(dispatchDate, 9);
  dispatchDate = setMinutes(dispatchDate, 0);

  const dispatchDateString = `${dispatchDate.toLocaleDateString(
    'en-US',
  )} ${dispatchDate.toLocaleTimeString('en-US')} (${timeZoneAbbreviation})`;

  return dispatchDateString;
};

export default calculateDispatchDate;
