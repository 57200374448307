import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';

const ConfirmChangesDialog = ({
  isOpen,
  close,
  handleConfirm,
}: {
  isOpen: boolean;
  handleConfirm: () => void;
  close: () => void;
}) => {
  return (
    <Modal isVisible={isOpen} close={close} className="max-w-[624px]">
      <div className="flex flex-col gap-2 rounded-t-lg bg-background-base-surface-2">
        <h1 className="bg-background-base-surface-3 px-8 py-6  text-[28px] leading-9 text-content-base-default">
          Confirm changes
        </h1>
        <div className="px-8 py-6">
          <p className="text-[20px] font-bold leading-[22px] text-content-base-default">
            Are you sure you want to make these changes?
          </p>
          <div className="">
            <p className="medium mb-[48px] text-base font-bold text-content-base-subdued">
              Changes to the scope of work, including changes to materials,
              time, or priority level, may result in changes to the total
              estimated price of dispatch. If you acknowledge and wish to
              proceed with the changes, please click “Save changes.”
            </p>
            <div className="mt-4 flex gap-5">
              <GraniteButton size="large" variant="secondary" onClick={close}>
                Cancel
              </GraniteButton>
              <GraniteButton
                size="large"
                variant="primary"
                onClick={handleConfirm}
              >
                Save changes
              </GraniteButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmChangesDialog;
