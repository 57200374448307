import { makeAutoObservable } from 'mobx';
import { ImportLocationMode } from '../AddLocations/schemas';

export class OpenQuoteWizardStore {
  private _step: number = 1;
  private _title?: JSX.Element;
  private _importMode?: ImportLocationMode;
  private _qe_quote_request_id?: number;

  constructor() {
    makeAutoObservable(this);
  }

  setStep(step: number, title?: JSX.Element) {
    this._step = step;
    this._title = title;
  }

  get step() {
    return this._step;
  }

  get title() {
    return this._title;
  }

  setImportMode(importMode: ImportLocationMode) {
    this._importMode = importMode;
  }

  get importLocationMode(): ImportLocationMode | undefined {
    return this._importMode;
  }

  setQeQuoteRequestId(qe_quote_request_id: number) {
    this._qe_quote_request_id = qe_quote_request_id;
  }

  get QeQuoteRequestId(): number | undefined {
    return this._qe_quote_request_id;
  }
}
