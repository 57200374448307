import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { GraniteLabel } from '../V2/Label/GraniteLabel';
import { clsx } from 'clsx';
import { ErrorSubtext } from '../ErrorSubtext/ErrorSubtext';

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  subtitle?: string;
  error?: string | boolean;
  unit?: string;
  height?: string;
  className?: string;
  inputClassName?: string;
}

export const TextAreaGranite = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ label, subtitle, className, error, inputClassName, ...props }, ref) => {
    return (
      <GraniteLabel label={label} subtitle={subtitle} className={className}>
        <textarea
          {...props}
          className={clsx(
            'vertical-scrollbar overflow-hidden',
            error && 'error',
            inputClassName,
          )}
          ref={ref}
        ></textarea>
        <ErrorSubtext error={error} />
      </GraniteLabel>
    );
  },
);

TextAreaGranite.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  unit: PropTypes.string,
  height: PropTypes.string,
};

TextAreaGranite.displayName = 'TextAreaGranite';

export default TextAreaGranite;
