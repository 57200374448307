import { GetStartedQuestionnaireForm } from '../../GetStarted/schemas';
import { CustomerEquipmentForm } from './schemas';
import { ReviewTicketForm } from '../../Review/schemas';
import { questionnaireFormToReviewTicketDefaultValues } from '../../BaseComponents/questionnaireFormToReviewTicketDefaultValues';
import {
  NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX,
  NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX,
} from '../../utils/nonStandardToolsSOWUtils';
import { formatContactTesting } from 'shared/util/util';

export const customerEquipmentFormToReview = (
  questionnaire: GetStartedQuestionnaireForm,
  form: CustomerEquipmentForm,
): Partial<ReviewTicketForm> => {
  return {
    ...questionnaireFormToReviewTicketDefaultValues(questionnaire),
    scopeOfWork: formToSOW(form),
    nonStandardTools: form.nonStandardTools,
    guideAttachments:
      form.testingGuideAvailable?.value === 'Yes, a guide will be available'
        ? form.guideAttachments
        : [],
  };
};

const formToSOW = (form: CustomerEquipmentForm) => {
  return [
    form.nonStandardTools.isNeeded
      ? `${NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX} ${form.nonStandardTools.nonStandardTools} ${NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX}\n`
      : '',
    `Service technician needed on site to repair existing customer equipment.`,
    `\nThe equipment experiencing an issue is ${form.whatEquipment}.`,
    `\nThis equipment is currently being used for ${form.whatIsUsedFor} and is located at ${form.currentLocation}.`,
    form.testingGuideAvailable.value !== 'No'
      ? '\nThere will be a installation guide available to the technician on site'
      : '',
    `\nOnce the equipment has been successfully installed, the technician should test with: ${formatContactTesting(
      form.itTestingContacts,
    )}`,
  ]
    .filter((l) => l)
    .join('\n');
};
