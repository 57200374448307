import { clsx } from 'clsx';
import { ComponentProps, ReactNode } from 'react';

export interface DetailFieldProps
  extends Omit<ComponentProps<'div'>, 'children'> {
  description: string;
  value?: string | number | ReactNode;
}

export const DetailField = ({
  description,
  value,
  className,
  ...props
}: DetailFieldProps) => {
  if (!value) return null;
  return (
    <div className={clsx(className, 'flex flex-col')} {...props}>
      <span className="text-base font-bold text-content-base-subdued">
        {description}
      </span>
      <span
        className={clsx(
          'whitespace-pre-wrap text-base font-bold',
          'text-content-base-default',
        )}
      >
        {value}
      </span>
    </div>
  );
};
