import { NumericCell } from 'components/Table/Table.styles';
import React from 'react';

interface MetricProps {
  value: number | null;
  metric: string;
}

const Metrics: React.FC<MetricProps> = ({ value, metric }) =>
  value !== null ? (
    <NumericCell>
      {value}
      {metric}
    </NumericCell>
  ) : (
    <NumericCell color="primary"> N/A</NumericCell>
  );

export default Metrics;
