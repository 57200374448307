import React, { ReactNode } from 'react';
import { RenderChildrenWithUpgradeTooltip } from 'components/DisplayForRole/RenderChildrenWithUpgradeTooltip';
import { PermissionsProps, usePermissions } from 'hooks/usePermissions';

interface DisplayForPermissionProps extends PermissionsProps {
  children: ReactNode;
  upgradeTooltip?: boolean;
}

export const DisplayForPermission: React.FC<DisplayForPermissionProps> = ({
  permission,
  onlyForSuperAdmin,
  children,
  upgradeTooltip,
}) => {
  const { hasPermission } = usePermissions({ permission, onlyForSuperAdmin });

  if (!hasPermission && upgradeTooltip) {
    return (
      <RenderChildrenWithUpgradeTooltip>
        {children}
      </RenderChildrenWithUpgradeTooltip>
    );
  }

  if (hasPermission) return <>{children}</>;
  return null;
};
