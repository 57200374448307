import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useOpenQuoteContext } from '../Wizard/OpenQuoteWizardReducer';
import { GraniteSelect } from 'components/Select/Select';
import { useCallback, useState } from 'react';
import { SearchAddressBar } from '../AddLocations/SearchAddressBar';
import { QuoteLocation } from '../schemas/QuoteLocation';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import { observer } from 'mobx-react-lite';
import { QuoteProduct } from '../schemas/Product';

export interface LabelProduct {
  value: QuoteProduct;
  label: string | undefined;
}

const AddLocationDialog = observer(
  ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
    const { openQuoteStore } = useOpenQuoteContext();
    const [address, setAddress] = useState<QuoteLocation | null>();
    const [products, setProducts] = useState<LabelProduct[]>([]);

    const addLocation = useCallback(() => {
      if (!address) return;
      openQuoteStore.addLocation(address);
      const productsByLocation = openQuoteStore.productsByLocations.find(
        (l) => l.location.id === address.id,
      );
      products.forEach((p) => productsByLocation?.addProduct(p.value));
      close();
    }, [address, close, openQuoteStore, products]);

    return (
      <Modal
        isVisible={isOpen}
        close={close}
        className="h-[428px] w-full max-w-[624px]"
      >
        <div className="flex flex-col gap-2 rounded-lg bg-background-base-surface-2">
          <h1 className="rounded-t-lg bg-background-base-surface-3 px-8 py-6 text-[28px]  font-bold leading-9 text-content-base-default">
            Add new location
          </h1>
          <div className="flex w-full flex-col items-start justify-start gap-6 p-8">
            <div className="flex w-full flex-col items-start justify-start gap-2">
              <GraniteLabel className="font-semibold">
                Location address
              </GraniteLabel>
              <SearchAddressBar
                isSelectable
                className="w-full"
                onAddressSelected={(value) => setAddress(value)}
                name="search address"
                value={openQuoteStore.locations}
                onAddressDelete={(l) => openQuoteStore.deleteLocation(l)}
              />
            </div>
            <GraniteSelect
              isMulti
              onChange={(selectedProducts) => {
                setProducts(selectedProducts as LabelProduct[]);
              }}
              label="Select products (optional)"
              options={openQuoteStore.products.map((product) => ({
                value: product,
                label: product.name,
              }))}
            />
            <div className="mt-6 flex gap-5">
              <GraniteButton size="large" variant="secondary" onClick={close}>
                Cancel
              </GraniteButton>

              <GraniteButton
                size="large"
                variant="primary"
                className="w-full"
                onClick={addLocation}
              >
                Add location
              </GraniteButton>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);

export default AddLocationDialog;
