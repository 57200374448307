import { ActionMeta, MultiValue } from 'react-select';

type OptionType = { label: string; value: string };

export const handleServiceSelectChange = (
  selectedOptions: MultiValue<OptionType>,
  actionMeta: ActionMeta<OptionType>,
  allOptions: OptionType[],
  setServiceOptions: React.Dispatch<React.SetStateAction<OptionType[]>>,
  setValue: (field: string, value: unknown, shouldValidate?: boolean) => void,
) => {
  const serviceNotListedOption = {
    label: 'My service is not listed',
    value: 'service_not_listed',
  };
  const selectAllOption = { label: 'Select all', value: '*' };

  if (actionMeta.option?.value === 'service_not_listed') {
    if (actionMeta.action === 'select-option') {
      // Only "service_not_listed" and "Select all" should be selectable
      setValue('service', [serviceNotListedOption]);
      setServiceOptions([selectAllOption, serviceNotListedOption]);
      return [serviceNotListedOption];
    } else if (actionMeta.action === 'deselect-option') {
      // Restore all options and clear "service_not_listed"
      setServiceOptions(allOptions);
      const filteredOptions = selectedOptions.filter(
        (opt) => opt.value !== 'service_not_listed',
      );
      setValue('service', filteredOptions);
      return filteredOptions;
    }
  } else if (actionMeta.option?.value === '*') {
    if (actionMeta.action === 'select-option') {
      // Select all except 'service_not_listed', and remove 'service_not_listed' from options

      const optionsToSelect =
        allOptions.length <= 2
          ? allOptions
          : allOptions.filter((opt) => opt.value !== 'service_not_listed');

      setValue('service', optionsToSelect);
      setServiceOptions(optionsToSelect);
      return optionsToSelect;
    } else if (actionMeta.action === 'deselect-option') {
      setValue('service', []);
      setServiceOptions(allOptions);
      return [];
    }
  } else {
    const isServiceNotListedSelected = selectedOptions.some(
      (opt) => opt.value === 'service_not_listed',
    );
    if (isServiceNotListedSelected) {
      // Remove all selections except 'service_not_listed' and 'Select all'
      const limitedOptions = [serviceNotListedOption, selectAllOption];
      setValue('service', limitedOptions);
      setServiceOptions(limitedOptions);
      return limitedOptions;
    } else {
      // If regular options are being selected/deselected, ensure 'service_not_listed' is not in the list
      const optionsToSet =
        allOptions.length <= 2
          ? allOptions
          : allOptions.filter((opt) => opt.value !== 'service_not_listed');
      setServiceOptions(optionsToSet);
      const newOptions = selectedOptions.filter(
        (opt) => opt.value !== '*' && opt.value !== 'service_not_listed',
      );
      setValue('service', newOptions);
      return newOptions;
    }
  }
};

export const handleSelectChange = (
  selectedOptions: MultiValue<OptionType>,
  actionMeta: ActionMeta<OptionType>,
  allOptions: OptionType[],
) => {
  if (
    actionMeta.action === 'select-option' &&
    actionMeta.option?.value === '*'
  ) {
    // 'Select All' option was selected
    return selectedOptions.length >= allOptions.length ? [] : allOptions;
  } else if (
    actionMeta.action === 'deselect-option' &&
    actionMeta.option?.value === '*'
  ) {
    // 'Select All' option was deselected
    return [];
  } else if (
    actionMeta.action === 'deselect-option' ||
    actionMeta.action === 'remove-value'
  ) {
    // A specific option was deselected
    return selectedOptions.filter((option) => option.value !== '*');
  }
  return selectedOptions;
};
