import { Fragment, useRef, useState } from 'react';
import { Flag } from 'react-ionicons';
import { usePopper } from 'react-popper';

const ChronicFlag = () => {
  const [isVisible, setIsVisible] = useState(false);
  const triggerEltRef = useRef<HTMLDivElement>(null);
  const popperEltRef = useRef<HTMLDivElement>(null);

  const { styles, attributes } = usePopper(
    triggerEltRef?.current,
    popperEltRef?.current,
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 5],
          },
        },
      ],
    },
  );
  return (
    <Fragment>
      <div
        ref={triggerEltRef}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        <div
          className={`flex h-6 w-[81px] flex-row items-center justify-center gap-1 rounded-lg bg-status-error-default font-bold text-content-flip-default`}
        >
          <p className="w-max text-xs font-bold">Chronic</p>
          <Flag width="12px" height="12px" color="inherit" />
        </div>
      </div>
      <div ref={popperEltRef} style={styles.popper} {...attributes.popper}>
        {isVisible && (
          <div className="max-w-[280px] rounded-lg bg-background-base-surface-0 p-5 shadow-elevation3">
            <p className="mb-1 text-base font-bold text-content-base-default">
              Chronic site
            </p>
            <p className="text-sm text-content-base-subdued">
              There have been at least 3 trouble tickets opened for this site
              within the last 30 days.
            </p>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ChronicFlag;
