import styled from 'styled-components';

export const ErrorText = styled.span`
  font-size: ${({ theme }) => theme.text.size.sm};
`;

export const InputDetail = styled.p`
  font-size: ${({ theme }) => theme.text.size.sm};
  margin-top: 8px;
`;
