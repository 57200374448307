import { ComponentProps } from 'react';
import { clsx } from 'clsx';

export type BadgeProps = ComponentProps<'div'>;

export const Badge = ({ className, ...props }: BadgeProps) => {
  return (
    <div
      className={clsx(
        className,
        'flex items-center justify-center rounded-lg px-4 py-1.5',
      )}
      {...props}
    />
  );
};
