import React from 'react';
import { AlertConfigurationListItemType } from '../AlertConfigurationTabel';
import Switch from 'components/Switch';
import Divider from 'components/Divider';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { AlertConfigurationSchema } from './schema';
import SingelRange from 'components/Range/SingelRange';
import { useParams } from 'react-router-dom';
import { GraniteInput } from 'components/V2/Input/GraniteInput';

interface AlertConfigurationFormProps {
  defaultValues: AlertConfigurationListItemType[];
  close: () => void;
}

const AlertConfigurationForm: React.FC<AlertConfigurationFormProps> = ({
  defaultValues,
  close,
}) => {
  const { alertId } = useParams();
  const { handleSubmit, control, watch } = useForm({
    resolver: zodResolver(AlertConfigurationSchema),
    defaultValues: {
      ...defaultValues[0],
      time: defaultValues[0].time || 50,
      'high-latency': defaultValues[0]['high-latency'] || 100,
      recovery: defaultValues[0].recovery || 350,
      status: {
        main: defaultValues.every((v) => v.status === 'Enabled'),
        indeterminate:
          !defaultValues.every((v) => v.status === 'Enabled') &&
          !defaultValues.every((v) => v.status === 'Disabled') &&
          !defaultValues.every((v) => v.status === 'Not eligible'),
      },
    },
  });

  const status = watch('status').main || watch('status').indeterminate;

  const onSubmit = () => {};

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="rounded-t-lg bg-background-base-surface-3 px-8 py-6 font-bold leading-9">
        <h1 className="text-2.5xl text-content-base-default">
          Edit alerts for selected devices ({defaultValues.length})
        </h1>
      </div>
      <div className="p-8">
        <div className="flex flex-col gap-6">
          <div className="flex items-start justify-between">
            <div>
              <p className="text-xl font-bold text-white">
                Enable alerts for {alertId?.toLocaleLowerCase()}
              </p>
              <p className="text-xs font-bold">
                Enable alerts for these devices in order to make changes
              </p>
            </div>
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, value } }) => {
                return (
                  <Switch
                    indeterminate={value.indeterminate}
                    isOn={value.main}
                    onChange={(v) => {
                      onChange({ main: v, indeterminate: false });
                    }}
                  />
                );
              }}
            />
          </div>
          <Divider />
          <div className="flex flex-col gap-6 ">
            <Controller
              control={control}
              name="time"
              render={({ field }) => {
                return (
                  <div className="flex items-center gap-6">
                    <p className="flex-1 text-base font-bold text-content-base-subdued">
                      Time
                    </p>
                    <GraniteInput
                      {...field}
                      disabled={!status}
                      innerInputClassName="max-w-[180px]"
                      suffix="minutes"
                    />
                    <div className="w-[150px]">
                      <SingelRange
                        disabled={!status}
                        minValue={0}
                        maxValue={50}
                        value={+field.value}
                        stepSize={1}
                        onChange={field.onChange}
                        getValue={(v) => `${v}m`}
                      />
                    </div>
                  </div>
                );
              }}
            />
            <Controller
              control={control}
              name="high-latency"
              render={({ field }) => {
                return (
                  <div className="flex items-center gap-6">
                    <p className="flex-1 text-base font-bold text-content-base-subdued">
                      High latency threshold
                    </p>

                    <GraniteInput
                      {...field}
                      disabled={!status}
                      innerInputClassName="max-w-[180px]"
                      suffix="milliseconds"
                    />
                    <div className="w-[150px]">
                      <SingelRange
                        disabled={!status}
                        minValue={0}
                        maxValue={100}
                        value={+field.value}
                        stepSize={1}
                        onChange={field.onChange}
                        getValue={(v) => `${v}m`}
                      />
                    </div>
                  </div>
                );
              }}
            />
            <Controller
              control={control}
              name="recovery"
              render={({ field }) => {
                return (
                  <div className="flex items-center gap-6">
                    <p className="flex-1 text-base font-bold text-content-base-subdued">
                      Recovery threshold
                    </p>
                    <GraniteInput
                      {...field}
                      disabled={!status}
                      innerInputClassName="max-w-[180px]"
                      suffix="milliseconds"
                    />
                    <div className="w-[150px]">
                      <SingelRange
                        disabled={!status}
                        minValue={0}
                        maxValue={500}
                        value={+field.value}
                        stepSize={1}
                        onChange={field.onChange}
                        getValue={(v) => `${v}m`}
                      />
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="mt-10 flex gap-5">
          <GraniteButton variant="secondary" size="large" onClick={close}>
            Cancel
          </GraniteButton>
          <GraniteButton size="large">Save changes</GraniteButton>
        </div>
      </div>
    </form>
  );
};

export default AlertConfigurationForm;
