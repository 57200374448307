import {
  LETTERS_NUMBERS_SPACES_HYPHENS_COLONS_REGEXP_LABEL,
  LETTERS_NUMBERS_SPACES_HYPHENS_COLONS_SLASHES_REGEXP_LABEL,
} from 'shared/constants/error-labels';
import {
  LETTERS_NUMBERS_SPACES_HYPHENS_COLONS_REGEXP,
  LETTERS_NUMBERS_SPACES_HYPHENS_COLONS_SLASHES_REGEXP,
} from 'shared/constants/validation-regex-constants';
import { z } from 'zod';

export const RoleSchema = z.object({
  name: z
    .string()
    .min(2, { message: 'Name must be at least 2 characters long.' })
    .max(50, {
      message: 'Name must be no more than 50 characters long.',
    })
    .regex(LETTERS_NUMBERS_SPACES_HYPHENS_COLONS_REGEXP, {
      message: LETTERS_NUMBERS_SPACES_HYPHENS_COLONS_REGEXP_LABEL,
    }),
  description: z
    .string()
    .min(2, { message: 'Description must be at least 2 characters long.' })
    .max(100, {
      message: 'Description must be no more than 100 characters long.',
    })
    .regex(LETTERS_NUMBERS_SPACES_HYPHENS_COLONS_SLASHES_REGEXP, {
      message: LETTERS_NUMBERS_SPACES_HYPHENS_COLONS_SLASHES_REGEXP_LABEL,
    }),
  // .optional()
  // .or(z.literal('')),
});

export const EditRoleSchema = z
  .object({
    permissions: z.array(z.string()).optional(),
  })
  .merge(RoleSchema);
