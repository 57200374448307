import { GetStartedQuestionnaireForm } from '../../GetStarted/schemas';
import { ReviewTicketForm } from '../../Review/schemas';
import { ExtensionPOTSForm } from './schemas';
import { questionnaireFormToReviewTicketDefaultValues } from '../../BaseComponents/questionnaireFormToReviewTicketDefaultValues';
import {
  NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX,
  NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX,
} from '../../utils/nonStandardToolsSOWUtils';
import { formatContactTesting } from 'shared/util/util';
import { formatPhoneNumber } from 'screens/LetUsHelp/utils/formatPhoneNumber';

export const extensionPOTSFormToReview = (
  questionnaire: GetStartedQuestionnaireForm,
  form: ExtensionPOTSForm,
): Partial<ReviewTicketForm> => {
  return {
    ...questionnaireFormToReviewTicketDefaultValues(questionnaire),
    scopeOfWork: formToSOW(form),
    nonStandardTools: form.nonStandardTools,
  };
};

const formToSOW = (form: ExtensionPOTSForm) => {
  return [
    form.nonStandardTools.isNeeded
      ? `${NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX} ${form.nonStandardTools.nonStandardTools} ${NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX}\n`
      : '',
    'Service technician needed on site to run an extension for an existing POTS line.',
    form.mpoeLocation
      ? `\nThe MPOE (Minimum Point of Entry) for this POTS line is located at ${form.mpoeLocation}.`
      : '',
    form.telephoneNumber
      ? `\nThe telephone number associated with this POTS line is ${formatPhoneNumber(
          form.telephoneNumber,
        )}.`
      : '',
    form.extension.value === 'JackIn'
      ? `\nThe extension will be going to a jack located at ${form.extension.location} on site and is approximately ${form.extensionLength} feet in length.`
      : `\nThe extension will be going to ${form.extension.label} and is approximately ${form.extensionLength} feet in length.`,
    form.techToTestWith.techToTestWith !== 'No'
      ? `\nOnce the extension is successful, the technician should test with: ${formatContactTesting(
          form.itTestingContacts,
        )}`
      : '',
  ]
    .filter((l) => l)
    .join('\n');
};
