import { PillarOption, pillarByCategory } from './constants';
import { hasPermission } from 'hooks/usePermissions';

export const getAllowedPillarByCategory = () =>
  Object.entries(pillarByCategory).reduce<
    Record<PillarOption['category'], PillarOption[]>
  >(
    (acc, [category, pillars]) => {
      const allowedPillars = pillars.filter((pillar) =>
        pillar.permissions
          ? hasPermission({ permission: pillar.permissions })
          : pillar,
      );
      if (allowedPillars.length > 0)
        acc[category as PillarOption['category']] = allowedPillars;
      return acc;
    },
    {} as Record<PillarOption['category'], PillarOption[]>,
  );
