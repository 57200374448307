interface DarkSparklesProps {
  fill?: string;
}

export const DarkSparkles = ({ fill = '#94A3B8' }: DarkSparklesProps) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.99994 15.9999C6.84193 16.0007 6.68743 15.9533 6.5571 15.8639C6.42676 15.7746 6.3268 15.6476 6.27057 15.4999L5.02557 12.2624C5.00035 12.1972 4.96178 12.1379 4.91232 12.0885C4.86286 12.039 4.80362 12.0004 4.73838 11.9752L1.49994 10.7293C1.35242 10.6727 1.22554 10.5727 1.13604 10.4424C1.04654 10.3122 0.998626 10.1579 0.998626 9.99991C0.998626 9.8419 1.04654 9.68761 1.13604 9.55739C1.22554 9.42717 1.35242 9.32716 1.49994 9.27054L4.73744 8.02554C4.80268 8.00032 4.86193 7.96175 4.91138 7.91229C4.96084 7.86284 4.99941 7.80359 5.02463 7.73835L6.27057 4.49991C6.32718 4.3524 6.4272 4.22551 6.55742 4.13601C6.68764 4.04651 6.84193 3.9986 6.99994 3.9986C7.15795 3.9986 7.31225 4.04651 7.44246 4.13601C7.57268 4.22551 7.6727 4.3524 7.72932 4.49991L8.97432 7.73741C8.99953 7.80265 9.0381 7.8619 9.08756 7.91136C9.13702 7.96081 9.19627 7.99939 9.2615 8.0246L12.4803 9.26304C12.6338 9.31994 12.766 9.42278 12.859 9.55754C12.952 9.6923 13.0012 9.85244 12.9999 10.0162C12.9976 10.1714 12.9486 10.3224 12.8595 10.4495C12.7703 10.5767 12.6451 10.6741 12.4999 10.7293L9.26244 11.9743C9.1972 11.9995 9.13795 12.0381 9.0885 12.0875C9.03904 12.137 9.00047 12.1962 8.97525 12.2615L7.72932 15.4999C7.67308 15.6476 7.57312 15.7746 7.44279 15.8639C7.31245 15.9533 7.15796 16.0007 6.99994 15.9999Z" />
      <path d="M3.24994 5.49991C3.1573 5.49991 3.06684 5.47184 2.99047 5.4194C2.9141 5.36696 2.85541 5.29262 2.82213 5.20616L2.29525 3.83616C2.28383 3.8062 2.26621 3.77899 2.24354 3.75632C2.22086 3.73364 2.19365 3.71602 2.16369 3.7046L0.793691 3.17773C0.707247 3.14444 0.632915 3.08574 0.580488 3.00937C0.528062 2.933 0.5 2.84255 0.5 2.74991C0.5 2.65728 0.528062 2.56682 0.580488 2.49045C0.632915 2.41408 0.707247 2.35539 0.793691 2.3221L2.16369 1.79523C2.19362 1.78375 2.22081 1.76611 2.24347 1.74345C2.26614 1.72078 2.28378 1.6936 2.29525 1.66366L2.81744 0.305851C2.84689 0.225914 2.89757 0.155514 2.96402 0.102216C3.03048 0.0489189 3.1102 0.0147393 3.19463 0.00335107C3.29599 -0.00897073 3.39856 0.0128976 3.48608 0.0654883C3.5736 0.118079 3.64105 0.198382 3.67775 0.293664L4.20463 1.66366C4.2161 1.6936 4.23374 1.72078 4.25641 1.74345C4.27908 1.76611 4.30626 1.78375 4.33619 1.79523L5.70619 2.3221C5.79263 2.35539 5.86697 2.41408 5.91939 2.49045C5.97182 2.56682 5.99988 2.65728 5.99988 2.74991C5.99988 2.84255 5.97182 2.933 5.91939 3.00937C5.86697 3.08574 5.79263 3.14444 5.70619 3.17773L4.33619 3.7046C4.30623 3.71602 4.27902 3.73364 4.25635 3.75632C4.23367 3.77899 4.21605 3.8062 4.20463 3.83616L3.67775 5.20616C3.64447 5.29262 3.58578 5.36696 3.50941 5.4194C3.43304 5.47184 3.34258 5.49991 3.24994 5.49991Z" />
      <path d="M12.9999 7.99991C12.8989 7.99988 12.8002 7.96923 12.7169 7.91199C12.6336 7.85475 12.5696 7.77362 12.5334 7.67929L11.8196 5.82398C11.8071 5.79128 11.7878 5.76159 11.763 5.73683C11.7383 5.71207 11.7086 5.69278 11.6759 5.68023L9.82057 4.96648C9.72631 4.93016 9.64526 4.86614 9.5881 4.78286C9.53094 4.69957 9.50034 4.60093 9.50034 4.49991C9.50034 4.3989 9.53094 4.30026 9.5881 4.21697C9.64526 4.13369 9.72631 4.06967 9.82057 4.03335L11.6759 3.3196C11.7086 3.30705 11.7383 3.28776 11.763 3.263C11.7878 3.23823 11.8071 3.20854 11.8196 3.17585L12.5281 1.33366C12.5604 1.24655 12.6158 1.16983 12.6882 1.11167C12.7607 1.0535 12.8476 1.01607 12.9396 1.00335C13.0502 0.989962 13.1621 1.0139 13.2576 1.07136C13.3531 1.12883 13.4266 1.21652 13.4665 1.32054L14.1803 3.17585C14.1928 3.20854 14.2121 3.23823 14.2369 3.263C14.2616 3.28776 14.2913 3.30705 14.324 3.3196L16.1793 4.03335C16.2736 4.06967 16.3546 4.13369 16.4118 4.21697C16.4689 4.30026 16.4995 4.3989 16.4995 4.49991C16.4995 4.60093 16.4689 4.69957 16.4118 4.78286C16.3546 4.86614 16.2736 4.93016 16.1793 4.96648L14.324 5.68023C14.2913 5.69278 14.2616 5.71207 14.2369 5.73683C14.2121 5.76159 14.1928 5.79128 14.1803 5.82398L13.4665 7.67929C13.4302 7.77362 13.3663 7.85475 13.283 7.91199C13.1997 7.96923 13.101 7.99988 12.9999 7.99991Z" />
    </svg>
  );
};
