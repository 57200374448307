import clsx from 'clsx';

function Skeleton({ className }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={clsx(
        `animate-skeleton overflow-hidden rounded-lg bg-neutral-500`,
        className,
      )}
    ></div>
  );
}

export default Skeleton;
