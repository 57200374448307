import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ModalParams } from 'hooks/useModal';
import { MapOutline } from 'react-ionicons';
import { LocationProducts } from '../Wizard/ProductSelectionStore';
import { observer } from 'mobx-react-lite';

interface LocationsDialog extends ModalParams {
  locations: LocationProducts[];
}

export const LocationsDialog = observer(
  ({ locations, isOpen, close }: LocationsDialog) => {
    return (
      <Modal isVisible={isOpen} close={close}>
        <div className="flex h-auto w-[624px] flex-col items-start justify-start gap-12 rounded bg-background-base-surface-2 p-8">
          <div className="flex items-center justify-center gap-2">
            <span className="text-content-base-default">
              <MapOutline color={'inherit'} width={'21px'} height={'21px'} />
            </span>
            <p className="text-2xl font-bold text-content-base-default">
              Locations ({locations.length})
            </p>
          </div>
          <div className="flex max-h-[288px] w-full flex-col items-start justify-start gap-3 overflow-y-auto rounded bg-background-base-surface-1 p-4">
            {locations.length > 0 &&
              locations.map((location, index) => (
                <div
                  key={location.id}
                  className="flex items-center justify-start gap-2"
                >
                  <span className="text-sm font-semibold text-content-base-subdued">
                    {index + 1}.
                  </span>
                  <p className="text-sm font-semibold text-content-base-default">
                    {location.fullAddress}
                  </p>
                </div>
              ))}
          </div>
          <GraniteButton variant="secondary" size="large" onClick={close}>
            Close
          </GraniteButton>
        </div>
      </Modal>
    );
  },
);
