import { z } from 'zod';

export const NetOpsTicketStatuses = z.enum([
  'Open',
  'In Progress',
  'Pending Action',
  'Cleared Canceled',
  'Pending Customer',
  'Pending NI',
  'Resolved',
]);
