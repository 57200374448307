import { FC, Fragment, useEffect, useMemo } from 'react';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb';
import { TitleActionButtonContainer } from 'components/PageTitle/PageTitle.styles';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { Pillar } from 'components/Notifications/utils';
import Tabs from 'components/Table/Tabs';
import clsx from 'clsx';
import {
  patchNotificationPreferences,
  resetNotificationPreferences,
} from 'api/notifications/api';
import {
  NotificationPreferenceForm,
  PatchNotificationPreferences,
} from 'api/notifications/schema';
import Divider from 'components/Divider';
import { ChevronDown, Trash } from 'react-ionicons';
import { Controller, useForm } from 'react-hook-form';
import Switch from 'components/Switch';
import { PreferenceSkeleton } from './PreferenceSkeleton';
import { useModal } from 'hooks/useModal';
import { RestorePreferencesConfirmationDialog } from './RestorePreferencesConfirmationDialog';
import { useQueryClient } from 'react-query';
import { useNotificationPreferences } from './useNotificationPreferences';
import { EventTypeFilterGroup } from './EventTypeFilterGroup';
import { UserCreatedOnlyFilterGroup } from './UserCreatedOnlyFilterGroup';

const Loader = () => (
  <>
    <PreferenceSkeleton />
    <Divider />
    <PreferenceSkeleton loaders={3} />
    <Divider />
    <PreferenceSkeleton loaders={2} />
  </>
);

export const NotificationPreferencesPage: FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    allowedPillarByCategory,
    activePillar,
    setActivePillar,
    activeTab,
    tabs,
    isLoadingPreferences,
    isLoadingSettings,
    pillarNotificationSettings,
    eventCategories,
    eventGroups,
    defaultPillarPreferences,
    userPreferences,
  } = useNotificationPreferences();

  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty },
    reset,
    setValue,
  } = useForm<NotificationPreferenceForm>({
    defaultValues: {
      preferences: userPreferences,
    },
  });

  const fields = watch('preferences');

  const { open, close, ...modalProps } = useModal();

  useEffect(() => {
    reset({ preferences: userPreferences });
  }, [userPreferences, reset]);

  const onSubmit = async ({ preferences }: NotificationPreferenceForm) => {
    const data = preferences.reduce<PatchNotificationPreferences>(
      (acc, preference) => {
        if (!preference.filter_event_type) {
          acc.push({
            id: preference.preference_id,
            event_group_id: preference.event_group_id,
            user_created_only: preference.user_created_only,
            in_app: preference.in_app,
          });
        } else {
          Object.entries<{ value: boolean; preference_id?: string | null }>(
            preference.event_type_ids,
          ).forEach(([event_type_id, { value, preference_id }]) =>
            acc.push({
              event_type_id: Number(event_type_id),
              id: preference_id,
              event_group_id: preference.event_group_id,
              user_created_only: preference.user_created_only,
              in_app: value,
            }),
          );
        }
        return acc;
      },
      [],
    );
    reset({ preferences });
    const newPreferences = await patchNotificationPreferences(data);
    queryClient.setQueryData(
      ['notification-preferences', activePillar],
      newPreferences,
    );
  };
  const resetChanges = () => {
    reset({ preferences: userPreferences });
  };
  const restoreDefault = async () => {
    reset({ preferences: defaultPillarPreferences });
    close();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    await resetNotificationPreferences(activePillar);
    queryClient.setQueryData(['notification-preferences', activePillar], []);
  };

  const breadcrumbs = useMemo(
    () => [
      {
        icon: 'home',
        label: 'Home',
        onClick: () => navigate('/'),
      },
    ],
    [navigate],
  );

  return (
    <ContentLayout className="w-full 2xl:max-w-[1440px]">
      <div className="mb-12">
        <Breadcrumb breadcrumbs={breadcrumbs} />
        <div className="flex justify-between">
          <h1 className="text-4xl font-bold text-content-base-default">
            Notification preferences
          </h1>
          <TitleActionButtonContainer className="gap-x-4">
            <GraniteButton
              size="large"
              onClick={resetChanges}
              disabled={!isDirty}
              variant="secondary"
            >
              Reset changes
            </GraniteButton>
            <GraniteButton
              size="large"
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty}
              variant="primary"
            >
              Save changes
            </GraniteButton>
          </TitleActionButtonContainer>
        </div>
      </div>
      <div className="grid min-h-screen grid-cols-3 rounded">
        <div className="col-span-1 space-y-12 bg-background-base-surface-3 p-8">
          <h2 className="m-0 mb-4 text-2xl font-bold text-white">
            Feature types
          </h2>
          <div className="space-y-6 pt-3">
            {Object.entries(allowedPillarByCategory).map(
              ([category, options]) => (
                <div key={category} className="flex flex-col gap-2">
                  <span className="text-sm font-bold">{category}</span>
                  <div className="space-y-2 bg-background-base-surface-2-subdued p-4">
                    {options
                      .filter(({ name }) => name !== 'all')
                      .map(({ name, Icon, label }) => (
                        <div
                          key={name}
                          onClick={() =>
                            setActivePillar(name as Exclude<Pillar, 'all'>)
                          }
                          className={clsx(
                            'group flex cursor-pointer items-center justify-start gap-2 border-l-2 border-transparent p-4',
                            activePillar === name
                              ? '!border-stroke-accent-default !bg-background-base-surface-2 fill-content-accent-default'
                              : 'fill-content-base-default',
                          )}
                        >
                          {Icon && (
                            <Icon
                              height="24px"
                              width="auto"
                              className="group-hover:filter-content-accent-default w-auto "
                            />
                          )}
                          {!Icon && (
                            <p
                              className={`group-hover:filter-content-accent-default font-bold ${
                                activePillar === name
                                  ? 'text-content-accent-default'
                                  : 'text-content-base-default'
                              }`}
                            >
                              {label}
                            </p>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
        <div className="col-span-2 space-y-12 bg-background-base-surface-2 p-8">
          <h2 className="m-0 mb-4 text-2xl font-bold text-content-base-default">
            Notification types
          </h2>
          <Tabs tabs={tabs} />
          {isLoadingSettings || isLoadingPreferences ? (
            <Loader />
          ) : (
            <>
              <form className="space-y-12">
                {Object.values(eventCategories).map((category) => {
                  const groups = Object.values(eventGroups).filter(
                    (group) => group.event_category_id === category.id,
                  );
                  const groupIds = groups.map(({ id }) => id);
                  const categoryFields = fields.filter((field) =>
                    groupIds.includes(field.event_group_id),
                  );
                  return (
                    <Fragment key={`${activePillar}.${category.id}`}>
                      <div
                        className="accordion-item w-2/3 space-y-4"
                        data-open="true"
                      >
                        <div className="flex justify-between">
                          <div
                            className="flex cursor-pointer select-none items-center gap-2 text-content-base-default"
                            onClick={(event) => {
                              const accordionItem = (
                                event.target as HTMLElement
                              ).closest('.accordion-item') as HTMLElement;
                              const accordionContent =
                                accordionItem.querySelector(
                                  '.accordion-content',
                                ) as HTMLElement;
                              const accordionChevron =
                                accordionItem.querySelector(
                                  '.accordion-chevron',
                                ) as HTMLElement;
                              accordionChevron.classList.toggle('rotate-180');
                              const isOpen =
                                accordionItem.getAttribute('data-open') ===
                                'true';
                              if (isOpen) {
                                accordionContent.style.maxHeight = '0';
                              } else {
                                accordionContent.style.maxHeight = `${accordionContent.scrollHeight}px`;
                              }
                              accordionItem.setAttribute(
                                'data-open',
                                `${!isOpen}`,
                              );
                            }}
                          >
                            <h3 className="text-2xl font-bold">
                              {category.name}
                            </h3>
                            <ChevronDown
                              color="currentColor"
                              cssClasses="accordion-chevron rotate-180"
                              width="24px"
                              height="24px"
                            />
                          </div>
                          <span>{activeTab}</span>
                        </div>
                        <div
                          className="accordion-content transform space-y-4 overflow-hidden transition-all duration-300 ease-in-out"
                          ref={(ref) => {
                            if (ref)
                              setTimeout(
                                () =>
                                  (ref.style.maxHeight = `${ref.scrollHeight}px`),
                                0,
                              );
                          }}
                        >
                          {categoryFields.map((field) => {
                            const eventGroup =
                              eventGroups[field.event_group_id];
                            return (
                              <div key={field.index} className="space-y-4">
                                <div className="flex items-center justify-between gap-x-32">
                                  <span>{eventGroup.description}</span>
                                  <Controller
                                    name={`preferences.${field.index}.in_app`}
                                    control={control}
                                    render={({
                                      field: { value, onChange },
                                    }) => (
                                      <Switch
                                        isOn={value}
                                        onChange={onChange}
                                        labelClassName="!text-sm !mr-2 !text-content-base-default !font-medium"
                                      />
                                    )}
                                  />
                                </div>
                                {eventGroup.config.event_types_filterable && (
                                  <EventTypeFilterGroup
                                    control={control}
                                    setValue={setValue}
                                    field={field}
                                    eventGroup={eventGroup}
                                    pillarNotificationSettings={
                                      pillarNotificationSettings
                                    }
                                  />
                                )}
                                {eventGroup.config
                                  .user_created_only_enabled && (
                                  <UserCreatedOnlyFilterGroup
                                    control={control}
                                    field={field}
                                    eventGroup={eventGroup}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <Divider className="last:hidden" />
                    </Fragment>
                  );
                })}
              </form>
              <GraniteButton variant="destructive" size="small" onClick={open}>
                <span>Restore default</span>
                <Trash height="18px" width="18px" />
              </GraniteButton>
            </>
          )}
        </div>
      </div>
      <RestorePreferencesConfirmationDialog
        {...modalProps}
        close={close}
        onConfirmation={restoreDefault}
      />
    </ContentLayout>
  );
};
