import { useSelectProductsContext } from '../Wizard/OpenQuoteWizardReducer';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { PageTitle } from '../BaseComponents/PageTitle';
import {
  CellContext,
  ColumnDef,
  ColumnFilter,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import {
  StackedCell,
  StackedCellMainContent,
  StackedCellSubtitle,
  TableCell,
} from '../../../components/Table/Table.styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Pagination from 'components/Table/Pagination';
import Searchbar from 'components/Table/SearchBar';

import styled from 'styled-components';
import Filters from 'components/Filters';
import { GraniteSelect } from 'components/Select/Select';
import { Chip } from 'components/Chip/Chip';
import { ChevronUp, Cog } from 'react-ionicons';
import { LocationProducts } from '../Wizard/ProductSelectionStore';
import { Observer, observer } from 'mobx-react-lite';
import { Price } from '../../../components/Price';
import { QuoteExpirationBanner } from '../BaseComponents/QuoteExpirationBanner';
import { PriceContainer } from '../BaseComponents/PriceContainer';

export const ProductsToLocationsTable = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto 107px 107px;
  column-gap: 48px;
  row-gap: 16px;
`;

export const TableRow = styled.div`
  display: grid !important;
  background-color: var(--background_base_surface-3_subdued) !important;
  grid-column: 1 / -1;
  grid-template-columns: auto 1fr auto 107px 107px;
  column-gap: 48px;
  border-radius: 4px;
`;

const getRowIdx = (index: number) => (index + 1) * 2;

const carrierOptions = [
  { label: 'Category 1', value: 'category_1' },
  { label: 'Category 2', value: 'category_2' },
];

const productCategories = [
  { label: 'Primary circuit', value: 'primary_circuit' },
  { label: 'Secondary circuit', value: 'secondary_circuit' },
];

const locationStatuses = [
  { label: 'Needs attention', value: 'needs_attention' },
  { label: 'No issues', value: 'no_issues' },
];

const LocationProductsCell = observer(
  ({ cellCtx }: { cellCtx: CellContext<LocationProducts, string> }) => {
    const products = cellCtx.row.original.selectedProducts;
    return (
      <StackedCell className="w-full gap-3">
        <StackedCellMainContent>
          <span className="text-base font-semibold">
            {cellCtx.row.original.fullAddress}
          </span>
        </StackedCellMainContent>
        <StackedCellSubtitle>
          {products.length > 0 ? (
            <div className="flex gap-4">
              {/* {products.map((p, i) => {
                return (
                  <ProductTooltip key={i} product={p} inReviewProducts={true} />
                );
              })} */}
            </div>
          ) : (
            <span className="text-sm font-bold">No products selected</span>
          )}
        </StackedCellSubtitle>
      </StackedCell>
    );
  },
);

export const ReviewProducts = () => {
  const { wizardStore, productSelectionStore } = useSelectProductsContext();
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'index', desc: false },
  ]);

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const [columnVisibility, setColumnVisibility] = useState({});

  useEffect(() => {
    wizardStore.setStep(3);
  }, [wizardStore]);

  const locationsColumns: ColumnDef<LocationProducts>[] = [
    {
      id: 'index',
      header: '#',
      accessorKey: 'index',
      enableSorting: true,
      sortingFn: (a, b) => a.index - b.index,
      cell: (cellCtx) => (
        <span className="text-content-base-subdued">
          {cellCtx.row.index + 1}
        </span>
      ),
    },
    {
      id: 'address',
      header: '',
      meta: {
        isAddress: true,
      },
      enableSorting: false,
      accessorFn: (row) => row.fullAddress,
      filterFn: (row, id, value) => {
        const rowValue = row.getValue(id) as string;
        if (Array.isArray(value)) {
          return value.some((item) =>
            rowValue.toLowerCase().includes(item.label.toLowerCase()),
          );
        }
        return rowValue.toLowerCase().includes(value.toLowerCase());
      },
      cell: (cellCtx) => <LocationProductsCell cellCtx={cellCtx} />,
    },
    {
      id: 'button',
      header: '',
      accessorKey: '',
      cell: (cellCtx) => {
        return (
          <Link
            to={`../location-detail/${cellCtx.row.original.id}`}
            className="button secondary medium"
          >
            <Cog width="20px" height="20px" />
            Edit Products
          </Link>
        );
      },
    },
    {
      header: 'Total NRC',
      accessorKey: 'total_nrc',
      cell: (cellCtx) => {
        return (
          <StackedCell className="w-full">
            <StackedCellSubtitle>
              <span className="text-base">Total NRC</span>
            </StackedCellSubtitle>
            <Observer>
              {() => (
                <Price
                  className="text-2xl font-bold"
                  total={cellCtx.row.original.totalNRC}
                />
              )}
            </Observer>
          </StackedCell>
        );
      },
    },
    {
      header: 'Total MRC',
      accessorKey: 'total_mrc',
      cell: (cellCtx) => {
        return (
          <StackedCell className="w-full">
            <StackedCellSubtitle>
              <span className="text-base">Total MRC</span>
            </StackedCellSubtitle>
            <Observer>
              {() => (
                <Price
                  className="text-2xl font-bold"
                  total={cellCtx.row.original.totalMRC}
                />
              )}
            </Observer>
          </StackedCell>
        );
      },
    },
  ];

  const table = useReactTable({
    data: productSelectionStore.locations,
    columns: locationsColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      columnFilters,
      columnVisibility,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false,
    enableMultiSort: false,
    sortDescFirst: false,
  });

  const clearAllFilters = useCallback(() => {
    setColumnFilters([]);
  }, []);

  const addressColumn = table.getColumn('address');
  const addressFilter: ColumnFilter | undefined = columnFilters.find(
    (filter) => filter.id === 'address',
  );

  const filters = columnFilters
    .filter((c) => c.id === 'address' && c.value)
    .flatMap((c) => c.value);

  return (
    <>
      <QuoteExpirationBanner productSelectionStore={productSelectionStore} />
      <div className="mt-6">
        <div className="grid grid-cols-1 gap-4 !rounded-t-[4px] bg-background-base-surface-2 p-6 pb-12">
          <PageTitle
            title="Review & customize products for each of your locations."
            subtitle="You can select a location to view details and make any customizations."
          />
          <div className="grid grid-cols-1 gap-6">
            <div className="flex w-full flex-wrap items-start justify-between gap-4">
              <div className="w-full sm:flex-1">
                <Searchbar
                  placeholder="Search by address"
                  clearAllValues={!addressFilter}
                  onQueryClear={() => addressColumn?.setFilterValue(undefined)}
                  onSearch={(query: string) =>
                    addressColumn?.setFilterValue(query)
                  }
                />
              </div>
              <Filters clearFilters={clearAllFilters}>
                <GraniteSelect
                  options={locationStatuses}
                  isMulti
                  placeholder="Location status"
                  controlShouldRenderValue={false}
                  isSearchable={false}
                  onChange={addressColumn?.setFilterValue}
                  value={filters}
                />
                <GraniteSelect
                  options={carrierOptions}
                  isMulti
                  placeholder="Carrier"
                  controlShouldRenderValue={false}
                  isSearchable={false}
                  onChange={addressColumn?.setFilterValue}
                  value={filters}
                />
                <GraniteSelect
                  options={productCategories}
                  isMulti
                  placeholder="Product category"
                  controlShouldRenderValue={false}
                  isSearchable={false}
                  onChange={addressColumn?.setFilterValue}
                  value={filters}
                />
              </Filters>
            </div>
            <div className="flex flex-wrap gap-4">
              {Array.isArray(addressFilter?.value) &&
                addressFilter?.value.map((sf) => (
                  <Chip
                    key={sf.value}
                    label={sf.label}
                    onDelete={() => {
                      const addressFilterValue =
                        addressColumn?.getFilterValue() as {
                          label: string;
                          value: string;
                        }[];
                      const filteredAddressValue = addressFilterValue?.filter(
                        (item: { value: string }) => item.value !== sf.value,
                      );
                      addressColumn?.setFilterValue(
                        filteredAddressValue.length > 0
                          ? filteredAddressValue
                          : undefined,
                      );
                    }}
                  />
                ))}
            </div>
            <div>
              <ProductsToLocationsTable>
                {table.getHeaderGroups().map((headerGroup, index) => (
                  <Fragment key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <div
                        key={header.id}
                        style={{ gridColumnStart: index }}
                        className={clsx(
                          'group row-[1] mb-6 flex items-center justify-between text-center font-bold text-content-base-default',
                          header.column.getCanSort() &&
                            'cursor-pointer gap-1 fill-input-content-focus',
                        )}
                        role="columnheader"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                        {header.column.getCanSort() ? (
                          <ChevronUp
                            cssClasses={clsx(
                              'group-hover:text-content-base-default group-active:text-content-accent-default focus:text-content-accent-default',
                              header.column.getIsSorted() === 'desc'
                                ? 'rotate-180'
                                : '',
                              header.column.getIsSorted()
                                ? '!text-content-accent-default'
                                : '',
                            )}
                            width="16px"
                            height="16px"
                            color="rgb(var(--content-base-subdued))"
                          />
                        ) : null}
                      </div>
                    ))}
                  </Fragment>
                ))}
                {table.getRowModel().rows.map((row, index) => {
                  return (
                    <TableRow key={row.id} className="p-4">
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          style={{
                            gridRow: getRowIdx(index),
                          }}
                          $minWidth={0}
                          className={clsx(
                            !cell.column.columnDef.meta?.isAddress &&
                              'text-end',
                          )}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </ProductsToLocationsTable>
              <div className="mt-10 flex">
                <Pagination
                  variant="short"
                  pageCount={table.getPageCount()}
                  totalRows={productSelectionStore.locations.length ?? 0}
                  currentPage={table.getState().pagination.pageIndex + 1 || 1}
                  onPageChange={(page: number) => table.setPageIndex(page - 1)}
                  currentRowsShown={table.getRowModel().rows.length}
                  pageSizeChanged={(page: number) =>
                    table.setPageSize(Number(page))
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-10 rounded-b bg-background-base-surface-0 px-6 py-4">
          <span className="text-base font-bold text-content-base-subdued">
            Total charges:
          </span>
          <PriceContainer
            title="Non-recurring charges"
            priceClassName={clsx(
              productSelectionStore.totalNRC === 0 &&
                '!text-content-base-subdued',
            )}
            price={productSelectionStore.totalNRC}
          />
          <PriceContainer
            title="Monthly recurring charges"
            priceClassName={clsx(
              productSelectionStore.totalMRC === 0 &&
                '!text-content-base-subdued',
            )}
            price={productSelectionStore.totalMRC}
          />
        </div>
        <div className="flex justify-end gap-4 rounded-b bg-background-base-surface-3 p-6">
          <Link
            to="../mass-apply"
            className="button large secondary box-border w-[222px]"
          >
            Back
          </Link>
          <Link
            to="../review"
            className="button large primary box-border w-[222px]"
          >
            Finalize & review
          </Link>
        </div>
      </div>
    </>
  );
};
