import clsx from 'clsx';
import Checkbox from 'components/Checkbox';
import { LocationProducts } from '../Wizard/ProductSelectionStore';
import { observer } from 'mobx-react-lite';
import { computed } from 'mobx';
import { PriceContainer } from '../BaseComponents/PriceContainer';

export interface SubtotalProps {
  locationProducts: LocationProducts;
}

const Subtotal = observer(({ locationProducts }: SubtotalProps) => {
  const checkboxLabel = computed(() => {
    switch (locationProducts.availableProducts.length) {
      case 0:
        return 'No products available';
      default:
        return `Assigned products (${locationProducts.enabledProductCount}/${locationProducts.availableProducts.length})`;
    }
  }).get();

  return (
    <div className="flex justify-between gap-10 bg-background-base-surface-0 px-8 py-4">
      <Checkbox
        label={
          <span
            className={clsx(
              'ml-2 text-2xl font-bold text-content-base-default',
              locationProducts.isAllProductsDisabled &&
                '!text-content-base-subdued',
            )}
          >
            {checkboxLabel}
          </span>
        }
        onChange={() => locationProducts.toggleAllProductsStatus()}
        checked={locationProducts.isAllProductsEnabled}
        isPartiallChecked={locationProducts.isPartialProductSelection}
      />

      <div className="flex justify-end gap-10">
        <div className="flex flex-col">
          <span className="text-base font-bold text-content-base-subdued">
            Total charges:
          </span>
        </div>
        <PriceContainer
          title="Non-recurring charges"
          priceClassName={clsx(
            locationProducts.totalNRC === 0 && '!text-content-base-default',
          )}
          price={locationProducts.totalNRC}
        />
        <PriceContainer
          title="Monthly recurring charges"
          priceClassName={clsx(
            locationProducts.totalMRC === 0 && '!text-content-base-default',
          )}
          price={locationProducts.totalMRC}
        />
      </div>
    </div>
  );
});

export default Subtotal;
