import { lookup } from 'zipcode-to-timezone';
import { zonedTimeToUtc } from 'date-fns-tz';

/**
 * Converts a date to the utc moment as if the given date is on the timezone of the given zipcode.
 *
 * @param date
 * @param zipcode
 */
export const zonedTimeOfZipCodeToUtc = (date: Date, zipcode: string): Date => {
  const targetTimezone = lookup(zipcode);
  if (!targetTimezone) return date;
  return zonedTimeToUtc(date, targetTimezone);
};
