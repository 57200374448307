import { ErrorSubtext } from 'components/ErrorSubtext/ErrorSubtext';
import React, { useState, useEffect } from 'react';
import StarForFeedback from './StarForFeedback';

const StarRating = ({
  label,
  value = 0,
  onRate,
  error,
  disabled,
}: {
  label: string;
  value: number;
  onRate?: (value: number) => void;
  error?: string;
  disabled?: boolean;
}) => {
  const [rating, setRating] = useState(value - 1);
  const [hoveredRating, setHoveredRating] = useState(-1);

  useEffect(() => {
    setRating(value - 1);
  }, [value]);

  const handleRating = (rate: number) => {
    if (disabled) return;
    setRating(rate);
    if (onRate) {
      onRate(rate + 1);
    }
  };

  return (
    <div className="flex w-full flex-col items-start justify-start gap-2">
      <p className="text-base font-bold text-content-base-subdued">{label}</p>
      <div className="flex items-start justify-start gap-4">
        {[...Array(5)].map((_, index) => (
          <span
            key={index}
            className={`${
              index <= (hoveredRating !== -1 ? hoveredRating : rating)
                ? 'text-teal-400'
                : 'text-neutral-700'
            } ${
              !disabled &&
              index <= (hoveredRating !== -1 ? hoveredRating : rating)
                ? 'scale-110'
                : ''
            } transform cursor-pointer transition-transform duration-200`}
            onClick={() => handleRating(index)}
            onMouseEnter={() => !disabled && setHoveredRating(index)}
            onMouseLeave={() => !disabled && setHoveredRating(-1)}
          >
            <StarForFeedback
              classNames={
                !disabled &&
                index <= (hoveredRating !== -1 ? hoveredRating : rating)
                  ? 'shadow-starRating'
                  : ''
              }
            />
          </span>
        ))}
      </div>
      {error && <ErrorSubtext error={error} />}
    </div>
  );
};

export default StarRating;
