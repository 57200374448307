import styled, { css } from 'styled-components';
import IconMapper from '../IconMapper';
import Button from '../Button';

export type DatePartState = 'empty' | 'filled' | 'error' | 'disabled';

export const DatePickerIcon = styled(IconMapper)<{
  $datePartState: DatePartState;
}>`
  margin: auto 0 auto auto;

  & svg {
    fill: ${({ $datePartState, theme }) => {
      switch ($datePartState) {
        case 'error':
          return theme.colors.content.error;
        case 'disabled':
          return theme.colors.content.readonly;
        case 'filled':
        case 'empty':
          return theme.colors.text.white;
      }
    }} !important;
  }
`;

export const DatePickerMenu = styled.div``;

export const DatePickerMenuWrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing.factorOf(4)};
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.factorOf(4)};
  color: ${({ theme }) => theme.colors.text.white};
  z-index: 9999;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background.baseSurface};

  box-shadow:
    0 1px 3px 1px rgba(0, 0, 0, 0.15),
    0 1px 2px 0 rgba(0, 0, 0, 0.3);
`;

export const DatePickerMenuToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DatePickerMenuToolBarIconWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const DatePickerMenuGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 32px);
  grid-template-rows: 40px;
  grid-auto-rows: 32px;
  grid-gap: ${({ theme }) => theme.spacing.factorOf(2)};

  font-weight: 600;
  line-height: 24px;
`;

export const DatePickerMenuHeaderCell = styled.span`
  border-bottom: 2px solid ${({ theme }) => theme.colors.border.hr};
  min-height: 40px;
  line-height: 40px;
  text-align: center;
`;

export const DatePickerMenuDayCell = styled.button<{
  $selected?: boolean;
}>`
  all: unset;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.white};

  &:hover {
    color: ${({ theme }) => theme.colors.button.primaryHover};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.text.primary};
  }

  ${({ $selected }) =>
    $selected &&
    css`
      background: ${({ theme }) => theme.colors.background.primary};
      color: ${({ theme }) => theme.colors.text.dark};
      border-radius: 100%;

      &:hover {
        color: ${({ theme }) => theme.colors.text.dark};
      }
    `}
`;

export const DatePickerFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DatePickerFooterButton = styled(Button)`
  color: ${({ theme }) => theme.colors.text.white};
  width: 63px;

  &:hover {
    color: ${({ theme }) => theme.colors.button.primaryHover};
  }
`;
