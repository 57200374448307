interface LightSparklesProps {
  fill?: string;
}

export const LightSparkles = ({ fill = '#94A3B8' }: LightSparklesProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.50002 16C6.39064 16 6.28439 15.9781 6.18439 15.9313C5.99377 15.8469 5.84689 15.6938 5.77189 15.4969L4.44689 12.0532L1.00002 10.7281C0.793769 10.65 0.631269 10.4844 0.550019 10.2781C0.396894 9.87502 0.596894 9.4219 1.00002 9.26877L4.44377 7.94377L5.77189 4.50002C5.85002 4.29377 6.01564 4.13127 6.22189 4.05002C6.41564 3.97502 6.62814 3.98127 6.81877 4.06565C7.00939 4.15002 7.15627 4.30315 7.23127 4.50002L8.55627 7.94377L12 9.2719C12.2063 9.35003 12.3688 9.51565 12.45 9.7219C12.6063 10.125 12.4031 10.5781 12 10.7313L8.55627 12.0563L7.22814 15.5C7.15002 15.7063 6.98439 15.8688 6.77814 15.95C6.69064 15.9844 6.59377 16 6.50002 16ZM1.89064 10L4.89689 11.1563C5.10314 11.2344 5.26564 11.4 5.34689 11.6063L6.50314 14.6125L7.65939 11.6063C7.73752 11.4 7.90314 11.2375 8.10939 11.1563L11.1156 10L8.10939 8.84377C7.90314 8.76565 7.74064 8.60003 7.65939 8.39377L6.50314 5.38752L5.34689 8.39377C5.26877 8.60003 5.10314 8.76252 4.89689 8.84377L1.89064 10Z" />
      <path d="M2.75 5.5C2.54375 5.5 2.35625 5.37187 2.28437 5.17812L1.74063 3.75938L0.321875 3.21563C0.128125 3.14375 0 2.95625 0 2.75C0 2.54375 0.128125 2.35625 0.321875 2.28437L1.74063 1.74063L2.28437 0.321875C2.35625 0.128125 2.54375 0 2.75 0C2.95625 0 3.14375 0.128125 3.21563 0.321875L3.75938 1.74063L5.17812 2.28437C5.37187 2.35938 5.5 2.54375 5.5 2.75C5.5 2.95625 5.37187 3.14375 5.17812 3.21563L3.75938 3.75938L3.21563 5.17812C3.14375 5.37187 2.95625 5.5 2.75 5.5ZM1.89375 2.75L2.30625 2.90937C2.4375 2.95937 2.54375 3.06563 2.59375 3.19688L2.75312 3.60938L2.9125 3.19688C2.9625 3.06563 3.06875 2.95937 3.2 2.90937L3.6125 2.75L3.2 2.59063C3.06875 2.54063 2.9625 2.43438 2.9125 2.30312L2.75 1.89375L2.59063 2.30625C2.54063 2.4375 2.43438 2.54375 2.30312 2.59375L1.89375 2.75Z" />
      <path d="M12.5 8C12.2938 8 12.1063 7.87187 12.0344 7.67812L11.2812 5.71875L9.32187 4.96562C9.12813 4.89062 9 4.70625 9 4.5C9 4.29375 9.12813 4.10625 9.32187 4.03438L11.2812 3.28125L12.0344 1.32187C12.1094 1.12812 12.2938 1 12.5 1C12.7063 1 12.8938 1.12812 12.9656 1.32187L13.7188 3.28125L15.6781 4.03438C15.8719 4.10938 16 4.29375 16 4.5C16 4.70625 15.8719 4.89375 15.6781 4.96562L13.7188 5.71875L12.9656 7.67812C12.8938 7.87187 12.7063 8 12.5 8ZM10.8938 4.5L11.8469 4.86562C11.9781 4.91563 12.0844 5.02187 12.1344 5.15312L12.5 6.10625L12.8656 5.15312C12.9156 5.02187 13.0219 4.91563 13.1531 4.86562L14.1062 4.5L13.1531 4.13438C13.0219 4.08438 12.9156 3.97813 12.8656 3.84687L12.5 2.89375L12.1344 3.84687C12.0844 3.97813 11.9781 4.08438 11.8469 4.13438L10.8938 4.5Z" />
    </svg>
  );
};
