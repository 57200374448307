import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { PermissionFormType } from 'screens/RolesPermissions/roles-permissions.types';
import { FormProvider, useForm } from 'react-hook-form';
import Loader from 'components/Loader';
import Form from './Form';
import {
  getPermissionById,
  putPermission,
} from 'screens/RolesPermissions/utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PermissionSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import showToast from 'components/Toast/Toast';
import { useNavigate, useParams } from 'react-router-dom';
import ModalFormHeader from 'components/Modal/ModalFormHeader';

const EditPermission = () => {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const onClose = () => navigate('..', { replace: true });

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ['permission-edit', id],
    () => getPermissionById(id as string),
    { refetchOnMount: true },
  );

  const { reset, ...methods } = useForm<PermissionFormType>({
    resolver: zodResolver(PermissionSchema),
  });

  useEffect(() => {
    if (data) {
      reset({
        name: data.name,
        description: data.description || '',
      });
    }
  }, [data, reset]);

  const { mutate, isLoading: putLoading } = useMutation(putPermission, {
    onSuccess: (data) => {
      showToast.confirmation({
        title: 'Permission updated!',
        message: `${data?.name}`,
      });
      queryClient.refetchQueries('permissions-index-table');
      queryClient.refetchQueries(['permission-edit', id]);
      onClose();
    },
  });

  const onSubmit = (data: PermissionFormType) => id && mutate({ id, ...data });

  return (
    <Modal isVisible={true} close={onClose} className="w-full max-w-[624px]">
      <ModalFormHeader
        title="Edit permission"
        subtitle="Permissions can be assigned to roles."
      />
      <FormProvider {...methods} reset={reset}>
        {isLoading ? (
          <div className="flex h-[388px] items-center justify-center p-8">
            <Loader />
          </div>
        ) : (
          <form className="p-8" onSubmit={methods.handleSubmit(onSubmit)}>
            <Form />
            <div className="mt-12 flex gap-5">
              <GraniteButton variant="secondary" size="large" onClick={onClose}>
                Cancel
              </GraniteButton>
              <GraniteButton size="large" type="submit" disabled={putLoading}>
                Save
              </GraniteButton>
            </div>
          </form>
        )}
      </FormProvider>
    </Modal>
  );
};

export default EditPermission;
