import { TicketDetailForm } from '../DefinedFormTypes';
import React, { Fragment } from 'react';
import Divider from '../../../../components/Divider';
import FormSection from '../../../../components/FormSection';
import { StandardTools } from '../../../../components/StandardTools/StandardTools';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import { GraniteLabel } from '../../../../components/V2/Label/GraniteLabel';
import RadioButton from '../../../../components/RadioButton';
import { GraniteInput } from '../../../../components/V2/Input/GraniteInput';
import { CustomerMaterialForm } from './schemas';
import {
  FormWithContact,
  TicketDetailContactForm,
} from '../../BaseComponents/TicketDetailContactForm';
import { NonStandardToolsFieldTextField } from '../../BaseComponents/NonStandardToolsField';

export type CustomerMaterialProps = TicketDetailForm<CustomerMaterialForm>;

export const CustomerMaterial = ({
  register,
  control,
  errors,
}: CustomerMaterialProps) => {
  return (
    <Fragment>
      <FormSection title="Ticket details" gridClassName="grid-cols-2">
        <GraniteInput
          label="What materials or equipment appear to be broken?"
          className="col-span-full"
          error={errors.whatMaterial?.message}
          {...register('whatMaterial')}
        />
        <GraniteInput
          label="Where is it located?"
          className="col-span-full"
          error={errors.currentLocation?.message}
          {...register('currentLocation')}
        />
        <GraniteInput
          label="What is it connected to?"
          className="col-span-full"
          error={errors.whatIsItConnectedTo?.message}
          {...register('whatIsItConnectedTo')}
        />
      </FormSection>
      <Divider />
      <FormSection gridClassName="grid-cols-2">
        <Controller
          control={control}
          name="techToTestWith"
          render={({ field: { onChange, value, ref } }) => (
            <Fragment>
              <GraniteLabel
                label="Does the technician need to test with anyone on site?"
                element="div"
                className="col-span-full"
              >
                <RadioButton
                  options={[
                    {
                      label: "Yes, I'll provide their contact information",
                      value: 'Yes',
                    },
                    { label: 'No', value: 'No' },
                  ]}
                  selectedValue={value?.techToTestWith}
                  error={errors.techToTestWith?.message}
                  onChange={(techToTestWith) =>
                    onChange({ ...value, techToTestWith })
                  }
                  ref={ref}
                />
              </GraniteLabel>
              {value?.techToTestWith && value.techToTestWith === 'Yes' && (
                <TicketDetailContactForm
                  errors={errors}
                  register={
                    register as unknown as UseFormRegister<FormWithContact>
                  }
                  control={control as unknown as Control<FormWithContact>}
                />
              )}
            </Fragment>
          )}
        />
      </FormSection>
      <Divider />
      <FormSection>
        <StandardTools />
        <NonStandardToolsFieldTextField control={control} />
      </FormSection>
    </Fragment>
  );
};
