import debounce from 'lodash.debounce';
import { useState } from 'react';
import { downloadCSVAsExcel } from 'shared/util/downloadCSVAsExcel';

const useDownloadConfigurations = ({
  fetchFn,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchFn: () => Promise<any>;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onDownloadHandler = debounce(async () => {
    if (!isLoading) {
      try {
        setIsLoading(true);

        const data = await fetchFn();
        downloadCSVAsExcel(data, 'site_list');
      } catch (error) {
        console.error('Error fetching configurations:', error);
      } finally {
        setIsLoading(false);
      }
    }
  }, 300);

  return {
    isLoading,
    onDownloadHandler,
  };
};

export default useDownloadConfigurations;
