import { QueryClient, QueryClientConfig } from 'react-query';

let queryClientInstance: QueryClient;

export const getQueryClient = (queryClientConfig?: QueryClientConfig) => {
  if (!queryClientInstance) {
    queryClientInstance = new QueryClient(queryClientConfig);
  }
  return queryClientInstance;
};
