import { IframeHTMLAttributes } from 'react';

export const Word = ({
  src,
  className,
}: IframeHTMLAttributes<HTMLIFrameElement>) => (
  <iframe
    className={className}
    src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
      src ?? '',
    )}`}
  />
);
