import { z } from 'zod';

export const EstimatedCostRequestSchema = z.object({
  board_name: z.string(),
  sub_type_name: z.string(),
  type_name: z.string(),
});

export type EstimatedCostRequest = z.infer<typeof EstimatedCostRequestSchema>;

export const EstimatedCostResponseSchema = z.object({
  rate_per_hour: z.number(),
  hours: z.number(),
  dispatch_fee_dollars: z.number(),
  misc_fee_dollars: z.number(),
  total: z.number(),
  priority: z.string().optional(),
});

export type EstimatedCostResponse = z.infer<typeof EstimatedCostResponseSchema>;
