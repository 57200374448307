import { makeAutoObservable, toJS } from 'mobx';
import { ActionMeta } from 'react-select';
import { optionsToLabelValuePairs } from '../LetUsHelp/utils/optionsToLabelValuePairs';
import { dateOptions, statusDescriptionOptions } from './filterConstantOptions';

export interface FilterOption {
  label: string;
  value: string;
}

export interface Filter {
  column: string;
  operand: 'IN' | 'NOT_IN';
  values: string[];
}

class FilterStore {
  private initialFilter?: Filter;
  private initialValues = [] as string[];
  private selected = new Set<string>();

  constructor(private column: string) {
    makeAutoObservable(this);
  }

  setInitialValues(initialFilter: Filter, valueOverride?: string[]) {
    this.initialFilter = initialFilter;
    this.initialValues = valueOverride ?? initialFilter.values ?? [];
  }

  get options() {
    return this.initialValues.map((v) => ({ label: v, value: v }));
  }

  get selections() {
    return optionsToLabelValuePairs(...Array.from(this.selected));
  }

  get filter() {
    if (this.selected.size === 0) {
      return this.initialFilter;
    } else {
      return {
        values: Array.from(this.selected),
        operand: 'IN',
        column: this.column,
      };
    }
  }

  select(action: ActionMeta<FilterOption>) {
    if (action.action === 'select-option' && action.option) {
      this.selected.add(action.option.value);
    }
    if (action.action === 'deselect-option' && action.option) {
      this.selected.delete(action.option.value);
    }
    if (action.action === 'clear') {
      this.selected.clear();
    }
  }
}

export class NOCDashboardFilterStore {
  private hasInitialFilters = false;
  private priorityStore = new FilterStore('Priority_Description');
  private statusStore = new FilterStore('status_description');
  private ticketTypeStore = new FilterStore('Ticket_Type');
  private ticketSubTypeStore = new FilterStore('Ticket_SubType');
  private onFilterChangeCB?: (
    filters: Filter[],
    interval: string,
    count: number,
  ) => void;
  private dateFilter = 'Default';

  constructor() {
    makeAutoObservable(this);
  }

  addInitialFilters(filterValues: Filter[]) {
    if (this.hasInitialFilters) return;
    const priority = filterValues.find(
      (fv) => fv.column === 'Priority_Description',
    );
    if (priority) {
      this.priorityStore.setInitialValues(priority);
    }

    const status = filterValues.find(
      (fv) => fv.column === 'status_description',
    );
    if (status) {
      // status_description has a 'NOT_IN' operand, thus not all options come through the filter.
      // Pass static options
      this.statusStore.setInitialValues(status, statusDescriptionOptions);
    }

    const ticketType = filterValues.find((fv) => fv.column === 'Ticket_Type');
    if (ticketType) {
      this.ticketTypeStore.setInitialValues(
        ticketType,
        ticketType.values.filter((tt) => tt !== 'Edgeboot'),
      );
    }

    const subticketType = filterValues.find(
      (fv) => fv.column === 'Ticket_SubType',
    );
    if (subticketType) {
      this.ticketSubTypeStore.setInitialValues(subticketType);
    }

    this.hasInitialFilters = true;
  }

  setOnFilterChangeCallback(
    cb: (filters: Filter[], interval: string, count: number) => void,
  ) {
    this.onFilterChangeCB = cb;
  }

  get priorityOptions() {
    return this.priorityStore.options;
  }

  get statusOptions() {
    return this.statusStore.options;
  }

  get ticketTypeOptions() {
    return this.ticketTypeStore.options;
  }

  get ticketSubTypeOptions() {
    return this.ticketSubTypeStore.options;
  }

  get currentSelectionPriority() {
    return this.priorityStore.selections;
  }

  get currentSelectionStatus() {
    return this.statusStore.selections;
  }

  get currentSelectionTicketType() {
    return this.ticketTypeStore.selections;
  }

  get currentSelectionTicketSubtype() {
    return this.ticketSubTypeStore.selections;
  }

  get currentDateFilter() {
    return dateOptions.find(
      ({ value }) => value === this.dateFilter,
    ) as FilterOption;
  }

  get isInitialized() {
    return this.hasInitialFilters;
  }

  togglePriorityOption(action: ActionMeta<FilterOption>) {
    this.priorityStore.select(action);
    this.updateFilters();
  }

  toggleStatusOption(action: ActionMeta<FilterOption>) {
    this.statusStore.select(action);
    this.updateFilters();
  }

  toggleTicketTypeOption(action: ActionMeta<FilterOption>) {
    this.ticketTypeStore.select(action);
    this.updateFilters();
  }

  toggleTicketSubtypeOption(action: ActionMeta<FilterOption>) {
    this.ticketSubTypeStore.select(action);
    this.updateFilters();
  }

  applyDateFilter(option?: FilterOption) {
    if (option && option.value !== 'Default') {
      this.dateFilter = option.value;
    } else {
      this.dateFilter = 'Default';
    }

    this.updateFilters();
  }

  private updateFilters() {
    if (this.onFilterChangeCB) {
      const filters = [
        toJS(this.priorityStore.filter),
        toJS(this.statusStore.filter),
        toJS(this.ticketTypeStore.filter),
        toJS(this.ticketSubTypeStore.filter),
      ].filter(Boolean) as Filter[];

      this.onFilterChangeCB(
        filters,
        this.dateFilter === 'Default' ? 'DAY' : this.dateFilter,
        this.dateFilter === 'Default' ? 7 : 1,
      );
    }
  }
}
