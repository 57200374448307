import { z } from 'zod';
import {
  ContactSubFormSchema,
  NonStandardToolsFieldSchema,
  OptionLabelValueSchema,
} from '../../utils/schemas';
import { TicketDetailForms } from '../DefinedFormTypes';

export const POTSGraniteLineFormSchema = z.object({
  formType: z.literal(TicketDetailForms.enum.POTSGraniteLine),

  whenDidIssueBegin: OptionLabelValueSchema,
  issueDescription: z.string().trim().nonempty('Required'),
  techToTestWith: z.discriminatedUnion('techToTestWith', [
    z.object({ techToTestWith: z.literal('Yes') }),
    z.object({ techToTestWith: z.literal('No') }),
  ]),
  itTestingContacts: z.array(ContactSubFormSchema),
  nonStandardTools: NonStandardToolsFieldSchema,
});

export type POTSGraniteLineForm = z.infer<typeof POTSGraniteLineFormSchema>;
