import { changePassword } from 'api/auth/auth';
import showToast from 'components/Toast/Toast';
import { useMutation } from 'react-query';

export const useChangePasswordMutation = () => {
  return useMutation(changePassword, {
    onSuccess: ({ message }: { message: string }) => {
      showToast.confirmation({
        message: message,
      });
    },
    onError: (error: { message: string }) => {
      console.error(error, 'error');
      showToast.error({
        message: error.message || 'Something went wrong!',
      });
    },
  });
};
