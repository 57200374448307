import { useEffect, useRef, useState } from 'react';
import { useOpenQuoteContext } from '../Wizard/OpenQuoteWizardReducer';
import { OpenQuoteLayout } from '../BaseComponents/OpenQuoteLayout';
import { Link } from 'react-router-dom';
import { QuoteProduct } from '../schemas/Product';
import Divider from 'components/Divider';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useModal } from 'hooks/useModal';
import AddProductDialog from '../BaseComponents/AddProductDialog';
import AddProductConfirmationDialog from './AddProductConfirmationDialog';
import { InformationCircle } from 'react-ionicons';
import AddLocationDialog from '../BaseComponents/AddLocationDialog';
import DeleteProductDialog from '../AddProducts/DeleteProductDialog';
import showToast from 'components/Toast/Toast';
import { observer } from 'mobx-react-lite';
import PricingRequestedDialog from '../AddProducts/PricingRequestedDialog';
import ProductItem from '../BaseComponents/ProductItem';

export const Review = observer(() => {
  const { openQuoteStore, wizardStore } = useOpenQuoteContext();
  const [productName, setProductName] = useState<string | null>('Product');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const promiseRef = useRef<{
    resolve: (data: boolean) => void;
  } | null>(null);
  const productRefs = useRef<Array<HTMLDivElement | null>>([]);

  const confirmDialog = () => {
    return new Promise((resolve) => {
      promiseRef.current = { resolve };
      setIsDeleteModalOpen(true);
    });
  };

  const handleConfirmation = () => {
    if (promiseRef.current) {
      promiseRef.current.resolve(true);
      promiseRef.current = null;
      setIsDeleteModalOpen(false);
    }
  };

  const handleClose = () => {
    if (promiseRef.current) {
      promiseRef.current.resolve(false);
      promiseRef.current = null;
      setIsDeleteModalOpen(false);
    }
  };

  useEffect(() => {
    wizardStore.setStep(2);
  }, [wizardStore]);

  const {
    open: openAddProduct,
    openWithProps: openAddProductWithProps,
    ...modalProps
  } = useModal<QuoteProduct>();

  const { openWithProps: openProductConfirmation, ...confirmationDialogProps } =
    useModal<{ product?: QuoteProduct }>();

  const { open: openAddLocation, ...addLocationDialogProps } = useModal();

  const { open: openCheckAvailabilityModal } = useModal<QuoteProduct>();

  const { open: openPricingRequestedModal, ...pricingRequestedModalProps } =
    useModal<QuoteProduct>();

  const handleAddProduct = (product: QuoteProduct) => {
    openQuoteStore.addProduct(product);
    showToast.confirmation({
      message: 'New product added',
    });
    openProductConfirmation({ product: product });
  };

  const handleDeleteProduct = async (product: QuoteProduct) => {
    const result = await confirmDialog();
    if (result) {
      openQuoteStore.deleteProduct(product);
      showToast.confirmation({
        message: 'Product has been successfully deleted',
      });
    }
  };

  const onProductEdit = (product: QuoteProduct) => {
    openQuoteStore.editProduct(product);
    showToast.confirmation({
      message: 'Product has been successfully edited',
    });
  };

  const scrollToElement = (index: number) => {
    productRefs.current.forEach((el) => {
      if (el) {
        el.classList.remove('border', 'border-status-info-default');
      }
    });

    const targetElement = productRefs.current?.[index];

    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      targetElement.classList.add('border', 'border-status-info-default');
    }
  };

  return (
    <OpenQuoteLayout>
      <div className="flex flex-col gap-16 rounded bg-background-base-surface-2 p-6 shadow">
        <div className="flex w-full flex-col items-start justify-start">
          <h2 className="mb-4 text-2xl font-bold text-content-base-default">
            Review your product requirements & locations
          </h2>
          <div className="flex w-full flex-col gap-6">
            {openQuoteStore.locationsByProduct.map((lbp, index) => (
              <ProductItem
                ref={(el) => (productRefs.current[index] = el)}
                key={lbp.product.id}
                product={lbp.product}
                locations={lbp.locations}
                onProductDelete={(product: QuoteProduct, name?: string) => {
                  if (name) setProductName(name);
                  handleDeleteProduct(product);
                }}
                onProductEdit={(product: QuoteProduct) => {
                  openAddProductWithProps(product);
                }}
              />
            ))}
          </div>
        </div>
        <Divider />
        <div className="flex gap-4">
          <Link
            to="../products-to-locations"
            className="button secondary large"
          >
            View product assignment
          </Link>
          <GraniteButton variant="secondary" onClick={openAddProduct}>
            Add new product
          </GraniteButton>
          <GraniteButton variant="secondary" onClick={openAddLocation}>
            Add new location
          </GraniteButton>
        </div>
      </div>
      <div className="sticky top-8 flex flex-col gap-6 rounded bg-background-base-surface-2 p-6 shadow">
        {!openQuoteStore.eachProductHasLocation && (
          <div className="flex items-center gap-4 rounded border border-status-info-default bg-background-base-surface-1 p-4">
            <InformationCircle width="22px" height="22px" color="#9796F3" />
            <span className="text-base font-bold text-content-base-default">
              All products must have at least one location.
            </span>
          </div>
        )}
        {openQuoteStore.products.length > 0 && (
          <div className="flex flex-col gap-2 bg-background-base-surface-1 p-4">
            <span className="text-base font-bold text-content-base-subdued">
              Your products
            </span>
            <div className="flex min-h-full flex-col">
              {openQuoteStore.products.map((product, index) => (
                <>
                  <div
                    key={product.id}
                    className="flex w-full items-center justify-between"
                  >
                    <p className="text-base font-bold text-content-base-default">
                      {product.name}
                    </p>
                    <GraniteButton
                      onClick={() => scrollToElement(index)}
                      variant="secondary"
                      size="small"
                    >
                      View
                    </GraniteButton>
                  </div>
                  {index !== openQuoteStore.products.length - 1 && (
                    <Divider className="my-2" />
                  )}
                </>
              ))}
            </div>
          </div>
        )}
        <div className="flex flex-col gap-4">
          {!openQuoteStore.eachProductHasLocation && (
            <Link
              to="../products-to-locations"
              className="button primary large"
            >
              View product assignments
            </Link>
          )}
          <GraniteButton
            size="large"
            disabled={
              openQuoteStore.products?.length === 0 ||
              !openQuoteStore.eachProductHasLocation
            }
            onClick={openCheckAvailabilityModal}
            className="grow"
          >
            Check availability
          </GraniteButton>
        </div>
      </div>
      <AddLocationDialog {...addLocationDialogProps} />
      <DeleteProductDialog
        handleConfirm={handleConfirmation}
        isOpen={isDeleteModalOpen}
        productName={productName!}
        close={handleClose}
      />

      <AddProductConfirmationDialog
        {...confirmationDialogProps}
        {...confirmationDialogProps.dynamicProps}
      />
      <AddProductDialog
        {...modalProps}
        onEditProduct={onProductEdit}
        onAddProduct={handleAddProduct}
      />
      {/* <CheckAvailabilityDialog
        {...checkAvailabilityModalProps}
        openPricingRequestedModal={openPricingRequestedModal}
      /> */}
      <PricingRequestedDialog {...pricingRequestedModalProps} />
    </OpenQuoteLayout>
  );
});
