import React, { Fragment, useMemo, useState } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  StackedCell,
  StackedCellMainContent,
  StackedCellSubtitle,
  TableCell,
} from '../../../components/Table/Table.styles';
import clsx from 'clsx';
import Pagination from 'components/Table/Pagination';
import Divider from '../../../components/Divider';
import { BulkLocations, Location } from './BulkAddLocations/schemas';
import { styled } from 'styled-components';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import ChronicFlag from '../BaseComponents/ChronicFlag';

const getRowIdx = (index: number) => (index + 1) * 2;

const LocationsTableStyle = styled.div<{
  $hasChronic: boolean;
  $hasParentAccount: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ $hasChronic, $hasParentAccount }) =>
    $hasChronic && $hasParentAccount
      ? '10px 2fr 2fr 3fr 1fr 3fr'
      : ($hasChronic && !$hasParentAccount) ||
          (!$hasChronic && $hasParentAccount)
        ? '10px 2fr 2fr 1fr 3fr'
        : '10px 3fr 3fr 3fr'};
  grid-auto-columns: auto;
  column-gap: 48px;
  grid-template-rows: auto;
  grid-auto-rows: 56px 1px;
`;

export const LocationsTable = ({
  locations,
  open,
  clearAll,
  hideTitle = false,
  hideClearAll = false,
}: {
  locations: BulkLocations['locations'];
  open?: () => void;
  clearAll?: () => void;
  hideTitle?: boolean;
  hideClearAll?: boolean;
}) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const table = useReactTable({
    data: locations,
    columns: [],
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  });

  const currentPageRows = table.getPaginationRowModel().rows;

  const hasChronicInCurrentPage = useMemo(
    () => currentPageRows.some((row) => row.original.is_chronic),
    [currentPageRows],
  );

  const tableHeaders: ColumnDef<Location>[] = [
    ...(locations[0].parent_account
      ? [
          {
            id: 'parent_account',
            header: 'Parent #',
            accessorKey: 'parent_account',
            enableSorting: false,
          },
        ]
      : []),
    {
      id: 'childAccount',
      header: 'Child #',
      accessorKey: 'childAccount',
      enableSorting: false,
    },
    {
      id: 'site',
      header: 'Address',
      accessorKey: 'site',
      accessorFn: (row: Location) => row,
      enableSorting: false,
      cell: (cellCtx) => {
        return (
          <StackedCell className="w-full">
            <StackedCellMainContent>
              {cellCtx.row.original.site?.address_line_1}
            </StackedCellMainContent>
            <StackedCellSubtitle>
              {[
                cellCtx.row.original.site?.city,
                cellCtx.row.original.site?.state,
                cellCtx.row.original.site?.zip,
              ].join(', ')}
            </StackedCellSubtitle>
          </StackedCell>
        );
      },
    },
    ...(hasChronicInCurrentPage
      ? [
          {
            id: 'is_chronic',
            header: '',
            accessorKey: 'is_chronic',
            accessorFn: (row: Location) => row,
            enableSorting: false,
            cell: (cellCtx: { row: { original: { is_chronic: boolean } } }) => {
              return cellCtx.row.original.is_chronic ? <ChronicFlag /> : '';
            },
          },
        ]
      : []),
    {
      id: 'maintenance_window',
      header: 'Maintenance window',
      accessorFn: (row) =>
        row.canTestingBeDoneAnytime
          ? 'Mon-Fri, 8:00AM-5:00PM'
          : row.maintenance_window,
      enableSorting: false,
    },
  ];

  table.setOptions((prev) => ({
    ...prev,
    columns: tableHeaders,
  }));

  return (
    <>
      <div className="grid grid-cols-1 gap-4 bg-background-base-surface-2">
        {!hideTitle && (
          <h2 className={'mb-2 text-2xl font-bold text-white'}>Locations</h2>
        )}
        <div className="grid grid-cols-1 gap-6">
          <div>
            <LocationsTableStyle
              $hasChronic={hasChronicInCurrentPage}
              $hasParentAccount={!!locations?.[0].parent_account}
            >
              {table.getHeaderGroups().map((headerGroup, index) => (
                <Fragment key={headerGroup.id}>
                  <div className="row-[1] mb-6 flex items-center font-bold text-content-base-subdued">
                    #
                  </div>
                  {headerGroup.headers.map((header) => (
                    <div
                      key={header.id}
                      style={{ gridColumnStart: index }}
                      className={clsx(
                        'group row-[1] mb-6 flex items-center font-bold text-content-base-default',
                        header.column.getCanSort() &&
                          'cursor-pointer gap-2 fill-input-content-focus',
                      )}
                      role="columnheader"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </div>
                  ))}
                </Fragment>
              ))}
              {table.getRowModel().rows.map((row, index, rows) => {
                return (
                  <Fragment key={row.id}>
                    <TableCell
                      style={{ gridRow: getRowIdx(index) }}
                      className="!text-content-base-subdued"
                      $minWidth={0}
                    >
                      {table.getState().pagination.pageSize *
                        table.getState().pagination.pageIndex +
                        index +
                        1}
                    </TableCell>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        style={{ gridRow: getRowIdx(index) }}
                        $minWidth={0}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                    {index + 1 < rows.length && (
                      <Divider
                        style={{
                          gridRow: getRowIdx(index) + 1,
                          gridColumnStart: 1,
                          gridColumnEnd: `span ${
                            row.getVisibleCells().length + 1
                          }`,
                        }}
                      />
                    )}
                  </Fragment>
                );
              })}
            </LocationsTableStyle>
            <div className="mt-10 flex">
              <Pagination
                pageCount={table.getPageCount()}
                totalRows={locations.length || 0}
                currentPage={table.getState().pagination.pageIndex + 1 || 1}
                onPageChange={(page: number) => table.setPageIndex(page - 1)}
                currentRowsShown={table.getRowModel().rows.length}
                pageSizeChanged={(page: number) =>
                  table.setPageSize(Number(page))
                }
                hidePageSizeSelection={true}
              />
            </div>
          </div>
        </div>
        {clearAll && (
          <div className="mt-3 flex gap-4">
            <GraniteButton variant="secondary" size="medium" onClick={open}>
              Edit
            </GraniteButton>
            {!hideClearAll && (
              <GraniteButton
                variant="secondary"
                size="medium"
                onClick={() => clearAll()}
              >
                Clear all
              </GraniteButton>
            )}
          </div>
        )}
      </div>
    </>
  );
};
