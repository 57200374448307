import { useMemo, useState } from 'react';
import { OnChangeFn, SortingState } from '@tanstack/react-table';

export interface UseSortedTableProps {
  initialSorting?: SortingState;
}

export interface UseSortedTableReturn {
  sortingQueryParams: { [key: string]: string };
  sortingState: SortingState;
  onSortingChange: OnChangeFn<SortingState>;
}

export const useSortedTable = ({
  initialSorting,
}: UseSortedTableProps): UseSortedTableReturn => {
  const [sortingState, setSortingState] = useState<SortingState>(
    initialSorting ?? [],
  );

  const sortingQueryParams = useMemo(
    () =>
      Object.fromEntries(
        sortingState.map(({ id, desc }) => [
          `${id}_order`,
          desc ? 'desc' : 'asc',
        ]),
      ),
    [sortingState],
  );

  return {
    sortingQueryParams,
    sortingState,
    onSortingChange: setSortingState,
  };
};
