import PageItem from 'components/PageItem/PageItem';
import { Outlet } from 'react-router-dom';

const Settings = () => {
  return (
    <div className="flex h-full flex-col flex-wrap justify-between overflow-hidden lg:flex-row lg:flex-nowrap">
      <div className="w-full rounded-tl rounded-tr bg-blue-700 px-8 py-6 lg:max-w-[375px] lg:rounded-bl lg:rounded-tr-none">
        <p className="mb-8 text-2xl font-bold text-white">Settings</p>
        <p className="text-xs font-bold">Personal</p>
        <div className="flex flex-col gap-6 p-2">
          <PageItem title="API keys" state="active" />
        </div>
      </div>
      <div className="flex-1 rounded-bl rounded-br bg-blue-800 p-8 lg:rounded-bl-none  lg:rounded-tr">
        <Outlet />
      </div>
    </div>
  );
};

export default Settings;
