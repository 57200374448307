import PropTypes from 'prop-types';

interface ListValueItem {
  label: string;
  value: string;
}

export interface ListValuesProps {
  items: ListValueItem[];
}

const ListValues: React.FC<ListValuesProps> = ({ items }) => {
  return (
    <div className="flex w-full flex-col divide-y-[1px] divide-stroke-base-subdued">
      {items.map(({ label, value }) => (
        <div className="flex flex-row justify-between py-2" key={value}>
          <label className="font-bold text-content-base-subdued">{label}</label>
          <label className="text-content-base-default">{value}</label>
        </div>
      ))}
    </div>
  );
};

ListValues.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default ListValues;
