import { isFederalHoliday } from 'date-fns-holiday-us';
import {
  addDays,
  addHours,
  differenceInMinutes,
  eachDayOfInterval,
  isAfter,
  isBefore,
  isEqual,
  isFriday,
  isMonday,
  isSameDay,
  isWeekend,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useCallback, useState } from 'react';
import { mergeDateTimeParts } from '../util/mergeDateTimeParts';
import { DispatchSchedule } from '../../api/techexpress/schemas/SiteDispatchDateScheduleSchemas';
import {
  AbbreviatedDispatchPriorities,
  AbbreviatedDispatchPriority,
} from '../../api/techexpress/schemas/DispatchPrioritySchemas';

export interface UseDispatchPriorityProps {
  dispatchDate: Date;
  accessTime: DispatchSchedule;
  timezone: string;
}

export type UseDispatchPriorityReturn = [
  AbbreviatedDispatchPriority | undefined,
  () => void,
];

/**
 * Gets the difference in days between two dates, excluding weekends and holidays
 */
function getBusinessDaysDifference(startDate: Date, endDate: Date) {
  const start = addDays(startDate, 1);
  if (isSameDay(start, endDate)) return 0;

  const daysBetween = eachDayOfInterval({
    start,
    end: endDate,
  });
  let daysDifference = 0;

  for (const day of daysBetween) {
    if (!isWeekend(day) && !isFederalHoliday(day)) {
      daysDifference += 1;
    }
  }

  return daysDifference;
}

export const useDispatchPriority = ({
  dispatchDate,
  accessTime,
  timezone,
}: UseDispatchPriorityProps): UseDispatchPriorityReturn => {
  const [, setTick] = useState(true);
  const updatePriorityCalc = useCallback(() => setTick((v) => !v), []);
  try {
    return [
      getDispatchPriority(
        dispatchDate,
        zonedAccessTimeToUtc(accessTime, timezone),
        timezone,
      ),
      updatePriorityCalc,
    ];
  } catch (e) {
    return [undefined, updatePriorityCalc];
  }
};

export const zonedAccessTimeToUtc = (
  accessTime: DispatchSchedule,
  timezone: string,
): DispatchSchedule => {
  return {
    ...accessTime,
    start_date: zonedTimeToUtc(accessTime.start_date, timezone),
    ...(accessTime.scheduling_type === 'Requested Window'
      ? { end_date: zonedTimeToUtc(accessTime.end_date, timezone) }
      : {}),
  };
};

/**
 * Calculates the dispatch priority for a given dispatchDate/accessTime/currentTime triplet.
 *
 * This function throws if:
 *  - Hour difference between currentTime and accessTime.start_date is less than 4
 *  - scheduling_type is Requested Window and start_date is after end_date
 *
 *  This function assumes that accessTime dates are on UTC.
 *  If you pass a date/time straight from the TimePicker/DatePicker this function will fail
 *  if the timezone is different than local time. Use `zonedAccessTimeToUtc` first.
 *
 * @param dispatchDate Date of the dispatch, as a Date but the time part is not used
 * @param accessTime Schedule, either window or hard start, only the time part is used
 * @param currentTime Current time to evaluate from, usually `new Date()`
 * @param timezone Timezone of the dispatchDate to calculate start/end of business day
 */
export const getDispatchPriority = (
  dispatchDate: Date,
  accessTime: DispatchSchedule,
  timezone: string,
  currentTime: Date = new Date(),
): AbbreviatedDispatchPriority => {
  const dispatchDateTimeStart = mergeDateTimeParts(
    dispatchDate,
    accessTime.start_date,
  );

  if (
    Math.abs(differenceInMinutes(currentTime, dispatchDateTimeStart)) <
    60 * 4 - 1
  ) {
    throw new Error(
      'currentTime and accessTime.start_date should be at least 4 hours apart',
    );
  }
  if (
    accessTime.scheduling_type === 'Requested Window' &&
    isBefore(accessTime.end_date, accessTime.start_date)
  ) {
    throw new Error('start_date must be before end_date');
  }

  if (isFederalHoliday(dispatchDateTimeStart)) {
    return AbbreviatedDispatchPriorities.enum.P1;
  }

  if (isSameDay(currentTime, dispatchDateTimeStart)) {
    return AbbreviatedDispatchPriorities.enum.P1;
  }

  if (isWeekend(dispatchDateTimeStart)) {
    return AbbreviatedDispatchPriorities.enum.P1;
  }

  // Given this date, what is its UTC representation
  const businessHoursStart = zonedTimeToUtc(
    setMinutes(setHours(dispatchDate, 8), 0),
    timezone,
  );
  const businessHoursEnd = zonedTimeToUtc(
    setMinutes(setHours(dispatchDate, 17), 0),
    timezone,
  );

  const businessDaysDifference = getBusinessDaysDifference(
    currentTime,
    dispatchDateTimeStart,
  );

  if (
    accessTime.scheduling_type === 'Hard Start' &&
    (isBefore(dispatchDateTimeStart, businessHoursStart) ||
      isAfter(dispatchDateTimeStart, businessHoursEnd))
  ) {
    return AbbreviatedDispatchPriorities.enum.P1;
  }

  const isNextNextDayOrLater = businessDaysDifference > 1;

  if (
    isNextNextDayOrLater &&
    accessTime.scheduling_type === 'Requested Window'
  ) {
    const dispatchDateTimeStart = mergeDateTimeParts(
      dispatchDate,
      accessTime.start_date,
    );
    const dispatchDateTimeEnd = mergeDateTimeParts(
      dispatchDate,
      accessTime.end_date,
    );

    // Check if window starts before 8 AM
    if (isBefore(dispatchDateTimeStart, businessHoursStart)) {
      // Check if the dispatch extends beyond 10 AM (2 hours into business hours)
      const twoHoursIntoBusiness = addHours(businessHoursStart, 2); // This is 10 AM
      if (!isBefore(dispatchDateTimeEnd, twoHoursIntoBusiness)) {
        return AbbreviatedDispatchPriorities.enum.P3;
      } else {
        return AbbreviatedDispatchPriorities.enum.P1;
      }
    }

    if (isAfter(dispatchDateTimeEnd, businessHoursEnd)) {
      // Check if the time spent in business hours is less than 1 hour
      const timeInBusinessHours = differenceInMinutes(
        businessHoursEnd,
        dispatchDateTimeStart,
      );
      if (timeInBusinessHours < 60) {
        return AbbreviatedDispatchPriorities.enum.P1;
      } else {
        return AbbreviatedDispatchPriorities.enum.P3;
      }
    }

    // check if next day dispatch entirely between 8 AM and 5 PM
    if (
      isAfter(dispatchDateTimeStart, businessHoursStart) &&
      isBefore(dispatchDateTimeStart, businessHoursEnd)
    ) {
      const dispatchEnd = accessTime.end_date
        ? mergeDateTimeParts(dispatchDate, accessTime.end_date)
        : addHours(dispatchDateTimeStart, 1); // Default duration of 1 hour if end date is not provided

      if (isBefore(dispatchEnd, businessHoursEnd)) {
        return AbbreviatedDispatchPriorities.enum.P3;
      }
    }
  }

  // Check for next day dispatches
  const isNextDay = isSameDay(addDays(currentTime, 1), dispatchDateTimeStart);
  if (isNextDay && accessTime.scheduling_type === 'Requested Window') {
    const dispatchDateTimeStart = mergeDateTimeParts(
      dispatchDate,
      accessTime.start_date,
    );
    const dispatchDateTimeEnd = mergeDateTimeParts(
      dispatchDate,
      accessTime.end_date,
    );

    // Check if window starts before 8 AM
    if (isBefore(dispatchDateTimeStart, businessHoursStart)) {
      // Check if the dispatch extends beyond 10 AM (2 hours into business hours)
      const twoHoursIntoBusiness = addHours(businessHoursStart, 2); // This is 10 AM
      if (!isBefore(dispatchDateTimeEnd, twoHoursIntoBusiness)) {
        return AbbreviatedDispatchPriorities.enum.P2;
      } else {
        return AbbreviatedDispatchPriorities.enum.P1;
      }
    }

    if (isAfter(dispatchDateTimeEnd, businessHoursEnd)) {
      // Check if the time spent in business hours is less than 1 hour
      const timeInBusinessHours = differenceInMinutes(
        businessHoursEnd,
        dispatchDateTimeStart,
      );
      if (timeInBusinessHours < 60) {
        return AbbreviatedDispatchPriorities.enum.P1;
      } else {
        return AbbreviatedDispatchPriorities.enum.P2;
      }
    }

    // check if next day dispatch entirely between 8 AM and 5 PM
    if (
      (isEqual(dispatchDateTimeStart, businessHoursStart) ||
        isAfter(dispatchDateTimeStart, businessHoursStart)) &&
      isBefore(dispatchDateTimeStart, businessHoursEnd)
    ) {
      const dispatchEnd = accessTime.end_date
        ? mergeDateTimeParts(dispatchDate, accessTime.end_date)
        : addHours(dispatchDateTimeStart, 1); // Default duration of 1 hour if end date is not provided

      if (
        isEqual(dispatchEnd, businessHoursEnd) ||
        isBefore(dispatchEnd, businessHoursEnd)
      ) {
        return AbbreviatedDispatchPriorities.enum.P2;
      }
    }
  }

  if (accessTime.scheduling_type === 'Requested Window') {
    const dispatchDateTimeEnd = mergeDateTimeParts(
      dispatchDate,
      accessTime.end_date,
    );
    const isFinishingAfter17PM = isAfter(dispatchDateTimeEnd, businessHoursEnd);
    if (isFinishingAfter17PM) {
      return AbbreviatedDispatchPriorities.enum.P1;
    }
  }

  const cutoffTime = zonedTimeToUtc(
    setMilliseconds(setSeconds(setMinutes(setHours(currentTime, 15), 0), 0), 0),
    'America/New_York',
  );

  const isAfterCutoffTime = isAfter(currentTime, cutoffTime);
  // if the request is made on Friday after cutoff, Monday dispatches should be P1
  if (
    isFriday(currentTime) &&
    isAfterCutoffTime &&
    isMonday(dispatchDateTimeStart)
  ) {
    return AbbreviatedDispatchPriorities.enum.P1;
  }

  // If the request is made on Friday before cutoff, Monday dispatches should be P2
  const isBeforeCutoffTime = isBefore(currentTime, cutoffTime);
  if (
    isFriday(currentTime) &&
    isBeforeCutoffTime &&
    isMonday(dispatchDateTimeStart)
  ) {
    return AbbreviatedDispatchPriorities.enum.P2;
  }

  const isNextNextDay = isSameDay(
    addDays(currentTime, 2),
    dispatchDateTimeStart,
  );

  if (isNextNextDay && isAfterCutoffTime) {
    return AbbreviatedDispatchPriorities.enum.P2;
  }

  if (isNextDay && accessTime.scheduling_type === 'Hard Start') {
    if (isBeforeCutoffTime) {
      return AbbreviatedDispatchPriorities.enum.P2;
    } else return AbbreviatedDispatchPriorities.enum.P1;
  }

  return AbbreviatedDispatchPriorities.enum.P3;
};
