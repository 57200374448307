import IconMapper from 'components/IconMapper';
import { GraniteButton } from 'components/V2/Button/GraniteButton';

interface HeaderProps {
  address: string;
  productType: 'Broadband' | 'DIA';
  isCollapsed: boolean;
  onToggleCollapse: () => void;
  hasPricingTiers?: boolean;
}

const Header = ({
  address,
  productType,
  isCollapsed,
  onToggleCollapse,
  hasPricingTiers = false,
}: HeaderProps) => {
  return (
    <div className="flex items-center gap-4">
      <div className="flex min-w-0 flex-grow items-center text-xl font-bold text-content-base-default">
        <span className="truncate">{address}</span>
        <div
          className={`ml-4 whitespace-nowrap rounded-lg px-2 py-1 text-xs font-bold text-black ${
            !hasPricingTiers
              ? 'bg-neutral-500'
              : productType === 'Broadband'
                ? 'bg-green-300'
                : 'bg-blue-400'
          }`}
        >
          {productType === 'Broadband' ? 'Broadband circuit' : 'DIA circuit'}
        </div>
      </div>
      <GraniteButton
        onClick={onToggleCollapse}
        variant="ghost"
        className="!box-border text-content-base-default"
      >
        {!isCollapsed ? 'Collapse' : 'Expand'}

        <IconMapper
          color="inherit"
          name={!isCollapsed ? 'chevronUp' : 'chevronDown'}
        />
      </GraniteButton>
    </div>
  );
};

export default Header;
