import Divider from 'components/Divider';
import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ModalParams } from 'hooks/useModal';
import { SettingsOutline } from 'react-ionicons';
import { getLabelForIpBlock, getTermLabel } from '../utils';
import { QuoteAPIProduct } from '../../../api/accessexpress/schema';
import { observer } from 'mobx-react-lite';

export interface MinimumRequirementsProps extends ModalParams {
  requirement: QuoteAPIProduct;
}

export const MinimumRequirementsDialog = observer(
  ({ requirement, isOpen, close }: MinimumRequirementsProps) => {
    return (
      <Modal isVisible={isOpen} close={close}>
        <div className="flex h-auto w-[624px] flex-col items-start justify-start gap-12 rounded bg-background-base-surface-2 p-8">
          <div className="flex items-center justify-center gap-2">
            <SettingsOutline color={'#F8FAFC'} width={'21px'} height={'21px'} />
            <p className="text-2xl font-bold text-content-base-default">
              Minimum requirements
            </p>
          </div>
          <div className="flex w-full flex-col items-start justify-start rounded bg-background-base-surface-1 ">
            <div className="flex w-full flex-col items-start justify-start gap-2 p-4">
              <div className="grid w-full grid-cols-2">
                <p className="text-base font-bold text-content-base-subdued">
                  Access type
                </p>
                <p className="text-base font-bold text-content-base-default">
                  {requirement.access_type}
                </p>
              </div>
              <Divider className="w-full" />
              <div className="grid w-full grid-cols-2">
                <p className="text-base font-bold text-content-base-subdued">
                  Minimum speed
                </p>
                <p className="text-base font-bold text-content-base-default">
                  {requirement.product_type === 'Broadband'
                    ? `${requirement.min_download} Mbps / ${requirement.min_upload} Mbps`
                    : `${requirement.minimum_connection_speed} Mbps`}
                </p>
              </div>
              <Divider className="w-full" />
              <div className="grid w-full grid-cols-2">
                <p className="text-base font-bold text-content-base-subdued">
                  IP type
                </p>
                <p className="text-base font-bold text-content-base-default">
                  {requirement.ip_type === 'STATIC'
                    ? 'Static IP'
                    : 'Dynamic IP'}
                </p>
              </div>
              <Divider className="w-full" />
              {requirement.ip_block && (
                <>
                  <div className="grid w-full grid-cols-2">
                    <p className="text-base font-bold text-content-base-subdued">
                      IP block
                    </p>
                    <p className="text-base font-bold text-content-base-default">
                      {getLabelForIpBlock(requirement.ip_block)}
                    </p>
                  </div>
                  <Divider className="w-full" />
                </>
              )}
              <div className="grid w-full grid-cols-2">
                <p className="text-base font-bold text-content-base-subdued">
                  Term length
                </p>
                <p className="text-base font-bold text-content-base-default">
                  {getTermLabel(requirement.term)}
                </p>
              </div>
            </div>
          </div>
          <GraniteButton variant="secondary" size="large" onClick={close}>
            Close
          </GraniteButton>
        </div>
      </Modal>
    );
  },
);
