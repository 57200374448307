import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';

export const WhatHappensAfterModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  return (
    <Modal isVisible={isOpen} close={close} className="w-[624px]">
      <div className="flex flex-col gap-8 rounded-lg rounded-t-lg bg-background-base-surface-2 p-8">
        <div className="flex flex-col items-start justify-start gap-2">
          <p className="text-2xl font-bold text-content-base-default">
            What happens when i submit a quote request?
          </p>
        </div>
        <div className="flex flex-col gap-12">
          <p className="text-base font-bold">
            You won&apos;t be charged anything for submitting a quote request.
            After you submit your request, our team will review your
            requirements and contact you to deliver a formal quote.
          </p>
          <div className="flex items-start justify-start gap-4">
            <GraniteButton variant="primary" onClick={close} size="large">
              Close
            </GraniteButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
