import { useEffect, useState } from 'react';

export const useElementPresence = (selector: string) => {
  const [isPresent, setIsPresent] = useState(false);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          const element = document.querySelector(selector);
          if (element) {
            setIsPresent(true);
          } else {
            setIsPresent(false);
          }
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [selector]);

  return isPresent;
};
