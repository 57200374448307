import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

export interface LoaderGraniteProps {
  className?: string;
  animationClassname?: string;
  width?: string;
  height?: string;
}

const Loader = ({
  className,
  animationClassname,
  height = 'h-10',
  width = 'w-10',
}: LoaderGraniteProps) => {
  return (
    <div className={clsx(className, 'flex items-center justify-center')}>
      <div
        className={clsx(
          `animate-spin rounded-full border-4 border-t-4 border-solid border-black border-t-button-background-primary-default`,
          animationClassname,
          height,
          width,
        )}
      ></div>
    </div>
  );
};

Loader.propTypes = {
  text: PropTypes.string,
};

export default Loader;
