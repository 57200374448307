import { useEffect, useMemo } from 'react';
import Joyride, { Step } from 'react-joyride';
import { StepDetail, TourSteps, getStepDetails } from './steps';
import { useProductTourContext } from 'context/ProductTourContext';
import { CustomToolTip } from './CustomTooltip';
import WelcomeModal from './WelcomeModal';
import { useQuery } from 'react-query';
import { ProductTourResponse } from 'api/producttour/schemas';
import { getProductTourProgress } from 'api/producttour/api';
import { useFeatureFlags } from 'feature-flags';
import ProgressTracker from './ProgressTracker';
import CustomBeacon from './CustomBeacon';
import { TokenUserRoles } from 'api/users/schemas/Users';
import { useAuthUser } from 'hooks/useAuthUser';

const ProductTour: React.FC = () => {
  const { flags } = useFeatureFlags();
  const { roles } = useAuthUser();

  const { enableProductTour } = useMemo(() => {
    return {
      enableProductTour:
        flags.PRODUCT_TOUR_ENABLED && !roles.includes(TokenUserRoles.PROSPECT),
    };
  }, [flags.PRODUCT_TOUR_ENABLED, roles]);

  const { data: tourProgress } = useQuery<ProductTourResponse>(
    'productTour',
    getProductTourProgress,
    {
      enabled: enableProductTour,
    },
  );

  const {
    running,
    setRunning,
    setStepHistory,
    setIsModalOpen,
    isModalOpen,
    stepIndex,
    setProgressData,
  } = useProductTourContext();

  useEffect(() => {
    if (tourProgress && tourProgress?.length > 0) {
      setProgressData(tourProgress);
    }
    if (tourProgress && tourProgress.length === 0 && enableProductTour) {
      setStepHistory([TourSteps.WELCOME_MODAL_STEP]);
      setIsModalOpen(true);
      setRunning(true);
      setProgressData(tourProgress);
    }
  }, [
    tourProgress,
    setStepHistory,
    setIsModalOpen,
    setRunning,
    setProgressData,
    enableProductTour,
  ]);

  const steps: StepDetail[] = getStepDetails();

  const formattedSteps: Step[] = steps.map((step: StepDetail) => ({
    content: step.content,
    placement: step.placement,
    styles: {
      spotlight: {
        borderRadius: step.spotLightRadius || 8,
      },
    },
    disableScrolling: step.disableScrolling,
    offset: -8,
    target: step.target,
    spotlightPadding: step.spotlightPadding || 0,
    tooltipComponent: () => (
      <CustomToolTip
        title={step.title}
        subtitle={step.content}
        step={step.step}
        steps={steps}
        className={step.className}
      />
    ),
    beaconComponent: CustomBeacon,
  }));

  const handleModalClose = () => {
    setIsModalOpen(false);
    setStepHistory([TourSteps.REVISIT_LATER_STEP]);
  };

  const handleModalGo = () => {
    setIsModalOpen(false);
    setStepHistory([1]);
  };

  return (
    <>
      {isModalOpen && (
        <WelcomeModal
          isOpen={isModalOpen}
          close={handleModalClose}
          onGo={handleModalGo}
        />
      )}
      <Joyride
        steps={formattedSteps}
        run={running && !isModalOpen}
        continuous
        showSkipButton
        stepIndex={stepIndex}
        styles={{
          options: {
            arrowColor: '#222231',
          },
        }}
      />
      {running &&
        stepIndex !== TourSteps.WELCOME_MODAL_STEP &&
        tourProgress &&
        tourProgress?.length > 0 && (
          <div
            className={`fixed bottom-8 right-8 z-[200] w-[314px] rounded bg-background-base-surface-1 shadow-lg`}
          >
            <ProgressTracker />
          </div>
        )}
    </>
  );
};

export default ProductTour;
