import { HelpCircle } from 'react-ionicons';
import React, { Fragment, ReactNode, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';

export const FieldTooltip = ({
  children,
  title,
  placement = 'top-end',
}: {
  children: ReactNode;
  title?: string;
  placement?: Placement;
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const triggerEltRef = useRef<HTMLDivElement>(null);
  const popperEltRef = useRef<HTMLDivElement>(null);

  const { styles, attributes } = usePopper(
    triggerEltRef?.current,
    popperEltRef?.current,
    {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-25, -150],
          },
        },
      ],
    },
  );

  return (
    <Fragment>
      <div
        ref={triggerEltRef}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        className="z-auto"
      >
        <HelpCircle width="24px" height="24px" color="#94A3B8" />
      </div>
      <div
        ref={popperEltRef}
        style={{ ...styles.popper, zIndex: 99999 }}
        {...attributes.popper}
      >
        {isVisible && (
          <div className="w-[328px] rounded-lg bg-background-base-surface-0 p-5 text-content-base-subdued shadow-elevation3">
            {title && (
              <p className="mb-1 text-base font-bold text-content-base-default">
                {title}
              </p>
            )}
            {children}
          </div>
        )}
      </div>
    </Fragment>
  );
};
