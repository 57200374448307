import React from 'react';

export const TechActiveDesktopSvg = () => {
  return (
    <svg
      width="110"
      height="24"
      viewBox="0 0 46 24"
      className="tech-active-svg"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.504 9.312H0.064V6.8H10.096V9.312H6.672V18H3.504V9.312ZM20.2683 15.552V18H11.2763V6.8H20.0603V9.248H14.4123V11.136H19.3883V13.504H14.4123V15.552H20.2683ZM27.5946 18.224C26.4213 18.224 25.3653 17.9787 24.4266 17.488C23.4986 16.9867 22.768 16.2933 22.2346 15.408C21.7013 14.5227 21.4346 13.52 21.4346 12.4C21.4346 11.28 21.7013 10.2773 22.2346 9.392C22.768 8.50667 23.4986 7.81867 24.4266 7.328C25.3653 6.82667 26.4213 6.576 27.5946 6.576C28.6186 6.576 29.5413 6.75733 30.3626 7.12C31.184 7.48267 31.8666 8.00533 32.4106 8.688L30.3946 10.512C29.6693 9.63733 28.7893 9.2 27.7546 9.2C27.1466 9.2 26.6026 9.33333 26.1226 9.6C25.6533 9.86667 25.2853 10.2453 25.0186 10.736C24.7626 11.216 24.6346 11.7707 24.6346 12.4C24.6346 13.0293 24.7626 13.5893 25.0186 14.08C25.2853 14.56 25.6533 14.9333 26.1226 15.2C26.6026 15.4667 27.1466 15.6 27.7546 15.6C28.7893 15.6 29.6693 15.1627 30.3946 14.288L32.4106 16.112C31.8666 16.7947 31.184 17.3173 30.3626 17.68C29.5413 18.0427 28.6186 18.224 27.5946 18.224ZM44.4791 6.8V18H41.3111V13.632H36.9911V18H33.8231V6.8H36.9911V11.008H41.3111V6.8H44.4791Z"
        fill="#F8FAFC"
      />
    </svg>
  );
};
