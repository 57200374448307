import { clsx } from 'clsx';
import { FC } from 'react';
import { formatCurrency } from '../../shared/util/formatCurrency';

interface PriceProps {
  total?: number;
  className?: string;
}

export const Price: FC<PriceProps> = ({ total, className = '' }) => (
  <span
    className={clsx(
      className,
      total !== undefined
        ? 'text-content-accent-default'
        : 'text-content-base-subdued',
    )}
  >
    {formatCurrency(total)}
  </span>
);
