import { createPaginatedResponseSchema } from 'api/common-schemas';
import { z } from 'zod';

const InventoryItemSchema = z.object({
  id: z.number(),
  parent_name: z.string(),
  parent_number: z.string(),
  account_number: z.string(),
  account_name: z.string(),
  address: z.string(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  service_type: z.string(),
  vendor: z.string(),
  service_id: z.string(),
  status: z.string(),
  completion_date: z.string(),
  end_date: z.string(),
  btn: z.string().nullable(),
  serial_number: z.string(),
  mac: z.string(),
  ip: z.string(),
  model_number: z.string(),
  tag_number: z.string().nullable(),
  product_offering: z.string(),
  host_status: z.string().nullable(),
});

export type InventoryItem = z.infer<typeof InventoryItemSchema>;

export const InventoryIndexAPIResponseSchema =
  createPaginatedResponseSchema(InventoryItemSchema);

export type InventoryIndexAPIResponse = z.infer<
  typeof InventoryIndexAPIResponseSchema
>;

export const InventoryFilterSearchParamsSchema = z
  .object({
    search: z.string(),
    service_type: z.string(),
    vendor: z.string(),
    status: z.string(),
    completion_date: z.string(),
    end_date: z.string(),
    page: z.number(),
    page_size: z.number(),
  })
  .partial();

export type InventoryFilterSearchParams = z.infer<
  typeof InventoryFilterSearchParamsSchema
>;

export const InventoryRecentActivityRequestSchema = z
  .object({
    date_range: z.string(),
  })
  .partial();

export type InventoryRecentActivityRequest = z.infer<
  typeof InventoryRecentActivityRequestSchema
>;
