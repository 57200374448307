import { Dispatch } from 'react';
import { useOutletContext } from 'react-router-dom';
import { FindLocationOrServiceForm } from '../FindLocationOrService/schemas';
import { TicketDetails } from '../TicketDetails/schemas';
import { DeepPartial } from 'react-hook-form';
import { BulkLocations } from '../FindLocationOrService/BulkAddLocations/schemas';
import { Configurations } from 'api/nocexpress/schemas/ConfigurationsSchema';

export type WizardReducerState =
  | {
      state: 'FindLocationOrService';
      findLocationOrServiceForm?: FindLocationOrServiceForm;
      ticketDetails?: DeepPartial<TicketDetails>;
      services?: Configurations[];
      step: 1;
      bulkLocations?: BulkLocations['locations'];
      serviceOptions?: { label: string; value: string }[];
    }
  | {
      state: 'TicketDetails';
      findLocationOrServiceForm?: FindLocationOrServiceForm;
      ticketDetails?: DeepPartial<TicketDetails>;
      services?: Configurations[];
      step: 2;
      bulkLocations?: BulkLocations['locations'];
      serviceOptions?: { label: string; value: string }[];
    };

export const getWizardInitialState = () =>
  ({
    state: 'FindLocationOrService',
    step: 1,
    findLocationOrServiceForm: undefined,
    ticketDetails: undefined,
    services: undefined,
    partialTicketDetails: undefined,
    bulkLocations: undefined,
    serviceOptions: undefined,
  }) as const;

export type WizardAction =
  | {
      type: 'GoToFindLocationOrService';
      findLocationOrServiceForm?: FindLocationOrServiceForm;
      partialTicketDetails?: DeepPartial<TicketDetails>;
      bulkLocations?: BulkLocations['locations'];
      serviceOptions?: { label: string; value: string }[];
    }
  | {
      type: 'GoToTicketDetails';
      findLocationOrServiceForm?: FindLocationOrServiceForm;
      ticketDetails?: TicketDetails;
      services?: Configurations[];
      bulkLocations?: BulkLocations['locations'];
      partialTicketDetails?: DeepPartial<TicketDetails>;
      serviceOptions?: { label: string; value: string }[];
    }
  | {
      type: 'SaveDetailsPartial';
      partialTicketDetails: DeepPartial<TicketDetails>;
    }
  | {
      type: 'ResubmitWithSelectedIdentifier';
      findLocationOrServiceForm?: DeepPartial<FindLocationOrServiceForm>;
      ticketDetails?: DeepPartial<TicketDetails>;
    }
  | {
      type: 'ResubmitWithPriorTicketAndInitialDescription';
      ticketDetails?: DeepPartial<TicketDetails>;
      findLocationOrServiceForm?: DeepPartial<FindLocationOrServiceForm>;
    }
  | {
      type: 'ResubmitWithPriorTicketAndAddress';
      ticketDetails?: DeepPartial<TicketDetails>;
      findLocationOrServiceForm?: DeepPartial<FindLocationOrServiceForm>;
    };

export const wizardReducer = (
  state: WizardReducerState,
  action: WizardAction,
): WizardReducerState => {
  switch (action.type) {
    case 'ResubmitWithSelectedIdentifier':
      if (!action.findLocationOrServiceForm) {
        throw new Error('Form data must be provided for resubmission');
      }
      return {
        ...getWizardInitialState(),
        findLocationOrServiceForm: {
          ...action.findLocationOrServiceForm,
          //@ts-expect-error workaround
          type: 'site',
        },
        ticketDetails: {
          prior_ticket: action?.ticketDetails?.prior_ticket,
          initialDescription: action?.ticketDetails?.initialDescription,
        },
      };
    case 'ResubmitWithPriorTicketAndAddress':
      return {
        ...getWizardInitialState(),
        findLocationOrServiceForm: {
          ...action.findLocationOrServiceForm,
          //@ts-expect-error workaround
          type: 'site',
        },
        ticketDetails: {
          prior_ticket: action?.ticketDetails?.prior_ticket,
        },
      };
    case 'ResubmitWithPriorTicketAndInitialDescription':
      return {
        ...getWizardInitialState(),
        ticketDetails: {
          prior_ticket: action?.ticketDetails?.prior_ticket,
          initialDescription: action?.ticketDetails?.initialDescription,
        },
      };
    case 'GoToFindLocationOrService':
      return {
        ...getWizardInitialState(),
        findLocationOrServiceForm: action.findLocationOrServiceForm,
        ticketDetails: state.ticketDetails,
        bulkLocations: state.bulkLocations,
        serviceOptions: state.serviceOptions,
      };
    case 'GoToTicketDetails':
      return {
        ...state,
        state: 'TicketDetails',
        findLocationOrServiceForm: action.findLocationOrServiceForm,
        services: action.services,
        ticketDetails: state.ticketDetails,
        step: 2,
        bulkLocations: action.bulkLocations,
        serviceOptions: action.serviceOptions,
      };

    case 'SaveDetailsPartial':
      if (state.state !== 'TicketDetails')
        throw new Error(
          'SaveDetailsPartial can only be called from the TicketDetails page',
        );
      return {
        ...state,
        ticketDetails: action.partialTicketDetails,
      };
  }
};

export type OpenNetOpsTicketWizardContext = {
  dispatch: Dispatch<WizardAction>;
  state: WizardReducerState;
};

export const useOpenNetOpsTicketContext = () => {
  return useOutletContext<OpenNetOpsTicketWizardContext>();
};
