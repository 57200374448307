import { z } from 'zod';
import { AnyTicketTypes, ProductTypes } from '../BaseComponents/schemas';
import {
  LETTERS_SPACES_HYPENS_ERROR_LABEL,
  LETTERS_SPACES_SPECIAL_CHARACTERS_ERROR_LABEL,
  UP_TO_6_DIGITS_ERROR_LABEL,
  VALID_PHONE_ERROR_LABEL,
  VALID_NUMBERS_ERROR_LABEL,
  ALPHANUMERIC_REGEX_ERROR,
} from '../../../shared/constants/error-labels';
import {
  ALPHANUMERIC_WITH_COMMAS_AND_SPACES_VALIDATOR,
  LETTERS_SPACES_HYPENS_REGEXP,
  UP_TO_6_DIGITS_REGEXP,
  VALID_PHONE_NUMBER,
  NUMBERS_ONLY_REGEXP,
  ALPHANUMERIC_REGEX,
  LETTERS_SPACES_DOTS_SPECIAL_CHARACTERS,
} from 'shared/constants/validation-regex-constants';
import { SiteDispatchDateScheduleWithValidationsSchema } from '../../../api/techexpress/schemas/SiteDispatchDateScheduleSchemas';
import { LocationsArray } from '../GetStarted/schemas';

const LocalContactSchema = z.object({
  name: z
    .string()
    .nonempty('Required')
    .max(100, 'Only 100 characters allowed')
    .regex(LETTERS_SPACES_HYPENS_REGEXP, {
      message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
    }),
  email: z.string().email().optional().or(z.literal('')),
  phoneNumber: z
    .string()
    .nonempty(VALID_PHONE_ERROR_LABEL)
    .length(10, VALID_PHONE_ERROR_LABEL)
    .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
  extension: z
    .string()
    .max(6, 'Only 6 characters allowed')
    .regex(UP_TO_6_DIGITS_REGEXP, UP_TO_6_DIGITS_ERROR_LABEL)
    .optional()
    .or(z.literal('')),
});

export type LocalContact = z.infer<typeof LocalContactSchema>;

const SingleLocationSchema = z.object({
  type: z.literal('single'),
  locationName: z
    .string()
    .nonempty('Required')
    .max(100, 'Only 100 characters allowed')
    .regex(LETTERS_SPACES_DOTS_SPECIAL_CHARACTERS, {
      message: LETTERS_SPACES_SPECIAL_CHARACTERS_ERROR_LABEL,
    }),
  locationNumber: z
    .string()
    .regex(ALPHANUMERIC_REGEX, ALPHANUMERIC_REGEX_ERROR)
    .or(z.literal(''))
    .optional(),
  localContact: z.array(LocalContactSchema),
});

const MultiTypeSchema = z.object({
  type: z.literal('multi'),
  locations: LocationsArray,
});

export const ReviewTicketBaseFormSchema = z.object({
  type: z.union([z.literal('single'), z.literal('multi')]),
  // Ticket Details
  product: ProductTypes,
  ticketType: AnyTicketTypes,
  priority: z.enum(['P1', 'P2', 'P3']),
  specialInstructions: z.string().max(1000, 'Only 1000 characters allowed'),
  customer_ticket_number: z.string(),
  prior_ticket: z
    .string()
    .regex(NUMBERS_ONLY_REGEXP, VALID_NUMBERS_ERROR_LABEL)
    .optional()
    .or(z.literal('')),
  customer_preferred_tech: z.boolean().optional(),
  po_number: z
    .string()
    .max(25, 'Only 25 characters allowed')
    .regex(
      ALPHANUMERIC_WITH_COMMAS_AND_SPACES_VALIDATOR,
      `Write it in the correct format. (DXX1234, DXX1234)`,
    )
    .optional()
    .or(z.literal('')),
  scopeOfWork: z.string().nonempty('Required'),
  scopeOfWorkInstructions: z.string(),
  nonStandardTools: z.discriminatedUnion('isNeeded', [
    z.object({ isNeeded: z.literal(false) }),
    z.object({
      isNeeded: z.literal(true),
      nonStandardTools: z.string(),
    }),
  ]),

  attachments: z.array(z.any()).optional(), // TODO: Validate file attachments > 15MB

  // Notifications
  isSendNotificationsViaEmailEnabled: z.boolean().optional(),
  recipients: z.array(z.object({ email: z.string().email().nonempty() })),
  guideAttachments: z.array(z.any()).optional(),
  content: z.discriminatedUnion('type', [
    SingleLocationSchema,
    MultiTypeSchema,
  ]),
});

export const ReviewTicketFormSchema = z.intersection(
  ReviewTicketBaseFormSchema,
  SiteDispatchDateScheduleWithValidationsSchema,
);

export type ReviewTicketForm = z.infer<typeof ReviewTicketFormSchema>;
