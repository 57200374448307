import Divider from 'components/Divider';
import { MapOutline, SettingsOutline } from 'react-ionicons';
import { ProductCard, ProductDetails } from './ProductCard';
import { useModal } from 'hooks/useModal';
import { MinimumRequirementsDialog } from './MinimumRequirementsDialog';
import { LocationsDialog } from './LocationsDialog';
import { useState } from 'react';
import { QuoteAPIProduct } from '../../../api/accessexpress/schema';
import { LocationProducts } from '../Wizard/ProductSelectionStore';
import { getProductRequirementLabel } from '../utils';
import { observer } from 'mobx-react-lite';

interface ProductPackageProps {
  locations: LocationProducts[];
  requirement: QuoteAPIProduct;
}

const productDetails: ProductDetails[] = [
  { type: 'recommended', averageMRC: 85.0, averageBandwidth: 100 },
  { type: 'performance', averageMRC: 110.0, averageBandwidth: 140 },
  { type: 'budget', averageMRC: 72.0, averageBandwidth: 60 },
];

export const ProductPackage = observer(
  ({ requirement, locations }: ProductPackageProps) => {
    const {
      isOpen: isMinimumRequirementsOpen,
      open: openMinimumRequirements,
      ...minimumRequirementsProps
    } = useModal();
    const {
      isOpen: isLocationsOpen,
      open: openLocations,
      ...locationProps
    } = useModal();

    const [selectedProduct, setSelectedProduct] =
      useState<ProductDetails | null>(null);

    const handleSelection = (product: ProductDetails) => {
      setSelectedProduct(product);
    };

    return (
      <div className="item-start flex w-full flex-col justify-start rounded bg-background-base-surface-1 p-4">
        <div className="mb-4 flex items-center justify-start gap-4">
          <h1 className="text-xl font-bold text-content-base-default">
            {requirement.name}
          </h1>
          <div className="flex h-[24px] w-[130px] items-center justify-center rounded-lg bg-orange-300 text-xs font-bold text-content-flip-default">
            {getProductRequirementLabel(requirement)}
          </div>
        </div>
        <div className="mb-[20px] flex items-center justify-start gap-4">
          <div
            className="flex cursor-pointer items-center justify-center gap-2"
            onClick={openMinimumRequirements}
          >
            <SettingsOutline color={'#F8FAFC'} width={'15px'} height={'16px'} />
            <p className="text-xs font-bold text-content-base-default">
              View minimum requirements
            </p>
          </div>
          <div
            className="flex cursor-pointer items-center justify-center gap-2"
            onClick={openLocations}
          >
            <MapOutline color={'#F8FAFC'} width={'15px'} height={'16px'} />
            <p className="text-xs font-bold text-content-base-default">
              View locations ({locations.length})
            </p>
          </div>
        </div>
        <div className="w-full">
          <Divider />
        </div>
        <div className="mt-6 grid grid-cols-3 gap-6">
          {productDetails.map((p, index) => (
            <ProductCard
              key={index}
              product={p}
              selectedProduct={selectedProduct}
              handleSelection={handleSelection}
            />
          ))}
        </div>
        <MinimumRequirementsDialog
          isOpen={isMinimumRequirementsOpen}
          {...minimumRequirementsProps}
          requirement={requirement}
        />
        <LocationsDialog
          locations={locations}
          isOpen={isLocationsOpen}
          {...locationProps}
        />
      </div>
    );
  },
);
