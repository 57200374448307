import { Modal } from 'components/Modal/Modal';
import React, { useState } from 'react';
import StarRating from './StarRating';
import {
  BaseFeedbackResponseSchema,
  FeedbackSchemaForm,
  FeedbackSchemaRequestType,
  FeedbackSchemaResponse,
  FeedbackSchemaType,
} from './form-schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller } from 'react-hook-form';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import GraniteTextArea from '../../../components/TextArea/TextArea';
import { RadioButtonGranite } from 'components';
import { useMutation } from 'react-query';
import { postTicketFeedback } from 'api/techexpress/api';
import { ticketFeedbackFormToRequest } from './utils';
import showToast from 'components/Toast/Toast';
import clsx from 'clsx';
import { ChevronDown } from 'react-ionicons';
import Divider from 'components/Divider';
import Checkbox from 'components/Checkbox';
import { RefetchType } from './ProvideFeedbackBanner';
import Loader from 'components/Loader';

const ProvideFeedbackModal = ({
  ticketId,
  isOpen,
  close,
  feedback,
  refetch,
}: {
  ticketId: string;
  isOpen: boolean;
  close: () => void;
  feedback?: BaseFeedbackResponseSchema;
  refetch: RefetchType;
}) => {
  const [isAdvancedFeedback, setIsAdvancedFeedback] = useState(false);
  const safeFormat = FeedbackSchemaResponse.safeParse(feedback);
  const [isChecked, setIsChecked] = useState<boolean>(
    safeFormat.success ? safeFormat.data.contact_me_flag : true,
  );
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { errors },
    trigger,
  } = useForm<FeedbackSchemaType>({
    resolver: zodResolver(FeedbackSchemaForm),
    defaultValues: safeFormat.success
      ? {
          contact_me_flag: safeFormat.data.contact_me_flag ? 'yes' : 'no',
          footer_response: safeFormat.data.footer_response,
          techArrivedInTimeRating: safeFormat.data.results.find(
            (item) => item.question_id === 5,
          )?.answer
            ? 'yes'
            : 'no',
          deliverablesRating: safeFormat.data.results.find(
            (item) => item.question_id === 6,
          )?.answer
            ? 'yes'
            : 'no',
          overallRating:
            (safeFormat.data.results.find((item) => item.question_id === 4)
              ?.answer ?? 0) + 1,
          communationRating:
            (safeFormat.data.results.find((item) => item.question_id === 1)
              ?.answer ?? 0) + 1,
          professionalismRating:
            (safeFormat.data.results.find((item) => item.question_id === 2)
              ?.answer ?? 0) + 1,
          qualityRating:
            (safeFormat.data.results.find((item) => item.question_id === 3)
              ?.answer ?? 0) + 1,
        }
      : {
          contact_me_flag: 'yes',
          techArrivedInTimeRating: undefined,
          deliverablesRating: undefined,
        },
  });

  const createTicketFeedbackMutation = useMutation(
    ({ feedback }: { feedback: FeedbackSchemaRequestType }) =>
      postTicketFeedback(ticketId, feedback),
    {
      onSuccess: async () => {
        await refetch();
        showToast.confirmation({
          title: 'Feedback submitted!',
          message: `Ticket #${ticketId}`,
        });
        close();
      },
    },
  );

  const onSubmit = (data: FeedbackSchemaType) => {
    if (createTicketFeedbackMutation.isLoading) return;
    const request: FeedbackSchemaRequestType =
      ticketFeedbackFormToRequest(data);
    createTicketFeedbackMutation.mutate({ feedback: request });
  };

  return (
    <Modal
      isVisible={isOpen}
      close={close}
      className="mt-4 w-full max-w-[624px]"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-start justify-start">
          <div className="flex flex-col items-start justify-start gap-2 rounded-t-lg bg-background-base-surface-3 px-8 py-6">
            <h1 className="text-[28px] font-bold leading-9 text-content-base-default">
              Rate your experience
            </h1>
            <p className="text-base font-semibold text-content-base-subdued">
              Your feedback is important to us. Please rate your dispatch
              experience to help us enhance our future services.
            </p>
          </div>
          <div className="justify-col flex max-h-[620px] w-full flex-col items-start justify-start gap-6 overflow-y-auto bg-background-base-surface-2 p-8 scrollbar-thin scrollbar-track-background-base-surface-1 scrollbar-thumb-stroke-base-subdued">
            <Controller
              name="overallRating"
              control={control}
              render={({ field }) => (
                <StarRating
                  label="Overall quality of dispatch"
                  value={field.value}
                  onRate={(value) => {
                    field.onChange(value);
                    trigger('overallRating');
                  }}
                  error={errors.overallRating?.message}
                  disabled={safeFormat.success}
                />
              )}
            />
            <div
              className={clsx(
                'w-full transition-all duration-500 ease-in-out',
                isAdvancedFeedback
                  ? 'max-h-[235px] opacity-100'
                  : 'pointer-events-none -mt-6 max-h-0 opacity-0',
              )}
            >
              <div className="flex w-full flex-col items-start justify-start gap-4 px-4">
                <Controller
                  name="qualityRating"
                  control={control}
                  render={({ field }) => (
                    <StarRating
                      label="Quality of work (optional)"
                      value={field.value as number}
                      onRate={field.onChange}
                      error={errors.qualityRating?.message}
                      disabled={safeFormat.success}
                    />
                  )}
                />
                <Controller
                  name="professionalismRating"
                  control={control}
                  render={({ field }) => (
                    <StarRating
                      label="Professionalism (optional)"
                      value={field.value as number}
                      onRate={field.onChange}
                      error={errors.professionalismRating?.message}
                      disabled={safeFormat.success}
                    />
                  )}
                />
                <Controller
                  name="communationRating"
                  control={control}
                  render={({ field }) => (
                    <StarRating
                      label="Communication (optional)"
                      value={field.value as number}
                      onRate={field.onChange}
                      error={errors.communationRating?.message}
                      disabled={safeFormat.success}
                    />
                  )}
                />
              </div>
            </div>
            <GraniteButton
              onClick={() => setIsAdvancedFeedback((prevState) => !prevState)}
              variant="tertiary"
            >
              Advanced feedback{' '}
              <span
                className={`${isAdvancedFeedback ? 'rotate-180' : 'rotate-0'}`}
              >
                {' '}
                <ChevronDown />{' '}
              </span>
            </GraniteButton>
            <span className="w-full">
              <Divider />
            </span>
            <RadioButtonGranite
              {...register('deliverablesRating')}
              options={[
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' },
              ]}
              containerClass="!flex !flex-row !gap-6 mt-2"
              selectedValue={watch('deliverablesRating')}
              onChange={(value: string) =>
                setValue('deliverablesRating', value as 'yes' | 'no')
              }
              label="Were all deliverables received? (optional)"
              error={errors.deliverablesRating && 'Required'}
              disabled={safeFormat.success}
            />
            <RadioButtonGranite
              {...register('techArrivedInTimeRating')}
              options={[
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' },
              ]}
              containerClass="!flex !flex-row !gap-6 mt-2"
              selectedValue={watch('techArrivedInTimeRating')}
              onChange={(value: string) =>
                setValue('techArrivedInTimeRating', value as 'yes' | 'no')
              }
              label="Did the technician arrive on time? (optional)"
              error={errors.techArrivedInTimeRating && 'Required'}
              disabled={safeFormat.success}
            />
            <Checkbox
              className="flex-col gap-2"
              label={'I may be contacted regarding this feedback'}
              disabled={safeFormat.success}
              checked={isChecked}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setIsChecked(e.target.checked);
                setValue('contact_me_flag', e.target.checked ? 'yes' : 'no');
              }}
            />
            <span className="w-full">
              <Divider />
            </span>
            <GraniteTextArea
              label="Additional feedback (optional)"
              inputClassName="resize-y"
              placeholder="Add additional notes about your experience"
              error={errors.footer_response?.message}
              disabled={safeFormat.success}
              className="w-full"
              {...register('footer_response')}
            />
          </div>
          <div className="mt-6 flex items-start justify-start gap-5 px-8 pb-8">
            <GraniteButton
              size="large"
              variant={!safeFormat.success ? 'secondary' : 'primary'}
              onClick={close}
            >
              {!safeFormat.success ? 'Cancel' : 'Close'}
            </GraniteButton>
            {!safeFormat.success && (
              <GraniteButton
                size="large"
                type="submit"
                className={clsx(
                  createTicketFeedbackMutation.isLoading && 'loading-button',
                )}
              >
                Submit feedback
                {createTicketFeedbackMutation.isLoading && (
                  <Loader animationClassname="!w-3 !h-3" />
                )}
              </GraniteButton>
            )}
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ProvideFeedbackModal;
