import { DropdownIndicatorProps, GroupBase } from 'react-select';
import downArrow from 'assets/images/downArrow.svg';

export const CustomDropdownIndicator = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  selectProps,
}: DropdownIndicatorProps<Option, IsMulti, Group>) => {
  const { isDisabled, menuIsOpen } = selectProps;

  return (
    <img
      src={downArrow}
      style={{
        filter: isDisabled ? 'grayscale(100%)' : 'none',
        transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
      }}
    />
  );
};
