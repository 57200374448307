import { useCallback } from 'react';
import clsx from 'clsx';
import { CloseCircle } from 'react-ionicons';

import Divider from 'components/Divider';
import Checkbox from 'components/Checkbox';

import { ServiceDetails } from './ServiceDetails';
import { ProductOfferingType, ProductType } from './schemas';

interface ServiceProps {
  data: {
    address: string;
    id: string;
    offerings: ProductOfferingType[];
    type?: string;
  };
  selected: boolean;
  onProductChange: (
    id: string,
    type: string,
    product: ProductType,
    mns: boolean,
  ) => void;
  onSelectChange: (id: string, type: string, checked: boolean) => void;
}

export const Service = ({
  data,
  selected,
  onProductChange,
  onSelectChange,
}: ServiceProps) => {
  const handleProductChange = useCallback(
    (product: ProductType, mns: boolean) => {
      onProductChange(data.id, data.type ?? '', product, mns);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSelectChange = useCallback(() => {
    onSelectChange(data.id, data.type ?? '', !selected);
  }, [data.id, data.type, onSelectChange, selected]);

  return (
    <div>
      <div
        className={clsx(
          'overflow-hidden rounded outline transition-all duration-500',
          selected ? 'outline-stroke-accent-default' : 'outline-transparent',
        )}
      >
        <div
          className={clsx(
            'relative rounded-t bg-gradient-to-b from-[#191925] to-[#222231] p-4',
            data.offerings.length > 0
              ? 'cursor-pointer'
              : 'pointer-events-none',
          )}
          onClick={handleSelectChange}
        >
          <div
            className={clsx(
              'relative flex w-[150%] justify-between transition-all duration-700 ease-[cubic-bezier(.01,1.4,.63,.99)]',
              selected ? '-left-1/2' : 'left-0',
            )}
          >
            <Checkbox
              disabled={!data.offerings.length}
              label={'Add to order'}
              className="pointer-events-none"
              checked={false}
            />
            <Checkbox
              disabled={!data.offerings.length}
              label={'Added to order!'}
              className="pointer-events-none !text-content-accent-default"
              checked={true}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 rounded-b bg-background-base-surface-1 p-4">
          <div className="flex min-w-0 flex-grow flex-row items-start gap-4">
            <div className="truncate text-xl font-bold text-content-base-default">
              {data.address}
            </div>
            <div
              className={clsx(
                'mt-0.5 whitespace-nowrap rounded-lg px-2 py-1 text-xs font-bold text-content-flip-default',
                data.type !== 'broadband' && data.type !== 'dia' && 'hidden',
                data.type === 'broadband'
                  ? 'bg-blue-400'
                  : data.type === 'dia'
                    ? 'bg-green-300'
                    : 'bg-neutral-500',
              )}
            >
              {data.type === 'broadband'
                ? 'Broadband circuit'
                : data.type === 'dia'
                  ? 'DIA circuit'
                  : ''}
            </div>
          </div>
          <Divider className="mb-2" />
          {data.offerings.length > 0 ? (
            <div className="flex items-start gap-4">
              <ServiceDetails
                data={data.offerings}
                onChange={handleProductChange}
                selected={selected}
              />
            </div>
          ) : (
            <NotAvailable />
          )}
        </div>
      </div>
    </div>
  );
};

const NotAvailable = () => {
  return (
    <div>
      <div className="flex h-full w-full flex-col gap-2 rounded bg-background-base-surface-3 p-4">
        <div className="flex items-center gap-2">
          <p className="text-xl font-bold text-content-base-default">
            There are no carriers available at this location.
          </p>
          <CloseCircle color="#FF315E" />
        </div>
        <p className="text-base font-semibold text-content-base-subdued">
          This location will be excluded from this quote request.
        </p>
      </div>
    </div>
  );
};
