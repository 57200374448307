import { TicketDetailForm } from '../DefinedFormTypes';
import React, { Fragment } from 'react';
import Divider from '../../../../components/Divider';
import FormSection from '../../../../components/FormSection';
import { StandardTools } from '../../../../components/StandardTools/StandardTools';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import { GraniteLabel } from '../../../../components/V2/Label/GraniteLabel';
import RadioButton from '../../../../components/RadioButton';
import { GraniteInput } from '../../../../components/V2/Input/GraniteInput';
import { CustomerEquipmentForm } from './schemas';
import {
  FormWithContact,
  TicketDetailContactForm,
} from '../../BaseComponents/TicketDetailContactForm';
import { optionsToLabelValuePairs } from '../../utils/optionsToLabelValuePairs';
import { NonStandardToolsFieldTextField } from '../../BaseComponents/NonStandardToolsField';
import { FileUpload } from 'components/V2/FileUpload/FileUpload';

export type CustomerEquipmentProps = TicketDetailForm<CustomerEquipmentForm>;

export const CustomerEquipment = ({
  register,
  control,
  errors,
  watch,
}: CustomerEquipmentProps) => {
  const testingGuideAvailable = watch?.('testingGuideAvailable');
  return (
    <Fragment>
      <FormSection title="Ticket details" gridClassName="grid-cols-2">
        <GraniteInput
          label="What equipment is experiencing the issue?"
          className="col-span-full"
          error={errors.whatEquipment?.message}
          {...register('whatEquipment')}
        />
        <GraniteInput
          label="What is this equipment currently being used for?"
          className="col-span-full"
          error={errors.whatIsUsedFor?.message}
          {...register('whatIsUsedFor')}
        />
        <GraniteInput
          label="Enter the current location of this equipment"
          className="col-span-full"
          error={errors.currentLocation?.message}
          {...register('currentLocation')}
        />

        <Controller
          name="testingGuideAvailable"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <GraniteLabel
              label="Is there an install or troubleshooting guide available for this equipment/service?"
              element="div"
              className="col-span-2"
            >
              <RadioButton
                options={optionsToLabelValuePairs(
                  'Yes, a guide will be available',
                  'No',
                )}
                selectedValue={value?.value}
                onChangeWithLV={(value, label) => onChange({ value, label })}
                error={errors.testingGuideAvailable?.message}
                ref={ref}
              />
            </GraniteLabel>
          )}
        />
        {testingGuideAvailable?.value === 'Yes, a guide will be available' && (
          <Controller
            name="guideAttachments"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FileUpload
                label="Upload guide (optional)"
                subtitle="Please add the install guide as an attachment and ensure it is on site upon tech's arrival."
                className="col-span-2"
                accept=".pdf,.png,.jpeg"
                value={value}
                onChange={(files) => {
                  onChange(files);
                }}
              />
            )}
          />
        )}
      </FormSection>
      <Divider />
      <FormSection gridClassName="grid-cols-2">
        <p className="col-span-2 font-bold text-content-base-default">
          The technician must test with someone on site. Please provide their
          contact details below.
        </p>

        <TicketDetailContactForm
          errors={errors}
          register={register as unknown as UseFormRegister<FormWithContact>}
          control={control as unknown as Control<FormWithContact>}
        />
      </FormSection>
      <Divider />
      <FormSection>
        <StandardTools />
        <NonStandardToolsFieldTextField control={control} />
      </FormSection>
    </Fragment>
  );
};
