import { ExtendedTicket } from 'api/techexpress/schema';
import { EditTicketForm, EditTicketRequest } from './form-schema';
import { utcToZonedTime } from 'date-fns-tz';
import { lookup } from 'zipcode-to-timezone';
import { dateToUTCWithoutMS } from '../../../shared/util/dateToUtcStrWithoutMS';
import { zonedTimeOfZipCodeToUtc } from '../../../shared/util/zonedTimeOfZipCodeToUtc';
import { mergeDateTimeParts } from '../../../shared/util/mergeDateTimeParts';
import { getEndTimeDate } from '../../LetUsHelp/Review/reviewTicketFormToRequest';
import getSummaryFieldValue from 'screens/LetUsHelp/utils/getSummaryFieldValue';

export const editDefaultValues = (
  data: ExtendedTicket,
): Partial<EditTicketForm> => {
  const siteTimezone = lookup(data.zip) ?? 'America/New_York';
  return {
    accessTime: data.schedule
      ? {
          ...data.schedule,
          start_date: utcToZonedTime(data.schedule.start_date, siteTimezone),
          ...(data.schedule.scheduling_type === 'Requested Window'
            ? { end_date: utcToZonedTime(data.schedule.end_date, siteTimezone) }
            : {}),
        }
      : {
          scheduling_type: 'Hard Start',
          start_date: new Date(),
        },
    customer_ticket_number: data.customer_ticket_number ?? '',
    customer_preferred_tech: data?.customer_preferred_tech ?? undefined,
    prior_ticket: data.prior_ticket ? data.prior_ticket.toString() : undefined,
    dispatchDate: data?.schedule?.start_date,
    automatic_email_contact: data?.automatic_email_contact,
    local_contact: [
      {
        name: data.local_contact_name ?? '',
        email: data.location_contact_email,
        phoneNumber: data.local_contact_number?.toString() ?? '',
        extension: data.location_contact_ext,
      },
    ],
    location_name: data.location_name,
    location_number: data.location_number ? data.location_number : '',
    po_number: data.po_number ?? '',
    nonStandardTools: data.non_standard_tools
      ? {
          isNeeded: true,
          nonStandardTools: data.non_standard_tools,
        }
      : { isNeeded: false },
    product: { value: data.board_name, label: data.board_name },
    recipients: data.automatic_email_cc_address
      ? data.automatic_email_cc_address
          .map((email) => email.trim())
          .filter((email) => email)
          .map((email: string) => ({ email }))
      : [],
    scope_of_work: data.initial_description,
    site: {
      name: data.location_name || '',
      address_line_1: data.address_1,
      city: data.city,
      state: data.state_identifier,
      zip: data.zip,
      // needed to provide a default one
      id: 0,
    },
    special_instructions: data.special_instructions,
    ticketType: { value: data.type_name, label: data.type_name },
  };
};

export const editTicketToRequest = (
  payload: EditTicketForm,
): EditTicketRequest => {
  const { dispatchDate, accessTime } = payload;

  return {
    ...payload,
    automatic_email_cc: payload.recipients.length > 0,
    automatic_email_cc_address: payload.recipients
      .map((r) => r.email)
      .join(';'),
    // NOTICE: currently there's no way to edit automatic_email_contact in the UI
    automatic_email_contact: payload.automatic_email_contact,
    customer_ticket_number: payload.customer_ticket_number,
    prior_ticket: payload.prior_ticket ? parseInt(payload.prior_ticket) : null,
    end_date: dateToUTCWithoutMS(
      zonedTimeOfZipCodeToUtc(
        getEndTimeDate(accessTime, dispatchDate),
        payload.site.zip,
      ),
    ),
    po_number: payload.po_number,
    initial_description: payload.scope_of_work,
    local_contact_name: payload.local_contact[0].name,
    local_contact_number: payload.local_contact[0].phoneNumber,
    location_contact_email: payload.local_contact[0].email,
    location_contact_ext: payload.local_contact[0].extension,
    ...(payload.local_contact.length > 1
      ? {
          secondary_lcon_name: payload.local_contact[1].name,
          secondary_lcon_email: payload.local_contact[1].email,
          secondary_lcon_number: payload.local_contact[1].phoneNumber,
          secondary_lcon_ext: payload.local_contact[1].extension,
        }
      : {}),
    location_number: payload.location_number?.toString(),
    non_standard_tools: payload.nonStandardTools.isNeeded
      ? payload.nonStandardTools.nonStandardTools
      : undefined,
    scheduling_type: accessTime.scheduling_type,
    start_date: dateToUTCWithoutMS(
      zonedTimeOfZipCodeToUtc(
        mergeDateTimeParts(dispatchDate, accessTime.start_date),
        payload.site.zip,
      ),
    ),
    sub_type: payload.priority,
    summary: getSummaryFieldValue({
      ticketType: payload.ticketType.value,
      priority: payload.priority as 'P1' | 'P2' | 'P3',
      dispatchDate: payload.dispatchDate,
      accessTime: {
        start_date: payload.accessTime.start_date,
        scheduling_type: payload.accessTime.scheduling_type,
        end_date:
          payload.accessTime.scheduling_type === 'Hard Start'
            ? undefined
            : payload.accessTime.end_date,
      },
      zip: payload.site.zip,
    }),
  };
};
