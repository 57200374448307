import { clsx } from 'clsx';
import { CheckmarkCircle } from 'react-ionicons';

interface WizardStateBlockProps {
  isActive?: boolean;
  isCompleted?: boolean;
  label?: string;
  textActiveClassName?: string;
  strokeActiveClassName?: string;
  classNames?: string;
}

export const WizardStateBlock = ({
  isActive,
  isCompleted,
  label,
  classNames,
  textActiveClassName = 'text-status-info-default fill-status-info-default',
  strokeActiveClassName = 'bg-status-info-default',
}: WizardStateBlockProps) => {
  return (
    <div className="flex flex-col gap-2">
      <div
        className={clsx(
          'flex items-center',
          isActive ? textActiveClassName : 'text-button-content-ghost-disabled',
        )}
      >
        {isCompleted ? (
          <CheckmarkCircle width="18px" height="18px" color="inherit" />
        ) : null}
        <span className={clsx('px-2 font-bold', classNames)}>{label}</span>
      </div>
      <div
        className={clsx(
          'h-[6px] rounded-sm',
          isActive ? strokeActiveClassName : 'bg-neutral-700',
        )}
      />
    </div>
  );
};
