import { PaginableRequest } from 'api/common-schemas';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { CopyOutline, HelpCircle } from 'react-ionicons';
import { copyText, getKeys } from './utils';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import { useSortedTable } from 'hooks/useSortedTable';
import { KeyType } from './keys.types';
import { ColumnDef, Row } from '@tanstack/react-table';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import { Outlet, useNavigate } from 'react-router-dom';
import Tag from 'components/Tag';
import { usePopper } from 'react-popper';
import { useRef, useState } from 'react';

const ApiKeys = () => {
  const navigate = useNavigate();

  const _getKeys = (args: PaginableRequest) => getKeys(args);

  const { sortingQueryParams, sortingState, onSortingChange } = useSortedTable({
    initialSorting: [{ id: 'name', desc: true }],
  });

  const { data: tableData, ...paginatedTableProps } = usePaginatedTable(
    _getKeys,
    {
      sortingQueryParams,
    },
    ['user-settings-api-keys', sortingQueryParams],
    {
      refetchOnMount: true,
    },
  );

  const tableHeaders: ColumnDef<KeyType>[] = [
    {
      header: 'Name',
      accessorKey: 'name',
      cell: (row) => (
        <Tag
          content={{ type: 'label', label: row.row.original.name }}
          status="success"
          style="default"
        />
      ),
    },
    {
      header: 'Client key',
      accessorKey: 'client_key',
      cell: (row) => (
        <div className="flex items-end gap-2 text-neutral-500">
          <p className="max-w-[140px] truncate text-white">
            {row.row.original.client_key}
          </p>
          <span
            onClick={(e) => {
              e.stopPropagation();
              copyText(row.row.original.client_key, row.row.original.name);
            }}
            className="hover:text-teal-400"
          >
            <CopyOutline width="24px" height="24px" color="currentColor" />
          </span>
        </div>
      ),
    },
    {
      header: 'Created',
      accessorKey: 'created_at',
    },
    {
      header: 'Actions',
      cell: (row) => (
        <GraniteButton
          variant="destructive"
          size="small"
          className="mr-1"
          onClick={(e) => {
            e.stopPropagation();
            navigate(
              `delete/${row.row.original.client_key}/${row.row.original.name}`,
              { replace: true },
            );
          }}
        >
          Delete
        </GraniteButton>
      ),
    },
  ];

  const onRowClick = (row: Row<KeyType>) =>
    navigate(`${row.original.client_key}`);

  const onCreate = () => navigate('create', { replace: true });

  const [isVisible, setIsVisible] = useState(false);
  const triggerEltRef = useRef<HTMLDivElement>(null);
  const popperEltRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);

  const { styles, attributes } = usePopper(
    triggerEltRef?.current,
    popperEltRef?.current,
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-152, 14],
          },
        },
        {
          name: 'arrow',
          options: {
            element: arrowRef.current,
            padding: 5,
          },
        },
      ],
    },
  );

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2 text-2xl font-bold text-white">
          <p>API keys</p>
          <div
            ref={triggerEltRef}
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
          >
            <HelpCircle width="24px" height="24px" color="#94A3B8" />
          </div>
        </div>
        <div
          ref={popperEltRef}
          style={{ ...styles.popper, zIndex: 99999 }}
          {...attributes.popper}
        >
          {isVisible && (
            <div className="relative w-[328px] rounded-lg bg-background-base-surface-0 p-5 text-content-base-subdued shadow-elevation3">
              <div
                ref={arrowRef}
                style={styles.arrow}
                className="absolute !-top-2 !left-1/2 h-4 w-4 !-translate-x-1/2 !rotate-45 !transform bg-background-base-surface-0"
              />
              <p className="mb-1 text-base font-bold text-content-base-default">
                API keys
              </p>
              <div className="text-sm font-medium">
                Create API keys to securely connect customer owned systems to
                Granite360, enabling automated access to ticket management.
              </div>
            </div>
          )}
        </div>

        <GraniteButton size="large" onClick={onCreate}>
          Create new key
        </GraniteButton>
      </div>
      <p className="mb-12 mt-8">
        In order to maintain the security of your account, do not share your
        secret keys with others. If one of your keys has been compromised, it’s
        recommended to delete & recreate the key as needed.
      </p>
      <div className="mt-6 w-full">
        <ServerPaginatedTable
          data={tableData}
          columns={tableHeaders}
          title="Roles"
          sortingState={sortingState}
          onSortingChange={onSortingChange}
          handleRowClick={onRowClick}
          tableContainerClassName="!grid-cols-[repeat(4,max-content)]"
          columnGap="48px"
          {...paginatedTableProps}
          isFetchingData={paginatedTableProps.isLoading}
        />
        <Outlet />
      </div>
    </>
  );
};

export default ApiKeys;
