import { zodResolver } from '@hookform/resolvers/zod';
import { OpenQuoteBaseForm } from '../BaseComponents/OpenQuoteBaseForm';
import { Controller, useForm } from 'react-hook-form';
import { QuoteProduct, QuoteProductSchema } from '../schemas/Product';
import FormSection from 'components/FormSection';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import RadioButton from 'components/RadioButton';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { GraniteSelect, OptionType } from 'components/Select/Select';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import {
  BroadbandCircuitForm as BroadbandCircuitFormType,
  BroadbandCircuitFormSchema,
} from './schemas';
import { ipBlocksOptions, termLengthOptions } from '../utils';
import { useEffect } from 'react';

interface BroadbandCircuitFormProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  onAddProduct: (product: QuoteProduct) => void;
  productValues?: QuoteProduct;
  onModalClose: () => void;
}
const BroadbandCircuitForm = ({
  setStep,
  onAddProduct,
  productValues,
  onModalClose,
}: BroadbandCircuitFormProps) => {
  const defaultValues =
    productValues &&
    productValues.type === 'broadband' &&
    productValues.ipType === 'dynamic'
      ? {
          ...productValues,
          minimumDownloadSpeed: productValues?.minimumDownloadSpeed.toString(),
          minimumUploadSpeed: productValues?.minimumUploadSpeed
            ? productValues?.minimumUploadSpeed?.toString()
            : '',
          ipType: 'dynamic' as const,
          termLength: termLengthOptions.find(
            (term) => term.value === productValues.termLength,
          ),
          ipBlocks: undefined,
        }
      : productValues &&
          productValues.type === 'broadband' &&
          productValues.ipType === 'static'
        ? {
            ...productValues,
            minimumDownloadSpeed:
              productValues?.minimumDownloadSpeed.toString(),
            minimumUploadSpeed: productValues?.minimumUploadSpeed
              ? productValues?.minimumUploadSpeed?.toString()
              : '',
            ipType: 'static' as const,
            termLength: termLengthOptions.find(
              (term) => term.value === productValues.termLength,
            ),
            ipBlocks: ipBlocksOptions.find(
              (ipBlock) => ipBlock.value === productValues.ipBlocks,
            ),
          }
        : {
            termLength: termLengthOptions[2],
            type: 'broadband' as const,
          };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<BroadbandCircuitFormType>({
    resolver: zodResolver(BroadbandCircuitFormSchema),
    defaultValues,
  });

  const onSubmit = (data: BroadbandCircuitFormType) => {
    const product = QuoteProductSchema.safeParse({
      ...data,
      termLength: data.termLength.value,
      minimumDownloadSpeed: Number(data.minimumDownloadSpeed),
      minimumUploadSpeed: Number(data.minimumUploadSpeed),
      ...(data.ipBlocks && {
        ipBlocks: data.ipBlocks.value,
      }),
    });
    if (product.success) {
      onAddProduct(product.data);
    }
  };
  const onError = (error: unknown) => {
    console.log('onSubmitError called', error);
  };

  const requiredFieldError = 'Required';

  const selectedTerm = watch('termLength');
  const selectedIPType = watch('ipType');
  const selectedIPBlock = watch('ipBlocks');

  useEffect(() => {
    if (selectedIPType === 'dynamic') {
      setValue('ipBlocks', undefined);
    }
  }, [selectedIPType, setValue]);

  return (
    <OpenQuoteBaseForm
      onSubmit={handleSubmit(onSubmit, onError)}
      id="ticket-review-form"
      className="grid-cols-2 gap-6"
    >
      <Controller
        name="accessType"
        control={control}
        render={({ field: { onChange, value } }) => (
          <GraniteLabel label="Access type" className="col-start-1">
            <RadioButton
              options={[
                {
                  value: 'Cable',
                  label: 'Cable',
                },
                {
                  value: 'Fiber',
                  label: 'Fiber',
                },
              ]}
              selectedValue={value}
              onChange={onChange}
              className="text-base"
              error={errors.accessType?.message}
            />
          </GraniteLabel>
        )}
      />
      <FormSection gridClassName="grid-cols-2">
        <GraniteInput
          label="Minimum download speed"
          className="col-span-1"
          {...register('minimumDownloadSpeed')}
          error={errors.minimumDownloadSpeed?.message}
          suffix="Mbps"
        />
        <GraniteInput
          label="Minimum upload speed (optional)"
          className="col-span-1"
          {...register('minimumUploadSpeed')}
          error={errors.minimumUploadSpeed?.message}
          suffix="Mbps"
        />
      </FormSection>
      <Controller
        name="ipType"
        control={control}
        render={({ field: { onChange, value } }) => (
          <GraniteLabel
            label="IP type"
            subtitle="Static IPs do not change and are useful for remote access systems, such as VPNs and proxy servers. Dynamic IPs change from time to time and offer more security and privacy."
            className="col-start-1"
          >
            <RadioButton
              options={[
                {
                  value: 'static',
                  label: 'Static IP',
                },
                {
                  value: 'dynamic',
                  label: 'Dynamic IP',
                },
              ]}
              selectedValue={value}
              onChange={onChange}
              className="text-base"
              error={errors.ipType?.message}
            />
          </GraniteLabel>
        )}
      />
      {selectedIPType === 'static' && (
        <Controller
          name="ipBlocks"
          control={control}
          render={() => (
            <GraniteLabel
              label="IP block"
              subtitle="An IP block is a series of consecutive static IP addresses that can be used for devices or networks."
              className="col-start-1"
            >
              <GraniteSelect
                options={ipBlocksOptions}
                value={selectedIPBlock}
                onChange={(selectedOption: OptionType | null) =>
                  setValue('ipBlocks', selectedOption || ipBlocksOptions[0])
                }
                error={errors.ipBlocks && requiredFieldError}
              />
            </GraniteLabel>
          )}
        />
      )}
      <GraniteSelect
        {...register('termLength')}
        options={termLengthOptions}
        label="Contract term length"
        onChange={(selectedOption: OptionType | null) =>
          setValue('termLength', selectedOption || termLengthOptions[2])
        }
        value={selectedTerm}
        error={errors.termLength && requiredFieldError}
      />
      <GraniteInput
        label="Add a name to this product (optional)"
        subtitle="Create a product name for easy identification and use across different locations."
        className="col-span-1"
        {...register('name')}
        error={errors.name?.message}
      />
      <div className="mt-6 flex gap-5">
        <GraniteButton
          size="large"
          variant="secondary"
          onClick={productValues ? () => onModalClose() : () => setStep(1)}
        >
          {productValues ? 'Cancel' : 'Back'}
        </GraniteButton>
        <GraniteButton size="large" type="submit" disabled={isSubmitting}>
          {productValues ? 'Save changes' : 'Add product'}
        </GraniteButton>
      </div>
    </OpenQuoteBaseForm>
  );
};

export default BroadbandCircuitForm;
