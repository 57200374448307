export const statusDescriptionOptions = [
  'Actively Troubleshooting',
  'Assigned',
  'Cancelled',
  'In Process',
  'Monitoring/Cleared',
  'Open',
  'Open (Alert Sent)',
  'Pending Carrier',
  'Pending Customer',
  'Pending NI',
  'Pending Premier',
  'Pending RMA',
  'Pending Vendor',
  'Resolved',
];

export const dateOptions = [
  { label: 'Yesterday', value: 'DAY' },
  {
    label: 'Previous week',
    value: 'WEEK',
  },
  { label: 'Default (last 7 days)', value: 'Default' },
  {
    label: 'Previous month',
    value: 'MONTH',
  },
  {
    label: 'Previous quarter',
    value: 'QUARTER',
  },
  { label: 'Previous Year', value: 'YEAR' },
];
