import { TicketDetailForm } from '../DefinedFormTypes';
import React, { Fragment } from 'react';
import Divider from '../../../../components/Divider';
import FormSection from '../../../../components/FormSection';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import { GraniteLabel } from '../../../../components/V2/Label/GraniteLabel';
import GraniteTextArea from '../../../../components/TextArea/TextArea';
import RadioButton from '../../../../components/RadioButton';
import { WiringCopperForm } from './schemas';
import { optionsToLabelValuePairs } from '../../utils/optionsToLabelValuePairs';
import { StandardTools } from '../../../../components/StandardTools/StandardTools';
import {
  FormWithContact,
  TicketDetailContactForm,
} from '../../BaseComponents/TicketDetailContactForm';
import { NonStandardToolsFieldTextField } from '../../BaseComponents/NonStandardToolsField';

export type WiringCopperProps = TicketDetailForm<WiringCopperForm>;

export const WiringCopper = ({
  register,
  control,
  errors,
}: WiringCopperProps) => {
  return (
    <Fragment>
      <FormSection title="Ticket details" gridClassName="grid-cols-2">
        <Controller
          name="whenDidIssueBegin"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <GraniteLabel
              label="When did the issue begin?"
              element="div"
              className="col-span-full"
            >
              <RadioButton
                options={optionsToLabelValuePairs(
                  'Less than 24 hours ago',
                  '24-48 hours ago',
                  'More than 48 hours ago',
                )}
                selectedValue={value?.value}
                error={errors.whenDidIssueBegin?.message}
                onChangeWithLV={(value, label) => onChange({ value, label })}
                ref={ref}
              />
            </GraniteLabel>
          )}
        />
        <Controller
          name="issueDescription"
          control={control}
          render={({ field }) => (
            <GraniteTextArea
              {...field}
              className="col-span-full"
              label="Please describe the issue"
              error={errors.issueDescription?.message}
              {...register('issueDescription')}
            />
          )}
        />
      </FormSection>
      <Divider />
      <FormSection gridClassName="grid-cols-2">
        <Controller
          control={control}
          name="techToTestWith"
          render={({ field: { onChange, value, ref } }) => (
            <Fragment>
              <GraniteLabel
                label="Does the technician need to test with anyone specific?"
                element="div"
                className="col-span-full"
              >
                <RadioButton
                  options={[
                    {
                      label: "Yes, I'll provide their contact information",
                      value: 'Yes',
                    },
                    { label: 'No', value: 'No' },
                  ]}
                  selectedValue={value?.techToTestWith}
                  error={errors.techToTestWith?.message}
                  onChange={(techToTestWith) =>
                    onChange({ ...value, techToTestWith })
                  }
                  ref={ref}
                />
              </GraniteLabel>
              {value?.techToTestWith && value.techToTestWith === 'Yes' && (
                <TicketDetailContactForm
                  errors={errors}
                  register={
                    register as unknown as UseFormRegister<FormWithContact>
                  }
                  control={control as unknown as Control<FormWithContact>}
                />
              )}
            </Fragment>
          )}
        />
      </FormSection>
      <Divider />
      <FormSection>
        <StandardTools />
        <NonStandardToolsFieldTextField control={control} />
      </FormSection>
    </Fragment>
  );
};
