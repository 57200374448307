import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { deletePermission } from '../utils';
import { useMutation, useQueryClient } from 'react-query';
import showToast from 'components/Toast/Toast';
import { useNavigate, useParams } from 'react-router-dom';

const DeletePermission = () => {
  const { id, name } = useParams<{ id: string; name: string }>();

  const navigate = useNavigate();

  const onClose = () => navigate('..', { replace: true });

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(deletePermission, {
    onSuccess: () => {
      showToast.confirmation({
        title: 'Permission deleted!',
        message: `${name}`,
      });
      queryClient.refetchQueries('permissions-index-table');
      onClose();
    },
  });

  return (
    <Modal isVisible={true} close={onClose} className="w-full max-w-[624px]">
      <div className="p-8">
        <div>
          <h1 className="text-2xl font-bold text-white">
            Are you sure you want to delete this permission?
          </h1>
          <p className="mt-2 text-base font-bold text-neutral-500">
            If deleted, this permission will be removed from any associated
            roles.
          </p>
        </div>
        <div className="mt-12 flex gap-5">
          <GraniteButton variant="secondary" size="large" onClick={onClose}>
            Cancel
          </GraniteButton>
          <GraniteButton
            size="large"
            variant="destructive"
            disabled={isLoading}
            onClick={() => mutate(id as string)}
          >
            Yes, delete permission
          </GraniteButton>
        </div>
      </div>
    </Modal>
  );
};

export default DeletePermission;
