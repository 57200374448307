import { apiClient } from 'api/apiClient';
import {
  DeletePreferenceParams,
  GetPreferenceParams,
  PostPreferenceParams,
  ResponsePreferencesModel,
} from './customize.types';

export const getPreferenceByTableName = async ({
  tableName,
}: GetPreferenceParams): Promise<ResponsePreferencesModel> => {
  const response = await apiClient.get(
    `/api/v1/index_preferences/${tableName}`,
  );
  return response.data;
};

export const postPreferenceByTableName = async ({
  body,
  tableName,
}: PostPreferenceParams): Promise<ResponsePreferencesModel> => {
  const response = await apiClient.post(
    `/api/v1/index_preferences/${tableName}`,
    { columns: body },
  );
  return response.data;
};

export const restorePreferenceByTableName = async ({
  tableName,
}: DeletePreferenceParams): Promise<ResponsePreferencesModel> => {
  const response = await apiClient.delete(
    `/api/v1/index_preferences/${tableName}`,
  );
  return response.data;
};
