import { Control, FieldValues, Path, useController } from 'react-hook-form';
import DatePicker, {
  DatePickerGraniteProps,
} from '../../../components/DatePicker/DatePicker';
import { addDays, isAfter, startOfDay } from 'date-fns';
import React from 'react';
import { getTodaysCutoffTime } from '../../../shared/util/getTodaysCutoffTime';

export interface DispatchDateSelectorFieldProps<T extends FieldValues>
  extends Omit<DatePickerGraniteProps, 'value' | 'onChange'> {
  name: Path<T>;
  control: Control<T>;
}

export const DispatchDateSelectorField = <T extends FieldValues>({
  control,
  name,
  ...datePickerProps
}: DispatchDateSelectorFieldProps<T>) => {
  const cutoffTime = getTodaysCutoffTime();
  const {
    field,
    fieldState: { error },
  } = useController({ control, name });
  const { ref, ...fieldProps } = field;

  return (
    <DatePicker
      {...datePickerProps}
      {...fieldProps}
      error={error?.message}
      ref={ref}
      min={addDays(
        startOfDay(new Date()),
        isAfter(new Date(), cutoffTime) ? 1 : 0,
      )}
    />
  );
};
