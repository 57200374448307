import { z } from 'zod';
import {
  ContactSubFormSchema,
  NonStandardToolsFieldSchema,
  OptionLabelValueSchema,
} from '../../utils/schemas';
import { TicketDetailForms } from '../DefinedFormTypes';

export const CustomerEquipmentFormSchema = z.object({
  formType: z.literal(TicketDetailForms.enum.CustomerEquipment),
  whatEquipment: z.string().trim().nonempty('Required'),
  whatIsUsedFor: z.string().trim().nonempty('Required'),
  currentLocation: z.string().trim().nonempty('Required'),
  testingGuideAvailable: OptionLabelValueSchema,
  itTestingContacts: z.array(ContactSubFormSchema),
  nonStandardTools: NonStandardToolsFieldSchema,
  guideAttachments: z.array(z.any()).optional(),
});

export type CustomerEquipmentForm = z.infer<typeof CustomerEquipmentFormSchema>;
