import Checkbox from 'components/Checkbox';
import { GraniteSelect } from 'components/Select/Select';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import RadioButton from 'components/RadioButton';
import { ipBlocksOptions } from 'screens/OpenQuote/utils';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import clsx from 'clsx';
import { SLADialog } from './SLADialog';
import { useModal } from 'hooks/useModal';
import {
  LocationProducts,
  SelectableProduct,
} from '../../Wizard/ProductSelectionStore';
import { observer } from 'mobx-react-lite';
import { optionsToLabelValuePairs } from '../../../LetUsHelp/utils/optionsToLabelValuePairs';
import { PriceDetails } from './PriceDetails';

const typeOfConnectionOptions = optionsToLabelValuePairs('Fiber', 'Cable');
const buildingStatusOptions = optionsToLabelValuePairs('On-net');

export interface ProductProps {
  name: string;
  locationProducts: LocationProducts;
  product: SelectableProduct;
}

const Product = observer(
  ({ name, locationProducts, product }: ProductProps) => {
    const { isOpen, open, ...props } = useModal();
    return (
      <>
        <div className="flex flex-col">
          <Checkbox
            label={
              <div className="flex items-center">
                <span
                  className={clsx(
                    'ml-2 text-2xl font-bold',
                    locationProducts.isError && '!text-status-error-default',
                    !product.isEnabled && '!text-content-base-subdued',
                  )}
                >
                  {name}
                  {!product.isEnabled && ' (inactive)'}
                </span>
                <span className="ml-4 flex h-[24px] w-[130px] items-center justify-center rounded-lg bg-orange-300 text-xs font-bold text-content-flip-default">
                  Broadband circuit
                </span>
              </div>
            }
            onChange={() => product.toggleStatus()}
            checked={product.isEnabled}
          />
          <div className="relative ml-8 mt-4 grid grid-cols-[1fr,332px]">
            <form className="grid grid-cols-2 gap-6 rounded bg-background-base-surface-3 p-4 shadow">
              <GraniteLabel label="Type of connection" className="col-span-1">
                <GraniteSelect
                  value={typeOfConnectionOptions.find(
                    (toc) => toc.value === product.typeOfConnection,
                  )}
                  onChange={(newValue) => {
                    product.setTypeOfConection(
                      newValue?.value as 'Fiber' | 'Cable',
                    );
                  }}
                  options={typeOfConnectionOptions}
                />
              </GraniteLabel>
              <GraniteLabel label="Bulding status" className="col-start-2">
                <GraniteSelect
                  isDisabled
                  options={buildingStatusOptions}
                  value={buildingStatusOptions[0]}
                />
              </GraniteLabel>
              <GraniteLabel label="Carrier name" className="col-start-1">
                <GraniteSelect
                  options={product.carrierOptions}
                  value={product.carrierValue}
                  onChange={(newValue) => {
                    product.setCarrier(newValue?.value);
                  }}
                  isDisabled={product.isCarrierDisabled}
                />
              </GraniteLabel>
              <GraniteLabel label="Term length" className="col-start-1">
                <GraniteSelect
                  options={product.termLengthOptions}
                  value={product.termLengthValue}
                  onChange={(newValue) => {
                    product.setTermLength(newValue?.value);
                  }}
                  isDisabled={product.isTermLengthDisabled}
                />
              </GraniteLabel>
              <GraniteLabel label="Download speed" className="col-start-1">
                <GraniteSelect
                  options={product.downloadSpeedOptions}
                  value={product.selectedDownloadSpeedValue}
                  onChange={(newValue) => {
                    product.setDownloadSpeed(newValue?.value);
                  }}
                  isDisabled={product.isDownloadSpeedDisabled}
                />
              </GraniteLabel>
              <GraniteLabel label="Upload speed" className="col-start-2">
                <GraniteSelect
                  options={product.uploadSpeedOptions}
                  value={product.selectedUploadSpeedValue}
                  onChange={(newValue) =>
                    product.setUploadSpeed(newValue?.value)
                  }
                  isDisabled={product.isUploadSpeedDisabled}
                />
              </GraniteLabel>
              <GraniteLabel
                label="IP type"
                subtitle="Static IPs do not change and are useful for remote access systems, such as VPNs and proxy servers. Dynamic IPs change from time to time and offer more security and privacy."
                className="col-start-1"
              >
                <RadioButton
                  options={[
                    {
                      value: 'static',
                      label: 'Static IP',
                    },
                    {
                      value: 'dynamic',
                      label: 'Dynamic IP',
                    },
                  ]}
                  selectedValue={product.selectedProduct?.ipType}
                  disabled
                  key={product.selectedProduct?.ipType}
                  className="text-base"
                />
              </GraniteLabel>

              <GraniteLabel
                label="IP block"
                subtitle="An IP block is a series of consecutive static IP addresses that can be used for devices or networks."
                className="col-start-2"
              >
                <GraniteSelect isDisabled options={ipBlocksOptions} />
              </GraniteLabel>
            </form>
            <div className="flex flex-col gap-1 bg-background-base-surface-1 p-4">
              <PriceDetails product={product} />
              <GraniteButton variant="secondary" size="large" onClick={open}>
                View SLA
              </GraniteButton>
            </div>
            <SLADialog isOpen={isOpen} {...props} />
            {!product.isEnabled && (
              <div className="absolute inset-0 z-20 bg-background-base-surface-2 opacity-50" />
            )}
          </div>
        </div>
      </>
    );
  },
);

export default Product;
