import React, { useEffect, useMemo, useState } from 'react';
import { pagination } from 'shared/util/util';
import { ChevronBack, ChevronForward } from 'react-ionicons';
import { clsx } from 'clsx';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { GraniteSelect } from 'components/Select/Select';
import { ShortPagination } from './ShortPagination';
import { MenuPlacement } from 'react-select';

export interface PaginationProps {
  currentPage: number;
  pageCount: number;
  totalRows: number;
  currentRowsShown: number | string;
  onPageChange: (newPage: number) => void;
  pageSizeChanged: (newPage: number) => void;
  variant?: 'short' | 'default';
  hidePageSizeSelection?: boolean;
  paginationSizeStored?: number;
  pageSizeMenuPlacement?: MenuPlacement;
}

interface PageProps {
  page: string | number;
  label: string | number;
  currentPage: number;
  pageCount: number;
  onPageChange: (newPage: number) => void;
}

const Page: React.FC<PageProps> = ({
  page,
  label,
  currentPage,
  pageCount,
  onPageChange,
}: PageProps) => {
  return (
    <div
      className={clsx(
        'flex h-8 w-[85px] cursor-pointer items-center justify-center rounded-full font-bold md:w-8',
        page === currentPage &&
          'bg-button-background-primary-default text-content-flip-default',
        page !== '...' &&
          page !== currentPage &&
          'hidden text-content-base-default md:flex',
        page === '...' &&
          'pointer-events-none hidden text-button-content-primary-disabled md:block',
      )}
      onClick={() => onPageChange(Number(page))}
    >
      <span className="block font-bold">{label}</span>
      <span className="ml-1 block font-bold md:hidden"> of {pageCount}</span>
    </div>
  );
};

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  pageCount = 0,
  totalRows,
  onPageChange,
  pageSizeChanged,
  currentRowsShown,
  variant = 'default',
  hidePageSizeSelection = false,
  paginationSizeStored,
  pageSizeMenuPlacement,
}: PaginationProps) => {
  const options = useMemo(
    () => [
      { value: '10', label: 'Show 10' },
      { value: '20', label: 'Show 20' },
      { value: '50', label: 'Show 50' },
      { value: '100', label: 'Show 100' },
    ],
    [],
  );
  const [pageSize, setPageSize] = useState<{
    value: string;
    label: string;
  } | null>(options[1]);

  useEffect(() => {
    if (paginationSizeStored) {
      const storedOption = options.find(
        (o) => parseInt(o.value, 10) === paginationSizeStored,
      );
      if (storedOption) {
        setPageSize(storedOption);
        pageSizeChanged(paginationSizeStored);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationSizeStored]);

  const [pages, setPages] = useState<(number | string)[]>([]);

  useEffect(() => {
    if (pageCount > 0) {
      setPages(pagination(currentPage, pageCount));
    } else {
      setPages([1]);
    }
  }, [currentPage, pageCount]);

  return (
    <div className="flex w-full flex-col-reverse items-end justify-start gap-6 sm:flex-row sm:items-center sm:justify-between sm:gap-0">
      <div className="flex w-[330px] items-center justify-between gap-6">
        <p className="text-base text-content-base-subdued">
          Showing {currentRowsShown} of {totalRows}
        </p>
        {!hidePageSizeSelection && (
          <div className="w-[140px]">
            <GraniteSelect
              placeholder="Select one"
              value={pageSize}
              menuPlacement={pageSizeMenuPlacement}
              onChange={(data) => {
                const val = data?.value;
                if (val) {
                  setPageSize(data);
                  pageSizeChanged(Number(val));
                }
              }}
              options={options}
            />
          </div>
        )}
      </div>
      {variant === 'default' && (
        <div className="flex h-full items-center justify-start gap-4">
          <GraniteButton
            variant="secondary"
            size="small"
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={clsx(
              'border-stroke-secondary-default flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded border !px-0',
              currentPage === 1 && 'pointer-events-none',
            )}
          >
            <ChevronBack color={'inherit'} height={'24px'} width={'24px'} />
          </GraniteButton>
          {!!pages.length &&
            pages.map((p: number | string, idx: number) => (
              <Page
                key={idx}
                page={p}
                label={p as string}
                currentPage={currentPage}
                onPageChange={onPageChange}
                pageCount={pageCount}
              />
            ))}
          <GraniteButton
            variant="secondary"
            size="small"
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === pageCount}
            className={clsx(
              'border-stroke-secondary-default flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded border !px-0',
              currentPage === pageCount && 'pointer-events-none',
            )}
          >
            <ChevronForward color={'inherit'} height={'24px'} width={'24px'} />
          </GraniteButton>
        </div>
      )}
      {variant === 'short' && (
        <ShortPagination
          currentPage={currentPage}
          totalPages={pageCount}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};

export default Pagination;
