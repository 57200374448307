import styled, { css } from 'styled-components';

const sharedStyles = css`
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: 700;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  font-size: ${({ theme }) => theme.text.size.md};
  display: flex;
  flex-direction: row;
  gap: 5px;
  text-align: center;

  svg {
    margin-top: -5px;
  }
  align-items: center;
  display: flex;
`;

const primaryStyles = css`
  border: 1px solid ${({ theme }) => theme.colors.text.primary};
  background: ${({ theme }) => theme.colors.iconButton.primary.backgroundColor};
  color: ${({ theme }) => theme.colors.iconButton.primary.color};

  svg {
    color: ${({ theme }) => theme.colors.iconButton.primary.color} !important;
    fill: ${({ theme }) => theme.colors.iconButton.primary.color} !important;
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.iconButton.primary.hover};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.button.disabled};
    color: ${({ theme }) => theme.colors.text.disabled};
    cursor: default;
  }

  &:active:not(:disabled) {
    background: ${({ theme }) =>
      theme.colors.iconButton.primary.active} !important;
  }
`;

const secondaryStyles = css`
  border: 1px solid
    ${({ theme }) => theme.colors.iconButton.secondary.borderColor};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text.white};

  &:hover:not(:disabled) {
    background: ${({ theme }) => theme.colors.iconButton.secondary.hover};
    border-color: transparent;
    color: ${({ theme }) => theme.colors.button.hoverColor};

    svg {
      color: ${({ theme }) =>
        theme.colors.iconButton.secondary.hoverColor} !important;
      fill: ${({ theme }) =>
        theme.colors.iconButton.secondary.hoverColor} !important;
    }
  }

  svg {
    color: ${({ theme }) => theme.colors.iconButton.secondary};
  }

  &:active:not(:disabled) {
    background: ${({ theme }) =>
      theme.colors.iconButton.secondary.active} !important;
  }

  &:disabled {
    background: transparent;
    color: ${({ theme }) => theme.colors.iconButton.secondary.disabled};
    cursor: default;
    border-color: ${({ theme }) => theme.colors.iconButton.secondary.disabled};

    svg {
      color: ${({ theme }) =>
        theme.colors.iconButton.secondary.disabled} !important;
      fill: ${({ theme }) =>
        theme.colors.iconButton.secondary.disabled} !important;
    }
  }
`;

const destructiveStyles = css`
  background-color: ${({ theme }) =>
    theme.colors.iconButton.destructive.backgroundColor};
  color: ${({ theme }) => theme.colors.text.white};

  &:hover {
    background: ${({ theme }) => theme.colors.iconButton.destructive.hover};
    border-color: ${({ theme }) => theme.colors.iconButton.destructive.active};
  }

  svg {
    color: ${({ theme }) => theme.colors.iconButton.secondary};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.iconButton.destructive.disabled};
    color: ${({ theme }) => theme.colors.text.disabled};
    cursor: default;
  }

  &:active:not(:disabled) {
    background: ${({ theme }) =>
      theme.colors.iconButton.destructive.active} !important;
  }
`;

const ghostStyles = css`
  border: none;
  background: transparent;

  &:hover {
    cursor: pointer;

    svg {
      color: ${({ theme }) => theme.colors.iconButton.ghost.hover} !important;
      fill: ${({ theme }) => theme.colors.iconButton.ghost.hover} !important;
    }
  }

  svg {
    color: ${({ theme }) => theme.colors.iconButton.ghost.color};
  }

  &:disabled {
    cursor: default;
  }

  &:active:not(:disabled) {
    svg {
      color: ${({ theme }) => theme.colors.iconButton.ghost.active} !important;
      fill: ${({ theme }) => theme.colors.iconButton.ghost.active} !important;
    }
  }
`;

const getButtonSizeStyles = (size?: string, mode?: string) => {
  switch (size) {
    case 'small':
      return css`
        ${sharedStyles}
        ${getButtonModeStyles(mode)}
        font-size: 12px;
        padding: 0 12px;
        height: 24px;
        font-size: ${({ theme }) => theme.text.size.sm};
        font-weight: ${({ theme }) => theme.fontWeights.bold};
      `;
    case 'medium':
      return css`
        ${sharedStyles}
        ${getButtonModeStyles(mode)}
        font-size: 14px;
        padding: 0 24px;
        height: 32px;
        font-size: ${({ theme }) => theme.text.size.md};
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        line-height: 0.8;
      `;
    case 'large':
      return css`
        ${sharedStyles}
        ${getButtonModeStyles(mode)}
        font-size: 40px;
        padding: 0 32px;
        height: 48px;
        font-size: ${({ theme }) => theme.text.size.lg};
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        line-height: 1.5;
      `;
    default:
      return css`
        ${sharedStyles}
        ${getButtonModeStyles(mode)}
        width: 100%;
      `;
  }
};

const getButtonModeStyles = (mode?: string) => {
  switch (mode) {
    case 'primary':
      return primaryStyles;
    case 'secondary':
      return secondaryStyles;
    case 'destructive':
      return destructiveStyles;
    case 'ghost':
      return ghostStyles;
    default:
      return primaryStyles;
  }
};

export const StyledButton = styled.button<{ size?: string; mode?: string }>`
  ${({ size, mode }) => {
    return css`
      ${getButtonSizeStyles(size, mode)}
    `;
  }}
`;
