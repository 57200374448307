import { BasePagination, PaginableRequest } from 'api/common-schemas';
import { KeyFormType, KeyType } from './keys.types';
import showToast from 'components/Toast/Toast';

export const copyText = (text: string, name: string) =>
  navigator.clipboard.writeText(text).then(() => {
    showToast.confirmation({
      title: 'API secret key copied to clipboard!',
      message: name,
    });
  });

const keys: KeyType[] = [
  {
    name: 'Test_key_1',
    client_key: 'ck377bcabsbdi229ck377bcabsbdi229',
    secret_key: 'secret-key-1',
    status: 'Enabled',
    created_at: '10/20/23',
  },
  {
    name: 'Test_key_2',
    client_key: 'c266ediwu388fnn5c266ediwu388fnn5',
    secret_key: 'secret-key-1',
    status: 'Disabled',
    created_at: '10/20/23',
  },
  {
    name: 'Test_key_3',
    client_key: '10391j2iksjc2j4g6vs10391j2iksjc2j4g6vs',
    secret_key: 'secret-key-1',
    status: 'Disabled',
    created_at: '10/20/23',
  },
];

export const getKeys = async (
  args: PaginableRequest,
): Promise<BasePagination<KeyType>> => {
  return new Promise((resolve) => {
    // simulation loading
    setTimeout(() => {
      resolve({
        data: keys,
        metadata: {
          page: args.page || 1,
          page_size: args.page_size || 20,
          total_items: keys.length,
          total_pages: 1,
        },
      });
    }, 1000);
  });
};

export const getKeyById = async (
  client_key: string,
): Promise<KeyType | undefined> => {
  return new Promise((resolve) => {
    // simulation loading
    setTimeout(() => {
      const key = keys.find((k) => k.client_key === client_key);
      resolve(key);
    }, 1000);
  });
};

export const deleteKey = async (
  client_key: string,
): Promise<KeyType | undefined> => {
  return new Promise((resolve) => {
    // simulation loading
    setTimeout(() => {
      const key = keys.find((k) => k.client_key === client_key);
      resolve(key);
    }, 1000);
  });
};

export const regenerateKey = async (
  client_key: string,
): Promise<KeyType | undefined> => {
  return new Promise((resolve) => {
    // simulation loading
    setTimeout(() => {
      resolve({
        name: 'Regenerate_test_key',
        status: 'Enabled',
        created_at: new Date().toISOString(),
        secret_key: 'generated-secret-key-1',
        client_key: client_key,
      });
    }, 1000);
  });
};

export const postKey = async (
  body: KeyFormType,
): Promise<KeyType | undefined> => {
  return new Promise((resolve) => {
    // simulation loading
    setTimeout(() => {
      resolve({
        ...body,
        status: 'Enabled',
        created_at: new Date().toISOString(),
        secret_key: keys[0].client_key,
        client_key: keys[0].client_key,
      });
    }, 1000);
  });
};
