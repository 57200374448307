import React, { useMemo } from 'react';
import format from 'date-fns/format';
import { useParams } from 'react-router-dom';
import PrimaryDetails from './PrimaryDetails';
import { BadgeRole } from 'components/V2/BadgeRole/BadgeRole';
import { useAuthUser } from 'hooks/useAuthUser';
import { getProfileUser, getUserByEmail } from 'api/users/api';
import { useQuery } from 'react-query';
import { plainTextToPhoneNumber } from 'shared/util/util';
import Avatar from 'components/Avatar';
import clsx from 'clsx';
import { Key } from 'react-ionicons';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useChangePasswordMutation } from 'hooks/useChangePasswordMutation';
import { TokenUserRoles } from 'api/users/schemas/Users';
import { usePermissions } from 'hooks/usePermissions';
import Divider from 'components/Divider';
import UserAccountDetails from 'screens/UserAccounts/UserAccountsDetails';
import { subGroups } from 'screens/UserAccounts/utils';
import { useFeatureFlags } from 'feature-flags';

const UserDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { flags } = useFeatureFlags();

  const { roles, email, companyName } = useAuthUser();
  const { isSuperAdmin: hasTokenSuperAdminRole } = usePermissions();

  const { data: profileUser } = useQuery(
    ['user-profile'],
    () => getProfileUser(),
    {
      refetchOnWindowFocus: false,
      enabled: !id,
    },
  );
  const { data: user } = useQuery(
    ['user', id],
    () => getUserByEmail({ email: id! }),
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    },
  );

  const data = id
    ? {
        ...user,
        email: id,
        roles: user?.roles
          ? user.roles.map((role) => ({
              label: role.label,
              value: role.value,
            }))
          : [],
      }
    : {
        ...profileUser,
        email,
        roles: roles.map((role) => ({ value: role, label: role })),
      };

  const userStatusClassName = data?.deactivated
    ? 'bg-user-status-deactivated'
    : 'bg-user-status-active';

  const dateAdded = data?.invited_at || data?.created_at;

  const changePasswordMutation = useChangePasswordMutation();

  const isSuperAdmin = useMemo(() => {
    return data.roles.find(
      (role) => role.value === TokenUserRoles['SUPER_ADMIN'],
    );
  }, [data.roles]);

  const systemRole = useMemo(() => {
    return data.roles.find((role) =>
      [
        TokenUserRoles.COMPANY_ADMIN,
        TokenUserRoles.USER,
        TokenUserRoles.VIEWER,
        TokenUserRoles.WELCOME_USER,
      ].includes(role.value as TokenUserRoles),
    );
  }, [data.roles]);

  return (
    <div className="flex w-full flex-col items-start justify-start gap-6">
      <div className="col-span-12 flex w-full items-center justify-between gap-12 rounded bg-background-base-surface-2 px-6 py-4">
        <div className="flex w-full items-center justify-start gap-3">
          <span
            className={clsx(`h-4 w-4 rounded-full`, userStatusClassName)}
          ></span>
          <h2 className="text-xl font-bold text-content-base-default">
            {data?.deactivated ? 'Deactivated' : 'Active'}
          </h2>
        </div>
        <div className="flex w-full flex-col items-end justify-start">
          <p className="text-base font-bold text-content-base-subdued">
            Date Added
          </p>
          <h2 className="text-base font-bold text-content-base-default">
            {dateAdded ? format(new Date(dateAdded), 'MM/dd/yyyy') : ' -- '}
          </h2>
        </div>
      </div>
      <div className="grid h-full w-full grid-cols-12 overflow-hidden rounded">
        <div className="col-span-4 flex h-full w-full flex-col items-start justify-start gap-4 bg-background-base-surface-3 px-8 py-6">
          <h2 className="text-2xl font-bold text-content-base-default">
            User Details
          </h2>
          <Avatar width="w-20" height="h-20" name={data?.name ?? 'N/A'} />
          <div className="flex w-full flex-col items-start justify-start gap-4">
            <PrimaryDetails label={'Name'} value={data?.name ?? 'N/A'} />
            <PrimaryDetails label={'Email'} value={data.email ?? 'N/A'} />
            <PrimaryDetails
              label={'Secondary email'}
              value={data?.secondary_email ?? 'N/A'}
            />
            <PrimaryDetails
              label={'Phone number'}
              value={
                data?.phone_number
                  ? plainTextToPhoneNumber(data.phone_number)
                  : 'No phone number'
              }
            />
            {data?.secondary_phone_number && (
              <PrimaryDetails
                label={'Secondary phone number'}
                value={plainTextToPhoneNumber(data?.secondary_phone_number)}
              />
            )}
            <PrimaryDetails label={'Company'} value={companyName ?? 'N/A'} />
            {flags.ACCOUNT_ACCESS && (
              <PrimaryDetails label={'Company account #'} value={'02346797'} />
            )}
            <PrimaryDetails
              label={'Company title'}
              value={data?.company_title ? data?.company_title : 'N/A'}
            />
            <PrimaryDetails
              label={'Department'}
              value={data?.department ? data?.department : 'N/A'}
            />
          </div>
          {!id && (
            <GraniteButton
              variant="secondary"
              size="large"
              className="mt-10 box-border w-full"
              onClick={() => changePasswordMutation.mutate()}
            >
              Reset password <Key />
            </GraniteButton>
          )}
        </div>
        <div className="col-span-8 flex h-full w-full flex-col items-start justify-start gap-4 bg-background-base-surface-2 px-8 pb-12 pt-6">
          <div className="flex w-full flex-col items-start justify-start gap-4">
            <h2 className="text-2xl font-semibold text-content-base-default">
              Role & permissions
            </h2>
            <div className="flex flex-col gap-2">
              {flags.ACCOUNT_ACCESS && (
                <p className="font-bold text-neutral-500">Role</p>
              )}
              <div className="flex w-full items-start justify-start gap-4">
                <div className="max-w-[130px]">
                  <BadgeRole
                    role={
                      (id && isSuperAdmin) || (hasTokenSuperAdminRole && !id)
                        ? TokenUserRoles['SUPER_ADMIN']
                        : systemRole?.value ?? ''
                    }
                  />
                </div>
                <p className="w-full text-base font-bold text-content-base-default">
                  {isSuperAdmin ? (
                    'This user will have full read & write permissions and the ability to manage users'
                  ) : (
                    <>
                      {systemRole?.value === TokenUserRoles.VIEWER &&
                        'This user will be limited to read only permissions'}
                      {[
                        TokenUserRoles.COMPANY_ADMIN,
                        TokenUserRoles.SUPER_ADMIN,
                      ].includes(systemRole?.value as TokenUserRoles) &&
                        'This user will have full read & write permissions and the ability to manage users'}
                      {systemRole?.value === TokenUserRoles.WELCOME_USER &&
                        'Pending role assignment'}
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
          {id && flags.ACCOUNT_ACCESS && (
            <>
              <Divider className="w-full" />
              <UserAccountDetails
                subGroups={subGroups.slice(0, 4)}
                allSelected={false}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
