import * as XLSX from 'xlsx';

/**
 * Converts a CSV string to an Excel file and triggers a download.
 *
 * @param data The CSV data as a string.
 * @param fileName The desired name of the downloaded Excel file.
 */
export const downloadCSVAsExcel = (data: string, fileName?: string) => {
  try {
    const workbook = XLSX.read(data, { type: 'string' });
    XLSX.writeFile(workbook, `${fileName ?? 'data'}.xlsx`);
  } catch (error) {
    console.error('Error in downloading the Excel file: ', error);
  }
};
