import { SiteAddressSchema } from 'api/addresssearch/schema';
import { nullToUndefined } from 'shared/util/schema-utils';
import { z } from 'zod';

export const QuoteAPIBroadbandProductSchema = z.object({
  access_type: z.enum(['Cable', 'Fiber']),
  id: z.string(),
  product_type: z.literal('Broadband'),
  term: z.string(),
  min_download: z.string(),
  min_upload: z.string().nullish(),
  ip_type: z.enum(['STATIC', 'DYNAMIC']),
  ip_block: z.string().nullish(), // Only present when ip_type is 'static'
  name: z.string().nullish(),
});

export const QuoteAPIDIAProductSchema = z.object({
  id: z.string(),
  product_type: z.literal('DIA'),
  access_type: z.enum(['FE', 'GE']),
  minimum_connection_speed: z.string(),
  ip_type: z.literal('STATIC'),
  ip_block: z.string(),
  term: z.string(),
  name: z.string().nullish(),
});

export const QuoteAPIProductSchema = z.discriminatedUnion('product_type', [
  QuoteAPIBroadbandProductSchema,
  QuoteAPIDIAProductSchema,
]);

export type QuoteAPIProduct = z.infer<typeof QuoteAPIProductSchema>;
export const QuoteAPILocationSchema = z.object({
  id: z.string(),
  address1: z.string(),
  address2: z.string().optional(),
  city: z.string(),
  state: z.string(),
  zip_code: z.string(),
  products: z.array(QuoteAPIProductSchema),
});

export type QuoteApiLocation = z.infer<typeof QuoteAPILocationSchema>;

export const CreateQuoteRequestSchema = z.object({
  customer_name: z.string(),
  contact_name: z.string(),
  email: z.string(),
  quote_name: z.string(),
  business_unit: z.string(),
  locations: z.array(QuoteAPILocationSchema),
});

export type CreateQuoteRequest = z.infer<typeof CreateQuoteRequestSchema>;

export const CreateQuoteResponse = z.object({
  id: z.string(),
  status: z.string(),
});

export type CreateQuoteResponse = z.infer<typeof CreateQuoteResponse>;

export const QuoteRequestedSchema = CreateQuoteRequestSchema.extend({
  qe_quote_expires_at: z.coerce.date(),
});

const QELocationMetadataSchema = z.object({
  address1: z.string(),
  address2: z.string(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
});

const ProductMatchesSchema = z.array(
  z.object({
    vendor: z.string(),
    download_speed: z.number(),
    upload_speed: z.number(),
    total_rate: z.number(),
    circuit_type: z.string(),
    products: z.array(
      z.object({
        access_type: z.string(),
        download_speed: z.number().optional(),
        frequency: z.string(),
        product_group: z.string(),
        product_type: z.string(),
        quote_request_location_id: z.string(),
        quote_request_product_id: z.string(),
        rate: z.number(),
        read_flag: z.boolean(),
        term: z.string(),
        upload_speed: z.number().optional(),
        vendor: z.string(),
      }),
    ),
  }),
);

export const QEQuoteAPIResponseSchema = z.object({
  quote_request: QuoteRequestedSchema,
  quote_results: z.record(
    z.string(),
    z.object({
      metadata: QELocationMetadataSchema,
      productMatches: z.record(ProductMatchesSchema),
    }),
  ),
});

export type QEQuoteAPIResponse = z.infer<typeof QEQuoteAPIResponseSchema>;

export const QuoteLocationSchema = z.object({
  name: z.string().transform(nullToUndefined).nullish(),
  address_line_1: z.string().transform(nullToUndefined).nullish(),
  address_line_2: z.string().transform(nullToUndefined).nullish(),
  parent_macnum: z.string().transform(nullToUndefined).nullish(),
  parent_name: z.string().transform(nullToUndefined).nullish(),
  city: z.string(),
  state: z.string(),
  street: z.string().transform(nullToUndefined).nullish(),
  zip: z.string(),
  id: z.number().transform(nullToUndefined).nullish(),
});

export type QuoteLocation = z.infer<typeof QuoteLocationSchema>;

export const QuoteLocationsResponseSchema = z.object({
  data: z.array(QuoteLocationSchema),
  data_source: z.enum(['CW', 'MD']),
});

export const VerifyAddressRequestSchema = z.object({
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  street: z.string(),
});

export type VerifyAddressRequest = z.infer<typeof VerifyAddressRequestSchema>;

export const VerifyAddressResponseSchema = z
  .object({
    md_status: z.enum(['success', 'corrected', 'error']),
  })
  .merge(VerifyAddressRequestSchema);

export type VerifyAddressResponse = z.infer<typeof VerifyAddressResponseSchema>;

export const PricingTierSchema = z.object({
  name: z.string(),
  avg_mrc: z.string(),
  avg_bandwidth: z.string(),
  term: z.string().optional(),
  ip_type: z.string().optional(),
  ip_blocks: z.string().optional(),
  bucket_id: z.string().optional(),
  product_id: z.string().optional(),
  quote_id: z.string().optional(),
  selected: z.boolean().optional(),
});

export type PricingTier = z.infer<typeof PricingTierSchema>;

export const PricingTierResponseSchema = z.object({
  location: SiteAddressSchema,
  sendMePricing: z.boolean(),
  product_type: z.enum(['Broadband', 'DIA']),
  id: z.string(),
  pricing_tiers: z.array(PricingTierSchema),
});

export type PricingTierResponse = z.infer<typeof PricingTierResponseSchema>;

export const SubmitOrderPayloadSchema = z.object({
  ordered_products: z.array(
    z.object({
      id: z.string(),
    }),
  ),
});

export type SubmitOrderPayload = z.infer<typeof SubmitOrderPayloadSchema>;
