import { z } from 'zod';
import {
  LETTERS_SPACES_HYPENS_ERROR_LABEL,
  VALID_PHONE_ERROR_LABEL,
} from '../../../shared/constants/error-labels';
import {
  LETTERS_SPACES_HYPENS_REGEXP,
  VALID_PHONE_NUMBER,
} from 'shared/constants/validation-regex-constants';

export const NonStandardToolsFieldSchema = z.discriminatedUnion('isNeeded', [
  z.object({ isNeeded: z.literal(false) }),
  z.object({
    isNeeded: z.literal(true),
    nonStandardTools: z.string(),
  }),
]);

export type NonStandardToolsField = z.infer<typeof NonStandardToolsFieldSchema>;

export const ContactSubFormSchema = z.object({
  contactName: z
    .string()
    .nonempty('Required')
    .max(100, 'Only 100 characters allowed')
    .regex(LETTERS_SPACES_HYPENS_REGEXP, {
      message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
    }),
  contactNumber: z
    .string()
    .trim()
    .nonempty(VALID_PHONE_ERROR_LABEL)
    .length(10, VALID_PHONE_ERROR_LABEL)
    .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
  contactEmail: z.string().email().optional().or(z.literal('')),
});

export type ContactSubForm = z.infer<typeof ContactSubFormSchema>;

export const OptionLabelValueSchema = z.object({
  label: z.string(),
  value: z.string(),
});
