import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';

const DeleteProductDialog = ({
  isOpen,
  close,
  handleConfirm,
  productName,
}: {
  isOpen: boolean;
  handleConfirm: () => void;
  close: () => void;
  productName: string;
}) => {
  return (
    <Modal isVisible={isOpen} close={close} className="w-[624px]">
      <div className="flex flex-col gap-2 rounded-lg rounded-t-lg bg-background-base-surface-2">
        <h1 className="rounded-t-lg bg-background-base-surface-3 px-8 py-6 text-[28px] font-bold leading-9 text-content-base-default">
          Are you sure you want to delete this product?
        </h1>
        <div className="px-8 py-6">
          <p className="medium mb-[48px] text-[20px] font-bold text-content-base-default">
            {productName}
          </p>
          <div className="mt-4 flex gap-5">
            <GraniteButton size="large" variant="secondary" onClick={close}>
              Cancel
            </GraniteButton>
            <GraniteButton
              size="large"
              variant="destructive"
              onClick={handleConfirm}
            >
              Yes, delete
            </GraniteButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteProductDialog;
