import { ComponentProps } from 'react';
import { clsx } from 'clsx';

export const ContentLayout = ({
  className,
  ...props
}: ComponentProps<'div'>) => {
  return (
    <div
      className={clsx(
        'mx-auto my-0 w-[1440px] px-4 pb-32 pt-8 sm:px-8',
        className,
      )}
      {...props}
    />
  );
};
