import { useRef, useState } from 'react';
import clsx from 'clsx';

import MenuIcon from './MenuIcon';
import MenuLink from './MenuLink';
import { useScrollThreshold } from 'hooks/useScrollThreshold';
import { useClickOutside } from 'hooks/useClickOutside';

const Menu: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const menuContainerRef = useRef<HTMLDivElement>(null);

  const hasScrolled = useScrollThreshold(100);

  const toggleMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsMenuOpen((prev) => !prev);
  };

  useClickOutside(menuContainerRef, () => setIsMenuOpen(false));

  return (
    <div
      className="sticky right-0 top-0 z-[49] hidden sm:block"
      ref={menuContainerRef}
    >
      <div className="absolute right-10 mt-4 flex w-[150px] flex-col items-end overflow-hidden">
        <div
          onClick={toggleMenu}
          className="group flex cursor-pointer flex-row items-center gap-3 p-4 pb-3"
        >
          <div
            className={clsx(
              'text-sm font-bold uppercase leading-6 tracking-[1.68px] text-content-base-subdued transition duration-300 ease-in-out',
              'group-hover:text-content-base-default',
              hasScrolled && '-translate-x-[200px]',
              'group-hover:translate-x-0',
              isMenuOpen && '!translate-x-0.5 !text-content-accent-default',
            )}
          >
            Menu
          </div>

          <MenuIcon isOpen={isMenuOpen} />
        </div>
        <div className="flex flex-col pr-4 text-sm">
          <MenuLink visible={isMenuOpen} to="/access-express/index">
            Go to quotes
          </MenuLink>
          <MenuLink
            visible={isMenuOpen}
            to="/access-express/open-quote/choose-services"
          >
            Find services
          </MenuLink>
        </div>
      </div>
    </div>
  );
};

export default Menu;
