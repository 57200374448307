import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Filter,
  NOCDashboardFilterStore,
} from '../../screens/NOCExpress/NOCDashboardFilterStore';

export const useDomoDashboard = () => {
  const portRef = useRef<MessagePort | undefined>();
  const [dashboardHeight, setDashboardHeight] = useState('1700px');

  const applyFilters = useCallback(
    (filters: Filter[], interval: string, count: number) => {
      if (portRef.current) {
        portRef.current.postMessage({
          id: 'update-filters',
          jsonrpc: '2.0',
          method: '/v1/filters/apply',
          params: {
            filters,
            dateRangeFilter: {
              dateTimeElement: null,
              dateTimeRange: {
                dateTimeRangeType: 'ROLLING_PERIOD',
                interval,
                offset: 0,
                count,
              },
              overrideDateRange: true,
            },
          },
        });
      } else {
        console.error(
          'Tried to set filters for dashboard, but no port is connected.',
        );
      }
    },
    [],
  );

  const [filterStore] = useState(new NOCDashboardFilterStore());
  useEffect(() => {
    filterStore.setOnFilterChangeCallback(applyFilters);
  }, [applyFilters, filterStore]);

  useEffect(() => {
    const iframeMessageHandler = (event: MessageEvent<unknown>) => {
      if (!event.origin.includes('domo')) return;

      if (event.ports[0]) {
        const port = event.ports[0];
        portRef.current = port;

        port.start();

        port.onmessage = (e: MessageEvent<unknown>) => {
          if (e.data instanceof Object && 'method' in e.data) {
            switch (e.data.method) {
              case '/v1/onFrameSizeChange': {
                if ('params' in e.data) {
                  const data = e.data as { params: { height: number } };
                  // Add 15 px to the height in order to take into account scroll bars
                  // that could just barely push things into a scrollable state
                  setDashboardHeight(data.params.height + 15 + 'px');
                }
                break;
              }
              case '/v1/onFiltersChange': {
                if ('params' in e.data) {
                  const data = e.data as {
                    params: {
                      filters: Filter[];
                      dateRangeFilter: {
                        dateTimeRange: { interval: string; count: number };
                      };
                    };
                  };
                  filterStore.addInitialFilters(data.params.filters);
                }
                break;
              }
            }
          }
        };
      }
    };

    window.addEventListener('message', iframeMessageHandler, false);

    return () => {
      portRef.current?.close();
      window.removeEventListener('message', iframeMessageHandler);
    };
  }, [filterStore]);

  return {
    dashboardHeight,
    filterStore,
  };
};
