import { NOCSiteAddressSchema } from 'api/nocexpress/schemas/ConfigurationsSchema';
import { NUMBERS_ONLY_REGEXP } from 'shared/constants/validation-regex-constants';
import { z } from 'zod';

const LocationBaseSchema = z.object({
  childAccount: z
    .string()
    .nonempty('Required')
    .max(8, 'Only 8 characters allowed')
    .regex(NUMBERS_ONLY_REGEXP, {
      message: 'Only numbers allowed',
    }),
  site: NOCSiteAddressSchema.optional(),
  id: z.number(),
  is_chronic: z.boolean().default(false),
  parent_account: z.string(),
});

const LocationWithMaintenanceRequired = LocationBaseSchema.extend({
  canTestingBeDoneAnytime: z.literal(false),
  maintenance_window: z.string().nonempty('Required'),
});

const LocationWithMaintenanceOptional = LocationBaseSchema.extend({
  canTestingBeDoneAnytime: z.literal(true),
  maintenance_window: z.string().optional(),
});

const LocationWithMaintenanceUndefined = LocationBaseSchema.extend({
  canTestingBeDoneAnytime: z.undefined(),
  maintenance_window: z.string().nonempty('Required'),
});

export const LocationSchema = z.discriminatedUnion('canTestingBeDoneAnytime', [
  LocationWithMaintenanceRequired,
  LocationWithMaintenanceOptional,
  LocationWithMaintenanceUndefined,
]);

export type Location = z.infer<typeof LocationSchema>;

export const BulkLocationsSchema = z.object({
  locations: z.array(LocationSchema),
});

export type BulkLocations = z.infer<typeof BulkLocationsSchema>;
