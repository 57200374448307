import { zodResolver } from '@hookform/resolvers/zod';
import { TokenUserPermissions } from 'api/users/schemas/Users';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { FormProvider, useForm } from 'react-hook-form';
import { ContactFormType, ContactGroupType, ContactSchema } from './schema';
import { FC, useEffect } from 'react';
import GroupForm from './GroupForm';
import RemoveMeFromGroup from '../RemoveMeFromGroup';
import ReactiveGroup from '../ReactiveGroup';
import DeactivateGroup from '../DeactivateGroup';
import DeleteGroup from './DeleteGroup';
import { useMutation } from 'react-query';
import { patchContactGroup } from '../../utils';
import showToast from 'components/Toast/Toast';
import { DisplayForPermission } from 'components/Permission/DisplayForPermission';
import { usePermissions } from 'hooks/usePermissions';

interface ContactsEditFormProps {
  defaultValues?: Omit<ContactGroupType, 'devices'>;
  close: () => void;
  refetchOnSuccess: () => void;
  refetchDetailsOnSuccess: () => void;
}

const ContactsEditForm: FC<ContactsEditFormProps> = ({
  defaultValues,
  close,
  refetchOnSuccess,
  refetchDetailsOnSuccess,
}) => {
  const { hasPermission } = usePermissions({
    permission: [TokenUserPermissions.CONTACT_GROUPS_WRITE],
  });

  const { reset, ...methods } = useForm<ContactFormType>({
    resolver: zodResolver(ContactSchema),
    defaultValues: {
      name: defaultValues?.last_name,
      recipients: defaultValues?.emails.map((e) => ({ email: e })) || [],
    },
  });

  useEffect(() => {
    reset({
      name: defaultValues?.last_name,
      recipients: defaultValues?.emails.map((e) => ({ email: e })) || [],
    });
  }, [defaultValues, reset]);

  const { mutate: editContactGroupMutate } = useMutation(patchContactGroup, {
    onSuccess: () => {
      refetchOnSuccess();
      close();
      showToast.confirmation({ message: 'Edited' });
    },
    onError: () => {
      close();
      showToast.error({ message: 'Failed to edited' });
    },
  });

  const onSubmit = (data: ContactFormType) => {
    if (defaultValues)
      editContactGroupMutate({
        id: defaultValues?.id,
        first_name: defaultValues?.first_name,
        last_name: data.name,
        emails: data.recipients.map((e) => e.email),
      });
  };
  const onDeleteSuccess = () => {
    refetchOnSuccess();
    close();
  };
  return (
    <>
      <FormProvider {...methods} reset={reset}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <GroupForm />
          <div className="mt-8 flex gap-4">
            {!hasPermission && defaultValues?.id && (
              <RemoveMeFromGroup
                label="Remove me from this group"
                contactGroupId={defaultValues.id}
                refetchOnSuccess={refetchDetailsOnSuccess}
              />
            )}
            <DisplayForPermission
              permission={TokenUserPermissions.CONTACT_GROUPS_WRITE}
            >
              <>
                {defaultValues?.status === 'inactive' && (
                  <ReactiveGroup
                    label=" Reactivate group"
                    contactGroupId={defaultValues.id}
                    refetchOnSuccess={refetchDetailsOnSuccess}
                  />
                )}
                {defaultValues?.status === 'active' && (
                  <DeactivateGroup
                    label="Deactivate group"
                    contactGroupId={defaultValues.id}
                    refetchOnSuccess={refetchDetailsOnSuccess}
                  />
                )}
                {defaultValues?.id && (
                  <DeleteGroup
                    contactGroupId={defaultValues.id}
                    onSuccess={onDeleteSuccess}
                  />
                )}
              </>
            </DisplayForPermission>
          </div>
          <div className="mt-12 flex gap-5">
            <GraniteButton variant="secondary" size="large" onClick={close}>
              Cancel
            </GraniteButton>
            <DisplayForPermission
              permission={TokenUserPermissions.CONTACT_GROUPS_WRITE}
            >
              <GraniteButton size="large" type="submit">
                Save Changes
              </GraniteButton>
            </DisplayForPermission>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default ContactsEditForm;
