const Support = ({ description }: { description: string }) => {
  return (
    <div className="col-span-full flex items-center justify-between bg-background-base-surface-3 px-6 py-4 last:rounded-b">
      <div className="flex flex-col items-start text-base font-bold">
        <span className="text-content-base-subdued">What’s next?</span>
        <span className="text-content-base-default">{description}</span>
      </div>
    </div>
  );
};

export default Support;
