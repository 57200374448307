import Loader from 'components/Loader';

const ResultsLoading = () => {
  return (
    <div className="flex-3 col-span-3" key="three-missing">
      <div className="flex h-full w-full flex-col gap-2 rounded bg-background-base-surface-3 p-4">
        <div className="flex items-center gap-2">
          <p className="text-xl font-bold text-content-base-default">
            Results loading
          </p>
          <Loader animationClassname="border-4 !border-t-4 w-6 h-6" />
        </div>
        <p className="text-base font-semibold text-content-base-subdued">
          We’re gathering the options available for this location now. This
          process may take a few minutes.
        </p>
      </div>
    </div>
  );
};

export default ResultsLoading;
