import { Apps, Exit, FileTrayFull, Person, Videocam } from 'react-ionicons';
import { NavigateFunction } from 'react-router-dom';
import {
  TokenUserPermissions,
  TokenUserRoles,
} from '../../api/users/schemas/Users';
import { toSentenceCase } from 'shared/util/util';
import { FEATURE_FLAGS } from 'feature-flags';
import { ReactComponent as People } from 'assets/images/people.svg';
import { hasPermission } from 'hooks/usePermissions';

const getDropdownOptionsByRole = ({
  name,
  companyName,
  navigate,
  logout,
  switchCompany,
  openCatalogModal,
  openCompanyAdminsModal,
  flags,
}: {
  name: string;
  companyName: string;
  navigate: NavigateFunction;
  logout: () => void;
  switchCompany: () => void;
  openFeedbackModal: () => void;
  openCatalogModal: () => void;
  openCompanyAdminsModal: () => void;
  flags: FEATURE_FLAGS;
}) => [
  {
    options: [
      {
        label: name,
        icon: <Person color={'#F8FAFC'} width="20px" height="20px" />,
        subText: companyName,
        onClick: () => navigate('/profile'),
        link: '/profile',
      },
      {
        label: 'Company details',
        icon: <People color={'#F8FAFC'} width="20px" height="20px" />,
        onClick: openCompanyAdminsModal,
      },
      ...(hasPermission({ permission: TokenUserPermissions.USER_MANAGEMENT })
        ? [
            {
              label: 'Manage users',
              icon: <People color={'#F8FAFC'} width="20px" height="20px" />,
              onClick: () => navigate('/users'),
              link: '/users',
              className: 'product-tour-user-management',
            },
          ]
        : []),
    ],
  },
  ...(hasPermission({ permission: TokenUserPermissions.MANAGE_GROUPS })
    ? [
        {
          options: [
            {
              label: 'Switch company',
              icon: <Apps color={'#F8FAFC'} width="20px" height="20px" />,
              onClick: switchCompany,
            },
          ],
        },
      ]
    : []),
  ...(flags.PRODUCT_TOUR_ENABLED
    ? [
        {
          options: [
            {
              label: 'Product tour',
              icon: <Videocam color={'#F8FAFC'} width="20px" height="20px" />,
              onClick: openCatalogModal,
              className: 'product-tour-menu',
            },
          ],
        },
      ]
    : []),
  ...(flags.RESOURCES_ENABLED &&
  hasPermission({ permission: TokenUserPermissions.RESOURCES_READ })
    ? [
        {
          options: [
            {
              label: 'Resources',
              icon: (
                <FileTrayFull color={'#F8FAFC'} width="20px" height="20px" />
              ),
              onClick: () => navigate('/resources'),
            },
          ],
        },
      ]
    : []),
  {
    options: [
      {
        label: 'Log out',
        icon: <Exit color={'#F8FAFC'} width="20px" height="20px" />,
        onClick: () => logout(),
      },
    ],
  },
];

export const userRolesEnumToSelectOptions = () =>
  Object.keys(TokenUserRoles)
    .filter(
      (key) =>
        !isNaN(Number(key)) === false &&
        ![
          'SUPER_ADMIN',
          'GRANITE_BETA_TESTER',
          'EARLY_ADOPTER',
          'QR_ADMIN',
          'PROSPECT',
        ].includes(key),
    )
    .map((key) => ({
      value: splitRoleKey(key),
      label: toSentenceCase(splitRoleKey(key)),
    }));

export const splitRoleKey = (key: string, splitKey: '_' | '-' = '_') =>
  key
    .split(splitKey)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export default getDropdownOptionsByRole;
