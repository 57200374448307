import { z } from 'zod';

import {
  ALPHANUMERIC_REGEX,
  LETTERS_SPACES_DOTS_SPECIAL_CHARACTERS,
  LETTERS_SPACES_SPECIAL_CHARACTERS,
  NUMBERS_ONLY_REGEXP,
  VALID_PHONE_NUMBER,
} from 'shared/constants/validation-regex-constants';
import {
  ALPHANUMERIC_REGEX_ERROR,
  LETTERS_SPACES_SPECIAL_CHARACTERS_ERROR_LABEL,
  VALID_PHONE_ERROR_LABEL,
} from 'shared/constants/error-labels';
import { SiteAddressSchema } from 'api/addresssearch/schema';
import { SiteDispatchDateScheduleWithValidationsSchema } from '../../../api/techexpress/schemas/SiteDispatchDateScheduleSchemas';

const technicianOptions = [
  'FixMyService',
  'MoveEquipmentOrWiring',
  'OnsiteTechSupport',
  'SiteAudit',
  'EquipmentInstall',
  'RunExtension',
] as const;

export const WhyTechnicianEnum = z.enum(technicianOptions);
export type WhyTechnician = z.infer<typeof WhyTechnicianEnum>;

export type GetStartedQuestionnaireBaseForm = z.infer<
  typeof GetStartedQuestionnaireBaseFormSchema
>;

export type GetStartedQuestionnaireForm = z.infer<
  typeof GetStartedQuestionnaireFormSchema
>;

export const LocationsArray = z.array(
  z.object({
    macnum: z
      .string()
      .nonempty('Required')
      .max(8, 'Only 8 characters allowed')
      .regex(NUMBERS_ONLY_REGEXP, {
        message: 'Only numbers allowed',
      }),
    site: SiteAddressSchema,
    locationName: z
      .string()
      .nonempty({ message: 'Required' })
      .max(100, { message: 'Only 100 characters allowed' })
      .regex(LETTERS_SPACES_DOTS_SPECIAL_CHARACTERS, {
        message: LETTERS_SPACES_SPECIAL_CHARACTERS_ERROR_LABEL,
      }),
    locationNumber: z
      .string()
      .nonempty({ message: 'Required' })
      .regex(ALPHANUMERIC_REGEX, { message: ALPHANUMERIC_REGEX_ERROR })
      .or(z.literal(''))
      .optional(),
    local_contact_name: z
      .string()
      .nonempty('Required')
      .max(100, 'Only 100 characters allowed')
      .regex(LETTERS_SPACES_SPECIAL_CHARACTERS, {
        message: LETTERS_SPACES_SPECIAL_CHARACTERS_ERROR_LABEL,
      }),
    local_contact_number: z
      .string()
      .nonempty(VALID_PHONE_ERROR_LABEL)
      .length(10, VALID_PHONE_ERROR_LABEL)
      .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
    priority: z.object({ value: z.string(), label: z.string() }),
  }),
);

export type LocationsArraySchema = z.infer<typeof LocationsArray>;

export const LocationsForm = z.object({
  locations: LocationsArray,
});

export type LocationsSchema = z.infer<typeof LocationsForm>;

const SingleTypeSchema = z.object({
  type: z.literal('single'),
  locationName: z
    .string()
    .nonempty({ message: 'Required' })
    .max(100, { message: 'Only 100 characters allowed' })
    .regex(LETTERS_SPACES_DOTS_SPECIAL_CHARACTERS, {
      message: LETTERS_SPACES_SPECIAL_CHARACTERS_ERROR_LABEL,
    }),
  locationNumber: z
    .string()
    .nonempty({ message: 'Required' })
    .regex(ALPHANUMERIC_REGEX, { message: ALPHANUMERIC_REGEX_ERROR })
    .or(z.literal(''))
    .optional(),
});

const MultiTypeSchema = z.object({
  type: z.literal('multi'),
  locations: LocationsArray,
});

export const GetStartedBaseForm = z.object({
  whyTechnician: WhyTechnicianEnum,
});

// Now, when you define your discriminated union, each option explicitly includes the 'type' field.
export const GetStartedForm = z.intersection(
  GetStartedBaseForm.merge(
    z.object({
      type: z.union([z.literal('single'), z.literal('multi')]),
      content: z.discriminatedUnion('type', [
        SingleTypeSchema,
        MultiTypeSchema,
      ]),
    }),
  ),
  SiteDispatchDateScheduleWithValidationsSchema,
);

export const GetStartedBaseFormWithMultipleLocations = z.intersection(
  z.object({
    whyTechnician: WhyTechnicianEnum,
  }),
  LocationsArray,
);

export const MyServiceIssues = z.enum([
  'Circuit',
  'POTSLine',
  'Wiring',
  'CustomerProvidedEquipment',
  'MaterialReplacement',
]);

export const FixMyServiceGraniteServices = z.enum([
  'BroadbandCircuit',
  'POTSLine',
  'Wiring',
]);

const GraniteOwnedServiceSubFormSchema = z.object({
  isGraniteOwned: z.literal(true),
  service: z.discriminatedUnion('which', [
    z.object({
      which: z.literal(FixMyServiceGraniteServices.enum.BroadbandCircuit),
    }),
    z.object({ which: z.literal(FixMyServiceGraniteServices.enum.POTSLine) }),
    z.object({
      which: z.literal(FixMyServiceGraniteServices.enum.Wiring),
      copperOrFiber: z.enum(['Copper', 'Fiber']),
    }),
  ]),
});

export type GraniteOwnedServiceSubForm = z.infer<
  typeof GraniteOwnedServiceSubFormSchema
>;

const GraniteNotOwnedServiceSubFormSchema = z.object({
  isGraniteOwned: z.literal(false),
  service: z.discriminatedUnion('which', [
    z.object({ which: z.literal(MyServiceIssues.enum.Circuit) }),
    z.object({ which: z.literal(MyServiceIssues.enum.POTSLine) }),
    z.object({
      which: z.literal(MyServiceIssues.enum.CustomerProvidedEquipment),
    }),
    z.object({ which: z.literal(MyServiceIssues.enum.MaterialReplacement) }),
    z.object({
      which: z.literal(MyServiceIssues.enum.Wiring),
      extensionType: z.enum(['Copper', 'Fiber']),
    }),
  ]),
});

export type GraniteNotOwnedServiceSubForm = z.infer<
  typeof GraniteNotOwnedServiceSubFormSchema
>;

const FixMyServiceSubFormSchema = z.object({
  graniteOwned: z.discriminatedUnion('isGraniteOwned', [
    GraniteOwnedServiceSubFormSchema,
    GraniteNotOwnedServiceSubFormSchema,
  ]),
});
export type FixMyServiceSubForm = z.infer<typeof FixMyServiceSubFormSchema>;

const DiscriminatedFixMyServiceSubFormSchema = z
  .object({ whyTechnician: z.literal(WhyTechnicianEnum.enum.FixMyService) })
  .merge(FixMyServiceSubFormSchema);

export const TechnicianReasonUnionSchema = z.discriminatedUnion(
  'whyTechnician',
  [
    DiscriminatedFixMyServiceSubFormSchema,
    z.object({
      whyTechnician: z.literal(WhyTechnicianEnum.enum.RunExtension),
      potsOrCircuit: z.enum(['POTS', 'Circuit']),
    }),
    ...[
      WhyTechnicianEnum.enum.MoveEquipmentOrWiring,
      WhyTechnicianEnum.enum.OnsiteTechSupport,
      WhyTechnicianEnum.enum.SiteAudit,
      WhyTechnicianEnum.enum.EquipmentInstall,
    ].map((wt) => z.object({ whyTechnician: z.literal(wt) })),
  ],
);

export const GetStartedQuestionnaireBaseFormSchema = z.intersection(
  GetStartedBaseForm,
  TechnicianReasonUnionSchema,
);

export const GetStartedQuestionnaireFormSchema = z.intersection(
  GetStartedForm,
  TechnicianReasonUnionSchema,
);
