import { SiteAddress } from 'api/addresssearch/schema';
import { getSites } from 'api/companies/api';
import clsx from 'clsx';
import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ModalParams } from 'hooks/useModal';
import { useState } from 'react';
import useDownloadConfigurations from 'shared/util/useDownloadConfigurations';

export interface DuplicateAccountWarningDialogProps extends ModalParams {
  duplicates?: SiteAddress[];
  onConfirm: () => void;
}

export const DuplicateAccountWarningDialog = ({
  isOpen,
  close,
  duplicates,
  onConfirm,
}: DuplicateAccountWarningDialogProps) => {
  const [step, setStep] = useState(1);
  const duplicateId =
    duplicates && duplicates.length > 0
      ? duplicates[0].name.split('-')[0].trim()
      : 0;

  const { isLoading: isDownloading, onDownloadHandler } =
    useDownloadConfigurations({ fetchFn: getSites });

  const onNextHandle = () => {
    if (step === 1) {
      setStep(2);
    } else {
      onConfirm();
      setStep(1);
    }
  };

  return (
    <Modal isVisible={isOpen} close={close}>
      <div className="flex max-w-[624px] flex-col gap-4 p-8">
        <h1 className="m-0 mb-4 text-2xl font-bold text-content-base-default">
          Duplicate account found
        </h1>
        {step === 1 && (
          <>
            <p className="text-base font-bold text-content-base-subdued">
              Account #{duplicateId} already exists with this address & zip code
              combination.
            </p>
            <div className="vertical-scrollbar flex h-[200px] max-h-[200px] flex-col gap-5 overflow-y-auto">
              {duplicates?.map((item) => (
                <p
                  className="h-6	w-max whitespace-nowrap rounded-lg bg-[#2B2B3F] px-2 py-1 text-xs font-bold text-content-base-default"
                  key={item.id}
                >
                  Account #{item.name.split('-')[0].trim()} -{' '}
                  {item.address_line_1}, {item.city}, {item.state}, {item.zip}
                </p>
              ))}
            </div>
            <p className="mb-8 font-bold text-content-base-subdued">
              <a
                rel="noreferrer"
                download
                onClick={
                  !isDownloading ? onDownloadHandler : (e) => e.preventDefault()
                }
                className={clsx(
                  !isDownloading &&
                    'cursor-pointer text-content-accent-default underline visited:text-content-accent-default',
                  isDownloading &&
                    'text-button-background-primary-disabled underline',
                )}
              >
                Download your company&apos;s site list
              </a>
              &nbsp;to review all existing accounts and confirm whether a new
              child account needs to be created.
            </p>
          </>
        )}
        {step === 2 && (
          <p className="mb-8 text-base font-bold text-content-base-subdued">
            It is recommended to have only one billable account per location.
            Are you sure you want to proceed with creating a new child account?
          </p>
        )}
        <div className="flex gap-4">
          <GraniteButton
            variant="secondary"
            size="large"
            onClick={() => {
              step === 1 ? close() : setStep(1);
            }}
          >
            {step === 1 ? 'Close' : 'Back'}
          </GraniteButton>
          <GraniteButton variant="primary" size="large" onClick={onNextHandle}>
            {step === 1 ? 'Next' : 'Yes, create new account'}
          </GraniteButton>
        </div>
      </div>
    </Modal>
  );
};
