import clsx from 'clsx';

interface MenuIconProps {
  isOpen: boolean;
}

const MenuIcon = ({ isOpen }: MenuIconProps) => {
  return (
    <button
      className="relative flex h-3 w-4 flex-col items-end justify-center"
      aria-label={isOpen ? 'Close menu' : 'Open menu'}
    >
      <div
        className={clsx(
          'absolute top-0 h-[1px] w-4 rounded-full bg-content-base-subdued transition duration-300 ease-in-out',
          'group-hover:bg-content-base-default',
          isOpen && 'translate-y-[4.5px] -rotate-45 !bg-content-accent-default',
        )}
      />
      <div
        className={clsx(
          'h-[1px] w-3.5 rounded-full bg-content-base-subdued transition duration-300 ease-in-out',
          'group-hover:bg-content-base-default',
          isOpen && 'opacity-0',
        )}
      />
      <div
        className={clsx(
          'absolute bottom-0 h-[1px] w-4 rounded-full bg-content-base-subdued transition duration-300 ease-in-out',
          'group-hover:bg-content-base-default',
          isOpen && '-translate-y-[6.5px] rotate-45 !bg-content-accent-default',
        )}
      />
    </button>
  );
};

export default MenuIcon;
