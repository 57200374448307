import { useState } from 'react';

export const useBasicPagination = (dataLength: number, pageSize = 2) => {
  const [page, setPage] = useState(1);
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  return {
    currentPage: page,
    pageCount: Math.ceil(dataLength / pageSize),
    currentRowsShown: 1,
    totalRows: dataLength,
    onPageChange: handlePageChange,
    pageSizeChanged: () => {},
    from: (page - 1) * pageSize,
    to: page * pageSize,
  };
};
