export const getAlignmentClass = (align?: 'center' | 'left' | 'right') => {
  switch (align) {
    case 'center':
      return 'justify-center text-center';
    case 'right':
      return 'justify-end text-right';
    case 'left':
    default:
      return 'justify-start text-left';
  }
};
