import { apiClient } from 'api/apiClient';
import {
  InventoryFilterSearchParams,
  InventoryFilterSearchParamsSchema,
  InventoryIndexAPIResponse,
  InventoryRecentActivityRequest,
} from './schema';

const mapOrderColumns = {
  parent_name_order: 'ParentAccountName',
  parent_number_order: 'ParentAccount',
  account_name_order: 'AccountName',
  account_number_order: 'Account',
  address_1_order: 'Address1',
  service_type_order: 'ServiceType',
  vendor_order: 'Vendor',
  status_order: 'OrderStatus',
  completion_date_order: 'CompletionDate',
  end_date_order: 'EndDate',
  btn_order: 'BTN',
  serial_number_order: 'SerialNumber',
  mac_order: 'MAC',
  model_number_order: 'ModelName',
  ip_order: 'WAN_IP_Address',
  tag_number_order: 'TagNumber',
  product_offering_order: 'ProductOffering',
};

export const getInventoryIndex = async (
  params: InventoryFilterSearchParams,
): Promise<InventoryIndexAPIResponse> => {
  const newQueryParams = prepareInventoryQueryParams(params);

  const response = await apiClient.get('/api/v1/inventory/services', {
    params: newQueryParams,
  });

  return {
    data: response.data?.results,
    metadata: {
      ...response.data?.info,
      total_items: response.data?.info?.total_records,
    },
  };
};

export const getInventoryStaticData = async () => {
  const response = await apiClient.get('/api/v1/inventory/static');

  return response.data;
};

export const getInventoryServicesChanges = async (dateRange: string) => {
  const response = await apiClient.get('/api/v1/inventory/services/changes', {
    params: {
      date_range: dateRange,
    },
  });

  return response.data;
};

export const getInventoryServicesCounts = async () => {
  const response = await apiClient.get('/api/v1/inventory/services/counts');

  return response.data;
};

export const getInventoryRecentActivity = async (
  params: InventoryRecentActivityRequest,
) => {
  const response = await apiClient.get('/api/v1/inventory/activity', {
    params: params,
  });

  return response.data?.results;
};

export const getInventoryExport = async (
  params: InventoryFilterSearchParams,
): Promise<InventoryIndexAPIResponse> => {
  const newQueryParams = prepareInventoryQueryParams(params);
  const response = await apiClient.get('/api/v1/inventory/services/export', {
    params: newQueryParams,
  });

  return {
    data: response.data?.results,
    metadata: {
      ...response.data?.info,
      total_items: response.data?.info?.total_records,
    },
  };
};

const prepareInventoryQueryParams = (params: InventoryFilterSearchParams) => {
  const queryParams = InventoryFilterSearchParamsSchema.parse(params);

  const sortKey = Object.keys(params).find((key) => key.endsWith('_order')) as
    | keyof typeof mapOrderColumns
    | undefined;

  const sortOrder = sortKey
    ? (params as Record<string, string>)[sortKey]?.toUpperCase()
    : undefined;
  const sortColumn = sortKey ? mapOrderColumns[sortKey] : undefined;

  return {
    ...queryParams,
    ...(sortColumn && sortOrder
      ? {
          sort_order: sortOrder,
          sort_column: sortColumn,
        }
      : {}),
  };
};
