import { z } from 'zod';
import {
  ContactSubFormSchema,
  NonStandardToolsFieldSchema,
} from '../../utils/schemas';
import { TicketDetailForms } from '../DefinedFormTypes';

export const AuditFormSchema = ContactSubFormSchema.merge(
  z.object({
    formType: z.literal(TicketDetailForms.enum.Audit),
    attachments: z.array(z.any()).optional(), // TODO: Validate file attachments > 15MB
    nonStandardTools: NonStandardToolsFieldSchema,
  }),
);

export type AuditForm = z.infer<typeof AuditFormSchema>;
