import React from 'react';

const StarSvg = ({
  width = '23',
  height = '30',
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 23 30`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="star-svg"
    >
      <g filter="url(#filter0_dd_23161_51941)">
        <path d="M9.06198 3.80744L12.7207 11.5124L18.9846 12.487L13.8891 16.2582L14.2257 24.7811L10.567 17.0761L4.30305 16.1016L9.39861 12.3303L9.06198 3.80744Z" />
      </g>
      <defs>
        <filter
          id="filter0_dd_23161_51941"
          x="0.303223"
          y="0.807373"
          width="22.6816"
          height="28.9739"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_23161_51941"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_23161_51941"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_23161_51941"
            result="effect2_dropShadow_23161_51941"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_23161_51941"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default StarSvg;
