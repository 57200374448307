import { ComponentProps, createElement, Fragment, ReactNode } from 'react';
import { clsx } from 'clsx';

export interface GraniteLabelProps extends ComponentProps<'label'> {
  label?: string;
  subtitle?: string | ReactNode;
  element?: string;
  rightContent?: React.ReactNode;
}

export const GraniteLabel = ({
  label,
  subtitle,
  className,
  children,
  element = 'label',
  rightContent,
  ...props
}: GraniteLabelProps) => {
  return createElement(
    element,
    { className: clsx(className, 'flex flex-col'), ...props },
    <Fragment>
      {label && (
        <span
          className={clsx(
            'mb-0.5 text-base font-bold text-content-base-subdued',
            subtitle ? 'mb-0.5' : 'mb-2',
          )}
        >
          {label}
        </span>
      )}
      {rightContent && (
        <span className="absolute right-1 !mb-4 ml-2">{rightContent}</span>
      )}
      {subtitle && (
        <span className="mb-2 text-sm text-content-base-subdued">
          {subtitle}
        </span>
      )}

      {children}
    </Fragment>,
  );
};
