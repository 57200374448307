import { clsx } from 'clsx';
import React, { Fragment } from 'react';

import { EstimatedCostResponse } from '../../api/techexpress/schemas/EstimatedCostSchemas';
import { Price } from 'components/Price';

interface EstimatedCostRowProps {
  label: string;
  value?: number | string;
  defaultValue: number | string;
  suffix?: string;
  prefix?: string;
}

const EstimatedCostRow = ({
  label,
  value,
  defaultValue,
  suffix,
  prefix = '$',
}: EstimatedCostRowProps) => {
  return (
    <Fragment>
      <span className="justify-self-start text-base font-bold text-content-base-subdued">
        {label}
      </span>
      <span
        className={clsx(
          'justify-self-end text-base font-bold leading-[22px]',
          value ? 'text-content-base-default' : 'text-content-base-subdued',
        )}
      >
        {prefix}
        {value ?? defaultValue} {suffix ?? ''}
      </span>
    </Fragment>
  );
};

export const EstimatedCost = ({
  estimatedCost,
}: {
  estimatedCost?: EstimatedCostResponse;
}) => {
  return (
    <div className="grid grid-cols-[auto_1fr] items-center gap-y-3 rounded bg-background-base-surface-1 p-4">
      <EstimatedCostRow
        label="Estimated duration"
        defaultValue="0"
        prefix=""
        suffix="hours"
        value={estimatedCost?.hours}
      />
      <EstimatedCostRow
        label="Hourly rate"
        defaultValue="0.00"
        value={estimatedCost?.rate_per_hour?.toFixed(2)}
      />
      <EstimatedCostRow
        label="Dispatch fee"
        defaultValue="0.00"
        value={estimatedCost?.dispatch_fee_dollars?.toFixed(2)}
      />
      <EstimatedCostRow
        label="Basic materials fee"
        defaultValue="0.00"
        value={estimatedCost?.misc_fee_dollars?.toFixed(2)}
      />
      <span className="col-span-full border-b border-stroke-base-subdued pb-3 text-sm font-semibold text-content-base-subdued">
        The basic materials fee applies to all dispatches. Any materials charges
        in excess of the basic materials fee will be calculated once dispatch is
        complete.
      </span>
      <span className="justify-self-start text-xl font-bold text-content-base-default">
        Total estimated price
      </span>
      <Price
        total={estimatedCost?.total}
        className="justify-self-end text-xl font-bold"
      />
    </div>
  );
};
