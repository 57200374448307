import {
  ListDropdownButton,
  ListDropdownButtonCaret,
  ListDropdownContainer,
  ListDropdownOption,
  ListDropdownOptionsTrack,
} from './ListDropdown.styles';
import {
  ComponentProps,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { usePopper } from 'react-popper';
import { Options } from '@popperjs/core';

export interface ListDropdownOptionProps {
  label: string;
  isSelected?: boolean;
  onClick?: () => void;
}

export interface ListDropdownGraniteProps extends ComponentProps<'button'> {
  label: string | ReactNode;
  withCaret?: boolean;
  options?: ListDropdownOptionProps[][];
}

const ListDropdownGranite = ({
  label,
  withCaret = false,
  options,
  ...props
}: ListDropdownGraniteProps) => {
  const dropdownButtonRef = useRef(null);
  const [isListVisible, setIsListVisible] = useState(false);

  return (
    <>
      <ListDropdownButton
        ref={dropdownButtonRef}
        onClick={() => setIsListVisible((isVisible) => !isVisible)}
        {...props}
      >
        {label}
        {withCaret && (
          <ListDropdownButtonCaret
            name={isListVisible ? 'caretUp' : 'caretDown'}
          />
        )}
      </ListDropdownButton>
      <BaseListDropdown
        isOpen={isListVisible}
        toggle={() => setIsListVisible(false)}
        options={options}
        triggerRef={dropdownButtonRef}
      />
    </>
  );
};

export interface BaseListDropdownProps {
  options?: ListDropdownOptionProps[][];
  triggerRef: RefObject<HTMLElement | null>;
  isOpen: boolean;
  toggle: () => void;
  closeOnClickOutside?: boolean;
  popperOptions?: Partial<Options>;
}

export const BaseListDropdown = ({
  options,
  triggerRef,
  isOpen,
  toggle,
  closeOnClickOutside,
  popperOptions,
}: BaseListDropdownProps) => {
  const popperElementRef = useRef<HTMLDivElement>(null);
  const { styles, attributes } = usePopper(
    triggerRef?.current,
    popperElementRef.current,
    {
      placement: 'bottom-start',
      ...popperOptions,
    },
  );
  const optionListRefs = useRef<Set<HTMLButtonElement>>(new Set());
  // TODO: Might have a bug when scrolling and content is non-scrollable
  // useEffect(() => {
  //   if (isListVisible) {
  //     optionListRefs.current.forEach((elt) =>
  //       elt.scrollIntoView({ behavior: 'instant' })
  //     );
  //   } else {
  //     optionListRefs.current.clear();
  //   }
  // }, [isListVisible]);

  useEffect(() => {
    if (!closeOnClickOutside) return;
    const handleClickOutside = (event: MouseEvent) => {
      const isClickOutside =
        popperElementRef.current &&
        event.target instanceof Node &&
        !popperElementRef.current.contains(event.target);

      if (isClickOutside) {
        toggle();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeOnClickOutside, toggle]);

  return (
    <ListDropdownContainer
      ref={popperElementRef}
      style={styles.popper}
      {...attributes.popper}
    >
      {isOpen
        ? options?.map((optionGroup, i) => (
            <ListDropdownOptionsTrack key={i}>
              {optionGroup.map((option, i) => (
                <ListDropdownOption
                  ref={(r) => {
                    if (option.isSelected && r) {
                      optionListRefs.current.add(r);
                    }
                  }}
                  key={i}
                  $isSelected={option.isSelected}
                  onClick={option.onClick}
                >
                  {option.label}
                </ListDropdownOption>
              ))}
            </ListDropdownOptionsTrack>
          ))
        : null}
    </ListDropdownContainer>
  );
};

export default ListDropdownGranite;
