import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StepDetail, getStepDetails } from 'components/ProductTour/steps';

export interface SkippedStep {
  dashboard: number;
  techexpress: number;
  nocexpress: number;
  mobility: number;
  user_management: number;
}

interface StepProgress {
  step: number;
  section: string;
  status: string;
  id?: string;
  created_at?: string;
  updated_at?: string;
}

interface ProductTourContextProps {
  setRunning: Dispatch<SetStateAction<boolean>>;
  running: boolean;
  stepIndex: number;
  setStepIndex: (step: number) => void;
  stepDetails: StepDetail;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  isModalOpen: boolean;
  setStepHistory: Dispatch<SetStateAction<number[]>>;
  stepHistory: number[];
  completedSections: Set<string>;
  markSectionAsCompleted: (section: string) => void;
  progressData: StepProgress[];
  setProgressData: (progress: StepProgress[]) => void;
  currentStep: number;
}

const ProductTourContext = createContext<ProductTourContextProps>(
  {} as ProductTourContextProps,
);

export const ProductTourContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [running, setRunning] = useState(false);
  const [stepHistory, setStepHistory] = useState<number[]>([0]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [completedSections, setCompletedSections] = useState<Set<string>>(
    new Set(),
  );
  const [stepIndex, setStepIndex] = useState(0);
  const [progressData, setProgressData] = useState<StepProgress[]>([]);
  const currentStep = useMemo(
    () => stepHistory[stepHistory.length - 1],
    [stepHistory],
  );

  useEffect(() => {
    setTimeout(() => {
      setStepIndex(stepHistory[stepHistory.length - 1]);
    }, 105);
  }, [stepHistory]);

  const stepDetails = useMemo(() => {
    const stepDetails = getStepDetails();
    return stepDetails[currentStep];
  }, [currentStep]);

  const markSectionAsCompleted = (section: string) => {
    setCompletedSections((prev) => new Set(prev).add(section));
  };

  const fullCurrentUrl = `${location.pathname}${location.search}`;

  useEffect(() => {
    if (running && fullCurrentUrl !== stepDetails?.pathname) {
      navigate(stepDetails?.pathname);
    }
  }, [stepDetails, fullCurrentUrl, running, navigate]);

  return (
    <ProductTourContext.Provider
      value={{
        setRunning,
        running,
        setIsModalOpen,
        isModalOpen,
        setStepHistory,
        stepHistory,
        stepDetails,
        completedSections,
        markSectionAsCompleted,
        progressData,
        setProgressData,
        stepIndex,
        setStepIndex,
        currentStep,
      }}
    >
      {children}
    </ProductTourContext.Provider>
  );
};

export const useProductTourContext = () => useContext(ProductTourContext);
