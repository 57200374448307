import { GraniteButton } from 'components/V2/Button/GraniteButton';
import Logo from 'components/Logo';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CUSTOMER_DESCRIPTION_TYPES,
  SignUpForm,
  SignUpSchema,
} from 'api/auth/schema';
import { GraniteSelect } from 'components/Select/Select';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { signup } from 'api/auth/auth';
import showToast from 'components/Toast/Toast';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import RadioButton from 'components/RadioButton';

export const SignUp = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
  } = useForm<SignUpForm>({
    resolver: zodResolver(SignUpSchema),
    defaultValues: {
      org_name: '',
      has_granite_service: false,
    },
  });
  const has_granite_service = watch('has_granite_service');

  const { mutate, isLoading } = useMutation(
    (payload: SignUpForm) => signup(payload),
    {
      onSuccess: (data) => {
        if (data.status === 'success') {
          navigate('/auth/select-company');
        } else if (data.status === 'fall_out') {
          let url = '/auth/pending-verification';
          if (data.info?.no_company && data.info?.ticket_id)
            url += `?ticket-id=${data.info.ticket_id}`;
          navigate(url);
        }
      },
      onError: ({ response }) => {
        showToast.error({
          message: response.data.message ?? 'Something went wrong',
        });
        navigate('/auth/pending-verification');
      },
    },
  );

  const onSubmit = (payload: SignUpForm) => {
    mutate(payload);
  };

  const onError = (err: unknown) => {
    console.log({ err });
  };

  return (
    <div className="flex h-screen flex-wrap items-center justify-center md:py-6">
      <div className="flex min-h-full w-full flex-col justify-center gap-8 bg-background-base-surface-2 px-6 py-12 md:min-h-0 md:w-[670px] md:px-12">
        <div className="flex flex-col items-center gap-6">
          <Logo className="!h-auto !w-[126px]" />
          <h1 className="font-semibold text-content-base-default">
            Finish setting up your account
          </h1>
        </div>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit, onError)}
          className="space-y-6"
          id="signup-form"
        >
          <GraniteInput
            label="Company name"
            innerInputClassName="w-full"
            className="w-full"
            placeholder="Company name"
            {...register('org_name')}
            error={errors.org_name?.message}
          />
          <Controller
            name="has_granite_service"
            control={control}
            render={({ field: { value, onChange, ref }, fieldState }) => (
              <GraniteLabel
                label="Do you currently have Granite services?"
                element="div"
              >
                <RadioButton
                  options={[
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' },
                  ]}
                  className="[&>div]:!gap-6"
                  horizontal
                  error={fieldState.error?.message}
                  selectedValue={value ? 'true' : 'false'}
                  onChange={(newValue) => onChange(newValue === 'true')}
                  ref={ref}
                />
              </GraniteLabel>
            )}
          />
          {has_granite_service && (
            <Controller
              name="type"
              control={control}
              render={({ field: { ref: _ref, ...field } }) => (
                <GraniteSelect
                  {...field}
                  label="Which of these best describes you?"
                  options={CUSTOMER_DESCRIPTION_TYPES.options.map((option) => ({
                    label: option,
                    value: option,
                  }))}
                  className="w-full"
                  placeholder="Select"
                  // @ts-expect-error type exists only for has_granite_service = true
                  error={errors.type?.message}
                />
              )}
            />
          )}
        </form>
        <div className="flex flex-col items-center gap-4">
          <GraniteButton
            size="large"
            variant="primary"
            type="submit"
            form="signup-form"
            className="w-full !p-0"
            disabled={isLoading}
          >
            Continue
          </GraniteButton>
        </div>
      </div>
    </div>
  );
};
