const PrimaryDetails = ({ label, value }: { label: string; value: string }) => {
  return (
    <div className="flex w-full flex-col items-start justify-start ">
      <h2 className="text-base font-bold text-content-base-subdued">{label}</h2>
      <p className="text-base font-bold text-content-base-default">{value}</p>
    </div>
  );
};

export default PrimaryDetails;
