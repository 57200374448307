import { GetStartedQuestionnaireForm } from '../../GetStarted/schemas';
import { SmartHandsForm } from './schemas';
import { ReviewTicketForm } from '../../Review/schemas';
import { questionnaireFormToReviewTicketDefaultValues } from '../../BaseComponents/questionnaireFormToReviewTicketDefaultValues';
import {
  NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX,
  NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX,
} from '../../utils/nonStandardToolsSOWUtils';
import { formatContactTesting } from 'shared/util/util';

export const smartHandsFormToReview = (
  questionnaire: GetStartedQuestionnaireForm,
  form: SmartHandsForm,
): Partial<ReviewTicketForm> => {
  return {
    ...questionnaireFormToReviewTicketDefaultValues(questionnaire),
    scopeOfWork: formToSOW(form),
    nonStandardTools: form.nonStandardTools,
  };
};

const formToSOW = (form: SmartHandsForm) => {
  return [
    form.nonStandardTools.isNeeded
      ? `${NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX} ${form.nonStandardTools.nonStandardTools} ${NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX}\n`
      : '',
    `Service technician needed on site to ${form.installOrTroubleShootingLV.label.toLowerCase()}.`,
    form.installOrTroubleShootingGuideLV.value !== 'No'
      ? '\nThere will be a install/troubleshooting guide available to the technician on site.'
      : '',
    `\nThe technician will be working on: ${form.whichEquipment}.`,
    form.portMapping
      ? `\nThe port mapping is as follows: ${form.portMapping}.`
      : '',
    form.techToTestWith.techToTestWith !== 'No'
      ? `\n${
          form.itTestingContacts.length > 1
            ? 'The technician should test with the following technical contacts'
            : `The technician will need to test with ${
                form.techToTestWith.techToTestWith === 'CustomerIT'
                  ? 'customer IT'
                  : 'person on site'
              }`
        }: ${formatContactTesting(form.itTestingContacts)}`
      : '',
  ]
    .filter((l) => l)
    .join('\n');
};
