import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { CheckmarkCircle } from 'react-ionicons';

const AllSetDialog = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="flex flex-col items-center gap-8 rounded-lg bg-background-base-surface-2 p-8">
      <CheckmarkCircle width="96px" height="96px" color="#82FF91" />
      <p className="text-center text-xl font-bold text-content-base-default">
        All set! Granite will review this quote request and will be in touch
        with you soon to deliver a formal quote.
      </p>
      <GraniteButton className="mt-4" size="large" onClick={onClose}>
        Close
      </GraniteButton>
    </div>
  );
};

export default AllSetDialog;
