import React, { useMemo, useState } from 'react';
import CustomSelectControl from './CustomSelectControl';
import { OptionType } from 'components/Select/Select';
import { inventoryChartFilterOptions } from './utils';
import { InventoryItem } from 'api/inventory/schema';
import { useQuery } from 'react-query';
import { getInventoryRecentActivity } from 'api/inventory/api';
import { SingleValue } from 'react-select';
import RecentActivity from './RecentActivity';

export interface IRecentActivity {
  id: number;
  address1: string;
  type: string;
  description: string;
  tag: 'Added' | 'End dated' | undefined;
  service_id: string;
}

const buildRecentActivityItem = (apiData: InventoryItem): IRecentActivity => {
  return {
    id: apiData.id,
    address1: apiData.address,
    type: apiData.service_type ?? '',
    description: apiData.account_number
      ? `Account # ${apiData.account_number}`
      : '',
    tag: apiData.end_date ? 'End dated' : 'Added',
    service_id: apiData.service_id,
  };
};

const InventoryRecentActivity = () => {
  const [resetKey, setResetKey] = useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState('Past Month');

  const { data: recentActicityData, isLoading: isRecentActivityLoading } =
    useQuery(
      ['inventory-recent-activity', selectedDateRange],
      () =>
        getInventoryRecentActivity({
          date_range: selectedDateRange,
        }),
      {
        enabled: !!selectedDateRange,
      },
    );

  const buildRecentActivityData = useMemo(() => {
    return recentActicityData?.map((activity: InventoryItem) =>
      buildRecentActivityItem(activity),
    );
  }, [recentActicityData]);

  const handleFilterChange = (option: SingleValue<OptionType>) => {
    if (option?.value) {
      setSelectedDateRange(option.value);
    }
    setResetKey((prev) => prev + 1);
  };

  return (
    <div className="relative h-full">
      <div className="flex w-full items-start justify-between">
        <div className="flex w-1/2 flex-col gap-0">
          <h2 className="text-xl font-bold text-content-base-default md:text-2xl">
            Recent activity
          </h2>
          <div className="-mt-2">
            <CustomSelectControl
              options={inventoryChartFilterOptions}
              onChange={handleFilterChange}
            />
          </div>
        </div>
      </div>
      <RecentActivity
        key={resetKey}
        tickets={buildRecentActivityData ?? []}
        pageSize={5}
        isLoading={isRecentActivityLoading}
      />
    </div>
  );
};

export default InventoryRecentActivity;
