import { useCallback } from 'react';
import { Close } from 'react-ionicons';

import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { SearchAddressBar } from 'screens/AccessExpress/BaseComponents/SearchAddressBar/SearchAddressBar';
import { QuoteLocation } from 'api/accessexpress/schema';

export type LocationValue = QuoteLocation | undefined;

interface LocationFieldProps {
  index: number;
  onChange: (index: number, value: LocationValue) => void;
  value: LocationValue;
  onRemove: () => void;
  onMenuStateChange: (isOpen: boolean) => void;
}

export const LocationField = ({
  index,
  onChange,
  value,
  onRemove,
  onMenuStateChange,
}: LocationFieldProps) => {
  const handleChange = useCallback(
    (value: LocationValue) => onChange(index, value),
    [index, onChange],
  );
  return (
    <div style={{ display: 'flex' }}>
      <SearchAddressBar
        onAddressSelected={handleChange}
        className="w-[80%] flex-1"
        value={value}
        onFocus={() => onMenuStateChange(true)}
        onBlur={() => onMenuStateChange(false)}
      />
      {!value && (
        <span style={{ position: 'absolute', right: 0 }}>
          <GraniteButton
            size="small"
            variant="unstyled"
            onClick={onRemove}
            color="inherit"
            className="text-content-base-default"
          >
            <Close style={{ fill: '#94A3B8' }} />
          </GraniteButton>
        </span>
      )}
    </div>
  );
};
