import { useOktaAuth } from '@okta/okta-react';
import { useSearchParams } from 'react-router-dom';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import Logo from 'components/Logo';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { BailOutForm, BailOutSchema } from 'api/auth/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { useMutation } from 'react-query';
import showToast from 'components/Toast/Toast';
import { signupWithAccountNumber } from 'api/auth/auth';
import { useState } from 'react';

export const PendingVerification = () => {
  const { oktaAuth } = useOktaAuth();
  const [isProvidingAccountNumber, setIsProvidingAccountNumber] =
    useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const ticketId = searchParams.get('ticket-id');

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<BailOutForm>({
    resolver: zodResolver(BailOutSchema),
  });

  const postFeedbackMutation = useMutation(
    (data: BailOutForm) =>
      signupWithAccountNumber(Number(ticketId), data.macnum),
    {
      onSuccess: () => {
        showToast.confirmation({
          message: 'Account submitted!',
        });
        setIsProvidingAccountNumber(false);
        setSearchParams();
      },
    },
  );

  const onSubmit = (data: BailOutForm) => {
    postFeedbackMutation.mutate(data);
  };

  const onError = (err: unknown) => console.log(err);

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-background-base-surface-1">
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        className="flex w-[624px] flex-col items-start gap-12 rounded bg-background-base-surface-2 p-8"
      >
        <Logo />
        <div className="space-y-4">
          <h1 className="rounded-t-lg text-[28px] font-bold leading-9 text-content-base-default">
            Account pending verification
          </h1>
          <p className="text-xl font-semibold text-content-base-subdued">
            Your sign up request has been received!
            {ticketId
              ? ' However, the account details provided do not match our records. A Granite representative will be in touch shortly to verify your identity and finalize your account.'
              : ' A Granite representative will be in touch shortly to verify your identity and finalize your account.'}
          </p>
          <div className={clsx('flex gap-x-2', !ticketId && 'hidden')}>
            <span className="text-content-base-default">
              Know your account number?
            </span>
            <button
              type="button"
              className="font-semibold text-content-accent-default"
              onClick={() => setIsProvidingAccountNumber(true)}
            >
              Provide it now
            </button>
          </div>
          {ticketId && isProvidingAccountNumber && (
            <GraniteInput
              label="Highest level Granite account #"
              innerInputClassName="w-full"
              className="w-full"
              placeholder="E.g., 01234567"
              error={errors.macnum?.message}
              {...register('macnum')}
            />
          )}
        </div>
        <div className="mt-4 flex gap-5">
          <GraniteButton
            type="button"
            size="large"
            variant={
              ticketId && !isProvidingAccountNumber ? 'primary' : 'secondary'
            }
            onClick={() => oktaAuth.signOut()}
          >
            Return to login
          </GraniteButton>
          {ticketId && isProvidingAccountNumber && (
            <GraniteButton type="submit" size="large" variant="primary">
              Submit
            </GraniteButton>
          )}
        </div>
      </form>
    </div>
  );
};
