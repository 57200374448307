import { ColumnDef } from '@tanstack/react-table';
import { PageTitleGranite } from 'components';
import Searchbar from 'components/Table/SearchBar';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { EditResourceDialog } from './EditResourceDialog';
import { useModal, useModalReturn } from 'hooks/useModal';
import { ResourcePreviewDialog } from './Preview';
import { DeleteResourceDialog } from './DeleteResourceDialog';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import {
  createResource,
  deleteResource,
  getResources,
  getSignedUrl,
  getTags,
  updateResource,
} from 'api/resources/api';
import {
  Resource,
  ResourceForm,
  GetResourceFilterSearchParams,
} from 'api/resources/schema';
import { useSortedTable } from 'hooks/useSortedTable';
import {
  StackedCell,
  StackedCellMainContent,
  StackedCellSubtitle,
} from 'components/Table/Table.styles';
import { endOfDay, format, parseISO, startOfDay, subMonths } from 'date-fns';
import { getQueryClient } from 'query-client';
import showToast from 'components/Toast/Toast';
import { useMutation, useQuery } from 'react-query';
import { useFilterForTable } from 'hooks/useFilterForTable';
import { SingleValue } from 'react-select';
import { GraniteSelect, OptionType } from 'components/Select/Select';
import { dateToUTCWithoutMS } from 'shared/util/dateToUtcStrWithoutMS';
import Filters from 'components/Filters';
import { SuggestedResources } from './SuggestedResources';
import { useDownloadAsExcel } from 'hooks/useDownloadAsExcel';
import { Chip } from 'components/Chip/Chip';
import { usePermissions } from 'hooks/usePermissions';
import { TokenUserPermissions } from 'api/users/schemas/Users';

const fileTypeOptions = [
  { value: '.pdf', label: 'PDF' },
  { value: '.docx', label: 'Word' },
  { value: '.xlsx', label: 'Excel' },
  { value: '.csv', label: 'CSV' },
  { value: '.png,.jpg,.jpeg', label: 'Image' },
];

export const filterByDate = [
  {
    value: dateToUTCWithoutMS(subMonths(startOfDay(new Date()), 1)),
    label: 'Past month',
  },
  {
    value: dateToUTCWithoutMS(subMonths(startOfDay(new Date()), 3)),
    label: 'Past 3 months',
  },
];

export const formatDate = (date?: string) => {
  if (date) {
    const parsedDate = parseISO(date);
    return format(parsedDate, 'MM/dd/yyyy');
  } else {
    return '';
  }
};

const queryClient = getQueryClient();

type ActionsContextProps = {
  deleteModalProps: useModalReturn<Resource>;
  editModalProps: useModalReturn<Resource>;
  previewModalProps: useModalReturn<Resource>;
  hasWritePermission: boolean;
  downloadFile: (resource: Resource) => void;
};

const ActionsContext = createContext<ActionsContextProps>(
  {} as ActionsContextProps,
);

const tableColumns: ColumnDef<Resource>[] = [
  {
    header: 'File name',
    accessorKey: 'title',
    cell: (row) => (
      <StackedCell>
        <StackedCellMainContent>
          {row.row.original.title}
        </StackedCellMainContent>
        <StackedCellSubtitle>
          {row.row.original.description}
        </StackedCellSubtitle>
      </StackedCell>
    ),
  },
  {
    id: 'date',
    accessorKey: 'created_at',
    header: 'Opened',
    accessorFn: (r) => formatDate(r.created_at),
  },
  {
    header: 'Type',
    accessorKey: 'file_type',
  },
  {
    header: 'Tags',
    cell: (row) => (
      <div className="flex items-start justify-start gap-4">
        {row.row.original.tags.map((tag) => (
          <span
            className="rounded bg-background-base-surface-3 px-2 py-1 font-bold text-content-base-default"
            key={tag.id}
          >
            {tag.name}
          </span>
        ))}
      </div>
    ),
  },
  {
    header: 'Actions',
    cell: (row) => <Actions data={row.row.original} />,
  },
];

const Actions = ({ data }: { data: Resource }) => {
  const { hasWritePermission } = useContext(ActionsContext);
  return hasWritePermission ? (
    <AdminActions data={data} />
  ) : (
    <ViewerActions data={data} />
  );
};

const AdminActions = ({ data }: { data: Resource }) => {
  const { editModalProps, deleteModalProps } = useContext(ActionsContext);
  return (
    <div className="flex items-start justify-start gap-3 pr-1.5">
      <GraniteButton
        onClick={() => {
          editModalProps.openWithProps(data);
        }}
        size="small"
        variant="secondary"
      >
        Edit
      </GraniteButton>
      <GraniteButton
        onClick={() => {
          deleteModalProps.openWithProps(data);
        }}
        size="small"
        variant="destructive"
      >
        Delete
      </GraniteButton>
    </div>
  );
};

const ViewerActions = ({ data }: { data: Resource }) => {
  const { previewModalProps, downloadFile } = useContext(ActionsContext);
  return (
    <div className="flex items-start justify-start gap-3 pr-1.5">
      <GraniteButton
        onClick={() => {
          previewModalProps.openWithProps(data);
        }}
        size="small"
        variant="secondary"
      >
        View
      </GraniteButton>
      <GraniteButton
        onClick={() => downloadFile(data)}
        size="small"
        variant="secondary"
      >
        Download
      </GraniteButton>
    </div>
  );
};

const Resources: React.FC = () => {
  const { hasPermission } = usePermissions({
    permission: TokenUserPermissions.RESOURCES_WRITE,
  });

  const editModalProps = useModal<Resource>();
  const deleteModalProps = useModal<Resource>();
  const previewModalProps = useModal<Resource>();

  const {
    queryParamFilter: tagFilter,
    clearFilter: clearTagFilters,
    ...tagFilterProps
  } = useFilterForTable({
    queryParamKey: 'tags',
  });
  const {
    queryParamFilter: fileTypeFilter,
    clearFilter: clearFileTypeFilters,
    ...fileTypeFilterProps
  } = useFilterForTable({
    queryParamKey: 'file_type',
  });

  const [dateFilter, setDateFilter] = useState<SingleValue<OptionType>>(null);
  const [search, setSearch] = useState('');
  const { sortingQueryParams, sortingState, onSortingChange } = useSortedTable({
    initialSorting: [{ id: 'date', desc: true }],
  });

  const getResourcesQueryFn = useCallback(
    (args?: GetResourceFilterSearchParams) => {
      return getResources({
        ...tagFilter,
        ...fileTypeFilter,
        ...(dateFilter
          ? {
              start_date: dateFilter.value,
              end_date: dateToUTCWithoutMS(endOfDay(new Date())),
            }
          : {}),
        ...(search ? { search } : {}),
        ...sortingQueryParams,
        ...args,
      });
    },
    [dateFilter, tagFilter, search, sortingQueryParams, fileTypeFilter],
  );

  const { data: tablePageData, ...paginatedTableProps } = usePaginatedTable(
    getResourcesQueryFn,
    {
      sortingQueryParams,
      dateFilter,
      tagFilter,
      search,
      fileTypeFilter,
    },
    [
      'resources',
      {
        sortingQueryParams,
        dateFilter,
        tagFilter,
        search,
        fileTypeFilter,
      },
    ],
    { refetchOnMount: true },
  );

  const { data: tags } = useQuery(['resources', 'tags'], getTags);

  const tagOptions = useMemo(
    () => tags?.map(({ name }) => ({ value: name, label: name })),
    [tags],
  );

  const { mutate: deleteResourceMutation } = useMutation(
    (resource: Resource) => deleteResource(resource.id),
    {
      onSuccess: (_, resource) => {
        showToast.confirmation({
          title: 'File successfully deleted',
          message: resource.title,
        });
        queryClient.invalidateQueries({
          queryKey: ['resources'],
        });
      },
      onError: (_, resource) => {
        showToast.error({
          title: 'Delete resource unsuccessful',
          message: resource.title,
        });
      },
      onSettled: () => {
        deleteModalProps.close();
      },
    },
  );

  const { mutate: createResourceMutation, isLoading: isPostingData } =
    useMutation(createResource, {
      onSuccess: (_, resource) => {
        showToast.confirmation({
          title: 'Resource uploaded!',
          message: resource.get('title') as string,
        });
        queryClient.invalidateQueries({
          queryKey: ['resources'],
        });
      },
      onError: (_) => {
        showToast.error({
          title: 'File upload failed',
          message: `File type not supported`,
        });
      },
      onSettled: () => {
        editModalProps.close();
      },
    });

  const { mutate: updateResourceMutation, isLoading: isUpdatingData } =
    useMutation(updateResource, {
      onSuccess: (_, { data }) => {
        showToast.confirmation({
          title: 'Resource updated!',
          message: data.get('title') as string,
        });
        queryClient.invalidateQueries({
          queryKey: ['resources'],
        });
      },
      onError: (_, { data }) => {
        showToast.error({
          title: 'Resource update failed',
          message: data.get('title') as string,
        });
      },
      onSettled: () => {
        editModalProps.close();
      },
    });

  const onSubmit = async (data: ResourceForm) => {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('is_suggested', `${data.is_suggested}`);
    if (data.description) formData.append('description', data.description);
    if (data.attachments && data.attachments.length > 0)
      formData.append('file', data.attachments[0]);
    if (data.tags && data.tags.length > 0)
      formData.append('tags', data.tags.map(({ value }) => value).join(','));

    if (data.id) updateResourceMutation({ id: data.id, data: formData });
    else createResourceMutation(formData);
  };

  const buttonAction = {
    action: () => editModalProps.open(),
    label: 'Upload new',
  };

  const clearAllFilters = useCallback(() => {
    clearFileTypeFilters();
    clearTagFilters();
    setDateFilter(null);
    setSearch('');
  }, [clearTagFilters, clearFileTypeFilters]);

  const downloadFile = async (resource: Resource) => {
    try {
      const { url } = await getSignedUrl(resource.id);
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = resource.filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      showToast.error({ message: 'Something went wrong!' });
    }
  };

  const createExcelFile = useDownloadAsExcel(async () => {
    const { data: files } = await getResourcesQueryFn();
    const transformedData = files.map((file) => ({
      'File name': file.filename,
      Opened: file.created_at,
      Type: file.file_type,
      Tags: file.tags.map((item) => item.name).join(', '),
    }));
    return transformedData;
  }, 'Resources_files');

  return (
    <ActionsContext.Provider
      value={{
        editModalProps,
        deleteModalProps,
        previewModalProps,
        hasWritePermission: hasPermission,
        downloadFile,
      }}
    >
      <ContentLayout>
        {hasPermission && (
          <PageTitleGranite title={'Resources'} buttonAction={buttonAction} />
        )}
        <div className="mt-6 flex w-full flex-col items-start justify-start gap-6">
          <SuggestedResources
            openResourcePreview={previewModalProps.openWithProps}
          />

          <h1 className="mb-2 text-[28px] font-bold leading-9 text-content-base-default">
            All Files
          </h1>

          <div className="flex w-full flex-col items-start justify-start">
            <div className="mb-4 flex w-full flex-wrap items-start justify-between gap-4">
              <div className="w-full sm:flex-1">
                <Searchbar
                  placeholder="Search by file name or tag"
                  clearAllValues={search === ''}
                  onQueryClear={() => setSearch('')}
                  onSearch={(query: string) => {
                    setSearch(query);
                  }}
                />
              </div>
              <Filters
                clearFilters={clearAllFilters}
                clearFilterClassName="col-span-full md:col-span-1"
              >
                <GraniteSelect
                  options={fileTypeOptions}
                  isMulti
                  className="col-span-2 md:col-span-2 xl:col-span-1"
                  placeholder="Filter by file type"
                  controlShouldRenderValue={false}
                  isSearchable={false}
                  {...fileTypeFilterProps}
                />
                <GraniteSelect
                  options={tagOptions}
                  isMulti
                  className="col-span-2 md:col-span-2 xl:col-span-1"
                  placeholder="Filter by tag"
                  controlShouldRenderValue={false}
                  isSearchable={false}
                  {...tagFilterProps}
                />
                <GraniteSelect
                  options={filterByDate}
                  className="col-span-2 md:col-span-2 xl:col-span-1"
                  placeholder="Filter by date added"
                  isClearable={false}
                  isSearchable={false}
                  controlShouldRenderValue={false}
                  onChange={setDateFilter}
                  value={dateFilter}
                />
              </Filters>
            </div>
            <div className="mb-12 flex flex-wrap gap-4">
              {fileTypeFilterProps.value.map((sf) => (
                <Chip
                  key={sf.value}
                  label={sf.label}
                  onDelete={() =>
                    fileTypeFilterProps.onChange(
                      fileTypeFilterProps.value.filter(
                        (option) => option.value !== sf.value,
                      ),
                    )
                  }
                />
              ))}
              {tagFilterProps.value.map((sf) => (
                <Chip
                  key={sf.value}
                  label={sf.label}
                  onDelete={() =>
                    tagFilterProps.onChange(
                      tagFilterProps.value.filter(
                        (option) => option.value !== sf.value,
                      ),
                    )
                  }
                />
              ))}
              {dateFilter?.value && (
                <Chip
                  label={dateFilter.label}
                  onDelete={() => setDateFilter(null)}
                />
              )}
            </div>
            <div className="w-full">
              <ServerPaginatedTable
                data={tablePageData}
                columns={tableColumns}
                title="All files"
                sortingState={sortingState}
                onSortingChange={onSortingChange}
                downloadTableFn={createExcelFile}
                {...paginatedTableProps}
              />
            </div>
          </div>

          <EditResourceDialog
            tags={tags}
            onSubmit={onSubmit}
            isLoading={isPostingData || isUpdatingData}
            {...editModalProps}
          />
          <ResourcePreviewDialog
            downloadFile={downloadFile}
            {...previewModalProps}
          />
          <DeleteResourceDialog
            handleConfirm={deleteResourceMutation}
            {...deleteModalProps}
          />
        </div>
      </ContentLayout>
    </ActionsContext.Provider>
  );
};

export default Resources;
