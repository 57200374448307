import { GraniteInput } from 'components/V2/Input/GraniteInput';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { PermissionFormType } from 'screens/RolesPermissions/roles-permissions.types';

const Form = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<PermissionFormType>();

  return (
    <div className="space-y-6">
      <GraniteInput
        {...register('name')}
        label="Permission"
        subtitle="Define the permissions (scopes) that the API uses."
        error={errors.name?.message}
        placeholder="E.g., read:techexpress"
      />
      <GraniteInput
        {...register('description')}
        label="Description"
        subtitle="Describe the purpose of this new permission."
        error={errors.description?.message}
        placeholder="E.g., Read TechExpress APIs"
      />
    </div>
  );
};

export default Form;
