import { CheckmarkCircle } from 'react-ionicons';
import clsx from 'clsx';

import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ReactComponent as Vektor } from 'assets/images/looking_service_options.svg';

interface LookingForMoreServiceOptionsProps {
  onSelect: () => void;
  isSelected: boolean;
}

const LookingForMoreServiceOptions = ({
  onSelect,
  isSelected,
}: LookingForMoreServiceOptionsProps) => {
  return (
    <div
      className={clsx(
        'relative z-10 flex h-full cursor-pointer flex-col gap-2 overflow-hidden rounded-md bg-background-base-surface-3',
        'border border-transparent p-[17px] transition-colors duration-200 hover:border-stroke-accent-default',
        isSelected && 'border-2 !border-stroke-accent-default p-[16px]',
      )}
      onClick={() => onSelect()}
    >
      <div
        className={clsx(
          'absolute bottom-0 left-0 right-0',
          isSelected && '-bottom-[1px] -left-[1px] -right-[1px]',
        )}
      >
        <Vektor className="w-full" />
      </div>
      <p className="font-bold text-content-base-default">
        Looking for more service options?
      </p>
      <p className="text-sm font-semibold">
        Select this option to have a Granite representative contact you with
        more information about the available options at this location.
      </p>
      <GraniteButton
        size="medium"
        variant={isSelected ? 'primary' : 'secondary'}
        className="mt-4 w-auto max-w-[133px]"
      >
        <span className="whitespace-nowrap">Contact me </span>
        {isSelected ? (
          <CheckmarkCircle width="20px" height="20px" color="#191925" />
        ) : null}
      </GraniteButton>
    </div>
  );
};

export default LookingForMoreServiceOptions;
