import PageTitle from 'components/PageTitle';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { alertData } from '../Alerting';
import AlertCard from '../AlertCard';
import AlertConfigurationTabel from './AlertConfigurationTabel';

const AlertConfiguration = () => {
  const navigate = useNavigate();
  const { alertId } = useParams();
  const { pathname } = useLocation();

  const onClick = (event: React.MouseEvent<HTMLDivElement>) =>
    navigate(
      `${pathname.replace(
        encodeURIComponent(`${alertId}`),
        event.currentTarget.id,
      )}`,
      {
        replace: true,
      },
    );

  const breadcrumbs = [
    {
      icon: 'notifications',
      label: 'Alert preferences',
      onClick: () => navigate('/noc-express?tab=alerting'),
    },
  ];

  return (
    <ContentLayout>
      <PageTitle title={'Alert configuration'} breadcrumbs={breadcrumbs} />
      <h2 className="mt-12 flex items-center gap-4 text-2.5xl font-bold text-white">
        Select alert type
      </h2>
      <div className="mb-16 mt-6 grid grid-cols-3 gap-6 lg:grid-cols-4 xl:grid-cols-5">
        {alertData.map((d, i) => (
          <AlertCard
            key={i}
            onClick={onClick}
            selected={alertId === d.name}
            size="medium"
            state={!!d.active_device_number}
            {...d}
          />
        ))}
      </div>
      <AlertConfigurationTabel />
    </ContentLayout>
  );
};

export default AlertConfiguration;
