import { useState, useEffect } from 'react';

export const useBannerVisibility = (storageKey: string) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const isDismissed = localStorage.getItem(storageKey);
    if (!isDismissed) {
      setIsVisible(true);
    }
  }, [storageKey]);

  const handleClose = () => {
    localStorage.setItem(storageKey, 'true');
    setIsVisible(false);
  };

  return { isVisible, handleClose };
};
