import styled from 'styled-components';

export const ProductsToLocationsTable = styled.div<{
  $visibleProductsCount?: number;
}>`
  display: grid;
  grid-template-columns: 18px minmax(100px, 250px) 97px;
  grid-auto-columns: auto;
  column-gap: 48px;
  grid-template-rows: auto;
  grid-auto-rows: 56px 1px;
`;
