/* eslint-disable @typescript-eslint/no-explicit-any */

type EventListener<T> = (data: T) => void;

export class EventDispatcher<TEvents extends Record<string, any>> {
  private listeners = new Map<
    keyof TEvents,
    EventListener<TEvents[keyof TEvents]>[]
  >();

  async emit<K extends keyof TEvents>(eventName: K, event: TEvents[K]) {
    const eventListeners = this.listeners.get(eventName) as
      | EventListener<TEvents[K]>[]
      | undefined;
    if (eventListeners) {
      for (const listener of eventListeners) {
        listener(event);
      }
    }
  }

  on<K extends keyof TEvents>(
    eventName: K,
    listener: EventListener<TEvents[K]>,
  ) {
    if (!this.listeners.has(eventName)) {
      this.listeners.set(eventName, [
        listener as EventListener<TEvents[keyof TEvents]>,
      ]);
    } else {
      (this.listeners.get(eventName) as EventListener<TEvents[K]>[]).push(
        listener,
      );
    }

    // Return a function to remove the listener
    return () => {
      this.off(eventName, listener);
    };
  }

  off<K extends keyof TEvents>(
    eventName: K,
    listener: EventListener<TEvents[K]>,
  ) {
    const eventListeners = this.listeners.get(eventName) as
      | EventListener<TEvents[K]>[]
      | undefined;
    if (eventListeners) {
      const listenerIndex = eventListeners.indexOf(listener);
      if (listenerIndex >= 0) {
        eventListeners.splice(listenerIndex, 1);
      }
    }
  }
}
