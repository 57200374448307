import { BeaconRenderProps } from 'react-joyride';

const CustomBeacon = (props: BeaconRenderProps) => {
  return (
    <button
      {...props}
      className="text-gray-500 relative z-[10000] inline-block h-9 w-9 cursor-pointer appearance-none rounded-none border-0 bg-transparent p-2"
      type="button"
    >
      <span className="absolute left-1/2 top-1/2 h-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-content-accent-default opacity-70" />
      <span className="absolute left-0 top-0 h-full w-full animate-beacon-outer rounded-full border-2 border-content-accent-default bg-content-accent-default bg-opacity-20 opacity-90" />
    </button>
  );
};

export default CustomBeacon;
