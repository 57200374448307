import React from 'react';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { Star } from 'react-ionicons';
import { useModal } from 'hooks/useModal';
import ProvideFeedbackModal from './ProvideFeedbackModal';
import {
  BaseFeedbackResponseSchema,
  FeedbackSchemaResponse,
} from './form-schema';
import { QueryObserverResult } from 'react-query';

export type RefetchType = () => Promise<
  QueryObserverResult<BaseFeedbackResponseSchema, unknown>
>;

const ProvideFeedbackBanner = ({
  ticketId,
  feedback,
  refetch,
}: {
  ticketId: string;
  feedback?: BaseFeedbackResponseSchema;
  refetch: RefetchType;
}) => {
  const { open, isOpen, ...modalProps } = useModal();

  const { success } = FeedbackSchemaResponse.safeParse(feedback);
  return (
    <div className="provide-feedback provide-feedback-border col-span-full flex w-full items-center justify-start gap-2 border p-4">
      <span className="fill-teal-400">
        <Star color={'inherit'} height={'22px'} width={'22px'} />
      </span>
      <div className="flex w-full flex-col items-start justify-start gap-2">
        <h1 className="text-base font-bold text-content-base-default">
          {success ? 'Thank you for your feedback!' : 'Rate your experience'}
        </h1>
        {!success && (
          <p className="text-sm font-semibold text-content-base-default">
            How did we do? Leave us feedback on this dispatch.
          </p>
        )}
        <GraniteButton variant="primary" size="small" onClick={open}>
          {success ? 'Review feedback' : 'Provide feedback'}
        </GraniteButton>
      </div>
      {isOpen && (
        <ProvideFeedbackModal
          ticketId={ticketId}
          feedback={feedback}
          isOpen={isOpen}
          refetch={refetch}
          {...modalProps}
        />
      )}
    </div>
  );
};

export default ProvideFeedbackBanner;
