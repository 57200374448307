import {
  StackedCell,
  StackedCellMainContent,
  StackedCellSubtitle,
  TableTitle,
} from 'components/Table/Table.styles';
import Searchbar from 'components/Table/SearchBar';
import Filters from 'components/Filters';
import { GraniteSelect } from 'components/Select/Select';
import { useFilterForTable } from 'hooks/useFilterForTable';
import { useCallback, useMemo, useState } from 'react';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import { useSortedTable } from 'hooks/useSortedTable';
import { EmptySearchResults } from 'screens/QuoteIndex/QuoteIndexPage';
import { ColumnDef } from '@tanstack/react-table';
import Checkbox from 'components/Checkbox';
import { getAlertConfigurationsMockData } from '../utils';
import ChipMapper from './ChipMapper';
import EditButton from './EditButton';
import { useModal } from 'hooks/useModal';
import AlertConfigurationForm from './Form/AlertConfigurationForm';
import { TokenUserPermissions } from 'api/users/schemas/Users';
import { Modal } from 'components/Modal/Modal';
import Metrics from './Metrics';
import Status from './Status';
import { PaginableRequest } from 'api/common-schemas';
import { usePermissions } from 'hooks/usePermissions';

export type AlertConfigurationItemType = {
  id: number;
  device: string;
  model: { name: string; company_name: string };
  account: string;
  city: string;
  address_1: string;
  status: 'Enabled' | 'Not eligible' | 'Disabled';
  time: number | null;
  'high-latency': number | null;
  recovery: number | null;
};

export type AlertConfigurationListItemType = {
  checked: boolean;
} & AlertConfigurationItemType;

const AlertConfigurationTabel = () => {
  const [search, setSearch] = useState<string>('');
  const [tableData, setTableData] = useState<AlertConfigurationListItemType[]>(
    [],
  );
  const [editAlerts, setEditAlerts] = useState<
    AlertConfigurationListItemType[]
  >([]);

  const { hasPermission } = usePermissions({
    permission: TokenUserPermissions.CONTACT_GROUPS_WRITE,
  });

  const {
    queryParamFilter: statusFilter,
    clearFilter: clearStatusFilter,
    ...statusFilterProps
  } = useFilterForTable({ queryParamKey: 'status' });

  const {
    queryParamFilter: modelFilter,
    clearFilter: clearModelFilter,
    ...modelFilterProps
  } = useFilterForTable({ queryParamKey: 'model' });

  const {
    queryParamFilter: manufacturerilter,
    clearFilter: clearManufacturerFilter,
    ...manufacturerFilterProps
  } = useFilterForTable({ queryParamKey: 'manufacturer' });

  const clearAllFilters = useCallback(() => {
    clearStatusFilter();
    clearModelFilter();
    clearManufacturerFilter();
    setSearch('');
  }, [clearStatusFilter, clearModelFilter, clearManufacturerFilter]);

  const { sortingQueryParams, sortingState, onSortingChange } = useSortedTable({
    initialSorting: [{ id: 'device', desc: true }],
  });

  const getAlertCongiurations = (args: PaginableRequest) => {
    // API call
    return Promise.resolve(getAlertConfigurationsMockData(args));
  };

  const { data: tablePageData, ...paginatedTableProps } = usePaginatedTable(
    getAlertCongiurations,
    {
      search,
      statusFilter,
      modelFilter,
      manufacturerilter,
      sortingQueryParams,
    },
    [
      'alert-index-table',
      search,
      statusFilter,
      modelFilter,
      manufacturerilter,
      sortingQueryParams,
    ],
    {
      onSuccess: ({ data }: { data: AlertConfigurationItemType[] }) => {
        setTableData(data.map((item) => ({ ...item, checked: false })));
      },
      refetchOnMount: true,
    },
  );

  const onChange = (alertId: AlertConfigurationItemType['id']) =>
    setTableData((prev) =>
      prev.map((row) =>
        row.id === alertId ? { ...row, checked: !row.checked } : row,
      ),
    );

  const onChangeAll = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTableData((prev) =>
      prev.map((row) => ({
        ...row,
        checked: e.target.checked && row.status !== 'Not eligible',
      })),
    );

  const isAllSelected = useMemo(
    () =>
      tableData.length > 0 &&
      tableData
        .filter((r) => r.status !== 'Not eligible')
        .every((row) => row.checked),
    [tableData],
  );
  const isSomeSelected = useMemo(
    () =>
      tableData
        .filter((r) => r.status !== 'Not eligible')
        .some((row) => row.checked) && !isAllSelected,
    [tableData, isAllSelected],
  );

  const totalSelected = useMemo(
    () => tableData.filter((row) => row.checked).length,
    [tableData],
  );

  const tableHeaders: ColumnDef<AlertConfigurationListItemType>[] = [
    ...(hasPermission
      ? ([
          {
            id: 'checkbox',
            header: () => (
              <Checkbox
                checked={isAllSelected}
                isPartiallChecked={isSomeSelected}
                onChange={onChangeAll}
                className="mx-1"
              />
            ),
            cell: (row) => {
              return (
                <div onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    disabled={row.row.original.status === 'Not eligible'}
                    checked={row.row.original.checked}
                    onChange={() => onChange(row.row.original.id)}
                    className="mx-1"
                  />
                </div>
              );
            },
          },
        ] as ColumnDef<AlertConfigurationListItemType>[])
      : []),
    {
      header: 'Device',
      accessorKey: 'device',
    },
    {
      header: 'Model',
      accessorKey: 'model',
      cell: (row) => (
        <StackedCell>
          <StackedCellMainContent>
            {row.row.original.model.name}
          </StackedCellMainContent>
          <StackedCellSubtitle>
            {row.row.original.model.company_name}
          </StackedCellSubtitle>
        </StackedCell>
      ),
    },
    {
      header: 'Account',
      accessorKey: 'account',
    },
    {
      header: 'Location',
      cell: (row) => (
        <StackedCell>
          <StackedCellMainContent>
            {row.row.original.city}
          </StackedCellMainContent>
          <StackedCellSubtitle>
            {row.row.original.address_1}
          </StackedCellSubtitle>
        </StackedCell>
      ),
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: (row) => <Status status={row.row.original.status} />,
    },
    {
      header: 'Time',
      accessorKey: 'time',
      cell: (row) => <Metrics value={row.row.original.time} metric="m" />,
      meta: {
        align: 'right',
      },
    },
    {
      header: 'High latency',
      accessorKey: 'high-latency',
      cell: (row) => (
        <Metrics value={row.row.original['high-latency']} metric="ms" />
      ),
      meta: {
        align: 'right',
      },
    },
    {
      header: 'Recovery',
      accessorKey: 'recovery',
      cell: (row) => <Metrics value={row.row.original.recovery} metric="ms" />,
      meta: {
        align: 'right',
      },
    },
  ];

  const { open, isOpen, close } = useModal();

  const onEditSingle = (alert: AlertConfigurationListItemType) => {
    if (!hasPermission) return;
    setEditAlerts([alert]);
    open();
  };

  const onBulkEdit = () => {
    setEditAlerts(tableData.filter((row) => row.checked));
    open();
  };

  return (
    <div>
      <TableTitle>Edit cellular signal</TableTitle>
      <div className="flex w-full flex-wrap items-start justify-between gap-4">
        <div className="w-full sm:flex-1">
          <Searchbar
            placeholder="Search by device, account, location"
            onSearch={setSearch}
            onQueryClear={() => {
              setSearch('');
            }}
            clearAllValues={search === ''}
          />
        </div>
        <Filters
          clearFilters={clearAllFilters}
          clearFilterClassName="col-span-full md:col-span-1"
        >
          <GraniteSelect
            isMulti
            className="col-span-2 md:col-span-2 xl:col-span-1"
            placeholder="Filter by status"
            options={[
              { value: 'Enabled', label: 'Enabled' },
              { value: 'Disabled', label: 'Disabled' },
              { value: 'Not eligible', label: 'Not eligible' },
            ]}
            controlShouldRenderValue={false}
            isSearchable={true}
            {...statusFilterProps}
          />
          <GraniteSelect
            isMulti
            className="col-span-2 md:col-span-2 xl:col-span-1"
            placeholder="Filter by model"
            options={[]}
            controlShouldRenderValue={false}
            isSearchable={true}
            {...modelFilterProps}
          />
          <GraniteSelect
            isMulti
            className="col-span-2 md:col-span-2 xl:col-span-1"
            placeholder="Filter by manufacturer"
            options={[]}
            controlShouldRenderValue={false}
            isSearchable={true}
            {...manufacturerFilterProps}
          />
        </Filters>
      </div>
      <ChipMapper
        manufacturerFilterProps={manufacturerFilterProps}
        modelFilterProps={modelFilterProps}
        statusFilterProps={statusFilterProps}
      />
      {hasPermission && (
        <EditButton onClick={onBulkEdit} totalSelected={totalSelected} />
      )}
      <Modal
        isVisible={isOpen}
        close={close}
        className="w-full max-w-[836px]"
        enableScrolling
      >
        <AlertConfigurationForm defaultValues={editAlerts} close={close} />
      </Modal>
      <div className="mt-6 w-full">
        <ServerPaginatedTable
          data={tableData}
          columns={tableHeaders}
          title=""
          handleRowClick={(row) => onEditSingle(row.original)}
          sortingState={sortingState}
          onSortingChange={onSortingChange}
          emptyDataElement={<EmptySearchResults />}
          {...paginatedTableProps}
          {...(hasPermission && {
            elementBeforePagination: (
              <EditButton onClick={onBulkEdit} totalSelected={totalSelected} />
            ),
          })}
        />
      </div>
    </div>
  );
};

export default AlertConfigurationTabel;
