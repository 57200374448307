import Loader from 'components/Loader';
import PageTitle from 'components/PageTitle';
import Tag from 'components/Tag';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { PermissionType } from 'screens/RolesPermissions/roles-permissions.types';
import { getRoleById } from 'screens/RolesPermissions/utils';
import PermissionTableDetails from './PermissionTableDetails';
import { format, parseISO } from 'date-fns';

const Role = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState<PermissionType[]>([]);

  const { data, isLoading } = useQuery(
    ['role-details', id],
    () => getRoleById(id as string),
    {
      refetchOnMount: true,
      onSuccess: (data) => data && setPermissions(data.permissions),
    },
  );

  const breadcrumbs = [
    {
      icon: 'home',
      label: 'User management',
      onClick: () => navigate('/users'),
    },
    {
      label: 'Role management',
      onClick: () => navigate('/users/roles'),
    },
    ...(data?.name ? [{ label: data.name }] : []),
  ];

  if (isLoading) return <Loader />;
  if (data)
    return (
      <ContentLayout>
        <div className="mb-2 flex items-center justify-between gap-2">
          <PageTitle title={data?.name} breadcrumbs={breadcrumbs} />
          <GraniteButton
            size="large"
            variant="primary"
            onClick={() => navigate(`../edit/${id}`)}
          >
            Edit role
          </GraniteButton>
        </div>
        <div className="flex items-center gap-2.5">
          <p className="text-xl font-bold text-neutral-500">Role ID:</p>
          <Tag
            content={{ type: 'label', label: `${data.id}` }}
            status="neutral"
            style="default"
          />
        </div>
        <div className="mx-4 flex h-full flex-col flex-wrap justify-between overflow-hidden pt-12 xl:flex-row">
          <div className="w-full rounded-tl rounded-tr bg-blue-700 px-8 py-6 xl:max-w-[564px] xl:rounded-bl xl:rounded-tr-none">
            <p className="mb-8 text-2xl font-bold text-white">Role details</p>
            <div className="flex flex-col gap-6">
              <div className="font-bold">
                <p>Name</p>
                <p className="text-white">{data.name}</p>
              </div>
              <div className="font-bold">
                <p>Description</p>
                <p className="text-white">{data.description}</p>
              </div>
              <div className="font-bold">
                <p>Created on</p>
                <p className="text-white">
                  {data.created_at
                    ? format(parseISO(data.created_at), 'MM/dd/yyyy')
                    : '--'}
                </p>
              </div>
              <div className="font-bold">
                <p>Last modified</p>
                <p className="text-white">
                  {data.updated_at
                    ? format(parseISO(data.updated_at), 'MM/dd/yyyy')
                    : '--'}
                </p>
              </div>
              <div className="font-bold">
                <p>Total # of users </p>
                <p className="font-fire text-white">{data.user_count}</p>
              </div>
            </div>
          </div>
          <div className="flex-1 rounded-bl rounded-br bg-blue-800 px-8 py-6 xl:rounded-bl-none  xl:rounded-tr">
            <p className="text-2xl font-bold text-white">Permissions</p>
            <div className="mt-8 w-full">
              <PermissionTableDetails data={permissions} />
            </div>
          </div>
        </div>
      </ContentLayout>
    );
};

export default Role;
