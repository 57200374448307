import { useState, useEffect, useRef } from 'react';

export const useHeightTransition = (isLoading: boolean) => {
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [contentHeight, setContentHeight] = useState('0px');
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoading) {
      if (contentRef.current) {
        const height = contentRef.current.scrollHeight + 'px';
        setContentHeight(height);
      }
      setTimeout(() => setIsContentVisible(true), 100);
    } else {
      setIsContentVisible(false);
      setContentHeight('0px');
    }
  }, [isLoading]);

  return { contentRef, contentHeight, isContentVisible };
};
