import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useModal } from 'hooks/useModal';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface UnsavedChangeModalProps {
  isDirty: boolean;
}

const UnsavedChangeModal: FC<UnsavedChangeModalProps> = ({ isDirty }) => {
  const { open, isOpen, close } = useModal();
  const navigate = useNavigate();

  const onBack = () => navigate('..', { replace: true });

  const onCancel = () => (isDirty ? open() : onBack());

  return (
    <>
      <GraniteButton size="large" variant="secondary" onClick={onCancel}>
        Cancel
      </GraniteButton>
      <Modal isVisible={isOpen} close={close} className="w-full max-w-[624px]">
        <div className="p-8">
          <div>
            <h1 className="text-2xl font-bold text-white">Unsaved changes</h1>
            <p className="mt-2 text-base font-bold text-neutral-500">
              Are you sure you want to discard the changes made to this role?
              This action cannot be reversed.
            </p>
          </div>
          <div className="mt-8 flex gap-5">
            <GraniteButton variant="secondary" size="large" onClick={close}>
              Cancel
            </GraniteButton>
            <GraniteButton size="large" variant="destructive" onClick={onBack}>
              Yes, Discard changes
            </GraniteButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UnsavedChangeModal;
