import React, { MouseEvent, useState } from 'react';
import {
  Slice,
  SliceProps,
  VictoryContainer,
  VictoryPie,
  VictoryTooltip,
  VictoryTooltipProps,
} from 'victory';
import Legend from './Legend';
import ChartTitle from './ChartTitle';
import theme from '../../shared/theme/custom-theme';
import { EmptyState } from 'components/EmptyState/EmptyState';
import Skeleton from 'components/Skeleton/Skeleton';

export interface DonutChartData {
  x: string;
  y: number;
  backgroundColor: string;
}

interface DonutChartProps {
  data: DonutChartData[];
  title?: string;
  isLoading?: boolean;
  showTicketLabel?: boolean;
}

interface CustomSliceProps extends SliceProps {
  events?: {
    onMouseOver: (event: MouseEvent) => void;
    onMouseOut: (event: MouseEvent) => void;
  };
  isHighlighted?: (datum: DonutChartData) => boolean;
}

const CustomSlice = (props: CustomSliceProps) => {
  const highlight =
    props.isHighlighted &&
    props.datum &&
    props.isHighlighted(props.datum as DonutChartData);

  // modified transformation from here
  // https://github.com/FormidableLabs/victory/blob/844109cfe4e40b23a4dcb565e551a5a98015d0c0/packages/victory-pie/src/slice.js#L74
  const transform = `translate(${props.origin?.x}, ${props.origin?.y}) scale(${
    highlight ? 1.05 : 1
  })`;
  const sliceEventProps = {
    transform,
  } as unknown as SliceProps;

  return <Slice {...props} style={{ ...props.style }} {...sliceEventProps} />;
};

interface CustomTooltipProps extends VictoryTooltipProps {
  isActive?: (datum: DonutChartData) => boolean;
}

const CustomTooltip = (props: CustomTooltipProps) => {
  const isActive =
    props.isActive &&
    props.datum &&
    props.isActive(props.datum as DonutChartData);
  return <VictoryTooltip {...props} active={isActive || props.active} />;
};

const DonutChart: React.FC<DonutChartProps> = ({
  data,
  title,
  isLoading,
  showTicketLabel = true,
}) => {
  const dataSet = data.filter((type) => type.y > 0); // separate labels from data to not show 0 data
  const [hoveredLabel, setHoveredLabel] = useState<string | undefined>();

  return (
    <div>
      {title && <ChartTitle title={title} />}
      {isLoading ? (
        <Skeleton className="h-[170px] w-full" />
      ) : dataSet.length > 0 ? (
        <div className="flex w-full items-center justify-center gap-6">
          <div className="hidden sm:block">
            <VictoryPie
              padding={0}
              data={dataSet}
              innerRadius={120}
              padAngle={1}
              dataComponent={
                <CustomSlice isHighlighted={({ x }) => x === hoveredLabel} />
              }
              events={[
                {
                  target: 'data',
                  eventHandlers: {
                    onMouseOver: (_, dataComponentProps) => {
                      return [
                        {
                          target: 'data',
                          mutation: () => {
                            setHoveredLabel(dataComponentProps.datum.x);
                            return { highlight: true };
                          },
                        },
                        {
                          target: 'labels',
                          mutation: () => ({ active: true }),
                        },
                      ];
                    },
                    onMouseOut: () => {
                      return [
                        {
                          target: 'data',
                          mutation: () => {
                            setHoveredLabel(undefined);
                            return { highlight: false };
                          },
                        },
                        {
                          target: 'labels',
                          mutation: () => ({ active: false }),
                        },
                      ];
                    },
                  },
                },
              ]}
              labels={({ datum }) =>
                showTicketLabel
                  ? `${datum.y} ${datum.y === 1 ? 'ticket' : 'tickets'}`
                  : `${datum.y}`
              }
              labelComponent={
                <CustomTooltip
                  isActive={({ x }) => x === hoveredLabel}
                  cornerRadius={16}
                  pointerWidth={56}
                  pointerLength={24}
                  style={{
                    fontFamily: theme.fonts.body,
                    fill: theme.colors.text.white,
                    fontWeight: theme.fontWeights.bold,
                    fontSize: '44px',
                  }}
                  flyoutPadding={54}
                  flyoutStyle={{
                    fill: theme.colors.background.baseSurface,
                    maxWidth: '120px',
                    borderRadius: '8px',
                    boxShadow:
                      '0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
                  }}
                />
              }
              cornerRadius={6}
              style={{
                data: {
                  fill: ({ datum }) => datum.backgroundColor,
                },
              }}
              containerComponent={
                <VictoryContainer className="[&>svg]:overflow-visible" />
              }
            />
          </div>
          <div className="vertical-scrollbar flex max-h-[160px] w-full flex-col items-start justify-start overflow-y-auto overflow-x-hidden scrollbar-none">
            {data.map((datum) => (
              <Legend
                key={datum.x}
                backgroundColor={datum.backgroundColor}
                x={datum.x}
                onMouseOver={() => setHoveredLabel(datum.x)}
                onMouseOut={() => setHoveredLabel(undefined)}
                onFocus={() => setHoveredLabel(datum.x)}
                onBlur={() => setHoveredLabel(undefined)}
                active={hoveredLabel === datum.x}
              />
            ))}
          </div>
        </div>
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

export default DonutChart;
