import { format } from 'date-fns';
import { LocationsArraySchema } from './schemas';
import calculateDispatchDate from 'shared/util/calculateDispatchDate';
import { formatPhoneNumber } from '../utils/formatPhoneNumber';

type LocationListProps = {
  locations: LocationsArraySchema;
  hasPriority?: boolean;
};

export const LocationList = ({ locations }: LocationListProps) => {
  return (
    <div>
      <div className="mb-6 flex w-full items-center justify-start gap-4">
        <div className="w-[30px] font-bold text-content-base-subdued">#</div>
        <div className="grid w-full grid-cols-11 gap-4">
          <div className="col-span-2 font-bold text-content-base-default">
            Child account
          </div>
          <div className="col-span-3 font-bold text-content-base-default">
            Address
          </div>
          <div className="col-span-2 font-bold text-content-base-default">
            Local contact
          </div>
          <div className="col-span-2 font-bold text-content-base-default">
            Location
          </div>
          <div className="col-span-2 font-bold text-content-base-default">
            Dispatch date
          </div>
        </div>
      </div>
      {locations.length > 0 &&
        locations.map((loc, index) => (
          <div
            className={`flex items-center justify-start gap-4 py-2 ${
              index !== locations.length - 1
                ? 'border-b border-stroke-base-subdued'
                : ''
            }`}
            key={index}
          >
            <div className="flex w-[30px] items-center justify-start text-sm font-medium text-content-base-subdued">
              {index + 1}
            </div>
            <div className="grid w-full grid-cols-11 gap-4">
              <div className="col-span-2 flex items-center justify-start text-sm font-medium text-content-base-default">
                {loc?.macnum}
              </div>
              <div className="col-span-3 flex items-center justify-start">
                <p className="truncate text-sm font-medium text-content-base-default">
                  {loc.site.address_line_1}
                </p>
              </div>
              <div className="col-span-2 flex flex-col ">
                <p className="truncate text-sm font-medium text-content-base-default">
                  {loc.local_contact_name}
                </p>
                <p className="text-xs font-semibold text-content-base-subdued">
                  {formatPhoneNumber(loc.local_contact_number as string)}
                </p>
              </div>
              <div className="col-span-2 flex flex-col">
                <p className="text-sm font-medium text-content-base-default">
                  {loc.locationName}
                </p>
                <p className="text-xs font-semibold text-content-base-subdued">
                  {loc.locationNumber}
                </p>
              </div>
              <div className="col-span-2 flex flex-col justify-start">
                <p className="text-sm font-medium text-content-base-default">
                  {format(
                    new Date(
                      calculateDispatchDate({
                        priority: loc.priority.value as 'P1' | 'P2' | 'P3',
                        zipcode: loc.site.zip,
                      }),
                    ),
                    'MM/dd/yyyy',
                  )}
                </p>
                <p className="text-xs font-semibold text-content-base-subdued">
                  9:00 AM - 5:00 PM
                </p>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
