import { AlertCircle, ArrowBack, RemoveCircle } from 'react-ionicons';
import Product from './Product/Product';
import Header from './Header';
import clsx from 'clsx';
import { Link, Navigate, useParams } from 'react-router-dom';
import Subtotal from './Subtotal';
import { LocationProducts } from '../Wizard/ProductSelectionStore';
import { useSelectProductsContext } from '../Wizard/OpenQuoteWizardReducer';
import { computed } from 'mobx';
import { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';

export const LocationDetailsContextLoader = observer(() => {
  const { productSelectionStore } = useSelectProductsContext();
  const { locationId } = useParams();

  const location = computed(() =>
    productSelectionStore.locations.find((l) => l.id === locationId),
  ).get();

  if (!locationId || !location) {
    return <Navigate to="../review-products" />;
  }

  return <LocationDetails locationProducts={location} />;
});

interface LocationDetailsProps {
  locationProducts: LocationProducts;
}

const LocationDetails = observer(
  ({ locationProducts }: LocationDetailsProps) => {
    return (
      <>
        <div className="mb-4 flex">
          <Link className="button large secondary" to="../review-products">
            <ArrowBack width="22px" height="22px" color="inherit" />
            Return to all locations
          </Link>
        </div>
        <div className="flex h-full min-h-[600px] w-full flex-col rounded-lg bg-background-base-surface-2">
          <Header />
          <Subtotal locationProducts={locationProducts} />
          <div className="flex flex-col gap-12 p-8">
            <Product
              name="Primary Circuit"
              locationProducts={locationProducts}
              product={locationProducts.primaryProduct}
            />
            <Product
              name="Secondary Circuit"
              locationProducts={locationProducts}
              product={locationProducts.secondaryProduct}
            />
          </div>
        </div>
      </>
    );
  },
);

const Banner = ({
  status,
  icon,
  title,
  content,
}: {
  status: 'warning' | 'error';
  icon: ReactNode;
  title: string;
  content: string;
}) => {
  return (
    <div
      className={clsx(
        'flex w-full items-center gap-2 rounded border border-status-warning-default bg-background-base-surface-1 p-4',
        status === 'error' && '!border-status-error-default',
      )}
    >
      {icon}
      <div className="flex flex-col text-content-base-default">
        <span className="text-base font-bold">{title}</span>
        <span className="text-sm font-semibold">{content}</span>
      </div>
    </div>
  );
};

export const NoCarriersAvailableBanner = () => (
  <Banner
    status="warning"
    icon={<RemoveCircle width="22px" height="22px" color="#F99247" />}
    title="This location will be excluded from this quote request."
    content="There are no carriers are available at this location."
  />
);

export const InvalidProductSelectionBanner = () => (
  <Banner
    status="error"
    icon={<AlertCircle width="22px" height="22px" color="#FF315E" />}
    title="Error: invalid product selection"
    content="You can only have one cable connection at a location.
															Change the type of connection on one of your products, or
															uncheck it to disable it."
  />
);

export default LocationDetails;
