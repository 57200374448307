import { Layout } from '../BaseComponents/Layout';
import { Form } from '../BaseComponents/Form';
import { Dispatch, Fragment, useEffect } from 'react';
import { TicketDetailFormSchemas } from '../../../LetUsHelp/TicketDetails/schemas';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import {
  useNewTemplateContext,
  WizardAction,
} from '../WizardProvider/WizardReducer';
import { preventSubmitOnEnter } from 'shared/util/preventSubmitOnEnter';
import { Navigate, useNavigate } from 'react-router-dom';
import { dispatchReviewFormFromTicketDetailUnionForm } from 'screens/LetUsHelp/utils/dispatchReviewFormFromTicketDetailUnionForm';
import {
  TemplateDetailsForm,
  TemplateDetailsFormSchema,
  ReviewTemplateForm,
  ReviewTemplateFormSchema,
} from '../schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import FormSection from 'components/FormSection';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import GraniteTextArea from 'components/TextArea/TextArea';
import Divider from 'components/Divider';
import { StandardTools } from 'components/StandardTools/StandardTools';
import { NonStandardToolsFieldTextField } from 'screens/LetUsHelp/BaseComponents/NonStandardToolsField';

interface ReviewProps {
  dispatch: Dispatch<WizardAction>;
  reviewForm: ReviewTemplateForm;
  name?: string;
  description?: string;
  onSave: () => void;
}

const Review = ({
  dispatch,
  reviewForm,
  name,
  description,
  onSave,
}: ReviewProps) => {
  const navigate = useNavigate();

  const { watch: templateDetailsFormWatch, ...templateDetailsForm } =
    useForm<TemplateDetailsForm>({
      resolver: zodResolver(TemplateDetailsFormSchema),
      defaultValues: {
        name,
        description,
      },
    });

  useEffect(() => {
    const { unsubscribe } = templateDetailsFormWatch((formData) => {
      dispatch({
        type: 'UpdateTemplateDetails',
        name: formData.name!,
        description: formData.description!,
      });
    });
    return () => unsubscribe();
  }, [dispatch, templateDetailsFormWatch]);

  const { watch: reviewTemplateFormWatch, ...reviewTemplateForm } =
    useForm<ReviewTemplateForm>({
      resolver: zodResolver(ReviewTemplateFormSchema),
      defaultValues: reviewForm,
    });

  useEffect(() => {
    const { unsubscribe } = reviewTemplateFormWatch((formData) => {
      dispatch({
        type: 'UpdateReview',
        reviewForm: formData as ReviewTemplateForm,
      });
    });
    return () => unsubscribe();
  }, [dispatch, reviewTemplateFormWatch]);

  const scopeOfWork = reviewTemplateFormWatch('scopeOfWork');

  const onSubmit = async () => {
    const templateDetailsFormIsValid = await templateDetailsForm.trigger();
    const reviewTemplateFormIsValid = await reviewTemplateForm.trigger();

    if (templateDetailsFormIsValid && reviewTemplateFormIsValid) {
      onSave();
    } else {
      // Scroll to the first error element
      const errorElements = document.getElementsByClassName('error');
      if (errorElements.length > 0) {
        errorElements[0].scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <Layout>
      <Form
        className="gap-12"
        id="templates-review-form"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
        onKeyDown={preventSubmitOnEnter}
      >
        <FormSection title="Template details" gridClassName="grid-cols-2">
          <GraniteInput
            className="col-span-full"
            error={templateDetailsForm.formState.errors.name?.message}
            label="Name"
            subtitle="Give this template a name to identify its use case."
            placeholder="E.g., New Modem Install"
            {...templateDetailsForm.register('name')}
          />
          <Controller
            name="description"
            control={templateDetailsForm.control}
            render={({ field }) => (
              <div className="col-span-full space-y-2">
                <GraniteTextArea
                  {...field}
                  label="Description"
                  placeholder="E.g., use when a site with new broadband circuit needs to be cutover from existing equipment to new, Granite-provided equipment."
                  error={
                    templateDetailsForm.formState.errors.description?.message
                  }
                  {...templateDetailsForm.register('description')}
                />
                <p className="text-sm text-content-base-subdued">
                  This description will aid in identifying the template&apos;s
                  intended use for all users within your company.
                </p>
              </div>
            )}
          />
        </FormSection>
        <Divider />
        <FormSection
          title="Scope of work & special instructions"
          gridClassName="grid-cols-1"
        >
          <div className="flex flex-col gap-4 rounded bg-input-background-filled p-4">
            <div>
              <h3 className="mb-2 text-xl font-bold text-status-info-default">
                Scope of work
              </h3>
              <p className="text-sm text-content-base-subdued">
                These are step-by-step instructions of what needs to be done on
                site. This may include pertinent equipment info, circuit info,
                location info, or any deliverables expected after completion.
              </p>
            </div>
            <Divider />
            <p className="whitespace-pre-line text-base font-bold text-input-content-filled">
              {scopeOfWork}
            </p>
          </div>
          <GraniteTextArea
            label="Additional notes/requests (optional)"
            subtitle="Please provide any additional instructions of what needs to be done on site that’s not already included above."
            inputClassName="resize-y"
            error={
              reviewTemplateForm.formState.errors.scopeOfWorkInstructions
                ?.message
            }
            {...reviewTemplateForm.register('scopeOfWorkInstructions')}
          />
          <GraniteTextArea
            label="Special instructions (optional)"
            subtitle="Door/access codes, call LCON 30 minutes prior to arrival, etc."
            className="col-span-full"
            error={
              reviewTemplateForm.formState.errors.specialInstructions?.message
            }
            {...reviewTemplateForm.register('specialInstructions')}
          />
          <StandardTools />
          <NonStandardToolsFieldTextField
            control={reviewTemplateForm.control}
          />
        </FormSection>
      </Form>
      <div className="sticky top-8 flex gap-4 rounded bg-background-base-surface-2 p-6 shadow">
        <GraniteButton
          className="w-full"
          variant="secondary"
          size="large"
          onClick={() => navigate(-1)}
        >
          Back
        </GraniteButton>
        <GraniteButton
          className="w-full"
          size="large"
          type="submit"
          form="templates-review-form"
        >
          Save Template
        </GraniteButton>
      </div>
    </Layout>
  );
};

export const ReviewFormWrapper = () => {
  const { dispatch, state, onSave } = useNewTemplateContext();

  if (state.state !== 'Review') {
    if (state.state === 'Details') {
      // Coming from TicketDetails using the browser forward button
      const ticketDetail = TicketDetailFormSchemas.safeParse(
        state.ticketDetailForm,
      );

      if (ticketDetail.success) {
        dispatchReviewFormFromTicketDetailUnionForm(
          ticketDetail.data,
          // @ts-expect-error dispatchReviewFormFromTicketDetailUnionForm uses the same GoToReview type
          dispatch,
          state.getStartedQuestionnaire,
        );
      } else return <Navigate to="/tech-express/templates/details" />;
    } else {
      // User is trying to access the route directly
      return <Navigate to="/tech-express/templates" />;
    }
    return <Fragment />;
  }

  return (
    <Review
      dispatch={dispatch}
      reviewForm={state.reviewForm}
      name={state.name}
      description={state.description}
      onSave={onSave}
    />
  );
};
