import clsx from 'clsx';
import { DetailField } from 'screens/TicketDetail/TicketDetailUtil';

interface MainProps {
  includedProducts: string;
  locations: string;
  dateSubmitted: string;
  submittedBy: string;
  title?: string;
  statusColor?: string;
}

const Main = ({
  includedProducts,
  locations,
  dateSubmitted,
  submittedBy,
  title = 'Quote requested',
  statusColor = 'bg-status-info-default',
}: MainProps) => {
  return (
    <div className="col-span-full flex items-center justify-between rounded-t bg-background-base-surface-2 px-6 py-4">
      <div className="flex items-center gap-3 text-xl font-bold text-content-base-default">
        <span className={clsx('h-[16px] w-[16px] rounded-full', statusColor)} />
        <div>
          <div>{title}</div>
        </div>
      </div>
      <div className="flex gap-12">
        <DetailField description="Included products" value={includedProducts} />
        <DetailField description="Locations" value={locations} />
        <DetailField description="Date submitted" value={dateSubmitted} />
        <DetailField description="Submitted by" value={submittedBy} />
      </div>
    </div>
  );
};

export default Main;
