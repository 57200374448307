import { ReactNode } from 'react';
import { LoaderGranite } from '../index';

export interface WithLoaderProps {
  isLoading?: boolean;
  children: ReactNode;
  loader?: ReactNode;
}

export const WithLoader = ({
  isLoading,
  children,
  loader = <LoaderGranite />,
}: WithLoaderProps) => {
  return isLoading ? loader : children;
};
