import { apiClient } from 'api/apiClient';
import {
  ProductTourRequest,
  ProductTourResponse,
  ProductTourSchema,
} from './schemas';

export const getProductTourProgress =
  async (): Promise<ProductTourResponse> => {
    const response = await apiClient.get('/api/v1/tour');
    return ProductTourSchema.parse(response.data);
  };

export const upsertProductTourProgress = async (
  request: ProductTourRequest,
): Promise<ProductTourResponse> => {
  const response = await apiClient.post('/api/v1/tour', request);
  //@ts-expect-error don't parse
  return response;
};

export const resetProductTourProgress = async () => {
  const response = await apiClient.post('/api/v1/tour/reset');
  return response;
};
