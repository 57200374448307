import React from 'react';
import { toast } from 'react-toastify';
import {
  AlertCircleSharp,
  CheckmarkCircle,
  InformationCircle,
} from 'react-ionicons';
import Button from 'components/Button';

const Toast: React.FC<{
  icon?: React.ReactNode;
  title: string;
  subTitle: string;
  button?: React.ReactNode;
  className?: string;
}> = ({ icon, title, subTitle, button, className }) => {
  return (
    <div
      className={`flex items-center gap-2 pr-1 text-sm text-white ${
        button ? 'justify-between' : ''
      } ${className ? className : ''}`}
    >
      {icon}
      <div className="flex flex-col gap-1">
        <p className="text-[14px]">{title}</p>
        <p className="text-xs">{subTitle}</p>
      </div>
      <div className="mr-2">{button}</div>
    </div>
  );
};

/**
 * Utility functions to display toast notifications.
 *
 * Usage:
 * - showToast.generic({title: "Title", message: "Message"}) to display a generic toast.
 * - showToast.error({message: 'Error message here.'}) to display an error toast.
 * - showToast.confirmation({message: 'Success message here.'}) to display a confirmation toast.
 * - showToast.informative({message: 'Info message here.'}) to display an informative toast.
 *
 * Each method accepts a message string that will be displayed as the subtitle of the toast.
 * The main title and icon for each toast type are predefined (except for generic one that needs title as well).
 */
const showToast = {
  generic: ({
    title,
    message,
    buttonLabel,
  }: {
    title: string;
    message: string;
    buttonLabel?: string;
  }) => {
    toast.success(
      <Toast
        title={title}
        subTitle={message}
        button={buttonLabel && <Button label={buttonLabel} size="small" />}
      />,
    );
  },
  error: ({
    message,
    title = 'Error',
    autoClose = false,
    buttonLabel,
    className,
  }: {
    message: string;
    buttonLabel?: string;
    title?: string;
    autoClose?: boolean;
    className?: string;
  }) => {
    toast.error(
      <Toast
        icon={<AlertCircleSharp color={'#FF315E'} height="24px" width="24px" />}
        title={title}
        className={className}
        subTitle={message}
        button={buttonLabel && <Button label={buttonLabel} size="small" />}
      />,
      {
        // error toast shouldn't disappear until dissmised by the user
        autoClose: autoClose ? 1 : false,
        // prevent duplicates toast
        toastId: 'error',
      },
    );
  },
  confirmation: ({
    message,
    title,
    buttonLabel,
  }: {
    message: string;
    title?: string;
    buttonLabel?: string;
  }) => {
    toast.success(
      <Toast
        icon={<CheckmarkCircle color={'#82FF91'} height="24px" width="24px" />}
        title={title ?? 'Success'}
        subTitle={message}
        button={buttonLabel && <Button label={buttonLabel} size="small" />}
      />,
    );
  },
  informative: ({
    message,
    buttonLabel,
  }: {
    message: string;
    buttonLabel?: string;
  }) => {
    toast.info(
      <Toast
        icon={
          <InformationCircle color={'#96BAFF'} height="24px" width="24px" />
        }
        title="Info"
        subTitle={message}
        button={buttonLabel && <Button label={buttonLabel} size="small" />}
      />,
    );
  },
};

export default showToast;
