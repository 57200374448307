import { Modal } from 'components/Modal/Modal';
import {
  GraniteButton,
  GraniteButtonProps,
} from 'components/V2/Button/GraniteButton';
import { useModal } from 'hooks/useModal';
import { FC } from 'react';
import { deactivateContactGroupById } from '../utils';
import { useMutation } from 'react-query';
import showToast from 'components/Toast/Toast';

interface DeactivateGroupProps extends Pick<GraniteButtonProps, 'size'> {
  label: string;
  contactGroupId: number;
  refetchOnSuccess: () => void;
}

const DeactivateGroup: FC<DeactivateGroupProps> = ({
  label,
  size = 'medium',
  contactGroupId,
  refetchOnSuccess,
}) => {
  const { open, isOpen, close } = useModal();

  const { mutate: deactivateContactGroupByIdMutate, isLoading } = useMutation(
    () => deactivateContactGroupById(contactGroupId),
    {
      onSuccess: () => {
        refetchOnSuccess();
        close();
        showToast.confirmation({ message: 'Deactivated' });
      },
      onError: () => {
        close();
        showToast.error({ message: 'Failed to deactivate' });
      },
    },
  );

  return (
    <>
      <GraniteButton variant="destructive-secondary" size={size} onClick={open}>
        {label}
      </GraniteButton>
      <Modal isVisible={isOpen} close={close} className="w-full max-w-[624px]">
        <div className="p-8">
          <div>
            <h1 className="text-2xl font-bold text-white">
              Are you sure you want to deactivate this contact group?
            </h1>
            <p className="mt-2 text-base font-bold text-neutral-500">
              Email recipients will no longer receive alerts. Contact groups can
              be reactivated at any time.
            </p>
          </div>
          <div className="mt-12 flex gap-5">
            <GraniteButton variant="secondary" size="large" onClick={close}>
              Cancel
            </GraniteButton>
            <GraniteButton
              size="large"
              variant="destructive"
              onClick={() => !isLoading && deactivateContactGroupByIdMutate()}
            >
              Yes, deactivate this group
            </GraniteButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeactivateGroup;
