import { SiteAddressSchema } from 'api/addresssearch/schema';
import { nullToUndefined } from 'shared/util/schema-utils';
import { z } from 'zod';

export const ConfigurationsSchema = z.object({
  id: z.number(),
  name: z.string(),
  type: z.string(),
  status: z.string(),
  company: z.object({
    _info: z
      .object({
        company_href: z.string(),
        site_href: z.string().nullish().transform(nullToUndefined),
      })
      .nullish()
      .transform(nullToUndefined),
    id: z.number(),
    name: z.string(),
    identifier: z.string().nullish().transform(nullToUndefined),
  }),
  company_id: z.number(),
  company_name: z.string(),
  site: SiteAddressSchema.extend({
    account_name: z.string(),
    site_name: z.string(),
  }),
  site_id: z.number(),
  site_name: z.string(),
  serial_number: z.string().nullish().transform(nullToUndefined),
  model_number: z.string().nullish().transform(nullToUndefined),
  ticket_type: z.string().nullish().transform(nullToUndefined),
  ticket_subtype: z.string().nullish().transform(nullToUndefined),
  product_offering: z.string().nullish().transform(nullToUndefined),
  device_identifier: z.string().nullish().transform(nullToUndefined),
  service: z.string().nullish().transform(nullToUndefined),
  service_id: z.string().nullish().transform(nullToUndefined),
  service_id_label: z.string().nullish().transform(nullToUndefined),
});

export type Configurations = z.infer<typeof ConfigurationsSchema>;

export const NOCSiteAddressSchema = SiteAddressSchema.extend({
  account_name: z.string(),
  site_name: z.string(),
  is_chronic: z.boolean().default(false),
});

export type NOCSiteAddress = z.infer<typeof NOCSiteAddressSchema>;
