import Switch from 'components/Switch/Switch';
import { Link } from 'react-router-dom';
import { CloseSharp, OpenOutline } from 'react-ionicons';

interface HeaderProps {
  close: () => void;
  handleOnlyUnread: (value: boolean) => void;
}

const Header = ({ close, handleOnlyUnread }: HeaderProps) => {
  return (
    <div className="flex w-full items-center justify-between">
      <p className="text-2xl font-bold text-content-base-default">
        Notifications
      </p>
      <div className="flex justify-between gap-6">
        <Switch
          label="Only unread"
          onChange={handleOnlyUnread}
          size="small"
          labelClassName="!text-sm !mr-2 !text-content-base-default !font-medium"
        />
        <Link
          to="/notifications"
          className="text-content-base-default"
          onClick={close}
        >
          <OpenOutline width="24px" height="24px" color="inherit" />
        </Link>
        <CloseSharp
          width="24px"
          height="24px"
          color="#F8FAFC"
          cssClasses="ml-2 cursor-pointer"
          onClick={close}
        />
      </div>
    </div>
  );
};

export default Header;
