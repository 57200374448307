import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useOpenQuoteContext } from '../Wizard/OpenQuoteWizardReducer';
import { Fragment, useEffect, useRef, useState } from 'react';
import { OpenQuoteLayout } from '../BaseComponents/OpenQuoteLayout';
import { AlertCircle } from 'react-ionicons';
import AddProductDialog from '../BaseComponents/AddProductDialog';
import { useModal } from 'hooks/useModal';
import { QuoteProduct } from '../schemas/Product';
import Divider from 'components/Divider';
import DeleteProductDialog from './DeleteProductDialog';
import showToast from 'components/Toast/Toast';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { clsx } from 'clsx';
import ProductItem from '../BaseComponents/ProductItem';

export const AddProducts = observer(() => {
  const { openQuoteStore, wizardStore } = useOpenQuoteContext();
  const [productName, setProductName] = useState<string | null>('Product');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const promiseRef = useRef<{
    resolve: (data: boolean) => void;
  } | null>(null);

  const productRefs = useRef<Array<HTMLDivElement | null>>([]);

  const confirmDialog = () => {
    return new Promise((resolve) => {
      promiseRef.current = { resolve };
      setIsDeleteModalOpen(true);
    });
  };

  const handleConfirmation = () => {
    if (promiseRef.current) {
      promiseRef.current.resolve(true);
      promiseRef.current = null;
      setIsDeleteModalOpen(false);
    }
  };

  const handleClose = () => {
    if (promiseRef.current) {
      promiseRef.current.resolve(false);
      promiseRef.current = null;
      setIsDeleteModalOpen(false);
    }
  };

  useEffect(() => {
    wizardStore.setStep(2);
  }, [wizardStore]);

  const { open, openWithProps, ...modalProps } = useModal<QuoteProduct>();

  const handleAddProduct = (product: QuoteProduct) => {
    openQuoteStore.addProduct(product);

    showToast.confirmation({
      message: 'New product added',
    });
  };

  const handleEditProduct = (product: QuoteProduct) => {
    openQuoteStore.editProduct(product);
    showToast.confirmation({
      message: 'Product has been successfully edited',
    });
  };

  const handleDeleteProduct = async (product: QuoteProduct) => {
    const result = await confirmDialog();
    if (result) {
      openQuoteStore.deleteProduct(product);
      showToast.confirmation({
        message: 'Product has been successfully deleted',
      });
    }
  };

  const scrollToElement = (index: number) => {
    productRefs.current.forEach((el) => {
      if (el) {
        el.classList.remove('border', 'border-status-info-default');
      }
    });

    const targetElement = productRefs.current?.[index];

    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      targetElement.classList.add('border', 'border-status-info-default');
    }
  };

  return (
    <OpenQuoteLayout>
      <div className="flex h-full min-h-[710px] flex-col gap-16 rounded bg-background-base-surface-2 p-6 shadow">
        <div className="flex w-full flex-col items-start justify-start">
          <h2 className="text-2xl font-bold text-content-base-default">
            Build product requirements
          </h2>
          <div className="mb-8 text-base text-content-base-subdued">
            Add products that meet the requirements of your locations.
            You&apos;ll be able to assign any of these products to a single
            location or many locations at a time.
          </div>
          <GraniteButton variant="primary" size="large" onClick={open}>
            Add new product
          </GraniteButton>
        </div>
        <div className="w-full">
          <Divider />
        </div>
        <div className="flex w-full flex-col items-start justify-start gap-6">
          {openQuoteStore.products.map((product, index) => (
            <ProductItem
              ref={(el) => (productRefs.current[index] = el)}
              onProductDelete={(product: QuoteProduct, name?: string) => {
                if (name) setProductName(name);
                handleDeleteProduct(product);
              }}
              onProductEdit={(product: QuoteProduct) => {
                openWithProps(product);
              }}
              key={product.id}
              product={product}
            />
          ))}
        </div>
      </div>
      <div className="sticky top-8 flex flex-col gap-6 rounded bg-background-base-surface-2 p-6 shadow">
        {openQuoteStore.products.length < 1 && (
          <div className="flex items-center gap-4 rounded border border-status-info-default bg-background-base-surface-1 p-4">
            <AlertCircle width="22px" height="22px" color="#9796F3" />
            <span className="text-base text-content-base-default">
              You must add at least one product before you can continue
            </span>
          </div>
        )}
        {openQuoteStore.products.length > 0 && (
          <div className="flex flex-col gap-2 bg-background-base-surface-1 p-4">
            <span className="text-base font-bold text-content-base-subdued">
              Your products
            </span>
            {openQuoteStore.products.map((product, index, array) => {
              const isLastItem = index === array.length - 1;
              return (
                <Fragment key={product.id}>
                  <div className="flex w-full items-center justify-between">
                    <span className="text-base font-bold text-content-base-default">
                      {product.name}
                    </span>
                    <GraniteButton
                      size="small"
                      variant="secondary"
                      onClick={() => scrollToElement(index)}
                    >
                      View
                    </GraniteButton>
                  </div>
                  {!isLastItem && (
                    <div className="w-full">
                      <Divider />
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        )}
        <div className="flex flex-col gap-4">
          <Link
            to="../products-to-locations"
            className={clsx(
              'button primary large',
              openQuoteStore.products.length === 0 && 'disabled-link',
            )}
          >
            Next
          </Link>
        </div>
      </div>
      <DeleteProductDialog
        handleConfirm={handleConfirmation}
        isOpen={isDeleteModalOpen}
        close={handleClose}
        productName={productName!}
      />
      <AddProductDialog
        onEditProduct={handleEditProduct}
        {...modalProps}
        onAddProduct={handleAddProduct}
      />
    </OpenQuoteLayout>
  );
});
