import { Dispatch } from 'react';
import { useOutletContext } from 'react-router-dom';
import { DeepPartial } from 'react-hook-form';
import { TicketDetailFormUnion } from 'screens/LetUsHelp/TicketDetails/schemas';
import { GetStartedQuestionnaireBaseForm } from 'screens/LetUsHelp/GetStarted/schemas';
import { ReviewTemplateForm, ReviewTemplateFormSchema } from '../schemas';
import { Template } from 'api/techexpress/schema';

export type WizardReducerState =
  | {
      state: 'Details';
      step: 1;
      name?: string;
      description?: string;
      getStartedQuestionnaire?: DeepPartial<GetStartedQuestionnaireBaseForm>;
      ticketDetailForm?: DeepPartial<TicketDetailFormUnion>;
      reviewForm?: DeepPartial<ReviewTemplateForm>;
    }
  | {
      state: 'Review';
      step: 2;
      name?: string;
      description?: string;
      getStartedQuestionnaire: GetStartedQuestionnaireBaseForm;
      ticketDetailForm: TicketDetailFormUnion;
      reviewForm: ReviewTemplateForm;
    };

export const getWizardInitialState = (initialData?: Template) =>
  ({
    state: 'Details',
    step: 1,
    name: initialData?.name,
    description: initialData?.description,
    getStartedQuestionnaire: initialData?.data?.getStartedQuestionnaire,
    ticketDetailForm: initialData?.data?.ticketDetailForm,
    reviewForm: initialData?.data?.reviewForm,
  }) as const;

export type WizardAction =
  | {
      type: 'GoToDetails';
      getStartedQuestionnaire?: DeepPartial<GetStartedQuestionnaireBaseForm>;
    }
  | {
      type: 'GoToReview';
      getStartedQuestionnaire: GetStartedQuestionnaireBaseForm;
      ticketDetail: TicketDetailFormUnion;
      reviewForm: ReviewTemplateForm;
    }
  | {
      type: 'UpdateDetails';
      getStartedQuestionnaire?: DeepPartial<GetStartedQuestionnaireBaseForm>;
      ticketDetailForm?: DeepPartial<TicketDetailFormUnion>;
    }
  | {
      type: 'UpdateTemplateDetails';
      name: string;
      description: string;
    }
  | {
      type: 'UpdateReview';
      reviewForm: ReviewTemplateForm;
    }
  | {
      type: 'ResetForms';
      keepDefaults?: boolean;
    };

export const wizardReducer = (
  state: WizardReducerState,
  action: WizardAction,
): WizardReducerState => {
  switch (action.type) {
    case 'GoToDetails':
      return {
        ...state,
        state: 'Details',
        step: 1,
      };
    case 'GoToReview':
      return {
        ...state,
        reviewForm: ReviewTemplateFormSchema.parse({
          ...action.reviewForm,
          // dispatchReviewFormFromTicketDetailUnionForm function strips
          // specialInstructions and scopeOfWorkInstructions from the action
          specialInstructions: state?.reviewForm?.specialInstructions,
          scopeOfWorkInstructions: state?.reviewForm?.scopeOfWorkInstructions,
        }),
        getStartedQuestionnaire: action.getStartedQuestionnaire,
        ticketDetailForm: action.ticketDetail,
        state: 'Review',
        step: 2,
      };
    case 'UpdateDetails':
      if (state.state !== 'Details')
        throw new Error(
          'UpdateDetails can only be called from the Details page',
        );
      return {
        ...state,
        ticketDetailForm: action.ticketDetailForm || state.ticketDetailForm,
        getStartedQuestionnaire:
          action.getStartedQuestionnaire || state.getStartedQuestionnaire,
      };
    case 'UpdateTemplateDetails':
      if (state.state !== 'Review')
        throw new Error(
          'UpdateTemplateDetails can only be called from the Review page',
        );
      return {
        ...state,
        name: action.name,
        description: action.description,
      };
    case 'UpdateReview':
      if (state.state !== 'Review')
        throw new Error('UpdateReview can only be called from the Review page');
      return {
        ...state,
        ticketDetailForm: {
          ...state.ticketDetailForm,
          nonStandardTools:
            action.reviewForm?.nonStandardTools ??
            state.ticketDetailForm.nonStandardTools,
        },
        reviewForm: action.reviewForm || state.reviewForm,
      };
    case 'ResetForms':
      return {
        ...getWizardInitialState(),
      };
  }
};

export type EditTemplateWizardContext = {
  dispatch: Dispatch<WizardAction>;
  state: WizardReducerState;
  onCancel: () => void;
  onSave: () => void;
};

export const useNewTemplateContext = () => {
  return useOutletContext<EditTemplateWizardContext>();
};
