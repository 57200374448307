import { Price } from '../../../components/Price';
import React from 'react';
import { clsx } from 'clsx';

export interface PriceContainerProps {
  title: string;
  titleClassName?: string;
  price?: number;
  priceClassName?: string;
}

export const PriceContainer = ({
  title,
  titleClassName,
  price,
  priceClassName,
}: PriceContainerProps) => {
  return (
    <div className="flex flex-col">
      <span
        className={clsx(
          'text-base font-bold text-content-base-subdued',
          titleClassName,
        )}
      >
        {title}
      </span>
      <Price
        className={clsx(
          'text-end text-[28px] font-bold text-content-accent-default',
          priceClassName,
        )}
        total={price}
      />
    </div>
  );
};
