import { GraniteSelect, OptionType } from 'components/Select/Select';
import React, { FC, useState } from 'react';
import { ShortPagination } from 'components/Table/ShortPagination';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import { PaginableRequest } from 'api/common-schemas';
import { getSubGroup } from './utils';
import Loader from 'components/Loader';
import { SubGroupType } from './accounts.types';
import { Control, useController } from 'react-hook-form';
import { EditUserForm } from 'screens/InviteMember/form-schema';
import { SingleValue } from 'react-select';
import SubGroup from './SubGroup';
import clsx from 'clsx';

interface UserAccountsProps {
  initialSelected: SubGroupType['id'][];
  allSelected?: boolean;
  control: Control<EditUserForm>;
}

const accountOptions = [
  { label: 'Select accounts', value: 'select-accounts' },
  { label: 'All accounts', value: 'all-accounts' },
];

const UserAccounts: FC<UserAccountsProps> = ({
  allSelected,
  control,
  initialSelected,
}) => {
  const [accountSelect, setAccountSelect] = useState<SingleValue<OptionType>>(
    allSelected ? accountOptions[1] : accountOptions[0],
  );

  const {
    field,
    fieldState: { error },
  } = useController({ control, name: 'sub_groups' });

  const _getAccounts = (args: PaginableRequest) => getSubGroup(args);

  const onSelect = (option: SingleValue<OptionType>) => {
    setAccountSelect(option);
    if (option?.value === 'all-accounts') field.onChange(['*']);
    else if (option?.value === 'select-accounts') {
      field.onChange(initialSelected);
    }
  };

  const onClick = (subGroupId: SubGroupType['id']) => {
    let _value = field?.value ?? [];

    if (_value.includes(subGroupId))
      _value = _value.filter((id) => id !== subGroupId);
    else _value.push(subGroupId);
    field.onChange(_value);
  };

  const {
    data,
    isLoading,
    pageCount,
    paginationState: { pageIndex },
    onPaginationChange,
  } = usePaginatedTable(_getAccounts, {}, ['user-account-access'], {
    refetchOnMount: true,
  });

  return (
    <div>
      <div className="flex flex-col gap-2">
        <GraniteSelect
          label="Accounts"
          value={accountSelect}
          options={accountOptions}
          onChange={onSelect}
        />
        <p>
          This user will have access to all locations within the selected
          account(s).{' '}
        </p>
      </div>
      <div className="mt-8 flex w-full flex-col">
        {isLoading && <Loader className="h-40" />}
        {data && (
          <>
            <div
              className={clsx(
                'mt-2 grid w-full grid-cols-1 gap-4 rounded p-0.5 xl:grid-cols-2',
                { 'ring-1 ring-red-500': !!error?.message },
              )}
            >
              {data.map((subGroup) => (
                <SubGroup
                  key={subGroup.id}
                  {...subGroup}
                  checked={
                    field.value?.includes(subGroup.id) ||
                    field.value?.includes('*')
                  }
                  onClick={() => onClick(subGroup.id)}
                />
              ))}
            </div>
            <div className="mt-6 self-end">
              <ShortPagination
                currentPage={pageIndex + 1}
                onPageChange={(page) => {
                  onPaginationChange({ pageIndex: page, pageSize: 20 });
                }}
                totalPages={pageCount}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserAccounts;
