import clsx from 'clsx';
import { CloseCircle } from 'react-ionicons';

export const ContactEmailTag = ({
  email,
  index,
  remove,
  disabled,
}: {
  email: string;
  index: number;
  remove?: (idx: number) => void;
  disabled?: boolean;
}) => (
  <div className="flex h-8 items-center rounded-[32px] border border-stroke-base-subdued bg-background-base-surface-3 px-2">
    <span
      className={clsx(
        'fill-content-base-subdued pr-1 ',
        disabled ? 'text-neutral-500' : 'text-content-base-default',
      )}
    >
      {email}
    </span>
    {remove && (
      <button className="fill-content-base-subdued" type="button">
        <CloseCircle
          width="16px"
          height="16px"
          color="inherit"
          onClick={() => remove(index)}
        />
      </button>
    )}
  </div>
);

export default ContactEmailTag;
