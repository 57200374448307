import React, { Fragment } from 'react';
import FormSection from '../../../../components/FormSection';
import { GraniteInput } from '../../../../components/V2/Input/GraniteInput';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import { GraniteLabel } from '../../../../components/V2/Label/GraniteLabel';
import RadioButton from '../../../../components/RadioButton';
import Divider from '../../../../components/Divider';
import { StandardTools } from '../../../../components/StandardTools/StandardTools';
import { ExtensionPOTSForm, ExtensionType } from './schemas';
import { TicketDetailForm } from '../DefinedFormTypes';
import {
  FormWithContact,
  TicketDetailContactForm,
} from '../../BaseComponents/TicketDetailContactForm';
import { NonStandardToolsFieldTextField } from 'screens/LetUsHelp/BaseComponents/NonStandardToolsField';
import PhoneNumberInput from 'components/V2/Input/PhoneNumber';

export type ExtensionPOTSProps = TicketDetailForm<ExtensionPOTSForm>;

export const ExtensionPOTS = ({
  register,
  control,
  errors,
}: ExtensionPOTSProps) => {
  return (
    <Fragment>
      <FormSection title="Ticket details" gridClassName="grid-cols-2">
        <GraniteInput
          label="Where is the MPOE (minimum point of entry) for the telephone number? (optional)"
          className="col-span-full"
          error={errors.mpoeLocation?.message}
          {...register('mpoeLocation')}
        />
        <PhoneNumberInput
          name="telephoneNumber"
          control={control}
          className="col-span-full"
          label="What is the telephone number?"
          error={errors.telephoneNumber?.message}
        />
        <Controller
          name="extension"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <Fragment>
              <GraniteLabel
                label="Where will this line be extended to?"
                element="div"
                className="col-span-full"
              >
                <RadioButton
                  options={[
                    {
                      value: ExtensionType.enum.NetworkRack,
                      label: 'Network rack',
                    },
                    {
                      value: ExtensionType.enum.PatchPanelPort,
                      label: 'Patch panel port',
                    },
                    {
                      value: ExtensionType.enum.JackIn,
                      label: 'Jack in a specific place',
                    },
                  ]}
                  selectedValue={value?.value}
                  onChangeWithLV={(value, label) => onChange({ value, label })}
                  error={errors.extension?.message}
                  className="col-span-2"
                  ref={ref}
                />
              </GraniteLabel>
              {value?.value === ExtensionType.enum.JackIn && (
                <GraniteInput
                  className="col-span-full"
                  label="Location of new jack"
                  error={errors.extension && 'Required'}
                  {...register('extension.location')}
                />
              )}
            </Fragment>
          )}
        />
        <Controller
          control={control}
          name="extensionLength"
          render={({ field }) => (
            <GraniteInput
              {...field}
              className="col-span-full col-start-1 md:col-span-1"
              label="What is the approximate length of cable needed?"
              error={
                errors.extensionLength?.message && 'Enter valid numbers only'
              }
              suffix="feet"
            />
          )}
        />
      </FormSection>
      <Divider />
      <FormSection gridClassName="grid-cols-2">
        <Controller
          control={control}
          name="techToTestWith"
          render={({ field: { onChange, value, ref } }) => (
            <Fragment>
              <GraniteLabel
                label="Does the technician need to work with someone to test?"
                element="div"
                className="col-span-full"
              >
                <RadioButton
                  options={[
                    {
                      label: 'Yes, a member of our IT team',
                      value: 'CustomerIT',
                    },
                    {
                      label: 'Yes, an on site member of our team',
                      value: 'Person',
                    },
                    { label: 'No', value: 'No' },
                  ]}
                  selectedValue={value?.techToTestWith}
                  error={errors.techToTestWith?.message}
                  onChange={(techToTestWith) =>
                    onChange({ ...value, techToTestWith })
                  }
                  ref={ref}
                />
              </GraniteLabel>
              {value?.techToTestWith &&
                (value.techToTestWith === 'CustomerIT' ||
                  value.techToTestWith === 'Person') && (
                  <TicketDetailContactForm
                    errors={errors}
                    register={
                      register as unknown as UseFormRegister<FormWithContact>
                    }
                    control={control as unknown as Control<FormWithContact>}
                  />
                )}
            </Fragment>
          )}
        />
      </FormSection>
      <Divider />
      <FormSection>
        <StandardTools />
        <NonStandardToolsFieldTextField control={control} />
      </FormSection>
    </Fragment>
  );
};
