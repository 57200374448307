import {
  Fragment,
  ReactElement,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Add, CloseCircle, Search } from 'react-ionicons';
import { clsx } from 'clsx';
import './SearchBar.css';
import { usePopper } from 'react-popper';
import { useDebouncedState } from '../../shared/hooks/useDebouncedState';
import { useQuery } from 'react-query';
import escapeRegExp from 'lodash.escaperegexp';
import { SiteAddress } from '../../api/addresssearch/schema';
import { ErrorSubtext } from '../ErrorSubtext/ErrorSubtext';
import { addressSearch } from '../../api/addresssearch/api';
import { NUMBERS_LETTERS_HYPHENS_SPACES_REGEXP } from 'shared/constants/validation-regex-constants';

export interface SearchAddressBarProps {
  className?: string;
  error?: string;
  onAddressSelected: (address?: SiteAddress) => void;
  value?: SiteAddress;
  isDisabled?: boolean;
  name?: string;
  isBulkUploadModal?: boolean;
  bulkModalValue?: SiteAddress | undefined;
  createChildAccountModalOpen?: () => void;
}

export const SearchAddressBar = forwardRef<
  HTMLInputElement,
  SearchAddressBarProps
>(
  (
    {
      className,
      onAddressSelected,
      error,
      value,
      isDisabled,
      isBulkUploadModal = false,
      bulkModalValue,
      name,
      createChildAccountModalOpen,
    }: SearchAddressBarProps,
    ref,
  ) => {
    const anchorEltRef = useRef<HTMLDivElement>(null);
    const popperElementRef = useRef<HTMLDivElement>(null);
    const inputEltRef = useRef<HTMLInputElement>(null);
    const [searchInput, setSearchInput] = useState('');
    const [isMenuVisible, setIsMenuVisible] = useState(true);
    const { value: debouncedIsMenuVisible } = useDebouncedState(isMenuVisible);
    const { value: debouncedSearch, flush } = useDebouncedState(searchInput);
    const [invalidInputError, setInvalidInputError] = useState('');

    const onAddressChangeInternal = useCallback(
      (address?: SiteAddress) => {
        // Order of execution matters as setSearchInput will trigger a re-render which can override the value
        // coming from the parent.
        onAddressSelected(address);
        setSearchInput('');
        // Flush changes immediately after selected address is set so
        // react-query discards data and closes menu.
        flush();
      },
      [setSearchInput, flush, onAddressSelected],
    );

    const onSearchResultRowEscape = useCallback(() => {
      if (inputEltRef.current) {
        inputEltRef.current.focus();
      }
    }, []);

    const onAddressClear = useCallback(() => {
      onAddressSelected(undefined);
    }, [onAddressSelected]);

    const { data: searchResults } = useQuery(
      ['search-address-bar', debouncedSearch],
      () => addressSearch(debouncedSearch),
      {
        enabled:
          debouncedSearch.trim().length > 0 &&
          NUMBERS_LETTERS_HYPHENS_SPACES_REGEXP.test(debouncedSearch.trim()),
        retry: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    );

    const { styles, attributes } = usePopper(
      anchorEltRef.current,
      popperElementRef.current,
      { placement: 'bottom' },
    );

    return (
      <Fragment>
        <div className={clsx('group relative', className)}>
          <div
            className={clsx(
              'search-bar-wrapper',
              isDisabled && '!bg-input-background-disabled',
              !searchResults && 'rounded-b',
              searchResults !== undefined &&
                'focus-within:border-b-input-stroke-unfilled',
              (error || invalidInputError) && 'error',
              bulkModalValue && isBulkUploadModal && 'max-h-[48px]',
            )}
            ref={anchorEltRef}
            role="listbox"
            aria-labelledby="search address bar"
          >
            {!isBulkUploadModal && (
              <Search color="inherit" width="16px" height="16px" />
            )}
            {bulkModalValue && isBulkUploadModal && (
              <div
                style={{ color: '#94A3B8' }}
                className={`flex max-h-[46px] items-center gap-1 rounded-[32px] fill-content-base-subdued px-3 font-light`}
              >
                {Object.keys(bulkModalValue).length > 0
                  ? `${bulkModalValue?.address_line_1}, ${bulkModalValue?.city}, ${bulkModalValue?.state}, ${bulkModalValue?.zip}`
                  : ''}
              </div>
            )}
            {value && !isBulkUploadModal && (
              <div
                className={`flex items-center gap-1 rounded-[32px] ${
                  !isBulkUploadModal
                    ? 'border border-stroke-base-subdued bg-background-base-surface-3'
                    : ''
                } fill-content-base-subdued px-3 font-light`}
              >
                {Object.keys(value).length > 0
                  ? `${value.address_line_1}, ${value.city}, ${value.state}, ${value.zip}`
                  : ''}
                {!isBulkUploadModal && (
                  <button
                    className="cursor-pointer fill-content-base-subdued"
                    title="remove address"
                    type="button"
                    onClick={onAddressClear}
                    disabled={isDisabled}
                  >
                    <CloseCircle color="inherit" width="16px" height="16px" />
                  </button>
                )}
              </div>
            )}
            {!value && !isBulkUploadModal && (
              <input
                type="text"
                placeholder="Search by address, site name, or child account"
                name={name}
                aria-label={name}
                value={searchInput}
                ref={ref}
                onChange={(e) => {
                  setInvalidInputError('');
                  const { value } = e.target;
                  const isValid =
                    NUMBERS_LETTERS_HYPHENS_SPACES_REGEXP.test(value);

                  setSearchInput(value);
                  if (value && !isValid) {
                    setInvalidInputError(
                      'Address can only include letters, numbers, hyphens and spaces.',
                    );
                  }
                }}
                onFocus={() => setIsMenuVisible(true)}
                onBlur={() => setIsMenuVisible(false)}
                className="disabled:cursor-not-allowed disabled:bg-input-background-disabled"
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              />
            )}
          </div>
          <div
            className={clsx(
              searchResults !== undefined && 'search-result-container',
            )}
            onFocus={() => setIsMenuVisible(true)}
            onBlur={() => setIsMenuVisible(false)}
            ref={popperElementRef}
            style={{
              width: (anchorEltRef.current?.clientWidth ?? -2) + 2,
              ...styles.popper,
            }}
            {...attributes.popper}
          >
            {searchResults !== undefined && debouncedIsMenuVisible && (
              <Fragment>
                {searchResults.map((address) => (
                  <SearchResultRow
                    key={address.id || address.name}
                    searchTerm={searchInput}
                    address={address}
                    onClick={onAddressChangeInternal}
                    onEscapeRequest={onSearchResultRowEscape}
                  />
                ))}
                {searchResults.length === 0 && (
                  <div className="flex flex-col gap-2 rounded-b bg-input-background-unfilled px-4 py-3">
                    <p className="font-light text-content-base-subdued">
                      No results
                    </p>
                    <button
                      type="button"
                      className="flex cursor-pointer items-center gap-1 text-content-accent-default"
                      role="button"
                      onClick={createChildAccountModalOpen}
                    >
                      <p>Create new account</p>
                      <Add color="inherit" width="24px" height="24px" />
                    </button>
                  </div>
                )}
              </Fragment>
            )}
          </div>
          <ErrorSubtext error={invalidInputError || error} />
        </div>
      </Fragment>
    );
  },
);

SearchAddressBar.displayName = 'SearchAddressBar';

interface SearchResultRowProps {
  searchTerm: string;
  address: SiteAddress;
  onClick: (address: SiteAddress) => void;
  onEscapeRequest?: () => void;
}

export const highlightMatch = (
  address: string,
  searchTerm: string,
): ReactElement => {
  const parts = address
    .split(new RegExp(`(${escapeRegExp(searchTerm)})`, 'ig'))
    .map((part, i) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <mark role="mark" key={i}>
          {part}
        </mark>
      ) : (
        part
      ),
    );

  return <Fragment>{parts}</Fragment>;
};

export const SearchResultRow = ({
  address,
  searchTerm,
  onClick,
  onEscapeRequest,
}: SearchResultRowProps) => {
  const fmtAddress = `${address.address_line_1}, ${address.city}, ${address.state}, ${address.zip}`;
  const highlightedResults = useMemo(
    () => highlightMatch(fmtAddress, searchTerm),
    [fmtAddress, searchTerm],
  );

  return (
    <div
      className="search-result-row"
      onClick={() => onClick(address)}
      role="option"
      tabIndex={0}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          onClick(address);
          return;
        }
        if (e.key === 'Escape') {
          onEscapeRequest && onEscapeRequest();
        }
      }}
    >
      <div className=" [&>mark]:bg-transparent [&>mark]:text-content-accent-default">
        {highlightedResults}
      </div>
      <div className="text-sm font-light text-input-content-unfilled">
        {[address.parent_macnum, address.parent_name, address.name]
          .filter(Boolean)
          .join(', ')}
      </div>
    </div>
  );
};
