import IconMapper from 'components/IconMapper/IconMapper';
import { StyledButton } from './Button.styles';
import { toSentenceCase } from 'shared/util/util';
import { ReactElement } from 'react';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  mode?: 'primary' | 'secondary' | 'destructive' | 'ghost';
  backgroundColor?: string;
  size?: 'small' | 'medium' | 'large' | string;
  label?: string | ReactElement;
  icon?: string;
  onClick?: () => void;
  width?: string;
  iconLocation?: 'left' | 'right';
}

const IconSize: { [key: string]: number } = {
  medium: 20,
};

const Button: React.FC<ButtonProps> = ({
  mode = 'primary',
  size = 'medium',
  label,
  width,
  icon,
  iconLocation = 'left',
  ...props
}: ButtonProps) => {
  return (
    <StyledButton
      type="button"
      mode={mode}
      size={size}
      style={{ width }}
      {...props}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '110%',
        }}
      >
        {icon && iconLocation === 'left' && (
          <IconMapper
            name={icon}
            size={IconSize[size]}
            style={{ marginRight: '4px' }}
          />
        )}
        <p style={{ margin: 0 }}>
          {label && typeof label === 'string' ? toSentenceCase(label) : label}
        </p>
        {icon && iconLocation === 'right' && (
          <IconMapper
            name={icon}
            size={IconSize[size]}
            style={{ marginLeft: '4px' }}
          />
        )}
      </div>
    </StyledButton>
  );
};

export default Button;
