import { Chip } from 'components/Chip/Chip';
import { OptionType } from 'components/Select/Select';
import { UseFilterForTableReturn } from 'hooks/useFilterForTable';
import React from 'react';

interface ChipMapperProps {
  statusFilterProps: Pick<
    UseFilterForTableReturn<string>,
    'value' | 'onChange'
  >;
  modelFilterProps: Pick<UseFilterForTableReturn<string>, 'value' | 'onChange'>;
  manufacturerFilterProps: Pick<
    UseFilterForTableReturn<string>,
    'value' | 'onChange'
  >;
}

const ChipMapper: React.FC<ChipMapperProps> = ({
  manufacturerFilterProps,
  modelFilterProps,
  statusFilterProps,
}) => {
  const onDelete = (
    filterProps: Pick<UseFilterForTableReturn<string>, 'value' | 'onChange'>,
    chip: OptionType,
  ) =>
    filterProps.onChange(
      filterProps.value.filter((option) => option.value !== chip.value),
    );

  return (
    <div className="flex flex-wrap gap-4">
      {statusFilterProps.value.map((sf) => (
        <Chip
          key={sf.value}
          label={sf.label}
          className="mt-4"
          onDelete={() => onDelete(statusFilterProps, sf)}
        />
      ))}
      {modelFilterProps.value.map((sf) => (
        <Chip
          key={sf.value}
          label={sf.label}
          className="mt-4"
          onDelete={() => onDelete(modelFilterProps, sf)}
        />
      ))}
      {manufacturerFilterProps.value.map((sf) => (
        <Chip
          key={sf.value}
          label={sf.label}
          className="mt-4"
          onDelete={() => onDelete(manufacturerFilterProps, sf)}
        />
      ))}
    </div>
  );
};

export default ChipMapper;
