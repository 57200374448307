import React, { Fragment } from 'react';
import IconButton from 'components/IconButton/IconButton';
import {
  PremiumBadge,
  Priority1Badge,
  Priority2Badge,
  Priority3Badge,
} from './TechExpress.styles';
import BadgeCell from 'components/Badge/Badge';
import { Link } from 'react-router-dom';
import { useSimplePagination } from '../../hooks/useSimplePagination';
import { EmptyState } from 'components/EmptyState/EmptyState';
import Skeleton from 'components/Skeleton/Skeleton';

export interface RecentTicket {
  id: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  dateResolved: Date;
  priority: string;
  type: string;
}

interface RecentTicketsProps {
  tickets: RecentTicket[];
  pageSize?: number;
  isLoading?: boolean;
}

const RecentTickets: React.FC<RecentTicketsProps> = ({
  tickets,
  pageSize = 5,
  isLoading,
}) => {
  const {
    maxPages,
    pageNumber,
    previousPage,
    nextPage,
    page: pagedTickets,
  } = useSimplePagination({ items: tickets, pageSize });

  return (
    <div className="relative h-full">
      <div className="mb-4 flex w-full items-start justify-between">
        <h2 className="text-xl font-bold text-content-base-default md:text-2xl">
          Recently completed
        </h2>
        <div className="hidden items-center justify-center gap-2 sm:flex ">
          <IconButton
            size="small"
            buttonType="secondary"
            icon="chevronBack"
            onClick={previousPage}
            disabled={pageNumber === 1}
          />
          <IconButton
            size="small"
            buttonType="secondary"
            icon="chevronForward"
            onClick={nextPage}
            disabled={pageNumber === maxPages}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="flex h-full w-[456px] flex-col gap-3">
          {Array.from({ length: 5 }).map((_, index) => (
            <Skeleton key={index} className="h-16" />
          ))}
        </div>
      ) : pagedTickets.length > 0 ? (
        <div className="max-h-400 flex flex-col gap-3 overflow-y-auto">
          {pagedTickets.map((ticket) => (
            <Link
              key={ticket.id}
              className="flex w-full flex-col gap-2 rounded bg-background-base-surface-3 p-2 hover:border-content-accent-default focus:border-content-accent-default active:bg-background-base-surface-2"
              to={`/tech-express/${ticket.id}`}
            >
              <div className="flex w-full justify-between">
                <div className="flex w-full flex-row">
                  <p className="w-full text-sm font-bold text-content-base-default">
                    {ticket.address1} {ticket.address2}
                  </p>
                </div>
                <div className="flex w-full justify-end gap-2">
                  <BadgeCell value={ticket.id} />
                  <BadgeForPriority priority={ticket.priority} />
                </div>
              </div>
              <div className="flex w-full justify-between">
                {/* remove flex row */}
                <div className="flex flex-row">
                  <p className="text-text-content-base-subdued text-xs font-semibold">
                    {ticket.city}, {ticket.state}, {ticket.zip}
                  </p>
                </div>
                <div className="flex flex-row">
                  <p className="text-xs font-semibold text-content-base-subdued">
                    {/* {ticket.dateResolved} */}
                    {ticket.type}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <EmptyState className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
      )}
    </div>
  );
};

export const BadgeForPriority = ({ priority }: { priority: string }) => {
  switch (priority) {
    case 'P1':
      return <Priority1Badge>Priority 1</Priority1Badge>;
    case 'P2':
      return <Priority2Badge>Priority 2</Priority2Badge>;
    case 'P3':
      return <Priority3Badge>Priority 3</Priority3Badge>;
    case 'PD':
      return <PremiumBadge>Premium</PremiumBadge>;
    default:
      return <Fragment />;
  }
};

export default RecentTickets;
