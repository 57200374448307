import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';

export const CancelConfirmationDialogModal = ({
  isOpen,
  close,
  onConfirm,
}: {
  isOpen: boolean;
  close: () => void;
  onConfirm: () => void;
}) => {
  return (
    <Modal isVisible={isOpen} close={close} className="w-full max-w-[624px]">
      <div className="flex flex-col gap-2 rounded-t-lg bg-background-base-surface-2">
        <h1 className="bg-background-base-surface-3 px-8 py-6 text-[28px] font-bold leading-9 text-content-base-default">
          Are you sure you want to leave?
        </h1>
        <div className="flex flex-col items-start justify-start gap-12 p-8">
          <p className="text-2xl font-bold text-content-base-default">
            Your changes will not be saved.
          </p>
          <div className="flex gap-5">
            <GraniteButton size="large" variant="secondary" onClick={close}>
              Cancel
            </GraniteButton>
            <GraniteButton
              size="large"
              variant="primary"
              onClick={() => {
                onConfirm();
              }}
            >
              Leave & discard ticket
            </GraniteButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
