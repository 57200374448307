import clsx from 'clsx';
import { Badge } from '../V2/Badge/Badge';

export interface PriorityBadgeProps {
  priority: 'P1' | 'P2' | 'P3';
  className?: string;
  textClassName?: string;
}

const getPriorityLabel = (priority: 'P1' | 'P2' | 'P3' | 'PD'): string => {
  switch (priority) {
    case 'P1':
      return 'Priority 1';
    case 'P2':
      return 'Priority 2';
    case 'P3':
      return 'Priority 3';
    case 'PD':
      return 'Premium';
    default:
      return '';
  }
};

const priorityClasses = {
  P1: 'bg-ticket-priority-1 text-content-base-default',
  P2: 'bg-ticket-priority-2 text-content-base-default',
  P3: 'bg-ticket-priority-3 text-content-flip-default',
  PD: 'bg-ticket-priority-premium text-content-base-default',
  '': '',
};

export const PriorityBadge = ({
  priority,
  className,
  textClassName,
}: PriorityBadgeProps) => {
  return (
    <Badge className={clsx(priorityClasses[priority], className)}>
      <span
        className={clsx('whitespace-nowrap text-base font-bold', textClassName)}
      >
        {getPriorityLabel(priority)}
      </span>
    </Badge>
  );
};
