import clsx from 'clsx';
import { ReactNode } from 'react';

export const FormFragment = ({
  title,
  children,
  className,
  gridClassName,
  subtitle,
}: {
  title?: string;
  children?: ReactNode;
  className?: string;
  gridClassName?: string;
  subtitle?: string;
}) => {
  return (
    <div className={clsx('flex flex-col', className)}>
      {title && <h2 className="text-2xl font-bold text-white">{title}</h2>}
      {subtitle && (
        <span className="text-base font-bold text-content-base-subdued">
          {subtitle}
        </span>
      )}
      <div
        className={clsx(
          'mt-4 grid grid-cols-2 items-start gap-x-4 gap-y-6',
          gridClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
};
