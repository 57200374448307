export const toFrontendTicketType = (beString: string) => {
  switch (beString) {
    case 'Broadband Circuit':
      return 'Broadband circuit';
    case 'Circuit Extension - MAC':
      return 'Circuit extension - MAC';
    case 'Circuit Extension - Repair':
      return 'Circuit extension - Repair';
    case 'Circuit Extension':
      return 'Circuit extension';
    case 'Equipment Install':
      return 'Equipment install';
    case 'Move':
      return 'Move';
    case 'POTS Extension':
      return 'POTS extension';
    case 'Smart Hands':
      return 'Smart hands';
    case 'Site Audit':
      return 'Site audit';
    case 'Customer Equipment':
      return 'Customer equipment';
    case 'Fiber Extension':
      return 'Fiber Extension';
    case 'POTS':
      return 'POTS';
    default:
      return beString;
  }
};

export const toFrontendBoardName = (beString: string) => {
  switch (beString) {
    case 'Field Services - MAC':
      return 'Field services - MAC';
    case 'Field Services - Repair':
      return 'Field services - Repair';
    default:
      return beString;
  }
};
