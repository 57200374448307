import React, { useState, useEffect, useRef } from 'react';
import './Carousel.css';
import {
  CheckAvailability,
  EdgeBoot,
  Mobility,
  Orders,
  Quotes,
} from './Slides';
import { ArrowBack, ArrowForward } from 'react-ionicons';

const Carousel: React.FC = () => {
  const [activeSlide, setActiveSlide] = useState<number>(2);
  const [intervalDuration, setIntervalDuration] = useState<number>(6500);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    startAutoRotate(intervalDuration);

    return () => {
      stopAutoRotate();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSlide, intervalDuration]);

  const startAutoRotate = (duration: number) => {
    stopAutoRotate();
    intervalRef.current = setInterval(() => {
      handleNext();
    }, duration);
  };

  const stopAutoRotate = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const handleClick = (index: number) => {
    setActiveSlide(index);
  };

  const handlePrev = () => {
    setActiveSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1,
    );
    setIntervalDuration(15000);
    startAutoRotate(15000);
  };

  const handleNext = () => {
    setActiveSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1,
    );
    setIntervalDuration(15000);
    startAutoRotate(15000);
  };

  const slides = [
    { id: 1, content: <EdgeBoot /> },
    { id: 2, content: <CheckAvailability /> },
    { id: 3, content: <Mobility /> },
    { id: 4, content: <Quotes /> },
    { id: 5, content: <Orders /> },
  ];

  const getSlideClass = (index: number) => {
    if (index === activeSlide) {
      return 'active-slide';
    } else if (index === (activeSlide - 1 + slides.length) % slides.length) {
      return 'upcoming-slide-left';
    } else if (index === (activeSlide + 1) % slides.length) {
      return 'upcoming-slide-right';
    } else if (index === (activeSlide - 2 + slides.length) % slides.length) {
      return 'far-slide-left';
    } else if (index === (activeSlide + 2) % slides.length) {
      return 'far-slide-right';
    } else {
      return '';
    }
  };

  const handleMouseEnter = () => {
    setIntervalDuration(6500); // Reset to 6500ms on hover
    stopAutoRotate();
  };

  const handleMouseLeave = () => {
    startAutoRotate(intervalDuration); // Resume auto-rotate with current duration
  };

  return (
    <div
      className="carousel-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <section id="slider">
        {slides.map((slide, index) => (
          <input
            key={slide.id}
            type="radio"
            name="slider"
            id={`s${index + 1}`}
            checked={activeSlide === index}
            onChange={() => handleClick(index)}
          />
        ))}
        {slides.map((slide, index) => (
          <label
            key={slide.id}
            htmlFor={`s${index + 1}`}
            id={`slide${index + 1}`}
            className={`slide relative overflow-hidden ${getSlideClass(index)}`}
          >
            <div
              className={`slide-content ${
                activeSlide === index ? 'gradient-bg' : ''
              }`}
            >
              {activeSlide === index && (
                <div className="overlay-active absolute left-0 top-0 h-full w-full"></div>
              )}
              {slide.content}
            </div>
          </label>
        ))}
      </section>
      <div className="carousel-nav-wrapper">
        <div className="carousel-nav">
          <span
            className="cursor-pointer fill-content-base-subdued"
            onClick={handlePrev}
          >
            <ArrowBack height={'24px'} width={'24px'} color={'inherit'} />
          </span>
          <div className="carousel-dots">
            {slides.map((_, index) => (
              <div
                key={index}
                className={`carousel-dot ${
                  activeSlide === index ? 'active' : ''
                }`}
                onClick={() => handleClick(index)}
              ></div>
            ))}
          </div>
          <span
            className="cursor-pointer fill-content-base-subdued"
            onClick={handleNext}
          >
            <ArrowForward height={'24px'} width={'24px'} color={'inherit'} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
