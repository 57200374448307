import { apiClient } from 'api/apiClient';
import { PricingTierResponse } from 'api/accessexpress/schema';

import { ResponseType } from './schema';
import { QuoteDetailsResponse } from '../QuoteDetails/schema';

export const patchQuoteRequest = async ({
  id,
  name,
}: {
  id: string;
  name: string;
}) => {
  const response = await apiClient.patch(`/api/v1/quotes/${id}`, {
    quote_name: name,
  });
  return response.data;
};

export const requestQuote = async ({
  id,
  name,
}: {
  id: string;
  name: string;
}) => {
  await patchQuoteRequest({ id, name });
  const response = await apiClient.post(`/api/v1/quotes/${id}/sendEmail`, {
    quote_name: name,
  });

  return response.data;
};

export const getQuoteBucketsRequest = async (id: string) => {
  const response = await apiClient.get<ResponseType>(
    `/api/v1/quotes/${id}/buckets`,
  );
  const pricingTiers = convertResponseToPricingTierResponses(response.data);
  return {
    quote_name: response.data.quote_name,
    qe_quote_request_id: response.data.qe_quote_request_id,
    qe_quote_created_at: response.data.qe_quote_created_at,
    requester: response.data.requester,
    status: response.data.quote_status,
    hasTwoProductTypes: hasTwoProductTypes(response.data),
    locations: response.data.locations,
    pricingTiers: pricingTiers,
    errorMessage: response.data.error_message,
  };
};

export const getFinalizedDetails = async (id: string) => {
  const response = await apiClient.get<QuoteDetailsResponse>(
    `/api/v1/quotes/${id}/finalizedDetails`,
  );
  return response.data;
};

const hasTwoProductTypes = (response: ResponseType) => {
  const productTypes = new Set<string>();

  response.locations.forEach((location) => {
    location.products.forEach((product) => {
      productTypes.add(product.product_type);
    });
  });

  return productTypes.size === 2;
};

const order = ['Starter', 'Advanced', 'Performance'];
const orderProduct = ['Broadband', 'DIA'];

export const selectBucketsRequest = async ({
  quote_id,
  bucket_id,
  product_id,
  selected,
}: {
  quote_id: string;
  bucket_id: string;
  product_id: string;
  selected: boolean;
}) => {
  const response = await apiClient.put(
    `/api/v1/quotes/${quote_id}/buckets/select`,
    {
      id: bucket_id,
      product_id,
      selected,
    },
  );
  return response;
};

export const convertResponseToPricingTierResponses = (
  response: ResponseType,
): PricingTierResponse[] => {
  return response.locations
    .map((location) => {
      return location.products
        .sort(
          (a, b) =>
            orderProduct.indexOf(a.product_type) -
            orderProduct.indexOf(b.product_type),
        )
        .map((product) => ({
          location: {
            name: location.city,
            address_line_1: location.address1,
            address_line_2: location.address2 || undefined,
            parent_macnum: undefined,
            parent_name: undefined,
            city: location.city,
            state: location.state,
            zip: location.zip_code,
            // !!!!
            id: 0,
          },
          sendMePricing: !!product.more_options_required,
          product_type: product.product_type as 'Broadband' | 'DIA',
          id: product.location_id + '|' + product.id,
          pricing_tiers: product.qe_offering_buckets
            .map((bucket) => ({
              name: bucket.bucket_category,
              avg_mrc: bucket.average_mrc.toString(),
              avg_bandwidth:
                bucket.bandwidth_min === bucket.bandwidth_max
                  ? `${bucket.bandwidth_min}`
                  : `${bucket.bandwidth_min}-${bucket.bandwidth_max}`,
              term: product.term,
              ip_type: product.ip_type,
              ip_blocks: product.ip_block,
              bucket_id: bucket.id,
              product_id: bucket.product_id,
              quote_id: response.id,
              selected: bucket.selected,
            }))
            .sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name)),
        }));
    })
    .flat();
};
