import { getTickets } from './api';
import { Ticket } from './schema';
import { dateToUTCWithoutMS } from '../../shared/util/dateToUtcStrWithoutMS';
import { startOfDay } from 'date-fns';

export interface GetDuplicateTicketsProps {
  site: number;
  dispatchDate: Date;
}

export const getDuplicateTickets = async ({
  site,
  dispatchDate,
}: GetDuplicateTicketsProps): Promise<Ticket[]> => {
  const ticketResponse = await getTickets({
    site: site,
    scheduled_date: dateToUTCWithoutMS(startOfDay(dispatchDate)),
  });
  return ticketResponse.data;
};
