import { ColumnDef } from '@tanstack/react-table';
import {
  ServerPaginatedTable,
  ServerPaginatedTableProps,
} from 'components/Table/ServerPaginatedTable';
import Tag from 'components/Tag';
import { useSortedTable } from 'hooks/useSortedTable';
import { useMemo } from 'react';
import { FileTray } from 'react-ionicons';
import { PermissionType } from 'screens/RolesPermissions/roles-permissions.types';

interface PermissionTableDetailsProps<T extends object>
  extends Pick<ServerPaginatedTableProps<T>, 'data'> {
  extraColumns?: ColumnDef<T>[];
}

const PermissionTableDetails = <T extends object>({
  data,
  extraColumns,
}: PermissionTableDetailsProps<T>) => {
  const { sortingState, onSortingChange } = useSortedTable({
    initialSorting: [],
  });

  const sortedPermissions = useMemo(() => {
    return [...data].sort((a, b) => {
      for (const sort of sortingState) {
        const { id, desc } = sort;
        const aValue = a[id as keyof T];
        const bValue = b[id as keyof T];

        if (aValue < bValue) return desc ? 1 : -1;
        if (aValue > bValue) return desc ? -1 : 1;
      }
      return 0;
    });
  }, [data, sortingState]);

  const columns: ColumnDef<PermissionType>[] = [
    {
      header: 'Permission',
      accessorKey: 'name',
      cell: (row) => (
        <Tag
          content={{ label: row.row.original.name, type: 'label' }}
          status="informative"
          style="default"
        />
      ),
    },
    {
      header: 'Description',
      accessorKey: 'description',
    },
    ...((extraColumns as ColumnDef<PermissionType>[]) ?? []),
  ];

  return (
    <ServerPaginatedTable
      data={sortedPermissions}
      columns={columns as ColumnDef<T>[]}
      title={'permissions role details page'}
      sortingState={sortingState}
      onSortingChange={onSortingChange}
      itemCount={0}
      pageCount={0}
      onPaginationChange={() => {}}
      paginationState={{ pageIndex: 0, pageSize: 0 }}
      showPagination={false}
      tableContainerClassName="!grid-cols-[max-content_1fr_max-content]"
      emptyDataElement={
        <div className="flex flex-col items-center gap-6 rounded bg-blue-900 p-8">
          <FileTray
            color="rgb(var(--content-base-subdued))"
            width="48px"
            height="48px"
          />
          <p className="rounded font-bold leading-[22px] text-content-base-default">
            You haven&apos;t added any permissions yet.
          </p>
        </div>
      }
      columnGap="48px"
    />
  );
};

export default PermissionTableDetails;
