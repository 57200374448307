import { z } from 'zod';
import { v4 as uuid } from 'uuid';

const BroadbandCircuitProductSchema = z.object({
  id: z.string().default(uuid),
  accessType: z.enum(['Cable', 'Fiber']),
  minimumDownloadSpeed: z.number(),
  minimumUploadSpeed: z.number().optional(),
  ipType: z.enum(['static', 'dynamic']),
  termLength: z.string(),
  ipBlocks: z.string().optional(),
  name: z.string().optional(),
});

const DIAProductSchema = z.object({
  id: z.string().default(uuid),
  accessType: z.enum(['FE', 'GE']),
  minimumConnectionSpeed: z.number(),
  ipType: z.enum(['static']),
  ipBlocks: z.string(),
  termLength: z.string(),
  name: z.string().optional(),
});

/**
 * This schema is `branded`, that means that for a function taking an object
 * of this type, the object **needs** to be created with
 * `QuoteProductSchema.parse` to be  accepted. It's not enough the object to
 * have the same _shape_.
 *
 * This is done so that we ensure that every object is valid and has a valid id
 * for tracking relationships.
 *
 * Essentially it uses this type as nominal instead of structural.
 */
export const QuoteProductSchema = z
  .discriminatedUnion('type', [
    BroadbandCircuitProductSchema.merge(
      z.object({ type: z.literal('broadband') }),
    ),
    DIAProductSchema.merge(z.object({ type: z.literal('dia') })),
  ])
  .brand<'QuoteProduct'>();

export type QuoteProduct = z.infer<typeof QuoteProductSchema>;
