import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ModalParams } from 'hooks/useModal';

export interface SLAProps extends ModalParams {}

export const SLADialog = ({ isOpen, close }: SLAProps) => {
  return (
    <Modal isVisible={isOpen} close={close}>
      <div className="flex h-auto w-[624px] flex-col items-start justify-start gap-12 rounded bg-background-base-surface-2 p-8">
        <div className="flex flex-col justify-center gap-2">
          <p className="text-2xl font-bold text-content-base-default">
            Service Level Agreement (SLA)
          </p>
          <p className="medium text-base font-bold text-content-base-subdued">
            Broadband is a best-effort service and therefore does not provide
            any guarantee that the data delivered will meet a specific quality
            of service.
          </p>
        </div>
        <div className="flex w-full flex-col items-start justify-start rounded bg-background-base-surface-2 ">
          <div className="flex w-full flex-col items-start justify-start gap-2">
            <table className="overflow-hidden rounded-[6px] p-3 text-base font-bold text-content-base-subdued">
              <tr>
                <th className="border-[0.5px] border-stroke-base-default bg-neutral-500 p-3 text-left font-bold text-content-flip-default">
                  SLA
                </th>
                <th className="border-[0.5px] border-stroke-base-default bg-neutral-200 p-3 text-left font-bold text-content-flip-default">
                  Cable
                </th>
                <th className="border-[0.5px] border-stroke-base-default bg-neutral-200 p-3 text-left font-bold text-content-flip-default">
                  ADSL
                </th>
              </tr>
              <tr>
                <td className="border-[0.5px] border-stroke-base-default p-3">
                  Network Availability
                </td>
                <td className="border-[0.5px] border-stroke-base-default p-3 text-content-base-default">
                  90.50%
                </td>
                <td className="border-[0.5px] border-stroke-base-default p-3 text-content-base-default">
                  99.50%
                </td>
              </tr>
              <tr>
                <td className="border-[0.5px] border-stroke-base-default p-3">
                  CPE MTTR
                </td>
                <td className="border-[0.5px] border-stroke-base-default p-3 text-content-base-default">
                  2nd business day
                </td>
                <td className="border-[0.5px] border-stroke-base-default p-3 text-content-base-default">
                  2nd business day
                </td>
              </tr>
              <tr>
                <td className="border-[0.5px] border-stroke-base-default p-3">
                  MTTR
                </td>
                <td className="border-[0.5px] border-stroke-base-default p-3 text-content-base-default">
                  48 hours
                </td>
                <td className="border-[0.5px] border-stroke-base-default p-3 text-content-base-default">
                  48 hours
                </td>
              </tr>
              <tr>
                <td className="border-[0.5px] border-stroke-base-default p-3">
                  Install interval
                </td>
                <td className="border-[0.5px] border-stroke-base-default p-3 text-content-base-default">
                  30 business days
                </td>
                <td className="border-[0.5px] border-stroke-base-default p-3 text-content-base-default">
                  31 business days
                </td>
              </tr>
            </table>
          </div>
        </div>
        <GraniteButton variant="primary" size="large" onClick={close}>
          Close
        </GraniteButton>
      </div>
    </Modal>
  );
};
