import React, { Fragment, useState } from 'react';
import IconButton from 'components/IconButton/IconButton';
import ChartTitle from './ChartTitle';
import Divider from '../Divider';
import { useSimplePagination } from '../../hooks/useSimplePagination';
import { toFrontendTicketType } from '../../screens/TechExpress/utils';
import { EmptyState } from 'components/EmptyState/EmptyState';
import Skeleton from 'components/Skeleton/Skeleton';
import { GraniteButton } from 'components/V2/Button/GraniteButton';

export interface TicketTypesItemProps {
  ticketType: string;
  ticketCount: number;
}

const TicketTypesItem: React.FC<TicketTypesItemProps> = ({
  ticketType,
  ticketCount,
}) => {
  return (
    <p className="flex items-center justify-between text-base font-bold leading-6 text-content-base-subdued sm:justify-start">
      {toFrontendTicketType(ticketType ?? '')}
      <span className="font-bold leading-none text-content-base-default sm:ml-4">
        {ticketCount ?? ''}
      </span>
    </p>
  );
};

export interface TicketTypesChartData {
  ticketType: string;
  ticketCount: number;
}

interface TicketTypesChartProps {
  data: TicketTypesChartData[];
  isLoading?: boolean;
  pageSize?: number;
}

const TicketTypesChart: React.FC<TicketTypesChartProps> = ({
  data,
  pageSize = 3,
  isLoading,
}) => {
  const [showAll, setShowAll] = useState(false);
  const {
    maxPages,
    pageNumber,
    previousPage,
    nextPage,
    page: pagedData,
  } = useSimplePagination({
    items: data,
    pageSize: showAll ? Infinity : pageSize,
  });

  return (
    <div className="flex w-full flex-col items-start justify-start">
      <div className="flex w-full items-start justify-between">
        <ChartTitle title="By ticket type" />
        <div className="hidden items-center justify-center gap-2 sm:flex">
          <IconButton
            size="small"
            buttonType="secondary"
            icon="chevronBack"
            onClick={previousPage}
            disabled={pageNumber === 1}
          />
          <IconButton
            size="small"
            buttonType="secondary"
            icon="chevronForward"
            onClick={nextPage}
            disabled={pageNumber === maxPages}
          />
        </div>
      </div>
      {isLoading ? (
        <Skeleton className="mt-2.5 h-[96px] w-full" />
      ) : pagedData.length > 0 ? (
        <div className="flex w-full flex-col gap-2">
          {pagedData.map((datum, index) => (
            <Fragment key={index}>
              <TicketTypesItem
                ticketType={datum.ticketType}
                ticketCount={datum.ticketCount}
              />
              {index < pagedData.length - 1 ? <Divider /> : null}
            </Fragment>
          ))}
        </div>
      ) : (
        <EmptyState />
      )}
      {pagedData.length > 0 && (
        <GraniteButton
          size="small"
          className="mt-6 flex sm:hidden"
          variant="secondary"
          onClick={() => setShowAll((prev) => !prev)}
        >
          {showAll ? `Show top ${pageSize}` : 'View all'}
        </GraniteButton>
      )}
    </div>
  );
};

export default TicketTypesChart;
