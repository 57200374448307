import { downloadTicketAttachment } from 'api/techexpress/api';
import showToast from 'components/Toast/Toast';
import { useEffect, useState } from 'react';
import { useQueries } from 'react-query';

interface UseDownloadAttachmentsProps {
  ticketId: string;
  attachments: { filename: string; id?: number | undefined }[];
}

export const useDownloadAttachments = ({
  ticketId,
  attachments = [],
}: UseDownloadAttachmentsProps) => {
  const [triggerDownload, setTriggerDownload] = useState(false);

  const queries = useQueries(
    attachments.map((attachment) => ({
      queryKey: ['download-attachment', ticketId, attachment.id],
      queryFn: () => downloadTicketAttachment(attachment.id!, ticketId),
      onSuccess: (data: string) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', attachment.filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode!.removeChild(link);
        window.URL.revokeObjectURL(url);
      },
      onError: () => {
        showToast.error({ message: 'Failed to download attachment!' });
      },
      enabled: triggerDownload && !!ticketId && attachments.length > 0,
      retry: false,
    })),
  );

  useEffect(() => {
    const allSuccessful = queries.every((query) => query.isSuccess);
    if (allSuccessful && triggerDownload) {
      setTriggerDownload(false);
    }
  }, [queries, triggerDownload]);

  const downloadAllAttachments = () => {
    setTriggerDownload(true);
  };

  return { downloadAllAttachments };
};
