import Papa, { LocalFile, ParseResult } from 'papaparse';

export const papaParseFileAsync = <T>(
  file: File,
  config?: Omit<Papa.ParseLocalConfig<T, LocalFile>, 'complete' | 'error'>,
): Promise<ParseResult<T>> => {
  const options = {
    skipEmptyLines: true,
    ...config,
  };

  return new Promise((complete, error) => {
    Papa.parse<T>(file, {
      complete,
      error,
      ...options,
    });
  });
};
