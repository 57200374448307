import { z } from 'zod';
import {
  ContactSubFormSchema,
  NonStandardToolsFieldSchema,
} from '../../utils/schemas';
import { TicketDetailForms } from '../DefinedFormTypes';
import { VALID_PHONE_ERROR_LABEL } from '../../../../shared/constants/error-labels';
import { VALID_PHONE_NUMBER } from 'shared/constants/validation-regex-constants';

export const POTSCustomerLineFormSchema = z.object({
  formType: z.literal(TicketDetailForms.enum.POTSCustomerLine),
  POTSTelephoneNumber: z
    .string()
    .trim()
    .nonempty(VALID_PHONE_ERROR_LABEL)
    .length(10, VALID_PHONE_ERROR_LABEL)
    .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL)
    .or(z.undefined())
    .or(z.literal('')),
  mpoe: z.string(),
  whatIsUsedFor: z.string().trim().nonempty('Required'),

  techToTestWith: z.discriminatedUnion('techToTestWith', [
    z.object({ techToTestWith: z.literal('Yes') }),
    z.object({ techToTestWith: z.literal('No') }),
  ]),
  itTestingContacts: z.array(ContactSubFormSchema),
  nonStandardTools: NonStandardToolsFieldSchema,
});

export type POTSCustomerLineForm = z.infer<typeof POTSCustomerLineFormSchema>;
