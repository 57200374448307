import { FeedbackSchemaRequestType, FeedbackSchemaType } from './form-schema';

export const ticketFeedbackFormToRequest = (data: FeedbackSchemaType) => {
  const request: FeedbackSchemaRequestType = {
    footer_response: data.footer_response ?? null,
    contact_me_flag: data.contact_me_flag === 'yes',
    results: [
      {
        question_id: 4,
        answer: data.overallRating - 1,
      },
      ...(data.communationRating !== undefined &&
      data.communationRating !== null
        ? [
            {
              question_id: 1,
              answer: data.communationRating - 1,
            },
          ]
        : []),
      ...(data.professionalismRating !== undefined &&
      data.professionalismRating !== null
        ? [
            {
              question_id: 2,
              answer: data.professionalismRating - 1,
            },
          ]
        : []),
      ...(data.qualityRating !== undefined && data.qualityRating !== null
        ? [
            {
              question_id: 3,
              answer: data.qualityRating - 1,
            },
          ]
        : []),
      {
        question_id: 5,
        answer: data.techArrivedInTimeRating === 'yes' ? 1 : 0,
      },
      {
        question_id: 6,
        answer: data.deliverablesRating === 'yes' ? 1 : 0,
      },
    ],
  };
  return request;
};
