import { z } from 'zod';
import { createPaginatedResponseSchema } from 'api/common-schemas';
import { OptionLabelValueSchema } from 'screens/LetUsHelp/utils/schemas';

export const acceptedFileTypes = [
  '.pdf',
  '.docx',
  '.png',
  '.jpg',
  '.jpeg',
  '.xlsx',
  '.csv',
  '.mp4',
  '.mov',
  '.mpeg',
] as const;

const TagSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const TagAPIResponseSchema = z.array(TagSchema);
export type TagAPIResponse = z.infer<typeof TagAPIResponseSchema>;

export const ResourceSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  filename: z.string(),
  file_type: z.enum(acceptedFileTypes),
  storage_filename: z.string(),
  is_suggested: z.boolean(),
  title: z.string(),
  description: z.string().nullable(),
  tags: z.array(TagSchema),
});

export type Resource = z.infer<typeof ResourceSchema>;

export const ResourceAPIResponseSchema =
  createPaginatedResponseSchema(ResourceSchema);

export type ResourceAPIResponse = z.infer<typeof ResourceAPIResponseSchema>;

export const ResourceFormSchema = z.object({
  id: z.string().optional(),
  title: z.string().min(1, 'Title is required'),
  is_suggested: z.boolean().default(false),
  attachments: z.array(z.any()).optional(), // TODO: Validate file attachments > 15MB
  description: z
    .string()
    .max(100, 'Description must be at most 100 characters')
    .optional(),
  tags: z.array(OptionLabelValueSchema),
});

export type ResourceForm = z.infer<typeof ResourceFormSchema>;

export const GetResourceFilterSearchParamsSchema = z
  .object({
    file_type: z.string(),
    filename: z.string(),
    tags: z.string(),
    is_suggested: z.boolean(),
    start_date: z.string().datetime(),
    end_date: z.string().datetime(),
    search: z.string(),
    page: z.number(),
    page_size: z.number(),
  })
  .partial();

export type GetResourceFilterSearchParams = z.infer<
  typeof GetResourceFilterSearchParamsSchema
>;
