import {
  Outlet,
  RouteObject,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  getWizardInitialState,
  LetUsHelpWizardContext,
  wizardReducer,
} from './WizardReducer';
import { ContentLayout } from '../../../layouts/ContentLayout/ContentLayout';
import PageTitle from '../../../components/PageTitle';
import { WizardProgress } from '../WizardProgress/WizardProgress';
import { GetStartedFormWrapper } from '../GetStarted/GetStarted';
import { TicketDetailsFormWrapper } from '../TicketDetails/TicketDetailsBase';
import { ReviewFormWrapper } from '../Review/Review';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useModal } from 'hooks/useModal';
import { SaveDraftConfirmationDialog } from '../../TechExpress/SaveDraftConfirmationDialog';
import { createDraft, updateDraft } from 'api/techexpress/api';
import showToast from 'components/Toast/Toast';

export const LetUsHelpWizard = () => {
  const navigate = useNavigate();
  // The selected draft is sent through the History API when navigating to let-us-help page
  const { state: historyState, pathname } = useLocation();
  const [draft] = useState(historyState);
  const [state, dispatch] = useReducer(
    wizardReducer,
    getWizardInitialState(draft?.data),
  );

  const { openWithProps, close, ...modalProps } = useModal<boolean>();

  const saveDraft = useCallback(async () => {
    if (!draft?.id) {
      await createDraft(state);
    } else {
      await updateDraft(draft.id, state);
    }
    showToast.confirmation({
      message: 'Draft saved!',
    });
    close();
    navigate('/tech-express');
  }, [close, draft, navigate, state]);

  const onCancel = () => {
    if (!state?.getStartedQuestionnaire) return navigate('/tech-express');
    openWithProps(true);
  };

  useEffect(() => {
    if (historyState) {
      navigate(pathname, { replace: true, state: null });
    }
  }, [historyState, navigate, pathname]);

  const breadcrumbs = [
    {
      icon: 'home',
      label: 'TechExpress',
      onClick: () => navigate('/tech-express'),
    },
  ];

  return (
    <ContentLayout>
      <div className="flex flex-col gap-6">
        <PageTitle
          title="Open ticket"
          breadcrumbs={breadcrumbs}
          className="flex-wrap gap-4"
        >
          <div className="my-auto w-full sm:w-auto">
            <GraniteButton
              size="large"
              variant="secondary"
              onClick={() => openWithProps(false)}
              disabled={!state?.getStartedQuestionnaire}
              className="medium sm:large w-full sm:w-auto"
            >
              Save as draft
            </GraniteButton>
          </div>
        </PageTitle>
        <WizardProgress step={state.step} />
      </div>
      <Outlet
        context={
          {
            dispatch,
            state,
            draftId: draft?.id,
            onCancel,
          } satisfies LetUsHelpWizardContext
        }
      />
      <SaveDraftConfirmationDialog
        {...modalProps}
        close={(cancel) => {
          close();
          if (cancel) navigate('/tech-express');
        }}
        onConfirmation={saveDraft}
      />
    </ContentLayout>
  );
};

export const LetUsHelpRoutes: RouteObject[] = [
  {
    path: 'let-us-help',
    element: <LetUsHelpWizard />,
    children: [
      { index: true, element: <GetStartedFormWrapper /> },
      { path: 'details', element: <TicketDetailsFormWrapper /> },
      { path: 'review', element: <ReviewFormWrapper /> },
    ],
  },
];
