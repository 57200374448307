import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useModal } from 'hooks/useModal';
import React from 'react';
import { Trophy } from 'react-ionicons';
import { toSentenceCase } from 'shared/util/util';
import { PackageDetailsDialog } from './PackageDetailsDialog';
import { CheckmarkCircle } from 'react-ionicons';
import { DarkSparkles } from 'components/Sparkles/DarkSparkles';
import { LightSparkles } from 'components/Sparkles/LightSparkles';

export type ProductDetails = {
  type: 'performance' | 'budget' | 'recommended';
  averageMRC: number;
  averageBandwidth: number;
};

type ProductCardProps = {
  product: ProductDetails;
  selectedProduct: ProductDetails | null;
  handleSelection: (product: ProductDetails) => void;
};

export const ProductCard: React.FC<ProductCardProps> = ({
  product,
  selectedProduct,
  handleSelection,
}) => {
  const { open, isOpen, ...modalProps } = useModal();

  const getIcon = () => {
    switch (product.type) {
      case 'recommended':
        return <Trophy color={'inherit'} width={'14px'} height={'14px'} />;
      case 'performance':
        return <DarkSparkles />;
      case 'budget':
        return <LightSparkles />;
      default:
        return <Trophy />;
    }
  };

  return (
    <div
      className={`flex h-[294px] w-full flex-col items-center justify-center gap-4 rounded border border-background-base-surface-1 hover:border hover:border-stroke-accent-default ${
        selectedProduct?.type === product.type
          ? '!border-stroke-accent-default'
          : ''
      } bg-background-base-surface-3 p-4`}
    >
      <div className="flex items-center justify-center gap-2 ">
        <div className="fill-yellow-400 text-yellow-400">{getIcon()}</div>
        <h1 className="text-base font-bold text-content-base-default">
          {toSentenceCase(product.type)}
        </h1>
      </div>
      <div className="flex h-full w-full flex-col items-center justify-center gap-4 rounded bg-background-base-surface-2 p-3">
        <div className="flex flex-col items-center justify-center ">
          <p className="text-xs font-bold text-content-base-subdued">
            Average MRC
          </p>
          <h1 className="text-[28px] font-bold leading-9 text-content-base-default">
            ${product.averageMRC.toFixed(2)}
          </h1>
        </div>
        <div className="flex flex-col items-center justify-center ">
          <p className="text-xs font-bold text-content-base-subdued">
            Average bandwidth
          </p>
          <h1 className="text-base font-bold text-content-base-default">
            {product.averageBandwidth} mbps
          </h1>
        </div>
      </div>
      <div className="flex w-full flex-col items-start justify-start gap-2">
        <GraniteButton
          className="!box-border w-full"
          variant="primary"
          onClick={() => handleSelection(product)}
        >
          {selectedProduct?.type === product.type ? (
            <span className="flex items-center justify-center gap-0.5 text-sm">
              Selected!{' '}
              <CheckmarkCircle
                width={'16px'}
                height={'16px'}
                color={'#00000'}
              />
            </span>
          ) : (
            'Select'
          )}
        </GraniteButton>
        <GraniteButton
          onClick={open}
          className="!box-border w-full text-content-base-default"
          variant="ghost"
        >
          View details
        </GraniteButton>

        <PackageDetailsDialog
          type={product.type}
          isOpen={isOpen}
          {...modalProps}
        />
      </div>
    </div>
  );
};
