import { ComponentProps } from 'react';
import { clsx } from 'clsx';

export interface OpenNetOpsTicketBaseFormProps extends ComponentProps<'form'> {}
export const OpenNetOpsTicketBaseForm = ({
  className,
  children,
  ...props
}: OpenNetOpsTicketBaseFormProps) => {
  return (
    <form
      className={clsx(
        className,
        'netops-form flex flex-col rounded bg-background-base-surface-2 p-6 shadow',
      )}
      {...props}
    >
      {children}
    </form>
  );
};
