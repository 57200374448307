import clsx from 'clsx';
import React, { FC, ReactNode } from 'react';

interface PageItemProps {
  state?: 'default' | 'active';
  title: ReactNode;
}

const PageItem: FC<PageItemProps> = ({ title, state = 'default' }) => {
  return (
    <div
      className={clsx(
        'hover: w-full cursor-pointer rounded-r p-2 hover:text-teal-400',
        {
          'text-white': state === 'default',
          'border-l-2 border-teal-400 bg-blue-800 text-teal-400':
            state === 'active',
        },
      )}
    >
      <p className="whitespace-nowrap font-bold">{title}</p>
    </div>
  );
};

export default PageItem;
