import React, { createContext, useContext, useState, useCallback } from 'react';
import { Notification as NotificationType } from 'api/notifications/schema';

interface InAppNotificationsContextType {
  filter: (notification: NotificationType) => boolean;
  registerFilter: (filter: (notification: NotificationType) => boolean) => void;
  unregisterFilter: (
    filter: (notification: NotificationType) => boolean,
  ) => void;
}

const InAppNotificationsContext = createContext<
  InAppNotificationsContextType | undefined
>(undefined);

export const InAppNotificationsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [filters, setFilters] = useState<
    Array<(notification: NotificationType) => boolean>
  >([]);

  const registerFilter = useCallback(
    (filter: (notification: NotificationType) => boolean) => {
      setFilters((prevFilters) => [...prevFilters, filter]);
    },
    [],
  );

  const unregisterFilter = useCallback(
    (filter: (notification: NotificationType) => boolean) => {
      setFilters((prevFilters) =>
        prevFilters.filter((currentFilter) => currentFilter !== filter),
      );
    },
    [],
  );

  const filter = useCallback(
    (notification: NotificationType) => {
      return filters.some((filter) => filter(notification));
    },
    [filters],
  );

  return (
    <InAppNotificationsContext.Provider
      value={{
        filter,
        registerFilter,
        unregisterFilter,
      }}
    >
      {children}
    </InAppNotificationsContext.Provider>
  );
};

export const useInAppNotifications = () => {
  const context = useContext(InAppNotificationsContext);
  if (!context) {
    throw new Error(
      'useInAppNotifications must be used within an InAppNotificationsProvider',
    );
  }
  return context;
};
