import { Configurations } from 'api/nocexpress/schemas/ConfigurationsSchema';

export const prioritizationList = [
  {
    ticket_type: 'GRID',
    priority: 1,
  },
  {
    ticket_type: 'Broadband',
    priority: 2,
  },
  {
    ticket_type: 'DIA',
    priority: 3,
  },
  {
    ticket_type: 'Private IP',
    priority: 4,
  },
  {
    ticket_type: 'Granite Switched Ethernet',
    priority: 5,
  },
  {
    ticket_type: 'MPLS',
    priority: 6,
  },
  {
    ticket_type: 'Rebill - DIA',
    priority: 7,
  },
  {
    ticket_type: 'Rebill - Broadband',
    priority: 8,
  },
  {
    ticket_type: 'edgeboot',
    priority: 9,
  },
  {
    ticket_type: 'Managed SD-WAN',
    priority: 10,
  },
  {
    ticket_type: 'Managed Switch',
    priority: 11,
  },
  {
    ticket_type: 'Managed WiFi',
    priority: 12,
  },
  {
    ticket_type: 'Proactive Ticketing',
    priority: 13,
  },
  {
    ticket_type: 'Advanced Monitoring',
    priority: 14,
  },
  {
    ticket_type: 'EPIK',
    priority: 15,
  },
  {
    ticket_type: 'Mobility Access / M2M',
    priority: 16,
  },
  {
    ticket_type: 'Wireless Voice',
    priority: 17,
  },
  {
    ticket_type: 'SIP',
    priority: 18,
  },
  {
    ticket_type: 'Hosted PBX',
    priority: 19,
  },
  {
    ticket_type: 'ePOTS',
    priority: 20,
  },
  {
    ticket_type: 'Toll-Free Number',
    priority: 21,
  },
  {
    ticket_type: 'RCF',
    priority: 22,
  },
];

export const determineHighestPriorityTicket = (services: Configurations[]) => {
  if (!services || services.length === 0) {
    return null;
  }
  if (services.length === 1) {
    return services[0];
  }

  return services.reduce((prev, current) => {
    const prevPriority =
      prioritizationList.find((p) => p.ticket_type === prev.ticket_type)
        ?.priority || Number.MAX_SAFE_INTEGER;
    const currentPriority =
      prioritizationList.find((p) => p.ticket_type === current.ticket_type)
        ?.priority || Number.MAX_SAFE_INTEGER;
    return prevPriority < currentPriority ? prev : current;
  });
};
