import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { CaretDown, CloseCircle } from 'react-ionicons';

interface OptionType {
  value: string;
  label: string;
}
interface FilterProps {
  placeholder: string;
  options: OptionType[];
  onChange: (value: OptionType | null) => void;
  value?: OptionType | null;
  classNames?: string;
}

const FilterSelect = ({
  placeholder,
  options,
  onChange,
  value,
  classNames = '',
}: FilterProps) => {
  const selectRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const closeOnClickOutside = (ev: MouseEvent) => {
      if (
        selectRef.current &&
        ev.target instanceof Node &&
        !selectRef.current.contains(ev.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', closeOnClickOutside);

    return () => {
      document.removeEventListener('mousedown', closeOnClickOutside);
    };
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  return (
    <div
      className={clsx(
        'relative flex w-full items-start justify-start',
        classNames,
      )}
      ref={selectRef}
    >
      <div
        onClick={() => {
          setIsMenuOpen((prevState) => !prevState);
          if (value) {
            onChange(null);
          }
        }}
        className={clsx(
          'flex h-[48px] w-full cursor-pointer items-center justify-between gap-4 border border-input-stroke-filled px-[16px] text-base text-content-base-default hover:border-input-stroke-focus',
          isMenuOpen &&
            'rounded-lb-none rounded-rb-none rounded-t border-input-stroke-focus',
          !isMenuOpen && 'rounded',
        )}
      >
        {value && (
          <div className="flex h-[32px] w-max max-w-[85%] items-center gap-1 rounded-[32px] border border-stroke-base-subdued bg-background-base-surface-3 fill-content-base-subdued px-3 font-light">
            <p className="w-full truncate text-base text-content-base-default">
              {value?.label}
            </p>

            <button
              className="cursor-pointer fill-content-base-subdued"
              title="Remove option"
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                onChange(null);
              }}
            >
              <CloseCircle color="inherit" width="16px" height="16px" />
            </button>
          </div>
        )}
        {!value && (
          <span className="truncate text-input-content-unfilled">
            {placeholder}
          </span>
        )}
        <span
          className={clsx(
            'text-content-base-default',
            isMenuOpen && 'rotate-180',
          )}
        >
          <CaretDown color={'#F8FAFC'} width={'12px'} />
        </span>
      </div>
      {isMenuOpen && (
        <div className="border-t-none absolute top-[48px] z-10 flex max-h-[300px] w-full flex-col items-start justify-start overflow-y-auto overflow-x-hidden rounded-b border-b  border-l border-r bg-background-base-surface-1">
          {options.map((item) => (
            <div
              onClick={() => {
                onChange(item);
                setIsMenuOpen(false);
              }}
              key={item?.value}
              className="background-input-background-filled w-full cursor-pointer p-[10px] text-content-base-default duration-200 hover:scale-[1.01] hover:bg-background-base-surface-2 hover:transition-transform"
            >
              {item?.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterSelect;
