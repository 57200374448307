import React, { Fragment } from 'react';
import FormSection from '../../../../components/FormSection';
import { GraniteInput } from '../../../../components/V2/Input/GraniteInput';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import { GraniteLabel } from '../../../../components/V2/Label/GraniteLabel';
import RadioButton from '../../../../components/RadioButton';
import Divider from '../../../../components/Divider';
import { StandardTools } from '../../../../components/StandardTools/StandardTools';
import { ExtensionCircuitForm, ExtensionType } from './schemas';
import { TicketDetailForm } from '../DefinedFormTypes';
import {
  FormWithContact,
  TicketDetailContactForm,
} from '../../BaseComponents/TicketDetailContactForm';
import { GraniteSelect } from '../../../../components/Select/Select';
import { NonStandardToolsFieldTextField } from '../../BaseComponents/NonStandardToolsField';

export type ExtensionCircuitProps = TicketDetailForm<ExtensionCircuitForm>;

export const ExtensionCircuit = ({
  register,
  control,
  errors,
}: ExtensionCircuitProps) => {
  return (
    <Fragment>
      <FormSection title="Ticket details" gridClassName="grid-cols-2">
        <GraniteInput
          label="Where is the MPOE (minimum point of entry) for the circuit? (optional)"
          className="col-span-full"
          error={errors.mpoeLocation?.message}
          {...register('mpoeLocation')}
        />
        <GraniteInput
          label="Describe the service"
          className="col-span-full"
          placeholder="Circuit ID, carrier, IP type, use case, etc."
          error={errors.serviceDescription?.message}
          {...register('serviceDescription')}
        />
        <GraniteInput
          label="Download speed (MB) (optional)"
          className="col-span-full md:col-span-1"
          suffix="MB"
          error={errors.downloadSpeed?.message}
          {...register('downloadSpeed')}
        />
        <Controller
          name="handoffType"
          control={control}
          render={({ field: { ref: _ref, ...field }, fieldState }) => (
            <GraniteSelect
              className="col-span-full md:col-span-1"
              {...field}
              label="Handoff type (optional)"
              error={fieldState.error?.message}
              options={[
                { label: 'Single mode fiber', value: 'Single mode fiber' },
                { label: 'Multi mode fiber', value: 'Multi mode fiber' },
                {
                  label: 'Ethernet (cat5/cat6)',
                  value: 'Ethernet (cat5/cat6)',
                },
              ]}
            />
          )}
        />
        <GraniteLabel
          label="Where will this circuit be extended to?"
          element="div"
          className="col-span-full"
        >
          <Controller
            name="extension"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <RadioButton
                options={[
                  {
                    value: ExtensionType.enum.NetworkRack,
                    label: 'Network rack',
                  },
                  {
                    value: ExtensionType.enum.PatchPanelPort,
                    label: 'Patch panel port',
                  },
                  {
                    value: ExtensionType.enum.JackIn,
                    label: 'Jack in a specific place',
                  },
                ]}
                selectedValue={value?.value}
                onChangeWithLV={(value, label) => onChange({ value, label })}
                error={errors.extension?.message}
                className="col-span-2"
                ref={ref}
              />
            )}
          />
        </GraniteLabel>
        <Controller
          name="extensionLength"
          control={control}
          render={({ field }) => (
            <GraniteInput
              {...field}
              className="col-span-full col-start-1 md:col-span-1"
              label="What is the approximate length of cable needed?"
              suffix="feet"
              error={errors.extensionLength?.message}
            />
          )}
        />
      </FormSection>
      <Divider />
      <FormSection gridClassName="grid-cols-2">
        <Controller
          control={control}
          name="techToTestWith"
          render={({ field: { onChange, value, ref } }) => (
            <Fragment>
              <GraniteLabel
                label="Does the technician need to work with someone to test?"
                element="div"
                className="col-span-full"
              >
                <RadioButton
                  options={[
                    {
                      label: 'Yes, a member of our IT team',
                      value: 'CustomerIT',
                    },
                    {
                      label: 'Yes, an on site member of our team',
                      value: 'Person',
                    },
                    { label: 'No', value: 'No' },
                  ]}
                  selectedValue={value?.techToTestWith}
                  error={errors.techToTestWith?.message}
                  onChange={(techToTestWith) =>
                    onChange({ ...value, techToTestWith })
                  }
                  ref={ref}
                />
              </GraniteLabel>
              {value?.techToTestWith &&
                (value.techToTestWith === 'CustomerIT' ||
                  value.techToTestWith === 'Person') && (
                  <TicketDetailContactForm
                    errors={errors}
                    register={
                      register as unknown as UseFormRegister<FormWithContact>
                    }
                    control={control as unknown as Control<FormWithContact>}
                  />
                )}
            </Fragment>
          )}
        />
      </FormSection>
      <Divider />
      <FormSection>
        <StandardTools />
        <NonStandardToolsFieldTextField control={control} />
      </FormSection>
    </Fragment>
  );
};
