import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';

export const DeleteDraftConfirmationDialog = ({
  isOpen,
  close,
  onConfirmation,
}: {
  isOpen: boolean;
  close: () => void;
  onConfirmation: () => void;
}) => (
  <Modal isVisible={isOpen} close={close} className="max-w-[624px]">
    <div className="space-y-12 rounded-t-lg bg-background-base-surface-2 p-8">
      <div className="space-y-2">
        <h1 className="text-content-base-default">Delete this draft?</h1>
        <p className="font-bold text-content-base-subdued">
          Are you sure you want to delete this drafted ticket? Deleting the
          ticket draft will permanently remove all unsaved changes.
        </p>
      </div>
      <div className="flex gap-5">
        <GraniteButton size="large" variant="secondary" onClick={close}>
          Cancel
        </GraniteButton>
        <GraniteButton
          size="large"
          variant="destructive"
          onClick={() => {
            onConfirmation();
          }}
        >
          Yes, delete this draft
        </GraniteButton>
      </div>
    </div>
  </Modal>
);
