import { apiClient } from 'api/apiClient';
import { OktaGroupProfile } from './schema';

export const getCompanyAdmins = async () => {
  const response = await apiClient.get(`/api/v1/users/admin-contacts`);

  return response.data;
};

export const getSites = async () => {
  const response = await apiClient.get('/api/v1/companies/sites/download/');
  return response.data;
};

export const getGroup = async (groupId: string) => {
  const response = await apiClient.get(`/api/v1/groups/${groupId}`);
  return response.data;
};

export const updateGroup = async (
  request: OktaGroupProfile,
  groupId: string,
) => {
  const response = await apiClient.patch(`/api/v1/groups/${groupId}`, request);

  return response.data;
};
export const createGroup = async (request: OktaGroupProfile) => {
  const response = await apiClient.post(`/api/v1/groups`, request);

  return response.data;
};
