import { zodResolver } from '@hookform/resolvers/zod';
import { Modal } from 'components/Modal/Modal';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  EscalateTicketForm,
  EscalateTicketFormType,
  EscalateTicketRequestType,
} from './form-schema';
import { GraniteSelect } from 'components/Select/Select';
import TextAreaGranite from 'components/TextArea/TextArea';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useMutation, useQueryClient } from 'react-query';
import { escalateTicket } from 'api/nocexpress/api';

const options = [
  'Medical facilities/emergency services',
  'Updated communication required',
  'The reported issue has changed',
  'My dispatch date was missed',
];

const EscalateTicket = ({
  isOpen,
  close,
  id,
}: {
  isOpen: boolean;
  close: () => void;
  id: string | undefined;
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EscalateTicketFormType>({
    resolver: zodResolver(EscalateTicketForm),
  });

  const queryClient = useQueryClient();

  const escalateMutation = useMutation(
    ({
      ticketId,
      data,
    }: {
      ticketId: number;
      data: EscalateTicketRequestType;
    }) => escalateTicket(ticketId, data),
    {
      onSuccess: async () => {
        queryClient.refetchQueries([id?.toString(), 'ticket-details-noc']);
        queryClient.refetchQueries([id?.toString(), 'ticket-noc-notes']);
        queryClient.refetchQueries([id?.toString(), 'ticket-noc-updates']);
        close();
      },
    },
  );

  const onSubmit = (payload: EscalateTicketFormType) => {
    const request = {
      reason: payload.reason.value,
      additional_notes: payload.additional_notes,
    };

    escalateMutation.mutate({ ticketId: Number(id), data: request });
  };

  const onError = (err: unknown) => console.log(err);

  return (
    <Modal isVisible={isOpen} close={close} className="w-full max-w-[624px]">
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        className="flex flex-col gap-2 rounded-lg rounded-t-lg bg-background-base-surface-2"
      >
        <div className="rounded-t-lg bg-background-base-surface-3 px-8  py-6 font-bold leading-9">
          <h1 className="text-[28px] text-content-base-default">
            Escalate ticket
          </h1>
        </div>
        <div className="flex w-full flex-col items-start justify-start gap-6 bg-background-base-surface-2 p-8">
          <Controller
            name="reason"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <GraniteSelect
                {...field}
                className={`col-span-full`}
                onChange={onChange}
                label="Reason for escalation"
                value={value}
                options={options.map((item) => {
                  return {
                    value: item,
                    label: item,
                  };
                })}
                classNames={{
                  control: () => '!rounded-r-none',
                }}
              />
            )}
          />
          <div className="flex w-full flex-col items-start justify-start gap-2">
            <TextAreaGranite
              {...register('additional_notes')}
              className="w-full"
              error={errors.additional_notes?.message}
              placeholder="Add specific details regarding your escalation"
              label="Additional details (optional)"
            />
            <GraniteLabel className="text-sm">
              This response will be added directly into the comments of this
              ticket.
            </GraniteLabel>
          </div>
        </div>
        <div className="flex items-center justify-start gap-5 px-8 pb-8">
          <GraniteButton size="large" variant="secondary" onClick={close}>
            Cancel
          </GraniteButton>
          <GraniteButton size="large" variant="primary" type="submit">
            Submit escalation
          </GraniteButton>
        </div>
      </form>
    </Modal>
  );
};

export default EscalateTicket;
