import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { CheckmarkCircle } from 'react-ionicons';
import { useCallback } from 'react';

const PricingRequestedDialog = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const onClose = useCallback(() => {
    close();
  }, [close]);
  return (
    <Modal isVisible={isOpen} close={onClose} className="w-[624px]">
      <div className="flex flex-col items-center justify-center gap-8 rounded-lg rounded-t-lg bg-background-base-surface-2 p-8">
        <CheckmarkCircle width={'96px'} height={'96px'} color={'#82FF91'} />
        <h1 className="mb-4 text-center text-xl text-content-base-default">
          The results may take a few hours to process. You’ll be notified when
          they are ready.
        </h1>
        <GraniteButton size="large" variant="primary" onClick={onClose}>
          Close
        </GraniteButton>
      </div>
    </Modal>
  );
};

export default PricingRequestedDialog;
