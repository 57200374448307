import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ArrowBackCircle, ArrowForwardCircle } from 'react-ionicons';

const Header = () => {
  return (
    <div className="flex justify-between px-3 py-4">
      <GraniteButton variant="ghost" size="medium" className="px-6 py-0">
        <ArrowBackCircle width="20px" height="20px" color="#F8FAFC" />
        Previous location
      </GraniteButton>
      <div className="flex flex-col text-center">
        <span className="text-2xl font-bold text-content-base-default">
          0666 Schmidt Isle
        </span>
        <span className="text-base">Deerfield Beach, NV 31202-5992</span>
      </div>
      <GraniteButton variant="ghost" size="medium">
        Next location
        <ArrowForwardCircle width="20px" height="20px" color="#F8FAFC" />
      </GraniteButton>
    </div>
  );
};

export default Header;
