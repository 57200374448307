import { clsx } from 'clsx';

export interface ErrorSubtextProps {
  className?: string;
  error?: string | boolean;
  withTopMargin?: boolean;
}

export const ErrorSubtext = ({
  className,
  error,
  withTopMargin = true,
}: ErrorSubtextProps) => {
  return typeof error === 'string' ? (
    <span
      className={clsx(
        'text-xs text-input-content-error',
        withTopMargin && 'mt-1',
        className,
      )}
    >
      {error}
    </span>
  ) : null;
};
