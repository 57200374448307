import styled from 'styled-components';

export const ChartTitle = styled.h2`
  font-size: ${({ theme }) => theme.heading.size.xs};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.text.white};
  margin: 0 0 16px;
`;

export const PieSectionContainer = styled.div``;

export const LegendSectionContainer = styled.div``;

export const PieWrapper = styled.div`
  padding-left: 48px;
`;

export const PieContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const CustomLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 130px;
`;

export const Circle = styled.div<{
  fill: string;
}>`
  min-width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) => props.fill};
`;

export const TextLegend = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.text.size.md};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
