import { WizardStateBlock } from 'components/StepperWizard/WizardStateBlock';

export interface WizardProgressProps {
  step: number;
}

export const WizardProgress = ({ step }: WizardProgressProps) => {
  return (
    <div className="mb-12 grid grid-cols-2 items-end gap-1 text-xs">
      <WizardStateBlock
        label="Add template details"
        isActive={step >= 1}
        isCompleted={step > 1}
      />
      <WizardStateBlock
        label="Finalize & review your template"
        isActive={step >= 2}
        isCompleted={step >= 2}
      />
    </div>
  );
};
