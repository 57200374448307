import { z } from 'zod';
import {
  ContactSubFormSchema,
  NonStandardToolsFieldSchema,
  OptionLabelValueSchema,
} from '../../utils/schemas';
import { TicketDetailForms } from '../DefinedFormTypes';

export const EquipmentInstallFormSchema = z.object({
  formType: z.literal(TicketDetailForms.enum.EquipmentInstall),
  whichEquipment: z.string().trim().nonempty('Required'),
  whereInstall: z.string().trim().nonempty('Required'),
  portMapping: z.string(),
  installOrTroubleShootingGuideLV: OptionLabelValueSchema,
  techToTestWith: z.discriminatedUnion('techToTestWith', [
    z.object({ techToTestWith: z.literal('CustomerIT') }),
    z.object({ techToTestWith: z.literal('Person') }),
    z.object({ techToTestWith: z.literal('No') }),
  ]),
  itTestingContacts: z.array(ContactSubFormSchema),
  nonStandardTools: NonStandardToolsFieldSchema,
});

export type EquipmentInstallForm = z.infer<typeof EquipmentInstallFormSchema>;
