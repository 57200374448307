import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FormSection from 'components/FormSection';
import { LetUsHelpBaseForm } from '../BaseComponents/LetUsHelpBaseForm';
import { LetUsHelpLayout } from '../BaseComponents/LetUsHelpLayout';
import { Surface } from '../BaseComponents/Surface';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import Divider from 'components/Divider';
import { Checkbox } from 'react-ionicons';
import AddEmailRecipient from '../utils/AddEmailRecipient';
import { PriorityBadge } from 'components/PriorityBadge/PriorityBadge';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import { StandardTools } from 'components/StandardTools/StandardTools';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { TimePicker } from 'components/TimePicker/TimePicker';
import RadioButton from 'components/RadioButton';
import TextAreaGranite from 'components/TextArea/TextArea';
import { EstimatedCost } from 'components/EstimatedCost/EstimatedCost';
import { ReviewTicketConfirmationDialog } from './ReviewTicketConfirmationDialog';
import { useModal } from 'hooks/useModal';

export const ReviewSkeleton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { open, ...modalProps } = useModal();
  useEffect(() => {
    if (location.search === '?open') {
      open();
    }
  }, [location.search, open]);

  return (
    <LetUsHelpLayout>
      <LetUsHelpBaseForm className="gap-16">
        <FormSection title="Basic information" gridClassName="grid-cols-2">
          <Surface className="col-span-full grid grid-cols-2 gap-8">
            <div>
              <div className="text-base font-bold leading-6 text-content-base-subdued">
                Ticket type
              </div>
            </div>
          </Surface>
          <GraniteInput
            className="grid-cols-1"
            label="Customer ticket # (optional)"
            subtitle="Multiple entries should be separated with commas"
          />
          <GraniteInput
            className="grid-cols-1"
            label="Prior ticket # (optional)"
            subtitle="Tickets that share info pertaining to this dispatch"
          />
        </FormSection>
        <Divider />
        <FormSection title="Notifications">
          <div className="flex flex-col gap-y-4">
            <div className="col-span-full">
              <h3 className="mb-2 text-base font-bold text-content-base-subdued">
                Would you like to receive notifications for this ticket?
              </h3>
              <Checkbox
                //@ts-expect-error asd
                label="Send me updates via email"
                className="col-span-full"
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-4">
            <AddEmailRecipient
              appendFunc={function ({ email }: { email: string }): void {
                throw new Error(`Function not implemented. ${email}`);
              }}
            />
          </div>
        </FormSection>
        <Divider />
        <FormSection
          title="Dispatch date & time"
          gridClassName="grid-cols-2 w-full"
        >
          <GraniteLabel className="col-span-1" label="Date"></GraniteLabel>

          <GraniteLabel label="Access time" className="col-start-1">
            <RadioButton
              options={[
                {
                  value: 'Hard Start',
                  label: 'Arrival at specified time',
                },
                {
                  value: 'Requested Window',
                  label: 'Arrival during dispatch window',
                },
              ]}
              className="text-base"
            />
          </GraniteLabel>

          <div className="col-span-1 col-start-1">
            <GraniteLabel
              label="Specified time"
              subtitle="Time will be automatically converted to the local timezone of the location"
            >
              <TimePicker timeZoneAbbreviation={''} />
            </GraniteLabel>

            <div className="mt-2 text-sm text-content-base-subdued">
              Dispatches outside of 8:00 AM - 5:00 PM on Mon-Fri, as well as
              holidays and weekends, are considered same-day dispatches.
            </div>
          </div>
          <GraniteLabel
            label="Purchase order # (optional)"
            subtitle="Multiple entries should be separated with commas"
          >
            <GraniteInput className="mb-6" />
          </GraniteLabel>
          <Surface className="col-start-2 row-start-1 row-end-5 flex w-full flex-col items-start gap-4 self-start">
            <PriorityBadge priority={'P1'} />
            <div className="text-sm text-content-base-subdued">
              Dispatch priority level is automatically determined based on
              dispatch date & access time requested above. Dispatch priority
              will directly affect the estimated dispatch fee and hourly rates.
            </div>
            <Divider className="w-full" />
            {/* <PriorityInfo estimatedCost={estimatedCost} /> */}
          </Surface>
        </FormSection>
        <Divider />
        <FormSection title="Location details" gridClassName="grid-cols-2">
          <div className="col-span-full">
            <GraniteLabel label="Address" element="div">
              {/* <SearchAddressBar /> */}
            </GraniteLabel>
          </div>
          <GraniteInput
            label="Location name"
            subtitle="Name of the business that the technician should look for upon arrival"
            className="col-start-1"
          />
          <GraniteInput
            label="Location number (optional)"
            className="col-start-2 mt-[42px]"
          />
        </FormSection>
        <Divider />
        <FormSection
          title="Scope of work & special instructions"
          gridClassName="grid-cols-1"
        >
          <div className="flex flex-col gap-4 rounded bg-input-background-filled p-4">
            <div>
              <h3 className="mb-2 text-xl font-bold text-status-info-default">
                Scope of work
              </h3>
              <p className="text-sm text-content-base-subdued">
                These are step-by-step instructions of what needs to be done on
                site. This may include pertinent equipment info, circuit info,
                location info, or any deliverables expected after completion.
              </p>
            </div>
            <Divider />
          </div>
          <TextAreaGranite
            label="Additional notes/requests (optional)"
            subtitle="Please provide any additional instructions of what needs to be done on site that’s not already included above."
            inputClassName="resize-y"
          />
          <TextAreaGranite
            label="Special instructions (optional)"
            subtitle="Door/access codes, call LCON 30 minutes prior to arrival, etc."
            className="col-span-full"
          />
          <StandardTools />
        </FormSection>
        <Divider />
        <FormSection title="Attachments"></FormSection>
      </LetUsHelpBaseForm>
      <div className="sticky top-8 flex flex-col gap-6 rounded bg-background-base-surface-2 p-6 shadow">
        <div>
          <h2 className="mb-1 text-2xl font-bold text-content-base-default">
            Estimated price of dispatch
          </h2>
          <h3 className="text-base font-bold text-content-base-subdued">
            Duration may change once the technician is on site.
          </h3>
        </div>
        <EstimatedCost
          estimatedCost={{
            rate_per_hour: 0,
            hours: 0,
            dispatch_fee_dollars: 0,
            misc_fee_dollars: 0,
            total: 0,
            priority: 'P1',
          }}
        />
        <div className="flex gap-4">
          <GraniteButton
            className="w-full"
            variant="secondary"
            size="large"
            onClick={() => navigate(-1)}
          >
            Back
          </GraniteButton>
          <GraniteButton
            className="w-full"
            variant="primary"
            size="large"
            type="submit"
            form="ticket-review-form"
          >
            Submit
          </GraniteButton>
        </div>
      </div>
      <ReviewTicketConfirmationDialog
        {...modalProps}
        close={() => {
          return;
        }}
        estimatedCost={{
          rate_per_hour: 0,
          hours: 0,
          dispatch_fee_dollars: 0,
          misc_fee_dollars: 0,
          total: 0,
          priority: 'P1',
        }}
        onConfirmation={() => {
          return;
        }}
        ticketType={'Equipment Install'}
        product={'Field Services - MAC'}
        priority={'P1'}
      />
    </LetUsHelpLayout>
  );
};
