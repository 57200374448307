import { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Options } from '@popperjs/core';

export const useToggleablePopper = <
  TAnchorElt extends HTMLElement,
  TPopperElt extends HTMLElement,
>(
  popperOptions?: Omit<Partial<Options>, 'modifiers'>,
) => {
  const anchorRef = useRef<TAnchorElt>(null);
  const popperElementRef = useRef<TPopperElt>(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [parentWidth, setParentWidth] = useState<number | undefined>(undefined);

  const { styles, attributes } = usePopper(
    anchorRef.current,
    popperElementRef.current,
    popperOptions ?? {
      placement: 'bottom-start',
    },
  );

  useEffect(() => {
    setParentWidth(anchorRef.current?.clientWidth);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickOutside =
        popperElementRef.current &&
        event.target instanceof Node &&
        !popperElementRef.current.contains(event.target);

      if (isClickOutside) {
        setIsMenuVisible(false);
      }
    };
    if (isMenuVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuVisible]);

  return {
    isMenuVisible,
    setIsMenuVisible,
    anchorRef,
    popperElementRef,
    parentWidth,
    styles,
    attributes,
  };
};
