import { z } from 'zod';

export const StatusMetricResponseSchema = z.object({
  'On Hold': z.number(),
  'Pending Customer': z.number(),
  'Pending Tech Assignment': z.number(),
  'Tech Assigned': z.number(),
  'Tech Checked In': z.number(),
});

export type StatusMetricResponse = z.infer<typeof StatusMetricResponseSchema>;

export const PriorityMetricResponseSchema = z.object({
  p1: z.number(),
  p2: z.number(),
  p3: z.number(),
});
export type PriorityMetricResponse = z.infer<
  typeof PriorityMetricResponseSchema
>;

export const TicketTypeMetricResponseSchema = z.object({
  'Activation Repairs': z.number(),
  Billing: z.number(),
  'Broadband Circuit': z.number(),
  'Circuit Extension - Granite Network': z.number(),
  'Circuit Extension - MAC': z.number(),
  'Circuit Extension - Repair': z.number(),
  Cradlepoint: z.number(),
  'Customer Equipment': z.number(),
  'Cutover and Test': z.number(),
  'DIA Circuit': z.number(),
  Decomm: z.number(),
  Dispatch: z.number(),
  'Equipment Install': z.number(),
  'Extension Survey': z.number(),
  'Facilities Audit': z.number(),
  'Facilities Port': z.number(),
  'Fiber Extension': z.number(),
  'GRID - GRID': z.number(),
  'GRID - Repair': z.number(),
  'GRID Tail - Expansion': z.number(),
  'GRID Tail - Proper': z.number(),
  'Granite Equipment': z.number(),
  HPBX: z.number(),
  'Hosted Voice(ePOTS)': z.number(),
  'LEC Prep Install': z.number(),
  'LEC Prep Survey': z.number(),
  'MAC - EPIK': z.number(),
  'MAC - GRID': z.number(),
  Move: z.number(),
  POTS: z.number(),
  'POTS Extension': z.number(),
  'Repair - EPIK': z.number(),
  'Repair - GRID': z.number(),
  Replacement: z.number(),
  SMCT: z.number(),
  'Site Audit - Granite Network': z.number(),
  'Site Audit - MAC': z.number(),
  'Smart Hands': z.number(),
  Survey: z.number(),
  'Survey and Mount': z.number(),
  'Switch Install': z.number(),
  'Turn Up': z.number(),
});

export type TicketTypeMetricResponse = z.infer<
  typeof TicketTypeMetricResponseSchema
>;
