import { GroupBase, MultiValueRemoveProps } from 'react-select';
import { CloseCircleSharp } from 'react-ionicons';

export const CustomMultiValueRemove = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  innerProps,
}: MultiValueRemoveProps<Option, IsMulti, Group>) => {
  return (
    <div {...innerProps}>
      <CloseCircleSharp width="16px" height="16px" color="#94A3B8" />
    </div>
  );
};
