import { GetStartedQuestionnaireForm } from '../../GetStarted/schemas';
import { BroadbandCircuitForm } from './schemas';
import { ReviewTicketForm } from '../../Review/schemas';
import { questionnaireFormToReviewTicketDefaultValues } from '../../BaseComponents/questionnaireFormToReviewTicketDefaultValues';
import {
  NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX,
  NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX,
} from '../../utils/nonStandardToolsSOWUtils';
import { formatContactTesting } from 'shared/util/util';

export const broadbandCircuitFormToReview = (
  questionnaire: GetStartedQuestionnaireForm,
  form: BroadbandCircuitForm,
): Partial<ReviewTicketForm> => {
  return {
    ...questionnaireFormToReviewTicketDefaultValues(questionnaire),
    scopeOfWork: formToSOW(form),
    nonStandardTools: form.nonStandardTools,
  };
};

const formToSOW = (form: BroadbandCircuitForm) => {
  return [
    form.nonStandardTools.isNeeded
      ? `${NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX} ${form.nonStandardTools.nonStandardTools} ${NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX}\n`
      : '',
    `Service technician needed on site to repair an existing Broadband circuit.`,
    `\nThe wiring issue began ${form.whenDidIssueBegin.label}. The issue is described as follows: ${form.issueDescription}.`,
    form.techToTestWith.techToTestWith !== 'No'
      ? `\nThe technician should test with: ${formatContactTesting(
          form.itTestingContacts,
        )}`
      : '',
  ]
    .filter((l) => l)
    .join('\n');
};
