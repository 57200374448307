import { z } from 'zod';

export const ProductTypes = z.enum(['FieldServicesMAC', 'FieldServicesRepair']);
export type ProductType = z.infer<typeof ProductTypes>;

export const MACTicketTypes = z.enum([
  'POTSExtension',
  'CircuitExtension',
  'EquipmentInstall',
  'SiteAudit',
  'SmartHands',
  'Move',
]);
export type MACTicketType = z.infer<typeof MACTicketTypes>;

export const RepairTicketTypes = z.enum([
  'BroadbandCircuit',
  'POTS',
  'CircuitExtension',
  'FiberExtension',
  'CustomerEquipment',
  'MaterialReplacement',
]);
export type RepairTicketType = z.infer<typeof RepairTicketTypes>;

export const AnyTicketTypes = RepairTicketTypes.or(MACTicketTypes);
export type AnyTicketType = z.infer<typeof AnyTicketTypes>;

export const ValidTicketTypeProductPairSchema = z.discriminatedUnion(
  'product',
  [
    z.object({
      product: z.literal(ProductTypes.enum.FieldServicesMAC),
      ticketType: MACTicketTypes,
    }),
    z.object({
      product: z.literal(ProductTypes.enum.FieldServicesRepair),
      ticketType: RepairTicketTypes,
    }),
  ],
);

export type ValidTicketTypeProductPair = z.infer<
  typeof ValidTicketTypeProductPairSchema
>;

export const ticketTypeBoardOf = (pair: ValidTicketTypeProductPair) => {
  return ValidTicketTypeProductPairSchema.parse(pair);
};

// Names for product type might be different for user-facing labels and for network operations.
// Separating for now, but they might be the same.
export const netNameForProductType = (productType: ProductType) => {
  switch (productType) {
    case 'FieldServicesMAC': {
      return 'Field Services - MAC';
    }
    case 'FieldServicesRepair': {
      return 'Field Services - Repair';
    }
  }
};

// Labels for ticket type are the same used for displaying data to the user and sending to the network.
export const labelForTicketType = (ticketType: AnyTicketType) => {
  switch (ticketType) {
    case 'POTSExtension': {
      return 'POTS Extension';
    }
    case 'CircuitExtension': {
      return 'Circuit Extension';
    }
    case 'EquipmentInstall': {
      return 'Equipment Install';
    }
    case 'SiteAudit': {
      return 'Site Audit';
    }
    case 'SmartHands': {
      return 'Smart Hands';
    }
    case 'Move': {
      return 'Move';
    }
    case 'BroadbandCircuit': {
      return 'Broadband Circuit';
    }
    case 'POTS': {
      return 'POTS';
    }
    case 'FiberExtension': {
      return 'Fiber Extension';
    }
    case 'CustomerEquipment': {
      return 'Customer Equipment';
    }
    case 'MaterialReplacement': {
      return 'Customer Equipment'; // Material Replacement is Customer Equipment
    }
  }
};

export const labelForTicketTypeBoard = ({
  ticketType,
  product,
}: ValidTicketTypeProductPair) => {
  const productLabel = netNameForProductType(product).split(' - ')[1];
  const ticketTypeLabel = labelForTicketType(ticketType);
  return `${productLabel} - ${ticketTypeLabel}`;
};
