import { getTemplates } from 'api/techexpress/api';
import { Template } from 'api/techexpress/schema';
import { Modal } from 'components/Modal/Modal';
import MultiOptionButton from 'components/MultiOptionButton';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { Fragment, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { labelForTicketType } from '../BaseComponents/schemas';
import Skeleton from 'components/Skeleton/Skeleton';

const CardSkeleton = () => (
  <div className="space-y-2 rounded-lg border p-4">
    <div className="flex items-center justify-between">
      <Skeleton className="h-6 w-2/3" />
      <Skeleton className="h-6 w-6 !rounded-full" />
    </div>
    <Skeleton className="h-12 w-full" />
  </div>
);
export const ApplyTemplateModal = ({
  isOpen,
  close,
  onApplyTemplate,
}: {
  isOpen: boolean;
  close: () => void;
  onApplyTemplate: (template: Template) => void;
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<Template>();
  const { data, isLoading } = useInfiniteQuery({
    queryKey: ['tech-express', 'template'],
    queryFn: () => {
      return getTemplates();
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.metadata.total_pages <= lastPage.metadata.page) return;
      return lastPage.metadata.page + 1;
    },
  });

  return (
    <Modal isVisible={isOpen} close={close} className="w-full max-w-[836px]">
      <div className="flex h-full max-h-[75vh] flex-col gap-2 overflow-hidden rounded-t-lg bg-background-base-surface-2">
        <div className="space-y-2 bg-background-base-surface-3 px-8 py-6">
          <h1 className="text-[28px] font-bold leading-9 text-content-base-default">
            Saved templates
          </h1>
          <span className="font-semibold">
            Use one your organization’s pre-saved templates to quickly fill in
            Scope of Work for your ticket.
          </span>
        </div>
        <div className="flex min-h-0 flex-1 flex-col items-start justify-between gap-12 p-8">
          <div className="gap grid w-full grid-cols-2 gap-4 overflow-auto">
            {isLoading ? (
              <Fragment>
                <CardSkeleton />
                <CardSkeleton />
              </Fragment>
            ) : (
              data?.pages.map(
                (page) =>
                  page.data?.map((template) => (
                    <MultiOptionButton
                      key={template.id}
                      label={template.name}
                      option={template.id}
                      value={selectedTemplate?.id}
                      onClick={() => setSelectedTemplate(template)}
                      subText={template.description}
                    >
                      <div className="mt-2 w-fit rounded-lg bg-status-info-default px-2 py-1 text-xs font-bold text-content-flip-default">
                        {labelForTicketType(
                          template.data.reviewForm.ticketType,
                        )}
                      </div>
                    </MultiOptionButton>
                  )),
              )
            )}
          </div>
          <div className="flex gap-5">
            <GraniteButton size="large" variant="secondary" onClick={close}>
              Cancel
            </GraniteButton>
            <GraniteButton
              size="large"
              variant="primary"
              disabled={!selectedTemplate}
              onClick={() => {
                onApplyTemplate(selectedTemplate!);
              }}
            >
              Use template
            </GraniteButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
