import { z } from 'zod';
import { UserRole } from '../../api/users/schemas/UserRole';
import {
  LETTERS_SPACES_HYPENS_APOSTROPHE_REGEXP,
  VALID_PHONE_NUMBER,
} from 'shared/constants/validation-regex-constants';
import {
  LETTERS_SPACES_HYPENS_ERROR_LABEL,
  VALID_PHONE_ERROR_LABEL,
} from '../../shared/constants/error-labels';
import {
  UpdateUserProfileRequest,
  UserProfileResponse,
} from '../../api/users/schemas/UserProfile';
import { InviteUserRequest } from '../../api/users/schemas/Invitations';
import { mapBackendRoleToFrontendRole } from 'api/schema-utils';

export const EditUserFormSchema = z.object({
  roles: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      }),
    )
    .min(1, 'At least one role must be selected')
    .refine(
      (roles) =>
        roles.filter((role) =>
          PRIMARY_ROLES.includes(role.value as PrimaryRole),
        ).length <= 1,
      {
        message:
          'You can select only one of Viewer, User, Company Admin, or Welcome User',
        path: ['roles'],
      },
    )
    .transform((roles) =>
      roles.map((role) => ({
        value: role.value,
        label: role.value,
      })),
    ),
  first_name: z.string(),
  last_name: z.string(),
  phone_number: z
    .string()
    .nonempty(VALID_PHONE_ERROR_LABEL)
    .length(10, VALID_PHONE_ERROR_LABEL)
    .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
  sub_groups: z.array(z.string()).min(1).optional(),
});

export type EditUserForm = z.infer<typeof EditUserFormSchema>;

const PRIMARY_ROLES = [
  'viewer',
  'user',
  'company-admin',
  'welcome-user',
  'Viewer',
  'User',
  'Company Admin',
  'Welcome User',
] as const;

type PrimaryRole = (typeof PRIMARY_ROLES)[number];

export const InviteUserFormSchema = z.object({
  first_name: z
    .string()
    .nonempty('Required')
    .max(100, 'Only 100 characters allowed')
    .regex(LETTERS_SPACES_HYPENS_APOSTROPHE_REGEXP, {
      message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
    }),
  last_name: z
    .string()
    .nonempty('Required')
    .max(100, 'Only 100 characters allowed')
    .regex(LETTERS_SPACES_HYPENS_APOSTROPHE_REGEXP, {
      message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
    }),
  email: z.string().nonempty('Required').email(),
  phone: z
    .string()
    .nonempty(VALID_PHONE_ERROR_LABEL)
    .length(10, VALID_PHONE_ERROR_LABEL)
    .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
  companyId: z.number().optional(),
  roles: z.array(
    z.object({
      value: z.string(),
      label: z.string(),
    }),
  ),
});

export type InviteUserForm = z.infer<typeof InviteUserFormSchema>;

export const inviteUserFormToRequest = (
  formData: InviteUserForm,
  inviterName: string | undefined,
): InviteUserRequest => {
  return [
    {
      first_name: formData.first_name,
      last_name: formData.last_name,
      phone_number: formData.phone,
      email: formData.email,
      role: mapBackendRoleToFrontendRole(formData.roles[0].value) as UserRole,
      inviter_name: inviterName,
    },
  ];
};

export const responseToUserEditForm = (
  response?: UserProfileResponse,
): EditUserForm | undefined => {
  if (!response) return undefined;
  return {
    roles: response.roles,
    first_name: response.first_name,
    last_name: response.last_name,
    phone_number: response.phone_number,
  };
};

export const userEditFormToEditRequest = (
  form: EditUserForm,
  base: UserProfileResponse,
): UpdateUserProfileRequest => {
  return {
    first_name: form.first_name,
    last_name: form.last_name,
    phone_number: form.phone_number,
    roles: form.roles.map((role) => role.value),
    company_title: base.company_title,
    department: base.department,
    secondary_email: base.secondary_email,
    secondary_phone_number: base.secondary_phone_number,
  };
};
