import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import clsx from 'clsx';

interface MenuLinkProps extends NavLinkProps {
  children: React.ReactNode;
  visible: boolean;
}

const MenuLink = React.forwardRef<HTMLAnchorElement, MenuLinkProps>(
  ({ children, visible, ...props }, ref) => {
    return (
      <NavLink
        {...props}
        ref={ref}
        className={clsx(
          'group relative inline-block p-1 text-right transition-all duration-300 ease-in-out',
          visible ? 'visible opacity-100' : 'invisible opacity-0',
        )}
      >
        <>
          <div className="inline-block origin-bottom-left transform text-xs uppercase tracking-[1.5px] text-content-base-subdued transition-all duration-300 ease-in-out group-hover:scale-[1.05] group-hover:text-content-base-default">
            {children}
            <span className="absolute -bottom-0.5 left-0 h-[1px] w-0 bg-stroke-accent-default transition-all duration-300 ease-in-out group-hover:w-[62px]"></span>
          </div>
        </>
      </NavLink>
    );
  },
);

MenuLink.displayName = 'MenuLink';

export default MenuLink;
