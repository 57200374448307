import React, { useCallback, useEffect, useRef } from 'react';
import { useOpenNetOpsTicketContext } from '../WizardProvider/WizardReducer';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchBasedOnIdentifier } from 'api/nocexpress/api';

const ResubmitTicketWithAddress = () => {
  const { state, dispatch } = useOpenNetOpsTicketContext();
  const isFirstRender = useRef<boolean>(true);
  const { addressName, priorTicket } = useParams<{
    addressName?: string;
    priorTicket?: string;
  }>();
  const fetchFunction = useCallback(() => {
    return fetchBasedOnIdentifier('Address', addressName as string);
  }, [addressName]);

  const {
    data: searchResults,
    isLoading,
    isError,
  } = useQuery(['noc-search-bar', addressName], fetchFunction, {
    enabled: !!addressName?.trim(),
    retry: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (
      !isLoading &&
      !isError &&
      isFirstRender.current &&
      searchResults &&
      searchResults.length > 0
    ) {
      isFirstRender.current = false;
      dispatch({
        type: 'ResubmitWithPriorTicketAndAddress',
        findLocationOrServiceForm: {
          selectedIdentifier: searchResults[0],
          type: 'site',
          service: [],
        },
        ticketDetails: {
          prior_ticket: priorTicket,
        },
      });
      navigate('/noc-express/open-ticket');
    }
  }, [
    isError,
    isLoading,
    navigate,
    state,
    searchResults,
    dispatch,
    priorTicket,
  ]);

  return <></>;
};

export default ResubmitTicketWithAddress;
