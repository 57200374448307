import { AvatarGranite } from 'components';
import Tabs from 'components/Table/Tabs';
import { useNavigate } from 'react-router-dom';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useRef, useState } from 'react';
import PageTitle from 'components/PageTitle';
import FilterSelect from 'components/FilterSelect/FilterSelect';
import Filters from 'components/Filters';
import Searchbar from 'components/Table/SearchBar';
import { TableTitle } from 'components/Table/Table.styles';
import {
  ColumnDef,
  ColumnFiltersState,
  OnChangeFn,
  Row,
  Table as ReactTable,
} from '@tanstack/react-table';
import Table from 'components/Table';
import { BadgeRole } from 'components/V2/BadgeRole/BadgeRole';
import { plainTextToPhoneNumber } from 'shared/util/util';
import { useAuthUser } from '../../hooks/useAuthUser';
import { userRolesEnumToSelectOptions } from '../../components/HeaderNav/utils';
import { useMutation, useQuery } from 'react-query';
import format from 'date-fns/format';
import {
  cancelInvitation,
  getInvitedUsers,
  getUsers,
  inviteUser,
} from 'api/users/api';
import { useModal } from '../../hooks/useModal';
import { BulkImportUsersDialog } from '../../components/BulkImportUsersDialog/BulkImportUsersDialog';
import { DropdownButton } from '../../components/DropdownButton/DropdownButton';
import { People, Person } from 'react-ionicons';
import {
  TokenUserPermissions,
  TokenUserRoles,
  USERS_LIMIT,
  UsersResponse,
} from '../../api/users/schemas/Users';
import { isAfterDate } from '../../components/Table/filters';
import { startOfDay, subMonths } from 'date-fns';
import {
  InvitationItem,
  InviteUserRequest,
  InviteUserResponse,
} from '../../api/users/schemas/Invitations';
import showToast from '../../components/Toast/Toast';
import { UserRole } from '../../api/users/schemas/UserRole';
import {
  INVITE_TABLE_QK,
  useInviteTableRefetch,
} from './useInviteTableRefetch';
import {
  mapBackendRoleToFrontendRole,
  mapFrontendRoleToBackendRole,
} from 'api/schema-utils';
import { DisplayForPermission } from 'components/Permission/DisplayForPermission';
import { usePermissions } from 'hooks/usePermissions';
import UserStatusColumn from '../../components/UserStatusColumn';

const AvatarNameCell = ({ name }: { name: string }) => {
  return (
    <div className="flex items-center justify-start gap-4">
      <AvatarGranite
        width="w-8"
        height="h-8"
        name={name}
        fontSize={'text-xs'}
      />
      <h2 className="text-sm text-content-base-default">{name}</h2>
    </div>
  );
};

const UserManagementUsersDef: ColumnDef<UsersResponse>[] = [
  {
    id: 'name',
    header: 'User',
    accessorKey: 'name',
    cell: (row) => {
      const user = row?.row?.original?.name;
      return <AvatarNameCell name={user} />;
    },
  },
  {
    id: 'role',
    header: 'Role',
    accessorKey: 'role',
    cell: (row) => {
      const role =
        row?.row?.original?.roles?.find((role) =>
          [
            TokenUserRoles.COMPANY_ADMIN,
            TokenUserRoles.USER,
            TokenUserRoles.VIEWER,
            TokenUserRoles.WELCOME_USER,
          ].includes(mapFrontendRoleToBackendRole(role) as TokenUserRoles),
        ) ?? 'Viewer';
      return <BadgeRole role={role} />;
    },
  },
  {
    id: 'status',
    accessorKey: 'status',
    header: 'Status',
    cell: (row: { row: { original: { status: string } } }) => (
      <UserStatusColumn status={row?.row?.original?.status} />
    ),
  },
  {
    id: 'phone_number',
    header: 'Phone',
    enableSorting: false,
    cell: (row) =>
      row?.row?.original?.phone_number
        ? plainTextToPhoneNumber(row?.row?.original?.phone_number)
        : ' -- ',
    accessorKey: 'phone_number',
  },
  {
    id: 'email',
    header: 'Email',
    accessorKey: 'email',
  },
  {
    id: 'date_added',
    header: 'Date created',
    cell: (row) => {
      const userData = row?.row?.original;
      const dateAdded = userData?.invited_at || userData?.created_at;
      return dateAdded ? format(new Date(dateAdded), 'MM/dd/yyyy') : ' -- ';
    },
    accessorKey: 'invited_at',
    filterFn: isAfterDate,
  },
];

const InvitationManagementTableDef: ColumnDef<InvitationItem>[] = [
  {
    id: 'name',
    header: 'User',
    accessorKey: 'name',
    cell: (row) => (
      <AvatarNameCell
        name={`${row?.row?.original?.first_name ?? ''} ${
          row?.row?.original?.last_name ?? ''
        }`}
      />
    ),
  },
  {
    id: 'role',
    header: 'Role',
    accessorKey: 'role',
    cell: (row) => {
      const role = row?.row?.original?.role ?? 'Viewer';
      return <BadgeRole role={role} />;
    },
  },
  {
    id: 'phone_number',
    header: 'Phone',
    enableSorting: false,
    cell: (row) =>
      row?.row?.original?.phone_number
        ? plainTextToPhoneNumber(row?.row?.original?.phone_number)
        : ' -- ',
    accessorKey: 'phone_number',
  },
  {
    id: 'email',
    header: 'Email',
    accessorKey: 'email',
  },
  {
    id: 'date_created',
    header: 'Date created',
    cell: (row) =>
      row?.row?.original?.created_at
        ? format(new Date(row?.row?.original?.created_at), 'MM/dd/yyyy')
        : ' -- ',
    accessorKey: 'created_at',
    filterFn: isAfterDate,
  },
  {
    header: 'Actions',
    enableSorting: false,
    cell: (cell) => <InvitationActions row={cell.row} />,
  },
];

const InvitationActions = ({ row }: { row: Row<InvitationItem> }) => {
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  const { name: inviterName } = useAuthUser();
  const refetchInviteList = useInviteTableRefetch();

  const cancelInvitationMutation = useMutation(cancelInvitation, {
    onSuccess: (response) => {
      if (!isSubmiting) {
        if (response) {
          refetchInviteList();
          showToast.confirmation({ message: 'Invitation revoked.' });
        } else {
          showToast.error({ message: 'Unknown error revoking invitation.' });
        }
      }
    },
  });

  const cancelInvitationMutationNoToastNotification =
    useMutation(cancelInvitation);

  const resendInvitationMutation = useMutation(
    (request: InviteUserRequest) => {
      return inviteUser(request);
    },
    {
      onSuccess: (response: InviteUserResponse[]) => {
        if (response.length !== 1) {
          showToast.error({ message: 'An unknown error occured' });
        }
        const status = response[0];
        if (status.result === 'Success') {
          showToast.confirmation({ message: 'Invite sent' });
        } else {
          showToast.error({ message: status.error_message });
        }
      },
      onSettled: () => {
        refetchInviteList();
        setIsSubmiting(false);
      },
    },
  );

  return (
    <div className="flex items-center justify-between gap-4 pr-2">
      <GraniteButton
        disabled={isSubmiting}
        variant="secondary"
        size="small"
        onClick={() => {
          setIsSubmiting(true);
          cancelInvitationMutationNoToastNotification.mutate(row.original.id);
          resendInvitationMutation.mutate([
            {
              first_name: row.original.first_name,
              last_name: row.original.last_name,
              email: row.original.email,
              phone_number: row.original.phone_number ?? '',
              role: mapBackendRoleToFrontendRole(row.original.role) as UserRole,
              inviter_name: inviterName,
            },
          ]);
        }}
      >
        Resend
      </GraniteButton>
      <GraniteButton
        variant="destructive"
        disabled={isSubmiting}
        size="small"
        onClick={() => {
          setIsSubmiting(true);
          cancelInvitationMutation.mutate(row.original.id);
        }}
      >
        Revoke
      </GraniteButton>
    </div>
  );
};

const statusOptions = [
  { value: 'Active', label: 'ACTIVE' },
  { value: 'Deactivated', label: 'Deactivated' },
];

const filterByDate = [
  {
    date: startOfDay(subMonths(new Date(), 1)),
    value: 'pastMonth',
    label: 'Past month',
  },
  {
    date: startOfDay(subMonths(new Date(), 3)),
    value: 'pastThreeMonths',
    label: 'Past 3 months',
  },
];

const UserManagement = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<
    'existingUsers' | 'pendingInvitations'
  >('existingUsers');

  const tableRef = useRef<ReactTable<unknown>>();
  const [filterState, setFilterState] = useState<ColumnFiltersState>([]);
  const [searchState, setSearchState] = useState<string | undefined>();
  const clearFilters = () => {
    setFilterState([]);
  };

  const tabs = [
    {
      title: 'Existing users',
      onClick: () => {
        setActiveTab('existingUsers');
        clearFilters();
      },
      active: false,
    },
    {
      title: 'Pending invitations',
      onClick: () => {
        setActiveTab('pendingInvitations');
        clearFilters();
      },
      active: false,
      className: 'product-tour-user-management-tab',
    },
  ];

  const roleOptions = [
    ...userRolesEnumToSelectOptions(),
    { label: 'Super Admin', value: 'Super Admin' },
  ];

  const { open: bulkInviteDialogOpen, ...bulkInviteDialogProps } = useModal();

  return (
    <div className="w-full p-8">
      <div className="user-management mx-auto flex w-full max-w-[1376px] flex-col items-start justify-start">
        <div className="mb-6 flex w-full items-center justify-between">
          <PageTitle title="User management" heightSpace={true} />
          <div className="flex items-center">
            <div className="product-tour-user-management-invite-user flex w-full items-center justify-start gap-4">
              <DisplayForPermission onlyForSuperAdmin>
                <GraniteButton
                  size="large"
                  variant="secondary"
                  onClick={() => navigate('/users/roles')}
                >
                  Manage roles
                </GraniteButton>
              </DisplayForPermission>
              <DisplayForPermission
                permission={TokenUserPermissions.USER_MANAGEMENT}
              >
                <DropdownButton
                  className="product-tour-user-management"
                  label="Invite user"
                  options={[
                    {
                      label: (
                        <>
                          <Person
                            width="16px"
                            height="16px"
                            color="rgb(var(--content-base-default))"
                          />
                          <span className="ml-3">Invite user</span>
                        </>
                      ),
                      onClick: () => navigate('/users/invite-user/'),
                    },
                    {
                      label: (
                        <>
                          <People
                            width="16px"
                            height="16px"
                            color="rgb(var(--content-base-default))"
                          />
                          <span className="ml-3">Bulk invite</span>
                        </>
                      ),
                      onClick: bulkInviteDialogOpen,
                    },
                  ]}
                />
              </DisplayForPermission>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col items-start justify-start">
          <TableTitle className="!mb-3">Users</TableTitle>
          <div className="mb-8 w-full">
            <Tabs tabs={tabs} />
          </div>
          <div className="mb-12 flex w-full flex-wrap items-start justify-between gap-4">
            <div className="w-full sm:flex-1">
              <Searchbar
                placeholder="Search by name, email, or phone number"
                clearAllValues={!searchState}
                onQueryClear={() =>
                  tableRef.current?.setGlobalFilter(undefined)
                }
                onSearch={(query: string) => {
                  tableRef.current?.setGlobalFilter(query);
                }}
              />
            </div>
            <Filters clearFilters={clearFilters}>
              <FilterSelect
                placeholder="Filter user role"
                options={roleOptions}
                value={roleOptions.find(
                  (ro) =>
                    ro.value ===
                    filterState.find((fs) => fs.id === 'role')?.value,
                )}
                onChange={(value) => {
                  tableRef.current
                    ?.getColumn('role')
                    ?.setFilterValue(value?.value);
                }}
              />
              {activeTab === 'existingUsers' && (
                <FilterSelect
                  placeholder="Filter user status"
                  options={statusOptions}
                  value={statusOptions.find(
                    (so) =>
                      so.value ===
                      filterState.find((fs) => fs.id === 'status')?.value,
                  )}
                  onChange={(value) => {
                    tableRef.current
                      ?.getColumn('status')
                      ?.setFilterValue(value?.value);
                  }}
                />
              )}
              <FilterSelect
                placeholder="Filter by date created"
                options={filterByDate}
                value={filterByDate.find(
                  (fbd) =>
                    fbd.date === filterState.find((fs) => fs.value)?.value,
                )}
                onChange={(value) => {
                  const columnId =
                    activeTab === 'existingUsers'
                      ? 'date_added'
                      : 'date_created';
                  tableRef.current
                    ?.getColumn(columnId)
                    ?.setFilterValue(
                      filterByDate.find((fbd) => fbd.value === value?.value)
                        ?.date,
                    );
                }}
              />
            </Filters>
          </div>
          {activeTab === 'existingUsers' ? (
            <UserManagementTable
              columnFilterState={filterState}
              onColumnFiltersChange={setFilterState}
              globalFilterState={searchState}
              onGlobalFilterChange={setSearchState}
              onTableLoaded={(table) =>
                (tableRef.current = table as ReactTable<unknown>)
              }
            />
          ) : (
            <InviteManagementTable
              columnFilterState={filterState}
              onColumnFiltersChange={setFilterState}
              globalFilterState={searchState}
              onGlobalFilterChange={setSearchState}
              onTableLoaded={(table) =>
                (tableRef.current = table as ReactTable<unknown>)
              }
            />
          )}
        </div>
      </div>
      <BulkImportUsersDialog {...bulkInviteDialogProps} />
    </div>
  );
};

interface UserManagementTableProps<T> {
  columnFilterState: ColumnFiltersState;
  onColumnFiltersChange: OnChangeFn<ColumnFiltersState>;
  onTableLoaded: (table: ReactTable<T>) => void;
  globalFilterState: string | undefined;
  onGlobalFilterChange: OnChangeFn<string | undefined>;
}

const UserManagementTable = ({
  columnFilterState,
  onColumnFiltersChange,
  onTableLoaded,
  globalFilterState,
  onGlobalFilterChange,
}: UserManagementTableProps<UsersResponse>) => {
  const navigate = useNavigate();
  const { hasPermission } = usePermissions({
    permission: TokenUserPermissions.MANAGE_GROUPS,
  });
  const { data, isFetching } = useQuery(
    ['user-management-table'],
    () =>
      getUsers({
        limit: USERS_LIMIT,
      }),
    {
      refetchOnMount: true,
    },
  );

  const formattedData =
    hasPermission && !isFetching
      ? data
      : data?.filter((item) => !item.email.endsWith('@granitenet.com'));

  const handleRowClick = (row: UsersResponse) => {
    navigate(`/users/${row?.email}`);
  };

  return (
    <Table
      data={formattedData ?? []}
      columns={UserManagementUsersDef}
      title="Users"
      isLoading={isFetching}
      handleRowClick={handleRowClick}
      columnFiltersState={columnFilterState}
      onColumnFiltersChange={onColumnFiltersChange}
      onTableLoaded={onTableLoaded}
      globalFilterState={globalFilterState}
      onGlobalFilterChange={onGlobalFilterChange}
    />
  );
};

const InviteManagementTable = ({
  columnFilterState,
  onColumnFiltersChange,
  onTableLoaded,
  globalFilterState,
  onGlobalFilterChange,
}: UserManagementTableProps<InvitationItem>) => {
  const { hasPermission } = usePermissions({
    permission: TokenUserPermissions.MANAGE_GROUPS,
  });
  const { data, isFetching } = useQuery(
    INVITE_TABLE_QK,
    () =>
      getInvitedUsers({
        limit: USERS_LIMIT,
      }),
    {
      refetchOnMount: true,
    },
  );

  const formattedData =
    hasPermission && !isFetching
      ? data?.invitations
      : data?.invitations.filter(
          (item) => !item.email.endsWith('@granitenet.com'),
        );

  return (
    <Table
      data={formattedData ?? []}
      columns={InvitationManagementTableDef}
      title="Users"
      columnFiltersState={columnFilterState}
      onColumnFiltersChange={onColumnFiltersChange}
      onTableLoaded={onTableLoaded}
      isLoading={isFetching}
      globalFilterState={globalFilterState}
      onGlobalFilterChange={onGlobalFilterChange}
    />
  );
};

export default UserManagement;
