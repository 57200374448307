import { z } from 'zod';

export const PaginationMetadataSchema = z.object({
  page: z.number(),
  page_size: z.number(),
  total_items: z.number(),
  total_pages: z.number(),
});

export type PaginationMetadata = z.infer<typeof PaginationMetadataSchema>;

const BasePaginationSchema = z.object({
  metadata: PaginationMetadataSchema,
  data: z.array(z.any()),
});

export type BasePagination<T> = { metadata: PaginationMetadata; data: T[] };

export const createPaginatedResponseSchema = <ItemType extends z.ZodTypeAny>(
  itemSchema: ItemType,
) =>
  BasePaginationSchema.merge(
    z.object({
      data: z.array(itemSchema),
    }),
  );

export const PaginableRequestSchema = z
  .object({
    page_size: z.number(),
    page: z.number(),
  })
  .partial();

export type PaginableRequest = z.infer<typeof PaginableRequestSchema>;

export const SortableFieldEnum = z.enum(['asc', 'desc']).optional();

export const RecaptchaSchema = z.object({
  recaptcha: z
    .string()
    .nullish()
    .superRefine((value, ctx) => {
      if (value === null || value === undefined)
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please verify that you are not a robot',
          fatal: true,
        });
      if (value === 'expired')
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Verification expired. Check the checkbox again.',
        });
    }),
});
