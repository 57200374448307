import React from 'react';

const HorizontalFieldWrapper: React.FC<{
  children: React.ReactNode;
  containerWidth?: string;
  title: string;
  description: string;
}> = ({ children, containerWidth = '920px', title, description }) => {
  return (
    <div
      style={{ width: containerWidth }}
      className={`flex items-start gap-6 rounded-[4px] bg-background-base-surface-3 p-4`}
    >
      <div className="w-1/2">
        <p className="text-2xl font-bold leading-8 text-content-base-default">
          {title}
        </p>
        <p className="text-base font-medium leading-6 text-content-base-subdued">
          {description}
        </p>
      </div>
      <div className="w-1/2">{children}</div>
    </div>
  );
};

export default HorizontalFieldWrapper;
