import React from 'react';
import closeCircle from '../../assets/icon/close-circle.svg';
import clsx from 'clsx';
import { GraniteButton } from 'components/V2/Button/GraniteButton';

type BannerProps = {
  type?: 'generic' | 'confirmation' | 'informative' | 'error';
  title: string;
  message: string;
  showCloseButton?: boolean;
  showActions?: boolean;
  className?: string;
};

const getTitleColor = (type: string) => {
  switch (type) {
    case 'generic':
      return 'text-content-base-default';
    case 'confirmation':
      return 'text-status-success-default';
    case 'informative':
      return 'text-status-info-default';
    case 'error':
      return 'text-status-error-default';
    default:
      return 'text-content-base-default';
  }
};

const Banner: React.FC<BannerProps> = ({
  type = 'generic',
  title,
  message,
  showCloseButton,
  showActions,
  className,
}) => {
  return (
    <div
      className={clsx('rounded bg-background-base-surface-0 p-3', className)}
    >
      <div className="mb-2 flex items-center justify-between">
        <h4 className={`text-base font-bold ${getTitleColor(type)}`}>
          {title}
        </h4>
        {showCloseButton && (
          <img className="cursor-pointer" src={closeCircle} />
        )}
      </div>
      <p className="mb-3 text-sm font-semibold leading-5 text-content-base-default">
        {message}
      </p>
      {showActions && (
        <div className="flex items-center gap-1">
          <GraniteButton size="small">Button</GraniteButton>
          <GraniteButton variant="ghost" size="small">
            Button
          </GraniteButton>
        </div>
      )}
    </div>
  );
};

export default Banner;
