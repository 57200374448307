import { z } from 'zod';
export const USERS_LIMIT = 500;

export const UsersResponseSchema = z.object({
  name: z.string(),
  roles: z.array(z.string()),
  status: z.string(),
  email: z.string(),
  phone_number: z.string(),
  created_at: z.string(),
  invited_at: z.string(),
});

export type UsersResponse = z.infer<typeof UsersResponseSchema>;

export const GetUsersParamsSchema = z
  .object({
    limit: z.number().default(USERS_LIMIT),
    after: z.string(),
    pending: z.boolean(),
  })
  .partial();

export type GetUsersParams = z.infer<typeof GetUsersParamsSchema>;

export enum TokenUserRoles {
  // systemRoles
  COMPANY_ADMIN = 'company-admin',
  USER = 'user',
  VIEWER = 'viewer',
  WELCOME_USER = 'welcome-user',
  // systemRoles end
  // these roles must delete in future
  SUPER_ADMIN = 'super-admin',
  GRANITE_BETA_TESTER = 'granite-beta-tester',
  EARLY_ADOPTER = 'early-adopter',
  QR_ADMIN = 'qr-admin',
  PROSPECT = 'prospect',
}

export enum TokenUserPermissions {
  TECH_EXPRESS_READ = 'read:techexpress',
  TECH_EXPRESS_WRITE = 'write:techexpress',
  TECH_EXPRESS_MANAGE = 'manage:techexpress',
  //
  NOC_EXPRESS_READ = 'read:nocexpress',
  NOC_EXPRESS_WRITE = 'write:nocexpress',
  //
  ACCESS_EXPRESS_READ = 'read:accessexpress',
  ACCESS_EXPRESS_WRITE = 'write:accessexpress',
  ACCESS_EXPRESS_MANAGE = 'manage:accessexpress',
  //
  USER_MANAGEMENT = 'manage:users',
  ROLE_MANAGE = 'manage:roles',
  USER_ROLE_MANAGEMENT = 'manage:user-roles',
  //
  MELISSA_DATA_VERIFY = 'verify:melissadata',
  //
  CONTACT_GROUPS_READ = 'read:contact-groups',
  CONTACT_GROUPS_WRITE = 'write:contact-groups',
  //
  API_KEYS_MANAGE = 'manage:api-keys',
  //
  MOBILITY_READ = 'read:mobility',
  //
  RESOURCES_READ = 'read:resources',
  RESOURCES_WRITE = 'write:resources',
  // only for testing cases
  TEST = 'read:test',
  INVENTORY_READ = 'read:inventory',
  MANAGE_GROUPS = 'manage:groups',
}

export const TokenUserRolesSet = new Set([
  TokenUserRoles.VIEWER,
  TokenUserRoles.USER,
  TokenUserRoles.COMPANY_ADMIN,
  TokenUserRoles.SUPER_ADMIN,
  TokenUserRoles.GRANITE_BETA_TESTER,
  TokenUserRoles.EARLY_ADOPTER,
  TokenUserRoles.QR_ADMIN,
  TokenUserRoles.WELCOME_USER,
  TokenUserRoles.PROSPECT,
]);

export enum G360Pillars {
  AccessExpress = 'access-express',
  NOCExpress = 'noc-express',
}

export const G360PillarsSet = new Set([
  G360Pillars.AccessExpress,
  G360Pillars.NOCExpress,
]);

export const canViewAccessExpress = (userRole: TokenUserRoles): boolean => {
  return [
    TokenUserRoles.GRANITE_BETA_TESTER,
    TokenUserRoles.EARLY_ADOPTER,
    TokenUserRoles.QR_ADMIN,
    TokenUserRoles.PROSPECT,
  ].includes(userRole);
};
