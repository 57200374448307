import { KeyboardEventHandler } from 'react';

export const preventSubmitOnEnter: KeyboardEventHandler = (event) => {
  const targetIsTextArea =
    event.target instanceof HTMLTextAreaElement &&
    event.target.tagName === 'TEXTAREA';

  if (event.key === 'Enter' && !targetIsTextArea) {
    event.preventDefault();
  }
};
