/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, RefObject } from 'react';

const useScrollbarPresence = (
  ref: RefObject<HTMLElement>,
  dependencies: ReadonlyArray<unknown> = [],
) => {
  const [hasScrollbar, setHasScrollbar] = useState(false);

  const checkForScrollbar = () => {
    if (ref.current) {
      setHasScrollbar(ref.current.scrollHeight > ref.current.clientHeight);
    }
  };

  useEffect(() => {
    checkForScrollbar();
  }, [ref, ...dependencies]);

  useEffect(() => {
    checkForScrollbar();

    const handleResize = () => {
      checkForScrollbar();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return hasScrollbar;
};

export default useScrollbarPresence;
