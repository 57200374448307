export const NoResultSvg = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6785 8.46867C36.5629 7.58427 36.5629 6.15058 35.6785 5.26663C34.7941 4.38269 33.3609 4.38269 32.4765 5.26663L5.26618 32.4765C4.38223 33.3609 4.38223 34.7946 5.26618 35.6785C5.7086 36.1205 6.28779 36.3419 6.86742 36.3419C7.44706 36.3419 8.02624 36.1205 8.46867 35.6785L35.6785 8.46867ZM33.3278 30.7661C35.5889 27.9458 36.9442 24.3684 36.9442 20.4726C36.9442 17.5699 36.1911 14.8447 34.8729 12.4763L32.174 15.1752C32.9089 16.7919 33.3215 18.5842 33.3215 20.4726C33.3215 27.5577 27.5577 33.3219 20.4726 33.3219C18.5842 33.3219 16.7914 32.9089 15.1752 32.1744L12.4763 34.8729C14.8447 36.1911 17.5699 36.9447 20.4726 36.9447C24.3684 36.9447 27.9458 35.5889 30.7657 33.3278L41.6624 44.2245C42.0165 44.5782 42.4797 44.7548 42.9434 44.7548C43.4072 44.7548 43.8704 44.5782 44.2245 44.2245C44.9319 43.5167 44.9319 42.3702 44.2245 41.6628L33.3278 30.7661ZM6.07178 28.4688C4.75402 26.1005 4.00049 23.3753 4.00049 20.4726C4.00049 11.3755 11.3755 4.00049 20.4726 4.00049C23.3748 4.00049 26.1005 4.75402 28.4688 6.07178L25.7699 8.77071C24.1533 8.03621 22.3609 7.62322 20.4726 7.62322C13.3874 7.62322 7.62322 13.3874 7.62322 20.4726C7.62322 22.3609 8.03621 24.1533 8.77071 25.7699L6.07178 28.4688Z"
        fill="#94A3B8"
      />
    </svg>
  );
};
