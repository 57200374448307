import { formatPhoneNumber } from 'screens/LetUsHelp/utils/formatPhoneNumber';
import { ContactSubForm } from 'screens/LetUsHelp/utils/schemas';
import { lookup } from 'zipcode-to-timezone';

export const convertHexToRgba = (hex: string, opacity: number) => {
  return `rgba(${parseInt(hex.slice(1, 3), opacity)},
  ${parseInt(hex.slice(3, 5), opacity)},
  ${parseInt(hex.slice(5, 7), opacity)},
  0.5)`;
};

export const removeDuplicatesFromArray = <T>(array: T[]): T[] => {
  const uniqueValues = new Set(array);
  return Array.from(uniqueValues);
};

export function toSentenceCase(str: string) {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const formatContactTesting = (itTestingContacts: ContactSubForm[]) => {
  return (
    (itTestingContacts.length > 1 ? '\n' : '') +
    itTestingContacts
      .map(
        (contact, index, array) =>
          `${array.length > 1 ? `• Testing contact #${index + 1}:` : ''} ${
            contact.contactName
          } who can be reached via phone at ${formatPhoneNumber(
            contact.contactNumber,
          )}${
            contact.contactEmail ? ` or email at ${contact.contactEmail}` : ''
          }.`,
      )
      .join('\n')
  );
};

const getRange = (start: number, end: number): number[] => {
  return Array(end - start + 1)
    .fill(0)
    .map((_, i) => i + start);
};

export const pagination = (
  currentPage: number,
  pageCount: number,
): (number | string)[] => {
  let delta: number;
  if (pageCount <= 7) {
    delta = 7;
  } else {
    delta = currentPage > 4 && currentPage < pageCount - 3 ? 2 : 3;
  }
  const range = {
    start: Math.round(currentPage - delta / 2),
    end: Math.round(currentPage + delta / 2),
  };

  if (range.start - 1 === 1 || range.end + 1 === pageCount) {
    range.start += 1;
    range.end += 1;
  }

  let pages: (number | string)[];

  if (currentPage > delta) {
    pages = getRange(
      Math.min(range.start, pageCount - delta),
      Math.min(range.end, pageCount),
    );
  } else {
    pages = getRange(1, Math.min(pageCount, delta + 1));
  }

  const withDots = (
    value: number | string,
    pair: (number | string)[],
  ): (number | string)[] => (pages.length + 1 !== pageCount ? pair : []);

  if (pages[0] !== 1) {
    pages = withDots(1, ['1', '...']).concat(pages);
  }

  if (+pages[pages.length - 1] < pageCount) {
    pages = pages.concat(
      withDots(pageCount, ['...', (pageCount - 1).toString()]),
    );
  }
  return pages;
};
export const plainTextToPhoneNumber = (str: string) =>
  `(${str.slice(0, 3)}) ${str.slice(3, 6)}-${str.slice(6)}`;

export const getTimeZoneAbbreviationFromZipCode = (zipcode: string) => {
  const targetTimezone = lookup(zipcode);
  if (targetTimezone)
    return new Date()
      .toLocaleTimeString('en-US', {
        timeZone: targetTimezone,
        timeZoneName: 'short',
      })
      .split(' ')
      .pop();
};

export const setCookie = (name: string, value: string, days: number): void => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = `${name}=${value};${expires};path=/`;
};

export const getCookie = (name: string): string | null => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
