import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useOpenNetOpsTicketContext } from '../WizardProvider/WizardReducer';

export const ResubmitTicketPrior = () => {
  const { state, dispatch } = useOpenNetOpsTicketContext();
  const isFirstRender = useRef<boolean>(true);
  const { priorTicket, initialDescription } = useParams<{
    priorTicket?: string;
    initialDescription?: string;
  }>();

  const navigate = useNavigate();
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      dispatch({
        type: 'ResubmitWithPriorTicketAndInitialDescription',
        findLocationOrServiceForm: {
          service: [],
        },
        ticketDetails: {
          prior_ticket: priorTicket,
          initialDescription: initialDescription,
        },
      });
      navigate('/noc-express/open-ticket');
    }
  }, [navigate, state, dispatch, priorTicket, initialDescription]);

  return <></>;
};
