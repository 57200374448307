import { GetStartedQuestionnaireForm } from '../../GetStarted/schemas';
import { MoveForm } from './schemas';
import { ReviewTicketForm } from '../../Review/schemas';
import { questionnaireFormToReviewTicketDefaultValues } from '../../BaseComponents/questionnaireFormToReviewTicketDefaultValues';
import {
  NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX,
  NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX,
} from '../../utils/nonStandardToolsSOWUtils';
import { formatContactTesting } from 'shared/util/util';

export const moveFormToReview = (
  questionnaire: GetStartedQuestionnaireForm,
  form: MoveForm,
): Partial<ReviewTicketForm> => {
  return {
    ...questionnaireFormToReviewTicketDefaultValues(questionnaire),
    scopeOfWork: formToSOW(form),
    nonStandardTools: form.nonStandardTools,
  };
};

const formToSOW = (form: MoveForm) => {
  return [
    form.nonStandardTools.isNeeded
      ? `${NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX} ${form.nonStandardTools.nonStandardTools} ${NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX}\n`
      : '',
    `Service technician needed on site to move ${form.equipmentOrServicesBeingMoved.label} from current location ${form.whereIsTheService} to new location ${form.whereDoesItNeedToBeMoved}.`,
    form.techToTestWith.techToTestWith !== 'No'
      ? `\n${
          form.itTestingContacts.length > 1
            ? 'The technician should test with the following technical contacts'
            : 'The technician will need to test with'
        }: ${formatContactTesting(form.itTestingContacts)}`
      : '',
  ]
    .filter((l) => l)
    .join('\n');
};
