import { apiClient } from '../apiClient';
import {
  GetResourceFilterSearchParams,
  GetResourceFilterSearchParamsSchema,
  ResourceAPIResponse,
  ResourceAPIResponseSchema,
  TagAPIResponse,
  TagAPIResponseSchema,
} from './schema';

export const getTags = async (): Promise<TagAPIResponse> => {
  const response = await apiClient.get('/api/v1/resources/tags');
  return TagAPIResponseSchema.parse(response.data);
};

export const getResources = async (
  parameters: GetResourceFilterSearchParams,
): Promise<ResourceAPIResponse> => {
  const params = GetResourceFilterSearchParamsSchema.parse(parameters);
  const response = await apiClient.get('/api/v1/resources', {
    params,
  });
  return ResourceAPIResponseSchema.parse(response.data);
};

export const createResource = async (data: FormData) => {
  const response = await apiClient.post('/api/v1/resources', data, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const updateResource = async ({
  id,
  data,
}: {
  id: string;
  data: FormData;
}) => {
  const response = await apiClient.patch(`/api/v1/resources/${id}`, data, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const deleteResource = async (id: string) => {
  const response = await apiClient.delete(`/api/v1/resources/${id}`);
  return response.data;
};

export const getSignedUrl = async (id: string): Promise<{ url: string }> => {
  const response = await apiClient.get(`/api/v1/resources/${id}/sign`);
  return response.data;
};
