import { NetOpsTicketStatuses } from 'api/nocexpress/schema';
import React from 'react';
import { returnMainStatusOfTicket } from './TicketDetails/util';

const StatusIndicatorNoc = ({ status }: { status: string }) => {
  const getBackgroundColor = () => {
    const unifiedStatus = returnMainStatusOfTicket(status);
    switch (unifiedStatus) {
      case NetOpsTicketStatuses.Enum.Open:
        return '#FBB979';
      case NetOpsTicketStatuses.Enum['In Progress']:
        return '#82FF91';
      case NetOpsTicketStatuses.Enum.Resolved:
        return '#82F0FF';
      case NetOpsTicketStatuses.Enum['Pending Action']:
      case NetOpsTicketStatuses.Enum['Pending NI']:
        return '#F9CB34';
      case NetOpsTicketStatuses.Enum['Pending Customer']:
        return '#FF315E';
      default:
        return '#FBB979';
    }
  };
  return (
    <div
      style={{ backgroundColor: getBackgroundColor() }}
      className="absolute left-0 h-[12px] w-[12px] rounded-full "
    ></div>
  );
};

export default StatusIndicatorNoc;
