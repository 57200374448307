import { isAfter, setHours, startOfHour } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

/**
 * Gets today cutoff time, ie 3PM EST as Date representing that moment in UTC
 */
export const getTodaysCutoffTime = (): Date => {
  return zonedTimeToUtc(
    setHours(startOfHour(new Date()), 15),
    'America/New_York',
  );
};

export const isAfterCutoffTime = () => {
  const cutoff = getTodaysCutoffTime();
  return isAfter(new Date(), cutoff);
};
