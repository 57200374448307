import { useRef, useEffect, useState } from 'react';
import { components, PlaceholderProps } from 'react-select';
import { GroupBase } from 'react-select';

const CustomPlaceholder = <Option,>(
  props: PlaceholderProps<Option, boolean, GroupBase<Option>>,
) => {
  const placeholderRef = useRef<HTMLDivElement>(null);
  const [showTitle, setShowTitle] = useState(false);

  useEffect(() => {
    const element = placeholderRef.current;
    if (element && element.scrollWidth > element.clientWidth) {
      setShowTitle(true);
    }
  }, []);

  return (
    <components.Placeholder {...props}>
      <div
        ref={placeholderRef}
        className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap"
        title={showTitle ? String(props.children) : undefined}
      >
        {props.children}
      </div>
    </components.Placeholder>
  );
};

export default CustomPlaceholder;
