import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import { ColumnDef } from '@tanstack/react-table';

interface CVSProps {
  src: string;
  className?: string;
}

export const CVS: React.FC<CVSProps> = ({ src, className }) => {
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCsv = async () => {
      const response = await fetch(src);
      const csvText = await response.text();
      Papa.parse<string[]>(csvText, {
        complete: (result) => {
          setLoading(false);
          setCsvData(result.data.slice(0, -1));
        },
      });
    };
    fetchCsv();
  }, [src]);

  const columns: ColumnDef<{ header: string }>[] = React.useMemo(
    () =>
      csvData[0]?.map((header) => ({
        header: header.length ? header : '--',
        accessorKey: header.length ? header : '--',
        enableSorting: false,
      })) ?? [],
    [csvData],
  );

  const data = React.useMemo(
    () =>
      csvData
        .slice(1)
        .map((row) =>
          Object.fromEntries(
            row.map((cell, i) => [csvData[0][i], cell.length ? cell : '--']),
          ),
        ),
    [csvData],
  );

  return (
    <div className={className}>
      <ServerPaginatedTable
        data={data}
        columns={columns as { header: string }[]}
        title="Resources-CVS"
        columnGap="48px"
        isFetchingData={loading}
        itemCount={data.length}
        onPaginationChange={() => {}}
        pageCount={0}
        tableContainerClassName="max-h-[440px]"
        paginationState={{ pageIndex: 0, pageSize: 0 }}
        showPagination={false}
      />
    </div>
  );
};
