import { DispatchSchedule } from '../../../api/techexpress/schemas/SiteDispatchDateScheduleSchemas';
import { Control, useController } from 'react-hook-form';
import { GraniteLabel } from '../../../components/V2/Label/GraniteLabel';
import RadioButton from '../../../components/RadioButton';
import React, { Fragment } from 'react';
import {
  RangeTimePicker,
  TimePicker,
} from '../../../components/TimePicker/TimePicker';

export type FormWithAccessTime = {
  accessTime: DispatchSchedule;
};

export interface AccessTimeFieldProps<FormType extends FormWithAccessTime> {
  control: FormType extends FormWithAccessTime ? Control<FormType> : never;
  timeZoneAbbreviation?: string;
  classNameRadioLabel?: string;
  classNameTimePicker?: string;
  classNameTimePickerWrapper?: string;
  disabled?: boolean;
}

export const AccessTimeField = <FormType extends FormWithAccessTime>({
  control,
  timeZoneAbbreviation,
  classNameRadioLabel,
  classNameTimePicker,
  classNameTimePickerWrapper,
  disabled,
}: AccessTimeFieldProps<FormType>) => {
  const { field: schedulingTypeField } = useController({
    control,
    name: 'accessTime.scheduling_type',
  });
  const {
    field: startDateField,
    fieldState: { error: startDateError },
  } = useController({
    control,
    name: 'accessTime.start_date',
  });
  const {
    field: endDateField,
    fieldState: { error: endDateError },
  } = useController({
    control,
    name: 'accessTime.end_date',
  });

  return (
    <Fragment>
      <GraniteLabel label="Access time" className={classNameRadioLabel}>
        <RadioButton
          options={[
            {
              value: 'Hard Start',
              label: 'Arrival at specified time',
            },
            {
              value: 'Requested Window',
              label: 'Arrival during dispatch window',
            },
          ]}
          selectedValue={schedulingTypeField.value}
          {...schedulingTypeField}
          className="text-base"
          disabled={disabled}
        />
      </GraniteLabel>
      <div className={classNameTimePickerWrapper}>
        {schedulingTypeField.value === 'Hard Start' ? (
          <GraniteLabel
            label="Specified time"
            subtitle="Time will be automatically converted to the local timezone of the location"
            className={classNameTimePicker}
          >
            <TimePicker
              {...startDateField}
              error={startDateError?.message}
              timeZoneAbbreviation={timeZoneAbbreviation}
              disabled={disabled}
            />
          </GraniteLabel>
        ) : (
          <GraniteLabel
            label="Dispatch window"
            subtitle="Time will be automatically converted to the local timezone of the location"
            className="col-start-1 col-end-2"
          >
            <RangeTimePicker
              onStartChange={startDateField.onChange}
              startValue={startDateField.value}
              onEndChange={endDateField.onChange}
              endValue={endDateField.value}
              error={startDateError?.message ?? endDateError?.message}
              timeZoneAbbreviation={timeZoneAbbreviation}
              ref={startDateField.ref}
              disabled={disabled}
            />
          </GraniteLabel>
        )}
        <div className="mt-2 text-sm text-content-base-subdued">
          Dispatches outside of 8:00 AM - 5:00 PM on Mon-Fri, as well as
          holidays and weekends, are considered same-day dispatches.
        </div>
      </div>
    </Fragment>
  );
};
