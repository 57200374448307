import { Control, useController } from 'react-hook-form';
import React, { Fragment } from 'react';
import { GraniteLabel } from '../../../components/V2/Label/GraniteLabel';
import Checkbox from '../../../components/Checkbox';
import GraniteTextArea from '../../../components/TextArea/TextArea';
import { NonStandardToolsField } from '../utils/schemas';

export type FormWithNonStandardTools = {
  nonStandardTools: NonStandardToolsField;
};
export interface NonStandardToolsFieldProps<
  FormType extends FormWithNonStandardTools,
> {
  control: FormType extends FormWithNonStandardTools
    ? Control<FormType>
    : never;
  classNameCheckboxLabel?: string;
  classNameTextArea?: string;
}

export const NonStandardToolsFieldTextField = <
  FormType extends FormWithNonStandardTools,
>({
  control,
  classNameTextArea,
  classNameCheckboxLabel,
}: NonStandardToolsFieldProps<FormType>) => {
  const { field: isNeededField } = useController({
    control,
    name: 'nonStandardTools.isNeeded',
  });
  const {
    field: nonStandardToolsField,
    fieldState: { error: nonStandardToolsError },
  } = useController({
    control,
    name: 'nonStandardTools.nonStandardTools',
  });

  return (
    <Fragment>
      <GraniteLabel
        className={classNameCheckboxLabel}
        label="Will any non-standard tools be needed? (optional)"
      >
        <Checkbox
          label="Yes, additional non-standard tools are required"
          checked={isNeededField.value}
          onChange={isNeededField.onChange}
          ref={isNeededField.ref}
        />
      </GraniteLabel>
      {isNeededField.value && (
        <GraniteTextArea
          className={classNameTextArea}
          label="Please note the required non-standard tools"
          subtitle="If requesting a lift, please specify how high the lift will need to reach in order to secure the appropriate lift type."
          error={nonStandardToolsError?.message}
          {...nonStandardToolsField}
        />
      )}
    </Fragment>
  );
};
