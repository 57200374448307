import clsx from 'clsx';
import { useHeightTransition } from 'hooks/useTransitionHeight';

const HeaderContainer = ({
  shouldAnimate,
  isLoading,
  children,
}: {
  shouldAnimate: boolean;
  isLoading: boolean;
  children: React.ReactNode;
}) => {
  const { contentRef, contentHeight, isContentVisible } =
    useHeightTransition(isLoading);
  return shouldAnimate ? (
    <div
      className={clsx(
        'overflow-hidden transition-all duration-700 ease-in-out',
        shouldAnimate &&
          (isContentVisible
            ? 'translate-y-0 opacity-100'
            : 'translate-y-[-20px] opacity-0'),
      )}
      style={{ height: contentHeight }}
      ref={contentRef}
    >
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

export default HeaderContainer;
