import { zodResolver } from '@hookform/resolvers/zod';
import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { Controller, useForm } from 'react-hook-form';
import {
  ISSUE_TYPES,
  FEEDBACK_TYPES,
  FeedbackForm,
  FeedbackFormSchema,
} from './schema';
import GraniteTextArea from 'components/TextArea/TextArea';
import { postFeedback } from 'api/techexpress/api';
import { useMutation } from 'react-query';
import showToast from 'components/Toast/Toast';
import { GraniteSelect } from 'components/Select/Select';

const FeedbackDialog = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const {
    handleSubmit,
    register,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<FeedbackForm>({
    resolver: zodResolver(FeedbackFormSchema),
  });

  const [feedback_type] = watch(['feedback_type']);

  const postFeedbackMutation = useMutation(
    ({ feedback }: { feedback: FeedbackForm }) => postFeedback(feedback),
    {
      onSuccess: () => {
        close();
        reset({});
        showToast.confirmation({
          message: 'Thanks for your feedback',
        });
      },
    },
  );

  const onSubmit = (feedback: FeedbackForm) => {
    postFeedbackMutation.mutate({ feedback });
  };

  const onError = (err: unknown) => console.log(err);

  return (
    <Modal isVisible={isOpen} close={close} className="w-[624px]">
      <div className="flex flex-col gap-2 rounded-lg bg-background-base-surface-2">
        <h1 className="rounded-t-lg bg-background-base-surface-3 px-8 py-6  text-[28px] font-bold leading-9 text-content-base-default">
          Need help?
        </h1>
        <div className="px-8 py-6">
          <form
            onSubmit={handleSubmit(onSubmit, onError)}
            className="grid grid-cols-1 gap-6"
          >
            <Controller
              name="feedback_type"
              control={control}
              render={({ field: { onChange, value, ...field } }) => (
                <GraniteSelect
                  {...field}
                  options={FEEDBACK_TYPES.options.map((option) => ({
                    label: option,
                    value: option,
                  }))}
                  className="w-full"
                  onChange={onChange}
                  label="How can we help?"
                  value={value}
                  error={errors.feedback_type?.message}
                />
              )}
            />
            {feedback_type?.value ===
              FEEDBACK_TYPES.Values['Granite360 technical support'] && (
              <Controller
                name="issue_type"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <GraniteSelect
                    {...field}
                    className="w-full"
                    onChange={onChange}
                    label="Select an issue"
                    value={value}
                    options={ISSUE_TYPES.options.map((option) => ({
                      label: option,
                      value: option,
                    }))}
                    error={errors.issue_type?.message}
                  />
                )}
              />
            )}
            <GraniteTextArea
              label="Describe the issue"
              inputClassName="resize-y"
              error={errors.description?.message}
              {...register('description')}
            />
            <div className="mt-6 flex gap-5">
              <GraniteButton
                size="large"
                variant="secondary"
                onClick={() => {
                  close();
                  reset({});
                }}
              >
                Cancel
              </GraniteButton>
              <GraniteButton size="large" variant="primary" type="submit">
                Submit
              </GraniteButton>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default FeedbackDialog;
