import Skeleton from 'components/Skeleton/Skeleton';
import { forwardRef } from 'react';

export const CardSkeleton = forwardRef<HTMLDivElement>((_, ref) => (
  <div
    className="flex w-full gap-4 rounded bg-background_base_surface-3_subdued p-4"
    ref={ref}
  >
    <Skeleton className="h-12 w-12 shrink-0 !rounded-full" />
    <div className="flex w-full flex-col gap-2">
      <Skeleton className="h-5 w-1/4 !rounded-sm" />
      <Skeleton className="h-5 w-1/2 !rounded-sm" />
      <Skeleton className="mt-1 h-4 w-1/6 !rounded-sm" />
    </div>
  </div>
));

CardSkeleton.displayName = 'CardSkeleton';
