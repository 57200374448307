import { NumericCell } from 'components/Table/Table.styles';
import Searchbar from 'components/Table/SearchBar';
import { useState } from 'react';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import { useSortedTable } from 'hooks/useSortedTable';
import { EmptySearchResults } from 'screens/QuoteIndex/QuoteIndexPage';
import { ColumnDef } from '@tanstack/react-table';
import { getPermissions } from '../utils';
import { PaginableRequest } from 'api/common-schemas';
import { PermissionType } from '../roles-permissions.types';
import { Outlet, useNavigate } from 'react-router-dom';
import { GraniteButton } from 'components/V2/Button/GraniteButton';

const Permissions = () => {
  const [search, setSearch] = useState<string>('');

  const navigate = useNavigate();
  const { sortingQueryParams, sortingState, onSortingChange } = useSortedTable({
    initialSorting: [{ id: 'name', desc: false }],
  });
  const _getPermissions = (args: PaginableRequest) =>
    getPermissions({
      ...args,
      ...(search && { search }),
      ...sortingQueryParams,
    });

  const { data: tableData, ...paginatedTableProps } = usePaginatedTable(
    _getPermissions,
    {
      search,
      sortingQueryParams,
    },
    ['permissions-index-table', search, sortingQueryParams],
    {
      refetchOnMount: true,
    },
  );

  const tableHeaders: ColumnDef<PermissionType>[] = [
    {
      header: 'Permission',
      accessorKey: 'name',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      enableSorting: false,
    },
    {
      header: '# of roles associated',
      accessorKey: 'role_count',
      cell: (row) => <NumericCell>{row.row.original.role_count}</NumericCell>,
      meta: {
        align: 'right',
      },
    },
    {
      header: 'Actions',
      cell: (row) => (
        <>
          <GraniteButton
            size="small"
            className="mr-3"
            variant="secondary"
            onClick={() =>
              navigate(`edit/${row.row.original.id}`, { replace: true })
            }
          >
            Edit
          </GraniteButton>
          <GraniteButton
            size="small"
            variant="destructive"
            className="mr-1"
            onClick={() =>
              navigate(
                `delete/${row.row.original.id}/${row.row.original.name}`,
                { replace: true },
              )
            }
          >
            Delete
          </GraniteButton>
        </>
      ),
    },
  ];

  return (
    <div className="flex w-full flex-wrap items-start justify-between gap-4">
      <Outlet />
      <Searchbar
        placeholder="Search by permission type"
        onSearch={setSearch}
        onQueryClear={() => {
          setSearch('');
        }}
        clearAllValues={search === ''}
      />
      <div className="mt-6 w-full">
        <ServerPaginatedTable
          data={tableData}
          columns={tableHeaders}
          title="Permissions"
          sortingState={sortingState}
          onSortingChange={onSortingChange}
          emptyDataElement={<EmptySearchResults />}
          tableContainerClassName="!grid-cols-[max-content_1fr_max-content_max-content]"
          columnGap="48px"
          {...paginatedTableProps}
        />
      </div>
    </div>
  );
};

export default Permissions;
