import { BasePagination, PaginableRequest } from 'api/common-schemas';
import // ContactGroupListItemType,
// DeviceType,
'./ContactGroups/ContactGroups';
import { AlertConfigurationItemType } from './AlertConfiguration/AlertConfigurationTabel';
import { apiClient } from 'api/apiClient';
import {
  ContactGroupListItemType,
  DeviceType,
  ContactGroupsSearchParams,
  ContactGroupType,
  ContactGroupPostType,
  ContactGroupPatchType,
  DeviceContactGroupPatchType,
} from './ContactGroups/Form/schema';

export enum TabsTypeEnum {
  group,
  devices,
}
// temporary
const data = [
  {
    id: 0,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MX67C-NA', company_name: 'Meraki' },
    account: '85979050',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Enabled' as AlertConfigurationItemType['status'],
    time: 50,
    'high-latency': 100,
    recovery: 350,
  },
  {
    id: 1,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MR42', company_name: 'Meraki' },
    account: '85979050',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Enabled' as AlertConfigurationItemType['status'],
    time: 8,
    'high-latency': 100,
    recovery: 350,
  },
  {
    id: 2,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MR42', company_name: 'Meraki' },
    account: '72737243',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Enabled' as AlertConfigurationItemType['status'],
    time: 50,
    'high-latency': 100,
    recovery: 350,
  },
  {
    id: 3,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MX67C-NA', company_name: 'Meraki' },
    account: '85979050',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Enabled' as AlertConfigurationItemType['status'],
    time: 50,
    'high-latency': 100,
    recovery: 350,
  },
  {
    id: 4,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MX67C-NA', company_name: 'Meraki' },
    account: '62156059',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Enabled' as AlertConfigurationItemType['status'],
    time: 50,
    'high-latency': 100,
    recovery: 350,
  },
  {
    id: 5,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MR42', company_name: 'Meraki' },
    account: '62156059',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Not eligible' as AlertConfigurationItemType['status'],
    time: 50,
    'high-latency': 100,
    recovery: 350,
  },
  {
    id: 6,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MX67C-NA', company_name: 'Meraki' },
    account: '85979050',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Not eligible' as AlertConfigurationItemType['status'],
    time: null,
    'high-latency': null,
    recovery: null,
  },
  {
    id: 7,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MX67C-NA', company_name: 'Meraki' },
    account: '85979050',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Enabled' as AlertConfigurationItemType['status'],
    time: 50,
    'high-latency': 100,
    recovery: 350,
  },
  {
    id: 8,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MR42', company_name: 'Meraki' },
    account: '85979050',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Disabled' as AlertConfigurationItemType['status'],
    time: null,
    'high-latency': null,
    recovery: null,
  },
  {
    id: 9,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MX67C-NA', company_name: 'Meraki' },
    account: '25386693',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Enabled' as AlertConfigurationItemType['status'],
    time: 50,
    'high-latency': 100,
    recovery: 350,
  },
  {
    id: 10,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MX67C-NA', company_name: 'Meraki' },
    account: '85979050',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Disabled' as AlertConfigurationItemType['status'],
    time: null,
    'high-latency': null,
    recovery: null,
  },
  {
    id: 11,
    device: 'c904c72b-d45a-49b0',
    model: { name: 'MX67C-NA', company_name: 'Meraki' },
    account: '85979050',
    city: '5832 Boat Club Rd',
    address_1: 'Fort Worth, TX 76179',
    status: 'Enabled' as AlertConfigurationItemType['status'],
    time: null,
    'high-latency': null,
    recovery: null,
  },
];

export const getAlertConfigurationsMockData = (
  args: PaginableRequest,
): BasePagination<AlertConfigurationItemType> => {
  return {
    data: data.slice(0, args.page_size),
    metadata: {
      page: 1,
      page_size: args.page_size || 0,
      total_items: 12,
      total_pages: 1,
    },
  };
};

export const getContactGroups = async (
  args: ContactGroupsSearchParams,
): Promise<BasePagination<ContactGroupListItemType>> => {
  const response = await apiClient.get('/api/v1/noc/contact/groups', {
    params: args,
  });
  return response.data;
};

export const getContactGroupById = async (
  id: number,
): Promise<ContactGroupType> => {
  const response = await apiClient.get(`/api/v1/noc/contact/groups/${id}`);
  return response.data;
};

export const deleteContactGroupById = async (id: number): Promise<unknown> => {
  const response = await apiClient.delete(`/api/v1/noc/contact/groups/${id}`);
  return response.data;
};

export const deactivateContactGroupById = async (
  id: number,
): Promise<unknown> => {
  const response = await apiClient.get(
    `/api/v1/noc/contact/groups/${id}/deactivate`,
  );
  return response.data;
};
export const activateContactGroupById = async (
  id: number,
): Promise<unknown> => {
  const response = await apiClient.get(
    `/api/v1/noc/contact/groups/${id}/activate`,
  );
  return response.data;
};

export const removeMeFromContactGroupById = async (
  id: number,
): Promise<unknown> => {
  const response = await apiClient.get(
    `/api/v1/noc/contact/groups/myself/${id}`,
  );
  return response.data;
};

export const getDevices = async (
  args: PaginableRequest,
): Promise<BasePagination<DeviceType>> => {
  const response = await apiClient.get('/api/v1/noc/contact/groups/devices', {
    params: args,
  });
  return response.data;
};

export const postContactGroup = async (
  body: ContactGroupPostType,
): Promise<ContactGroupType> => {
  const response = await apiClient.post('/api/v1/noc/contact/groups', body);
  return response.data;
};

export const patchContactGroup = async (
  body: ContactGroupPatchType,
): Promise<ContactGroupType> => {
  const { id, ...rest } = body;
  const response = await apiClient.patch(
    `/api/v1/noc/contact/groups/${id}`,
    rest,
  );
  return response.data;
};

export const patchDevicesContactGroup = async (
  body: DeviceContactGroupPatchType,
): Promise<ContactGroupType> => {
  const { id, ...rest } = body;
  const response = await apiClient.patch(
    `/api/v1/noc/contact/groups/devices/${id}`,
    rest,
  );
  return response.data;
};
