import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useModal } from 'hooks/useModal';
import { useMutation } from 'react-query';
import { deleteContactGroupById } from '../../utils';
import { FC } from 'react';
import showToast from 'components/Toast/Toast';

interface DeleteGroupProps {
  contactGroupId: number;
  onSuccess: () => void;
}

const DeleteGroup: FC<DeleteGroupProps> = ({ contactGroupId, onSuccess }) => {
  const { open, isOpen, close } = useModal();

  const { mutate: deleteContactGroupByIdMutate, isLoading } = useMutation(
    () => deleteContactGroupById(contactGroupId),
    {
      onSuccess: () => {
        onSuccess();
        close();
        showToast.confirmation({ message: 'Deleted' });
      },
      onError: () => {
        showToast.error({ message: 'Failed to delete' });
        close();
      },
    },
  );
  return (
    <>
      <GraniteButton variant="destructive" onClick={open}>
        Delete group
      </GraniteButton>
      <Modal isVisible={isOpen} close={close} className="w-full max-w-[624px]">
        <div className="p-8">
          <div>
            <h1 className="text-2xl font-bold text-white">
              Are you sure you want to delete this contact group?
            </h1>
            <p className="mt-2 text-base font-bold text-neutral-500">
              This action cannot be reversed.
            </p>
          </div>
          <div className="mt-12 flex gap-5">
            <GraniteButton variant="secondary" size="large" onClick={close}>
              Cancel
            </GraniteButton>
            <GraniteButton
              size="large"
              variant="destructive"
              onClick={() => !isLoading && deleteContactGroupByIdMutate()}
            >
              Yes, delete this group
            </GraniteButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteGroup;
