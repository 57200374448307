import { OptionType } from 'components/Select/Select';
import {
  Label,
  RadioButtonInput,
  RadioContainer,
  RadioLabel,
  StyledRadioButton,
} from './RadioButton.styles';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { ErrorSubtext } from '../ErrorSubtext/ErrorSubtext';
import clsx from 'clsx';

export interface RadioButtonProps {
  label?: string;
  error?: string;
  options: OptionType[];
  selectedValue?: string;
  onChange?: (value: string) => void;
  onChangeWithLV?: (value: string, label: string) => void;
  horizontal?: boolean;
  className?: string;
  containerClass?: string;
  disabled?: boolean;
}

const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  (
    {
      options,
      selectedValue,
      onChange,
      onChangeWithLV,
      label,
      error,
      horizontal,
      className,
      containerClass,
      disabled = false,
    },
    ref,
  ) => {
    const [checkedValue, setCheckedValue] = useState(selectedValue);

    useEffect(() => setCheckedValue(selectedValue), [selectedValue]);

    const handleRadioChange = (value: string, label: string) => {
      if (disabled) return;
      setCheckedValue(value);
      if (onChangeWithLV) {
        onChangeWithLV(value, label);
      } else {
        onChange && onChange(value);
      }
    };

    return (
      <div className={className}>
        {label && <Label>{label}</Label>}
        <RadioContainer
          horizontal={horizontal}
          className={clsx(error ? 'error' : undefined, containerClass)}
        >
          {options.map((option, index) => (
            <RadioLabel
              key={option.value}
              onClick={() => handleRadioChange(option.value, option.label)}
              horizontal={horizontal}
              data-disabled={disabled}
            >
              <RadioButtonInput
                ref={index === 0 ? ref : null}
                value={option.value}
                checked={checkedValue === option.value}
                onChange={() => handleRadioChange(option.value, option.label)}
                aria-disabled={disabled}
              />
              <StyledRadioButton
                checked={checkedValue === option.value}
                disabled={disabled}
              />
              <span className={`${disabled ? 'opacity-50' : 'opacity-100'}`}>
                {option.label}
              </span>
            </RadioLabel>
          ))}
        </RadioContainer>
        {error && <ErrorSubtext error={error} />}
      </div>
    );
  },
);

RadioButton.displayName = 'RadioButton';

RadioButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  selectedValue: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onChangeWithLV: PropTypes.func,
  horizontal: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default RadioButton;
