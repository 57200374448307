import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useCallback,
  useRef,
} from 'react';
import { useOktaAuth } from '@okta/okta-react';
import {
  AccessToken,
  AuthState,
  CustomUserClaims,
  OktaAuth,
  SignoutOptions,
  toRelativeUrl,
  UserClaims,
} from '@okta/okta-auth-js';
import { PageLoader } from 'components/PageLoader';
import { signupGraniteUser } from 'api/auth/auth';
import { G360Token } from 'api/auth/schema';

export interface AuthContextType {
  authState: AuthState | null;
  oktaAuth: OktaAuth;
  user: UserClaims<CustomUserClaims> | null;
  logout: (options?: SignoutOptions) => Promise<boolean>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [user, setUser] = useState<UserClaims<CustomUserClaims> | null>(null);
  const [loading, setLoading] = useState(true);
  const isLoggingOut = useRef(false);
  const logout = useCallback(
    async (options?: SignoutOptions): Promise<boolean> => {
      try {
        await oktaAuth.signOut(options);
        isLoggingOut.current = true;
        return true;
      } catch (error) {
        return false;
      }
    },
    [oktaAuth],
  );

  const initializeUserSession = useCallback(async () => {
    try {
      const user = await oktaAuth.getUser();
      setUser(user);
      const isGraniteAccount = user.email?.split('@')[1] === 'granitenet.com';
      switch (user.onboarding_status) {
        case 'MIGRATING':
        case 'PROSPECT':
        case 'COMPLETED': {
          if (window.location.pathname !== '/auth/select-company') {
            const g360Token: G360Token | undefined = JSON.parse(
              localStorage.getItem('g360-token-storage') ?? '{}',
            );
            if (!g360Token?.token || g360Token?.user_id !== user.sub) {
              localStorage.removeItem('g360-token-storage');
              return window.location.replace(
                toRelativeUrl('/auth/select-company', window.location.origin),
              );
            }
          }
          setLoading(false);
          break;
        }

        case 'PENDING':
          window.location.replace(
            toRelativeUrl('/auth/pending-verification', window.location.origin),
          );
          break;

        default:
          if (!isGraniteAccount) {
            if (window.location.pathname === '/signup') {
              setLoading(false);
            } else {
              window.location.replace(
                toRelativeUrl('/signup', window.location.origin),
              );
            }
          } else {
            const { accessToken } = (await oktaAuth.tokenManager.get(
              'accessToken',
            )) as AccessToken;
            await signupGraniteUser(accessToken);
            window.location.replace(
              toRelativeUrl('/auth/select-company', window.location.origin),
            );
          }
          break;
      }
    } catch (error) {
      oktaAuth.signOut();
    }
  }, [oktaAuth]);

  useEffect(() => {
    if (isLoggingOut.current) {
      localStorage.removeItem('g360-token-storage');
    }
    if (!authState || isLoggingOut.current) {
      return;
    }

    if (authState.isAuthenticated) {
      initializeUserSession();
    } else {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin,
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [authState, initializeUserSession, oktaAuth]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <AuthContext.Provider value={{ authState, oktaAuth, user, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
