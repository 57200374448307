const Marker = ({
  read,
  onClick,
}: {
  read: boolean;
  onClick: (e: React.MouseEvent) => void;
}) => {
  return (
    <div
      onClick={onClick}
      className="hover:rounded-full hover:border hover:border-stroke-base-default"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {!read && <circle cx="12" cy="12" r="4" fill="#82F0FF" />}
      </svg>
    </div>
  );
};

export default Marker;
