import { GetStartedQuestionnaireForm } from '../../GetStarted/schemas';
import { EquipmentInstallForm } from './schemas';
import { ReviewTicketForm } from '../../Review/schemas';
import { questionnaireFormToReviewTicketDefaultValues } from '../../BaseComponents/questionnaireFormToReviewTicketDefaultValues';
import {
  NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX,
  NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX,
} from '../../utils/nonStandardToolsSOWUtils';
import { formatContactTesting } from 'shared/util/util';

export const equipmentInstallFormToReview = (
  questionnaire: GetStartedQuestionnaireForm,
  form: EquipmentInstallForm,
): Partial<ReviewTicketForm> => {
  return {
    ...questionnaireFormToReviewTicketDefaultValues(questionnaire),
    scopeOfWork: formToSOW(form),
    nonStandardTools: form.nonStandardTools,
  };
};

const formToSOW = (form: EquipmentInstallForm) => {
  return [
    form.nonStandardTools.isNeeded
      ? `${NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX} ${form.nonStandardTools.nonStandardTools} ${NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX}\n`
      : '',
    `Service technician needed on site to install the following equipment: ${form.whichEquipment}. The equipment should be installed at ${form.whereInstall}.`,
    form.portMapping
      ? `\nThe port mapping for LAN or specific gear is as follows: ${form.portMapping}.`
      : '',
    form.installOrTroubleShootingGuideLV.value !== 'No'
      ? '\nThere will be a installation guide available to the technician on site.'
      : '',
    form.techToTestWith.techToTestWith !== 'No'
      ? `\nOnce the equipment has been successfully installed, the technician should test with ${
          form.techToTestWith.techToTestWith === 'CustomerIT'
            ? 'customer IT'
            : 'person on site'
        }: ${formatContactTesting(form.itTestingContacts)}`
      : '',
  ]
    .filter((l) => l)
    .join('\n');
};
