import { QEQuoteAPIResponse } from '../../../api/accessexpress/schema';
import { Fragment, useMemo, useState } from 'react';
import { ProductSelectionStore } from './ProductSelectionStore';
import { quoteAPIResponseToQEQuote } from '../schemas/QE';
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { SelectProductsWizardContext } from './OpenQuoteWizardReducer';
import { useQuery } from 'react-query';
import { getQuote } from '../../../api/accessexpress/api';
import { WithLoader } from '../../../components/WithLoader/WithLoader';
import { ContentLayout } from '../../../layouts/ContentLayout/ContentLayout';
import PageTitle from '../../../components/PageTitle';
import { Observer } from 'mobx-react-lite';
import { OpenQuoteWizardProgress } from './OpenQuoteWizardProgress';
import { OpenQuoteWizardStore } from './OpenQuoteWizardStore';
import { LoaderGranite } from '../../../components';

const SelectProductsContext = ({ quote }: { quote: QEQuoteAPIResponse }) => {
  const [wizardStore] = useState(() => new OpenQuoteWizardStore());
  const [productSelectionStore] = useState(
    () => new ProductSelectionStore(quoteAPIResponseToQEQuote(quote)),
  );

  const navigate = useNavigate();
  const location = useLocation();
  const isUserInLocationDetail = useMemo(
    () => location.pathname.includes('location-detail/'),
    [location.pathname],
  );

  const breadcrumbs = [
    {
      icon: 'home',
      label: 'AccessExpress',
      onClick: () => navigate('/access-express'),
    },
  ];

  return (
    <ContentLayout>
      {!isUserInLocationDetail && (
        <div className="flex flex-col gap-6">
          <PageTitle title="Order services" breadcrumbs={breadcrumbs} />
          <Observer>
            {() => <OpenQuoteWizardProgress step={wizardStore.step} />}
          </Observer>
        </div>
      )}

      <Outlet
        context={
          {
            productSelectionStore,
            wizardStore,
          } satisfies SelectProductsWizardContext
        }
      />
    </ContentLayout>
  );
};

export const SelectProductsContextLoader = () => {
  const { quoteId } = useParams();
  const { data, isLoading, isError } = useQuery(
    ['access-express-quote', quoteId],
    () => getQuote(quoteId ?? ''),
    {
      enabled: !!quoteId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  if (isError) {
    return <Navigate to="/access-express" />;
  }

  return (
    <WithLoader
      isLoading={isLoading}
      loader={<LoaderGranite className="min-h-[600px]" />}
    >
      {data ? <SelectProductsContext quote={data} /> : <Fragment />}
    </WithLoader>
  );
};
