import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { CheckmarkCircle } from 'react-ionicons';

const UpgradeRequestedModal = ({
  close,
  isOpen,
}: {
  close: () => void;
  isOpen: boolean;
}) => {
  return (
    <Modal isVisible={isOpen} close={close} className="w-[624px]">
      <div className="flex flex-col items-center gap-8 rounded-lg bg-background-base-surface-2 p-8">
        <CheckmarkCircle width="96px" height="96px" color="#82FF91" />
        <p className="text-center text-xl font-bold text-content-base-default">
          Role upgrade requested! Your role will be upgraded if approved by a
          Company Admin.
        </p>
        <GraniteButton className="mt-4" size="large" onClick={close}>
          Close
        </GraniteButton>
      </div>
    </Modal>
  );
};

export default UpgradeRequestedModal;
