import Avatar from 'components/Avatar/Avatar';

interface AvatarProps {
  name?: string;
  type: 'granite' | 'user';
}
const NotificationsAvatar = ({ name, type }: AvatarProps) => {
  return (
    <div className="h-12 w-12">
      {type === 'granite' ? (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="47"
            height="47"
            rx="23.5"
            fill="#191925"
          />
          <rect
            x="0.5"
            y="0.5"
            width="47"
            height="47"
            rx="23.5"
            stroke="#94A3B8"
          />
          <g clipPath="url(#clip0_16269_264267)">
            <path
              d="M32.1901 35.6099H35.9251V22.7849H23.8651V27.1049H31.3951C26.6401 37.4999 16.9651 33.6749 16.9651 33.6749C23.9701 38.4599 29.8501 34.5899 31.6351 33.1349V35.5949H32.1901V35.6099Z"
              fill="#4D9CD3"
            />
            <path
              d="M26.0852 16.965C26.0852 16.965 29.1602 17.685 30.6302 20.655H35.4602C35.4602 20.655 34.6952 17.385 31.7252 14.88C31.7102 14.865 31.6802 14.85 31.6502 14.85C31.2752 14.88 28.6352 15.21 26.0852 16.965Z"
              fill="#4D9CD3"
            />
            <path
              d="M17.8501 20.2799C17.8501 20.2799 19.9201 16.2149 24.9601 16.7399C24.9601 16.7399 27.0301 14.9099 30.7951 14.1449C30.7951 14.1449 24.3151 9.26991 17.0251 14.2049C16.9951 14.2349 16.9801 14.2799 16.9801 14.3249C16.9501 14.7299 16.9201 16.9649 17.8501 20.2799Z"
              fill="#4D9CD3"
            />
            <path
              d="M19.5151 29.6699C19.5151 29.6699 15.4351 26.6999 17.3401 21.3299C17.3401 21.3299 16.2451 18.5849 16.1101 14.9399C16.1101 14.9399 10.0501 19.6049 12.6451 27.8699C12.6451 27.8999 12.6601 27.9149 12.6901 27.9299C12.9601 28.1699 15.2551 30.0149 19.5151 29.6699Z"
              fill="#4D9CD3"
            />
            <path
              d="M20.5352 30.36C20.5352 30.36 23.8652 32.595 27.9602 30.24C27.9602 30.24 25.9052 33.75 20.2952 33.75C14.3252 33.75 13.2302 29.25 13.2302 29.25C13.2302 29.25 16.1852 31.14 20.5352 30.36Z"
              fill="#4D9CD3"
            />
          </g>
          <defs>
            <clipPath id="clip0_16269_264267">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(12 12)"
              />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <Avatar width="!w-12 !h-12" name={name!} />
      )}
    </div>
  );
};

export default NotificationsAvatar;
