import { z } from 'zod';
import {
  QEQuoteAPIResponse,
  QuoteAPIProduct,
  QuoteAPIProductSchema,
} from '../../../api/accessexpress/schema';

export const QEProductSchema = z.object({
  access_type: z.string(), // 'N/A' 'CABLE' 'DRY LOOP'
  download_speed: z.coerce.number(), // 0, '1.25', '500'
  frequency: z.enum(['MRC', 'NRC']), // 'FIBER' 'COAX' 'Carrier Provider'
  product_group: z.string(), // "DEVICE", "MONTHLY SERVICE", "INSTALLATION"
  product_type: z.string(), //411513
  quote_request_location_id: z.string(),
  quote_request_product_id: z.string(), // 19.99,
  rate: z.number(), // false,
  read_flag: z.boolean(), //"1 YR",
  term: z.string(), // 0, '100', '500'
  upload_speed: z.coerce.number(), // "COMCAST"
  vendor: z.string(),
});

export type QEProduct = z.infer<typeof QEProductSchema>;

/**
 * A QEBundle is a set of products variants offered by a vendor fulfilling the given
 * product requirements sent to QE.
 * Some products in `bundle`  are unique, such as devices, installation costs, ip blocks
 * Products with product_type 'MONTHLY_SERVICE' are different variants for the same
 * bundle.
 */
export const QEBundleSchema = z.object({
  bundle: z.array(QEProductSchema),
  circuit_type: z.string(),
  download_speed: z.number(),
  total_rate: z.number(),
  upload_speed: z.number(),
  vendor: z.string(),
  requirement: QuoteAPIProductSchema,
});

export type QEBundle = z.infer<typeof QEBundleSchema>;

export const QELocationSchema = z.object({
  id: z.string(),
  address: z.string(),
  address_2: z.string(),
  city: z.string(),
  state: z.string(),
  zipcode: z.string(),
  requirements: z.record(QuoteAPIProductSchema),
  products: z.array(QEBundleSchema),
});

export type QELocation = z.infer<typeof QELocationSchema>;

export const QEQuoteSchema = z.object({
  quote_expiration: z.coerce.date(),
  locations: z.array(QELocationSchema),
});

export type QEQuote = z.infer<typeof QEQuoteSchema>;

export const quoteAPIResponseToQEQuote = (
  response: QEQuoteAPIResponse,
): QEQuote => {
  return QEQuoteSchema.parse({
    quote_expiration: response.quote_request.qe_quote_expires_at,
    locations: Object.entries(response.quote_results).map(
      ([locationId, locations]) => {
        const { metadata, productMatches } = locations;

        const requestedLocation = response.quote_request.locations.find(
          (loc) => loc.id === locationId,
        );

        const requirements: Record<string, QuoteAPIProduct | undefined> =
          Object.fromEntries(
            Object.keys(productMatches).map((productId) => [
              productId,
              requestedLocation?.products.find((p) => p.id === productId),
            ]),
          );

        return QELocationSchema.parse({
          id: locationId,
          address: metadata.address1,
          address_2: metadata.address2,
          city: metadata.city,
          state: metadata.state,
          zipcode: metadata.zip,
          requirements,
          products: Object.entries(productMatches).flatMap(
            ([requirementId, products]) =>
              products.map((product) => ({
                bundle: product.products,
                circuit_type: product.circuit_type,
                download_speed: product.download_speed,
                requirement: requirements[requirementId],
                total_rate: product.total_rate,
                upload_speed: product.upload_speed,
                vendor: product.vendor,
              })),
          ),
        });
      },
    ),
  });
};
