import { Modal } from '../Modal/Modal';
import { GraniteButton } from '../V2/Button/GraniteButton';
import { ModalParams } from '../../hooks/useModal';
import { Link } from 'react-router-dom';
import { Ticket } from '../../api/techexpress/schema';

export interface DuplicateTicketWarningDialogProps extends ModalParams {
  duplicates?: Ticket[];
}

export const DuplicateTicketWarningDialog = ({
  isOpen,
  close,
  duplicates,
}: DuplicateTicketWarningDialogProps) => {
  const duplicateId =
    duplicates && duplicates.length > 0 ? duplicates[0].id : 0;

  return (
    <Modal isVisible={isOpen} close={close}>
      <div className="max-w-[624px] p-8">
        <h1 className="m-0 mb-2 text-2xl font-bold text-content-base-default">
          There’s already a dispatch for this date
        </h1>
        <p className="mb-12 text-base font-bold text-content-base-subdued">
          You cannot have more than one dispatch scheduled for a location on the
          same day. Would you like to add a note to the existing ticket?
        </p>
        <div className="flex gap-4">
          <GraniteButton variant="secondary" size="large" onClick={close}>
            Cancel
          </GraniteButton>
          <Link
            className="button primary large"
            to={`/tech-express/${duplicateId}?addNote`}
          >
            View ticket & add note
          </Link>
        </div>
      </div>
    </Modal>
  );
};
