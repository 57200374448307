import { InformationCircle } from 'react-ionicons';
import React, { Fragment, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

export const MaintenanceWindowTooltip = () => {
  const [isVisible, setIsVisible] = useState(false);
  const triggerEltRef = useRef<HTMLDivElement>(null);
  const popperEltRef = useRef<HTMLDivElement>(null);

  const { styles, attributes } = usePopper(
    triggerEltRef?.current,
    popperEltRef?.current,
    {
      placement: 'top-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-120, 10],
          },
        },
      ],
    },
  );

  return (
    <Fragment>
      <div
        ref={triggerEltRef}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        <InformationCircle width="20px" height="20px" color="#94A3B8" />
      </div>
      <div ref={popperEltRef} style={styles.popper} {...attributes.popper}>
        {isVisible && (
          <div className="max-w-[280px] rounded-lg bg-background-base-surface-0 p-5 text-content-base-subdued shadow-elevation3">
            <p>
              Allow intrusive testing regardless of date or time. Check the box
              below if testing may be done at anytime between 8:00AM-5:00PM
              Mon-Fri.
            </p>
          </div>
        )}
      </div>
    </Fragment>
  );
};
