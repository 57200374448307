import clsx from 'clsx';
import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  Ref,
} from 'react';
import styled from 'styled-components';

type StyleProps = {
  color?: string;
  'data-dragged': boolean;
};

const Slider = styled.input.attrs({
  type: 'range',
  className: `h-1.5 bg-transparent rounded-lg appearance-none cursor-pointer absolute w-full pointer-events-none`,
})<StyleProps>`
  &::-webkit-slider-thumb {
    appearance: none;
    height: 24px;
    width: 24px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    cursor: pointer;
    margin-top: 0;
    pointer-events: all;
    z-index: 2;
  }

  &::-moz-range-thumb {
    appearance: none;
    height: 24px;
    width: 24px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    cursor: pointer;
    margin-top: 0;
    pointer-events: all;
    z-index: 2;
  }
`;

interface SingleRangeSliderProps {
  minValue: number;
  maxValue: number;
  value: number;
  stepSize: number;
  onChange: (value: number) => void;
  getValue?: (value: number) => React.ReactNode;
  disabled?: boolean;
}

const SingleRangeSlider = forwardRef<HTMLInputElement, SingleRangeSliderProps>(
  (
    {
      minValue,
      maxValue,
      value: propValue,
      stepSize,
      onChange,
      getValue,
      disabled,
    }: SingleRangeSliderProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const [isDragged, setIsDragged] = useState(false);
    const [value, setValue] = useState(
      clampValue(propValue, minValue, maxValue),
    );
    const [position, setPosition] = useState<number>(0);

    const handleOnMouseUp = useCallback(() => setIsDragged(false), []);
    const handleOnMouseDown = useCallback(() => setIsDragged(true), []);

    const _getValue = () =>
      getValue
        ? getValue(
            isNaN(propValue) || !propValue.toString().length ? 0 : propValue,
          )
        : isNaN(propValue) || !propValue.toString().length
          ? 0
          : propValue;

    const fillSlider = useCallback(() => {
      const positionValue = (value - minValue) / (maxValue - minValue);
      setPosition(positionValue);
    }, [value, minValue, maxValue]);

    useEffect(() => {
      setValue(clampValue(propValue, minValue, maxValue));
    }, [propValue, minValue, maxValue]);

    useEffect(() => {
      fillSlider();
    }, [value, fillSlider]);

    const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = clampValue(
        parseFloat(event.target.value),
        minValue,
        maxValue,
      );
      setValue(newValue);
      onChange(newValue);
    };

    return (
      <div className="relative flex items-center rounded-lg">
        <div
          className={clsx(
            'pointer-events-none absolute left-0 right-0 h-1.5 cursor-pointer appearance-none rounded-xl ',
            disabled ? 'bg-neutral-700' : 'bg-neutral-500',
          )}
        >
          <div
            className={clsx(
              'pointer-events-none absolute h-1.5 cursor-pointer appearance-none rounded-lg ',
              disabled ? 'bg-neutral-700' : 'bg-teal-400',
            )}
            style={{
              left: '0%',
              right: `${(1 - position) * 100}%`,
            }}
          />
        </div>
        <Slider
          ref={ref}
          data-dragged={isDragged}
          value={value}
          min={minValue}
          max={maxValue}
          step={stepSize}
          onChange={handleSliderChange}
          color={disabled ? 'rgba(71, 85, 105, 1)' : 'rgba(130, 240, 255, 1)'}
          onMouseUp={handleOnMouseUp}
          onTouchEnd={handleOnMouseUp}
          onMouseDown={handleOnMouseDown}
          onTouchStart={handleOnMouseDown}
          disabled={disabled}
        />
        <div
          style={{
            width: 'calc(100% - 20px)',
            position: 'relative',
            left: 10,
            right: 10,
          }}
        >
          <div
            className="pointer-events-none absolute -top-2"
            style={{
              left: `calc(${
                ((value - minValue) / (maxValue - minValue)) * 100
              }%)`,
            }}
          >
            <div
              className={clsx(
                'absolute bottom-full left-1/2 mb-1 -translate-x-1/2 text-center text-xs font-bold leading-4 ',
                disabled ? 'text-neutral-500' : 'text-white',
              )}
            >
              {_getValue()}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

SingleRangeSlider.displayName = 'SingleRangeSlider';

export default SingleRangeSlider;

const clampValue = (value: number, min: number, max: number): number => {
  if (isNaN(value) || !value.toString().length) return 0;
  if (value < min) return min;
  if (value > max) return max;
  return value;
};
