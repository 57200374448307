import { WizardStateBlock } from '../../../components/StepperWizard/WizardStateBlock';

export interface WizardProgressProps {
  step: number;
}

export const WizardProgress = ({ step }: WizardProgressProps) => {
  return (
    <div className="mb-12 grid grid-cols-2 items-end gap-1 text-xs">
      <WizardStateBlock
        label="Find location or service"
        isActive={step >= 1}
        isCompleted={step > 1}
        classNames="product-tour-noc-open-find-location"
      />
      <WizardStateBlock
        label="Add ticket details"
        isActive={step >= 2}
        isCompleted={step >= 2}
        classNames="product-tour-noc-open-add-details"
      />
    </div>
  );
};
