import clsx from 'clsx';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import GraniteTextArea from 'components/TextArea/TextArea';
import { StandardTools } from 'components/StandardTools/StandardTools';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Add } from 'react-ionicons';
import {
  EditTicketForm,
  EditTicketFormSchema,
  EditTicketRequest,
} from './form-schema';
import { editDefaultValues, editTicketToRequest } from './utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  editTicket,
  getEstimatedCost,
  postTicketAttachments,
} from 'api/techexpress/api';
import showToast from 'components/Toast/Toast';
import {
  ExtendedTicket,
  PENDING_TECH_ASSIGNMENT,
  TECH_CHECKED_IN,
} from 'api/techexpress/schema';
import PhoneNumberInput from 'components/V2/Input/PhoneNumber';
import { getTimeZoneAbbreviationFromZipCode } from 'shared/util/util';
import { FileLike, FileUpload } from 'components/V2/FileUpload/FileUpload';
import ConfirmChangesDialog from '../ConfirmChangesDialog';
import { NonStandardToolsFieldTextField } from '../../LetUsHelp/BaseComponents/NonStandardToolsField';
import { DispatchDateSelectorField } from '../../LetUsHelp/BaseComponents/DispatchDateSelectorField';
import { AccessTimeField } from '../../LetUsHelp/BaseComponents/AccessTimeField';
import { Surface } from 'screens/LetUsHelp/BaseComponents/Surface';
import Divider from 'components/Divider';
import { PriorityBadge } from 'components/PriorityBadge/PriorityBadge';
import { PriorityInfo } from 'screens/LetUsHelp/BaseComponents/PriorityDescriptions';
import Accordion from 'components/Accordion';
import AddEmailRecipient from 'screens/LetUsHelp/utils/AddEmailRecipient';
import { useDispatchPriority } from 'shared/hooks/useDispatchPriority';
import { lookup } from 'zipcode-to-timezone';
import ContactEmailTag from 'components/ContactEmailTag/ContactEmailTag';
import Checkbox from 'components/Checkbox';

const MAX_CONTACTS_LENGTH = 2;
const fallbackTimezone = 'America/New_York';

interface TicketFormProps {
  data: ExtendedTicket;
  attachments: { filename: string; id?: number | undefined }[] | undefined;
  onSuccessSubmission: () => void;
}

const TicketForm = ({
  data,
  onSuccessSubmission,
  attachments = [],
}: TicketFormProps) => {
  const [fileLikes, setFileLikes] = useState<FileLike[]>(attachments);

  const queryClient = useQueryClient();
  const promiseRef = useRef<{
    resolve: (data: boolean) => void;
  } | null>(null);

  const confirmDialog = () => {
    return new Promise((resolve) => {
      promiseRef.current = { resolve };
      setIsEditModalOpen(true);
    });
  };

  const updateTicketMutation = useMutation(
    ({
      ticketId,
      editedTicket,
    }: {
      ticketId: number;
      editedTicket: EditTicketRequest;
    }) => editTicket(ticketId, editedTicket),
    {
      onSuccess: async () => {
        await handleNewFiles();

        queryClient.refetchQueries([data?.id.toString(), 'ticket-detail']);
        queryClient.refetchQueries([data?.id.toString(), 'ticket-notes']);
        queryClient.refetchQueries([data?.id.toString(), 'ticket-attachments']);
        onSuccessSubmission();

        showToast.confirmation({
          message: 'Ticket has been successfully edited!',
        });
      },
    },
  );
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const submitTicket = (payload: EditTicketForm) => {
    updateTicketMutation.mutate({
      ticketId: data.id,
      editedTicket: editTicketToRequest(payload),
    });
  };

  const onSubmit = async (payload: EditTicketForm) => {
    const result = await confirmDialog();
    if (result) {
      submitTicket(payload);
    }
  };

  const handleConfirmation = () => {
    if (promiseRef.current) {
      promiseRef.current.resolve(true);
      promiseRef.current = null;
      setIsEditModalOpen(false);
    }
  };

  const handleClose = () => {
    if (promiseRef.current) {
      promiseRef.current.resolve(false);
      promiseRef.current = null;
      setIsEditModalOpen(false);
    }
  };

  const onError = (err: unknown) => {
    console.log(err);
  };
  const defaultValues = data && editDefaultValues(data);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm<EditTicketForm>({
    resolver: zodResolver(EditTicketFormSchema),
    defaultValues,
  });

  const priorTicket = watch('prior_ticket');

  useEffect(() => {
    if (!priorTicket) setValue('customer_preferred_tech', undefined);
  }, [priorTicket, setValue]);

  const [accessTime, dispatchDate, priority] = watch([
    'accessTime',
    'dispatchDate',
    'priority',
  ]);

  const timezone = lookup(data.zip) ?? fallbackTimezone;
  const [calculatedPriority] = useDispatchPriority({
    dispatchDate,
    accessTime,
    timezone,
  });

  useEffect(() => {
    setValue('priority', calculatedPriority as 'P1' | 'P2' | 'P3');
  }, [setValue, calculatedPriority]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'recipients',
  });

  const {
    fields: localContactFields,
    append: localContactAppend,
    remove: localContactRemove,
  } = useFieldArray({
    control,
    name: 'local_contact',
  });

  const timeZoneAbbreviation = getTimeZoneAbbreviationFromZipCode(
    data?.zip || '',
  );

  const handleFileChange = (newFiles: FileLike[]) => {
    const fileLikesToAdd = newFiles.map((fileLike) => {
      if ('id' in fileLike) {
        return { ...fileLike, name: fileLike.filename, id: fileLike.id };
      } else return fileLike;
    });
    setFileLikes(fileLikesToAdd);
  };

  const handleFileRemove = (fileToRemove: FileLike, index: number) => {
    const updatedFileLikes = fileLikes.filter((_, i) => i !== index);
    setFileLikes(updatedFileLikes);
  };

  const handleNewFiles = async () => {
    const newFiles = fileLikes.filter(
      (file): file is File => file instanceof File,
    );

    if (newFiles.length > 0) {
      const attachmentsFormData = new FormData();
      newFiles.forEach((file) => {
        attachmentsFormData.append('file', file);
      });

      try {
        await postTicketAttachments(data.id, attachmentsFormData);

        queryClient.setQueryData(
          [data?.id.toString(), 'ticket-attachments-noc'],
          (previousData = []) => {
            const existingFiles = Array.isArray(previousData)
              ? previousData
              : [];
            const newFileData = newFiles.map((item) => ({
              id: undefined,
              filename: item.name,
            }));
            return [...existingFiles, ...newFileData];
          },
        );
      } catch (error) {
        showToast.error({
          message: 'Failed to upload attachments!',
        });
      }
    }
  };

  const { data: estimatedCost } = useQuery(
    [
      defaultValues.priority,
      priority,
      defaultValues.product?.value,
      'estimate',
    ],
    () => {
      return getEstimatedCost({
        board_name: defaultValues.product?.value as string,
        sub_type_name: priority ?? 'P1',
        type_name: defaultValues.ticketType?.value as string,
      });
    },
  );

  return (
    <>
      <form
        id="ticket-form"
        className={`flex flex-col gap-16 rounded bg-background-base-surface-2`}
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <FormFragment title="Basic information">
          <div className="col-span-full grid gap-8 rounded bg-input-background-filled p-4">
            <div>
              <div className="text-base font-bold leading-6 text-content-base-subdued">
                Ticket type
              </div>
              <div className="text-xl font-bold text-content-base-default">
                {defaultValues.ticketType?.value} -{' '}
                {defaultValues.product?.value}
              </div>
            </div>
          </div>
          <GraniteInput
            label="Customer ticket # (optional)"
            className="col-span-1"
            subtitle="Multiple entries should be separated with commas"
            {...register('customer_ticket_number')}
          />
          <GraniteInput
            label="Prior ticket # (optional)"
            subtitle="Tickets that share info pertaining to this dispatch"
            className="col-span-1"
            error={errors?.prior_ticket?.message}
            {...register('prior_ticket')}
          />
          {priorTicket && (
            <Controller
              name="customer_preferred_tech"
              control={control}
              render={({ field: { onChange, value, ...field } }) => (
                <Checkbox
                  className="col-start-2 grid-cols-1"
                  label="Request the same technician from this ticket"
                  checked={value}
                  onChange={onChange}
                  {...field}
                />
              )}
            />
          )}
        </FormFragment>
        <hr className="h-[1px] border-stroke-base-subdued" />
        <FormFragment title="Dispatch date & time" gridClassName="grid-cols-2">
          <GraniteLabel className="col-span-1" label="Date">
            <DispatchDateSelectorField
              name="dispatchDate"
              control={control}
              disabled={data.status_name === TECH_CHECKED_IN}
            />
          </GraniteLabel>
          <AccessTimeField
            control={control}
            timeZoneAbbreviation={timeZoneAbbreviation}
            classNameRadioLabel="col-start-1"
            classNameTimePickerWrapper="col-start-1"
            disabled={data.status_name === TECH_CHECKED_IN}
          />
          <GraniteLabel
            label="Purchase order # (optional)"
            subtitle="Multiple entries should be separated with commas"
          >
            <GraniteInput
              className="mb-6"
              error={errors?.po_number?.message}
              {...register(`po_number`)}
            />
          </GraniteLabel>
          <Surface className="col-start-2 row-start-1 row-end-5 flex w-full flex-col items-start gap-4">
            <PriorityBadge
              priority={(priority as 'P1' | 'P2' | 'P3') ?? 'P1'}
            />
            <div className="text-sm text-content-base-subdued">
              Dispatch priority level is automatically determined based on
              dispatch date & access time requested above. Dispatch priority
              will directly affect the estimated dispatch fee and hourly rates.
            </div>
            <Divider className="w-full" />
            <PriorityInfo estimatedCost={estimatedCost} />
          </Surface>
        </FormFragment>

        <hr className="h-[1px] border-stroke-base-subdued" />
        <FormFragment title="Location details" gridClassName="grid-cols-4">
          <div className="col-span-full grid gap-8 rounded bg-input-background-filled p-4">
            <div>
              <div className="text-base font-bold leading-6 text-content-base-subdued">
                Address
              </div>
              <div className="text-xl font-bold text-content-base-default">
                {defaultValues.site?.address_line_1}, {defaultValues.site?.city}
                , {defaultValues.site?.state}, {defaultValues.site?.zip}
              </div>
            </div>
          </div>
          <GraniteInput
            label="Location name"
            subtitle="Name of the business that the technician should look for upon arrival"
            className="col-span-2"
            {...register('location_name')}
            error={errors.location_name?.message}
          />
          <GraniteInput
            label="Location number (optional)"
            className="col-span-2 mt-auto"
            {...register('location_number')}
            error={errors.location_number?.message}
          />
        </FormFragment>
        <hr className="h-[1px] border-stroke-base-subdued" />
        <FormFragment
          title="Scope of work & special instructions"
          gridClassName="grid-cols-4"
        >
          {data.status_name === PENDING_TECH_ASSIGNMENT && (
            <GraniteTextArea
              label="Scope of work"
              subtitle="These are step-by-step instructions of what needs to be done on site. This may include pertinent equipment info, circuit info, location info, or any deliverables expected after completion."
              className="col-span-4"
              inputClassName="resize-y"
              error={errors.scope_of_work?.message}
              {...register('scope_of_work')}
            />
          )}
          <GraniteTextArea
            label="Special instructions (optional)"
            subtitle="Door/access codes, call LCON 30 minutes prior to arrival, etc."
            className="col-span-4"
            error={errors.special_instructions?.message}
            {...register('special_instructions')}
          />
          <div className="col-span-full">
            <Accordion
              items={[
                {
                  title: 'Non-standard cabling tools',
                  content: (
                    <div className="flex flex-col gap-6">
                      <StandardTools />
                      <NonStandardToolsFieldTextField
                        control={control}
                        classNameTextArea="col-span-4"
                        classNameCheckboxLabel="col-span-4"
                      />
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </FormFragment>
        <hr className="h-[1px] border-stroke-base-subdued" />
        <FormFragment
          title="Local contact details"
          subtitle="A local contact should be someone on site to that can provide access to the dispatch location."
        >
          {localContactFields.map((field, index) => (
            <Fragment key={field.id}>
              <h3 className="col-span-full text-xl font-bold text-content-base-subdued">
                Local contact {index + 1}
              </h3>
              <div className="col-span-full grid grid-cols-2 items-start gap-x-4 gap-y-6">
                <GraniteInput
                  error={errors.local_contact?.[index]?.name?.message}
                  {...register(`local_contact.${index}.name`)}
                  label="Name"
                />
                <GraniteInput
                  error={errors.local_contact?.[index]?.email?.message}
                  {...register(`local_contact.${index}.email`)}
                  label="Email (optional)"
                />
                <PhoneNumberInput<EditTicketForm>
                  error={errors.local_contact?.[index]?.phoneNumber?.message}
                  name={`local_contact.${index}.phoneNumber`}
                  control={control}
                  label="Phone number"
                />
                <GraniteInput
                  error={errors.local_contact?.[index]?.extension?.message}
                  {...register(`local_contact.${index}.extension`)}
                  label="Extension (optional)"
                />
              </div>
              {index > 0 && index < MAX_CONTACTS_LENGTH ? (
                <div>
                  <GraniteButton
                    variant="secondary"
                    onClick={() => localContactRemove(index)}
                  >
                    Delete contact {index + 1}
                  </GraniteButton>
                </div>
              ) : null}
            </Fragment>
          ))}
          {localContactFields.length < MAX_CONTACTS_LENGTH ? (
            <div>
              <GraniteButton
                variant="secondary"
                className="col-span-full mt-2"
                onClick={() =>
                  localContactAppend({ name: '', phoneNumber: '' })
                }
              >
                Add another contact
                <Add
                  color="inherit"
                  width="16px"
                  height="16px"
                  cssClasses="ml-2"
                />
              </GraniteButton>
            </div>
          ) : null}
        </FormFragment>
        <hr className="h-[1px] border-stroke-base-subdued" />
        <FormFragment title="Attachments">
          <FileUpload
            label="Upload any additional files (optional)"
            subtitle="Documents pertaining to dispatch such as floor plan, install guide, survey document, photos of broken items, etc. Up to 25 MB of documents (.pdf, .doc, .csv, .xlsx) and images (.png, .jpg, .jpeg) are allowed"
            className="col-span-2"
            accept=".pdf,.docx,.doc,.xlsx,.csv,.png,.jpeg,.jpg"
            multiple
            value={fileLikes.map((fileLike) => ({
              ...fileLike,
              name: 'filename' in fileLike ? fileLike.filename : fileLike.name,
            }))}
            onChange={handleFileChange}
            onRemove={handleFileRemove}
          />
        </FormFragment>
        <hr className="h-[1px] border-stroke-base-subdued" />
        <FormFragment title="Notifications" gridClassName="!gap-y-4">
          <AddEmailRecipient appendFunc={append} />
          <div className="col-span-full flex">
            <div className="flex flex-wrap gap-2">
              {data.automatic_email_contact && data.contact_email_address && (
                <ContactEmailTag email={data.contact_email_address} index={0} />
              )}
              {(fields?.length ?? 0) > 0 &&
                fields?.map((field, i) => (
                  <ContactEmailTag
                    email={field.email}
                    index={i}
                    remove={remove}
                    key={i}
                  />
                ))}
            </div>
          </div>
        </FormFragment>
      </form>
      <ConfirmChangesDialog
        handleConfirm={handleConfirmation}
        isOpen={isEditModalOpen}
        close={handleClose}
      />
    </>
  );
};

export const FormFragment = ({
  title,
  children,
  className,
  gridClassName,
  subtitle,
}: {
  title?: string;
  children?: ReactNode;
  className?: string;
  gridClassName?: string;
  subtitle?: string;
}) => {
  return (
    <div className={clsx('flex flex-col', className)}>
      {title && <h2 className="text-2xl font-bold text-white">{title}</h2>}
      {subtitle && (
        <span className="text-base font-bold text-content-base-subdued">
          {subtitle}
        </span>
      )}
      <div
        className={clsx(
          'mt-4 grid grid-cols-2 items-start gap-x-4 gap-y-6',
          gridClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default TicketForm;
