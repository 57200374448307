import { z } from 'zod';
import {
  ContactSubFormSchema,
  NonStandardToolsFieldSchema,
} from '../../utils/schemas';
import { TicketDetailForms } from '../DefinedFormTypes';

export const CustomerMaterialFormSchema = z.object({
  formType: z.literal(TicketDetailForms.enum.CustomerMaterial),
  whatMaterial: z.string().trim().nonempty('Required'),
  currentLocation: z.string().trim().nonempty('Required'),
  whatIsItConnectedTo: z.string().trim().nonempty('Required'),
  techToTestWith: z.discriminatedUnion('techToTestWith', [
    z.object({ techToTestWith: z.literal('Yes') }),
    z.object({ techToTestWith: z.literal('No') }),
  ]),
  itTestingContacts: z.array(ContactSubFormSchema),
  nonStandardTools: NonStandardToolsFieldSchema,
});

export type CustomerMaterialForm = z.infer<typeof CustomerMaterialFormSchema>;
