import clsx from 'clsx';
import { WizardStateBlock } from '../../../components/StepperWizard/WizardStateBlock';

export interface WizardProgressProps {
  step: number;
}

export const OpenQuoteWizardProgress = ({ step }: WizardProgressProps) => {
  return (
    <div
      className={clsx(
        'mb-6 grid items-end gap-1 text-xs',
        step === 5 ? 'grid-cols-5' : 'grid-cols-4',
      )}
    >
      <WizardStateBlock
        label="Add locations"
        isActive={step >= 1}
        isCompleted={step > 1}
      />
      <WizardStateBlock
        label="Choose services"
        isActive={step >= 2}
        isCompleted={step > 2}
      />
      <WizardStateBlock
        label="Select service bundle"
        isActive={step >= 3}
        isCompleted={step > 3}
      />
      <WizardStateBlock
        label="Request quote"
        isActive={step >= 4}
        isCompleted={step > 4}
      />
      {step === 5 && (
        <WizardStateBlock
          label="Submit order"
          isActive={step >= 5}
          isCompleted={step > 5}
        />
      )}
    </div>
  );
};
