import { GetStartedQuestionnaireForm } from '../../GetStarted/schemas';
import { CustomerMaterialForm } from './schemas';
import { ReviewTicketForm } from '../../Review/schemas';
import { questionnaireFormToReviewTicketDefaultValues } from '../../BaseComponents/questionnaireFormToReviewTicketDefaultValues';
import {
  NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX,
  NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX,
} from '../../utils/nonStandardToolsSOWUtils';
import { formatContactTesting } from 'shared/util/util';

export const customerMaterialFormToReview = (
  questionnaire: GetStartedQuestionnaireForm,
  form: CustomerMaterialForm,
): Partial<ReviewTicketForm> => {
  return {
    ...questionnaireFormToReviewTicketDefaultValues(questionnaire),
    scopeOfWork: formToSOW(form),
    nonStandardTools: form.nonStandardTools,
  };
};

const formToSOW = (form: CustomerMaterialForm) => {
  return [
    form.nonStandardTools.isNeeded
      ? `${NON_STANDARD_TOOLS_REQUESTED_LABEL_PREFIX} ${form.nonStandardTools.nonStandardTools} ${NON_STANDARD_TOOLS_REQUESTED_LABEL_SUFFIX}\n`
      : '',
    `Service technician needed on site to replace broken equipment/materials. The technician will be replacing ${form.whatMaterial}.`,
    `\nThis equipment is currently located at ${form.currentLocation} and is connected to ${form.whatIsItConnectedTo}.`,
    form.techToTestWith.techToTestWith !== 'No'
      ? `\nOnce the equipment has been successfully replaced, the technician should test with: ${formatContactTesting(
          form.itTestingContacts,
        )}`
      : '',
  ]
    .filter((l) => l)
    .join('\n');
};
