import { getProfileUser, getUserByEmail } from 'api/users/api';
import { TokenUserPermissions, TokenUserRoles } from 'api/users/schemas/Users';
import { ButtonGranite, LoaderGranite } from 'components';
import PageTitle from 'components/PageTitle';
import { DisplayForPermission } from 'components/Permission/DisplayForPermission';
import Tabs from 'components/Table/Tabs';
import { BadgeRole } from 'components/V2/BadgeRole/BadgeRole';
import { useFeatureFlags } from 'feature-flags';
import { useAuthUser } from 'hooks/useAuthUser';
import { usePermissions } from 'hooks/usePermissions';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

interface UserProps {
  withTabs?: boolean;
}

const User: FC<UserProps> = ({ withTabs = true }) => {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { flags } = useFeatureFlags();

  const { roles } = useAuthUser();
  const { isSuperAdmin: hasTokenSuperAdminRole } = usePermissions();

  const { data: profileUser, isLoading: profileIsLoading } = useQuery(
    ['user-profile'],
    () => getProfileUser(),
    {
      refetchOnWindowFocus: false,
      enabled: !id,
    },
  );
  const { data: user, isLoading: userIsLoading } = useQuery(
    ['user', id],
    () => getUserByEmail({ email: id! }),
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    },
  );

  const data = id
    ? {
        ...user,
        roles: user?.roles ? user.roles : [],
      }
    : {
        ...profileUser,
        roles: roles.map((role) => ({ value: role, label: role })),
      };

  const isLoading = id ? userIsLoading : profileIsLoading;

  const breadcrumbs = [
    {
      icon: 'home',
      label: 'User management',
      onClick: () => navigate('/users'),
    },
    {
      label: `${data.name}`,
    },
  ];

  const isSuperAdmin = useMemo(() => {
    return data.roles.find(
      (role) => role.value === TokenUserRoles['SUPER_ADMIN'],
    );
  }, [data.roles]);

  const filteredRoles = useMemo(() => {
    return data.roles.filter(
      (role) =>
        ![
          TokenUserRoles.EARLY_ADOPTER,
          TokenUserRoles.GRANITE_BETA_TESTER,
          TokenUserRoles.QR_ADMIN,
        ].includes(role.value as TokenUserRoles),
    );
  }, [data.roles]);

  const tabs = useMemo(() => {
    return [
      {
        title: 'Profile',
        onClick: () => navigate(''),
      },
      {
        title: 'Settings',
        onClick: () => navigate('settings'),
      },
    ];
  }, [navigate]);
  console.log(filteredRoles?.[0]?.value);
  if (isLoading) return <LoaderGranite />;
  return (
    <ContentLayout className="invite-user">
      <div className="h-[calc(100%-92px)]">
        <div className="mb-12 flex w-full items-center justify-between gap-6">
          <div className="flex items-end justify-start gap-6">
            <PageTitle title={data?.name ?? ''} breadcrumbs={breadcrumbs} />
            <BadgeRole
              role={
                (id && isSuperAdmin) || (hasTokenSuperAdminRole && !id)
                  ? TokenUserRoles['SUPER_ADMIN']
                  : (filteredRoles?.[0]?.value as string)
              }
              classNames="text-base py-1.5 h-[36px] px-3"
            />
          </div>
          {!pathname.includes('/edit') ? (
            <ButtonGranite
              label="Edit user"
              size="large"
              onClick={() => {
                id ? navigate(`/users/edit/${id}`) : navigate(`/profile/edit`);
              }}
            />
          ) : null}
        </div>
        {flags.DEVELOPER_MODE && (
          <DisplayForPermission
            permission={TokenUserPermissions.API_KEYS_MANAGE}
          >
            {withTabs && (
              <Tabs
                className="mb-6 w-max"
                variant="medium"
                fullUnderline={false}
                defaultActiveTab={pathname.includes('/settings') ? 1 : 0}
                tabs={tabs}
              />
            )}
          </DisplayForPermission>
        )}
        <Outlet />
      </div>
    </ContentLayout>
  );
};

export default User;
