import { OpenNetOpsTicketLayout } from '../BaseComponents/OpenNetOpsTicketLayout';
import { OpenNetOpsTicketBaseForm } from '../BaseComponents/OpenNetOpsTicketBaseForm';
import FormSection from 'components/FormSection';
import {
  Controller,
  DeepPartial,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { GraniteSelect } from 'components/Select/Select';
import GraniteTextArea from 'components/TextArea/TextArea';
import Divider from 'components/Divider/Divider';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import AddEmailRecipient from 'screens/LetUsHelp/utils/AddEmailRecipient';
import ContactEmailTag from 'components/ContactEmailTag/ContactEmailTag';
import { FormFragment } from 'components/FormFragment/FormFragment';
import { useAuthUser } from 'hooks/useAuthUser';
import { TicketDetails, TicketDetailsFormSchema } from './schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import Checkbox from 'components/Checkbox';
import { FileUpload } from 'components/V2/FileUpload/FileUpload';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import SiteInformation from '../BaseComponents/SiteInformation';
import PhoneNumberInput from 'components/V2/Input/PhoneNumber';
import {
  WizardAction,
  useOpenNetOpsTicketContext,
} from '../WizardProvider/WizardReducer';
import { FindLocationOrServiceForm } from '../FindLocationOrService/schemas';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Dispatch, Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import showToast from 'components/Toast/Toast';
import { createTicket, fetchNOCStaticData } from 'api/nocexpress/api';
import { ticketDetailsFormToRequest } from './ticketDetailsFormToRequest';
import { LocationsTable } from '../FindLocationOrService/LocationsTable';
import { BulkLocations } from '../FindLocationOrService/BulkAddLocations/schemas';
import { Configurations } from 'api/nocexpress/schemas/ConfigurationsSchema';
import isSiteAddress from '../utils/isSiteAddress';
import { determineHighestPriorityTicket } from '../utils/prioritizationList';
import { preventSubmitOnEnter } from 'shared/util/preventSubmitOnEnter';
import { useProductTourContext } from 'context/ProductTourContext';
import { POTSTicketSubmissionDialog } from '../BaseComponents/POTSTicketSubmissionDialog';
import { useModal } from 'hooks/useModal';
import { Add } from 'react-ionicons';
import { FieldTooltip } from 'components/FieldTooltip/FieldTooltip';
import { TicketSearchDropdown } from 'components/TicketSearchDropdown/TicketSearchDropdown';

const getInformation = (data?: FindLocationOrServiceForm) => {
  const isSelectAll = data?.service[0].value === '*';

  const service = isSiteAddress(data?.selectedIdentifier)
    ? data?.service[isSelectAll ? 1 : 0].label
    : data?.selectedIdentifier?.service;

  const cID = isSiteAddress(data?.selectedIdentifier)
    ? data?.service_identifier?.[0]?.service_id ||
      data?.service[isSelectAll ? 1 : 0].service_id
    : data?.selectedIdentifier?.service_id;

  const address = isSiteAddress(data?.selectedIdentifier)
    ? `${data?.selectedIdentifier?.address_line_1}, ${data?.selectedIdentifier?.city}, ${data?.selectedIdentifier?.state}, ${data?.selectedIdentifier?.zip}`
    : [
        data?.selectedIdentifier?.site.address_line_1,
        data?.selectedIdentifier?.site.city,
        data?.selectedIdentifier?.site.state,
        data?.selectedIdentifier?.site.zip,
      ]
        .filter(Boolean)
        .join(', ');

  const accountName = isSiteAddress(data?.selectedIdentifier)
    ? data?.selectedIdentifier?.account_name
    : data?.selectedIdentifier?.site.account_name;
  const siteName = isSiteAddress(data?.selectedIdentifier)
    ? data?.selectedIdentifier.site_name
    : data?.selectedIdentifier?.site.name;
  return [
    {
      label: 'Service',
      value: service ?? '--',
    },
    {
      label: data?.service_identifier?.[0]?.service_id_label ?? 'Circuit ID',
      value: cID?.toString() ?? '--',
    },
    {
      label: 'Address',
      value: address ?? '--',
    },
    {
      label: 'Account',
      value: accountName ?? '--',
    },
    {
      label: 'Site name',
      value: siteName ?? '--',
    },
  ];
};

interface TicketDetailsProps {
  findLocationOrServiceForm?: FindLocationOrServiceForm;
  ticketDetailsDefaultValues?: DeepPartial<TicketDetails>;
  services?: Configurations[];
  bulkLocations?: BulkLocations['locations'];
  dispatch: Dispatch<WizardAction>;
}

const contactFiledsOptions = [
  { value: 'Local contact', label: 'Local contact' },
  { value: 'Technical contact', label: 'Technical contact' },
  { value: 'Other', label: 'Other' },
];

const TicketDetailsForm = ({
  findLocationOrServiceForm,
  bulkLocations,
  services,
  ticketDetailsDefaultValues,
  dispatch,
}: TicketDetailsProps) => {
  const navigate = useNavigate();
  const [ticketType, setTicketType] = useState<string | null>(null);
  const [ticketSubtype, setTicketSubtype] = useState<string | null>(null);
  const { running } = useProductTourContext();

  const {
    handleSubmit,
    control,
    watch,
    resetField,
    setValue,
    register,
    formState: { errors, isDirty },
  } = useForm<TicketDetails>({
    resolver: zodResolver(TicketDetailsFormSchema),
    defaultValues: {
      isSendNotificationsViaEmailEnabled: true,
      ...ticketDetailsDefaultValues,
    },
  });

  const additionalContactsFields = useFieldArray({
    control,
    name: 'additional_contacts',
  });

  watch((formData) => {
    if (isDirty) {
      dispatch({
        type: 'SaveDetailsPartial',
        partialTicketDetails: formData,
      });
    }
  });

  useEffect(() => {
    if (running) {
      setTicketType('Mock ticket type');
      setTicketSubtype('mock ticket subtype');
      return;
    }
    if (findLocationOrServiceForm?.type === 'multi-site') {
      setTicketType('Multi-Site Service Request');
      setTicketSubtype(findLocationOrServiceForm.site_type?.value as string);
      return;
    }

    if (
      findLocationOrServiceForm?.service.find(
        (item) => item.value === 'service_not_listed',
      ) &&
      findLocationOrServiceForm.service_category
    ) {
      const [type_name, subtype_name] =
        findLocationOrServiceForm.service_category.value.split('|||');
      setTicketType(type_name);
      setTicketSubtype(subtype_name);
      return;
    }

    if (!services || services.length === 0) {
      showToast.error({
        message: 'Cannot open a ticket under this account and service.',
      });
      navigate('/noc-express/open-ticket');
      return;
    }

    const highestPriorityService = determineHighestPriorityTicket(services);
    if (
      !highestPriorityService ||
      !highestPriorityService.ticket_type ||
      !highestPriorityService.ticket_subtype
    ) {
      showToast.error({
        message: 'Cannot open a ticket under this account and service.',
      });
      navigate('/noc-express/open-ticket');
      return;
    }
    setTicketType(highestPriorityService.ticket_type);
    setTicketSubtype(highestPriorityService.ticket_subtype!);
  }, [
    services,
    navigate,
    findLocationOrServiceForm?.type,
    findLocationOrServiceForm?.site_type?.value,
    findLocationOrServiceForm?.service,
    findLocationOrServiceForm?.service_category,
    findLocationOrServiceForm,
    running,
  ]);

  const [canTestingBeDoneAnytime, maintenanceWindow] = watch([
    'canTestingBeDoneAnytime',
    'maintenance_window',
  ]);

  const { data: staticData } = useQuery(
    ['whatNeedsToBeDone-options'],
    () => fetchNOCStaticData(ticketType!, ticketSubtype!),
    {
      enabled: !!ticketType && !!ticketSubtype && !running,
      retry: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (canTestingBeDoneAnytime) {
      resetField('maintenance_window', { defaultValue: '' });
    }
  }, [canTestingBeDoneAnytime, maintenanceWindow, resetField]);

  useEffect(() => {
    // workaround to make maintenance_window optional
    if (findLocationOrServiceForm?.type === 'multi-site') {
      setValue('canTestingBeDoneAnytime', true);
    }
  }, [findLocationOrServiceForm?.type, setValue]);

  const { email: ticketCreatorEmail } = useAuthUser();

  const potsModalProps = useModal();

  const createTicketMutation = useMutation(
    ({ newTicket }: { newTicket: FormData }) => createTicket(newTicket),
    {
      onSettled: (_, error) => {
        if (ticketType === 'POTS') {
          return;
        }
        if (!error) navigate('/noc-express?tab=tickets');
      },
      onSuccess: (data) => {
        if (ticketType === 'POTS') {
          potsModalProps.open();
          return;
        }

        showToast.confirmation({
          title: 'Ticket has been successfully created!',
          message: `Ticket #${data.id}`,
        });
        data.attachments
          ?.filter((a) => a.status === 'failure')
          .forEach((a) =>
            showToast.error({
              message: `The attachment '${a.filename}' could not be uploaded.`,
            }),
          );
      },
    },
  );

  const user = useAuthUser();

  const onSubmit = async (data: TicketDetails) => {
    createTicketMutation.mutate({
      newTicket: ticketDetailsFormToRequest(
        data,
        findLocationOrServiceForm!,
        user,
        ticketType!,
        ticketSubtype!,
        bulkLocations,
      ),
    });
  };
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'recipients',
  });

  const isSendNotificationsViaEmailEnabled = watch(
    'isSendNotificationsViaEmailEnabled',
  );

  const onError = (err: unknown) => console.log(err);

  return (
    <OpenNetOpsTicketLayout>
      <OpenNetOpsTicketBaseForm
        className="min-h-[662px] gap-16"
        id="noc-ticket-details-form"
        onSubmit={handleSubmit(onSubmit, onError)}
        onKeyDown={preventSubmitOnEnter}
      >
        <FormSection title="Service details" gridClassName="grid-cols-1">
          <Controller
            name="whatNeedsToBeDone"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <GraniteSelect
                {...field}
                error={errors.whatNeedsToBeDone?.message}
                value={value}
                onChange={onChange}
                options={staticData?.ticket_issues_dropdown_values?.map(
                  (item) => ({
                    label: item,
                    value: item,
                  }),
                )}
                label="What is the issue that needs to be fixed?"
                classNames={{
                  control: () => '!rounded-r-none',
                }}
              />
            )}
          />
          {ticketType === 'POTS' && (
            <Controller
              name="tns_impacted"
              control={control}
              rules={{
                required: 'Required',
              }}
              render={({ field: { onChange, value, ...field } }) => (
                <GraniteTextArea
                  {...field}
                  onChange={onChange}
                  value={value}
                  error={errors.tns_impacted?.message}
                  label="Telephone numbers impacted"
                  inputClassName="resize-y"
                />
              )}
            />
          )}
          <Controller
            name="initialDescription"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <GraniteTextArea
                {...field}
                onChange={onChange}
                value={value}
                error={errors.initialDescription?.message}
                label="Initial description of the issue"
                inputClassName="resize-y"
              />
            )}
          />
        </FormSection>
        <Divider />
        <FormSection title="Ticket details" gridClassName="grid-cols-2">
          <div className="col-span-2 flex flex-col bg-background-base-surface-1">
            <div className="flex flex-col gap-2 px-4 py-2">
              <div className="flex w-full items-center justify-between">
                <span className="w-1/2 text-base font-bold text-content-base-subdued">
                  Ticket type
                </span>
                <span className="w-1/2 text-left text-base font-bold text-content-base-default">
                  {ticketType}
                </span>
              </div>
            </div>
            <div className="w-full">
              <Divider />
            </div>
            <div className="flex flex-col gap-2 px-4 py-2">
              <div className="flex w-full items-center justify-between">
                <span className="w-1/2 text-base font-bold text-content-base-subdued">
                  Ticket subtype
                </span>
                <span className="w-1/2 text-left text-base font-bold text-content-base-default">
                  {ticketSubtype}
                </span>
              </div>
            </div>
          </div>
          <Controller
            name="customer_name"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <GraniteInput
                {...field}
                onChange={onChange}
                value={value}
                label="Customer ticket # (optional)"
                subtitle="Multiple entries should be separated by commas"
                className="col-span-1"
                error={errors.customer_name?.message}
              />
            )}
          />
          <Controller
            name="prior_ticket"
            control={control}
            render={({ field }) => (
              <TicketSearchDropdown
                {...field}
                className="col-span-1"
                label="Prior ticket # (optional)"
                subtitle="Tickets that share info pertaining to this issue"
                onTicketSelected={(ticketNumber) => {
                  setValue('prior_ticket', String(ticketNumber));
                  field.onChange(String(ticketNumber));
                }}
                error={errors.prior_ticket?.message}
                configurationIds={(
                  findLocationOrServiceForm?.service_identifier ?? []
                )
                  .map((configuration) => configuration.value)
                  .filter(Boolean)
                  .join(',')}
              />
            )}
          />
        </FormSection>
        <Divider />
        <FormSection title="Contact" gridClassName="grid-cols-1">
          <div className="grid grid-cols-3 gap-x-4 gap-y-4">
            <GraniteSelect
              value={
                findLocationOrServiceForm?.type === 'multi-site'
                  ? contactFiledsOptions[1]
                  : contactFiledsOptions[0]
              }
              isDisabled={true}
              label="Contact Type"
            />
            <GraniteInput
              {...register(`local_contact_name`)}
              label="Name"
              error={errors.local_contact_name?.message}
              className="col-span-1"
            />
            <PhoneNumberInput
              error={errors.local_contact_number?.message}
              name={`local_contact_number`}
              control={control}
              label="Phone number"
            />
            {additionalContactsFields.fields.map((field, index) => (
              <Fragment key={field.id}>
                <Controller
                  name={`additional_contacts.${index}.type`}
                  control={control}
                  render={({
                    field: { onChange, value, ...field },
                    fieldState: { error },
                  }) => (
                    <GraniteSelect
                      {...field}
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                      options={contactFiledsOptions}
                      label="Contact Type"
                    />
                  )}
                />

                <GraniteInput
                  {...register(`additional_contacts.${index}.name`)}
                  label="Name"
                  error={
                    errors.additional_contacts?.length
                      ? errors.additional_contacts[index]?.name?.message
                      : ''
                  }
                  className="col-span-1"
                />
                <PhoneNumberInput
                  error={
                    errors.additional_contacts?.length
                      ? errors.additional_contacts[index]?.number?.message
                      : ''
                  }
                  name={`additional_contacts.${index}.number`}
                  control={control}
                  label="Phone number"
                />
                <div className="col-span-full">
                  <GraniteButton
                    variant="destructive"
                    className="max-w-fit"
                    onClick={() => additionalContactsFields.remove(index)}
                  >
                    Delete
                  </GraniteButton>
                </div>
              </Fragment>
            ))}
          </div>
          {additionalContactsFields.fields.length < 2 ? (
            <GraniteButton
              variant="secondary"
              className="!box-border max-w-fit whitespace-nowrap"
              onClick={() => {
                additionalContactsFields.append({
                  name: '',
                  number: '',
                });
              }}
            >
              Add another contact
              <Add width="20px" height="20px" color="inherit" />
            </GraniteButton>
          ) : null}
        </FormSection>
        <Divider />
        {findLocationOrServiceForm?.type === 'multi-site' && (
          <LocationsTable locations={bulkLocations!} />
        )}
        <FormSection title="Access hours" gridClassName="grid-cols-1">
          <Controller
            name="access_hours"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <GraniteTextArea
                {...field}
                onChange={onChange}
                value={value}
                label="Access hours"
                subtitle="Select the time frame in which an on-site contact will be available for access and/or troubleshooting if technician dispatch is required."
                inputClassName="resize-y"
                placeholder="E.g., Mon-Fri 8:00AM-5:00PM EST; Sat-Sun 10:00AM-4:00PM EST"
                error={errors.access_hours?.message}
              />
            )}
          />
          <div className="col-span-full">
            <h3 className="mb-2 flex gap-2 text-base font-bold text-content-base-subdued">
              Technician dispatch approval (optional){' '}
              <div className="">
                <FieldTooltip placement="right-end">
                  Technician dispatches are not always needed. This checkbox
                  allows you to pre-approve that if a technician dispatch is
                  required, Granite is authorized to schedule and dispatch the
                  technician to your location.
                </FieldTooltip>
              </div>
            </h3>
            <Controller
              name="technician_dispatch_approved"
              control={control}
              render={({ field: { value, ...field } }) => (
                <Checkbox
                  {...field}
                  label={
                    <p className="select-none">
                      I authorize a technician to be dispatched to this location
                      if necessary. See{' '}
                      <Link
                        to={`https://www.granitenet.com/Content/pdfs/Granite%20Website%20-%20General%20Terms%20and%20Conditions%20of%20Service%20(May%2023,%202022)%20(00053066xD85FF).pdf`}
                        target="_blank"
                        rel="noreferrer"
                        className="cursor-pointer text-content-accent-default underline visited:text-content-accent-default"
                      >
                        {`Granite's Terms and Conditions of Service`}
                      </Link>{' '}
                      and{' '}
                      <Link
                        to={`https://www.granitenet.com/Content/pdfs/Granite%20Website%20-%20Network%20Integration%20Services%20Terms%20of%20Service.pdf`}
                        target="_blank"
                        rel="noreferrer"
                        className="cursor-pointer text-content-accent-default underline visited:text-content-accent-default"
                      >
                        {' '}
                        Network Integration Services
                      </Link>
                    </p>
                  }
                  checked={value}
                />
              )}
            />
          </div>
        </FormSection>
        <Divider />
        {findLocationOrServiceForm?.type !== 'multi-site' && (
          <>
            <FormSection title="Maintenance window" gridClassName="grid-cols-1">
              <Controller
                name="maintenance_window"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <GraniteTextArea
                    {...field}
                    onChange={onChange}
                    value={maintenanceWindow}
                    label="Maintenance window"
                    subtitle="For intrusive testing purposes, list your location’s maintenance window for each day of the week."
                    inputClassName="resize-y"
                    placeholder="E.g., Mon-Fri 8:00AM-5:00PM EST; Sat-Sun 10:00AM-4:00PM EST"
                    error={errors.maintenance_window?.message}
                    disabled={canTestingBeDoneAnytime}
                  />
                )}
              />
              <div className="col-span-full">
                <h3 className="mb-2 text-base font-bold text-content-base-subdued">
                  Allow intrusive testing regardless of date or time
                </h3>
                <h4 className="mb-2 text-sm text-content-base-subdued">
                  Check the box below if intrusive testing may be done at
                  anytime Mon-Fri.
                </h4>
                <Controller
                  name="canTestingBeDoneAnytime"
                  control={control}
                  render={({ field: { onChange, value, ...field } }) => (
                    <Checkbox
                      {...field}
                      label="Yes, intrusive testing can be done anytime"
                      className="col-span-full"
                      checked={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </FormSection>
            <Divider />
          </>
        )}
        <FormFragment title="Notifications" gridClassName="!gap-y-4">
          <div className="col-span-full flex flex-col gap-y-4">
            <div className="col-span-full">
              <h3 className="mb-2 text-base font-bold text-content-base-subdued">
                Would you like to receive notifications for this ticket?
              </h3>
              <Controller
                name="isSendNotificationsViaEmailEnabled"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <Checkbox
                    {...field}
                    label="Send me updates via email"
                    className="col-span-full"
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            {isSendNotificationsViaEmailEnabled && (
              <div className="flex flex-wrap">
                <div className="flex h-8 items-center rounded-[32px] border border-stroke-base-subdued bg-background-base-surface-3 px-2">
                  <span className="fill-content-base-subdued pr-1 text-content-base-default">
                    {ticketCreatorEmail}
                  </span>
                </div>
              </div>
            )}
          </div>
          <AddEmailRecipient appendFunc={append} />
          <div className="col-span-full flex">
            <div className="flex flex-wrap gap-2">
              {(fields?.length ?? 0) > 0 &&
                fields?.map((field, i) => (
                  <ContactEmailTag
                    email={field.email}
                    index={i}
                    remove={remove}
                    key={i}
                  />
                ))}
            </div>
          </div>
        </FormFragment>
        {ticketType !== 'POTS' && (
          <>
            <Divider />
            <FormSection title="Attachments">
              <Controller
                name="attachments"
                control={control}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <FileUpload
                    ref={ref}
                    label="Upload any additional files (optional)"
                    subtitle="Documents pertaining to dispatch such as floor plan, install guide, survey document, photos of broken items, etc. Up to 25 MB of documents (.pdf, .doc, .csv, .xlsx) and images (.png, .jpg, .jpeg) are allowed"
                    className="col-span-2"
                    accept=".pdf,.docx,.doc,.xlsx,.csv,.png,.jpeg,.jpg"
                    multiple
                    value={value}
                    error={error?.message}
                    onChange={(files) => {
                      onChange(files);
                    }}
                  />
                )}
              />
            </FormSection>
          </>
        )}
      </OpenNetOpsTicketBaseForm>
      <div className="sticky top-8 flex flex-col gap-6 rounded bg-background-base-surface-2 p-6 shadow">
        {findLocationOrServiceForm?.type !== 'multi-site' &&
          !findLocationOrServiceForm?.service.find(
            (item) => item.value === 'service_not_listed',
          ) && (
            <div>
              <h3 className="text-base font-bold text-content-base-subdued">
                Service summary
              </h3>
              <SiteInformation
                showTitle={false}
                information={getInformation(findLocationOrServiceForm!)}
                chronic={false}
              />
            </div>
          )}
        <div className="flex gap-4">
          <GraniteButton
            className="w-full"
            variant="secondary"
            size="large"
            onClick={() => navigate(-1)}
          >
            Back
          </GraniteButton>
          <GraniteButton
            className="w-full"
            variant="primary"
            size="large"
            type="submit"
            form="noc-ticket-details-form"
            disabled={createTicketMutation.isLoading}
          >
            Submit
          </GraniteButton>
        </div>
      </div>
      <POTSTicketSubmissionDialog
        {...potsModalProps}
        close={() => navigate('/noc-express?tab=tickets')}
      />
    </OpenNetOpsTicketLayout>
  );
};

export const TicketDetailsFormWrapper = () => {
  const { state, dispatch } = useOpenNetOpsTicketContext();

  if (
    state.state === 'FindLocationOrService' &&
    !state.findLocationOrServiceForm
  ) {
    // User is trying to access the route directly
    return <Navigate to="/noc-express/open-ticket" />;
  }

  if (state.state !== 'TicketDetails') {
    if (state.state === 'FindLocationOrService') {
      // User coming from FindLocationOrServiceForm using the browser forward button
      dispatch({
        type: 'GoToTicketDetails',
        partialTicketDetails: state.ticketDetails,
      });
    }
  }

  return (
    <TicketDetailsForm
      findLocationOrServiceForm={state.findLocationOrServiceForm}
      ticketDetailsDefaultValues={state.ticketDetails}
      services={state.services}
      bulkLocations={state.bulkLocations}
      dispatch={dispatch}
    />
  );
};
