import clsx from 'clsx';
import { getResources } from 'api/resources/api';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import { ChevronForward } from 'react-ionicons';
import { Resource } from './Resource';
import { Resource as ResourceType } from 'api/resources/schema';
import Skeleton from 'components/Skeleton/Skeleton';
import { Fragment } from 'react';

type SuggestedResourcesProps = {
  openResourcePreview: (resource: ResourceType) => void;
};

const CardSkeleton = () => (
  <div className="flex flex-col items-start justify-start gap-6 rounded bg-background-base-surface-2 px-6 py-8">
    <Skeleton className="h-9 w-9" />
    <div className="mt-[10px] flex w-full flex-col items-start justify-start gap-0.5">
      <Skeleton className="h-4 w-1/2" />
      <Skeleton className="h-4 w-3/4" />
    </div>
  </div>
);

const Loader = () => (
  <Fragment>
    <CardSkeleton />
    <CardSkeleton />
    <CardSkeleton />
    <CardSkeleton />
  </Fragment>
);

export const SuggestedResources = ({
  openResourcePreview,
}: SuggestedResourcesProps) => {
  const { data: resources, ...paginationProps } = usePaginatedTable(
    (args) => getResources({ ...args, is_suggested: true }),
    {
      is_suggested: true,
    },
    [
      'resources',
      {
        is_suggested: true,
      },
    ],
    {
      staleTime: 300_000, // 5 minutes
    },
    { pageSize: 4, pageIndex: 0 },
  );

  return (
    <div className="w-full">
      <h1 className="text-[28px] font-bold leading-9 text-content-base-default">
        Suggested
      </h1>
      <div className="mt-6 flex w-full items-center">
        <button
          disabled={paginationProps.isFetchingData}
          onClick={() =>
            paginationProps.onPaginationChange((pageData) => ({
              ...pageData,
              pageIndex: pageData.pageIndex - 1,
            }))
          }
          className={clsx(
            'ml-12 mr-6 cursor-pointer text-content-base-default',
            paginationProps.paginationState.pageIndex == 0 && 'hidden',
          )}
        >
          <ChevronForward
            cssClasses="rotate-180"
            width="24px"
            height="24px"
            color="inherit"
          />
        </button>
        <div className="grid flex-1 grid-cols-4 gap-6">
          {paginationProps.isFetchingData ? (
            <Loader />
          ) : (
            resources.map((resource) => (
              <Resource
                key={resource.id}
                resource={resource}
                onClick={openResourcePreview}
              />
            ))
          )}
        </div>
        <button
          disabled={paginationProps.isFetchingData}
          onClick={() =>
            paginationProps.onPaginationChange((pageData) => ({
              ...pageData,
              pageIndex: pageData.pageIndex + 1,
            }))
          }
          className={clsx(
            'ml-6 mr-12 cursor-pointer text-content-base-default',
            (resources.length === 0 ||
              paginationProps.paginationState.pageIndex + 1 ===
                paginationProps.pageCount) &&
              'hidden',
          )}
        >
          <ChevronForward width="24px" height="24px" color="inherit" />
        </button>
      </div>
    </div>
  );
};
