import { Notification } from 'api/notifications/schema';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { EventDispatcher } from 'shared/util/event-dispatcher';

type CategorizedNotifications = {
  today: Notification[];
  yesterday: Notification[];
  older: Notification[];
};

export const notificationDispatcher = new EventDispatcher<
  Record<Notification['event']['event_type']['key'], Notification>
>();

export function categorizeNotifications(
  notifications: Notification[],
): CategorizedNotifications {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const categorized: CategorizedNotifications = {
    today: [],
    yesterday: [],
    older: [],
  };

  notifications.forEach((notification) => {
    const notificationDate = new Date(notification.created_at + 'Z'); // Append 'Z' at the end to indicate UTC timezone
    notificationDate.setHours(0, 0, 0, 0);

    if (notificationDate.getTime() === today.getTime()) {
      categorized.today.push(notification);
    } else if (notificationDate.getTime() === yesterday.getTime()) {
      categorized.yesterday.push(notification);
    } else {
      categorized.older.push(notification);
    }
  });

  return categorized;
}

export function formatDate(date: string): string {
  return formatDistanceToNow(parseISO(date), { addSuffix: true });
}

export type Pillar =
  | 'all'
  | 'access-express'
  | 'tech-express'
  | 'noc-express'
  | 'user-management';
export type NotificationType = 'all' | 'comment' | 'blast' | 'actionable';

export const NOTIFICATIONS_POLL_INTERVAL = 300_000; // 5 minutes
