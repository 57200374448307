import Pagination from 'components/Table/Pagination';
import { PaginationWrapper } from './BasicPagination.styles';

export const BasicPagination = (
  props: React.ComponentProps<typeof Pagination>,
) => {
  return (
    <PaginationWrapper>
      <Pagination {...props} hidePageSizeSelection variant="short" />
    </PaginationWrapper>
  );
};
