import { ComponentProps } from 'react';
import { clsx } from 'clsx';

export interface FormProps extends ComponentProps<'form'> {}
export const Form = ({ className, children, ...props }: FormProps) => {
  return (
    <form
      className={clsx(
        className,
        'flex flex-col rounded bg-background-base-surface-2 p-6 shadow',
      )}
      {...props}
    >
      {children}
    </form>
  );
};
