import { startOfDay } from 'date-fns';
import { VALID_US_DATE_FORMAT } from 'shared/constants/error-labels';
import { NUMBERS_ONLY_REGEXP } from 'shared/constants/validation-regex-constants';
import { z } from 'zod';

export const UploadQRCodeSchema = z
  .object({
    qe_quote_request_id: z
      .string()
      .nonempty('Required')
      .regex(NUMBERS_ONLY_REGEXP, {
        message: 'Only numbers allowed',
      }),
    file: z.array(z.any()).optional(),
    expiration_date: z
      .date({ required_error: VALID_US_DATE_FORMAT })
      .min(
        startOfDay(new Date()),
        'Expiration date should be today or in the future',
      ),
    formal_quote_filename: z.string().nullable().optional(),
  })
  .refine(
    (data) => data.formal_quote_filename || (data.file && data.file.length > 0),
    {
      path: ['file'],
      message: 'Required',
    },
  );

export type UploadQRCodeType = z.infer<typeof UploadQRCodeSchema>;
