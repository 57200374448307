/**
 * Custom login callback component based on
 * https://github.com/okta/okta-react/blob/okta-react-6.9/src/LoginCallback.tsx
 */

import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

declare type OnAuthResumeFunction = () => void;

interface LoginCallbackProps {
  errorComponent?: React.ComponentType<{
    error: Error;
  }>;
  onAuthResume?: OnAuthResumeFunction;
  loadingElement?: React.ReactElement;
}

const OktaError: React.FC<{ error: Error }> = ({ error }) => {
  if (error.name && error.message) {
    return (
      <p>
        {error.name}: {error.message}
      </p>
    );
  }
  return <p>Error: {error.toString()}</p>;
};

let handledRedirect = false;

export const LoginCallback: React.FC<LoginCallbackProps> = ({
  errorComponent,
  loadingElement = null,
  onAuthResume,
}) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [callbackError, setCallbackError] = React.useState(null);

  const ErrorReporter = errorComponent || OktaError;
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore OKTA-464505: backward compatibility support for auth-js@5
    const isInteractionRequired =
      oktaAuth.idx.isInteractionRequired ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore OKTA-464505: backward compatibility support for auth-js@5
      oktaAuth.isInteractionRequired.bind(oktaAuth);
    if (onAuthResume && isInteractionRequired()) {
      onAuthResume();
      return;
    }

    // OKTA-635977: Prevents multiple calls of handleLoginRedirect() in React18 StrictMode
    // Top-level variable solution follows: https://react.dev/learn/you-might-not-need-an-effect#initializing-the-application
    if (!handledRedirect) {
      oktaAuth.handleLoginRedirect().catch((e) => {
        if (
          e.message ===
          'Could not load PKCE codeVerifier from storage. This may indicate the auth flow has already completed or multiple auth flows are executing concurrently.'
        )
          return (window.location.href = '/');
        setCallbackError(e);
      });
      handledRedirect = true;
    }
  }, [oktaAuth, onAuthResume]);

  const authError = authState?.error;
  const displayError = callbackError || authError;

  if (displayError) {
    return <ErrorReporter error={displayError} />;
  }

  return loadingElement;
};
