import { useState } from 'react';
import { useBlocker } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Notifications } from 'react-ionicons';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';

import { RequestQoute, RequestQuoteSchema } from './schema';
import { patchQuoteRequest } from './utils';
import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import Loader from 'components/Loader';
import { GraniteInput } from 'components/V2/Input/GraniteInput';

interface SimpleFlowNavigationHandlerProps {
  quoteId: string;
  block: boolean;
  notify: boolean;
}

export const SimpleFlowNavigationHandler = (
  props: SimpleFlowNavigationHandlerProps,
) => {
  const { quoteId, block, notify } = props;
  const [step, setStep] = useState(1);

  const blocker = useBlocker(() => block);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<RequestQoute>({
    resolver: zodResolver(RequestQuoteSchema),
    defaultValues: {
      name: '',
    },
  });

  const requestQuoteModalMutation = useMutation(
    ({ name }: { name: string }) => {
      return patchQuoteRequest({ id: quoteId, name });
    },
    {
      onSuccess: () => {
        if (notify) {
          setStep(2);
        } else {
          blocker.proceed?.();
        }
      },
    },
  );

  const onSubmit = (request: RequestQoute) => {
    if (requestQuoteModalMutation.isLoading) return;
    requestQuoteModalMutation.mutate(request);
  };

  const onError = (err: unknown) => console.log(err);

  const handleCloseModal = () => {
    if (step === 2) {
      blocker.proceed?.();
      return;
    }

    reset({});
    blocker.reset?.();
  };

  return (
    <>
      <Modal
        isVisible={blocker.state === 'blocked'}
        close={handleCloseModal}
        className="w-[624px]"
      >
        {step === 1 && (
          <div className="flex flex-col gap-2 rounded-lg bg-background-base-surface-2">
            <div
              className={clsx(
                'flex flex-col items-start justify-start rounded-t-lg  px-8 py-6 pb-0 font-bold',
                'bg-background-base-surface-3 !py-6',
              )}
            >
              <h1 className="text-[28px] text-content-base-default">
                Name your quote
              </h1>{' '}
            </div>
            <div className={clsx('p-8')}>
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="grid grid-cols-1 gap-6"
              >
                <GraniteInput
                  label="Give this quote request a name so that you can reference it later"
                  className="col-span-1"
                  error={errors.name?.message}
                  {...register(`name`)}
                />
                <div className={clsx('flex gap-5')}>
                  <GraniteButton
                    size="large"
                    variant="secondary"
                    onClick={() => blocker.reset?.()}
                  >
                    Cancel
                  </GraniteButton>
                  <GraniteButton
                    size="large"
                    variant="primary"
                    type="submit"
                    className={clsx(
                      requestQuoteModalMutation.isLoading && 'loading-button',
                    )}
                  >
                    Save
                    {requestQuoteModalMutation.isLoading && (
                      <Loader animationClassname="!w-3 !h-3" />
                    )}
                  </GraniteButton>
                </div>
              </form>
            </div>
          </div>
        )}
        {step === 2 && (
          <>
            <div className="flex flex-col items-center gap-8 rounded-lg bg-background-base-surface-2 p-8">
              <Notifications width="96px" height="96px" color="#82FF91" />
              <p className="text-center text-xl font-bold text-content-base-default">
                The results may take a few hours to process. You’ll be notified
                when they are ready.
              </p>
              <GraniteButton
                className="mt-4"
                size="large"
                onClick={handleCloseModal}
              >
                Close
              </GraniteButton>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
