import React from 'react';

interface TooltipProps {
  content: string | React.ReactNode;
}

const TooltipContent: React.FC<{
  content: string | React.ReactNode;
}> = ({ content }) => {
  return (
    <div className="relative w-[328px] rounded-[8px] bg-background-base-surface-0 p-[20px] shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] hover:bg-[linear-gradient(213.35deg,_#0D1219_19.85%,_#222231_160.96%)]">
      {content}
    </div>
  );
};

const Tooltip: React.FC<TooltipProps> = ({ content }) => {
  return (
    <div className="relative inline-block">
      <TooltipContent content={content} />
    </div>
  );
};

export default Tooltip;
