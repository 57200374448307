import styled, { css } from 'styled-components';
import IconMapper from '../IconMapper';

export const ListDropdownButton = styled.button`
  all: unset;
  min-height: 24px;
  display: flex;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.background.baseSurface};
  color: ${({ theme }) => theme.colors.text.white};
  padding: 0 8px;

  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const ListDropdownButtonCaret = styled(IconMapper).attrs(
  ({ theme }) => ({
    color: theme.colors.text.white,
    size: 12,
  }),
)`
  line-height: 8px;
  padding-right: 8px;
  padding-left: 8px;
`;

export const ListDropdownOptionsTrack = styled.div`
  padding-right: 8px;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.colors.scroll.thumb}
    ${({ theme }) => theme.colors.scroll.background};
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.scroll.background};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.scroll.thumb};
  }
`;

export const ListDropdownOption = styled.button<{ $isSelected?: boolean }>`
  all: unset;

  color: ${({ theme }) => theme.colors.text.white};
  font-weight: 600;
  line-height: 24px;
  min-width: 64px;
  min-height: 32px;
  text-align: center;
  padding-right: 8px;
  padding-left: 8px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.button.primaryHover};
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background: ${({ theme }) => theme.colors.background.primary};
      color: ${({ theme }) => theme.colors.text.dark};
      border-radius: 50px;

      &:hover {
        color: ${({ theme }) => theme.colors.text.dark};
      }
    `}
`;

export const ListDropdownContainer = styled.div`
  max-height: 300px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background.baseSurface};
  box-shadow:
    0 1px 3px 1px rgba(0, 0, 0, 0.15),
    0 1px 2px 0 rgba(0, 0, 0, 0.3);
  display: inline-flex;
  padding-top: 8px;
  padding-bottom: 8px;
  position: relative;
  z-index: 9999;

  & ${ListDropdownOptionsTrack}:not(:last-child) {
    border-right: solid 1px ${({ theme }) => theme.colors.border.hr};
  }
`;
