import { useState } from 'react';

export interface UseSimplePaginationProps<T> {
  pageSize: number;
  items: T[];
}

export interface UseSimplePaginationReturn<T> {
  nextPage: () => void;
  previousPage: () => void;
  page: T[];
  pageNumber: number;
  maxPages: number;
}

export const useSimplePagination = <T>({
  pageSize,
  items,
}: UseSimplePaginationProps<T>): UseSimplePaginationReturn<T> => {
  const [pageNumber, setPageNumber] = useState(1);
  const pageStartIndex = pageSize * (pageNumber - 1);
  const pageEndIndex = pageSize * pageNumber;
  const maxPages = Math.ceil(items.length / pageSize);
  const itemSlice = items.slice(pageStartIndex, pageEndIndex);

  const nextPage = () => {
    if (pageNumber < maxPages) {
      setPageNumber((pageNumber) => pageNumber + 1);
    }
  };

  const previousPage = () => {
    if (pageNumber > 1) {
      setPageNumber((pageNumber) => pageNumber - 1);
    }
  };

  return {
    nextPage,
    previousPage,
    page: itemSlice,
    maxPages,
    pageNumber,
  };
};
