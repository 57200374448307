import { ComponentProps } from 'react';
import { clsx } from 'clsx';

export type SurfaceProps = ComponentProps<'div'>;
export const Surface = ({ className, ...props }: SurfaceProps) => {
  return (
    <div
      className={clsx(className, 'rounded bg-input-background-filled p-4')}
      {...props}
    />
  );
};
