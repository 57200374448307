import IconMapper from '../IconMapper';
import React, { Fragment } from 'react';
import { BreadcrumbItemWrapper, Breadcrumbs } from './Breadcrumb.styles';
import { ChevronForward } from 'react-ionicons';

export interface BreadcrumbItem {
  icon?: string;
  label: string;
  onClick?: () => void; // Add onClick handler
}

export interface BreadcrumbProps {
  breadcrumbs: BreadcrumbItem[];
}

export const Breadcrumb = ({ breadcrumbs }: BreadcrumbProps) => {
  return (
    <Breadcrumbs>
      {breadcrumbs.map((item, index) => (
        <Fragment key={index}>
          <BreadcrumbItemWrapper onClick={item.onClick}>
            <div className={`${item.icon ? 'b-icon' : 'n-icon'}`}>
              {item.icon && <IconMapper name={item.icon} />}
              <p>{item.label}</p>
            </div>
          </BreadcrumbItemWrapper>
          {index < breadcrumbs.length - 1 && (
            <span>
              <ChevronForward width="16px" height="16px" color="inherit" />
            </span>
          )}
        </Fragment>
      ))}
    </Breadcrumbs>
  );
};
