import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Modal } from 'components/Modal/Modal';
import { Controller, useForm } from 'react-hook-form';
import {
  CloseAndResolveTicketForm,
  CloseAndResolveTicketFormType,
  EscalateTicketFormType,
  EscalateTicketRequestType,
  NetOpsResponseForm,
} from './form-schema';
import { GraniteSelect } from 'components/Select/Select';
import TextAreaGranite from 'components/TextArea/TextArea';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useMutation, useQueryClient } from 'react-query';
import { reopenNocTicket } from 'api/nocexpress/api';
import { canReopenTicket } from './util';

const options = [
  'The same trouble report has occurred again.',
  'I requested an RCA for this ticket but did not receive one.',
  'An identical problem has occured at a different location.',
  'A new issue has occurred at this location.',
];

const ReopenTicket = ({
  isOpen,
  close,
  ticket,
  handleReOpenTicket,
}: {
  isOpen: boolean;
  close: () => void;
  handleReOpenTicket: (value: string, details: string) => void;
  ticket: NetOpsResponseForm;
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CloseAndResolveTicketFormType>({
    resolver: zodResolver(CloseAndResolveTicketForm),
  });
  const queryClient = useQueryClient();

  const reopenMutation = useMutation(
    ({
      ticketId,
      data,
    }: {
      ticketId: number;
      data: EscalateTicketRequestType;
    }) => reopenNocTicket(ticketId, data),
    {
      onSuccess: async () => {
        queryClient.refetchQueries([
          ticket?.id?.toString(),
          'ticket-details-noc',
        ]);
        queryClient.refetchQueries([
          ticket?.id?.toString(),
          'ticket-noc-notes',
        ]);
        close();
      },
    },
  );

  const onSubmit = (payload: EscalateTicketFormType) => {
    const { result } = canReopenTicket(ticket.last_updated);
    if (
      !result ||
      payload.reason.value ===
        'An identical problem has occured at a different location.' ||
      payload.reason.value === 'A new issue has occurred at this location.'
    ) {
      handleReOpenTicket(payload.reason.value, payload.additional_notes || '');
      return;
    }
    if (result) {
      const request = {
        reason: payload.reason.value,
        additional_notes: payload.additional_notes,
      };

      reopenMutation.mutate({ ticketId: Number(ticket?.id), data: request });
    }
  };

  const onError = (err: unknown) => console.log(err);

  return (
    <Modal isVisible={isOpen} close={close} className="w-full max-w-[624px]">
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        className="flex flex-col gap-2 rounded-lg rounded-t-lg bg-background-base-surface-2"
      >
        <div className="flex flex-col items-start justify-start rounded-t-lg bg-background-base-surface-3 px-8 py-6 font-bold leading-9">
          <h1 className="text-[28px] text-content-base-default">
            Reopen ticket
          </h1>
        </div>
        <div className="flex w-full flex-col items-start justify-start gap-6 bg-background-base-surface-2 p-8">
          <Controller
            name="reason"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <GraniteSelect
                {...field}
                className={`col-span-full`}
                onChange={onChange}
                label="Reason for reopening ticket"
                value={value}
                options={options.map((item) => {
                  return {
                    value: item,
                    label: item,
                  };
                })}
                classNames={{
                  control: () => '!rounded-r-none',
                }}
              />
            )}
          />
          <div className="flex w-full flex-col items-start justify-start gap-2">
            <TextAreaGranite
              {...register('additional_notes')}
              className="w-full"
              error={errors.additional_notes?.message}
              placeholder="Add specific details regarding the issue"
              label="Additional details (optional)"
            />
          </div>
        </div>
        <div className="flex items-center justify-start gap-5 px-8 pb-8">
          <GraniteButton size="large" variant="secondary" onClick={close}>
            Back
          </GraniteButton>
          <GraniteButton size="large" variant="primary" type="submit">
            Reopen ticket
          </GraniteButton>
        </div>
      </form>
    </Modal>
  );
};

export default ReopenTicket;
