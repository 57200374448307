import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import ContactGroupAddForm, {
  ContactGroupAddFormProps,
} from './Form/ContactGroupAddForm';
import { useModal } from 'hooks/useModal';
import { FC } from 'react';

const AddContactGroup: FC<
  Pick<ContactGroupAddFormProps, 'refetchOnSuccess'>
> = ({ refetchOnSuccess }) => {
  const { open, isOpen, close } = useModal();

  const onAdd = () => {
    open();
  };
  return (
    <>
      <GraniteButton onClick={onAdd}>Add contact group</GraniteButton>
      <Modal
        isVisible={isOpen}
        close={close}
        className="w-full max-w-[836px]"
        enableScrolling
      >
        <ContactGroupAddForm
          close={close}
          refetchOnSuccess={refetchOnSuccess}
        />
      </Modal>
    </>
  );
};

export default AddContactGroup;
