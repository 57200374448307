import { forwardRef, useLayoutEffect, useMemo, useRef, useState } from 'react';
import format from 'date-fns/format';
import TextAreaGranite from '../../components/TextArea';
import { GraniteButton } from '../../components/V2/Button/GraniteButton';
import { Tabs } from '../../components/V2/Tabs/Tabs';
import {
  isTicketCompletedOrCancelled,
  isTicketReadonlyFn,
} from './ticketdetail-utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  CANCELLED,
  COMPLETE,
  ExtendedTicket,
} from '../../api/techexpress/schema';
import {
  createTicketNote,
  getTicketNotes,
  getTicketUpdates,
} from 'api/techexpress/api';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { toSentenceCase } from 'shared/util/util';
import {
  TicketNoteRequest,
  TicketNoteRequestSchema,
  TicketNoteResponse,
} from '../../api/techexpress/schemas/TicketNoteSchemas';
import { useProductTourContext } from 'context/ProductTourContext';
import { TokenUserPermissions } from 'api/users/schemas/Users';
import { DisplayForPermission } from 'components/Permission/DisplayForPermission';

export interface TicketDetailActivityProps {
  ticket?: ExtendedTicket;
  isEdit: boolean;
}

export const TicketDetailActivity = forwardRef<
  HTMLTextAreaElement | null,
  TicketDetailActivityProps
>(function TicketDetailActivity({ ticket, isEdit = false }, ref) {
  const [currentTab, setCurrentTab] = useState<'chat' | 'ticketUpdates'>(
    'chat',
  );
  const { ticketId } = useParams();
  const { running } = useProductTourContext();

  const { data } = useQuery(
    [ticketId, 'ticket-notes'],
    () => getTicketNotes(ticketId),
    {
      enabled: !!ticketId && !running,
    },
  );

  return (
    <div className="flex flex-col gap-6 rounded bg-background-base-surface-2 p-6">
      <h2 className="text-2xl font-bold text-content-base-default">Activity</h2>
      <div>
        <Tabs
          className="mb-6"
          tabs={[
            {
              title: 'Notes',
              active: currentTab === 'chat',
              onClick: () => setCurrentTab('chat'),
            },
            {
              title: 'Ticket updates',
              active: currentTab === 'ticketUpdates',
              onClick: () => setCurrentTab('ticketUpdates'),
            },
          ]}
        />
        {currentTab === 'chat' && (
          <Chat ticket={ticket} notes={data} isEdit={isEdit} ref={ref} />
        )}
        {currentTab === 'ticketUpdates' && (
          <TicketUpdates ticketId={ticketId} />
        )}
      </div>
    </div>
  );
});

interface ChatProps {
  ticket?: ExtendedTicket;
  notes?: TicketNoteResponse[];
  isEdit: boolean;
}

export const Chat = forwardRef<HTMLTextAreaElement | null, ChatProps>(
  function Chat({ ticket, notes, isEdit }, ref) {
    const {
      control,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm<TicketNoteRequest>({
      resolver: zodResolver(TicketNoteRequestSchema),
    });

    const isTicketReadonly = useMemo(
      () => (!ticket ? false : isTicketReadonlyFn(ticket)),
      [ticket],
    );

    const queryClient = useQueryClient();

    const createTicketNoteMutation = useMutation(
      ({
        ticketId,
        newTicketNote,
      }: {
        ticketId: string | undefined;
        newTicketNote: { text: string };
      }) => createTicketNote(newTicketNote, ticketId),
      {
        onSuccess: () => {
          queryClient.refetchQueries([ticket?.id.toString(), 'ticket-notes']);
        },
      },
    );

    const onSubmit = (data: { text: string }) => {
      createTicketNoteMutation.mutate({
        ticketId: ticket?.id.toString(),
        newTicketNote: { text: data.text },
      });
      reset({ text: '' });
    };

    return (
      <div className="flex flex-col gap-6">
        <div className="flex-end vertical-scrollbar flex h-[512px] flex-col gap-6 rounded bg-background-base-surface-1 p-4">
          {notes?.map((cm) => (
            <TicketActivityItem
              key={cm.id}
              datetime={cm.date_entered}
              title={cm.created_by}
              body={cm.text}
            />
          ))}
        </div>
        {!isTicketCompletedOrCancelled(ticket) && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <span className="text-base font-bold text-teal-400">
              Add a note
            </span>
            <Controller
              name="text"
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field: { ref: _ref, ...field } }) => (
                <TextAreaGranite
                  {...field}
                  ref={ref}
                  className="mb-4 mt-2"
                  error={errors.text?.message}
                  disabled={isTicketReadonly || isEdit}
                />
              )}
            />
            <div className="flex justify-end">
              <DisplayForPermission
                permission={TokenUserPermissions.TECH_EXPRESS_WRITE}
                upgradeTooltip
              >
                <GraniteButton
                  type="submit"
                  disabled={
                    isTicketReadonly ||
                    isEdit ||
                    createTicketNoteMutation.isLoading
                  }
                >
                  Submit
                </GraniteButton>
              </DisplayForPermission>
            </div>
          </form>
        )}
      </div>
    );
  },
);

export const TicketUpdates = ({ ticketId }: { ticketId?: string }) => {
  const { data: updates } = useQuery(
    [ticketId, 'ticket-updates'],
    () => getTicketUpdates(ticketId),
    {
      enabled: !!ticketId,
    },
  );

  return (
    <div className="flex flex-col gap-6">
      <div className="flex-end flex h-[512px] flex-col gap-6 overflow-y-scroll rounded bg-background-base-surface-1 p-4 scrollbar-none">
        {updates?.map((update) => (
          <TicketActivityItem
            key={update.date_entered}
            datetime={update.date_entered}
            title={
              !update.old_status && update.new_status
                ? 'Ticket opened'
                : update.new_status === CANCELLED
                  ? 'Ticket cancelled'
                  : update.new_status === COMPLETE
                    ? 'Ticket completed'
                    : 'Status update'
            }
            body={
              !update.old_status && update.new_status
                ? `Ticket #${ticketId} was created`
                : update.new_status === CANCELLED
                  ? `Ticket #${ticketId} was cancelled`
                  : update.new_status === COMPLETE
                    ? `Ticket #${ticketId} was completed`
                    : `Ticket status changed from ${toSentenceCase(
                        update.old_status,
                      )} to ${toSentenceCase(update.new_status)}`
            }
          />
        ))}
      </div>
    </div>
  );
};

interface TicketActivityItemProps {
  datetime?: string;
  title?: string;
  body?: string;
}

export const TicketActivityItem = ({
  datetime,
  title,
  body,
}: TicketActivityItemProps) => {
  const container = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (container.current)
      container.current.scrollIntoView({
        behavior: 'instant',
        block: 'nearest',
        inline: 'end',
      });
  }, []);

  return (
    <div
      ref={container}
      className="odd:text-status-info-default even:text-teal-400"
    >
      {datetime && (
        <div className="text-xs font-bold text-content-base-subdued">
          {format(new Date(datetime), 'MM/dd/yyyy @ HH:mm a ')}
        </div>
      )}
      <div className="mb-2 text-base font-bold">{title}</div>
      <p className="text-base font-bold text-content-base-default">{body}</p>
    </div>
  );
};
