import { Modal } from 'components/Modal/Modal';
import { useNavigate } from 'react-router-dom';
import CreateFormPermission from './CreateFormPermission';

const CreatePermission = () => {
  const navigate = useNavigate();

  const onClose = () => navigate('..', { replace: true });

  return (
    <Modal isVisible={true} close={onClose} className="w-full max-w-[624px]">
      <CreateFormPermission />
    </Modal>
  );
};

export default CreatePermission;
