import logo from 'assets/images/logo.svg';
import clsx from 'clsx';

interface LogoProps {
  className?: string;
}
const Logo = ({ className = '' }: LogoProps) => {
  return (
    <img
      className={clsx('pointer-events-none h-8 w-auto sm:h-12', className)}
      src={logo}
      alt="logo"
    />
  );
};

export default Logo;
