import { utils, writeFile } from 'xlsx';
import { useMutation } from 'react-query';

export const useDownloadAsExcel = <Result>(
  queryFn: () => Promise<Result[]>,
  fileName: string,
  columns?: Array<keyof Result>,
) => {
  const mutation = useMutation({ mutationFn: queryFn });
  return async () => {
    const data = await mutation.mutateAsync();
    const json = !columns
      ? data ?? []
      : (data ?? []).map((item) =>
          columns.reduce<Partial<Result>>((acc, key) => {
            if (key in (item as object)) acc[key] = item[key];
            return acc;
          }, {}),
        );
    const worksheet = utils.json_to_sheet(json);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    writeFile(
      workbook,
      fileName ? `${fileName} ${new Date()}.xlsx` : 'data.xlsx',
    );
  };
};
