import { ChevronDown } from 'react-ionicons';

interface LocationAccordionProps {
  title: React.ReactNode;
  children: React.ReactNode;
  toggle: () => void;
  isOpen: boolean;
  isMenuOpen?: boolean;
}

export const LocationAccordion = ({
  children,
  isOpen,
  title,
  toggle,
  isMenuOpen,
}: LocationAccordionProps) => {
  return (
    <div
      className={`align-center relative w-full rounded ${
        // eslint-disable-next-line no-constant-condition
        false ? 'bg-background-base-surface-1' : ''
      }`}
    >
      <div>
        {/* {({ open }) => ( */}
        <div
          className={`mb-3 ${isMenuOpen ? 'rounded-t' : 'rounded'}  ${
            // eslint-disable-next-line no-constant-condition
            false
              ? ''
              : 'border border-solid border-stroke-base-default border-opacity-[.5]'
          } py-2 text-content-base-default hover:border-stroke-accent-default`}
        >
          <div
            className="flex !w-full cursor-pointer justify-between rounded-lg px-4 py-2 text-left text-base"
            onClick={toggle}
          >
            <span>{title}</span>
            <div
              className={`${
                isOpen ? 'rotate-180 transform' : ''
              } h-5 w-5 self-center `}
            >
              <ChevronDown color="inherit" />
            </div>
          </div>
          <div className={`px-4 pb-2 pt-4 text-sm ${isOpen ? '' : 'hidden'}`}>
            {children}
          </div>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};
