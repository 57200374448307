import { MultiValueProps, components } from 'react-select';
type OptionType = { label: string; value: string };

export const CustomMultiValue = (props: MultiValueProps<OptionType>) => {
  // Check if the option is the 'Select All' option and skip rendering it
  if (props.data.value === '*') {
    return null;
  }
  return <components.MultiValue {...props} />;
};
