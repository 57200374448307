import { z } from 'zod';

const ProductSchema = z.object({
  type: z.string().optional(),
  id: z.string(),
  ip_type: z.string(),
  bandwidth_download: z.string(),
  bandwidth_upload: z.string(),
  formal_broadband_pricing: z
    .object({
      circuit: z.number(),
      id: z.string(),
      internet_access_recovery: z.number(),
      ip: z.number(),
      total_mrc: z.number(),
      modem: z.number().optional(),
      equipment: z.number().optional(),
      service_activation: z.number().nullable(),
    })
    .optional(),
  formal_dia_pricing: z
    .object({
      id: z.string(),
      ip: z.number(),
      circuit: z.number(),
      total_mrc: z.number(),
      modem: z.number().optional(),
      equipment: z.number().optional(),
      carrier_surcharge_recovery: z.number(),
      service_activation: z.number().nullable(),
    })
    .optional(),
});

const ProductOfferingSchema = z.object({
  formal_broadband_product: ProductSchema.nullable(),
  formal_dia_product: ProductSchema.nullable(),
  id: z.string(),
  ip_block: z.string(),
  ordered: z.boolean(),
  product_type: z.string(),
  provider: z.string(),
  qty: z.number(),
  term: z.string(),
});

const LocationSchema = z.object({
  address1: z.string(),
  address2: z.string().nullable(),
  city: z.string(),
  state: z.string(),
  zip_code: z.string(),
});

const ProductLocationSchema = z.object({
  ...LocationSchema.shape,
  formal_products_offerings: z.array(ProductOfferingSchema),
  id: z.string(),
});

// TODO: Temp untill align with backend, and finish full API integration
export const FormalPricingSchema = z.record(z.any());

// export const FormalPricingSchema = z.object({
//   business_unit: z.string(),
//   company_id: z.number(),
//   customer_name: z.string(),
//   email: z.string().email(),
//   error_message: z.string().nullable(),
//   id: z.string(),
//   locations: z.array(ProductLocationSchema),
//   qe_quote_created_at: z.string(),
//   qe_quote_request_id: z.number(),
//   quote_name: z.string().nullable(),
//   quote_status: z.string(),
//   requester: z.string(),
// });

// TypeScript interfaces
export type FormalPricingResponse = z.infer<typeof FormalPricingSchema>;
export type ProductLocationType = z.infer<typeof ProductLocationSchema>;
export type LocationType = z.infer<typeof LocationSchema>;
export type ProductOfferingType = z.infer<typeof ProductOfferingSchema>;
export type ProductType = z.infer<typeof ProductSchema>;
// type DIAProduct = z.infer<typeof DIAProductSchema>;
// type BroadbandPricing = z.infer<typeof BroadbandPricingSchema>;
