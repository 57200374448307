import { Dispatch } from 'react';
import { TicketDetailFormUnion } from '../TicketDetails/schemas';
import { GetStartedQuestionnaireForm } from '../GetStarted/schemas';
import { WizardAction } from '../WizardProvider/WizardReducer';
import { TicketDetailForms } from '../TicketDetails/DefinedFormTypes';
import { extensionPOTSFormToReview } from '../TicketDetails/RunExtension/ExtensionPOTSFormToReview';
import { extensionCircuitFormToReview } from '../TicketDetails/RunExtension/ExtensionCircuitFormToReview';
import { moveFormToReview } from '../TicketDetails/Move/moveFormToReview';
import { smartHandsFormToReview } from '../TicketDetails/SmartHands/smartHandsFormToReview';
import { auditFormToReview } from '../TicketDetails/Audit/auditFormToReview';
import { equipmentInstallFormToReview } from '../TicketDetails/EquipmentInstall/equipmentInstallFormToReview';
import { broadbandCircuitFormToReview } from '../TicketDetails/BroadbandCircuit/broadbandCircuitFormToReview';
import { POTSCustomerLineFormToReview } from '../TicketDetails/POTSCustomerLine/POTSCustomerLineFormToReview';
import { POTSGraniteLineFormToReview } from '../TicketDetails/POTSGraniteLine/POTSGraniteLineFormToReview';
import { wiringCopperFormToReview } from '../TicketDetails/WiringCopper/wiringCopperFormToReview';
import { wiringFiberFormToReview } from '../TicketDetails/WiringFiber/wiringFiberFormToReview';
import { customerBroadbandCircuitFormToReview } from '../TicketDetails/CustomerBroadbandCircuit/customerBroadbandCircuitFormToReview';
import { customerWiringCopperFormToReview } from '../TicketDetails/CustomerWiringCopper/customerWiringCopperFormToReview';
import { customerWiringFiberFormToReview } from '../TicketDetails/CustomerWiringFiber/customerWiringFiberFormToReview';
import { customerEquipmentFormToReview } from '../TicketDetails/CustomerEquipment/customerEquipmentFormToReview';
import { customerMaterialFormToReview } from '../TicketDetails/CustomerMaterial/customerMaterialFormToReview';

export const dispatchReviewFormFromTicketDetailUnionForm = (
  formData: TicketDetailFormUnion,
  dispatch: Dispatch<WizardAction>,
  questionnaire: GetStartedQuestionnaireForm,
) => {
  switch (formData.formType) {
    case TicketDetailForms.enum.ExtensionPOTS: {
      dispatch({
        type: 'GoToReview',
        getStartedQuestionnaire: questionnaire,
        reviewForm: extensionPOTSFormToReview(questionnaire, formData),
        ticketDetail: formData,
      });
      break;
    }
    case TicketDetailForms.enum.ExtensionCircuit:
      {
        dispatch({
          type: 'GoToReview',
          getStartedQuestionnaire: questionnaire,
          reviewForm: extensionCircuitFormToReview(questionnaire, formData),
          ticketDetail: formData,
        });
      }
      break;
    case TicketDetailForms.enum.Move:
      {
        dispatch({
          type: 'GoToReview',
          getStartedQuestionnaire: questionnaire,
          reviewForm: moveFormToReview(questionnaire, formData),
          ticketDetail: formData,
        });
      }
      break;
    case TicketDetailForms.enum.SmartHands:
      {
        dispatch({
          type: 'GoToReview',
          getStartedQuestionnaire: questionnaire,
          reviewForm: smartHandsFormToReview(questionnaire, formData),
          ticketDetail: formData,
        });
      }
      break;
    case TicketDetailForms.enum.Audit:
      {
        dispatch({
          type: 'GoToReview',
          getStartedQuestionnaire: questionnaire,
          reviewForm: auditFormToReview(questionnaire, formData),
          ticketDetail: formData,
        });
      }
      break;
    case TicketDetailForms.enum.EquipmentInstall:
      {
        dispatch({
          type: 'GoToReview',
          getStartedQuestionnaire: questionnaire,
          reviewForm: equipmentInstallFormToReview(questionnaire, formData),
          ticketDetail: formData,
        });
      }
      break;
    case TicketDetailForms.enum.BroadbandCircuit:
      {
        dispatch({
          type: 'GoToReview',
          getStartedQuestionnaire: questionnaire,
          reviewForm: broadbandCircuitFormToReview(questionnaire, formData),
          ticketDetail: formData,
        });
      }
      break;
    case TicketDetailForms.enum.POTSGraniteLine:
      {
        dispatch({
          type: 'GoToReview',
          getStartedQuestionnaire: questionnaire,
          reviewForm: POTSGraniteLineFormToReview(questionnaire, formData),
          ticketDetail: formData,
        });
      }
      break;
    case TicketDetailForms.enum.WiringCopper:
      {
        dispatch({
          type: 'GoToReview',
          getStartedQuestionnaire: questionnaire,
          reviewForm: wiringCopperFormToReview(questionnaire, formData),
          ticketDetail: formData,
        });
      }
      break;
    case TicketDetailForms.enum.WiringFiber:
      {
        dispatch({
          type: 'GoToReview',
          getStartedQuestionnaire: questionnaire,
          reviewForm: wiringFiberFormToReview(questionnaire, formData),
          ticketDetail: formData,
        });
      }
      break;
    case TicketDetailForms.enum.CustomerBroadbandCircuit:
      {
        dispatch({
          type: 'GoToReview',
          getStartedQuestionnaire: questionnaire,
          reviewForm: customerBroadbandCircuitFormToReview(
            questionnaire,
            formData,
          ),
          ticketDetail: formData,
        });
      }
      break;
    case TicketDetailForms.enum.POTSCustomerLine:
      {
        dispatch({
          type: 'GoToReview',
          getStartedQuestionnaire: questionnaire,
          reviewForm: POTSCustomerLineFormToReview(questionnaire, formData),
          ticketDetail: formData,
        });
      }
      break;
    case TicketDetailForms.enum.CustomerWiringCopper:
      {
        dispatch({
          type: 'GoToReview',
          getStartedQuestionnaire: questionnaire,
          reviewForm: customerWiringCopperFormToReview(questionnaire, formData),
          ticketDetail: formData,
        });
      }
      break;
    case TicketDetailForms.enum.CustomerWiringFiber:
      {
        dispatch({
          type: 'GoToReview',
          getStartedQuestionnaire: questionnaire,
          reviewForm: customerWiringFiberFormToReview(questionnaire, formData),
          ticketDetail: formData,
        });
      }
      break;
    case TicketDetailForms.enum.CustomerEquipment:
      {
        dispatch({
          type: 'GoToReview',
          getStartedQuestionnaire: questionnaire,
          reviewForm: customerEquipmentFormToReview(questionnaire, formData),
          ticketDetail: formData,
        });
      }
      break;
    case TicketDetailForms.enum.CustomerMaterial:
      {
        dispatch({
          type: 'GoToReview',
          getStartedQuestionnaire: questionnaire,
          reviewForm: customerMaterialFormToReview(questionnaire, formData),
          ticketDetail: formData,
        });
      }
      break;
  }
};
