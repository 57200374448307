import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useQuery } from 'react-query';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { getAddressBasedOnMacnum } from 'api/techexpress/api';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { LocationData } from 'screens/LetUsHelp/GetStarted/BulkModalLocations';
import { LocationsArraySchema } from 'screens/LetUsHelp/GetStarted/schemas';

interface MacnumInputProps<T extends FieldValues> {
  index: number;
  name: Path<T>;
  control: Control<T>;
  error?: string | boolean;
  currentLocations: LocationsArraySchema;
  macnumValue?: string;
  storedData?: LocationsArraySchema;
  updateLocationData: (
    index: number,
    data: LocationData | undefined,
    removeFromSavedState?: boolean,
  ) => void;
  onPaste: (
    event: React.ClipboardEvent<HTMLInputElement>,
    pasteIndex: number,
  ) => void;
}

export const MacnumInput = <T extends FieldValues>({
  index,
  name,
  control,
  error,
  updateLocationData,
  onPaste,
  currentLocations,
  macnumValue,
  storedData,
}: MacnumInputProps<T>) => {
  const isFirstRender = useRef<boolean>(true);
  const { field } = useController({ name, control });
  const { onChange, ref, ...rest } = field;
  const [inputValue, setInputValue] = useState(field.value || '');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const isDuplicate = useCallback(
    (value: string, index: number) => {
      if (value.trim() === '') {
        return false;
      }

      const duplicateIndexes = currentLocations
        .map((loc, locIndex) => (loc.macnum === value ? locIndex : -1))
        .filter((locIndex) => locIndex !== -1);

      const isFirstOccurrence = duplicateIndexes[0] === index;
      const isDuplicateOccurrence =
        duplicateIndexes.includes(index) && !isFirstOccurrence;

      return isDuplicateOccurrence;
    },
    [currentLocations],
  );

  const { data } = useQuery(
    ['macnum-address', inputValue, macnumValue],
    () => getAddressBasedOnMacnum(macnumValue || inputValue),
    {
      enabled:
        (!isDuplicate(macnumValue || inputValue, index) &&
          macnumValue?.length === 8 &&
          !!macnumValue) ||
        (!!inputValue && inputValue.length === 8),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  useEffect(() => {
    if (data && data?.reason === 'Site with the given macnum not found') {
      setErrorMessage('Macnum not found');
    } else if (
      data &&
      data?.reason === 'Cannot be a non-billing account number'
    ) {
      setErrorMessage('Cannot be a non-billing account number');
    }
  }, [data]);

  useEffect(() => {
    if (inputValue.length === 0) {
      updateLocationData(index, undefined);
      setErrorMessage('');
    }
  }, [index, inputValue, updateLocationData]);

  useEffect(() => {
    const isError = data && data.reason;
    const isNewMacnum = storedData?.length ? index >= storedData?.length : true;
    if (
      data &&
      (!isError || !isError === undefined) &&
      !isDuplicate(macnumValue || inputValue, index) &&
      isNewMacnum
    ) {
      updateLocationData(index, data);
      isFirstRender.current = false;
    }
  }, [
    data,
    index,
    updateLocationData,
    isDuplicate,
    macnumValue,
    inputValue,
    storedData,
  ]);

  const handleBackSpace = (key: string) => {
    if (key === 'Backspace') {
      updateLocationData(index, undefined, true);
    }
  };

  return (
    <GraniteInput
      {...rest}
      type="number"
      innerInputClassName="w-full"
      placeholder="E.g., 05322334"
      className={`col-span-2 w-full`}
      maxLength={8}
      ref={ref}
      error={errorMessage.length ? errorMessage : error}
      onChange={(e) => {
        const newValue = e.target.value;
        if (!!newValue.length && isDuplicate(newValue, index)) {
          setErrorMessage('Macnum already added.');
          setInputValue(newValue);
          onChange(newValue);
        } else {
          setInputValue(newValue);
          onChange(newValue);
          setErrorMessage('');
        }
      }}
      onPaste={(e) => {
        const pastedData = e.clipboardData.getData('text');
        const splitData = pastedData.split(/\r\n|\r|\n/)[0];
        let value: string;
        if (splitData.length === 7) {
          value = (inputValue || 0 + splitData).toString();
        } else {
          value = splitData;
        }
        onPaste(e, index);
        if (isDuplicate(value, index)) {
          setErrorMessage('Macnum already added.');
          return;
        }
        setInputValue(value);
        onChange(value);
        setErrorMessage('');
      }}
      onKeyDown={(e) => handleBackSpace(e.key)}
    />
  );
};
