import { NetOpsItem } from 'api/nocexpress/schema';
import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ModalParams } from 'hooks/useModal';
import { Link } from 'react-router-dom';

export interface DuplicateTicketWarningDialogProps extends ModalParams {
  duplicates?: NetOpsItem[];
}

export const DuplicateTicketWarningDialog = ({
  isOpen,
  close,
  duplicates,
}: DuplicateTicketWarningDialogProps) => {
  const duplicateId =
    duplicates && duplicates.length > 0 ? duplicates[0].id : 0;

  return (
    <Modal isVisible={isOpen} close={close}>
      <div className="max-w-[624px] p-8">
        <h1 className="m-0 mb-2 text-2xl font-bold text-content-base-default">
          There’s an existing ticket for this account
        </h1>
        <p className="mb-12 text-base font-bold text-content-base-subdued">
          You already have ticket #{duplicateId} open for this location. Only
          one ticket can be opened for a particular location at a time.
        </p>
        <div className="flex gap-4">
          <GraniteButton variant="secondary" size="large" onClick={close}>
            Cancel
          </GraniteButton>
          <Link
            className="button primary large"
            to={`/noc-express/${duplicateId}`}
          >
            View ticket
          </Link>
        </div>
      </div>
    </Modal>
  );
};
