import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useQuery } from 'react-query';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { fetchBasedOnIdentifier } from 'api/nocexpress/api';
import { BulkLocations } from './schemas';
import { Configurations } from 'api/nocexpress/schemas/ConfigurationsSchema';
import { SiteAddress } from 'api/addresssearch/schema';
interface ChildAccountInputProps<T extends FieldValues> {
  index: number;
  name: Path<T>;
  control: Control<T>;
  error?: string | boolean;
  currentLocations: BulkLocations['locations'];
  accountValue?: string;
  storedData?: BulkLocations['locations'];
  updateLocationData: (
    index: number,
    data: Configurations[] | SiteAddress[] | undefined,
    removeFromSavedState?: boolean,
  ) => void;
  onPaste: (
    event: React.ClipboardEvent<HTMLInputElement>,
    pasteIndex: number,
  ) => void;
  handleLoadingChange: (loading: boolean, index: number) => void;
  disabled?: boolean;
}

export const ChildAccountInput = <T extends FieldValues>({
  index,
  name,
  control,
  error,
  updateLocationData,
  onPaste,
  currentLocations,
  accountValue,
  storedData,
  disabled = false,
  handleLoadingChange,
}: ChildAccountInputProps<T>) => {
  const isFirstRender = useRef<boolean>(true);
  const { field } = useController({ name, control });
  const { onChange, ref, ...rest } = field;
  const [inputValue, setInputValue] = useState(field.value || '');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const isDuplicate = useCallback(
    (value: string, index: number) => {
      if (value.trim() === '') {
        return false;
      }

      const duplicateIndexes = currentLocations
        .map((loc, locIndex) => (loc.childAccount === value ? locIndex : -1))
        .filter((locIndex) => locIndex !== -1);

      const isFirstOccurrence = duplicateIndexes[0] === index;
      const isDuplicateOccurrence =
        duplicateIndexes.includes(index) && !isFirstOccurrence;

      return isDuplicateOccurrence;
    },
    [currentLocations],
  );

  const { data, isLoading } = useQuery(
    ['child-account', inputValue, accountValue],
    () => fetchBasedOnIdentifier('Account', accountValue || inputValue),
    {
      enabled:
        (!isDuplicate(accountValue || inputValue, index) &&
          accountValue?.length === 8 &&
          !!accountValue) ||
        (!!inputValue && inputValue.length === 8),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const previousLoadingState = useRef(isLoading);

  useEffect(() => {
    if (previousLoadingState.current !== isLoading) {
      handleLoadingChange(isLoading, index);
      previousLoadingState.current = isLoading;
    }
  }, [isLoading, index, handleLoadingChange]);

  useEffect(() => {
    if (data && data.length === 0) {
      setErrorMessage('Account not found');
    }
  }, [data]);

  useEffect(() => {
    if (inputValue.length === 0) {
      updateLocationData(index, undefined);
      setErrorMessage('');
    }
  }, [index, inputValue, updateLocationData]);

  useEffect(() => {
    const isError = data && data.length === 0;
    if (
      data &&
      (!isError || !isError === undefined) &&
      !isDuplicate(accountValue || inputValue, index)
    ) {
      updateLocationData(index, data);
      isFirstRender.current = false;
    }
  }, [
    data,
    index,
    updateLocationData,
    isDuplicate,
    accountValue,
    inputValue,
    storedData,
  ]);

  const handleBackSpace = (key: string) => {
    if (key === 'Backspace') {
      updateLocationData(index, undefined, true);
    }
  };

  return (
    <GraniteInput
      {...rest}
      type="number"
      innerInputClassName="w-full"
      placeholder="E.g., 00112233"
      className={`col-span-2 w-full !max-w-[165px]`}
      maxLength={8}
      ref={ref}
      error={errorMessage.length ? errorMessage : error}
      onChange={(e) => {
        const newValue = e.target.value;
        if (!!newValue.length && isDuplicate(newValue, index)) {
          setErrorMessage('Child account already added.');
          setInputValue(newValue);
          onChange(newValue);
        } else {
          setInputValue(newValue);
          onChange(newValue);
          setErrorMessage('');
        }
      }}
      onPaste={(e) => {
        const pastedData = e.clipboardData.getData('text');
        const splitData = pastedData.split(/\r\n|\r|\n/)[0];
        let value: string;
        if (splitData.length === 7) {
          value = (inputValue || 0 + splitData).toString();
        } else {
          value = splitData;
        }
        onPaste(e, index);
        if (isDuplicate(value, index)) {
          setErrorMessage('Account already added.');
          return;
        }
        setInputValue(value);
        onChange(value);
        setErrorMessage('');
      }}
      onKeyDown={(e) => handleBackSpace(e.key)}
      disabled={!disabled}
    />
  );
};
